import Stack from "components/Stack/Stack";
import { FC } from "react";
import RateOutputRow, { Marker, RateOutputRowProps } from "./RateOutputRow";

export type RateOutputItem = Omit<RateOutputRowProps, "index" | "marker">;

export type RateOutputListProps = {
	items: RateOutputItem[];
	marker?: Marker;
};

const RateOutputList: FC<RateOutputListProps> = ({
	items,
	marker = "none",
}) => {
	return (
		<Stack spacing="xxlarge" direction="vertical">
			{items.map((item, i) => (
				<RateOutputRow
					key={`rate-output-${i}`}
					index={i}
					marker={marker}
					description={item.description}
					min={item.min}
					max={item.max}
					value={item.value}
					marks={item.marks}
					badgesLeading={item.badgesLeading}
					badgesTrailing={item.badgesTrailing}
					quidInputs={item.quidInputs}
					variant={item.variant}
				/>
			))}
		</Stack>
	);
};

export default RateOutputList;
