
import Scorm2004Constants from "./Scorm2004Constants";
import { convertStatusConstantToScormStatus } from "../utils/StatusUtils";
import * as StatusConstants from "../constants/StatusConstants";

var scormCommunicator = window.pipwerks && window.pipwerks.SCORM;

export default class Scorm2004Storage {
	constructor() {}

	save(name, domain, data) {
		this.setData(name, domain, data);
	}

	restore(name, domain, restoreFunction) {
		const data = this.getData(domain);
		const restoredData = restoreFunction(data[name]);
	}

	getData(domain) {
		var data = scormCommunicator.get(Scorm2004Constants.SUSPEND_DATA);
		console.log(
			`Scorm2004Storage: getData: Getting scorm variable '${Scorm2004Constants.SUSPEND_DATA}'. Data:`,
			data
		);

		let jsonData;
		try {
			jsonData = JSON.parse(data);
		} catch (e) {
			console.log(
				`Scorm2004Storage: getData: Error! Could not parse. Data:`,
				data
			);
		}
		console.log(`Scorm2004Storage: getData: JSONData:`, jsonData || {});
		return jsonData || {};
	}

	setData(name, domain, data) {
		const oldData = this.getData(domain);
		const newData = Object.assign({}, oldData, { [name]: data });
		console.log(
			`Scorm2004Storage: setData: Setting scorm variable '${Scorm2004Constants.SUSPEND_DATA}' with data:`,
			newData
		);
		scormCommunicator.set(
			Scorm2004Constants.SUSPEND_DATA,
			JSON.stringify(newData)
		);
		scormCommunicator.save();
	}

	clearData() {
		console.log("Scorm 2004 Storage: clearData()");
		scormCommunicator.set(Scorm2004Constants.SUSPEND_DATA, JSON.stringify({}));
		scormCommunicator.save();
	}

	setCourseStatus(domain, value) {
		console.log(
			`Scorm2004Storage: setCourseStatus: Setting scorm variable '${Scorm2004Constants.COMPLETION_STATUS}' with value:`,
			value
		);
		scormCommunicator.set(Scorm2004Constants.COMPLETION_STATUS, value);

		if (
			value === StatusConstants.COMPLETED ||
			value === convertStatusConstantToScormStatus(StatusConstants.COMPLETED)
		) {
			console.log(
				`Scorm2004Storage: setCourseStatus: course is completed and '${Scorm2004Constants.SUCCESS_STATUS}' is therefore set to passed`
			);
			scormCommunicator.set(
				Scorm2004Constants.SUCCESS_STATUS,
				convertStatusConstantToScormStatus(StatusConstants.PASSED)
			);
		} else {
			console.log(
				`Scorm2004Storage: setCourseStatus: course is not completed and '${Scorm2004Constants.SUCCESS_STATUS}' is therefore set to failed`
			);
			scormCommunicator.set(
				Scorm2004Constants.SUCCESS_STATUS,
				convertStatusConstantToScormStatus(StatusConstants.FAILED)
			);
		}

		scormCommunicator.save();
	}

	getCourseStatus(domain) {
		var value = scormCommunicator.get(Scorm2004Constants.COMPLETION_STATUS);
		console.log(
			`Scorm2004Storage: getCourseStatus: Getting scorm variable '${Scorm2004Constants.COMPLETION_STATUS}'. Value:`,
			value
		);
		return value;
	}

	setCompletionStatus(domain, value) {
		console.log(
			`Scorm2004Storage: setCompletionStatus: Setting scorm variable '${Scorm2004Constants.COMPLETION_STATUS}' with value:`,
			value
		);
		scormCommunicator.set(Scorm2004Constants.COMPLETION_STATUS, value);
		scormCommunicator.save();
	}

	getCompletionStatus(domain) {
		var value = scormCommunicator.get(Scorm2004Constants.COMPLETION_STATUS);
		console.log(
			`Scorm2004Storage: getCompletionStatus: Getting scorm variable '${Scorm2004Constants.COMPLETION_STATUS}'. Value:`,
			value
		);
		return value;
	}

	setSuccessStatus(domain, value) {
		console.log(
			`Scorm2004Storage: setSuccessStatus: Setting scorm variable '${Scorm2004Constants.SUCCESS_STATUS}' with value:`,
			value
		);
		scormCommunicator.set(Scorm2004Constants.SUCCESS_STATUS, value);
		scormCommunicator.save();
	}

	getSuccessStatus(domain) {
		var value = scormCommunicator.get(Scorm2004Constants.SUCCESS_STATUS);
		console.log(
			`Scorm2004Storage: getSuccessStatus: Getting scorm variable '${Scorm2004Constants.SUCCESS_STATUS}'. Value:`,
			value
		);
		return value;
	}

	setScore(domain, rawScore, minScore, maxScore) {
		console.log("Scorm2004Storage: setScore", rawScore, minScore, maxScore);
		scormCommunicator.set(Scorm2004Constants.SCORE_RAW, rawScore);
		scormCommunicator.set(Scorm2004Constants.SCORE_MIN, minScore);
		scormCommunicator.set(Scorm2004Constants.SCORE_MAX, maxScore);
		scormCommunicator.set(
			Scorm2004Constants.SCORE_SCALED,
			Math.round((rawScore / maxScore) * 100) / 100
		);
		scormCommunicator.save();
	}

	getScore(domain) {
		var value = scormCommunicator.get(Scorm2004Constants.SCORE_RAW);
		console.log("Scorm2004Storage: getScore", value);
		return value;
	}

	getUserName(domain) {
		var userName = scormCommunicator.get(Scorm2004Constants.STUDENT_NAME);
		console.log(
			`Scorm2004Storage: getUserName: Getting scorm variable '${Scorm2004Constants.STUDENT_NAME}'. User name:`,
			userName
		);
		return userName;
	}

	getUserId(domain) {
		var userId = scormCommunicator.get(Scorm2004Constants.STUDENT_ID);
		console.log(
			`Scorm2004Storage: getUserId: Getting scorm variable '${Scorm2004Constants.STUDENT_ID}'. User ID:`,
			userId
		);
		return userId;
	}

	getQuiddityTitleId() {
		var quiddityTitleId = scormCommunicator.get(
			Scorm2004Constants.QUIDDITY_TITLE_ID
		);
		console.log(
			`Scorm2004Storage: getQuiddityTitleId: Getting scorm variable '${Scorm2004Constants.QUIDDITY_TITLE_ID}'. Quiddity Title ID:`,
			quiddityTitleId
		);
		return quiddityTitleId;
	}
	getQuiddityTitleOrganizationId() {
		var quiddityTitleOrganizationId = scormCommunicator.get(
			Scorm2004Constants.QUIDDITY_TITLE_ORGANIZATION_ID
		);
		console.log(
			`Scorm2004Storage: getQuiddityTitleOrganizationId: Getting scorm variable '${Scorm2004Constants.QUIDDITY_TITLE_ORGANIZATION_ID}'. Quiddity Title Organization ID:`,
			quiddityTitleOrganizationId
		);
		return quiddityTitleOrganizationId;
	}

	getQuiddityTitleName() {
		var quiddityTitleName = scormCommunicator.get(
			Scorm2004Constants.QUIDDITY_TITLE_NAME
		);
		console.log(
			`Scorm2004Storage: getQuiddityTitleName: Getting scorm variable '${Scorm2004Constants.QUIDDITY_TITLE_NAME}'. Quiddity Title name:`,
			quiddityTitleName
		);
		return quiddityTitleName;
	}
}
