import React from "react";

const IconQuote = ({ className, color = "#000000" }) => {
	return (
		<div className={className}>
			<svg width="58px" height="47px" viewBox="0 0 58 47" version="1.1">
				<title>Quote</title>
				<g
					id="****-INDIVIDUAL-TRAINING-WORK-*******"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
				>
					<g
						id="1366x768-desktop-copy-13"
						transform="translate(-347.000000, -1959.000000)"
					>
						<path
							d="M349.960961,2006 C351.644645,2005.12963 353.87988,2003.75154 356.666667,2001.86574 C359.453453,1999.97994 362.008008,1997.84753 364.33033,1995.46852 C366.594595,1993.14753 368.568569,1990.40586 370.252252,1987.24352 C371.935936,1984.08117 372.777778,1980.58519 372.777778,1976.75556 C372.777778,1971.47531 371.703704,1967.19599 369.555556,1963.91759 C367.407407,1960.6392 363.865866,1959 358.930931,1959 C355.737738,1959 353.212212,1960.05895 351.354354,1962.17685 C349.496496,1964.29475 348.567568,1966.54321 348.567568,1968.92222 C348.567568,1972.22963 349.583584,1974.81173 351.615616,1976.66852 C353.647648,1978.52531 356.492492,1979.4537 360.15015,1979.4537 L360.15015,1979.4537 L364.33033,1979.4537 C364.098098,1982.52901 363.517518,1985.00957 362.588589,1986.89537 C361.65966,1988.78117 360.411411,1990.59444 358.843844,1992.33519 C357.682683,1993.66975 356.013514,1995.07685 353.836336,1996.55648 C351.659159,1998.03611 349.38038,1999.47222 347,2000.86481 L347,2000.86481 L349.960961,2006 Z M382.183183,2006 C383.866867,2005.12963 386.102102,2003.75154 388.888889,2001.86574 C391.675676,1999.97994 394.23023,1997.84753 396.552553,1995.46852 C398.816817,1993.14753 400.790791,1990.40586 402.474474,1987.24352 C404.158158,1984.08117 405,1980.58519 405,1976.75556 C405,1971.47531 403.925926,1967.19599 401.777778,1963.91759 C399.62963,1960.6392 396.088088,1959 391.153153,1959 C387.95996,1959 385.434434,1960.05895 383.576577,1962.17685 C381.718719,1964.29475 380.78979,1966.54321 380.78979,1968.92222 C380.78979,1972.22963 381.805806,1974.81173 383.837838,1976.66852 C385.86987,1978.52531 388.714715,1979.4537 392.372372,1979.4537 L392.372372,1979.4537 L396.552553,1979.4537 C396.32032,1982.52901 395.73974,1985.00957 394.810811,1986.89537 C393.881882,1988.78117 392.633634,1990.59444 391.066066,1992.33519 C389.904905,1993.66975 388.235736,1995.07685 386.058559,1996.55648 C383.881381,1998.03611 381.602603,1999.47222 379.222222,2000.86481 L379.222222,2000.86481 L382.183183,2006 Z"
							id="”-copy"
							fill={color}
							fillRule="nonzero"
						/>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default IconQuote;
