import React, { useState, useRef } from "react";
import classnames from "classnames";
import _ from "lodash";
import { PageItemActions, ReduxObjectSelectors } from "funkis-foundation";
import { getText } from "../../../utils/textUtils";
import { getBootstrapColor } from "../../../utils/colorUtils";
import Image from "../../../components/core/Image";
import Text from "../../../components/core/Text";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";
import IconHotspot from "../../../assets/svg/IconHotspot";
import Style from "./PageItemHotspots.module.css";
import Hover from "../../../components/core/Hover";
import LightBox from "../../../components/scroll/LightBox";
import Backend from "../../../components/backend/Backend";
import { connect } from "../../../utils/reduxUtils";
const { makeGetValueSelector } = ReduxObjectSelectors;

const HotspotsComponent = (props) => {
	const { pageItem, viewVisible, isDevMode } = props;
	const { cmtPageItem = {} } = pageItem;

	const header = cmtPageItem.header;
	const instruction = cmtPageItem.instruction;
	const imageSrc = cmtPageItem.image;
	const imageSize = cmtPageItem.image_size;
	const imageAlign = cmtPageItem.image_align;
	const imageMaxHeight = cmtPageItem.image_max_height;
	const imageSpan =
		cmtPageItem.image_span === undefined || cmtPageItem.image_span === ""
			? "2-column"
			: cmtPageItem.image_span;
	const imageCaption = cmtPageItem.image_caption;
	const bodyColumn1 = cmtPageItem.body_column_1;
	const bodyColumn2 = cmtPageItem.body_column_2;

	const hotspot_button_color = getBootstrapColor(
		cmtPageItem.hotspot_button_color || "primary"
	);
	const hotspot_button_hover_color = getBootstrapColor(
		cmtPageItem.hotspot_button_hover_color || "interaction"
	);

	const showBodyColumn1 = getText(bodyColumn1) || props.bodyColumn1;
	const showBodyColumn2 = getText(bodyColumn2) || props.bodyColumn2;

	const content_array = cmtPageItem.content_array;

	const [activeIndex, setActiveIndex] = useState();

	const coordinateSortedContentArray = [...content_array].sort((a, b) => {
		const [aX, aY] = a.position_xy_percent.split(",");
		const [bX, bY] = b.position_xy_percent.split(",");
		return Math.hypot(aX, aY) - Math.hypot(bX, bY);
	});

	const ioID = cmtPageItem.io_id || cmtPageItem.id;

	const show_next_button = cmtPageItem.show_next_button;
	const checkIsComplete = ({ selectedIndexes = [] }) => {
		// always,on-select,on-done
		return (
			pageItem.status === "complete" ||
			show_next_button === undefined ||
			show_next_button == "always" ||
			(show_next_button == "on-done" &&
				selectedIndexes.length === content_array.length)
		);
	};

	const selectedItemHeaderTextId = _.get(
		content_array,
		`[${activeIndex}].header`
	);
	const selectedItemBodyTextId = _.get(content_array, `[${activeIndex}].body`);
	const selectedItemImage = _.get(content_array, `[${activeIndex}].image`);

	const imageContainerRef = useRef();

	const handleMouseMove = _.throttle((event) => {
		if (!imageContainerRef.current || !isDevMode) return;
		const { width, height } = imageContainerRef.current.getBoundingClientRect();
		const offsetY = (event.nativeEvent.offsetY / height) * 100;
		const offsetX = (event.nativeEvent.offsetX / width) * 100;
		console.log(`Mouse position (x,y), only works on empty part of image :
     ${offsetX.toFixed(2)} , ${offsetY.toFixed(2)}`);
	}, 50);

	return (
		<React.Fragment>
			{/* HEADER */}
			<div className="container-fluid">
				<div className="row m-0">
					<Text
						className={classnames(
							"col-sm-12 mb-4 font-weight-bold animated",
							viewVisible ? "fadeInUp" : "fadeOut"
						)}
						tagName="h2"
						textId={header}
					/>
				</div>
			</div>
			{/* INSTRUCTION */}
			<div className="container-fluid">
				<div className="row m-0">
					<Text
						className={classnames(
							"instruction font-italic col-sm-12 mb-2 animated",
							viewVisible ? "fadeInUp" : "fadeOut"
						)}
						tagName="p"
						textId={instruction}
					/>
				</div>
			</div>
			<div className={classnames("container-fluid px-sm-5")}>
				<div
					className={classnames("position-relative")}
					ref={imageContainerRef}
					onMouseMove={handleMouseMove}
				>
					<Image
						src={imageSrc}
						maxHeight={imageMaxHeight}
						className="mb-5 row m-0"
						classNameImage={classnames("col-12")}
						show={viewVisible}
					></Image>
					<React.Fragment>
						<Backend
							quidType="multi-select"
							mode="input"
							targetDataKey={ioID}
							onFetchComplete={({ quidInputs }) => {
								const selectedIndexes = _.get(
									quidInputs[0],
									"data.selectedIndexes",
									[]
								);
								console.log("loaded ", selectedIndexes);
								PageItemActions.updatePageItem(pageItem.id, {
									status: checkIsComplete({ selectedIndexes })
										? "completed"
										: "incomplete",
								});
								/// setup internal state
							}}
							renderInput={(renderProps) => {
								const { data, save, updateData } = renderProps;
								const completed = data.completed;
								const selectedIndexes = data.selectedIndexes || [];

								return (
									<div>
										{content_array.map((option, optionIndex) => {
											const [x, y] = option.position_xy_percent.split(",");
											const animationDuration = 0.5;
											const coordinateSortedIndex =
												coordinateSortedContentArray.indexOf(option);
											const isSelected = selectedIndexes.includes(optionIndex);
											const isCenteredHotspotOrigin =
												!cmtPageItem.hotspot_internal_origin
													? false
													: cmtPageItem.hotspot_internal_origin === "center";
											return (
												<div
													onClick={() => {
														const newIndexes = _.uniq(
															selectedIndexes.concat(optionIndex)
														);
														// updateData( { selectedIndexes: newIndexes } );
														const completed = checkIsComplete({
															selectedIndexes: newIndexes,
														});
														save(false, {
															selectedIndexes: newIndexes,
															completed: completed,
														});
														setActiveIndex(optionIndex);
														PageItemActions.updatePageItem(pageItem.id, {
															status: completed ? "completed" : "incomplete",
														});
													}}
													key={`multi-choice-${optionIndex}`}
													className={classnames(
														"animated position-absolute",
														viewVisible ? "bounceIn" : "fadeOut",
														isSelected ? "selected" : "unselected",
														Style.clickIcon
													)}
													style={{
														animationDuration: `${animationDuration}s`,
														animationDelay:
															1 +
															(animationDuration / 4) * coordinateSortedIndex +
															"s",
														top: `${y}%`,
														left: `${x}%`,
													}}
												>
													<Hover
														style={{
															transform: isCenteredHotspotOrigin
																? "translate(-50%,-50%)"
																: "",
														}}
													>
														{({ hover }) => {
															return (
																<IconHotspot
																	showOuterCircle={
																		selectedIndexes.includes(optionIndex) &&
																		!hover
																	}
																	className={""}
																	fillColor={
																		hover
																			? hotspot_button_hover_color
																			: hotspot_button_color
																	}
																/>
															);
														}}
													</Hover>
												</div>
											);
										})}
									</div>
								);
							}}
						/>
					</React.Fragment>
				</div>
			</div>

			{/* BODY */}
			<div className="container-fluid">
				<div
					className={classnames(
						"row m-0 mb-1 animated",
						viewVisible ? "fadeIn delay-1000ms" : "fadeOut"
					)}
				>
					{showBodyColumn1 && (
						<Text
							className={classnames(
								"p-mediumn",
								showBodyColumn2 ? "col-sm-6" : "col-sm-12"
							)}
							tagName="p"
							textId={bodyColumn1}
							placeholder={props.bodyColumn1}
							hideIfMissing={props.bodyColumn1 === undefined}
						/>
					)}
					{showBodyColumn2 && (
						<Text
							className="p-medium col-sm-6"
							tagName="p"
							textId={bodyColumn2}
							placeholder={props.bodyColumn2}
							hideIfMissing={props.bodyColumn2 === undefined}
						/>
					)}
				</div>
			</div>
			<LightBox
				onOuterContainerClick={() => setActiveIndex(false)}
				classNameOuterBackground=""
				classNameInnerContainer={classnames(Style.lightBoxInnerContainer)}
				classNameContentContainer={Style.lightBoxContentContainer}
				show={_.isNumber(activeIndex)}
				onCloseClick={() => setActiveIndex(false)}
				styleOuterContainer={{ backgroundColor: "rgba(0,0,0, 0.3)" }}
			>
				{/* TODO:  #f4f4f4  x-light-grey, should be added as named color?  */}
				<Text
					tagName="h4"
					className="mt-4 font-weight-bold mb-4"
					textId={selectedItemHeaderTextId}
				/>
				{selectedItemImage && (
					<Image
						className={classnames(Style.lightBoxImage, "mb-4", {
							"mt-5": !getText(selectedItemHeaderTextId),
						})}
						src={selectedItemImage}
					/>
				)}
				<Text
					tagName="p"
					className={classnames("p-medium", {
						"mt-5": !selectedItemImage && !getText(selectedItemHeaderTextId),
					})}
					textId={selectedItemBodyTextId}
				/>
			</LightBox>
		</React.Fragment>
	);
};

const PageItemHotspots = (props) => {
	return (
		<PageItemScrollBase
			{...props}
			className={Style.root}
			containerClassName="w-100"
			renderFunction={(renderProps) => (
				<HotspotsComponent
					{...props}
					viewVisible={renderProps.viewVisible}
				></HotspotsComponent>
			)}
			showNextButton={props.pageItem.status == "completed"}
		/>
	);
};

const makeMapStateToProps = () => {
	const GetValueSelector = makeGetValueSelector();

	const mapStateToProps = (state, props) => {
		return {
			isDevMode: GetValueSelector(
				state,
				{ ...props, path: "devMode" },
				"config"
			),
		};
	};
	return mapStateToProps;
};

export default connect(PageItemHotspots, makeMapStateToProps);
