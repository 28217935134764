import { createSelector } from "reselect";

const getLocation = (state) =>
	(state.location.item && state.location.item.location) || [];
const getLocationQuery = (state) =>
	(state.location.item && state.location.item.query) || {};

export const getLocationSelector = createSelector(getLocation, (location) => {
	return location;
});

export const getLocationQuerySelector = createSelector(
	getLocationQuery,
	(locationQuery) => {
		return locationQuery;
	}
);

export function makeGetLocation() {
	console.warn(
		`"makeGetLocation" is depricated. Please use "makeGetLocationSelector" `
	);
	return getLocationSelector;
}

export function makeGetLocationSelector() {
	return getLocationSelector;
}

export function makeGetLocationQuery() {
	console.warn(
		`"makeGetLocationQuery" is depricated. Please use "makeGetLocationQuerySelector" `
	);
	return getLocationQuerySelector;
}

export function makeGetLocationQuerySelector() {
	return getLocationQuerySelector;
}
