import React from "react";
import IconCheck from "./IconCheck";
import IconCross from "./IconCross";

const IconNeutral = (props) => {
	const {
		className,
		classNameCheck,
		classNameCross,
		style,
		styleCheck,
		styleCross,
	} = props;
	return (
		<div className={className} style={style}>
			<IconCheck {...props} className={classNameCheck} style={styleCheck} />
			<IconCross {...props} className={classNameCross} style={styleCross} />
		</div>
	);
};

export default IconNeutral;
