import React from "react";

function splitToChunks<T>(array: T[], parts: number): T[][] {
	const arrayCopy = [...array];
	const result = [] as T[][];
	for (let i = parts; i > 0; i--) {
		result.push(arrayCopy.splice(0, Math.ceil(arrayCopy.length / i)));
	}

	return result;
}

type MasonryProps = {
	gap?: number | string;
	columns: number;
};

const Masonry: React.FC<MasonryProps> = ({
	gap = "1rem",
	columns,
	children,
}) => {
	const childrenArray = React.Children.toArray(children);
	const childColumns = splitToChunks(childrenArray, columns);

	return (
		<div style={{ display: "flex", gap: gap }}>
			{childColumns.map((column, i) => (
				<div
					key={i}
					style={{
						display: "flex",
						flexDirection: "column",
						flexBasis: `${100 / columns}%`,
						flexShrink: "1",
						gap: gap,
					}}
				>
					{column}
				</div>
			))}
		</div>
	);
};

export default Masonry;
