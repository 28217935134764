import React from "react";
import classnames from "classnames";
import { PageItemImage } from "funkis-foundation";
import { getBootstrapColor } from "../../utils/colorUtils";
import Style from "./Bullet.module.css";

const MODES = {
	STANDARD: "standard",
	EMPHASIZED: "emphasized",
};

const Bullet = ({
	image,
	mode = "standard",
	color = "primary",
	className,
	size = 58,
}) => {
	const styleDot = { backgroundColor: getBootstrapColor(color) };
	if (size) {
		styleDot.width = size;
		styleDot.height = size;
		styleDot.borderRadius = size * 0.5;
	}

	if (image) {
		return (
			<PageItemImage
				className={className}
				pageItem={{
					src: image,
					backgroundImage: false,
					autoSize: false,
					width: size || "auto",
					height: "auto",
				}}
			/>
		);
	} else {
		return (
			<div
				style={styleDot}
				className={classnames(
					mode === MODES.EMPHASIZED
						? Style.emphasizedBullet
						: Style.defaultBullet,
					className
				)}
			/>
		);
	}
};

export default Bullet;
