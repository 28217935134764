import { StoreUtils } from "funkis-foundation";
import { IOContext, QuidType } from "../hooks/backend";

export const getText = (
	textId: TextId,
	stripTags = false
): string | undefined => {
	const textById = StoreUtils.getReducer("texts").itemsById;
	const textObj = textById[textId];

	const result = textObj && textObj.value !== "" && textObj.value;
	return stripTags ? stripHTMLTags(result) : result;
};

type ReturnType = "bool" | "boolean" | "id" | "textId" | "text";
type FindTextOptions = {
	stripTags?: boolean;
	returnType?: ReturnType;
};

export const findText = (
	textId: TextId,
	{ stripTags = false, returnType = "boolean" }: FindTextOptions
) => {
	const textById = StoreUtils.getReducer("texts").itemsById;
	const text = textById[textId];
	const hasText = text !== undefined && text !== "";

	switch (returnType) {
		case "bool":
		case "boolean":
			return hasText;

		case "id":
		case "textId":
			return hasText && textId;

		case "text":
			return hasText && getText(textId, stripTags);
	}
};

export const stripHTMLTags = (str?: string): string | undefined => {
	return str && str.replace(/(<([^>]+)>)/gi, "");
};

export type IOTextTemplateVariable = {
	ioId: string;
	quidType: QuidType;
	data: string;
	originalTemplateString: string;
	ioContext?: IOContext;
	index?: number;
};

const IO_TEXT_VARIABLE_MATCHER = /\${ioId.*?}/g;
const IO_TEXT_VARIABLE_TEMPLATE_MARKER_MATCHER = /\${|}/g;

export const getIOTextTemplateVariables = (
	text: string
): IOTextTemplateVariable[] => {
	// Match all substrings that starts with "${iodId" and ends with "}"
	const matches = text.matchAll(IO_TEXT_VARIABLE_MATCHER);
	const textTemplateStrings = Array.from(matches, (m) => m[0]);

	const ioTextTemplateVariables = textTemplateStrings.map(
		(textTemplateString) => {
			// Strip the string of "${}" markers
			const templateStringWithoutTemlateMarkers = textTemplateString.replace(
				IO_TEXT_VARIABLE_TEMPLATE_MARKER_MATCHER,
				""
			);
			const ioTextTemplateVariable: Partial<IOTextTemplateVariable> =
				templateStringWithoutTemlateMarkers
					.split(",")
					.map((keyValueString) => keyValueString.split(":"))
					.reduce(
						(acc, [key, value]: string[]) =>
							Object.assign(acc, { [key]: value }),
						{}
					);

			ioTextTemplateVariable.originalTemplateString = textTemplateString;

			return ioTextTemplateVariable as IOTextTemplateVariable;
		}
	);

	return ioTextTemplateVariables;
};
