import React from "react";
import { FFText, PageItemMenuButton } from "funkis-foundation";

const PageItemChapterButton = (props) => {
	const { pageItem, children } = props;
	const { cmtPage } = pageItem;
	return (
		<PageItemMenuButton
			pageItem={{
				...pageItem,
				pageId: children.length < 1 && cmtPage.id,
				enabled: children.length < 1,
			}}
		>
			<FFText tagName="h1" textId={cmtPage.title_txt_id} />
			{children}
		</PageItemMenuButton>
	);
};

export default PageItemChapterButton;
