import React from "react";
import classnames from "classnames";
import IconCross from "../../assets/svg/IconCrossBig";
import { FFButton } from "funkis-foundation";
import Styles from "./LightBox.module.css";
import Text from "../core/Text";
import { Portal } from "../core/Portal";
import { getBootstrapColor } from "../../utils/colorUtils";

const LightBox = (props) => {
	const {
		children,
		show,
		onCloseClick,
		buttonLabel,
		buttonType = "cross",
		size = "big",
		variant = "default",
		closeTextId = "1BFF7A67-1155-421F-B1D0-4496A9FABA43",
		className,
		classNameInnerContainer,
		classNameOuterContainer,
		classNameContentContainer,
		classNameCloseButton,
	} = props;

	if (!show) {
		return <div />;
	}

	let ButtonClose;

	switch (buttonType) {
		case "cross":
			ButtonClose = ButtonCloseCross;
			break;
		default:
			ButtonClose = ButtonCloseDefault;
			break;
	}

	return (
		<Portal className="light-box">
			<div
				className={classnames(
					"outer-container animated fadeIn faster position-absolute w-100 h-100 bg-dark-80",
					Styles.outerContainer,
					variant === "action" && Styles.variantActionOuterContainer,
					className,
					classNameOuterContainer
				)}
			>
				<div
					className={classnames(
						"inner-container position-relative bg-white w-100 h-100 d-flex flex-column",
						Styles.innerContainer,
						size === "small" && Styles.sizeSmall,
						classNameInnerContainer
					)}
				>
					{buttonType === "cross" && (
						<ButtonClose
							className={classNameCloseButton}
							onCloseClick={onCloseClick}
							buttonLabel={buttonLabel}
							closeTextId={closeTextId}
						/>
					)}
					<div
						className={classnames(
							"d-relative content-container absolute-top d-flex",
							Styles.contentCointainer,
							variant === "default" && "flex-column",
							variant === "action" && "justify-content-between flex-row",
							variant === "action" && Styles.variantActionContentCointainer,
							classNameContentContainer
						)}
					>
						{children}
					</div>
					{buttonType !== "cross" && (
						<ButtonClose
							className={classNameCloseButton}
							onCloseClick={onCloseClick}
							buttonLabel={buttonLabel}
							closeTextId={closeTextId}
						/>
					)}
				</div>
			</div>
		</Portal>
	);
};

export default LightBox;

const ButtonCloseCross = ({
	onCloseClick,
	buttonLabel,
	className,
	closeTextId,
}) => {
	return (
		<FFButton
			className={classnames(
				"button-close-cross align-self-end d-flex align-items-center",
				Styles.buttonClose,
				className
			)}
			onClick={onCloseClick}
		>
			{({ hover }) => (
				<React.Fragment>
					<Text
						className={classnames(
							"button-label-xs font-weight-bold text-uppercase mr-5"
						)}
						textId={buttonLabel || closeTextId}
						style={{
							color: hover ? getBootstrapColor("interaction") : "black",
						}}
					/>
					<div className="position-relative">
						<IconCross scale={1.5} className="absolute-top" />
						<IconCross
							scale={1.5}
							className={classnames(
								"position-relative",
								Styles.iconCross,
								hover && Styles.iconCrossHover
							)}
							color={
								hover
									? getBootstrapColor("interaction")
									: getBootstrapColor("primary")
							}
						/>
					</div>
				</React.Fragment>
			)}
		</FFButton>
	);
};

const ButtonCloseDefault = ({
	onCloseClick,
	buttonLabel,
	className,
	closeTextId,
}) => {
	return (
		<div
			className={classnames(
				"row justify-content-center",
				Styles.buttonClose,
				className
			)}
		>
			<FFButton
				onClick={onCloseClick}
				className={classnames(
					"btn border border-1 col-sm-6 border-gray rounded-pill btn-light animated fadeInUp text-uppercase"
				)}
			>
				<div className={classnames("d-flex w-100 justify-content-center")}>
					<Text
						className="font-weight-bold "
						textId={buttonLabel || closeTextId}
					/>
				</div>
			</FFButton>
		</div>
	);
};
