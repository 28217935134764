import React, { useEffect } from "react";
import _ from "lodash";
import { FFText, AudioUtils } from "funkis-foundation";
import QuizCard from "./core/QuizCard";
import { getBootstrapColor } from "../../../utils/colorUtils";
import Backend from "../../backend/Backend";
import QuizScoreItem from "./core/QuizScoreItem";
import { useSelector } from "react-redux";
import { getMembers } from "quiddity";

let currentResult;

const QuizHighScore = (props) => {
	const {
		show,
		contentHeight,
		contentMinHeight,
		currentQuidInput,
		textColor,
		baseColor = "primary",
		backgroundColor = "white",
		onTryAgainClick,
		onNextClick,
		ioId,
		ioContext,
		maxRecords = 10,
	} = props;
	const colorStyle = { color: getBootstrapColor(textColor || baseColor) };

	const apiUrl = useSelector((state) => {
		const quiddity = state.quiddity.item;
		return quiddity.url + quiddity.api;
	});
	const quidditySessionId = useSelector((state) =>
		_.get(state, "quiddity.item.session.id")
	);

	// Get all members for session...
	useEffect(() => {
		getMembers(apiUrl, {
			quidditySession: quidditySessionId,
			populate: "user",
		});
	});

	useEffect(() => {
		// Timeout to make sure that "quizInput" doesnt fade in the music onViewVisible
		if (show) {
			setTimeout(() => {
				AudioUtils.fade("quiz-music", {
					from: AudioUtils.get("quiz-music").volume(),
					to: 0,
				});
			}, 1000);
		}
	}, [show]);

	return (
		<QuizCard
			cardContext="highscore"
			contentClassName="w-100 d-flex justify-content-center quiz-highscore"
			contentHeight={contentHeight}
			contentMinHeight={contentMinHeight}
			show={show}
			delimitters
			onTryAgainClick={onTryAgainClick}
			onNextClick={onNextClick}
			baseColor={baseColor}
			backgroundColor={backgroundColor}
		>
			{() => (
				<div className="w-100 h-100  d-flex flex-column align-items-center justify-content-around">
					<FFText
						tagName="h2"
						className="text-center mt-5 font-weight-bold"
						textId="E91457BF-BAE4-47F6-A4A7-AFC969FE7AB8"
						style={colorStyle}
					/>
					<Backend
						className="w-100 h-100 p-md-5 p-1 d-flex flex-column justify-content-stretch"
						quidType="result"
						mode="outputs"
						inputContext={ioContext || "session"}
						targetDataKey={ioId}
						sort={({ ids, items }) => {
							const sortedItems = _.sortBy(items, "data.scoreRaw").reverse();
							const currentIndex = sortedItems.findIndex(
								(item) => item.id === currentQuidInput.id
							);
							// "save" current result...
							currentResult = { index: currentIndex };
							// slice the current result...
							const topTenIds = sortedItems
								.map((item) => item.id)
								.slice(0, maxRecords);
							if (currentIndex > maxRecords - 1) {
								topTenIds[maxRecords - 1] = sortedItems[currentIndex].id;
							}

							return topTenIds;
						}}
						renderOutputs={(renderProps) => {
							const { quidInput, data, index } = renderProps;
							const isCurrent = currentQuidInput.id === quidInput.id;

							return (
								<QuizScoreItem
									show={show}
									showDelay={1.2 + index * 0.1}
									index={
										isCurrent && currentResult ? currentResult.index : index
									}
									memberId={quidInput.member}
									score={data.scoreRaw}
									highlight={isCurrent}
									baseColor={baseColor || textColor}
								/>
							);
						}}
					/>
				</div>
			)}
		</QuizCard>
	);
};

export default QuizHighScore;
