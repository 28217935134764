import { useSelector, shallowEqual } from "react-redux";
import _ from "lodash";

const QuiddityUIBaseQuidInputs = (props) => {
	const { renderFunction } = props;
	const quidInputs = useSelector((state) => {
		const quidInputIds = _.get(props, "quidInputIds", []);
		const match = _.get(props, "match");
		const quidInputsById = state.quidInputs.itemsById;
		return quidInputIds.length
			? quidInputIds.map((id) => quidInputsById[id])
			: match
			? _.filter(state.quidInputs.items, match)
			: [];
	}, shallowEqual);

	return renderFunction({
		...props,
		quidInputs,
	});
};

export default QuiddityUIBaseQuidInputs;
