import classnames from "classnames";
import _ from "lodash";
import React from "react";
import Media, { MediaCrop, MediaFit } from "../../../components/core/Media";
import Text from "../../../components/core/Text";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";
import { getBootstrapColor } from "../../../utils/colorUtils";
import Style from "./PageItemFactBox.module.css";

const borderImage = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21 21" style="enable-background:new 0 0 21 21;" xml:space="preserve"> <circle cx="1.5" cy="1.5" r="1.5"/><circle cx="10.5" cy="1.5" r="1.5"/><circle cx="1.5" cy="10.5" r="1.5"/><circle cx="19.5" cy="1.5" r="1.5"/><circle cx="19.5" cy="19.5" r="1.5"/><circle cx="10.5" cy="19.5" r="1.5"/><circle cx="1.5" cy="19.5" r="1.5"/><circle cx="19.5" cy="10.5" r="1.5"/></svg>`;

function undefineEmptyValue<Type>(val: Type): Type | undefined {
	switch (typeof val) {
		case "number":
			return val <= 0 ? undefined : val;
		default:
			return _.isEmpty(val) ? undefined : val;
	}
}

type FactBoxPageItem = ScrollPageItem & {
	header: TextId;

	fact_box_header: TextId;
	fact_box_body: TextId;

	border_color: string;
	border_style: "dotted" | "custom_dotted" | "dashed" | "solid" | "none";
	border_width: number;

	content_bg_color: string;
	content_text_color: string;

	header_media: PageItemMedia;
	header_media_fit: MediaFit;
	header_media_alt: TextId;
	header_media_width: number;
	header_media_height: number;
	header_media_crop: MediaCrop;

	figure_media: PageItemMedia;
	figure_media_alt: TextId;
	figure_media_fit: MediaFit;
	figure_media_crop: MediaCrop;
	figure_media_width: number;
	figure_media_height: number;
	figure_media_caption: TextId;
};

const PageItemFactBox = (props: any) => {
	const { pageItem } = props;
	const factBoxPageItem: FactBoxPageItem = pageItem.cmtPageItem;

	const {
		border_color,
		border_style,
		border_width,
		fact_box_header,
		fact_box_body,
		figure_media,
		figure_media_alt,
		figure_media_fit,
		figure_media_crop,
		figure_media_caption,
		figure_media_width,
		figure_media_height,
		header,
		header_media,
		header_media_width,
		header_media_height,
		header_media_fit,
		header_media_crop,
	} = factBoxPageItem;

	const headerMediaSize = {
		width: undefineEmptyValue(header_media_width),
		height: undefineEmptyValue(header_media_height),
	};

	const figureMediaSize = {
		width: undefineEmptyValue(figure_media_width),
		height: undefineEmptyValue(figure_media_height),
	};

	let borderStyle: React.CSSProperties;

	switch (border_style) {
		case "solid":
		case "dashed":
		case "dotted":
			borderStyle = {
				borderStyle: border_style,
				borderWidth: border_width,
				borderColor: getBootstrapColor(border_color),
			};
			break;
		case "custom_dotted":
			borderStyle = {
				borderStyle: border_style,
				borderWidth: 10,
				borderColor: border_color,
				borderImage: `url('data:image/svg+xml;utf8,${borderImage}') 33% repeat`,
			};
			break;
	}

	return (
		<PageItemScrollBase
			className="container-fluid"
			pageItem={pageItem}
			renderFunction={(_renderProps: any) => (
				<>
					{header && (
						<div className="row m-0">
							<div className="col-sm-12">
								<Text
									tagName="h2"
									className={classnames("font-weight-bold", {
										// We add bottom margin to the fact box
										// if we don't have any header media so that
										// the elements are allowed to breath even
										// though they're not separated by an image.
										"mb-4": !header_media,
									})}
									textId={header}
								/>
							</div>
						</div>
					)}
					{header_media && (
						<div className="row m-0 mt-2">
							<Media
								className="col-sm-12"
								mediaCrop={header_media_crop}
								mediaFit={header_media_fit}
								src={header_media}
								width={headerMediaSize.width}
								height={headerMediaSize.height}
							/>
						</div>
					)}
					<div className="row m-0">
						<div className="col-sm-12">
							<div
								className="p-4 pb-6"
								style={{
									backgroundColor: getBootstrapColor(
										factBoxPageItem.content_bg_color
									),
									color: getBootstrapColor(factBoxPageItem.content_text_color),

									...borderStyle,
								}}
							>
								{fact_box_header && (
									<>
										<Text
											tagName="h3"
											className="p-md font-weight-bold"
											textId={fact_box_header}
										/>
										<hr className={classnames(Style.divider)} />
									</>
								)}

								<Text
									tagName="div"
									className={classnames(Style.factBoxBody, "col-sm-11 p-0")}
									textId={fact_box_body}
								/>

								{figure_media && (
									<figure>
										<Media
											className="pt-5"
											mediaCrop={figure_media_crop}
											mediaFit={figure_media_fit}
											src={figure_media}
											width={figureMediaSize.width}
											height={figureMediaSize.height}
											alt={figure_media_alt}
										/>

										{figure_media_caption && (
											<Text
												className="pt-2 font-italic"
												tagName="figcaption"
												textId={figure_media_caption}
											/>
										)}
									</figure>
								)}
							</div>
						</div>
					</div>
				</>
			)}
		/>
	);
};

export default PageItemFactBox;
