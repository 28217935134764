import _ from "lodash";
import { StoreUtils } from "funkis-foundation";
import { getQuidInputs, getQuid } from "quiddity";

export const getAppQuidInputs = async ({
	memberIds,
	fromRedux = false,
	query = {},
}) => {
	const quiddity = StoreUtils.getReducer("quiddity").item;
	const app = StoreUtils.getReducer("app").item;
	const quids = StoreUtils.getReducer("quids").items;
	const quidInputs = StoreUtils.getReducer("quidInputs").items;
	const quidditySession = _.get(quiddity, "session.id");
	const apiUrl = _.get(quiddity, "url") + _.get(quiddity, "api");
	const appId = _.get(app, "id");

	const appQuid =
		quids.find((quid) => _.matches(quid, { type: "app", template: true })) ||
		(await getQuid(apiUrl, { type: "app", template: true }));

	const reqQuery = {
		sourceQuid: appQuid.id,
		targetDataKey: appId,
		quidditySession,
		member: memberIds.length > 1 ? memberIds : memberIds[0],
		...query,
	};

	const quidInputFromRedux =
		(fromRedux && _.filter(quidInputs, reqQuery), undefined);
	const res = quidInputFromRedux || (await getQuidInputs(apiUrl, reqQuery));
	return res;
};
