import MissingListError from "errors/MissingListError";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { QuidType, useBackend } from "funkis-template/hooks/backend";
import { useValueList } from "funkis-template/hooks/valuelist";
import { RateContentArray } from "funkis-template/models/contentarray";
import { RateOutputScrollBlock } from "funkis-template/models/pageitem";
import { PageItem } from "funkis-template/models/player";
import { getBootstrapColor } from "funkis-template/utils/colorUtils";
import { FC } from "react";
import RateOutput from "./RateOutput";

const PageItemRateOutput: FC<{ pageItem: PageItem<RateOutputScrollBlock> }> = (
	props
) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;

	const contentArray: RateContentArray = useValueList(cmtPageItem.valuelist_id);

	if (
		contentArray?.values === undefined ||
		contentArray?.values?.length === 0
	) {
		throw new MissingListError(cmtPageItem.symbol_name);
	}

	const { quidInputs } = useBackend({
		targetDataKey:
			cmtPageItem.io_id !== "" ? cmtPageItem.io_id : cmtPageItem.id,
		inputContext: cmtPageItem.io_context,
		quidType: QuidType.Rate,
	});

	return (
		<PageItemScrollBase {...props} showNextButton={true}>
			<RateOutput
				contentArray={contentArray}
				quidInputs={quidInputs}
				bodyText={cmtPageItem.body}
				headingText={cmtPageItem.header}
				descriptionText={cmtPageItem.description}
				sliderMedia={cmtPageItem.slider_media}
				media={cmtPageItem.media}
				track={cmtPageItem.slider_fill_mode === "colored"}
				badgeTone={cmtPageItem.badge_tone}
				badgeStyle={cmtPageItem.badge_style}
				variationThresholdHigh={cmtPageItem.variation_threshold_high}
				variationThresholdMedium={cmtPageItem.variation_threshold_medium}
				showVariationLabel={cmtPageItem.show_deviation === "true"}
				showValueLabel={cmtPageItem.show_average_result === "true"}
				showInputCountLabel={cmtPageItem.show_number_of_inputs === "true"}
				sliderHandleColor={
					cmtPageItem.slider_handle_color !== ""
						? getBootstrapColor(cmtPageItem.slider_handle_color)
						: undefined
				}
				sliderRailColor={
					cmtPageItem.slider_track_color !== ""
						? getBootstrapColor(cmtPageItem.slider_track_color)
						: undefined
				}
			/>
		</PageItemScrollBase>
	);
};

export default PageItemRateOutput;
