import React from "react";

const IconPause = () => {
	return (
		<svg width="23px" height="24px" viewBox="0 0 23 24" version="1.1">
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="Group-6" transform="translate(-33.000000, -33.000000)">
					<g
						id="Group"
						transform="translate(33.871000, 33.000000)"
						fill="#FFFFFF"
					>
						<polygon id="Fill-4" points="0 24 10 24 10 0 0 0"></polygon>
						<polygon
							id="Fill-5"
							points="11.925 24 21.925 24 21.925 0 11.925 0"
						></polygon>
					</g>
				</g>
				<rect
					id="Rectangle-Copy-5"
					stroke="#CA0000"
					strokeWidth="11"
					x="-509"
					y="-865"
					width="1859"
					height="1692"
				></rect>
			</g>
		</svg>
	);
};

export default IconPause;
