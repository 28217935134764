import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	removeItemInStateItems,
} from "../utils/reducerUtils";

import {
	UPDATE_USERS,
	UPDATE_USER,
	REMOVE_USER,
} from "../constants/ActionConstants";

const users = (state = initialStateObjectCollection(), action) => {
	switch (action.type) {
		case UPDATE_USERS:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_USER:
			return updateItemInStateItems(state, action.item);
		case REMOVE_USER:
			return removeItemInStateItems(state, action.item);
		default:
			return state;
	}
};

export default users;
