import { Functions } from "funkis-foundation";

export const addFunction = (name, func, { override = false } = {}) => {
	Functions.addFunction(name, func, override);
	return func;
};

export const getFunction = (name) => {
	return Functions.getFunction(name);
};

export const render = (name, props = {}, component) => {
	const func = () => component;
	return (getFunction(name) || func)(props);
};
