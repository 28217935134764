import {
	initialStateObject,
	updateStateItem,
	removeValueInStateItem,
} from "../utils/reducerUtils";
import {
	UPDATE_QUIDDITY,
	UPDATE_QUIDDITY_VALUE,
	REMOVE_QUIDDITY_VALUE,
} from "../constants/ActionConstants";

const initialState = initialStateObject({
	requestStatus: undefined,
	requestMessage: undefined,
	fetchQuidsSucces: false,
	loggedIn: false,
	userLoggedIn: false,
	sessionLoggedIn: false,
	session: undefined,
	event: undefined,
	member: undefined,
	user: undefined,
});

const quiddity = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_QUIDDITY:
			return updateStateItem(state, action.item);
		case UPDATE_QUIDDITY_VALUE:
			return updateStateItem(state, action.item);
		case REMOVE_QUIDDITY_VALUE:
			return removeValueInStateItem(state, action.key);
		default:
			return state;
	}
};

export default quiddity;
