import React from "react";

const IconPerson = () => {
	return (
		<svg width="17px" height="24px" viewBox="0 0 17 24" version="1.1">
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="icons/person" transform="translate(-14.000000, -10.000000)">
					<path
						d="M25.7467,16.7021 L17.4317,16.7021 L16.3087,13.6961 L14.4557,14.3891 L16.0587,18.6811 L19.6117,18.6811 L19.6117,33.9701 L21.9757,33.9701 L23.3827,33.9701 L25.7467,33.9701 L25.7467,19.7841 L29.0267,23.6931 L30.5447,22.4201 L25.7467,16.7021 Z M25.0217,12.9761 C25.0217,14.2701 23.9737,15.3191 22.6797,15.3191 C21.3857,15.3191 20.3367,14.2701 20.3367,12.9761 C20.3367,11.6821 21.3857,10.6341 22.6797,10.6341 C23.9737,10.6341 25.0217,11.6821 25.0217,12.9761 L25.0217,12.9761 Z"
						id="Fill-2"
						fill="#FFFFFF"
					></path>
				</g>
			</g>
		</svg>
	);
};

export default IconPerson;
