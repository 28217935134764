import React from "react";
import { connect } from "../../../utils/reduxUtils";
import classnames from "classnames";
import {
	ReduxCollectionSelectors,
	FFButton,
	LocationSelectors,
	StoreUtils,
} from "funkis-foundation";
import { updateLocation } from "../../../actions/Actions";
import { getText } from "../../../utils/textUtils";
import SideMenuPageButton from "./SideMenuPageButton";
import { getBootstrapColor } from "../../../utils/colorUtils";
import { isPageInLocation } from "../../../utils/locationUtils";

const { makeGetItemByIdSelector, makeGetItemIdsSelector } =
	ReduxCollectionSelectors;
const { makeGetLocationSelector } = LocationSelectors;

const SideMenuChapterButton = (props) => {
	const {
		page,
		index,
		pageIds,
		onClick,
		onPageClick,
		location,
		className = "",
	} = props;
	const { cmtPage } = page.data;
	const app = StoreUtils.getReducer("app").item;
	const enabled =
		(pageIds.length && page.status !== "not_attempted") || index === 0;
	const chapterIdentifier = getText(cmtPage.chapter_identifier) || ""; // || index + 1 + ".";
	const isCurrentPage = isPageInLocation({ pageId: page.id, location });
	const highLight = isCurrentPage && pageIds.length < 2;

	return (
		<React.Fragment>
			<FFButton
				enabled={enabled}
				className={classnames(
					className,
					"pl-2 py-3 pr-3",
					`chapter-status-${page.status}`
				)}
				style={{ backgroundColor: highLight ? "white" : "#ECECEC" }}
				onClick={() => {
					updateLocation({ pageId: page.id, path: [{ child: 0 }] });
					onClick && onClick(props);
				}}
			>
				{({ hover }) => {
					return (
						<h4
							className={classnames("font-weight-bold d-block")}
							style={{
								color: getBootstrapColor((hover && "interaction") || "black"),
								opacity: enabled ? 1 : 0.25,
							}}
						>
							{chapterIdentifier + " " + getText(cmtPage.title_txt_id)}
						</h4>
					);
				}}
			</FFButton>

			{app.reveal_menu_chapters !== "off" && pageIds.length > 1 ? (
				<>
					{pageIds.map((id, i) => (
						<SideMenuPageButton
							key={`SideMenuPageButton-${id}`}
							id={id}
							index={i}
							chapterIndex={index}
							onClick={onPageClick}
							isChapterEnabled={enabled}
						/>
					))}
				</>
			) : undefined}
		</React.Fragment>
	);
};

const makeMapStateToProps = () => {
	const getItemByIdSelector = makeGetItemByIdSelector();
	const getItemIdsSelector = makeGetItemIdsSelector();
	const getLocationSelector = makeGetLocationSelector();
	const mapStateToProps = (state, props) => {
		return {
			page: getItemByIdSelector(state, props, "pages"),
			pageIds: getItemIdsSelector(
				state,
				{ ...props, match: { parentPageId: props.id } },
				"pages"
			),
			location: getLocationSelector(state, props),
		};
	};
	return mapStateToProps;
};

export default connect(SideMenuChapterButton, makeMapStateToProps);
