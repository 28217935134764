import {
	initialStateObject,
	updateStateItem,
	removeValueInStateItem,
	makeStateSnapshot,
	restoreStateSnapshot,
} from "../utils/reducerUtils";
import {
	UPDATE_APP,
	UPDATE_APP_VALUE,
	REMOVE_APP_VALUE,
	MAKE_APP_SNAPSHOT,
	RESTORE_FROM_APP_SNAPSHOT,
} from "../constants/ActionConstants";
import * as StatusConstants from "../../constants/StatusConstants";
import { REQUEST_IDLE } from "../../constants/RequestConstants";

const initialState = initialStateObject({
	pageContainer: {},
	size: {},
	courseStatus: StatusConstants.NOT_ATTEMPTED,
	completionStatus: StatusConstants.NOT_ATTEMPTED,
	successStatus: StatusConstants.UNKNOWN,
	rawScore: 0,
	minScore: 0,
	maxScore: 100,
	requestStatus: REQUEST_IDLE,
	requestMessage: "",
	inspector: { open: false, tabIndex: 0 },
	userName: null,
	userId: null,
});

const app = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_APP:
		case UPDATE_APP_VALUE:
			return updateStateItem(state, action.item);
		case REMOVE_APP_VALUE:
			return removeValueInStateItem(state, action.item);
		case MAKE_APP_SNAPSHOT:
			return makeStateSnapshot(state, action.item);
		case RESTORE_FROM_APP_SNAPSHOT:
			return restoreStateSnapshot(state, action.item);
		default:
			return state;
	}
};

export default app;
