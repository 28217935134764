import React from "react";

const IconTarget = ({ className, style, color = "#00005A" }) => {
	return (
		<svg
			className={className}
			style={style}
			width="24px"
			height="24px"
			viewBox="0 0 24 24"
			version="1.1"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="Group-3" transform="translate(0.000000, 0.000300)">
					<g id="Clip-2"></g>
					<path
						d="M12,15.695 C14.041,15.695 15.695,14.04 15.695,12 C15.695,9.959 14.041,8.304 12,8.304 C9.959,8.304 8.305,9.959 8.305,12 C8.305,14.04 9.959,15.695 12,15.695 M12,19.965 C16.399,19.965 19.966,16.399 19.966,12 C19.966,7.6 16.399,4.034 12,4.034 C7.601,4.034 4.034,7.6 4.034,12 C4.034,16.399 7.601,19.965 12,19.965 M0,12 C0,5.372 5.373,0 12,0 C18.627,0 24,5.372 24,12 C24,18.627 18.627,24 12,24 C5.373,24 0,18.627 0,12"
						id="Fill-1"
						fill={color}
					></path>
				</g>
			</g>
		</svg>
	);
};

export default IconTarget;
