let _functions = {};

export function addFunction(functionSignature, func, override = true) {
	if (!override && getFunction(functionSignature)) {
		window.devMode &&
			console.warn(
				`Function with signature "${functionSignature}" already exists!`
			);
		return;
	} else {
		_functions[functionSignature] = func;
	}
}

export function getFunction(functionSignature) {
	return _functions[functionSignature];
}
