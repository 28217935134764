import React, { useState } from "react";
import classnames from "classnames";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import Text from "../../components/core/Text";
import IOList from "../../components/scroll/IOListsInputSortable";

import Styles from "./PageItemIOListsInput.module.css";

const PageItemIOListsInput = (props) => {
	const { pageItem } = props;
	const { cmtPageItem = {} } = pageItem;
	const contentArray = cmtPageItem.content_array || [];
	const { header, body, theme, all_lists_are_mandatory } = cmtPageItem;

	const [listsStatus, setListsStatus] = useState(
		contentArray.map(() => "incomplete")
	);
	const [completed, setCompleted] = useState(
		listsStatus.every((status) => status === "completed")
	);

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={all_lists_are_mandatory === "no" || completed}
			renderFunction={(renderProps) => (
				<div
					className={classnames(
						"row m-0 align-items-stretch",
						theme === "default" && "no-gutters"
					)}
				>
					{/* HEADER */}
					<Text
						className={classnames(
							"col-sm-12 mb-5 font-weight-bold animated",
							renderProps.viewVisible ? "fadeIn" : "fadeOut"
						)}
						tagName="h2"
						textId={header}
					/>
					{/* BODY */}
					<Text
						className={classnames(
							"col-sm-12 mb-4 animated",
							renderProps.viewVisible ? "dealy-250ms fadeIn" : "fadeOut"
						)}
						tagName="p"
						textId={body}
					/>
					{contentArray.map((content, index) => (
						<div
							className={classnames(
								"position-relative",
								theme === "default" && "col-md-6 m-0",
								theme === "mustach" && "col-sm-12"
							)}
						>
							<IOList
								{...props}
								{...renderProps}
								className={classnames(
									"position-relative",
									theme === "default" && "p-4 pb-6"
								)}
								theme={cmtPageItem.theme}
								color={content.color || cmtPageItem.color}
								header={content.header}
								headerMustach={content.header_mustach}
								body={content.body}
								buttonLabel={content.button_label}
								targetDataKey={content.io_id || content.id}
								onStatusChange={(status) => {
									listsStatus[index] = status;
									setListsStatus(listsStatus.concat());
								}}
							></IOList>
							<div className={Styles.highlight} />
						</div>
					))}
				</div>
			)}
		/>
	);
};

export default PageItemIOListsInput;
