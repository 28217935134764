import _ from "lodash";
import * as StatusConstants from "../constants/StatusConstants";
import * as StoreUtils from "../utils/StoreUtils";
import * as MockScormConstants from "./Scorm2004Constants";

const getConfigObject = () => {
	const config = StoreUtils.getReducer("config");
	return config.item;
};

export default class NoStorage {
	save(name, domain, data) {
		this.setData(name, domain, data);
	}

	restore(name, domain, restoreFunction) {
		const data = this.getData(domain);
		restoreFunction(data[name]);
	}

	getData(_domain) {
		return {};
	}

	setData(_name, _domain, _data) {}

	clearData(_domain) {}

	setCourseStatus(_domain, _value) {}

	getCourseStatus(_domain) {
		return StatusConstants.NOT_ATTEMPTED;
	}

	setCompletionStatus(_domain, _value) {}

	getCompletionStatus(_domain) {
		return StatusConstants.NOT_ATTEMPTED;
	}

	setSuccessStatus(_domain, _value) {}

	getSuccessStatus(_domain) {
		return StatusConstants.PASSED;
	}

	setScore(_domain, _rawScore, _minScore, _maxScore) {}

	getScore(_domain) {
		return 0;
	}

	getUserName(_domain) {
		return "anonymous";
	}

	getUserId(_domain) {
		return "anonymous";
	}

	getQuiddityTitleId() {
		const config = getConfigObject();
		const quiddityTitleId = _.get(
			config,
			["lms", MockScormConstants.QUIDDITY_TITLE_ID],
			"no title id found"
		);

		console.info(
			`NoStorage: getQuiddityTitleId(). Quiddity Title ID:`,
			quiddityTitleId
		);

		return quiddityTitleId;
	}

	getQuiddityTitleOrganizationId() {
		const config = getConfigObject();
		const quiddityTitleOrganizationId = _.get(
			config,
			["lms", MockScormConstants.QUIDDITY_TITLE_ORGANIZATION_ID],
			"no title organization id found"
		);

		console.info(
			`NoStorage: getQuiddityTitleOrganizationId(). Quiddity Title Organization ID:`,
			quiddityTitleOrganizationId
		);

		return quiddityTitleOrganizationId;
	}

	getQuiddityTitleName() {
		const config = getConfigObject();
		const quiddityTitleName = _.get(
			config,
			["lms", MockScormConstants.QUIDDITY_TITLE_NAME],
			"no title name found"
		);

		console.info(
			`NoStorage: getQuiddityTitleName(). Quiddity Title name:`,
			quiddityTitleName
		);

		return quiddityTitleName;
	}
}
