import React, { lazy, Suspense, useEffect, useRef } from "react";
import fatThemes from "./fat_themes.json";
import { composeCSSVariableName, TitleThemeVariables } from "./Theme.utils";
import ScrollModules from "../modules/ScrollModules_v2";
import useBodyClassName from "funkis-template/hooks/useBodyClassName";

type ThemeProps = {
	theme: string;
	programType: "slide" | "scroll";
	titleThemeVariables?: TitleThemeVariables;
};

const Theme: React.FC<ThemeProps> = ({
	children,
	theme,
	programType,
	titleThemeVariables,
}) => {
	const templateVersion = "2";

	useBodyClassName(`theme-${theme} program-type-${programType}`);

	useEffect(() => {
		/**
		First we apply the theme colors for the chosen theme
		**/
		const themeObject = fatThemes.find(
			(themeObj) => themeObj.theme_identifier === theme
		);

		if (themeObject && themeObject.colors) {
			Object.entries(themeObject.colors).forEach(([key, value]) => {
				document.documentElement.style.setProperty(
					composeCSSVariableName(key),
					value
				);
			});
		}

		/**
		And then, we override the colors with the colors that 
		have been specified in the title
		**/
		if (titleThemeVariables) {
			Object.entries(titleThemeVariables).forEach(([key, value]) => {
				document.documentElement.style.setProperty(
					composeCSSVariableName(key),
					value
				);
			});
		}
	}, [theme, titleThemeVariables]);

	const ProgramModules =
		programType === "scroll"
			? ScrollModules
			: lazy(() => import("../modules/SlideModules"));

	const RenderFunctions = lazy(() =>
		import(`../render-functions/themes/${theme}/RenderFunctions.js`).catch(
			(err) => import(`../render-functions/themes/default/RenderFunctions.js`)
		)
	);

	const DefaultCSSComponent = lazy(
		() => import(`../assets/BootstrapDefault_v${templateVersion}.js`)
	);

	// Load theme css
	const BootstrapCSS =
		(theme === "ikea" &&
			lazy(() => import(`../assets/BootstrapIKEA_v${templateVersion}.js`))) ||
		(theme === "ikea-vbs" &&
			lazy(
				() => import(`../assets/BootstrapIKEAVBS_v${templateVersion}.js`)
			)) ||
		(theme === "woodfirst" &&
			lazy(
				() => import(`../assets/BootstrapWoodfirst_v${templateVersion}.js`)
			)) ||
		(theme === "getinge" &&
			lazy(
				() => import(`../assets/BootstrapGetinge_v${templateVersion}.js`)
			)) ||
		(theme === "nka" &&
			lazy(() => import(`../assets/BootstrapNKA_v${templateVersion}.js`))) ||
		(theme === "volvo-trucks" &&
			lazy(
				() => import(`../assets/BootstrapVolvoTrucks_v${templateVersion}.js`)
			)) ||
		(theme === "volvo-group" &&
			lazy(
				() => import(`../assets/BootstrapVolvoGroup_v${templateVersion}.js`)
			)) ||
		(theme === "volvo-penta" &&
			lazy(
				() => import(`../assets/BootstrapVolvoPenta_v${templateVersion}.js`)
			)) ||
		(theme === "renault" &&
			lazy(
				() => import(`../assets/BootstrapRenault_v${templateVersion}.js`)
			)) ||
		(theme === "ordrum" &&
			lazy(() => import(`../assets/BootstrapOrdrum_v${templateVersion}.js`))) ||
		(theme === "takeda" &&
			lazy(() => import(`../assets/BootstrapTakeda_v${templateVersion}.js`))) ||
		(theme === "hmcsverige" &&
			lazy(
				() => import(`../assets/BootstrapHmcSverige_v${templateVersion}.js`)
			)) ||
		(theme === "getinge2021" &&
			lazy(
				() => import(`../assets/BootstrapGetinge2021_v${templateVersion}.js`)
			)) ||
		(theme === "navigating-the-techstorm" &&
			lazy(() => import(`../assets/BootstrapNavigatingTheTechstorm_v2.js`))) ||
		(theme === "minimal" &&
			lazy(() => import(`../assets/BootstrapMinimal_v2.js`))) ||
		(theme === "silent-killers" &&
			lazy(() => import(`../assets/BootstrapSilentKillers_v2.js`))) ||
		(theme === "default" &&
			lazy(
				() => import(`../assets/BootstrapDefault_v${templateVersion}.js`)
			)) ||
		lazy(() => {
			console.warn(
				`Theme "${theme}" is not supported. Funkis player will load default theme.`
			);
			return import(`../assets/BootstrapDefault_v${templateVersion}.js`);
		});

	return (
		<Suspense fallback={<div>Loading theme…</div>}>
			<ProgramModules>
				<RenderFunctions />
				<DefaultCSSComponent />
				<BootstrapCSS />
				{children}
			</ProgramModules>
		</Suspense>
	);
};

export default Theme;
