import React from "react";
import _ from "lodash";
import { FFMultiSelect, PageItemActions } from "funkis-foundation";
import Backend from "../backend/Backend";

const { updatePageItem } = PageItemActions;

const IOMultiSelect = ({
	pageItem,
	minSelections,
	maxSelections,
	onSelect,
	optionRender,
	className,
	backendClassName,
	buttonClassName,
	enabled = true,
	autoDeselectWhenMaxSelectionsReached = false,
	disableDeselect = false,
	alwaysTriggerOnSelect = true,
	toggle = true,
}) => {
	const { cmtPageItem = {} } = pageItem;
	const options = cmtPageItem.content_array;
	const correctIndexes = options
		.map((option, index) => option.correct && index)
		.filter((val) => val !== false);

	const completed = pageItem.status === "completed";
	const isEnabled = enabled && !completed;
	const ioID = cmtPageItem.valuelist_id || cmtPageItem.io_id || cmtPageItem.id;

	return (
		<Backend
			quidType="multi-select"
			mode="input"
			targetDataKey={ioID}
			className={backendClassName}
			renderInput={(renderProps) => {
				const { data, save } = renderProps;
				const selectedIndexes = data.selectedIndexes || [];
				const isCorrect = _.isEqual(
					[...selectedIndexes].sort(),
					[...correctIndexes].sort()
				);
				return (
					<FFMultiSelect
						className={className}
						enabled={isEnabled}
						buttonClassName={buttonClassName}
						isCorrect={isCorrect}
						toggle={toggle}
						maxSelections={
							(correctIndexes.length === 1 && 1) ||
							maxSelections ||
							Number.MAX_VALUE
						}
						minSelections={minSelections || 1}
						selectedIndexes={selectedIndexes}
						disableWhenMaxSelectionsReached={false}
						disableDeselect={disableDeselect}
						alwaysTriggerOnSelect={alwaysTriggerOnSelect}
						autoDeselectWhenMaxSelectionsReached={
							correctIndexes.length === 1 ||
							autoDeselectWhenMaxSelectionsReached
						}
						onSelect={(indexes, index) => {
							save(false, { selectedIndexes: indexes });
							// only used to keep track of status completed
							updatePageItem(pageItem.id, {
								selectedIndexes: indexes,
							});

							onSelect &&
								onSelect({
									selectedIndexes: indexes,
									selectedIndex: index,
									option: indexes.length === 1 && options[indexes[0]],
									isCorrect,
									completed,
								});
						}}
					>
						{options.map((option, index) =>
							optionRender({
								index,
								option,
								selected: selectedIndexes.includes(index),
								selectedIndexes,
								completed,
								isCorrect,
								enabled: isEnabled,
							})
						)}
					</FFMultiSelect>
				);
			}}
		/>
	);
};

export default IOMultiSelect;
