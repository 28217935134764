import React from "react";
import classnames from "classnames";
import { connect } from "../../utils/reduxUtils";
import { ReduxCollectionSelectors } from "funkis-foundation";
import _ from "lodash";
import RadioItem from "../../components/core/RadioItem";
import Hover from "../../components/core/Hover";
import IconGroupOfMembers from "../../assets/svg/IconGroupOfMembers";
import { getBootstrapColor } from "../../utils/colorUtils";
import FormattedDate from "components/FormattedDate/FormattedDate";

const { makeGetItemByIdSelector } = ReduxCollectionSelectors;

const GroupOrUnitListItem = ({ group, unit, id, selected }) => {
	const name = _.get(unit, "nodeId") || _.get(group, "name");
	const date =
		_.get(group || unit, "updatedAt") || _.get(group || unit, "createdAt");

	return (
		<Hover
			className="w-100 row m-0 align-items-stretch"
			style={{ maxHeight: "74px" }}
		>
			{({ hover }) => {
				const color = getBootstrapColor(hover ? "interaction" : "white");
				return (
					<React.Fragment>
						<div className="col-2 selected d-flex align-items-center">
							<RadioItem
								selected={selected}
								forceHover={hover}
								className="mr-1"
							/>
							<IconGroupOfMembers style={{ height: "48px", width: "100px" }} />
							{/* </div> */}
						</div>
						<div className="col-5 d-flex align-items-center">
							<span
								style={{ color }}
								className={classnames(
									"message-xs d-block group-name",
									selected && "font-weight-bold"
								)}
							>
								{name}
							</span>
						</div>
						<div className="col-2" />
						<div className="col-3 d-flex align-items-center justify-content-end">
							<span
								style={{ color }}
								className={classnames(
									"message-xs d-block date",
									selected && "font-weight-bold"
								)}
							>
								{date && <FormattedDate date={date} />}
							</span>
						</div>
					</React.Fragment>
				);
			}}
		</Hover>
	);
};

const makeMapStateToProps = () => {
	const getItemByIdSelector = makeGetItemByIdSelector();
	const mapStateToProps = (state, props) => {
		return {
			group: getItemByIdSelector(state, props, "groups"),
			unit: getItemByIdSelector(state, props, "units"),
		};
	};
	return mapStateToProps;
};

export default connect(GroupOrUnitListItem, makeMapStateToProps);
