import React from "react";
import classnames from "classnames";
import Text from "../core/Text";
import ImageVideoOrDiv from "../core/ImageVideoOrDiv";
import Styles from "./CuepointText.module.css";
import { getBootstrapColor } from "../../utils/colorUtils";

const CuepointText = ({
	headerTextId,
	bodyTextId,
	headerAlign = "left",
	bodyAlign = "left",
	headerColor = "black",
	bodyColor = "black",
	headerMargin = "",
	height = "auto",
	textContainerWidth = "auto",
	textContainerPadding = "",
	textContainerColor = "black",
	textContainerOpacity = 0,
	media,
	className,
	classNameHeader = "",
	classNameBody = "",
	classNameTextContainer = "",
	left,
	top,
	children,
}) => {
	return (
		//  OUTER CONTAINER
		<div
			className={classnames("cuepoint-text", Styles.outerContainer, className)}
			style={{ height, left: `${left}%`, top: `${top}%` }}
		>
			{/* INNER CONTAINER */}
			<div className={classnames("inner-container", Styles.innerContainer)}>
				{/* BACKGROUND COLOR */}
				<ImageVideoOrDiv
					className={classnames(
						"outer-container absolute-top",
						media ? "media-cuepoint" : "w-100 h-100"
					)}
					imageClassName=""
					style={{
						opacity: media ? 1 : textContainerOpacity,
						backgroundColor: getBootstrapColor(textContainerColor),
					}}
					src={media}
				/>

				{/* TEXT CONTAINER */}

				<div
					className={classnames(
						"text-container px-3 pt-3 pb-1",
						Styles.textContainer,
						classNameTextContainer
					)}
					style={{ width: textContainerWidth, padding: textContainerPadding }}
				>
					{/* HEADER */}
					<Text
						tagName="h3"
						textId={headerTextId}
						className={classnames(
							`text-${headerAlign} d-block font-weight-bold`,
							classNameHeader
						)}
						style={{
							margin: headerMargin,
							color: getBootstrapColor(headerColor),
						}}
					/>
					{/* BODY */}
					<Text
						tagName="p"
						textId={bodyTextId}
						className={classnames(`text-${bodyAlign} d-block`, classNameBody)}
						style={{ color: getBootstrapColor(bodyColor) }}
					/>
					{children}
				</div>
			</div>
		</div>
	);
};

export default CuepointText;
