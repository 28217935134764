import React from "react";

const SquareBubbleArrow = ({
	fillColor = "#FFFFFF",
	strokeColor = "#000000",
	className,
	style,
}) => {
	return (
		<svg
			className={className}
			style={style}
			width="14px"
			height="12px"
			viewBox="0 0 14 12"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill={fillColor}
				fillRule="evenodd"
			>
				<g
					id="Stroke-1"
					transform="translate(1.000000, 0.000000)"
					stroke={strokeColor}
				>
					<polyline points="12 0 0 12 0 0"></polyline>
				</g>
			</g>
		</svg>
	);
};

export default SquareBubbleArrow;
