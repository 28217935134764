import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { makeGetPagesByLocationPath } from "../../selectors/PageSelectors";
import { makeGetLocationSelector } from "../../selectors/LocationSelectors";
import Page from "./Page";

class Pages extends React.Component {
	static propTypes = {
		parentPageId: PropTypes.string,
		layer: PropTypes.string.isRequired,
		pages: PropTypes.array.isRequired, // from connect
		locationPath: PropTypes.array.isRequired,
	};

	render() {
		const { pages, location, locationPath } = this.props;
		return (
			<React.Fragment>
				{pages.map((page) => {
					const locationPathUpdate = locationPath.concat();
					locationPathUpdate.push(page.id);
					return (
						<Page
							key={`page-${page.id}`}
							page={page}
							locationPath={locationPathUpdate}
							location={location}
						/>
					);
				})}
			</React.Fragment>
		);
	}
}

const makeMapStateToProps = () => {
	const getPagesByLocationPath = makeGetPagesByLocationPath();
	const getLocation = makeGetLocationSelector();
	const mapStateToProps = (state, props) => {
		return {
			pages: getPagesByLocationPath(state, props),
			location: getLocation(state, props),
		};
	};
	return mapStateToProps;
};

export default connect(makeMapStateToProps)(Pages);
