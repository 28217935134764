import React from "react";
import _ from "lodash";
import QuizCard from "./core/QuizCard";
import Text from "../../core/Text";
import { getText } from "../../../utils/textUtils";
import { getBootstrapColor } from "../../../utils/colorUtils";

const QuizSummary = (props) => {
	const {
		currentRound,
		show,
		contentHeight,
		contentMinHeight,
		header,
		baseColor,
		backgroundColor = "white",
		textIdLabelPreviousButton = "AE4BC80D-DB9F-4967-A493-227293119794",
		textIdLabelDoneButton = "5D970C1E-6FFD-4233-9FB7-A25FF9C43479",
		onPreviousClick,
		onDoneClick,
	} = props;
	const answers = _.valuesIn(props.answers).filter((answer) =>
		currentRound.includes(answer.questionIndex)
	);
	const answersSorted = _.sortBy(answers, "questionNum");
	const textColor = getBootstrapColor(baseColor);

	return (
		<QuizCard
			show={show}
			cardContext="summary"
			delimitters
			contentHeight={contentHeight}
			contentMinHeight={contentMinHeight}
			baseColor={baseColor}
			backgroundColor={backgroundColor}
			textIdLabelTryAgainButton={textIdLabelPreviousButton}
			textIdLabelNextButton={textIdLabelDoneButton}
			onTryAgainClick={onPreviousClick}
			onNextClick={onDoneClick}
		>
			<div className="w-100 h-100 d-flex flex-column justify-content-stretch mb-3">
				<div className="row justify-content-center flex-grow-0">
					<Text
						className="quiz-message-header text-center font-weight-bold mt-3"
						textId={
							getText(header) ? header : "86C80D74-3636-48C9-998B-D8E00C07B40B"
						}
						style={{ color: textColor }}
					/>
				</div>
				<div
					className="w-100 flex-grow-1 overflow-y-auto my-2 mb-3"
					style={{ maxHeight: "360px" }}
				>
					<div className="px-md-5 px-1  text-dark-gray">
						{answersSorted.map((answer, index) => {
							return (
								<div key={index} className="border-top py-2 row m-0 ">
									{/* QUESTION */}
									<div className="col-sm-9 font-weight-bold">
										<Text textId="5B745E7A-89EB-4CD1-B560-9A92C4179D06" />
										<span>{` #${index + 1}`}</span>
									</div>
									<p
										className="col-sm-9"
										dangerouslySetInnerHTML={{ __html: answer.question }}
									/>
									<br />
									<br />
									{/* CORRECT ANSWER */}
									<Text
										className="font-weight-bold col-sm-9"
										textId="C39AF3E9-F91D-4BF9-93DA-6E540956D054"
									/>
									<p
										className="col-sm-9"
										dangerouslySetInnerHTML={{ __html: answer.correctOption }}
									/>
									{/* YOUR ANSWER */}
									<Text
										className="font-weight-bold col-sm-9"
										textId="A90F64FB-645F-41B2-AAA4-F26B808E2522"
									/>
									<p className="col-sm-9">{answer.answer}</p>
									{/* SCORE */}

									<p
										className="col-sm-9 font-weight-bold"
										style={{
											color: getBootstrapColor(
												answer.points > 0 ? "success" : "fail"
											),
										}}
									>
										{`${getText("6CE9DCDE-F522-470C-961B-D47DE4B5E422")} +${
											answer.points
										}`}
									</p>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</QuizCard>
	);
};

// Question: 5B745E7A-89EB-4CD1-B560-9A92C4179D06
// Correct answer: C39AF3E9-F91D-4BF9-93DA-6E540956D054
// Your answer: A90F64FB-645F-41B2-AAA4-F26B808E2522
// Score: 6CE9DCDE-F522-470C-961B-D47DE4B5E422
// Correct answers: 86C80D74-3636-48C9-998B-D8E00C07B40B

export default QuizSummary;
