import React, { useEffect } from "react";
import _ from "lodash";
import { connect } from "../../utils/reduxUtils";
import { ReduxCollectionSelectors, StoreUtils } from "funkis-foundation";
import { getUnits } from "quiddity";
import StatusIcon from "./../core/StatusIcon";
import ParticipantIcon from "../../assets/svg/Participant";
import { getBootstrapColor } from "../../utils/colorUtils";
import Backend from "../backend/Backend";
import { useSelector } from "react-redux";
import classnames from "classnames";

const { makeGetItemByIdSelector } = ReduxCollectionSelectors;

const MemeberListItem = ({ member, unitId, unit }) => {
	const preparatoryAppId = useSelector(
		(state) =>
			_.get(state, "app.item.preparatory_package_id") ||
			_.get(state, "app.item.preparatory_app_id")
	);

	console.log();

	const firstName =
		_.get(member, "firstName") || _.get(member, "user.firstName");
	const lastName = _.get(member, "lastName") || _.get(member, "user.lastName");
	const name = firstName + " " + lastName;
	const email = _.get(member, "user.email", "");
	const status = _.get(member, "status");
	const avatarUrl = _.get(member, "user.avatarUrl");
	const isGroupLeader = _.get(member, "properties.isGroupLeader");

	useEffect(() => {
		if (unitId && !unit) {
			const { url, api, session } = StoreUtils.getReducer("quiddity").item;
			getUnits(url + api, {
				quidditySession: session && session.id,
				id: unitId,
			});
		}
	}, [unitId]);

	return (
		<div className="d-flex py-1 justify-content-between positions-relative w-100 text-white">
			<div className="d-flex">
				{/* AVATAR */}
				{avatarUrl ? (
					<img src={avatarUrl} width="50px" height="50px" className="mr-1" />
				) : (
					<div
						style={{ width: "50px", height: "50px" }}
						className={classnames(
							"mr-1 d-flex justify-content-center align-items-center border ",
							isGroupLeader ? "border-interaction" : "border-gray"
						)}
					>
						<ParticipantIcon />
					</div>
				)}
				{/* NAME & ROLE */}
				<div className="d-flex flex-column justify-content-center">
					<span className="d-block member-name p-medium">{name}</span>
					<div className="d-flex member-role text-gray">
						<span
							className="font-italic d-block"
							style={{ width: "120px" }}
						>{`${email}`}</span>
					</div>
				</div>
			</div>
			{/* PROGRESS */}
			<Backend
				quidType="app"
				mode="input"
				targetDataKey={preparatoryAppId}
				renderInput={(renderProps) => {
					const { data } = renderProps;
					return (
						<StatusIcon
							status={_.get(data, "successStatus")}
							color={getBootstrapColor("primary")}
						/>
					);
				}}
			/>
		</div>
	);
};

const makeMapStateToProps = () => {
	const getItemByIdSelector = makeGetItemByIdSelector();
	const getGroupItemByIdSelector = makeGetItemByIdSelector();
	const mapStateToProps = (state, props) => {
		const member = getItemByIdSelector(state, props, "members");
		const group = getGroupItemByIdSelector(
			state,
			{ id: member.group },
			"groups"
		);
		const units = _.get(member, "user.units") || [];
		const unitId = units[0];

		return {
			member,
			unitId,
			unit: getItemByIdSelector(state, { id: unitId }, "units"),
			group,
		};
	};
	return mapStateToProps;
};

export default connect(MemeberListItem, makeMapStateToProps);
