import React from "react";
import classnames from "classnames";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import IOListsOutput from "../../components/scroll/IOListsOutput";
import Backend from "../../components/backend/Backend";
import Text from "../../components/core/Text";
import { getBootstrapColor } from "../../utils/colorUtils";
import { getText } from "../../utils/textUtils";

const PageItemIOListsOutput = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;
	const contentArray = cmtPageItem.content_array || [];
	const ioID = cmtPageItem.io_id;

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={true}
			renderFunction={(renderProps) => {
				return (
					<React.Fragment>
						<div className={classnames("row m-0")}>
							{/* HEADER */}
							<Text
								className={classnames(
									"col-sm-12 mb-5 font-weight-bold animated",
									renderProps.viewVisible ? "fadeIn" : "fadeOut"
								)}
								tagName="h2"
								textId={cmtPageItem.header}
							/>
							{/* BODY */}
							<Text
								className={classnames(
									"col-sm-12 mb-4 animated",
									renderProps.viewVisible ? "dealy-250ms fadeIn" : "fadeOut"
								)}
								tagName="p"
								textId={cmtPageItem.body}
							/>
						</div>
						<Backend
							{...props}
							className="row m-0"
							quidType="comment"
							mode="outputs"
							inputContext={cmtPageItem.io_context}
							targetDataKey={ioID}
							match={{ targetDataKey: ioID }}
							renderOutputs={(backendRenderProps) => {
								return (
									<IOListsOutput
										{...backendRenderProps}
										className="col-md-4"
										backgroundColor={cmtPageItem.comment_background_color}
										textColor={cmtPageItem.comment_text_color}
										barColor={cmtPageItem.comment_bar_color}
										showUser={cmtPageItem.show_comment_author !== "no"}
										showDate={cmtPageItem.show_comment_date !== "no"}
									/>
								);
							}}
						/>
						{/* CONTENT ARRAY */}
						<div>
							{contentArray.map((content, index) => {
								return (
									<div
										className="row m-0 mb-2 py-2"
										style={{
											borderBottom: `1px solid ${getBootstrapColor(
												getText(content.comment_background_color) ||
													"light-gray"
											)}`,
										}}
									>
										{/* HEADER */}
										<Text
											className={classnames(
												"col-sm-12 h3-sm mb-5 font-weight-bold animated",
												renderProps.viewVisible ? "fadeIn" : "fadeOut"
											)}
											tagName="h3"
											textId={content.header}
										/>
										{/* BODY */}
										<Text
											className={classnames(
												"col-sm-12 mb-6 animated",
												renderProps.viewVisible
													? "dealy-250ms fadeIn"
													: "fadeOut"
											)}
											tagName="p"
											textId={content.body}
										/>

										<Backend
											key={content.id}
											// {...props}
											className="row m-0"
											quidType="comment"
											mode="outputs"
											targetDataKey={`${ioID}-${index}`}
											inputContext={cmtPageItem.io_context}
											match={{ targetDataKey: `${ioID}-${index}` }}
											renderOutputs={(backendContentRenderProps) => {
												return (
													<IOListsOutput
														{...backendContentRenderProps}
														className="col-12 mb-1"
														textColor={getText(content.comment_text_color)}
														color={content.color}
													/>
												);
											}}
										/>
									</div>
								);
							})}
						</div>
					</React.Fragment>
				);
			}}
		></PageItemScrollBase>
	);
};

export default PageItemIOListsOutput;
