import _ from "lodash";

export function convertQueryToString(
	query,
	omit = ["throttle", "throttleWait"]
) {
	if (!query) {
		return "";
	}

	const _query = _.omit(query, omit);

	let queryString = "?";
	for (let key in _query) {
		if (_query[key]) {
			if (Array.isArray(_query[key])) {
				queryString += `${key}=[${_query[key]}]&`;
			} else {
				queryString += `${key}=${_query[key]}&`;
			}
		}
	}

	return queryString.slice(0, -1);
}
