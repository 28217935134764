import classNames from "classnames";
import { useText } from "funkis-template/hooks/text";
import { TextId } from "funkis-template/models/player";
import _ from "lodash";
import RCSlider, { Range as RCRange } from "rc-slider";
import "rc-slider/assets/index.css";
import React, { FC, ReactElement } from "react";
import Styles from "./Slider.module.css";

const RCSliderWithTooltip = RCSlider.createSliderWithTooltip(RCSlider);
const RCRangeWithTooltip = RCSlider.createSliderWithTooltip(RCRange);

export type SliderMarks = Record<number, SliderMarkItem>;

export type SliderMarkItem = {
	label: TextId | string;
	style?: React.CSSProperties;
};

type CommonProps = {
	min: number;
	max: number;
	step?: number;
	tone?: "brand" | "positive" | "neutral" | "caution" | "critical";
	tooltip?: "never" | "auto" | "always";
	disabled?: boolean;
	marks: SliderMarks;
	track?: boolean;
};

type SingleSliderProps = CommonProps & {
	value: number;
	onChange: (value: number) => void;
};

type RangeProps = CommonProps & {
	value: number[];
	onChange: (value: number[]) => void;
};

function composeHandleStyle(): React.CSSProperties {
	return {
		backgroundColor: "var(--slider-color)",
		borderColor: "var(--slider-color)",
		width: "var(--slider-handle-size)",
		height: "var(--slider-handle-size)",
		marginTop:
			"calc(-1 * var(--slider-handle-size) / 2 + var(--slider-rail-height) / 2)",
	};
}

function composeTrackStyle(showTrack: boolean): React.CSSProperties {
	return {
		backgroundColor: showTrack ? "var(--slider-color)" : "transparent",
		height: "var(--slider-rail-height)",
		borderRadius: 0,
	};
}

function composeDotStyle(): React.CSSProperties {
	return {
		border: "none",
		backgroundColor: "var(--slider-rail-color)",
		borderRadius: 0,
		width: "var(--slider-dot-width)",
		height: "var(--slider-dot-height)",
		marginLeft: "calc(-1 * var(--slider-dot-width) / 2)",
		bottom:
			"calc(var(--slider-rail-height) / 2 - var(--slider-dot-height) / 2)",
	};
}

function composeActiveDotStyle(showTrack: boolean): React.CSSProperties {
	return {
		backgroundColor: showTrack
			? "var(--slider-color)"
			: "var(--slider-rail-color)",
	};
}

function composeRailStyle(): React.CSSProperties {
	return {
		backgroundColor: "var(--slider-rail-color)",
		height: "var(--slider-rail-height)",
		borderRadius: 0,
	};
}

export type SliderProps = SingleSliderProps | RangeProps;

const Slider: FC<SliderProps> = ({
	min,
	max,
	tone = "brand",
	step = 1,
	marks,
	value,
	onChange,
	disabled = false,
	tooltip = "auto",
	track = false,
}): ReactElement => {
	const marksTextTemplates = _.mapValues(marks, (mark) => mark.label);
	const marksTexts = useText(marksTextTemplates);

	const marksWithStyles = _.mapValues(marks, (mark, key) => ({
		label: marksTexts[key],
		style: {
			...mark.style,
			textAlign:
				Number(key) === min ? "left" : Number(key) === max ? "right" : "center",
			transform: `translateX(${
				Number(key) === min
					? "0%"
					: Number(key) === max
					? "calc(-100% + var(--slider-dot-width))"
					: "-50%"
			})`,
			width: "fit-content",
			whiteSpace: "nowrap",
		},
	}));

	const isSlider = typeof value == "number";

	if (isSlider) {
		const RCSliderComponent =
			tooltip === "never" ? RCSlider : RCSliderWithTooltip;

		return (
			<RCSliderComponent
				className={classNames(Styles.slider, Styles[tone])}
				disabled={disabled}
				min={min}
				max={max}
				step={step}
				value={value}
				tipProps={tooltip === "auto" ? {} : { visible: tooltip === "always" }}
				onChange={onChange as (value: number) => void}
				marks={marksWithStyles}
				handleStyle={composeHandleStyle()}
				trackStyle={composeTrackStyle(track)}
				activeDotStyle={composeActiveDotStyle(track)}
				railStyle={composeRailStyle()}
				dotStyle={composeDotStyle()}
			/>
		);
	} else {
		const rangeValue = value;

		const RCRangeComponent = tooltip === "never" ? RCRange : RCRangeWithTooltip;

		return (
			<RCRangeComponent
				className={classNames(Styles.slider, Styles[tone])}
				disabled={disabled}
				min={min}
				max={max}
				step={step}
				value={rangeValue}
				tipProps={tooltip === "auto" ? {} : { visible: tooltip === "always" }}
				onChange={onChange as (value: number[]) => void}
				marks={marksWithStyles}
				handleStyle={rangeValue.map(() => composeHandleStyle())}
				trackStyle={rangeValue.map(() => composeTrackStyle(track))}
				activeDotStyle={composeActiveDotStyle(track)}
				railStyle={composeRailStyle()}
				dotStyle={composeDotStyle()}
			/>
		);
	}
};

export default Slider;
