import React from "react";
import {
	ReduxCollectionSelectors,
	ReduxObjectSelectors,
	FFButton,
	FFText,
	PageItemActions,
	StoreUtils,
} from "funkis-foundation";
import { connect } from "../../../utils/reduxUtils";
import SideMenuChapterButton from "../../../components/scroll/version-2/SideMenuChapterButton";
import SidePanel from "../../../components/core/version-2/SidePanel";
import ImageVideoOrDiv from "../../../components/core/ImageVideoOrDiv";
import { updateLocation } from "../../../actions/Actions";
import GamificationPanel from "../../../components/core/GamificationPanel";
import GroupMembersPanel from "../../../components/core/GroupMembersPanel";
import { getBootstrapColor } from "../../../utils/colorUtils";
import Text from "../../../components/core/Text";

const { makeGetItemIdsSelector } = ReduxCollectionSelectors;
const { makeGetValueSelector } = ReduxObjectSelectors;
const { updatePageItem } = PageItemActions;

const PageItemSideMenu = (props) => {
	const { pageItem, pageIds, menuPosition, subTitleTextId } = props;
	const { open } = pageItem;
	const app = StoreUtils.getReducer("app").item;

	const onMainMenuButtonClick = () => {
		updateLocation({ friendlyId: "menu" });
		closeMenu();
	};

	const closeMenu = () => {
		updatePageItem(pageItem.id, { open: false });
	};

	if (app.navigation_menu_hidden === "yes") {
		return <React.Fragment />;
	}

	return (
		<SidePanel
			align={menuPosition}
			open={open}
			backgroundColor="#ECECEC"
			className="box-shadow-left"
			innerClassName="pb-4"
		>
			<div
				style={{ height: app.size.name === "mobile small" ? "243px" : "288px" }}
			>
				<ImageVideoOrDiv src={app.navigation_menu_image}></ImageVideoOrDiv>
			</div>
			{/* TITLE / SUB TITLE */}
			<div className="px-3 pt-4 pb-3 bg-black">
				<Text
					textId={app.title_txt_id}
					tagName="h4"
					className="font-weight-bold text-white"
				/>

				<Text
					textId={subTitleTextId}
					tagName="span"
					className="p-md text-white d-block"
					style={{ marginTop: "-0.45rem" }}
				/>
			</div>
			{/* GAMIFICATION PANEL 1 */}
			<GamificationPanel
				context={app.navigation_menu_gamification_panel_1}
				textIdBadge="9B34FA43-A641-4897-B9FF-8B7155AD0171"
				textIdScore="BBAF3794-9F9E-4C6A-B6D2-DDF3A9C0D0E2"
			/>
			{/* GAMIFICATION PANEL 2 */}
			<GamificationPanel
				context={app.navigation_menu_gamification_panel_2}
				textIdBadge="979AFCB8-CA1E-44AF-9DF5-0D690A472A15"
				textIdScore="51049CA1-45E0-4CF9-AE78-4457949BB2AB"
			/>
			{/* GROUP MEMBERS PANEL */}
			{(app.navigation_menu_show_team === "yes" ||
				app.navigation_menu_show_group === "yes") && <GroupMembersPanel />}
			<FFButton
				className="pl-2 pt-5 pb-3 pr-3"
				style={{ backgroundColor: "#ECECEC" }}
				onClick={onMainMenuButtonClick}
			>
				{({ hover }) => {
					return (
						<FFText
							tagName="h4"
							className="text-uppercase"
							textId="8931969D-F644-46D9-8B13-97000804C5A4"
							placeholder="[Back to main menu]"
							style={{
								color: getBootstrapColor(hover ? "interaction" : "black"),
							}}
						/>
					);
				}}
			</FFButton>
			{!open
				? null
				: pageIds.map((id, index) => (
						<SideMenuChapterButton
							key={`SideMenuPageButton-${id}`}
							id={id}
							index={index}
							onClick={closeMenu}
							onPageClick={closeMenu}
						/>
				  ))}
		</SidePanel>
	);
};

const match = (page) => {
	return (
		page &&
		page.data &&
		page.data.cmtPage &&
		page.data.cmtPage.type === "chapter"
	);
};
//makeGetValueForCurrentPage;
const makeMapStateToProps = () => {
	const getItemIdsSelector = makeGetItemIdsSelector();
	const getValueSelector = makeGetValueSelector();
	const getSubTitleSelector = makeGetValueSelector();
	const mapStateToProps = (state, props) => {
		return {
			pageIds: getItemIdsSelector(state, { ...props, match }, "pages"),
			menuPosition: getValueSelector(
				state,
				{ ...props, path: "menu_position" },
				"app"
			),
			subTitleTextId: getSubTitleSelector(
				state,
				{ ...props, path: "sub_title_1_txt_id" },
				"app"
			),
		};
	};
	return mapStateToProps;
};

export default connect(PageItemSideMenu, makeMapStateToProps);
