import { createSelector } from "reselect";

import { getLocationSelector } from "./LocationSelectors";
import { getPageIdsForLocation } from "../utils/LocationUtils";

const getPages = (state) => state.pages.items;
const getPageById = (state, props) =>
	state.pages.itemsById[props.pageId || props.id];
const getPageByParentPageId = (state, props) =>
	state.pages.itemsById[props.parentPageId];

const locationPathProp = (_, props) => props.locationPath;
const layerProp = (_, props) => props.layer;
const idProp = (_, props) => props.id;
const parentPageIdProp = (_, props) => props.parentPageId;

const getPagesByParentPageIdSelector = () =>
	createSelector(
		getPages,
		parentPageIdProp,
		idProp,
		(pages, parentPageIdFromProp, idFromProp) => {
			return pages.filter((page) =>
				page.hasOwnProperty("parentPageId")
					? page.parentPageId === (idFromProp || parentPageIdFromProp)
					: page.parent === (idFromProp || parentPageIdFromProp)
			);
		}
	);

export const getPageByIdSelector = () =>
	createSelector(getPageById, (page) => {
		return page;
	});

export const getPageByParentPageIdSelector = createSelector(
	getPageByParentPageId,
	(page) => {
		return page;
	}
);

// invoked getPagesByParentPageIdSelector() because each selector must have their own version of it
export const getPageIdsByParentPageIdSelector = () =>
	createSelector(getPagesByParentPageIdSelector(), (pages) => {
		return pages.map((page) => page.id);
	});

export const getPagesByLocationPathSelector = () =>
	createSelector(
		getLocationSelector,
		getPages,
		locationPathProp,
		parentPageIdProp,
		layerProp,
		(location, pages, locationPath, parentPageId, layer) => {
			const pageIds = getPageIdsForLocation(location, locationPath);
			return pages
				.filter(
					(page) => page.parentPageId === parentPageId && page.layer === layer
				)
				.filter((page) => pageIds.includes(page.id));
		}
	);

export function makeGetPageByIdSelector() {
	return getPageByIdSelector();
}

export function makeGetPageIdsByParentPageId() {
	return getPageIdsByParentPageIdSelector();
}

export function makeGetPagesByLocationPath() {
	return getPagesByLocationPathSelector();
}
