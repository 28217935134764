import classNames from "classnames";
import Badge from "components/Badge/Badge";
import Heading from "components/Heading/Heading";
import Spacer from "components/Spacer/Spacer";
import Stack from "components/Stack/Stack";
import IconStatusCompleted from "funkis-template/assets/svg/IconStatusCompleted";
import IconStatusIncomplete from "funkis-template/assets/svg/IconStatusIncomplete";
import IconStatusNotAttempted from "funkis-template/assets/svg/IconStatusNotAttempted";
import IconTime from "funkis-template/assets/svg/IconTime";
import Media, {
	MediaCrop,
	MediaFit,
} from "funkis-template/components/core/Media";
import Text from "funkis-template/components/core/Text";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { IOContext, QuidType, useBackend } from "funkis-template/hooks/backend";
import {
	useSelectedLanguage,
	useViewportSize,
	ViewportSize,
} from "funkis-template/hooks/player";
import { useText } from "funkis-template/hooks/text";
import { ActivityLinkScrollBlock } from "funkis-template/models/pageitem";
import { PageItem } from "funkis-template/models/player";
import _ from "lodash";
import { FC } from "react";
import { useSelector } from "react-redux";
import { GlobalTexts } from "./ActivityLink.constants";
import Style from "./ActivityLink.module.css";

type Status = "not_attempted" | "incomplete" | "completed";

function composeCourseUrl(
	packageUrl: string,
	sessionKey: string,
	selectedLanguage: string
): string {
	const searchParams = new URLSearchParams();
	sessionKey && searchParams.append("sessionKey", sessionKey);
	selectedLanguage && searchParams.append("language", selectedLanguage);

	return unescape(packageUrl) + "#?" + searchParams.toString();
}

type ActivityStatus = Record<
	Status,
	{
		icon: React.ReactNode;
		label: GlobalTexts;
	}
>;

const activityStatuses: ActivityStatus = {
	not_attempted: {
		icon: <IconStatusNotAttempted className={undefined} style={undefined} />,
		label: GlobalTexts.NotAttempted,
	},
	incomplete: {
		icon: (
			<IconStatusIncomplete
				className={undefined}
				style={undefined}
				color="#000"
			/>
		),
		label: GlobalTexts.Incomplete,
	},
	completed: {
		icon: (
			<IconStatusCompleted
				className={undefined}
				style={undefined}
				color="#000"
			/>
		),
		label: GlobalTexts.Completed,
	},
};

const PageItemActivityLink: FC<{
	pageItem: PageItem<ActivityLinkScrollBlock>;
}> = (props) => {
	const {
		pageItem: { cmtPageItem },
	} = props;

	const { quidInputs } = useBackend({
		targetDataKey: cmtPageItem.package_id ?? `empty_io_${cmtPageItem.id}`,
		inputContext:
			cmtPageItem.activity_type === "individual"
				? IOContext.Member
				: IOContext.Group,
		quidType: QuidType.App,
	});

	const appQuid = quidInputs.at(0);

	const currentStatus: Status | undefined = cmtPageItem.package_id
		? appQuid?.data.courseStatus ?? "not_attempted"
		: undefined;

	const sessionKey = useSelector((state) => {
		return _.get(state, "quiddity.item.session.code");
	});

	const selectedLanguage = useSelectedLanguage();

	const courseUrl = cmtPageItem.package_url
		? composeCourseUrl(cmtPageItem.package_url, sessionKey, selectedLanguage)
		: "";

	const labelText = useText(cmtPageItem.label);

	const viewportSize = useViewportSize();

	return (
		<PageItemScrollBase
			{...props}
			className="container-fluid"
			renderFunction={(renderProps) => (
				<div className="row m-0">
					<div className="col-sm-12">
						<a
							className={classNames(Style.activityLink)}
							href={cmtPageItem.launch_url || courseUrl}
						>
							<Stack
								direction={
									viewportSize < ViewportSize.Mobile ? "vertical" : "horizontal"
								}
							>
								<div
									style={{
										flexShrink: 0,
										maxWidth:
											viewportSize < ViewportSize.Mobile ? "none" : "40%",
									}}
								>
									<Media
										src={cmtPageItem.image}
										mediaFit={MediaFit.FullWidth}
										mediaCrop={MediaCrop.ResizeToFit}
									/>
								</div>
								<Stack spacing="xsmall">
									<Stack direction="horizontal">
										<Text
											tagName="h2"
											className={Style.activityIdentifier}
											textId={cmtPageItem.activity_identifier}
										/>

										<Spacer />

										<div>
											{labelText && (
												<Badge tone="neutral" weight="strong">
													<Text textId={cmtPageItem.label} />
												</Badge>
											)}
										</div>
									</Stack>

									<Heading
										level="4"
										textId={cmtPageItem.header}
										balance={true}
									/>

									<Text tagName="p" textId={cmtPageItem.body} balance={true} />

									<Spacer />

									<Stack direction="horizontal">
										<Stack
											direction="horizontal"
											spacing="small"
											width="fit-content-width"
										>
											<IconTime color="#000" />
											<Text textId={cmtPageItem.duration} />
										</Stack>

										{currentStatus && (
											<Stack
												direction="horizontal"
												spacing="small"
												width="fit-content-width"
											>
												{activityStatuses[currentStatus].icon}
												<Text textId={activityStatuses[currentStatus].label} />
											</Stack>
										)}
									</Stack>
								</Stack>
							</Stack>
						</a>
					</div>
				</div>
			)}
		/>
	);
};

export default PageItemActivityLink;
