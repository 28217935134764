import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { FFButton, ReduxCollectionSelectors } from "funkis-foundation";
import PostItBackground from "../../../assets/svg/PostItBackground";
import { connect } from "../../../utils/reduxUtils";
import { getBootstrapColor } from "../../../utils/colorUtils";
import Style from "./QuiddityPostItsOutput.module.css";
import IconCheck from "../../../assets/svg/IconCheck";
import IconMove from "../../../assets/svg/IconMove";
import IconRemove from "../../../assets/svg/IconRemove";
import { Elastic, TweenMax } from "gsap";
import TextInput from "../../core/TextInput";
import Hover from "../../core/Hover";

const { makeGetItemByIdSelector } = ReduxCollectionSelectors;

const SelectedIcon = ({ show }) => {
	useEffect(() => {
		TweenMax.to(ref.current, { opacity: 0, scale: 0 });
	}, []);

	useEffect(() => {
		if (show) {
			TweenMax.to(ref.current, 0.8, {
				opacity: 1,
				scale: 1.5,
				ease: Elastic.easeOut,
			});
		} else {
			TweenMax.to(ref.current, 0.3, { opacity: 0, scale: 0 });
		}
	}, [show]);

	const ref = useRef(null);
	return (
		<div
			ref={ref}
			className={classnames(
				Style.selectedIcon,
				"d-flex absolute-top absolute-right bg-black p-1 justify-content-center align-items-center"
			)}
			style={{ opacity: 0 }}
		>
			<IconCheck color="white" />
		</div>
	);
};

let tween;
const RemoveButton = ({ show, onClick, style }) => {
	const ref = useRef(null);

	useEffect(() => {
		TweenMax.to(ref.current, { opacity: 0, scale: 0 });
	}, []);

	useEffect(() => {
		if (show) {
			tween = TweenMax.to(ref.current, 0.8, {
				opacity: 1,
				scale: 0.7,
				ease: Elastic.easeOut,
			});
		} else {
			tween && tween.kill();
			TweenMax.to(ref.current, 0.3, { opacity: 0, scale: 0 });
		}
	}, [show]);

	return (
		<div
			ref={ref}
			className="absolute-top absolute-right"
			style={{ zIndex: 1, ...style }}
		>
			<FFButton onClick={onClick}>
				{({ hover }) => (
					<IconRemove
						color={getBootstrapColor(hover ? "interaction" : "black")}
					/>
				)}
			</FFButton>
		</div>
	);
};

let tweenB;
const DragHandle = ({ show }) => {
	const ref = useRef(null);

	useEffect(() => {
		TweenMax.to(ref.current, { opacity: 0, scale: 0 });
	}, []);

	useEffect(() => {
		if (show) {
			tweenB = TweenMax.to(ref.current, 0.8, {
				opacity: 1,
				scale: 0.7,
				ease: Elastic.easeOut,
			});
		} else {
			tweenB && tweenB.kill();
			TweenMax.to(ref.current, 0.3, { opacity: 0, scale: 0 });
		}
	}, [show]);

	return (
		<div
			ref={ref}
			className="drag-handle absolute-top absolute-left"
			style={{ zIndex: 1, left: "-30px", top: "-30px" }}
		>
			<Hover style={{ cursor: "grabbing" }}>
				{({ hover }) => (
					<IconMove
						color={getBootstrapColor(hover ? "interaction" : "black")}
					/>
				)}
			</Hover>
		</div>
	);
};

const QuiddityPostItsOutput = (props) => {
	const {
		body,
		index,
		onChange,
		onFocus,
		onBlur,
		onRemove,
		className,
		classNameBody,
		style,
		width = "450px",
		color = "light-gray",
		textColor = "black",
		quidInput,
		maxRotation = 6,
		maxTranslateX = 40,
		children,
		selected = false,
		focus = false,
		removeEnabled = false,
		showDragHandle = false,
		styleRemoveButton,
	} = props;

	// Fulfix...
	if (!quidInput) {
		return <div />;
	}

	const ref = useRef(null);

	useEffect(() => {
		if (focus) {
			ref.current.focus();
		}
	}, [focus]);

	const { data } = quidInput;
	const [rotation] = useState(Math.random() * maxRotation - maxRotation * 0.5);
	const [translateX] = useState(
		Math.random() * maxTranslateX - maxTranslateX * 0.5
	);

	return (
		<Hover
			className={classnames(Style.root, className, "pb-4")}
			style={{
				transform: `translate(${translateX}px) rotate(${rotation}deg)`,
				cursor: "auto",
				minWidth: width,
				maxWidth: width,
				...style,
			}}
		>
			{({ hover }) => (
				<React.Fragment>
					<div
						style={{ background: getBootstrapColor(color) }}
						className="d-flex align-items-center flex-column px-4 pt-4 pb-1"
						enabled={false}
					>
						<TextInput
							refFwd={ref}
							className="w-100 h-100"
							classNameTextArea={classnames(
								Style.body,
								classNameBody,
								"overflow-hidden"
							)}
							styleTextArea={{
								color: getBootstrapColor(textColor),
								backgroundColor: "transparent",
								borderWidth: 0,
							}}
							// rows={rows}
							// maxLength={maxLength || Math.MAX_VALUE}
							value={body || data.body}
							onChange={(text) => {
								onChange && onChange(text);
							}}
							onFocus={onFocus}
							onBlur={onBlur}
						/>
						{/* SELECTED ICON */}
						<SelectedIcon show={selected} />
						{/* REMOVE BUTTON */}
						{removeEnabled && (
							<RemoveButton
								show={hover || focus}
								onClick={onRemove}
								style={styleRemoveButton}
							/>
						)}
						{/* DRAG HANDLE */}
						{showDragHandle && <DragHandle show={hover || focus} />}
						{/* CHILDREN */}
						{(children && typeof children === "function" && children(props)) ||
							children}
					</div>
					<div
						className="w-100 h-100 d-flex flex-column"
						style={{ marginTop: "-3px" }}
					>
						{/* Remove extra height. Should grow by content. */}
						{/* <div className={classnames(Style.dropShadow, "flex-grow-1")} style={{ background: getBootstrapColor(color) }} /> */}
						<PostItBackground
							index={index}
							color={getBootstrapColor(color)}
							className="mb-n1 flex-grow-0"
							style={{ transform: "translateY(-2px)" }}
						/>
					</div>
				</React.Fragment>
			)}
		</Hover>
	);
};

const makeMapStateToProps = () => {
	const getItemByIdSelector = makeGetItemByIdSelector();
	const mapStateToProps = (state, props) => {
		return {
			quidInput: getItemByIdSelector(state, props, "quidInputs"),
		};
	};
	return mapStateToProps;
};

export default connect(QuiddityPostItsOutput, makeMapStateToProps);
