import {
	initialStateObjectCollection,
	updateItemInStateItems,
} from "../utils/reducerUtils";

import { UPDATE_TIMER } from "../constants/ActionConstants";

const timers = (state = initialStateObjectCollection(), action) => {
	switch (action.type) {
		case UPDATE_TIMER:
			return updateItemInStateItems(state, action.item);
		default:
			return state;
	}
};

export default timers;
