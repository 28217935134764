import { createSelector } from "reselect";

import { getAppSelector } from "./AppSelectors";
import { getPageItemsFilter } from "../utils/filters/PageItemsFilters";
import { getPageItemByPath } from "../utils/PageItemsUtils";

const getPageItems = (state) => state.pageItems.items;
const getPageItemById = (state, props) => state.pageItems.itemsById[props.id];
const getPageItemByParentPageItemId = (state, props) =>
	state.pageItems.itemsById[props.parentPageItemId];
const getPageItemByRelativePath = (_state, props) =>
	getPageItemByPath(props.path, props.relativeToPageItemId || props.id);

const getParentPageId = (_, props) => props.parentPageId;
const layerProp = (_, props) => props.layer;
const disableConnectToStateProp = (_, props) => props.disableConnectToState;
const pageItemProp = (_, props) => props.pageItem;

export const getPageItemByIdSelector = createSelector(
	getPageItemById,
	disableConnectToStateProp,
	pageItemProp,
	(pageItemById, disableConnectToState, pageItem) => {
		return disableConnectToState ? pageItem : pageItemById;
	}
);

export const getPageItemByRelativePathSelector = createSelector(
	getPageItemByRelativePath,
	(pageItem) => {
		return pageItem;
	}
);

export function makeGetPageItemById() {
	return getPageItemByIdSelector;
}

export function makeGetPageItemIdsByParentPageId() {
	return createSelector(
		getPageItems,
		getParentPageId,
		getAppSelector,
		layerProp,
		(pageItems, parentPageId, app, layer) => {
			if (!parentPageId) {
				console.log(
					`Parent page for page could not be found! Returning empty array... ${parentPageId}`
				);
				return [];
			}

			let filteredPageItems = pageItems;

			filteredPageItems = getPageItemsFilter("parentPageId")(
				filteredPageItems,
				parentPageId
			);
			filteredPageItems = getPageItemsFilter("parentPageItemId")(
				filteredPageItems,
				null
			);
			filteredPageItems = getPageItemsFilter("layer")(filteredPageItems, layer);
			filteredPageItems = getPageItemsFilter("visibility")(
				filteredPageItems,
				true
			);
			filteredPageItems = getPageItemsFilter("size")(
				filteredPageItems,
				app.size.name
			);

			return filteredPageItems.map((pageItem) => pageItem.id);
		}
	);
}

export function makeGetPageItemIdsByParentPageItemId() {
	return createSelector(
		getPageItems,
		getPageItemByParentPageItemId,
		getAppSelector,
		layerProp,
		(pageItems, pageItem, app, layer) => {
			if (!pageItem) {
				console.log(
					`Page item for parent page item could not be found! Returning empty array...`
				);
				return [];
			}

			let filteredPageItems = pageItems;

			filteredPageItems = getPageItemsFilter("parentPageItemId")(
				filteredPageItems,
				pageItem.id
			);
			filteredPageItems = getPageItemsFilter("layer")(filteredPageItems, layer);
			filteredPageItems = getPageItemsFilter("visibility")(
				filteredPageItems,
				true
			);
			filteredPageItems = getPageItemsFilter("size")(
				filteredPageItems,
				app.size.name
			);

			return filteredPageItems.map((pageItem) => pageItem.id);
		}
	);
}

export function makeGetPageItemByRelativePathSelector() {
	return getPageItemByRelativePathSelector;
}
