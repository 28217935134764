import * as StoreUtils from "../../utils/StoreUtils";

// Action constants
import {
	UPDATE_QUID,
	REMOVE_QUID,
	UPDATE_QUIDS,
} from "../constants/ActionConstants";

export function updateQuids(items) {
	StoreUtils.getStore().dispatch(_updateQuids(items));
}

function _updateQuids(items) {
	return (dispatch: (action) => void) => {
		dispatch({ items, type: UPDATE_QUIDS });
	};
}

export function updateQuid(id, updates) {
	StoreUtils.getStore().dispatch(_updateQuid(Object.assign(updates, { id })));
}

function _updateQuid(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_QUID });
	};
}

export function removeQuid(id) {
	StoreUtils.getStore().dispatch(_removeQuid({ id }));
}

function _removeQuid(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_QUID });
	};
}
