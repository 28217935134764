import React, { FC, ReactNode } from "react";
import { TextId, PageItemMedia } from "funkis-template/models/player";
import { QuidInput } from "funkis-template/hooks/backend";
import { calculateRateDataFromQuidInputs } from "funkis-template/pageitems/slide/RateOutput/RateOutput.utils";
import { RateContentArray } from "funkis-template/models/contentarray";
import Stack from "components/Stack/Stack";
import Media, {
	MediaCrop,
	MediaFit,
} from "funkis-template/components/core/Media";
import Text, { TextVariables } from "funkis-template/components/core/Text";
import Slider from "components/Slider/Slider";
import _ from "lodash";
import {
	composeBadge,
	VariationBadges,
	VariationLevel,
} from "funkis-template/pageitems/slide/RateOutput/RateOutput";
import { GlobalText } from "./RateOutput.constants";
import Badge from "components/Badge/Badge";

type BadgeStyle = "text" | "badge";
type RateOutputBadgeTone = "auto" | "info";

export type RateOutputProps = {
	headingText: TextId;
	bodyText: TextId;
	descriptionText: TextId;
	media?: PageItemMedia;
	sliderMedia?: PageItemMedia;
	badgeTone: RateOutputBadgeTone;
	badgeStyle: BadgeStyle;
	variationThresholdHigh: Number;
	variationThresholdMedium: Number;
	sliderHandleColor?: string;
	sliderRailColor?: string;
	quidInputs: QuidInput[];
	contentArray: RateContentArray;
	showVariationLabel: boolean;
	showValueLabel: boolean;
	showInputCountLabel: boolean;
	track: boolean;
};

const scrollVariationBadges: VariationBadges = {
	low: {
		tone: "positive",
		textId: GlobalText.VariationLow,
	},
	medium: {
		tone: "caution",
		textId: GlobalText.VariationMedium,
	},
	high: {
		tone: "critical",
		textId: GlobalText.VariationHigh,
	},
};

export function composeValueBadge(
	badgeStyle: BadgeStyle,
	tone: "neutral",
	textId: TextId,
	values: TextVariables
): ReactNode {
	switch (badgeStyle) {
		case "badge":
			return (
				<Badge tone={tone}>
					<Text textId={textId} variables={values} />
				</Badge>
			);

		case "text":
			return (
				<Text
					className="font-weight-bold"
					tone={tone}
					textId={textId}
					variables={values}
				/>
			);
	}
}

const RateOutput: FC<RateOutputProps> = ({
	variationThresholdHigh,
	variationThresholdMedium,
	media,
	headingText,
	bodyText,
	descriptionText,
	sliderMedia,
	contentArray,
	quidInputs,
	showVariationLabel = true,
	showValueLabel = true,
	showInputCountLabel = true,
	badgeStyle,
	badgeTone,
	sliderHandleColor,
	sliderRailColor,
	track,
}) => {
	const marks = contentArray.values.reduce(
		(acc, caItem) => ({ ...acc, [caItem.value]: { label: caItem.label } }),
		{}
	);

	const markKeys = Object.keys(marks).map((k) => parseInt(k, 10));

	const minValue = _.min(markKeys) ?? 0;
	const maxValue = _.max(markKeys) ?? 100;

	const { averageValue, coefficientOfVariation } =
		calculateRateDataFromQuidInputs(quidInputs);

	const variationLevel: VariationLevel =
		coefficientOfVariation > variationThresholdHigh
			? "high"
			: coefficientOfVariation > variationThresholdMedium
			? "medium"
			: "low";

	const handleStyle = sliderHandleColor
		? ({ "--slider-color": sliderHandleColor } as React.CSSProperties)
		: {};

	const railStyle = sliderRailColor
		? ({ "--slider-rail-color": sliderRailColor } as React.CSSProperties)
		: {};

	return (
		<div style={{ ...handleStyle, ...railStyle }}>
			<Stack spacing="medium" direction={"vertical"}>
				{media && (
					<Media
						className="col-sm-12"
						src={media}
						mediaFit={MediaFit.FullWidth}
						mediaCrop={MediaCrop.ResizeToFit}
					/>
				)}

				<Text
					className="col-sm-12"
					tagName="h2"
					textId={headingText}
					balance={true}
				/>

				<Text className="col-sm-9" tagName="div" textId={bodyText} />

				<Text
					className="col-sm-9 instruction font-italic"
					textId={descriptionText}
				/>

				{sliderMedia && (
					<Media
						className="col-sm-12"
						src={sliderMedia}
						mediaFit={MediaFit.FullWidth}
						mediaCrop={MediaCrop.ResizeToFit}
					/>
				)}

				<Stack className="col-sm-12" spacing="small">
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "end",
						}}
					>
						<div>
							<Stack spacing="xxsmall">
								{showInputCountLabel &&
									composeValueBadge(
										badgeStyle,
										"neutral",
										GlobalText.InputsCountLabel,
										{
											inputsCount: {
												value: quidInputs.length,
												className: "font-weight-normal",
											},
										}
									)}

								{showValueLabel &&
									composeValueBadge(badgeStyle, "neutral", GlobalText.Value, {
										averageValue: {
											value: averageValue,
											className: "font-weight-normal",
										},
									})}
							</Stack>
						</div>

						<div>
							{showVariationLabel &&
								composeBadge(
									badgeStyle,
									badgeTone,
									variationLevel,
									scrollVariationBadges
								)}
						</div>
					</div>

					<Slider
						marks={marks}
						min={minValue}
						max={maxValue}
						value={averageValue}
						onChange={_.noop}
						disabled={true}
						track={track}
						step={0.01}
						tone={sliderHandleColor ? "neutral" : "brand"}
					/>
				</Stack>
			</Stack>
		</div>
	);
};

export default RateOutput;
