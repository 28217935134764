import { createSelector } from "reselect";

const getTexts = (state, props) => state.texts.items;
const getTextById = (state, props) =>
	state.texts.itemsById[props.textId || props.id];

export const getTextsSelector = createSelector(getTexts, (texts) => {
	return texts;
});

export const getTextObjectByIdSelector = createSelector(getTextById, (text) => {
	return text;
});

export const getTextByIdSelector = createSelector(getTextById, (text) => {
	return text && text.value;
});

export function makeGetTextsSelector() {
	return getTextsSelector;
}

export function makeGetTextById() {
	console.warn(
		`"makeGetTextById" is depricated. Please use "makeGetTextByIdSelector" `
	);
	return getTextByIdSelector;
}

export function makeGetTextByIdSelector() {
	return getTextByIdSelector;
}

export function makeGetTextObjectById() {
	console.warn(
		`"makeGetTextObjectById" is depricated. Please use "makeGetTextObjectByIdSelector" `
	);
	return getTextObjectByIdSelector;
}

export function makeGetTextObjectByIdSelector() {
	return getTextObjectByIdSelector;
}
