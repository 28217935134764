import React, { useEffect, useRef } from "react";
import IconCheck from "../../assets/svg/IconCheck";
import { Elastic, TweenMax } from "gsap";
import styled from "styled-components";
import PropTypes from "prop-types";
import classnames from "classnames";

const Root = styled.div`
	position: relative;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ backgroundColor }) => backgroundColor};
`;

const SelectedIcon = ({
	show,
	backgroundColor,
	classNames,
	style,
	iconCheckProps,
	...rest
}) => {
	const ref = useRef(null);

	useEffect(() => {
		TweenMax.to(ref.current, { opacity: 0.7, scale: 1.3 });
	}, []);

	useEffect(() => {
		if (show) {
			TweenMax.to(ref.current, 0.8, {
				opacity: 1,
				scale: 1.3,
				ease: Elastic.easeOut,
			});
		} else {
			TweenMax.to(ref.current, 0.3, { opacity: 0.7, scale: 1.3 });
		}
	}, [show]);

	return (
		<Root
			ref={ref}
			backgroundColor={backgroundColor}
			className={classnames("selected-icon p-1", classNames)}
			style={{ opacity: 0, ...style }}
			{...rest}
		>
			<IconCheck
				color={show ? "white" : "black"}
				size="md"
				scale={0.5}
				className={classnames("icon", iconCheckProps.className)}
				{...iconCheckProps}
			/>
		</Root>
	);
};

SelectedIcon.propTypes = {
	show: PropTypes.bool,
	backgroundColor: PropTypes.string,
	iconCheckProps: PropTypes.object,
};

SelectedIcon.defaultProps = {
	show: false,
	backgroundColor: "black",
	iconCheckProps: {},
};

export default SelectedIcon;
