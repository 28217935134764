import _ from "lodash";
import { StoreUtils } from "funkis-foundation";
import { upsertQuidInput, getQuid, QuidInputUtils } from "quiddity";
import { getAppQuidInputs } from "./AppQuidInputRequests";

export const updateMemberPresence = async ({
	memberId,
	presenceStatus,
	query = {},
}) => {
	const quiddity = StoreUtils.getReducer("quiddity").item;
	const app = StoreUtils.getReducer("app").item;
	const quids = StoreUtils.getReducer("quids").items;
	const quidditySession = _.get(quiddity, "session.id");
	const apiUrl = _.get(quiddity, "url") + _.get(quiddity, "api");
	const appId = _.get(app, "id");
	const appQuidInputs = await getAppQuidInputs({
		memberIds: [memberId],
		fromRedux: true,
	});
	const appQuidInput = appQuidInputs[0];

	const appQuid =
		quids.find((quid) => _.matches(quid, { type: "app", template: true })) ||
		(await getQuid(apiUrl, { type: "app", template: true }));

	const reqQuery = {
		sourceQuid: appQuid.id,
		targetDataKey: appId,
		quidditySession,
		member: memberId,
		...query,
	};

	const quidInput =
		appQuidInput || QuidInputUtils.createQuidInputFromQuid(appQuid, reqQuery);

	const status = _.get(quidInput, "data.presenceStatus");
	_.set(
		quidInput,
		`data.presenceStatus`,
		presenceStatus || status !== "present" ? "present" : "not_present"
	);
	return upsertQuidInput(apiUrl, quidInput);
};
