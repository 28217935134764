import * as API from "../api";
import { getCode } from "../utils/QuiddityUtils";
import * as GroupActions from "../redux/actions/GroupActions";
import { getReducer } from "../utils/StoreUtils";

export const getGroups = async (url, query, addToStore = true) => {
	try {
		const groups = await API.getGroups(url, query, getCode());
		addToStore && GroupActions.updateGroups(groups);
		return groups;
	} catch (error) {
		return [];
	}
};

export const getGroup = async (url, query, addToStore = true) => {
	const groups = await getGroups(url, query, addToStore);
	return groups[0];
};

export const upsertGroup = async (url, query, addToStore = true) => {
	const groupsById = getReducer("groups").itemsById;
	const group = groupsById[query.id] || (await getGroup(url, query));
	console.log("upsertGroup", group);
	let upsertedGroup = { ...group, ...query };
	addToStore &&
		group &&
		GroupActions.updateGroup(upsertedGroup.id, upsertedGroup);
	upsertedGroup = await API.postOrPutGroup(url, upsertedGroup, getCode());
	addToStore &&
		!group &&
		GroupActions.updateGroup(upsertedGroup.id, upsertedGroup);
	return upsertedGroup;
};

export const createGroup = async (url, query, addToStore = true) => {
	try {
		const group = API.postOrPutGroup(url, query, getCode());
		addToStore && GroupActions.updateGroups([group]);
		return groups;
	} catch (error) {
		return null;
	}
};
