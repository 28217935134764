import FetchError from "./FetchError";
import throttleFetch from "./ThrottleFetch";
import { convertQueryToString } from "../utils/ApiUtils";

const defaultLimit = 20000;

function handleErrors(response) {
	if (!response.ok) {
		if (response.status === 404) {
			throw new FetchError("Could not communicate with server", "404");
		}

		if (response.status === 401) {
			throw new FetchError("Unauthorized", "401");
		}

		if (response.status === 403) {
			throw new FetchError("Not privileged", "403");
		}
	}

	return response;
}

export function getQuiddityTitles(url, query = {}, code = null) {
	query.limit = query.limit || defaultLimit;
	const queryStr = convertQueryToString(query);
	// console.log(`API getQuiddityEvents: ${url}/quiddityEvents${queryStr}`);
	const headers = Object.assign({}, code && { Authentication: code });
	return fetch(`${url}/quiddityTitles${queryStr}`, {
		method: "get",
		credentials: "include",
		headers,
	})
		.then(handleErrors)
		.then((res) => res.json());
}

export function postOrPutQuiddityTitle(url, quiddityTitle, code = null) {
	const method = quiddityEvent.id === undefined ? "post" : "put";
	// console.log(`API postOrPutQuiddityEvent: ${url}/quiddityEvents/ body:${quiddityEvent}`);

	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);
	return fetch(`${url}/quiddityTitles/`, {
		headers,
		method,
		body: JSON.stringify(quiddityTitle),
		credentials: "include",
	})
		.then(handleErrors)
		.then((res) => res.json());
}

// Quiddity Organizations
export function getQuiddityOrganizations(url, query = {}, code = null) {
	query.limit = query.limit || defaultLimit;
	const queryStr = convertQueryToString(query);
	// console.log(`API getQuiddityEvents: ${url}/quiddityEvents${queryStr}`);
	const headers = Object.assign({}, code && { Authentication: code });
	return fetch(`${url}/organizations${queryStr}`, {
		method: "get",
		credentials: "include",
		headers,
	})
		.then(handleErrors)
		.then((res) => res.json());
}

export function postOrPutQuiddityOrganization(
	url,
	quiddityOrganization,
	code = null
) {
	const method = quiddityEvent.id === undefined ? "post" : "put";
	// console.log(`API postOrPutQuiddityEvent: ${url}/quiddityEvents/ body:${quiddityEvent}`);

	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);
	return fetch(`${url}/organizations/`, {
		headers,
		method,
		body: JSON.stringify(quiddityOrganization),
		credentials: "include",
	})
		.then(handleErrors)
		.then((res) => res.json());
}

// Quiddity Events
export function getQuiddityEvents(url, query = {}, code = null) {
	query.limit = query.limit || defaultLimit;
	const queryStr = convertQueryToString(query);
	// console.log(`API getQuiddityEvents: ${url}/quiddityEvents${queryStr}`);
	const headers = Object.assign({}, code && { Authentication: code });
	return fetch(`${url}/quiddityEvents${queryStr}`, {
		method: "get",
		credentials: "include",
		headers,
	})
		.then(handleErrors)
		.then((res) => res.json());
}

export function postOrPutQuiddityEvent(url, quiddityEvent, code = null) {
	const method = quiddityEvent.id === undefined ? "post" : "put";
	// console.log(`API postOrPutQuiddityEvent: ${url}/quiddityEvents/ body:${quiddityEvent}`);

	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);
	return fetch(`${url}/quiddityEvents/`, {
		headers,
		method,
		body: JSON.stringify(quiddityEvent),
		credentials: "include",
	})
		.then(handleErrors)
		.then((res) => res.json());
}

// Quiddity Sessions

export function getQuidditySessions(url, query = {}, code = null) {
	query.limit = query.limit || defaultLimit;
	const queryStr = convertQueryToString(query);
	// console.log(`API getQuidditySessions ${url}/quidditySessions${queryStr}`);
	const headers = Object.assign({}, code && { Authentication: code });
	return fetch(`${url}/quidditySessions${queryStr}`, {
		method: "get",
		credentials: "include",
		headers,
	})
		.then(handleErrors)
		.then((res) => res.json());
}

export function postOrPutQuidditySession(url, quidditySession, code = null) {
	const method = quidditySession.id === undefined ? "post" : "put";
	// console.log(`API postOrPutQuidditySession: ${url}/quidditySessions/ body:${quidditySession}`);
	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);
	return fetch(`${url}/quidditySessions/`, {
		headers,
		method,
		body: JSON.stringify(quidditySession),
		credentials: "include",
	})
		.then(handleErrors)
		.then((res) => res.json());
}

// Quids

export function getQuids(url, query = {}, code = null) {
	if (throttleFetch(query)) {
		return;
	}
	query.limit = query.limit || defaultLimit;
	const queryStr = convertQueryToString(query);
	// console.log(`API getQuids: ${url}/quids${queryStr}&archived=false`);
	const headers = Object.assign({}, code && { Authentication: code });
	return fetch(`${url}/quids${queryStr}&archived=false`, {
		method: "get",
		credentials: "include",
		headers,
	})
		.then(handleErrors)
		.then((res) => res.json());
}

export function postOrPutQuid(url, quid, code = null) {
	const method = quid.id === undefined ? "post" : "put";
	// console.log(`API postOrPutQuid: ${url}/quids/ body:${quid}`);
	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);
	return fetch(`${url}/quids/`, {
		headers,
		method,
		body: JSON.stringify(quid),
		credentials: "include",
	})
		.then(handleErrors)
		.then((res) => res.json());
}

export function deleteQuid(url, id, code = null) {
	const quidIds = typeof id === "string" ? [id] : id;
	// console.log(`API deleteQuid: ${url}/quids/ body:${quidIds}`);
	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);
	return fetch(`${url}/quids/`, {
		headers,
		method: "delete",
		body: JSON.stringify({ id: quidIds }),
		credentials: "include",
	})
		.then(handleErrors)
		.then((res) => res.json());
}

// Quids Inputs

export function getQuidInputs(url, query = {}, code = null) {
	if (throttleFetch(query)) {
		return;
	}

	query.limit = query.limit || defaultLimit;
	const queryStr = convertQueryToString(query);
	// console.log(`API getQuidInputs: ${url}/quidInputs${queryStr}`);
	const headers = Object.assign({}, code && { Authentication: code });
	return fetch(`${url}/quidInputs${queryStr}`, {
		method: "get",
		credentials: "include",
		headers,
	})
		.then(handleErrors)
		.then((res) => res.json());
}

export function postOrPutQuidInput(url, quidInput, code = null) {
	const method = quidInput.id === undefined ? "post" : "put";
	// console.log(`API postOrPutQuidInput: ${url}/quidInputs/ body: ${quidInput}`);
	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);
	return fetch(`${url}/quidInputs/`, {
		headers,
		method,
		body: JSON.stringify(quidInput),
		credentials: "include",
	})
		.then(handleErrors)
		.then((res) => res.json());
}

export function deleteQuidInput(url, id, code = null) {
	const quidIds = typeof id === "string" ? [id] : id;
	// console.log(`API deleteQuid: ${url}/quids/ body:${quidIds}`);
	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);
	return fetch(`${url}/quidInputs/`, {
		headers,
		method: "delete",
		body: JSON.stringify({ id: quidIds }),
		credentials: "include",
	})
		.then(handleErrors)
		.then((res) => res.json());
}

// Groups

export function getGroups(url, query = {}, code = null) {
	if (throttleFetch(query)) {
		return;
	}
	query.limit = query.limit || defaultLimit;
	const queryStr = convertQueryToString(query);
	// console.log(`API getGroups: ${url}/groups${queryStr}`);
	const headers = Object.assign({}, code && { Authentication: code });
	return fetch(`${url}/groups${queryStr}`, {
		method: "get",
		credentials: "include",
		headers,
	})
		.then(handleErrors)
		.then((res) => res.json());
}

export function postOrPutGroup(url, group, code = null) {
	const method = group.id === undefined ? "post" : "put";
	// console.log(`API postOrPutGroup: ${url}/groups/ body:${JSON.stringify(group)}`);
	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);
	return fetch(`${url}/groups/`, {
		headers,
		method,
		body: JSON.stringify(group),
		credentials: "include",
	})
		.then(handleErrors)
		.then((res) => res.json());
}

// Members

export function getMembers(url, query = {}, code = null) {
	if (throttleFetch(query)) {
		return;
	}
	query.limit = query.limit || defaultLimit;
	const queryStr = convertQueryToString(query);
	const headers = Object.assign({}, code && { Authentication: code });
	return fetch(`${url}/members${queryStr}`, {
		method: "get",
		credentials: "include",
		headers,
	})
		.then(handleErrors)
		.then((res) => res.json());
}

export const getSessionMembersForUnits = async (
	url,
	query = { units, sessionId },
	code = null
) => {
	if (throttleFetch(query)) {
		return;
	}
	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);

	return fetch(`${url}/members/ensure-session-members-for-units`, {
		method: "POST",
		body: JSON.stringify(query),
		headers,
	})
		.then(handleErrors)
		.then((res) => res.json());
};

export function postOrPutMember(url, member, code = null) {
	const method = member.id === undefined ? "post" : "put";
	// console.log(`API postOrPutMember: ${url}/members/ body:${member}`);
	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);
	return fetch(`${url}/members/`, {
		headers,
		method,
		body: JSON.stringify(member),
		credentials: "include",
	})
		.then(handleErrors)
		.then((res) => res.json());
}

export function getUnits(url, query = {}, code = null) {
	if (throttleFetch(query)) {
		return;
	}
	const { quidditySession } = query;
	const queryStr = convertQueryToString({ ...query, quidditySession: null });
	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);
	return fetch(`${url}/quidditySessions/${quidditySession}/units${queryStr}`, {
		headers,
		method: "get",
		credentials: "include",
	}).then((res) => {
		return res.json();
	});
}

// Misc

export function createMember(
	url,
	quidditySession,
	{
		firstName,
		lastName,
		email,
		city,
		country,
		organization,
		title,
		role = "participant",
		state = "invited",
		group = null,
	} = params,
	code = null
) {
	var payload = {
		quidditySession,
		role,
		state,
		group,
		user: {
			firstName,
			lastName,
			email,
			city,
			country,
			organization,
			title,
		},
	};
	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);
	return fetch(url + "/members", {
		method: "POST",
		headers,
		credentials: "include",
		body: JSON.stringify(payload),
	})
		.then((res) => {
			if (res.ok) {
				return res.json();
			} else {
				return res;
			}
		})
		.catch((e) => {
			return e;
		});
}

// Users

export function getUsers(url, query = {}, code = null) {
	if (throttleFetch(query)) {
		return;
	}
	if (!query.limit) {
		query.limit = defaultLimit;
	}
	const queryStr = convertQueryToString(query);
	// console.log(`API getUsers: ${url}/users${queryStr}`);
	const headers = Object.assign({}, code && { Authentication: code });
	return fetch(`${url}/users${queryStr}`, {
		method: "get",
		credentials: "include",
		headers,
	})
		.then(handleErrors)
		.then((res) => res.json());
}

export function postOrPutUser(url, user, code = null) {
	const method = user.id === undefined ? "post" : "put";
	// console.log(`API postOrPutUser: ${url}/users/`);
	const headers = Object.assign(
		{
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		code && { Authentication: code }
	);
	return fetch(`${url}/users/`, {
		headers,
		method,
		body: JSON.stringify(user),
		credentials: "include",
	})
		.then(handleErrors)
		.then((res) => res.json());
}

export function getCurrentUser(url, code = null) {
	// console.log(`API getCurrentUser: ${url}/users/current`);
	const headers = Object.assign({}, code && { Authentication: code });
	return fetch(`${url}/users/current`, {
		method: "get",
		credentials: "include",
		headers,
	})
		.then(handleErrors)
		.then((res) => (res.status === 404 ? res : res.json()));
}

export const makeCurrentUserToFacilitator = async (
	url,
	sessionCode,
	query = {},
	code = null
) => {
	const queryStr = convertQueryToString({ ...query, sessionCode });
	const headers = Object.assign({}, code && { Authentication: code });
	return fetch(
		`${url}/members/upgrade-user-to-session-facilitator${queryStr}`,
		{
			method: "post",
			credentials: "include",
			headers,
		}
	)
		.then(handleErrors)
		.then((res) => {
			return res.json();
		});
};

//

export function loginSession(url, key, authPath = "/auth/session") {
	// console.log(`API login session: ${url + authPath}`);
	return fetch(url + authPath, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		credentials: "include",
		body: JSON.stringify({ key }),
	})
		.then(handleErrors)
		.then((res) => res.json())
		.catch((error) => error);
}

export function uploadFormData(url, sessionId, files, data = {}, onProgress) {
	var formData = new FormData();

	for (let i = 0; i < files.length; i++) {
		formData.append("files", files[i]);
	}

	for (var variable in data) {
		if (data.hasOwnProperty(variable)) {
			formData.append(variable, data[variable]);
		}
	}

	return new Promise((resolve, reject) => {
		var request = new XMLHttpRequest();

		request.upload.addEventListener(
			"progress",
			function (e) {
				// console.log(e.loaded, e.total, Math.ceil(e.loaded / e.total * 100) + "%");
				onProgress && onProgress(e);
			},
			false
		);

		request.onreadystatechange = function () {
			if (request.readyState === 4) {
				const response = JSON.parse(request.response);
				// console.log("response.data.files", response);
				resolve(response);
			}
		};

		request.upload.addEventListener("error", function (err) {
			reject(err);
			// console.log("Something went wrong.");
		});

		request.open(
			"POST",
			url + "/quidditySessions/" + sessionId + "/fileupload"
		);
		request.send(formData);
	});
}

export function shortURL(
	url,
	{ sourceUrl, prefix, usePrefixOnly } = {},
	path = "/short-url"
) {
	return fetch(`${url}${path}`, {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		method: "post",
		body: JSON.stringify({ url: sourceUrl, prefix, usePrefixOnly }),
		credentials: "include",
	})
		.then(handleErrors)
		.then((res) => res.json())
		.catch((error) => error);
}
