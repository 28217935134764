import React from "react";
import classnames from "classnames";
import { updatePageItem } from "../actions/PageItemActions";
import FFScrollVertical from "../components/FFScrollVertical";

class PageItemScrollVertical extends React.Component {
	_onScroll = (e) => {
		const { pageItem } = this.props;
		const { updateOnScroll } = pageItem;
		if (updateOnScroll) {
			updatePageItem(pageItem.id, e);
		}
	};

	_onScrollEnterStart = () => {
		const { pageItem, onScrollEnterStart } = this.props;
		!pageItem.viewPartial && updatePageItem(pageItem.id, { viewPartial: true });

		onScrollEnterStart && onScrollEnterStart(this.props);
	};

	_onScrollEnterEnd = () => {
		const { pageItem, onScrollEnterEnd } = this.props;
		!pageItem.viewComplete &&
			updatePageItem(pageItem.id, { viewComplete: true });

		onScrollEnterEnd && onScrollEnterEnd(this.props);
	};

	_onScrollExitStart = () => {
		const { onScrollExitStart } = this.props;

		onScrollExitStart && onScrollExitStart(this.props);
	};

	_onScrollExitEnd = () => {
		const { onScrollExitEnd } = this.props;

		onScrollExitEnd && onScrollExitEnd(this.props);
	};

	render() {
		const { pageItem, style, className, children } = this.props;
		return (
			<FFScrollVertical
				style={Object.assign({}, pageItem.style, style)}
				className={classnames(
					className,
					"page-item page-item-scroll-vertical",
					pageItem.className
				)}
				onScroll={this._onScroll}
				onScrollEnterStart={this._onScrollEnterStart}
				onScrollEnterEnd={this._onScrollEnterEnd}
				onScrollExitStart={this._onScrollExitStart}
				onScrollExitEnd={this._onScrollExitEnd}
			>
				{children}
			</FFScrollVertical>
		);
	}
}

export default PageItemScrollVertical;
