import React from "react";
import PropTypes from "prop-types";
import { getPage } from "../../Pages";
import Layers from "./Layers";
import _ from "lodash";

import * as EventConstants from "../../constants/EventConstants";
import * as StatusConstants from "../../constants/StatusConstants";
import * as PagesUtils from "../../utils/PagesUtils";
import * as PageActions from "../../redux/actions/PageActions";
import * as StoreUtils from "../../utils/StoreUtils";

const { imitateScrollProduction, undoImitiateScrollProduction } = PagesUtils;

class Page extends React.Component {
	static propTypes = {
		page: PropTypes.object.isRequired,
		locationPath: PropTypes.array.isRequired,
		location: PropTypes.array.isRequired,
	};

	isScrollPageInSlideProduction() {
		const app = _.get(StoreUtils.getReducer("app"), "item");
		const isSlideProduction =
			app.program_type === "slide" || app.original_program_type === "slide";

		const isPrintPage =
			_.get(this.props, "page.data.cmtPage.page_type") === "print";
		return isSlideProduction && isPrintPage;
	}

	componentDidMount() {
		const { page } = this.props;
		if (
			page.status === "unlocked" ||
			page.status === "not_attempted" ||
			page.status === StatusConstants.NOT_ATTEMPTED ||
			!page.status
		) {
			PageActions.updatePage(page.id, { status: StatusConstants.INCOMPLETE });
		}

		this.restoreScroll();

		if (this.isScrollPageInSlideProduction()) {
			imitateScrollProduction();
		}
	}

	saveScroll() {
		const { page } = this.props;
		const { winScrollBehaviour } = page;
		if (
			winScrollBehaviour &&
			["persist", "persist-if-incomplete"].includes(winScrollBehaviour)
		) {
			PageActions.updatePage(page.id, {
				winScroll: { scrollY: window.scrollY, scrollX: window.scrollX },
			});
		}
	}

	restoreScroll() {
		const { page } = this.props;
		const { winScrollBehaviour } = page;
		if (winScrollBehaviour) {
			const winScroll = Object.assign(
				{ scrollX: 0, scrollY: 0 },
				page.winScroll
			);
			switch (winScrollBehaviour) {
				case "top":
					window.scrollTo(window.scrollX, 0);
					break;
				case "left":
					window.scrollTo(0, window.scrollY);
					break;
				case "topLeft":
					window.scrollTo(0, 0);
					break;
				case "persist":
					setTimeout(() => {
						window.scrollTo(winScroll.scrollX, winScroll.scrollY);
					}, 100);
					break;
				case "persist-if-incomplete":
					if (page.status === "completed") {
						window.scrollTo(window.scrollX, 0);
					} else {
						setTimeout(() => {
							window.scrollTo(winScroll.scrollX, winScroll.scrollY);
						}, 100);
					}
					break;
				default:
			}
		}
	}

	componentWillUnmount() {
		this.saveScroll();

		if (this.isScrollPageInSlideProduction()) {
			undoImitiateScrollProduction();
		}
	}

	render() {
		const { page, location, locationPath } = this.props;
		const PageElement = getPage(page.type);

		return (
			<PageElement {...this.props}>
				<Layers page={page} location={location} locationPath={locationPath} />
			</PageElement>
		);
	}
}

export default Page;
