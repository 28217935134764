import _ from "lodash";
import { useSelector, shallowEqual } from "react-redux";

const QuiddityBaseQuidInputs = (props) => {
	const { renderFunction, match, quidInputIds } = props;

	const quidInputs = useSelector((state) => {
		let quidInputsById = state.quidInputs.itemsById;
		// If quidInputIds specified...
		if (quidInputIds && quidInputIds.length) {
			return quidInputIds.map((id) => quidInputsById[id]);
		}
		// If match object specified...
		if (match) {
			return _.filter(state.quidInputs.items, match, []);
		}

		return [];
	}, shallowEqual);

	return renderFunction({
		...props,
		quidInputs,
	});
};

export default QuiddityBaseQuidInputs;
