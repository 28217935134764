import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	removeItemInStateItems,
} from "../utils/reducerUtils";

import {
	UPDATE_UNITS,
	UPDATE_UNIT,
	REMOVE_UNIT,
} from "../constants/ActionConstants";

const units = (state = initialStateObjectCollection(), action) => {
	switch (action.type) {
		case UPDATE_UNITS:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_UNIT:
			return updateItemInStateItems(state, action.item);
		case REMOVE_UNIT:
			return removeItemInStateItems(state, action.item);
		default:
			return state;
	}
};

export default units;
