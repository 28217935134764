import * as API from "../api";
import { getCode } from "../utils/QuiddityUtils";
import * as QuidActions from "../redux/actions/QuidActions";

export const getQuids = async (url, query, addToStore = true) => {
	try {
		const quids = await API.getQuids(url, query, getCode());
		addToStore && QuidActions.updateQuids(quids);
		return quids;
	} catch (e) {
		return [];
	}
};

export const getQuid = async (url, query, addToStore = true) => {
	const quids = await getQuids(url, query, addToStore);
	return quids[0];
};
