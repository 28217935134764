import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	removeItemInStateItems,
} from "../utils/reducerUtils";

import {
	UPDATE_GROUPS,
	UPDATE_GROUP,
	REMOVE_GROUP,
} from "../constants/ActionConstants";

const groups = (state = initialStateObjectCollection(), action) => {
	switch (action.type) {
		case UPDATE_GROUPS:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_GROUP:
			return updateItemInStateItems(state, action.item);
		case REMOVE_GROUP:
			return removeItemInStateItems(state, action.item);
		default:
			return state;
	}
};

export default groups;
