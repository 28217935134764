import * as API from "../api";
import { getCode } from "../utils/QuiddityUtils";
import * as MemberActions from "../redux/actions/MemberActions";
import { getReducer } from "../utils/StoreUtils";

export const getMembers = async (url, query, addToStore = true) => {
	try {
		const members = await API.getMembers(url, query, getCode());
		addToStore && MemberActions.updateMembers(members);
		return members;
	} catch (error) {
		return [];
	}
};

export const getMember = async (url, query, addToStore = true) => {
	const members = await getMembers(url, query, addToStore);
	return members[0];
};

export const upsertMember = async (url, query, addToStore = true) => {
	const membersById = getReducer("members").itemsById;
	const member = membersById[query.id] || (await getMember(url, query));
	let upsertedMember = { ...member, ...query };
	addToStore &&
		member &&
		MemberActions.updateMember(upsertedMember.id, upsertedMember);
	upsertedMember = await API.postOrPutMember(url, upsertedMember, getCode());
	addToStore &&
		!member &&
		MemberActions.updateMember(upsertedMember.id, upsertedMember);
	return upsertedMember;
};

export const createMember = async (url, query, addToStore = true) => {
	const { quidditySession } = query;
	const member = await API.createMember(url, quidditySession, query, getCode());
	addToStore && MemberActions.updateMembers([member]);
	return member;
};

export const createMemberFromCurrentUser = async (
	url,
	sessionCode,
	query,
	addToStore = true
) => {
	const member = await API.makeCurrentUserToFacilitator(
		url,
		sessionCode,
		query,
		getCode()
	);
	addToStore && MemberActions.updateMembers([member]);
	return member;
};

export const getMembersForSessionAndUnits = async (
	url,
	query = { sessionId, units },
	addToStore = true
) => {
	try {
		const members = await API.getSessionMembersForUnits(url, query, getCode());
		addToStore && MemberActions.updateMembers(members);
		return members;
	} catch (error) {
		return [];
	}
};
