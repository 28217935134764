import { createSelector } from "reselect";

const getApp = (state) => state.app.item;

export const getAppSelector = createSelector(getApp, (app) => {
	return app;
});

export const getInspectorSelector = createSelector(getAppSelector, (app) => {
	return app.inspector;
});

export function makeGetAppSelector() {
	return getAppSelector;
}

export function makeGetInspectorSelector() {
	return getInspectorSelector;
}

export function makeGetApp() {
	console.warn(`"makeGetApp" is depricated. Please use "makeGetAppSelector" `);
	return getAppSelector;
}
