import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	removeItemInStateItems,
} from "../utils/reducerUtils";

import {
	UPDATE_MEMBERS,
	UPDATE_MEMBER,
	REMOVE_MEMBER,
} from "../constants/ActionConstants";

const members = (state = initialStateObjectCollection(), action) => {
	switch (action.type) {
		case UPDATE_MEMBERS:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_MEMBER:
			return updateItemInStateItems(state, action.item);
		case REMOVE_MEMBER:
			return removeItemInStateItems(state, action.item);
		default:
			return state;
	}
};

export default members;
