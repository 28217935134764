import _ from "lodash";

export function initialStateObject(item = undefined) {
	return {
		fetchStatus: undefined,
		item,
		stateSnapshots: {},
		pastItems: [],
		futureItems: [],
		didRedo: false,
		didUndo: false,
	};
}

export function initialStateObjectCollection(items = [], itemsById = {}) {
	return {
		fetchStatus: undefined,
		items,
		stateSnapshots: {},
		itemsById,
	};
}

export function undoStateItem(state) {
	if (state.pastItems.length) {
		const newState = { ...state };
		const previousItem = newState.pastItems[state.pastItems.length - 1];
		const newPastItems = newState.pastItems.slice(
			0,
			state.pastItems.length - 1
		);
		newState.item = previousItem;
		newState.pastItems = newPastItems;
		newState.futureItems = [state.item, ...newState.futureItems];
		newState.didUndo = true;
		newState.didRedo = false;
		return newState;
	} else {
		return state;
	}
}

export function redoStateItem(state) {
	// debugger;
	if (state.futureItems.length) {
		const newState = { ...state };
		const nextItem = newState.futureItems[0];
		const newFutureItems = newState.futureItems.slice(1);
		newState.item = { ...nextItem };
		newState.pastItems = [...state.pastItems, state.item];
		newState.futureItems = [...newFutureItems];
		newState.didUndo = false;
		newState.didRedo = true;
		return newState;
	} else {
		return state;
	}
}

export function updateStateItem(state, item) {
	const newState = Object.assign({}, state, {
		item: updateObject(state.item, item),
		futureItems: [],
		didUndo: false,
		didRedo: false,
	});
	state.item && newState.pastItems.push(state.item);
	return newState;
}

export function updateItemInState(state, oldItem, newItem) {
	return (Object.assign({}, state).item = updateObject(oldItem, newItem));
}

export function updateItemInStateItems(state, item) {
	const stateCopy = { ...state };
	const oldItem = stateCopy.itemsById[item.id];
	const newItem = Object.assign({}, oldItem, item);

	stateCopy.itemsById[newItem.id] = newItem;

	if (oldItem) {
		stateCopy.items = updateItemInArray(stateCopy.items, newItem);
		return stateCopy;
	} else {
		return addItemInStateItems(stateCopy, item);
	}
}

export function updateItemsInStateItems(state, items) {
	let newState = { ...state };
	items.forEach((item) => {
		newState = updateItemInStateItems(newState, item);
	});
	return newState;
}

export function resetItemsInStateItems(state) {
	return { ...state };
}

export function makeStateSnapshot(state, item) {
	const newState = { ...state };
	newState.stateSnapshots[item.key] = _.omit({ ...newState }, "stateSnapshots");
	return newState;
}

export function restoreStateSnapshot(state, item) {
	const newState = { ...state };
	const stateSnapshots = newState.stateSnapshots;
	const stateSnapshot = newState.stateSnapshots[item.key];
	return stateSnapshot ? { ...stateSnapshot, stateSnapshots } : newState;
}

export function addItemInStateItems(state, item) {
	let stateCopy = { ...state };
	let itemsCopy = [...stateCopy.items];
	itemsCopy.push(item);
	stateCopy.itemsById[item.id] = item;
	return Object.assign({}, state, { items: itemsCopy });
}

export function removeItemInStateItems(state, item) {
	let stateCopy = { ...state };
	let itemsCopy = [...stateCopy.items];
	delete stateCopy.itemsById[item.id];
	return Object.assign({}, state, {
		items: removeItemInArray(itemsCopy, item),
	});
}

export function removeItemsInStateItems(state, item) {
	let stateCopy = { ...state };
	let itemsCopy = [...stateCopy.items];
	const itemsToRemove = itemsCopy.filter((itemCopy) =>
		item.ids.includes(itemCopy.id)
	);
	itemsToRemove.forEach((itemToRemove) => {
		delete stateCopy.itemsById[itemToRemove.id];
	});
	return Object.assign({}, state, {
		items: removeItemsInArray(itemsCopy, itemsToRemove),
	});
}

export function removeValueInState(state, key) {
	let stateCopy = Object.assign({}, state);
	delete stateCopy[key];
	return stateCopy;
}

export function removeValueInStateItem(state, key) {
	let stateCopy = Object.assign({}, state);
	delete stateCopy.item[key];
	return stateCopy;
}

export function changeIndexForItemInStateItems(state, item, toIndex) {
	let stateItemsCopy = state.items.concat();
	const fromIndex = stateItemsCopy.findIndex(
		(stateItem) => stateItem.id === item.id
	);
	if (fromIndex !== -1) {
		arrayChangeIndex(stateItemsCopy, fromIndex, toIndex);
		return Object.assign({}, state, { items: stateItemsCopy });
	} else {
		console.warn(`Index for item ${item.id} cold not be found!`);
		return state;
	}
}

// Array and Object utils

export function resetObject(oldObj, newObj) {
	return newObj;
}

export function updateObject(oldObj, newObj) {
	return Object.assign({}, oldObj, newObj);
}

export function updateItemInArray(items, item) {
	let itms = items.concat();
	const index = items.findIndex((itm) =>
		item.id ? itm.id === item.id : itm._id === item._id
	);
	if (index > -1) {
		itms[index] = item;
	}

	return itms;
}

export function removeItemInArray(array, item) {
	let arrayCopy = [...array];
	const itemIndex = arrayCopy.findIndex((oldItem) =>
		item.id ? oldItem.id === item.id : oldItem._id === item._id
	);
	if (itemIndex > -1) {
		arrayCopy.splice(itemIndex, 1);
	}

	return arrayCopy;
}

export function removeItemsInArray(array, items) {
	let newArray = array;
	items.forEach((item) => {
		newArray = removeItemInArray(newArray, item);
	});
	return newArray;
}

function arrayChangeIndex(arr, fromIndex, toIndex) {
	var element = arr[fromIndex];
	arr.splice(fromIndex, 1);
	arr.splice(toIndex, 0, element);
}
