import React, { useEffect } from "react";
import classnames from "classnames";
import { FFText, PageItemActions, PageItemImage } from "funkis-foundation";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";
import Text from "../../../components/core/Text";
import IconQuote from "../../../assets/svg/IconQuote";
import { getBootstrapColor } from "../../../utils/colorUtils";
import Style from "./PageItemQuote.module.css";

const ContentWrapper = ({ pageItem, children, className, isComplete }) => {
	const { cmtPageItem = {}, status } = pageItem;
	const image = cmtPageItem.background_image;

	useEffect(() => {
		if (isComplete && status !== "completed") {
			PageItemActions.updatePageItem(pageItem.id, { status: "completed" });
		}
	}, [status, isComplete]);

	return (
		<PageItemImage
			pageItem={{
				src: image,
				backgroundImage: true,
				autoSize: cmtPageItem.image_max_height,
				width: "auto",
				height: cmtPageItem.image_max_height
					? cmtPageItem.image_max_height
					: "auto",
			}}
			className={classnames(
				className,
				"w-100",
				cmtPageItem.image_size === "crop" && Style.backgroundSizeContain, // compability with <v2
				cmtPageItem.image_size === "keep ratio" && Style.backgroundSizeCover, // compability with <v2
				cmtPageItem.image_size === "cover" && Style.backgroundSizeCover,
				cmtPageItem.image_size === "contain" && Style.backgroundSizeContain,
				cmtPageItem.image_size === "auto" && Style.backgroundSizeAuto,
				cmtPageItem.image_align === "center" && Style.alignCenterCenter,
				cmtPageItem.image_align === "left" && Style.alignLeft,
				cmtPageItem.image_align === "right" && Style.alignRight
			)}
			style={{
				backgroundColor: getBootstrapColor(
					cmtPageItem.background_color || "white"
				),
			}}
		>
			{children}
		</PageItemImage>
	);
};

const PageItemQuote = (props) => {
	const { pageItem } = props;
	const { cmtPageItem = {} } = pageItem;
	const quote = cmtPageItem.quote;
	const author = cmtPageItem.author;
	const hasImage =
		cmtPageItem.background_image && cmtPageItem.background_image != "";
	const textColor = getBootstrapColor(
		cmtPageItem.text_color || (hasImage ? "white" : "black")
	);

	return (
		<PageItemScrollBase
			{...props}
			className={classnames("px-5")}
			// "overflow-hidden" or else the fadeInRight causes side scroll which also messes up auto scroll in
			containerClassName="overflow-hidden"
			renderFunction={(renderProps) => (
				<ContentWrapper
					{...props}
					className="position-relative d-flex flex-column justify-content-center px-4 py-6 px-sm-5 pt-sm-4 pb-sm-6"
					animate={renderProps.viewVisible}
					isComplete={renderProps.wasViewComplete}
				>
					<IconQuote
						className={classnames(
							"mb-2 mb-sm-4 animated",
							renderProps.viewVisible ? "fadeIn" : "fadeOut"
						)}
						color={textColor}
					/>
					<FFText
						className={classnames(
							"mb-2 mb-sm-4 quote animated font-italic",
							renderProps.viewVisible ? "fadeInRight" : "fadeOutRight"
						)}
						style={{ color: textColor }}
						tagName="blockquote"
						textId={quote}
					/>
					<IconQuote
						className={classnames(
							"mb-2 mb-sm-4 animated",
							renderProps.viewVisible ? " fadeIn" : "fadeOut"
						)}
						color={textColor}
					/>
					<Text
						className={classnames(
							"mb-4 mb-sm-3 caption-sm author animated font-italic",
							renderProps.viewVisible ? " fadeInUp delay-1s" : "fadeOutDown"
						)}
						style={{ color: textColor }}
						textId={author}
					/>
				</ContentWrapper>
			)}
		/>
	);
};

export default PageItemQuote;
