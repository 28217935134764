import _ from "lodash";
import z from "zod";

import {
	Actions,
	PageActions,
	PageItemActions,
	ConfigActions,
	AppActions,
	TextActions,
	SubtitleActions,
	QuidInputActions,
	LoadUtils,
	StoreUtils,
	StorageUtils,
	LocationUtils,
	Reducers,
	CMT,
} from "funkis-foundation";

import {
	quidInputsReducer,
	quidsReducer,
	groupsReducer,
	unitsReducer,
	membersReducer,
	quiddityReducer,
} from "quiddity";
import {
	compabilityParsePages,
	compabilityParsePageItems,
} from "../utils/cmtUtils";

import { validatePageItems } from "../models/pageitem";
import { selectClosestNavigatorLanguage } from "../utils/languageUtils";

// ADD REDCUERS
Reducers.addReducer("quidInputs", quidInputsReducer);
Reducers.addReducer("quids", quidsReducer);
Reducers.addReducer("quiddity", quiddityReducer);
Reducers.addReducer("groups", groupsReducer);
Reducers.addReducer("units", unitsReducer);
Reducers.addReducer("members", membersReducer);

export const setup = async ({ basePath }) => {
	const query = LocationUtils.getQueryObjectFromCurrentUrl();

	// Add windows functions
	window.enableDevMode = () => ConfigActions.updateConfig({ devMode: true });
	window.disableDevMode = () => ConfigActions.updateConfig({ devMode: false });

	// Load config
	const config = await LoadUtils.loadConfig(undefined, basePath);

	const language = query.language || config.language;

	// Get query param project to load content from different source...
	const { project } = query;
	const originalContentPath = "content/";
	const contentPath = project
		? `content/projects/${project}/public/content/`
		: originalContentPath;
	config.paths.basePath = project
		? `./content/projects/${project}/public/`
		: "";

	const fetchJson = (path, options = { defaultValue: [] }) =>
		// don't use fetch(path) because that, for some reason, can cause 401 or 403 on IE 11 and OLD Edge versions (v41 tested to have this bug),
		// by not including auth cookies internal to the LMS
		// MAYBE it would work with fetch(path, { credentials: 'include' }) but the implementation in loadJSON seem to work fine.
		LoadUtils.loadJSON(path).catch((err) => {
			console.error("failed to load path ", path, err);
			debugger;
			if (options.defaultValue) {
				console.log(
					`Failed to load ${path}, but file is marked as optional. Will load with defaultValue of ${JSON.stringify(
						options.defaultValue
					)}`
				);
				return options.defaultValue;
			}
		});

	const preloadFiles = {
		cmtTexts: await fetchJson(
			`${contentPath}language/${language}/cmt_text_${language}.json`
		),
		cmtSubtitles: await fetchJson(
			`${contentPath}language/${language}/cmt_subtitles_${language}.json`
		),
		cmtPages: await fetchJson(`${contentPath}json/cmt_page.json`),
		cmtPageItems: await fetchJson(`${contentPath}json/cmt_page-item.json`),
		cmtParseSchemes: await fetchJson(
			// use originalContentPath because this one is never different for different devProjects
			`${originalContentPath}json/cmt_parse_schemes.json`
		),
	};

	const disabelParser = config.cmt.parse === false;
	const cmtSubtitles = preloadFiles.cmtSubtitles || [];

	SubtitleActions.updateSubtitles(cmtSubtitles);

	const cmtParseSchemes = preloadFiles.cmtParseSchemes;
	const _cmtPages = preloadFiles.cmtPages;

	const introductionPage = _cmtPages.find(
		(page) => page.page_type === "introduction"
	);
	if (introductionPage) {
		// Mutate object, which is still in the array and is passed on.
		// page_type === "introduction" should has some default attributes that FAT can't guarantee as of now
		introductionPage.page_footer_navigation = "none";
		introductionPage.visible = "yes";
	}

	const cmtPages = compabilityParsePages(_cmtPages);
	const cmtPageItems = compabilityParsePageItems(preloadFiles.cmtPageItems);
	const cmtTexts = preloadFiles.cmtTexts || [];

	const result = await CMT.parse({
		disable: disabelParser,
		cmtParseSchemes,
		cmtPages,
		cmtPageItems,
		cmtTexts,
		projectTexts: [],
		projectActions: [],
		template: config.cmt.template,
	});

	// update
	PageActions.updatePages(result.pages);
	PageItemActions.updatePageItems(result.pageItems);
	TextActions.updateTexts(result.texts);

	// remove
	PageItemActions.removePageItems(result.diffPageItemIds);

	const newConfig = { ...config, ...result.config };
	ConfigActions.updateConfig(newConfig);

	const isScormReady = await waitForServiceIsReady({
		name: "scorm",
		config: newConfig,
		validate: validateIsScormReady,
		maxWaitMs: 10000,
	});

	/*  
      NOTE:  if adding custom locationStoreObserver, make sure to test with with
      storage: scorm12/scorm2004
      because a delayed setup (waiting for LMS connection to be ready)
      may conflict with the locationStoreObserver in Location.jsx (funkis-foundation)
    */

	const loadedLanguage = await loadLanguage({ config, result });

	try {
		const validatedPageItems = validatePageItems(preloadFiles.cmtPageItems);
		console.log("Validated page items:", validatedPageItems);
	} catch (e) {
		if (e instanceof z.ZodError) {
			const validationIssues = e.issues.map((issue) => {
				const [pageItemIndex] = issue.path;
				const pageItem = preloadFiles.cmtPageItems[pageItemIndex];

				return { ...issue, pageItem };
			});

			console.log("Validation issues", validationIssues);
		}
	}

	return {
		config: newConfig,
		app: StoreUtils.getReducer("app").item,
		isScormReady,
		language: loadedLanguage,
	};
};

export const addStoreObservers = ({ config, restore = true }) => {
	// console.log("setupStoreObservers", { restore });
	// Make restore snapshots of store states...
	AppActions.makeAppSnapshot({ key: "initialSetup" });
	PageActions.makePagesSnapshot({ key: "initialSetup" });
	PageItemActions.makePageItemsSnapshot({ key: "initialSetup" });
	ConfigActions.makeConfigSnapshot({ key: "initialSetup" });
	QuidInputActions.makeQuidInputsSnapshot({ key: "initialSetup" });

	// Setup store observers...
	addConfigStoreObserver({ config, restore });
	addPageStoreObserver({ config, restore });
	addPageItemsStoreObserver({ config, restore });
	addAppStoreObserver({ config, restore });

	// If not quiddity use quidInputs reducer as storage...
	const backends = ["lms", "local", "session"];
	const backend = _.get(StoreUtils.getReducer("app").item, "backend");
	if (backends.includes(backend)) {
		addQuidInputsStoreObserver({ config, restore });
	}
};

const loadLanguage = async ({ config, result }) => {
	const query = LocationUtils.getQueryObjectFromCurrentUrl();

	const navigatorLanguageCode = selectClosestNavigatorLanguage(
		result.config.installedLanguages
	);

	const languageToLoad =
		query.language ??
		navigatorLanguageCode ??
		result?.config?.language ??
		config.language;

	const updateConfigLanguageValue = true;
	const language = await Actions.loadLanguage(
		languageToLoad,
		updateConfigLanguageValue
	);
	setHtmlLangAttribute(languageToLoad);
	return language;
};

const waitForServiceIsReady = ({
	config,
	name,
	validate,
	maxWaitMs = 10000,
}) => {
	return new Promise((resolve, reject) => {
		if (config.storage.includes(name)) {
			const startTime = new Date().getTime();
			let duration = 0;
			const INTERVAL_FREQUENCY_MS = 250;
			const interval = setInterval(() => {
				const serviceIsReady = validate();
				const hasTimeLeft = duration < maxWaitMs;
				if (!serviceIsReady && hasTimeLeft) {
					// Fail - try again later
					console.log(`---
        ${name} NOT ready after waiting ${duration}ms - trying again in ${INTERVAL_FREQUENCY_MS}ms
        ---`);
				} else if (!serviceIsReady && !hasTimeLeft) {
					// Fail by timeout
					console.log(`---
        ${name} NOT ready after ${duration}ms - cancel waiting because maxWaitMs ${maxWaitMs}ms is reached 
        ---`);

					resolve(true);
					alert(
						"The LMS took too long to respond. Please close the window and try again. If you continue despite this warning, all progress may be lost."
					);
					clearInterval(interval);
				} else {
					// Success
					console.log(`---
        ${name} READY after waiting ${duration}ms 
        ---`);

					resolve(true);
					clearInterval(interval);
				}
				duration = new Date().getTime() - startTime;
			}, INTERVAL_FREQUENCY_MS);
		} else {
			resolve(true);
		}
	});
};

const validateIsScormReady = () => {
	const pipwerks = window.pipwerks;
	const apiIsFound = _.get(pipwerks, "SCORM.API.isFound", false);
	const connectionIsActive = _.get(
		pipwerks,
		"SCORM.connection.isActive",
		false
	);
	return apiIsFound && connectionIsActive;
};

const setHtmlLangAttribute = (language = "en") => {
	try {
		document.querySelector("html").setAttribute("lang", language.split("-")[0]);
	} catch (error) {
		console.error(error);
	}
};

export const setInitialCourseStatus = ({ config, status = "incomplete" }) => {
	// Note: only implemented for backend "quiddity" & "local"
	const storages = ["local", "quiddity"];
	if (storages.includes(config.storage)) {
		const courseStatusOrPromise = StorageUtils.getCourseStatus();
		if (courseStatusOrPromise && courseStatusOrPromise.then) {
			courseStatusOrPromise.then((res) => {
				// console.log("setInitialCourseStatus", { res });
				if (res === "not_attempted") {
					StorageUtils.setCourseStatus("incomplete");
				}
			});
		}
	}
};

const addConfigStoreObserver = ({ config, restore }) => {
	StoreUtils.addStoreObserver(
		"config",
		config.storage,
		config.projectPrefix,
		(item) => {
			restore && ConfigActions.updateConfig(item || {});
		},
		(newState, oldState) => {
			const newConfig = (newState && newState.item) || { language: "en" };
			const { language } = newConfig;

			// Load text file if language changes
			if (newState.item.language !== oldState.item.language) {
				// Load text with selected language
				Actions.loadLanguage(language || config.language);
				setHtmlLangAttribute(language || config.language);
			}
			// keep internal state up to date, or else "showInspector() will not work"
			// _self.setState({ config });

			return { language };
		}
	);
};

const addPageStoreObserver = ({ config, restore }) => {
	StoreUtils.addStoreObserver(
		"pages",
		config.storage,
		config.projectPrefix,
		(pages) => {
			restore && PageActions.updatePages(pages || []);
		},
		(newState) => {
			const pages = (newState && newState.items) || [];
			return pages.map((page) => {
				return { id: page.id, status: page.status };
			});
		}
	);
};

const addQuidInputsStoreObserver = ({ config, restore = true }) => {
	StoreUtils.addStoreObserver(
		"quidInputs",
		config.storage,
		config.projectPrefix,
		(quidInputs) => {
			restore && QuidInputActions.updateQuidInputs(quidInputs || []);
		},
		(newState) => {
			return (newState && newState.items) || [];
		}
	);
};

const addPageItemsStoreObserver = ({ config, restore }) => {
	StoreUtils.addStoreObserver(
		"pageItems",
		config.storage,
		config.projectPrefix,
		(pageItems) => {
			restore && PageItemActions.updatePageItems(pageItems || []);
		},
		(newState) => {
			const pageItems = newState.items;

			// Save selected language (also saved in config)
			const languageMenus = pageItems
				.filter((pageItem) => pageItem.friendlyId === "languageMenu")
				.map((pageItem) => ({
					id: pageItem.id,
					selectedIndexes: pageItem.selectedIndexes,
				}));

			// Save course status page items
			const courseStatuses = pageItems
				.filter((pageItem) => pageItem.type === "course-status")
				.map((pageItem) => ({ id: pageItem.id, status: pageItem.status }));

			// Save reveals
			const reveals = pageItems
				.filter((pageItem) => pageItem.reveal)
				.map((pageItem) => ({
					id: pageItem.id,
					reveal: pageItem.reveal,
					revealed: pageItem.revealed,
				}));

			// Save autoScrolls
			const autoScrolls = pageItems
				.filter(
					(pageItem) =>
						pageItem.hasOwnProperty("autoScroll") ||
						pageItem.hasOwnProperty("autoScrollOnReval") ||
						pageItem.hasOwnProperty("autoScrollOnShowNextButton")
				)
				.map((pageItem) =>
					_.pick(pageItem, [
						"id",
						"autoScroll",
						"autoScrollOnReval",
						"autoScrollOnShowNextButton",
					])
				);

			// Save wasViewComplete
			const viewCompleted = pageItems
				.filter((pageItem) => pageItem.wasViewComplete)
				.map((pageItem) => ({
					id: pageItem.id,
					wasViewComplete: pageItem.wasViewComplete,
				}));

			// Save status of all page items that explicitly want to store their completion status
			const statusPageItems = pageItems
				.filter((pageItem) => pageItem.shouldSaveStatus)
				.map((pageItem) => ({
					id: pageItem.id,
					status: pageItem.status,
					shouldSaveStatus: true,
				}));

			// Save all page items with "selectedIndexes"
			const selectedIndexesPageItems = pageItems
				.filter((pageItem) => pageItem.selectedIndexes)
				.map((pageItem) => ({
					id: pageItem.id,
					selectedIndexes: pageItem.selectedIndexes,
					status: pageItem.status,
				}));

			// Save quiz status
			const quizPageItems = pageItems
				.filter((pageItem) => pageItem.type === "io-quiz-input")
				.map((pageItem) => ({ id: pageItem.id, status: pageItem.status }));

			// Save trait status
			const traitPageItems = pageItems
				.filter(
					(pageItem) => pageItem.type === "io-sprig-story-trait-selection"
				)
				.map((pageItem) => ({ id: pageItem.id, status: pageItem.status }));

			return _.flatten([
				languageMenus,
				reveals,
				autoScrolls,
				selectedIndexesPageItems,
				statusPageItems,
				courseStatuses,
				viewCompleted,
				quizPageItems,
				traitPageItems,
			]);
		}
	);
};

const addAppStoreObserver = ({ config, restore }) => {
	StoreUtils.addStoreObserver(
		"app",
		config.storage,
		config.projectPrefix,
		(app) => {
			restore &&
				AppActions.updateAppValue({
					...app,
					userName: StorageUtils.getUserName(),
					userId: StorageUtils.getUserId(),
					restoreLocation: app && app.location,
				});
		},
		(newState, oldState) => {
			const appNewState = (newState && newState.item) || {};
			const appOldState = (oldState && oldState.item) || {};
			const {
				courseStatus,
				completionStatus,
				successStatus,
				rawScore,
				minScore,
				maxScore,
			} = appNewState;
			// Success Status (reports to lms)
			if (appNewState.courseStatus !== appOldState.courseStatus) {
				StorageUtils.setCourseStatus(courseStatus);
			}
			// Complettion Status (reports to lms)
			if (appNewState.completionStatus !== appOldState.completionStatus) {
				StorageUtils.setCompletionStatus(completionStatus);
			}
			// Success Status (reports to lms)
			if (appNewState.successStatus !== appOldState.successStatus) {
				StorageUtils.setSuccessStatus(successStatus);
			}
			// Score (reports to lms)
			if (appNewState.rawScore !== appOldState.rawScore) {
				StorageUtils.setScore(rawScore, minScore, maxScore);
			}
			// Inspector
			const { inspector } = newState.item;
			// Location
			const { location } = newState.item;
			// Main menu revealed by user (scroll)
			const { mainMenuRevealedByUser } = newState.item;

			// Save to storage (suspend data)
			return {
				courseStatus,
				completionStatus,
				successStatus,
				rawScore,
				minScore,
				maxScore,
				inspector,
				location,
				mainMenuRevealedByUser,
			};
		}
	);
};
