import React, { useState } from "react";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import Backend from "../../components/backend/Backend";
import Text from "../../components/core/Text";
import ImageWithCaption from "../../components/core/ImageWithCaption";
import DraggableActionTarget from "../../components/core/DraggableActionTarget";
import DraggableActionItem from "../../components/core/DraggableActionItem";
import DraggableBullet from "../../components/core/DraggableBullet";
import ActionPriorityMatrix from "../../components/core/ActionPriorityMatrix";

const DropTarget = (props) => {
	const { pageItem, onDrop } = props;
	const { cmtPageItem } = pageItem;
	const [dropData, setDropData] = useState();

	const onDropHandler = (e) => {
		const dragData = JSON.parse(e.dataTransfer.getData("Text"));
		const dropData = {
			dropX: e.dropX,
			dropY: e.dropY,
			dropValueX: e.dropValueX,
			dropValueY: e.dropValueY,
		};
		setDropData({ dragData, ...dropData });
		onDrop && onDrop({ dragData, ...dropData });
	};

	return (
		<DraggableActionTarget onDrop={onDropHandler}>
			{
				<ActionPriorityMatrix>
					<Backend
						{...props}
						className="w-100 h-100"
						quidType="action"
						mode="inputs"
						renderInputs={(renderProps) => {
							const { quidInput, save } = renderProps;
							const { index = 0, effort, impact } = quidInput.data;
							return (
								<DraggableBullet
									number={index + 1}
									draggable
									style={{
										position: "absolute",
										top: 100 * impact + "%",
										left: 100 * effort + "%",
									}}
									onDragEnd={(e) => {
										const { dropValueX, dropValueY } = dropData;
										save(false, {
											...quidInput.data,
											effort: dropValueX,
											impact: dropValueY,
										});
									}}
								/>
							);
						}}
					/>
				</ActionPriorityMatrix>
			}
		</DraggableActionTarget>
	);
};

const PageItemIOActionPriorityMatrixInput = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;
	const [completed, setCompleted] = useState();
	const [canSave, setCanSave] = useState();
	const [dropData, setDropData] = useState();

	return (
		<PageItemScrollBase {...props} showNextButton={completed || canSave}>
			{cmtPageItem.media && (
				<ImageWithCaption src={cmtPageItem.media} className="col-sm-12 mb-2" />
			)}
			<Text
				className="col-md-8 mb-5"
				tagName="h2"
				textId={cmtPageItem.header}
			/>
			<Text className="col-md-10 mb-4" tagName="p" textId={cmtPageItem.body} />
			<Text
				className="col-md-6"
				tagName="h5"
				textId={cmtPageItem.label_input_1}
			/>
			<div className="px-6 mt-6 col-sm-12 justify-content-center"></div>
			<DropTarget
				{...props}
				onDrop={(e) => {
					setDropData(e);
				}}
			/>
			<div className="row m-0 mt-4">
				{cmtPageItem.content_array.map((content, index) => (
					<Backend
						{...props}
						className="col-md-4 col-sm-6 p-0 mb-1"
						quidType="action"
						mode="input"
						targetDataKey={content.action}
						renderInput={(renderProps) => {
							const {
								hasSavedDataForKeys,
								hasUnsavedDataForKeys,
								save,
								quidInput,
							} = renderProps;
							setCompleted(hasSavedDataForKeys(["impact", "effort"]));
							setCanSave(hasUnsavedDataForKeys(["impact", "effort"]));

							return (
								<DraggableActionItem
									number={index + 1}
									textId={content.action}
									dragData={{ ...content, index }}
									draggable={quidInput === undefined}
									onDragEnd={(e) => {
										const { dropValueX, dropValueY } = dropData;
										save(false, {
											effort: dropValueX,
											impact: dropValueY,
											index,
										});
									}}
								></DraggableActionItem>
							);
						}}
					/>
				))}
			</div>
		</PageItemScrollBase>
	);
};

export default PageItemIOActionPriorityMatrixInput;
