import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { PageItemAnchor, FFButton, PagesUtils } from "funkis-foundation";
import { getText } from "../../utils/textUtils";
import Text from "../core/Text";

import Style from "./Link.module.css";
import PrintPage from "./PrintPage";
import { getBootstrapColor } from "../../utils/colorUtils";
import Hover from "./Hover";
import { updateLocation } from "../../actions/Actions";
import Bullet from "./Bullet";

const paths = { pdf: "content/pdf/" };
const localFiles = ["pdf"];

export const Wrapper = ({ url = "", children, className, modal }) => {
	const printURL = url.indexOf("print://") > -1 && url.slice(8, url.length);
	// initial state null is needed to differentiate between
	// first render (should not restore scroll) and first close (should restore scroll)
	const [show, setShow] = useState(null);
	const [hasSavedScrollPosition, setHasSavedScrollPosition] = useState(false);

	useEffect(() => {
		// This lets ut keep scroll position when we come back
		// even though we never actually never leave or re-enter the page
		if (modal && printURL && show) {
			PagesUtils.saveScrollPositionForPage();
			// give some time to save the position before "changing page"
			setTimeout(() => setHasSavedScrollPosition(true), 100);
		}
		if (modal && printURL && show === false) {
			PagesUtils.restoreScrollPositionForPage();
			setHasSavedScrollPosition(false);
		}
	}, [printURL, show, modal]);

	if (printURL) {
		return (
			<FFButton
				className={classnames("default-link-style", className)}
				enabled={!show}
				onClick={() => {
					if (modal) {
						setShow(true);
					} else {
						updateLocation({ friendlyId: printURL });
					}
				}}
			>
				{children}
				<PrintPage
					show={show && hasSavedScrollPosition}
					friendlyId={printURL}
					onCloseClick={() => {
						setTimeout(() => {
							setShow(false);
						}, 100);
					}}
				></PrintPage>
			</FFButton>
		);
	} else {
		return (
			<PageItemAnchor
				pageItem={{ href: url, target: "_blank" }}
				className={classnames("w-100", className)}
			>
				{children}
			</PageItemAnchor>
		);
	}
};

const Link = ({
	href,
	icon,
	file: fileName,
	header,
	body,
	className,
	classNameTextContainer,
	classNameBullet,
	classNameHeader,
	classNameBody,
	textColor = "black",
	modal = true,
	bulletSize,
}) => {
	const maybeFileName = fileName || icon || "";
	const file = maybeFileName.substr(
		maybeFileName.indexOf(".") + 1,
		maybeFileName.length
	);
	const path = paths[file];
	const url = localFiles.includes(file)
		? path + maybeFileName
		: getText(href) || "";

	return (
		<Wrapper
			url={url}
			className={classnames(
				"link w-100 d-flex align-items-center flex-nowrap",
				className
			)}
			modal={modal}
		>
			<div
				className={classnames(
					"flex-grow-0 mr-2",
					Style.bullet,
					classNameBullet
				)}
			>
				<Bullet image={icon} className="bullet-test" size={bulletSize} />
			</div>

			<Hover
				className={classnames(
					Style.textContainer,
					"flex-grow-0 flex-column",
					classNameTextContainer
				)}
			>
				{({ hover }) => (
					<React.Fragment>
						<Text
							textId={header}
							className={classNameHeader}
							style={{
								color: hover
									? getBootstrapColor("interaction")
									: getBootstrapColor(textColor),
							}}
						/>
						<Text
							textId={body}
							className={classNameBody}
							style={{
								color: hover
									? getBootstrapColor("interaction")
									: getBootstrapColor(textColor),
							}}
						/>
					</React.Fragment>
				)}
			</Hover>
		</Wrapper>
	);
};

export default Link;
