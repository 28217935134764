import classnames from "classnames";
import React from "react";
import Masonry from "components/Masonry/Masonry";
import Text from "funkis-template/components/core/Text";
import IOCommentOutput from "./IOCommentOutput";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { QuidType, useBackend } from "funkis-template/hooks/backend";
import { useViewportSize, ViewportSize } from "funkis-template/hooks/player";
import { CommentOutputScrollBlock } from "funkis-template/models/pageitem";
import { PageItem } from "funkis-template/models/player";

const PageItemIOCommentOutput: React.FC<{
	pageItem: PageItem<CommentOutputScrollBlock>;
}> = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;

	const { quidInputs } = useBackend({
		targetDataKey: cmtPageItem.io_id,
		inputContext: cmtPageItem.io_context,
		quidType: QuidType.Comment,
	});

	const filteredQuidInputs =
		cmtPageItem.filter_selected === "yes"
			? quidInputs.filter((quidInput) => Boolean(quidInput.data.selected))
			: quidInputs;

	const viewportSize = useViewportSize();
	const columnCount =
		viewportSize <= ViewportSize.Mobile || cmtPageItem.desktop_columns === "one"
			? 1
			: 3;

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={true}
			renderFunction={(renderProps: { viewVisible: boolean }) => {
				return (
					<React.Fragment>
						<div className={classnames("row m-0")}>
							{/* HEADER */}
							<Text
								className={classnames(
									"col-sm-12 mb-5 font-weight-bold animated",
									renderProps.viewVisible ? "fadeIn" : "fadeOut"
								)}
								tagName="h2"
								textId={cmtPageItem.header}
							/>
							{/* BODY */}
							<Text
								className={classnames(
									"col-sm-12 mb-4 animated",
									renderProps.viewVisible ? "delay-250ms fadeIn" : "fadeOut"
								)}
								tagName="p"
								textId={cmtPageItem.body}
							/>
						</div>
						<div className="row m-0">
							<div className="col-sm-12">
								<Masonry columns={columnCount}>
									{filteredQuidInputs.map((quidInput) => (
										<IOCommentOutput
											key={`comment-${quidInput.id}`}
											quidInput={quidInput}
											data={quidInput.data}
											backgroundColor={cmtPageItem.comment_background_color}
											textColor={cmtPageItem.comment_text_color}
											barColor={cmtPageItem.comment_bar_color}
											showUser={cmtPageItem.show_comment_author !== "no"}
											showDate={cmtPageItem.show_comment_date !== "no"}
										/>
									))}
								</Masonry>
							</div>
						</div>
					</React.Fragment>
				);
			}}
		></PageItemScrollBase>
	);
};

export default PageItemIOCommentOutput;
