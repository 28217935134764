import React from "react";
import classnames from "classnames";
import IconPerson from "../../assets/svg/IconPerson";
import IconCalender from "../../assets/svg/IconCalender";

const ActionItem = ({
	header,
	body,
	icon,
	prio,
	className,
	classNameBullet = "bg-x-dark-gray mr-2",
	classNameHeader = "p-md font-weight-bold",
	classNameBody = "p-md",
}) => {
	let Icon = <div />;
	switch (icon) {
		case "person":
			Icon = <IconPerson />;
			break;
		case "calender":
			Icon = <IconCalender />;
			break;
		case "prio":
			Icon = (
				<div className="text-white button-label-sm font-weight-bold">
					{prio}
				</div>
			);
			break;
	}
	return (
		<div className={classnames("d-flex", className)}>
			<div
				style={{ width: "45px", height: "45px" }}
				className={classnames(
					"d-flex justify-content-center align-items-center flex-shrink-0",
					classNameBullet
				)}
			>
				{Icon}
			</div>
			<div className="d-flex flex-column">
				<span className={classNameHeader}>{header}</span>
				<span className={classNameBody}>{body}</span>
			</div>
		</div>
	);
};

export default ActionItem;
