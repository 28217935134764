import React from "react";

const IconSkip = () => {
	return (
		<svg
			width="25px"
			height="30px"
			viewBox="0 0 25 30"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="Group-6" transform="translate(-34.000000, -30.000000)">
					<path
						d="M34.0742,30.8278 L52.9702,44.9998 L34.0742,59.1718 L34.0742,30.8278 Z M58.471,30.827 L58.471,59.007 L53.471,59.007 L53.471,30.827 L58.471,30.827 Z M39.0742,40.8278 L44.6372,44.9998 L39.0742,49.1718 L39.0742,40.8278 Z"
						id="Fill-4"
						fill="#FFFFFF"
					></path>
				</g>
			</g>
		</svg>
	);
};

export default IconSkip;
