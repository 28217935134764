import * as StoreUtils from "../../utils/StoreUtils";
import { UPDATE_TIMER } from "../constants/ActionConstants";

export function updateTimer(id, updates) {
	StoreUtils.getStore().dispatch(_updateTimer(Object.assign(updates, { id })));
}

function _updateTimer(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_TIMER });
	};
}
