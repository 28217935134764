import * as API from "../api";
import { getCode } from "../utils/QuiddityUtils";
import * as QuidditySessionActions from "../redux/actions/QuidditySessionActions";

const getQuidditySessions = async (url, query, addToStore = true) => {
	try {
		const sessions = await API.getQuidditySessions(url, query, getCode());
		addToStore && QuidditySessionActions.updateQuidditySessions(sessions);
		return sessions;
	} catch (error) {
		return [];
	}
};

export const getQuidditySession = async (url, query, addToStore = true) => {
	const sessions = await getQuidditySessions(url, query, addToStore);
	return sessions[0];
};
