import * as StoreUtils from "../../utils/StoreUtils";
import * as LocationUtils from "../../utils/LocationUtils";
import {
	UPDATE_LOCATION,
	UNDO_LOCATION,
	REDO_LOCATION,
} from "../constants/ActionConstants";

export function updateLocation(location, query) {
	StoreUtils.getStore().dispatch(_updateLocation({ location, query }));
}

export function undoLocation() {
	StoreUtils.getStore().dispatch(_undoLocation());
}

export function redoLocation() {
	StoreUtils.getStore().dispatch(_redoLocation());
}

export function pushLocation(pageId, locationPath, query) {
	const locationState = StoreUtils.getReducer("location").item.location;
	const location = LocationUtils.getLocationByLocationPath(
		locationState,
		locationPath
	);
	let locationChildren = LocationUtils.getChildrenFromLocation(location);
	locationChildren.push({ [pageId]: [] });
	updateLocation(locationState, query);
}

export function popLocation(locationPath, query) {
	const locationState = StoreUtils.getReducer("location").item.location;
	const location = LocationUtils.getLocationByLocationPath(
		locationState,
		locationPath
	);
	let locationChildren = LocationUtils.getChildrenFromLocation(location);
	locationChildren.pop();
	updateLocation(locationState, query);
}

function _updateLocation(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_LOCATION });
	};
}

function _undoLocation() {
	return (dispatch: (action) => void) => {
		dispatch({ type: UNDO_LOCATION });
	};
}

function _redoLocation() {
	return (dispatch: (action) => void) => {
		dispatch({ type: REDO_LOCATION });
	};
}
