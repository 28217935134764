import React from "react";

const Roller = (props) => {
	const { fetchingStatus } = props;
	if (fetchingStatus === undefined || fetchingStatus === "fetching") {
		return (
			<div className="loader-container">
				<div className="lds-roller">
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
				</div>
			</div>
		);
	} else {
		return <React.Fragment />;
	}
};

export default Roller;
