import React from "react";
import ReactDOM from "react-dom";
import LoadApp from "./LoadApp";
import { unregister } from "./registerServiceWorker";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { Reducers, StoreUtils } from "funkis-foundation";
import {
	quiddityReducer,
	quidsReducer,
	groupsReducer,
	membersReducer,
	usersReducer,
	unitsReducer,
	quidditySessionsReducer,
	quiddityEventsReducer,
} from "quiddity";

const foundationReducers = Reducers.getReducers();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	combineReducers({
		...foundationReducers,
		quiddity: quiddityReducer,
		quids: quidsReducer,
		groups: groupsReducer,
		users: usersReducer,
		units: unitsReducer,
		members: membersReducer,
		quiddityEvents: quiddityEventsReducer,
		quidditySessions: quidditySessionsReducer,
	}),
	composeEnhancers(applyMiddleware(thunk))
);
StoreUtils.setStore(store);

ReactDOM.render(
	<Provider store={store}>
		<LoadApp />
	</Provider>,
	document.getElementById("root")
);
// keep until we figure out a safe way to use service worker without introducing further caching issues
unregister();
