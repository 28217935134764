import { z } from "zod";

const FATColor = z.string().optional();

const TitleThemeVariablesSchema = z.object({
	primary: FATColor,
	secondary: FATColor,
	theme_color_1: FATColor,
	theme_color_2: FATColor,
	theme_color_3: FATColor,
	theme_color_4: FATColor,
	theme_color_5: FATColor,
	postit_color_1: FATColor,
	postit_color_2: FATColor,
	postit_color_3: FATColor,
	postit_color_4: FATColor,
	postit_color_5: FATColor,
	interaction: FATColor,
	success: FATColor,
	fail: FATColor,
	danger: FATColor,
	information: FATColor,
	warning: FATColor,
	gamification_gold: FATColor,
	gamification_silver: FATColor,
	gamification_bronze: FATColor,
	color_block_divider: FATColor,
	preamble: FATColor,
	block_divider: FATColor,
	text_on_primary: FATColor,
	text_primary: FATColor,
});

export type TitleThemeVariables = z.infer<typeof TitleThemeVariablesSchema>;

export function parseThemeVariablesFromConfigPage(
	configPage: unknown
): TitleThemeVariables {
	return TitleThemeVariablesSchema.parse(configPage);
}

export function composeCSSVariableName(fatVariableName: string) {
	return `--fp-${fatVariableName.replaceAll("_", "-")}`;
}
