import _ from "lodash";

export function camelToDash(str) {
	return str
		.replace(/(^[A-Z])/, ([first]) => first.toLowerCase())
		.replace(/([A-Z])/g, ([letter]) => `-${letter.toLowerCase()}`);
}

export function camelToUnderScore(str) {
	return str
		.replace(/(^[A-Z])/, ([first]) => first.toLowerCase())
		.replace(/([A-Z])/g, ([letter]) => `_${letter.toLowerCase()}`);
}

export function template(text, params) {
	if (!text) {
		console.warn("No text!");
		return text;
	}

	for (var key in params) {
		const value = params[key];

		if (_.isPlainObject(value)) {
			const { value: realValue, className } = value;
			text = text.replace(
				new RegExp("\\${" + key + "}", "g"),
				`<span class="${className ?? ""}">${realValue}</span>`
			);
		} else {
			text = text.replace(new RegExp("\\${" + key + "}", "g"), value);
		}
	}

	return text;
}

export function evalString(str) {
	let evaledStr;
	try {
		if (typeof str === "string") {
			// Try to parse object. Will work with json-like strings.
			evaledStr = eval(`(${str})`);
		} else {
			evaledStr = str;
		}
	} catch (e) {
		// Fallback to parse a string. Will work for strings, numbers and bools.
		evaledStr = eval(`"${str}"`);
	}

	return evaledStr;
}

export function pxToNum(value = "0px") {
	return Number(value.replace("px", ""));
}

export function addWords(str, words, splitBy = " ") {
	const origWordsArray = str
		.split(splitBy)
		.map((str) => str.replace(/\s/g, ""));
	const wordsArray = words.split(splitBy).map((str) => str.replace(/\s/g, ""));
	return _.union(wordsArray, origWordsArray).join(splitBy);
}

export function removeWords(str, words, splitBy = " ") {
	const strArray = str.split(splitBy).map((str) => str.replace(/\s/g, ""));
	const wordsArray = words.split(splitBy).map((str) => str.replace(/\s/g, ""));
	_.remove(strArray, (str) => {
		return wordsArray.includes(str);
	});
	return strArray.join(splitBy);
}

export const getPageIdFromClassName = (className) => {
	const classNames = className.split(" ");
	const pageItemIdClasName = classNames.find(
		(cn) => cn.indexOf("page-item-id-") > -1
	);
	return pageItemIdClasName
		? pageItemIdClasName.replace("page-item-id-", "")
		: "";
};

export function toggleState(toggles, updates) {
	const toggleCN = updates;
	const toggleCNKeys = _.keys(toggleCN);

	toggles.forEach((toggle) => {
		const toggleKey = _.keys(toggle)[0];
		const state = toggle[toggleKey]; // Toggle state deactivate is reserved for deactivation;
		if (toggleCNKeys.includes(toggleKey)) {
			const toggles = toggleCN[toggleKey];
			// Check if valid
			const validToggle =
				toggles.find((t) => t.state === state) || state === "deactivate";
			if (validToggle) {
				// reset previous active
				toggles.forEach((t) => {
					t.active = false;
				});
				// update
				state !== "deactivate" && (validToggle.active = true);
			} else {
				throw new Error(
					`Toggle state ${state} is not valid for key ${toggleKey}`
				);
			}
			//
		} else {
			throw new Error(`Toggle key ${toggleKey} not found!`);
		}
	});

	return toggleCN;
}
