import React, { useCallback } from "react";
import _ from "lodash";

const balanceText = (element: HTMLElement) => {
	if (doesElementSpanMultipleLines(element)) {
		element.style.maxWidth = "unset";
		const width = element.parentElement?.clientWidth ?? 0;
		const bottomRange = Math.max(250, width * 0.5);

		squeezeContainer(element, element.clientHeight, bottomRange, width);
	}
};

const doesElementSpanMultipleLines = (element: HTMLElement): boolean => {
	const elementStyles = window.getComputedStyle(element);
	const elementLineHeight = parseInt(elementStyles["line-height"], 10);
	const elementHeight = parseInt(elementStyles["height"], 10);
	return elementLineHeight < elementHeight;
};

const squeezeContainer = (
	element: HTMLElement,
	originalHeight: number,
	bottomRange: number,
	topRange: number
) => {
	if (bottomRange + 4 >= topRange) {
		element.style.maxWidth = Math.ceil(topRange) + "px";
		return;
	}

	const mid = (bottomRange + topRange) / 2;
	element.style.maxWidth = mid + "px";

	if (
		element.clientHeight > originalHeight ||
		element.scrollWidth > element.clientWidth
	) {
		// We've squoze too far and headline has spilled
		// onto an additional line. Or, there is a long word
		// that is overflowing the container.
		squeezeContainer(element, originalHeight, mid, topRange);
	} else {
		// headline has not wrapped to another line; keep squeezing!
		squeezeContainer(element, originalHeight, bottomRange, mid);
	}
};

type BalancedTextProps = {
	text: string;
	tagName?: React.ElementType;
	className?: string;
	style?: React.CSSProperties;
};

const BalancedText: React.FC<BalancedTextProps> = ({
	text,
	tagName: CustomTag = "span",
	className,
	style,
}) => {
	const ref = React.useRef<HTMLElement>(null);

	const throttledBalanceText = useCallback(_.throttle(balanceText, 100), []);

	React.useEffect(() => {
		let resizeObserver: ResizeObserver | undefined;
		const element = ref.current;
		if (element) {
			throttledBalanceText(element);

			if (window.ResizeObserver && element.parentElement) {
				resizeObserver = new ResizeObserver(() => {
					throttledBalanceText(element);
				});

				resizeObserver.observe(element.parentElement);
			}
		}

		return () => {
			resizeObserver?.disconnect();
		};
	});

	return (
		<CustomTag
			ref={ref}
			className={className}
			style={style}
			dangerouslySetInnerHTML={{ __html: text }}
		/>
	);
};

export default BalancedText;
