import _ from "lodash";

export function findObjectByKey(key, arr) {
	const objArr = arr.map((obj) => {
		const keys = _.keys(obj).map((key) => String(key));
		return { keys, obj };
	});
	const foundObj = objArr.find((obj) => obj.keys.includes(key));
	return foundObj && foundObj.obj;
}

export function asyncForEach(items, onForEach, onComplete, timeout = 0) {
	return new Promise((resolve) => {
		if (!items.length) {
			resolve(onComplete && onComplete());
		} else {
			items.forEach((item, index) => {
				setTimeout(() => {
					const prevItem = index > 0 && items[index - 1];
					const nextItem = index < items.length - 1 && items[index + 1];
					onForEach(item, index, prevItem, nextItem);
					if (index === items.length - 1) {
						resolve(onComplete && onComplete());
					}
				}, timeout);
			});
		}
	});
}

export function forEachReverse(items, onForEach) {
	for (var i = items.length - 1; i > -1; i--) {
		const prevItem = i < items.length - 1 && items[i + 1];
		const nextItem = i > 0 && items[i - 1];
		onForEach(items[i], i, prevItem, nextItem);
	}
}

export function sortItemsByKey(items, key, reverse = false) {
	return items.sort((a, b) => {
		if (a[key] > b[key]) {
			return reverse ? -1 : 1;
		}
		if (a[key] < b[key]) {
			return reverse ? 1 : -1;
		}
		return 0;
	});
}
