import * as React from "react";
import paperClipSrc from "assets/images/paper-clip.png";

function PaperClip({ className }) {
	return (
		<svg
			className={className}
			width={30}
			height={120}
			viewBox="0 0 30 120"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<title>Paper Clip</title>
			<image
				x={167}
				y={65}
				width={39}
				height={123}
				href={paperClipSrc}
				transform="translate(-171 -65)"
				fill="none"
				fillRule="evenodd"
			/>
		</svg>
	);
}

export default PaperClip;
