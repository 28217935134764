import React from "react";
import classnames from "classnames";
import _ from "lodash";
import { FFText } from "funkis-foundation";
import Logo from "../components/slide/Logo";
import Page from "../components/scroll/Page";
import { useSelector } from "react-redux";
import ImageVideoOrDiv from "../components/core/ImageVideoOrDiv";
import WorkshopGroupSetup from "../components/core/WorkshopGroupSetup";
import DefaultButton from "../components/scroll/DefaultButton";
import { updateLocation } from "../actions/Actions";
import bgQuiddity from "assets/images/bg_quiddity.jpg";

const GLOBAL_TEXT_IDS_BY_FUNCTION = {
	HEADER: "0863704F-8138-40D2-89A9-708398474E7E", // Eg. "What group do you want to use for the workshop?"",
	SUBHEADER_INSTRUCTION: "0C0053DC-3AA3-44ED-B699-B6B8DB80FABC",
	TABLE_COL_STATUS: "479E2F25-2376-4578-A893-556628D77D94",
	TABLE_COL_GROUP: "C3B8454D-BC5B-4D48-B5B8-3CFCF387EF2E",
	TABLE_COL_CREATED: "56C9E988-8A0A-469C-839A-A8E3BA5C2DBF",
};

const PageSelfStudyGroupSetup = (props) => {
	const { page } = props;
	const backend = useSelector((state) => _.get(state, "app.item.backend"));

	const goToNextPage = () => {
		updateLocation({
			pageId: page.id,
			path: [{ sibling: 1 }],
		});
	};

	return (
		<Page {...props} className={"container-fluid"}>
			<ImageVideoOrDiv
				className="position-fixed w-100 h-100 bg-black"
				style={{ top: 0, left: 0 }}
				src={backend && bgQuiddity}
			></ImageVideoOrDiv>

			<div className="position-relative text-white w-100">
				<Logo className="position-relative d-flex justify-content-start mb-7" />
				<div className={classnames("row m-0 mb-5")}>
					<FFText
						className="d-block h4-sm col-12 font-weight-bold mb-1"
						textId={GLOBAL_TEXT_IDS_BY_FUNCTION.HEADER}
					/>
					<FFText
						tagName="p"
						className="p-sm d-block col-12 font-weight-bold"
						textId={GLOBAL_TEXT_IDS_BY_FUNCTION.SUBHEADER_INSTRUCTION}
					/>
				</div>

				<div className="row m-0 mb-1 border-bottom border-bottom-1 border-gray pb-3">
					<div className="col-2" />
					<FFText
						tagName="h5"
						className="h5-sm font-weight-bold col-5"
						textId={GLOBAL_TEXT_IDS_BY_FUNCTION.TABLE_COL_GROUP}
					/>
					<div className="col-2">
						{/* <FFText
              tagName="h5"
              className="h5-sm font-weight-bold"
              textId={GLOBAL_TEXT_IDS_BY_FUNCTION.TABLE_COL_STATUS}
            /> */}
					</div>
					<div className="col-3 justify-content-end">
						<FFText
							tagName="h5"
							className="h5-sm font-weight-bold text-right"
							textId={GLOBAL_TEXT_IDS_BY_FUNCTION.TABLE_COL_CREATED}
						/>
					</div>
				</div>
				<WorkshopGroupSetup {...props} />
				<div className="mt-7 w-100 d-flex justify-content-center">
					<DefaultButton
						className="mt-6"
						labelTextId="63C5338F-B327-44E5-9FD7-A7844D6FC522"
						onClick={goToNextPage}
						color="white"
					/>
				</div>
			</div>
		</Page>
	);
};

export default PageSelfStudyGroupSetup;
