import _ from "lodash";
import * as ArrayUtils from "./ArrayUtils";
import { getLocationPathForPage } from "./PagesUtils";
import * as StoreUtils from "./StoreUtils";

export function getPageIdsForLocation(location, locationPath) {
	let index = -1;
	let locObj = {};

	while (index < locationPath.length) {
		index++;
		if (index === 0) {
			locObj = location[index];
		} else {
			try {
				locObj = locObj[locationPath[index - 1]][0];
			} catch (error) {
				// fail silently...
			}
		}
	}

	return _.keys(locObj);
}

export function isFriendlyLocationHash(hash) {
	const hashEscaped = unescape(
		window.location.hash.slice(1, window.location.hash.length)
	);
	const indexOfParams = hashEscaped.indexOf("?");
	const hashStriped =
		indexOfParams > -1 ? hashEscaped.slice(0, indexOfParams) : hashEscaped;
	let result = false;
	try {
		JSON.parse(hashStriped);
	} catch (e) {
		result = true;
	}

	return result;
}

export function friendlyLocationPathToLocation(friendlyLocationPath) {
	if (friendlyLocationPath === "") {
		return undefined; // undefined, because [] is not a valid location
	}
	const pages = StoreUtils.getReducer("pages").items;
	const friendlyLocationPathArr = friendlyLocationPath
		.split("/")
		.filter((path) => Boolean(path)); // filter to ignore trailing slash
	const locationPath = friendlyLocationPathArr.map(
		(friendlyId) => pages.find((page) => page.friendlyId === friendlyId)?.id
	);
	if(locationPath.includes(undefined)) {
		// invalid location
		return undefined;
	}
	return convertLocationPathToLocation(locationPath);
}

export function locationToFriendlyHash(location, joinBy = "/") {
	const pages = StoreUtils.getReducer("pages").items;
	if (!Array.isArray(location) || location.length === 0) {
		return undefined;
	}
	let locationNode = location[0];
	let friendlyIds = [];
	while (locationNode) {
		const keys = _.keys(locationNode);
		const pageId = keys[0];
		const friendlyPageId = pages.find((page) => page.id === pageId).friendlyId;
		if (friendlyPageId) {
			locationNode = locationNode[pageId][0];
			friendlyIds.push(friendlyPageId);
		} else {
			locationNode = null;
		}
	}

	if (joinBy) {
		return locationNode === null ? undefined : friendlyIds.join(joinBy);
	} else {
		return friendlyIds;
	}
}

/**
 * Converts a location path to a location object
 */
export function convertLocationPathToLocation(locationPath) {
	let level = 0;
	let pageId;
	const location = [];
	let refLocation;
	while (level < locationPath.length) {
		pageId = locationPath[level];
		const locationObj = { [pageId]: [] };
		refLocation ? refLocation.push(locationObj) : location.push(locationObj);
		refLocation = refLocation ? refLocation[0][pageId] : location[0][pageId];
		level++;
	}

	return location;
}

/**
 * Get the location object from location object and location path if location exists. (Used by LocationActions.pushLocatiomn && LocationActions.popLocatiomn)
 */
export function getLocationByLocationPath(location, locationPath) {
	let level = 0;
	let locObj;
	let locArr = location;
	let key;
	while (level < locationPath.length) {
		if (level > 0) {
			locArr = locObj[key];
		}
		key = locationPath[level];
		locObj = ArrayUtils.findObjectByKey(key, locArr);
		locArr = [locObj];

		if (!locObj) {
			console.warn(
				`locationPath is invalid. No location found for ${locationPath.slice(
					0,
					level
				)}!`
			);
			return null;
		}
		level++;
	}

	return locArr;
}

export function getPageIdFromLocation(location) {
	return _.keys(location[0])[0];
}

export function getChildrenFromLocation(location) {
	const pageId = getPageIdFromLocation(location);
	return location[0][pageId];
}

export function redirectIfLinkIsShared() {
	let href = window.location.href;
	if (href.indexOf("hash/") > -1 || href.indexOf("/query/") > -1) {
		href = href.replace("hash/", "#"); //When sharing links = is replace with /query/ (support for twitter)
		href = href.replace("/query/", "?"); //When sharing links = is replace with /query/ (support for twitter)
		window.location.href = href;
		return true;
	}
	return false;
}

export function convertHrefToSharableLink(href) {
	let newHref = href;
	newHref = newHref.replace("#", "hash/");
	newHref = newHref.replace("?", "/query/");

	return newHref;
}

export function hashToLocation(hash) {
	if (hash === "") {
		return false;
	}
	const hashEscaped = unescape(hash.slice(1, hash.length));
	const indexOfParams = hashEscaped.indexOf("?");
	const hashStriped =
		indexOfParams > -1 ? hashEscaped.slice(0, indexOfParams) : hashEscaped;
	let location;
	try {
		location = JSON.parse(hashStriped);
	} catch (e) {
		location = friendlyLocationPathToLocation(hashStriped);
	}

	return location;
}

export function locationToHash(location) {
	return escape(JSON.stringify(location));
}

export function updateWindowHash(location, query) {
	const friendlyHash = locationToFriendlyHash(location);
	if (friendlyHash) {
		window.location.hash = friendlyHash + queryObjectToUrlParams(query);
	} else {
		window.location.hash =
			locationToHash(location) + queryObjectToUrlParams(query);
	}
}

export function queryObjectToUrlParams(queryObject) {
	const keys = _.keys(queryObject);
	if (!keys.length) {
		return "";
	} else {
		let result = "?";
		keys.forEach((key, index) => {
			result =
				result +
				key +
				"=" +
				queryObject[key] +
				(index < keys.length - 1 ? "&" : "");
		});
		return result;
	}
}

export function getUrlParamsFromCurrentUrl() {
	const hashEscaped = unescape(
		window.location.hash.slice(1, window.location.hash.length)
	);
	const indexOfParams = hashEscaped.indexOf("?");
	return indexOfParams > -1
		? hashEscaped.slice(indexOfParams + 1, hashEscaped.length)
		: "";
}

export function getQueryObjectFromCurrentUrl() {
	const urlParams = getUrlParamsFromCurrentUrl();
	const result = {};
	if (urlParams !== "") {
		urlParams.split("&").forEach((urlParam) => {
			const urlParamArr = urlParam.split("=");
			result[urlParamArr[0]] = urlParamArr[1];
		});
	}
	return result;
}

export function getBaseHref() {
	return window.location.href.replace(window.location.hash, "");
}

export function getPageIdsFromLocation(location) {
	let pageIds = [];
	let currentLocation = _.isArray(location) ? location : location.location;
	if (!_.isArray(currentLocation))
		throw Error(
			"getPageIdsFromLocation expected a location array or object with 'location' props being array but received " +
				JSON.stringify(location, null, 2)
		);

	while (getPageIdFromLocation(currentLocation)) {
		let nextPageId = getPageIdFromLocation(currentLocation);
		pageIds.push(nextPageId);
		currentLocation = currentLocation[0][nextPageId];
	}
	return pageIds;
}

/*
can useful for things like checking if the current location is part of a specific "chapter" page, without knowing how deeply "nested" your location is.
For example: 
  const isLocationInChapter2 = getPagesFromLocation(location).some(page => page.friendlyId === "chapter-2")  
*/
export function getPagesFromLocation(location) {
	const pagesByIds = StoreUtils.getReducer("pages").itemsById;
	const pageIdsInLocation = getPageIdsFromLocation(location);
	return pageIdsInLocation.map((pageId) => pagesByIds[pageId]);
}

export function getLocationFromPageFriendlyId(friendlyId) {
	const pages = StoreUtils.getReducer("pages").items;
	const page = pages.find((p) => p.friendlyId === friendlyId);
	if (!page) {
		throw new Error(`No page matching friendlyId of "${friendlyId}"`);
	}
	const locationPath = getLocationPathForPage(pages, page);
	const location = convertLocationPathToLocation(locationPath);
	return location;
}
