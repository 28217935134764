import { LocationUtils, StoreUtils } from "funkis-foundation";

const { getPageIdsFromLocation } = LocationUtils;

export const isPageInLocation = ({ pageId, location }) => {
	const ids = getPageIdsFromLocation(location);
	return ids.includes(pageId);
};

export const getCurrentChapterId = () => {
	const location = StoreUtils.getReducer("location").item.location;
	const ids = getPageIdsFromLocation(location);
	return ids[1];
};

export const getCurrentPageId = () => {
	const location = StoreUtils.getReducer("location").item.location;
	const ids = getPageIdsFromLocation(location);
	return ids[2];
};
