import { useMemo } from "react";
import _ from "lodash";
import { useSelector, shallowEqual } from "react-redux";
import { createSelector } from "reselect";
import * as QuidInputContext from "../../constants/QuidInputContexts";
import { getQueryForContext } from "../../utils/QuidInputUtils";
import { useMembersForGroup } from "./memberSelectors";
import { useQuidTemplate } from "./quidSelectors";

export const makeUseQuidInputs = () =>
	createSelector(
		(state) => state.quidInputs.items,
		(_, props) => props.context,
		(_, props) => props.query,
		(_, props) => props.membersForGroup,
		(quidInputs, context, query, membersForGroup) => {
			// extrakt targetDataKey..-
			const targetDataKey = query.targetDataKey;
			const targetDataKeys = targetDataKey ? targetDataKey.split(",") : [];
			const matchQuery = { ...query };
			delete matchQuery.targetDataKey;
			// Match against "matchQuery" and targetDataKeys...
			let quidInputItems = _.filter(quidInputs, matchQuery).filter(
				(quidInput) => targetDataKeys.includes(quidInput.targetDataKey)
			);

			switch (context) {
				// If context "CURRENT_GROUP_MEMBERS"
				case QuidInputContext.CURRENT_GROUP_MEMBERS:
					quidInputItems = quidInputItems.filter((item) =>
						membersForGroup.includes(item.member)
					);
					break;
			}

			return quidInputItems.map((quidInput) => quidInput.id);
		}
	);

export const useQuidInputs = (props) => {
	const context = props.context || props.quidInputContext;
	const quiddity = useSelector((state) => {
		state.quiddity.item;
	});
	const membersForGroup =
		context === QuidInputContext.CURRENT_GROUP_MEMBERS &&
		useMembersForGroup(props);
	const quidTemplateId = useQuidTemplate(props);
	const query = getQueryForContext(
		context,
		{
			...quiddity,
			sourceQuid: quidTemplateId,
			...props,
		},
		["limit", "throttle", "throttleWait", "data"]
	);

	const makedUseQuidInputs = useMemo(makeUseQuidInputs, []);
	// console.log("makedUseQuidInputs recomputations", makedUseQuidInputs.recomputations());
	return useSelector(
		(state) =>
			makedUseQuidInputs(state, {
				query,
				membersForGroup,
				context,
			}),
		shallowEqual
	);
};
