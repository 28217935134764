import React from "react";

function IconGroupOfMembers({ className, style }) {
	return (
		<svg
			className={className}
			style={style}
			width="183px"
			height="88px"
			viewBox="0 0 183 88"
			version="1.1"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="Group-3">
					<path
						d="M61.5,40.0602298 C62.9561245,40.0602298 64.136474,38.873516 64.136474,37.4095405 C64.136474,35.9456478 62.9561245,34.7588512 61.5,34.7588512 C60.043958,34.7588512 58.863526,35.9456478 58.863526,37.4095405 C58.863526,38.873516 60.043958,40.0602298 61.5,40.0602298 L61.5,40.0602298 Z M73.0058731,34.5064559 L73.0058731,47.7235143 L73.0058731,88 L64.136474,88 L64.136474,51.9328378 L58.863526,51.9328378 L58.863526,88 L49.9941269,88 L49.9941269,47.7235143 L49.9941269,34.5064559 L37.6911036,49.2484958 L32,44.4475976 L49.9941269,22.886326 L73.0058731,22.886326 L91,44.4475976 L85.3088964,49.2484958 L73.0058731,34.5064559 Z M52.7120555,8.83540994 C52.7120555,3.9558506 56.6465266,0 61.5,0 C66.3534734,0 70.2880269,3.9558506 70.2880269,8.83540994 C70.2880269,13.7150522 66.3534734,17.6708199 61.5,17.6708199 C56.6465266,17.6708199 52.7120555,13.7150522 52.7120555,8.83540994 L52.7120555,8.83540994 Z"
						id="Fill-102"
						fill="#AAAAAF"
					></path>
					<path
						d="M122.5,40.0602298 C123.956124,40.0602298 125.136474,38.873516 125.136474,37.4095405 C125.136474,35.9456478 123.956124,34.7588512 122.5,34.7588512 C121.043958,34.7588512 119.863526,35.9456478 119.863526,37.4095405 C119.863526,38.873516 121.043958,40.0602298 122.5,40.0602298 L122.5,40.0602298 Z M134.005873,34.5064559 L134.005873,47.7235143 L134.005873,88 L125.136474,88 L125.136474,51.9328378 L119.863526,51.9328378 L119.863526,88 L110.994127,88 L110.994127,47.7235143 L110.994127,34.5064559 L98.6911036,49.2484958 L93,44.4475976 L110.994127,22.886326 L134.005873,22.886326 L152,44.4475976 L146.308979,49.2484958 L134.005873,34.5064559 Z M113.712056,8.83540994 C113.712056,3.9558506 117.646527,0 122.5,0 C127.353473,0 131.288027,3.9558506 131.288027,8.83540994 C131.288027,13.7150522 127.353473,17.6708199 122.5,17.6708199 C117.646527,17.6708199 113.712056,13.7150522 113.712056,8.83540994 L113.712056,8.83540994 Z"
						id="Fill-104"
						fill="#AAAAAF"
					></path>
					<path
						d="M29.5,53.02486 C30.9561245,53.02486 32.136474,51.8381451 32.136474,50.3741682 C32.136474,48.9101912 30.9561245,47.7234764 29.5,47.7234764 C28.043958,47.7234764 26.863526,48.9101912 26.863526,50.3741682 C26.863526,51.8381451 28.043958,53.02486 29.5,53.02486 L29.5,53.02486 Z M41.0059555,34.5064884 L41.0059555,46.8305184 L47.6018811,69.9664433 L41.0059555,69.9664433 L41.0059555,88 L32.136474,88 L32.136474,69.9664433 L26.863526,69.9664433 L26.863526,88 L17.9941269,88 L17.9941269,69.9664433 L11.3982013,69.9664433 L17.9941269,46.8305184 L17.9941269,34.5064055 L5.6911036,49.2483764 L0,44.4475565 L17.9941269,22.8862647 L41.0059555,22.8862647 L59,44.4475565 L53.3088964,49.2483764 L41.0059555,34.5064884 Z M29.5,42.5310912 C30.9561245,42.5310912 32.136474,41.3443763 32.136474,39.8804823 C32.136474,38.4165054 30.9561245,37.2297076 29.5,37.2297076 C28.043958,37.2297076 26.863526,38.4165054 26.863526,39.8804823 C26.863526,41.3443763 28.043958,42.5310912 29.5,42.5310912 L29.5,42.5310912 Z M29.5,32.0374053 C30.9561245,32.0374053 32.136474,30.8506076 32.136474,29.3866306 C32.136474,27.9227366 30.9561245,26.7360217 29.5,26.7360217 C28.043958,26.7360217 26.863526,27.9227366 26.863526,29.3866306 C26.863526,30.8506076 28.043958,32.0374053 29.5,32.0374053 L29.5,32.0374053 Z M38.2879445,8.83533537 C38.2879445,13.7149822 34.3534734,17.6707536 29.5,17.6707536 C24.6465266,17.6707536 20.7120555,13.7149822 20.7120555,8.83533537 C20.7120555,3.95568855 24.6465266,0 29.5,0 C34.3534734,0 38.2879445,3.95568855 38.2879445,8.83533537 L38.2879445,8.83533537 Z"
						id="Fill-116"
						fill="#FFFFFF"
					></path>
					<path
						d="M91.5,53.02486 C92.956042,53.02486 94.136474,51.8381451 94.136474,50.3741682 C94.136474,48.9101912 92.956042,47.7234764 91.5,47.7234764 C90.043958,47.7234764 88.863526,48.9101912 88.863526,50.3741682 C88.863526,51.8381451 90.043958,53.02486 91.5,53.02486 L91.5,53.02486 Z M103.005956,34.5064884 L103.005956,46.8305184 L109.601881,69.9664433 L103.005956,69.9664433 L103.005956,88 L94.136474,88 L94.136474,69.9664433 L88.863526,69.9664433 L88.863526,88 L79.9941269,88 L79.9941269,69.9664433 L73.3982013,69.9664433 L79.9941269,46.8305184 L79.9941269,34.5064055 L67.6911036,49.2483764 L62,44.4475565 L79.9941269,22.8862647 L103.005956,22.8862647 L121,44.4475565 L115.308896,49.2483764 L103.005956,34.5064884 Z M91.5,42.5310912 C92.956042,42.5310912 94.136474,41.3443763 94.136474,39.8804823 C94.136474,38.4165054 92.956042,37.2297076 91.5,37.2297076 C90.043958,37.2297076 88.863526,38.4165054 88.863526,39.8804823 C88.863526,41.3443763 90.043958,42.5310912 91.5,42.5310912 L91.5,42.5310912 Z M91.5,32.0374053 C92.956042,32.0374053 94.136474,30.8506076 94.136474,29.3866306 C94.136474,27.9227366 92.956042,26.7360217 91.5,26.7360217 C90.043958,26.7360217 88.863526,27.9227366 88.863526,29.3866306 C88.863526,30.8506076 90.043958,32.0374053 91.5,32.0374053 L91.5,32.0374053 Z M100.287944,8.83533537 C100.287944,13.7149822 96.3534734,17.6707536 91.5,17.6707536 C86.6465266,17.6707536 82.7120555,13.7149822 82.7120555,8.83533537 C82.7120555,3.95568855 86.6465266,0 91.5,0 C96.3534734,0 100.287944,3.95568855 100.287944,8.83533537 L100.287944,8.83533537 Z"
						id="Fill-117"
						fill="#FFFFFF"
					></path>
					<path
						d="M153.5,53.02486 C154.956042,53.02486 156.136474,51.8381451 156.136474,50.3741682 C156.136474,48.9101912 154.956042,47.7234764 153.5,47.7234764 C152.043876,47.7234764 150.863526,48.9101912 150.863526,50.3741682 C150.863526,51.8381451 152.043876,53.02486 153.5,53.02486 L153.5,53.02486 Z M165.005956,34.5064884 L165.005956,46.8305184 L171.601799,69.9664433 L165.005956,69.9664433 L165.005956,88 L156.136474,88 L156.136474,69.9664433 L150.863526,69.9664433 L150.863526,88 L141.994044,88 L141.994044,69.9664433 L135.398201,69.9664433 L141.994044,46.8305184 L141.994044,34.5064055 L129.691021,49.2483764 L124,44.4475565 L141.994044,22.8862647 L165.005956,22.8862647 L183,44.4475565 L177.308896,49.2483764 L165.005956,34.5064884 Z M153.5,42.5310912 C154.956042,42.5310912 156.136474,41.3443763 156.136474,39.8804823 C156.136474,38.4165054 154.956042,37.2297076 153.5,37.2297076 C152.043876,37.2297076 150.863526,38.4165054 150.863526,39.8804823 C150.863526,41.3443763 152.043876,42.5310912 153.5,42.5310912 L153.5,42.5310912 Z M153.5,32.0374053 C154.956042,32.0374053 156.136474,30.8506076 156.136474,29.3866306 C156.136474,27.9227366 154.956042,26.7360217 153.5,26.7360217 C152.043876,26.7360217 150.863526,27.9227366 150.863526,29.3866306 C150.863526,30.8506076 152.043876,32.0374053 153.5,32.0374053 L153.5,32.0374053 Z M162.287944,8.83533537 C162.287944,13.7149822 158.353473,17.6707536 153.5,17.6707536 C148.646527,17.6707536 144.711973,13.7149822 144.711973,8.83533537 C144.711973,3.95568855 148.646527,0 153.5,0 C158.353473,0 162.287944,3.95568855 162.287944,8.83533537 L162.287944,8.83533537 Z"
						id="Fill-118"
						fill="#FFFFFF"
					></path>
				</g>
			</g>
		</svg>
	);
}

export default IconGroupOfMembers;
