import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";

import { getBootstrapColor } from "../../../utils/colorUtils";
import { getLetterByIndex } from "../../../utils/stringUtils";
import { getText } from "../../../utils/textUtils";
import ImageWithCaption from "../ImageWithCaption";
import SelectionItem from "./SelectionSprig/SelectionItem";
import Text from "../../../components/core/Text";

import styles from "./SelectionSprig.module.css";

const SelectionItemsContainer = styled.div`
	padding-bottom: 60px;
`;

const StyledTitle = styled.h2`
	p {
		font-weight: inherit;
		font-size: inherit;
		line-height: inherit;
	}
`;

const SelectionSprig = (props) => {
	const {
		sprigId,
		sprigStory,
		exits,
		onExitClick,
		onShow,
		onShowExit,
		selectedExitId,
		paragraphTextColor,
		animate,
		scrollIntoView = true,
		titleId,
	} = props;
	const sprig = sprigStory.find((sp) => sp.id === sprigId);
	const refExit = useRef<HTMLDivElement>(null);

	const [wasFinishedBeforeInstantiation] = useState(Boolean(selectedExitId));
	const shouldPlayEnterAnimation = !wasFinishedBeforeInstantiation;

	const [selectedExit, setSelectedExit] = useState<
		{ exit: any; sprig: any } | undefined
	>(undefined);

	const onEnterTimeline = useRef<GSAPTimeline>();
	const onExitTimeline = useRef<GSAPTimeline>();
	const q = gsap.utils.selector(refExit);

	useEffect(() => {
		if (shouldPlayEnterAnimation) {
			onEnterTimeline.current = gsap
				.timeline()
				.fromTo(
					q(`.${styles.staggerIn}`),
					{
						y: 30,
					},
					{
						opacity: 1,
						y: 0,
						duration: 0.3,
						stagger: 0.3,
						delay: 1,
					}
				)
				.to(q(`.${styles.staggerInOptions}`), {
					opacity: 1,
					duration: 0.2,
					stagger: 0.1,
					delay: 0.6,
				});
		}
	}, [shouldPlayEnterAnimation]);

	useEffect(() => {
		if (selectedExit) {
			onExitTimeline.current = gsap
				.timeline({
					onComplete: () => {
						onExitClick(selectedExit);
					},
				})
				.fromTo(
					q(`.animationOutNotSelected`),
					{ opacity: 1 },
					{
						opacity: 0,
						height: 0,
						duration: 0.4,
						ease: "easeIn",
					}
				)
				.fromTo(
					q(`.animationOutSelected`),
					{ opacity: 1 },
					{
						opacity: 0.2,
						duration: 0.01,
						delay: 0.25,
						repeat: 3,
						yoyo: true,
						repeatDelay: 0.2,
					}
				)
				.fromTo(
					q(`.animationOutSelected`),
					{ opacity: 1 },
					{
						opacity: 0,
						height: 0,
						duration: 0.5,
						ease: "easeIn",
						delay: 1,
					}
				)
				.set([q(".animationOut")], { display: "none" });
		}
	}, [selectedExit]);

	useEffect(() => {
		if (refExit?.current) {
			scrollIntoView && refExit.current.scrollIntoView(true);
		}

		onShowExit && onShowExit(props);
	}, []);

	return (
		<div ref={refExit}>
			{/* PARAGRAPH */}
			<div className="row m-0 mb-2 sprig-paragraph">
				<div
					className={classnames(
						"col-sm-12",
						shouldPlayEnterAnimation && styles.staggerIn
					)}
				>
					{getText(titleId || sprig.txt_title_id) && (
						<StyledTitle className="mb-5">
							{getText(titleId || sprig.txt_title_id)}
						</StyledTitle>
					)}
				</div>
				{sprig.media && (
					<ImageWithCaption
						animate={false}
						src={sprig.media}
						className={classnames(
							"col-md-12 mb-5",
							shouldPlayEnterAnimation && styles.staggerIn
						)}
						classNameImage="w-100 sprig-paragraph-image"
						size={sprig.image_size}
						maxHeight={sprig.image_max_height}
						align={sprig.image_align}
						caption={undefined}
						classNameCaption={undefined}
						isZoomable={undefined}
						videoAutoPlay={undefined}
					/>
				)}
				<div
					className={classnames(
						"col-md-9",
						shouldPlayEnterAnimation && styles.staggerIn
					)}
				>
					<Text
						textId={sprig.txt_story_id}
						tagName="div"
						className="p-md"
						style={{ color: getBootstrapColor(paragraphTextColor) }}
					/>
				</div>
			</div>
			{/* EXIT */}
			<div className="row m-0 mb-1 align-items-end sprig-exit overflow-hidden">
				{shouldPlayEnterAnimation && (
					<SelectionItemsContainer className="col-12">
						{exits.map((exit, index) => (
							<div
								key={index}
								style={{
									marginTop: index === 0 ? "unset" : -1,
									borderTop: `1px solid ${getBootstrapColor("gray")}`,
									borderBottom: `1px solid ${getBootstrapColor("gray")}`,
								}}
								className={classnames(
									"overflow-hidden",
									styles.staggerInOptions,
									"animationOut",
									{
										animationOutNotSelected:
											selectedExit?.exit && exit.id !== selectedExit.exit.id,
										animationOutSelected:
											selectedExit?.exit && exit.id === selectedExit.exit.id,
									}
								)}
							>
								<SelectionItem
									index={index}
									indexIndicator={getLetterByIndex(index, true) + "."}
									body={getText(exit.text_id) || exit.text_string}
									selected={
										selectedExit?.exit && exit.id === selectedExit?.exit.id
									}
									unselected={
										selectedExit?.exit && exit.id !== selectedExit?.exit.id
									}
									onClick={() => {
										const exit = sprig.exits[index];
										setSelectedExit({ sprig, exit });
									}}
									selectionMarker={undefined}
									className={undefined}
								/>
							</div>
						))}
					</SelectionItemsContainer>
				)}
			</div>
		</div>
	);
};

export default SelectionSprig;
