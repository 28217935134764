import classnames from "classnames";
import { FFText, FFVideo } from "funkis-foundation";
import React from "react";
import { getLocalizedMediaPath, getMediaType } from "utils/MediaUtils";
import { getText } from "../../utils/textUtils";
import Image from "./Image";

const ImageWithCaption = ({
	src,
	maxHeight,
	caption,
	size,
	show = true,
	style = {},
	align,
	className,
	classNameImage,
	classNameCaption,
	animate = true,
	isZoomable,
	videoAutoPlay,
}) => {
	// default argumments overriding all falsy values, including empty string which is what CMT produces
	align = align || "center";
	size = size || "ratio";

	const mediaType = getMediaType(src);

	return (
		<div className={className} style={style}>
			{src !== "" && (
				<div
					className={classnames(
						"mb-2 animated",
						{ fadeIn: show && animate, fadeOut: !show && animate },
						classNameImage
					)}
				>
					{mediaType === "image" ? (
						<Image
							src={src}
							size={size}
							maxHeight={maxHeight}
							align={align}
							isZoomable={isZoomable}
						/>
					) : mediaType === "video" ? (
						<FFVideo
							src={getLocalizedMediaPath(src)}
							loop={videoAutoPlay === "loop"}
							muted
							controls={false}
							autoPlay
							width="100%"
							height="100%"
						/>
					) : (
						<></>
					)}
				</div>
			)}
			{getText(caption) && (
				<React.Fragment>
					<FFText
						className={classnames(
							"caption-small animated font-italic",
							show ? "fadeIn delay-500ms" : "fadeOut",
							classNameCaption
						)}
						textId={caption}
					/>
				</React.Fragment>
			)}
		</div>
	);
};

export default ImageWithCaption;
