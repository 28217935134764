import classNames from "classnames";
import Text from "funkis-template/components/core/Text";
import { TextId } from "funkis-template/models/player";
import React from "react";
import Styles from "./Choice.module.css";

export type ChoiceItemValue = string | number;
export type ChoiceItem = {
	value: ChoiceItemValue;
	title: TextId;
};

export type ChoiceVariant =
	| "plain"
	| "speech_bubble_angular"
	| "speech_bubble_round";

export type ChoiceProps = {
	variant: ChoiceVariant;
	items: ChoiceItem[];
	onChange: (value: ChoiceItemValue) => void;
	disabled?: boolean;
	selectedValue?: ChoiceItemValue;
	idleBackgroundColor?: string;
};

const Choice: React.FC<ChoiceProps> = ({
	items,
	onChange,
	disabled,
	selectedValue,
	variant = "plain",
	idleBackgroundColor,
}) => {
	const uniqueId = items.map(({ value }) => value).join("+");

	const handleChange = React.useCallback(
		(e) => {
			const nextValue: ChoiceItemValue = e.target.value;
			onChange && onChange(nextValue);
		},
		[onChange]
	);

	const cssCustomProperties =
		idleBackgroundColor && idleBackgroundColor !== ""
			? ({
					"--choice-idle-background-color": idleBackgroundColor,
					"--choice-idle-border-color": idleBackgroundColor,
			  } as React.CSSProperties)
			: undefined;

	return (
		<div
			style={cssCustomProperties}
			className={classNames(Styles.choiceGroup, {
				[Styles.variantSpeechBubbleDrama]: variant === "speech_bubble_round",
				[Styles.variantSpeechBubbleSerious]:
					variant === "speech_bubble_angular",
			})}
		>
			{items.map((item) => (
				<label
					key={item.value}
					className={classNames(Styles.choiceItem, {
						[Styles.disabled]: disabled,
						[Styles.enabled]: !disabled,
						[Styles.selected]: item.value === selectedValue,
					})}
				>
					<input
						checked={item.value === selectedValue}
						disabled={disabled}
						value={item.value}
						type="radio"
						name={uniqueId}
						onChange={handleChange}
					/>
					<Text textId={item.title} />
				</label>
			))}
		</div>
	);
};

export default Choice;
