import React, { useEffect } from "react";
import _ from "lodash";
import { StoreUtils } from "funkis-foundation";
import { getMembers, getGroup } from "quiddity";
import { getBootstrapColor } from "../../utils/colorUtils";
import { useSelector } from "react-redux";

const IOCommentOutput = (props) => {
	const { className, color = "primary", data, quidInput } = props;
	const quiddity = StoreUtils.getReducer("quiddity").item;
	const { url, api } = quiddity;
	const member = useSelector(
		(state) => quidInput && state.members.itemsById[quidInput.member]
	);
	const user = _.get(member, "user");
	const group = useSelector(
		(state) => quidInput && state.groups.itemsById[quidInput.group]
	);

	useEffect(() => {
		// Get member....
		if ((!user || typeof user === "string") && quidInput.member) {
			getMembers(url + api, { id: quidInput.member, populate: "user" });
		}
	}, [quidInput, user]);

	useEffect(() => {
		// Get group...
		if (!group && quidInput.group) {
			getGroup(url + api, { group: quidInput.group });
		}
	}, [quidInput, group]);

	return (
		<div className={className}>
			<div className="w-100 d-flex flex-column">
				{data.header && (
					<h4 className="mb-1 font-weight-bold">{data.header}</h4>
				)}
				<div className="d-flex align-items-center">
					<div
						className="mr-3"
						style={{
							backgroundColor: getBootstrapColor(color),
							borderRadius: "50%",
							width: "1.1875rem",
							height: "1.1875rem",
						}}
					/>
					<span className="p-sm ">{data.value}</span>
				</div>
			</div>
		</div>
	);
};

export default IOCommentOutput;
