import * as LMSActions from "./actions/LMSActions";
import * as PageItemActions from "./actions/PageItemActions";
import * as LocationActions from "./actions/LocationActions";
import * as MiscActions from "./actions/MiscActions";

// PageItem Actions
export const updatePageItem = PageItemActions.updatePageItem;
export const updatePageItems = PageItemActions.updatePageItems;
export const updatePageItemSelf = PageItemActions.updatePageItemSelf;
export const updatePageItemParent = PageItemActions.updatePageItemParent;
export const updatePageItemSibling = PageItemActions.updatePageItemSibling;
export const updatePageItemByFriendlyId =
	PageItemActions.updatePageItemByFriendlyId;
export const addClassNamesToPageItem = PageItemActions.addClassNamesToPageItem;
export const removeClassNamesInPageItem =
	PageItemActions.removeClassNamesInPageItem;
export const showNextPageItem = PageItemActions.showNextPageItem;
export const showPreviousPageItem = PageItemActions.showPreviousPageItem;
export const toggleState = PageItemActions.toggleState;
// Location Actions
export const isLocationByPath = LocationActions.isLocationByPath; // NOTE: What?
export const updateLocation = LocationActions.updateLocation;
export const undoLocation = LocationActions.undoLocation;
export const redoLocation = LocationActions.redoLocation;
export const pushLocation = LocationActions.pushLocation;
export const popLocation = LocationActions.popLocation;
export const updateWindowLocationHash =
	LocationActions.updateWindowLocationHash;
export const openURL = LocationActions.openURL;
export const scrollPageItemIntoView = LocationActions.scrollPageItemIntoView;
export const scrollTo = LocationActions.scrollTo;
export const scrollByWindowHeight = LocationActions.scrollByWindowHeight;
// Misc Actions
export const addClassNamesToBody = MiscActions.addClassNamesToBody;
export const removeClassNamesFromBody = MiscActions.removeClassNamesFromBody;
export const closeWindow = MiscActions.closeWindow;
export const changeLanguage = MiscActions.changeLanguage;
export const loadLanguage = MiscActions.loadLanguage;
export const print = MiscActions.print;
// Storage/LMS Actions
export const setSuccessStatus = LMSActions.setSuccessStatus;
export const setScore = LMSActions.setScore;
export const clearData = LMSActions.clearData;
export const setCourseToCompleted = LMSActions.setCourseToCompleted;
