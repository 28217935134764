import React from "react";
import classnames from "classnames";
import { PagesUtils, StoreUtils } from "funkis-foundation";
import _ from "lodash";
import { updateLocation } from "../../actions/Actions";
import { connect } from "../../utils/reduxUtils";
import DefaultButton from "./DefaultButton";
import { makeGetCurrentPage } from "../../../selectors/playerSelectors";
import Style from "./PageNavigation.module.css";

const { getSibling } = PagesUtils;

const PageNavigation = (props) => {
	const {
		page,
		className,
		classNameButton,
		show,
		hide = false, // use it to hide completely.
		color = "black",
		transparent = false,
		nextTextId = "FAA5BD0C-8239-4FAF-8301-BCDC32ED8654",
		previousTextId = "E2F25B30-76CC-4AE0-8BC6-708A124BEEB8",
		menuTextId = "8931969D-F644-46D9-8B13-97000804C5A4",
	} = props;
	const pages = StoreUtils.getReducer("pages").items;

	const enabled = show;
	const previousPage = getSibling(page.id, pages, -1);
	const nextPage = getSibling(page.id, pages, 1);

	const chapter = pages.find((p) => p.id === page.parentPageId);
	const showNextChapterButton =
		!nextPage &&
		_.get(chapter, "data.cmtPage.show_next_chapter_button") === "yes";
	const classNames = classnames(
		Style.root,
		"page-navigation animated",
		{ fadeOut: hide, fadeIn: !hide && show },
		className
	);
	return (
		<div className={classNames}>
			{/* PREVIOUS PAGE */}
			{previousPage !== undefined && (
				<DefaultButton
					labelTextId={previousTextId}
					className={classnames(classNameButton, "mx-2")}
					classNameShow=""
					classNameHide=""
					color={color}
					transparent={transparent}
					onClick={() => {
						updateLocation({
							pageId: page && page.id,
							path: [{ sibling: -1 }],
						});
					}}
				></DefaultButton>
			)}
			{/* NEXT PAGE */}
			{nextPage !== undefined && (
				<DefaultButton
					labelTextId={nextTextId}
					className={classnames(classNameButton, "mx-2")}
					classNameShow=""
					classNameHide=""
					enabled={enabled}
					color={color}
					transparent={transparent}
					onClick={() => {
						updateLocation({
							pageId: page && page.id,
							path: [{ sibling: 1 }],
						});
					}}
				></DefaultButton>
			)}
			{/* MENU */}
			{nextPage === undefined && (
				<DefaultButton
					labelTextId={menuTextId}
					className={classnames(classNameButton, "mx-2")}
					classNameShow=""
					classNameHide=""
					transparent={transparent}
					enabled={enabled}
					color={color}
					onClick={() => {
						updateLocation({ friendlyId: "menu" });
					}}
				></DefaultButton>
			)}
			{/* NEXT CHAPTER */}
			{showNextChapterButton && (
				<DefaultButton
					labelTextId={nextTextId}
					className={classnames(classNameButton, "mx-2")}
					classNameShow=""
					classNameHide=""
					enabled={enabled}
					color={color}
					transparent={transparent}
					onClick={() => {
						updateLocation({
							pageId: page && page.id,
							// next chapter page
							path: [{ parent: 1 }, { sibling: 1 }, { child: 0 }],
						});
					}}
				></DefaultButton>
			)}
		</div>
	);
};

const makeMapStateToProps = () => {
	const getCurrentPage = makeGetCurrentPage();
	const mapStateToProps = (state, props) => {
		return {
			page: getCurrentPage(state, props),
		};
	};
	return mapStateToProps;
};

export default connect(PageNavigation, makeMapStateToProps);
