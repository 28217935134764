import React from "react";
import classnames from "classnames";
import {
	FFText,
	PageItemActions,
	ReduxObjectSelectors,
} from "funkis-foundation";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";
import VideoPlayer from "funkis-template/components/core/VideoPlayer/VideoPlayer";
import SpeechBubble from "../../../components/core/SpeechBubble2";
import SimpleReveal from "../../../components/core/SimpleReveal";
import MultiSelect from "../../../components/core/MultiSelect";
import Image from "../../../components/core/Image";
import Text from "../../../components/core/Text";
import ReadMore from "../../../components/scroll/ReadMore";
import Style from "./PageItemConversation.module.css";
import {
	getBootstrapColor,
	getBootstrapOverridesForPresentValues,
} from "../../../utils/colorUtils";
import { getText } from "../../../utils/textUtils";
import { connect } from "../../../utils/reduxUtils";
import Hover from "../../../components/core/Hover";
import { getMediaType } from "utils/MediaUtils";

const { updatePageItem } = PageItemActions;
const { makeGetValueSelector } = ReduxObjectSelectors;

const PageItemConversation = (props) => {
	const { pageItem, size } = props;
	const { cmtPageItem = {} } = pageItem;
	const [hasVideoEnded, setHasVideoEnded] = React.useState(false);

	const isMobile = size === "mobile" || size === "mobile small";
	const imgOrVideo =
		cmtPageItem.image_or_video !== "" && cmtPageItem.image_or_video;

	const mediaType = getMediaType(imgOrVideo);
	const isVideo = mediaType === "video";

	const imageMarginsMobile = cmtPageItem.image_margins_mobile !== "no";
	const completed = pageItem.status === "completed";
	const correctIndexes = cmtPageItem.content_array
		.map((option, index) => (option.correct_option ? index : false))
		.filter((value) => value !== false);
	const selectedIndexes = pageItem.selectedIndexes || [];
	const selectedIndex = selectedIndexes[0];
	const isCorrect = correctIndexes.includes(selectedIndex);

	const handleVideoEnded = React.useCallback(() => {
		setHasVideoEnded(true);
	}, []);

	// OPTION
	const Option = ({ index, option, selected, completed }) => {
		return (
			<Hover
				enabled={!completed || cmtPageItem.redoable === "yes"}
				className={classnames(isMobile && "pb-1")}
			>
				{({ hover }) => (
					<SpeechBubble
						key={`speech-bubble-${index}`}
						className={classnames(
							Style.speechBubble,
							"mx-n1",
							completed && selected && Style.selected,
							completed && !selected && Style.unselected,
							hover && Style.selected,
							hover && "animated tada"
						)}
						color={
							hover
								? "interaction"
								: cmtPageItem.option_bg_color ||
								  cmtPageItem.option_background_color ||
								  "primary"
						}
						arrowDirection="down"
					>
						<FFText
							className={classnames(
								Style.optionText,
								cmtPageItem.mode === "emphasized"
									? "speech-bubble-text-emphasized text-uppercase text-center font-weight-bold"
									: "speech-bubble-text"
							)}
							style={{
								color: getBootstrapColor(
									cmtPageItem.option_text_color || "white"
								),
							}}
							textId={option.option}
						/>
					</SpeechBubble>
				)}
			</Hover>
		);
	};

	const shouldShowFeedback = completed && selectedIndex !== undefined;

	return (
		<PageItemScrollBase
			{...props}
			className="px-0 mx-0"
			showNextButton={completed}
			renderFunction={(renderProps) => {
				const { viewVisible } = renderProps;
				return (
					<React.Fragment>
						<div className="row m-0 px-3">
							<Text
								textId={cmtPageItem.header}
								tagName="h2"
								className="col-sm-8 mb-2 font-weight-bold"
								style={getBootstrapOverridesForPresentValues({
									color: cmtPageItem.header_text_color,
								})}
							/>
						</div>

						{getText(cmtPageItem.instruction) && (
							<div className="row m-0 px-3">
								<Text
									textId={cmtPageItem.instruction}
									tagName="span"
									className="col-sm-9 instruction font-italic mb-2"
									style={getBootstrapOverridesForPresentValues({
										color: cmtPageItem.header_text_color,
									})}
								/>
							</div>
						)}

						<div
							className={classnames(
								imageMarginsMobile ? "container-fluid" : ""
							)}
						>
							{isVideo ? (
								<div
									className={classnames(
										imageMarginsMobile ? "col-sm-12" : "w-100 px-sm-0 px-md-5"
									)}
								>
									<div className="w-100">
										<VideoPlayer
											src={imgOrVideo}
											posterFrame={cmtPageItem.posterframe}
											onEnded={handleVideoEnded}
											className={classnames(
												"animated",
												viewVisible ? "fadeIn" : "fadeOut"
											)}
										/>
									</div>
								</div>
							) : imgOrVideo ? (
								<div
									className={classnames(
										imageMarginsMobile ? "col-sm-12" : "w-100 px-sm-0 px-md-5"
									)}
								>
									<Image
										src={imgOrVideo}
										size={cmtPageItem.image_size}
										maxHeight={cmtPageItem.image_max_height}
										align={cmtPageItem.image_align}
										alignBottom={cmtPageItem.image_bottom_margin === "no"}
									/>
								</div>
							) : null}
						</div>

						{/* TRANSCRIBE SUBTITLE */}
						{isVideo && cmtPageItem.transcribe_video === "yes" && (
							<ReadMore
								pageItem={{
									cmtPageItem: {
										video_src: cmtPageItem.video,
										label_open: "85ED8C97-F3D6-452F-A598-BA4DBBC42DA1",
										label_closed: "C7353ED0-A22A-4287-BFF2-95230DDFEE28",
									},
								}}
								viewVisible={renderProps.viewVisible}
								className="mt-3"
							></ReadMore>
						)}

						<div className={classnames("row m-0", !isMobile && "px-3")}>
							{/* QUESTION */}
							<SpeechBubble
								/*  */
								arrowDirection=""
								color={
									cmtPageItem.question_bg_color ||
									cmtPageItem.question_background_color ||
									"dark-gray"
								}
								className={classnames(
									"col-sm-12 mb-4",
									cmtPageItem.image_bottom_margin !== "no" && "mt-5",
									viewVisible &&
										(completed || (isVideo && hasVideoEnded) || !isVideo)
										? "animated fadeIn"
										: "opacity-0"
								)}
								classNameSpeechBubble={Style.speechBubbleExpanded}
								classNameSpeechBubbleContainer={Style.speechBubbleExpanded}
							>
								<FFText
									textId={cmtPageItem.question}
									className="speech-bubble-text"
									style={{
										color: getBootstrapColor(
											cmtPageItem.question_text_color || "white"
										),
									}}
								/>
							</SpeechBubble>

							{/* OPTIONS */}
							<MultiSelect
								pageItem={{
									...pageItem,
									status:
										cmtPageItem.redoable === "yes"
											? "incomplete"
											: pageItem.status,
								}}
								className="row m-0 px-1 mb-3"
								maxSelections={1}
								autoDeselectWhenMaxSelectionsReached
								onSelect={() => {
									updatePageItem(pageItem.id, { status: "completed" });
								}}
								buttonClassName={classnames(
									Style.buttonAnswer,
									"col-md-6 mb-1",
									viewVisible &&
										(completed || (isVideo && hasVideoEnded) || !isVideo)
										? "animated fadeIn delay-1s"
										: "opacity-0"
								)}
								optionRender={(renderProps) => (
									<Option {...renderProps} completed={completed} />
								)}
							/>

							{/* FEEDBACK */}
							<SimpleReveal
								reveal={shouldShowFeedback}
								className={classnames(
									"row m-0 px-3 animated justify-content-start",
									viewVisible ? "fadeIn" : "fadeOut"
								)}
							>
								<SpeechBubble
									className={classnames(
										"w-100 feedback-bubble animated delay-1s fast",
										shouldShowFeedback ? "flipInX" : "flipOutX"
									)}
									classNameSpeechBubble={Style.speechBubbleExpanded}
									classNameContent="px-6 py-2"
									color={
										cmtPageItem.feedback_bg_color ||
										cmtPageItem.feedback_background_color ||
										"dark-gray"
									}
									arrowDirection=""
								>
									<FFText
										className={classnames(
											"mt-2 pl-0 speech-bubble-text",
											shouldShowFeedback ? "animated fadeInUp" : "opacity-0"
										)}
										style={{
											color: getBootstrapColor(
												cmtPageItem.feedback_text_color || "white"
											),
										}}
										tagName="p"
										textId={
											isCorrect
												? cmtPageItem.feedback_correct
												: cmtPageItem.feedback_incorrect
										}
									/>
								</SpeechBubble>
							</SimpleReveal>
						</div>
					</React.Fragment>
				);
			}}
		/>
	);
};

const isEven = (n) => {
	return n === parseFloat(n) ? !(n % 2) : void 0;
};

const makeMapStateToProps = () => {
	const GetValueSelector = makeGetValueSelector();

	const mapStateToProps = (state, props) => {
		return {
			size: GetValueSelector(state, { ...props, path: "size.name" }, "app"),
		};
	};
	return mapStateToProps;
};

export default connect(PageItemConversation, makeMapStateToProps);
