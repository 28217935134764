import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { StoreUtils } from "funkis-foundation";
import QuiddityBase from "./QuiddityBase";
import QuiddityBaseQuidInputs from "./QuiddityBaseQuidInputs";
import QuiddityBaseInput from "./QuiddityBaseInput";
import {
	upsertQuidInput,
	deleteQuidInput,
} from "../../requests/QuidInputRequests";

class QuiddityBackend extends React.Component {
	static propTypes = {
		quidType: PropTypes.string.isRequired,
		quidInputContext: PropTypes.string,
		quidContext: PropTypes.string,
		quidInputId: PropTypes.string,
		mode: PropTypes.oneOf([
			"input",
			"inputs",
			"output",
			"outputs",
			"bareOutputs",
		]),
		sort: PropTypes.func,
		renderInputIfNoInputs: PropTypes.bool,
		renderFunction: PropTypes.func,
		renderInput: PropTypes.func,
		renderInputs: PropTypes.func,
		renderOutput: PropTypes.func,
	};

	static defaultProps = {
		quidContext: "event",
		quidInputContext: "session",
		quidInputId: undefined,
		mode: "input",
		sort: ({ ids = [], items = [] }) => ids,
		renderInputIfNoInputs: false,
		renderFunction: (props) => <div />,
		renderInput: (props) => <div />,
		renderInputs: (props) => <div />,
		renderOutput: (props) => <div />,
		renderOutputs: (props) => <div />,
	};

	getComponent = (props) => {
		const {
			mode,
			sort,
			renderInputIfNoInputs,
			renderFunction,
			renderInput,
			renderInputs,
			renderOutput,
			renderOutputs,
		} = this.props;
		const { quidInputIds } = props;

		const sortFunc = (quidInputIds) => {
			const items = quidInputIds.map(
				(id) => StoreUtils.getReducer("quidInputs").itemsById[id]
			);
			return sort({ ids: quidInputIds, items });
		};

		switch (mode) {
			// use mode "bareInputs" when only bare bare data is needed.
			case "bareInputs":
			case "bareOutputs":
				return (
					<QuiddityBaseQuidInputs
						{...props}
						{...this.props}
						renderFunction={renderFunction}
					/>
				);
			case "input":
				return (
					<QuiddityBaseInput
						{...props}
						{...this.props}
						quidInputId={props.quidInputId || quidInputIds[0]}
						renderFunction={renderInput}
					/>
				);
			case "inputs":
				if (renderInputIfNoInputs && !quidInputIds.length) {
					return (
						<QuiddityBaseInput
							{...props}
							{...this.props}
							quidInputId={props.quidInputId || quidInputIds[0]}
							renderFunction={renderInputs}
						/>
					);
				}
				return sortFunc(quidInputIds).map((quidInputId, index) => (
					<QuiddityBaseInput
						key={`QuiddityBaseInput-${quidInputId}`}
						{...props}
						{...this.props}
						quidInputId={quidInputId}
						renderFunction={renderInputs}
						index={index}
					/>
				));
			case "output":
				return (
					<QuiddityBaseInput
						{...props}
						{...this.props}
						quidInputId={props.quidInputId || quidInputIds[0]}
						renderFunction={renderOutput}
					/>
				);
			case "outputs":
				return sortFunc(quidInputIds).map((quidInputId, index) => (
					<QuiddityBaseInput
						key={`QuiddityBaseInput-${quidInputId}`}
						{...props}
						{...this.props}
						quidInputId={quidInputId}
						renderFunction={renderOutputs}
						index={index}
					/>
				));
			default:
				if (QuiddityBase.propTypes[mode]) {
					return QuiddityBase.propTypes[mode]({
						...this.props,
						...props,
					});
				}
				return <p className="text-warning">{`Mode "${mode}" is not valid!`}</p>;
		}
	};

	render() {
		return (
			<QuiddityBase
				{...this.props}
				renderLoggedIn={(props) => {
					return this.getComponent(props);
				}}
				renderNotLoggedIn={(props) => (
					<p className="text-warning">{`Not correctly logged for context: ${
						props.logInType || props.quidInputContext
					}!`}</p>
				)}
			/>
		);
	}
}

QuiddityBackend.save = ({ id, data }) => {
	const quiddity = StoreUtils.getReducer("quiddity").item;
	const quidInput = StoreUtils.getReducer("quidInputs").itemsById[id];

	if (!quidInput) {
		return new Promise((resolve, reject) => {
			return reject("No quidinput found");
		});
	}

	quidInput.data = { ...quidInput.data, ...data };
	const { url, api } = quiddity;
	return upsertQuidInput(url + api, quidInput);
};

QuiddityBackend.remove = async ({ id }) => {
	const quiddity = StoreUtils.getReducer("quiddity").item;
	const { url, api } = quiddity;
	return await deleteQuidInput(url + api, id);
};

QuiddityBackend.saveItems = ({ items }) => {
	const quiddity = StoreUtils.getReducer("quiddity").item;
	const { url, api } = quiddity;
	items.forEach((item) => {
		upsertQuidInput(url + api, item);
	});
};

QuiddityBackend.getItems = ({ match }) => {
	return _.filter(StoreUtils.getReducer("quidInputs").items, match);
};

export default QuiddityBackend;
