import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import { TweenMax } from "gsap";
import { ReduxObjectSelectors } from "funkis-foundation";
import Text from "funkis-template/components/core/Text";
import LightBox from "funkis-template/components/core/LightBox";
import IconCheck from "funkis-template/assets/svg/IconCheck";
import IconCross from "funkis-template/assets/svg/IconCross";
import { getBootstrapColor } from "funkis-template/utils/colorUtils";
import { connect } from "funkis-template/utils/reduxUtils";
import { FFImage } from "funkis-foundation";

const { makeGetValueSelector } = ReduxObjectSelectors;

const Icon = ({
	score = 0,
	color,
	imageCorrect,
	imageIncorrect,
	imageNeutral,
}) => {
	const IconComp = score > -1 ? IconCheck : IconCross;

	const imageSrc = (score > -1 && imageCorrect) || imageIncorrect;

	if (imageSrc) {
		return <FFImage src={`./content/images/${imageSrc}`} />;
	} else {
		return <IconComp size="md" color={color} />;
	}
};

const DilemmaFeedback = (props) => {
	const {
		show,
		body,
		correct,
		score,
		programType,
		onCloseClick,
		imageCorrect,
		imageIncorrect,
		imageNeutral,
	} = props;
	const color = getBootstrapColor(
		(score > 0 && "success") || (score < 0 && "danger") || "gray"
	);
	const refIconContainer = useRef(null);

	let headerTextId;

	if (programType === "slide") {
		headerTextId =
			(score < 0 && "CD1546DB-A677-4A66-A0FD-964E0A2D86CD") ||
			(score === 0 && "F4B3C8DC-A84A-4EC9-9D1F-9AFEFE1D091F") ||
			(score > 0 && "B3494791-7CD4-4062-A1DA-8BBC7C330C12");
	} else {
		headerTextId =
			(score < 0 && "7E6F8E27-E560-4517-AB1B-C7C46B5C9DB8") ||
			(score === 0 && "2E58B73F-A80E-42EA-8607-76456C8FA06F") ||
			(score > 0 && "A6E00ED6-4A9A-4E0B-82DE-CAE5FE440AD5");
	}

	useEffect(() => {
		if (show && score == 0) {
			TweenMax.fromTo(
				refIconContainer.current,
				0.6,
				{ opacity: 0 },
				{ opacity: 1, delay: 1 }
			);
		}

		if (show && score > 0) {
			TweenMax.fromTo(
				refIconContainer.current,
				0.6,
				{ y: -100, x: 100, rotate: 30, opacity: 0 },
				{ y: 0, x: 0, rotate: 0, opacity: 1, delay: 1, ease: "bounce.out" }
			);
		}

		if (show && score < 0) {
			TweenMax.fromTo(
				refIconContainer.current,
				1,
				{ scale: 0, rotate: 45, opacity: 0 },
				{ scale: 1, rotate: 0, opacity: 1, delay: 1 }
			);
		}
	}, [show]);

	// const Icon = score > -1 ? IconCheck : IconCross;

	return (
		<LightBox
			onCloseClick={onCloseClick}
			show={show}
			classNameContentContainer="position-relative"
		>
			{/* ICON */}
			<div className="mb-4">
				<div
					ref={refIconContainer}
					className={classnames("d-inline-block")}
					style={{
						transformOrigin: score >= 0 ? "bottom center" : "center center",
					}}
				>
					<Icon
						size="md"
						color={color}
						score={score}
						imageCorrect={imageCorrect}
						imageIncorrect={imageIncorrect}
						imageNeutral={imageNeutral}
					/>
					{score === 0 && (
						<IconCross size="md" color={color} className="ml-1" />
					)}
				</div>
			</div>
			{/* TEXT */}
			<Text
				className="mb-1 font-weight-bold w-100 animated fadeIn"
				tagName={programType === "slide" ? "h1" : "h3"}
				hideIfMissing={false}
				textId={headerTextId}
			/>
			<Text
				className={classnames(
					"animated fadeInUp delay-1500ms",
					programType === "slide" && "p-md"
				)}
				tagName="p"
				textId={body}
			/>
		</LightBox>
	);
};

const makeMapStateToProps = () => {
	const getValueSelector = makeGetValueSelector();
	const mapStateToProps = (state, props) => {
		return {
			programType: getValueSelector(state, { path: "program_type" }, "app"),
		};
	};
	return mapStateToProps;
};

export default connect(DilemmaFeedback, makeMapStateToProps);
