import React from "react";
import PropTypes from "prop-types";

import { camelToUnderScore } from "../../../utils/StringUtils";
import * as StatusConstants from "../../../constants/StatusConstants";

import Select from "react-select";

const statusOptions = [
	{
		value: camelToUnderScore(StatusConstants.NOT_ATTEMPTED),
		label: <span>⚫</span>,
	},
	{
		value: StatusConstants.INCOMPLETE,
		label: <span>✔️</span>,
	},
	{
		value: StatusConstants.COMPLETED,
		label: <span>✅</span>,
	},
	/* we don't use failed passed etc anymore for the inspector  */
	// { value: StatusConstants.FAILED, label: <span>❌</span> },
	// { value: StatusConstants.PASSED, label: <span>☑️</span> },
	// { value: StatusConstants.UNKNOWN, label: <span>❓</span> },
];

class StatusSelect extends React.Component {
	static propTypes = {
		onSelect: PropTypes.func,
		status: PropTypes.oneOf([
			StatusConstants.COMPLETED,
			camelToUnderScore(StatusConstants.NOT_ATTEMPTED),
			StatusConstants.INCOMPLETE,
			StatusConstants.PASSED,
			StatusConstants.FAILED,
			StatusConstants.UNKNOWN,
			StatusConstants.UNLOCKED,
		]),
	};

	_onChange = (e) => {
		const { onSelect } = this.props;
		onSelect && onSelect(e.value);
	};

	getStatus = (status) => {
		var stat = statusOptions.find((stat) => stat.value == status);
		if (!stat) {
			stat = "notAttempted";
		}
		return stat;
	};

	render() {
		const { status, className } = this.props;
		return (
			<Select
				className={className}
				defaultValue={this.getStatus(status)}
				options={statusOptions}
				styles={{
					control: (base) => ({
						...base,
						backgroundColor: "#272726",
						border: "0",
						height: "24px",
						width: "70px",
					}),
					indicatorSeparator: () => ({
						backgroundColor: "#272726",
					}),
					singleValue: () => ({
						color: "#ffffff",
					}),
					indicatorsContainer: () => ({
						visibility: "hidden",
					}),
				}}
				onChange={this._onChange}
			/>
		);
	}
}

export default StatusSelect;
