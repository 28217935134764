import React from "react";
import classnames from "classnames";

const PageItemAnchor = ({ pageItem, style, className, children, rest }) => {
	const { href, target } = pageItem;
	return (
		<a
			style={Object.assign({}, pageItem.style, style)}
			className={classnames(
				className,
				"page-item page-item-anchor",
				pageItem.className
			)}
			href={href}
			target={target}
			children={children}
			{...rest}
		/>
	);
};

export default PageItemAnchor;
