import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import throttle from "lodash/throttle";

import * as VideoConstants from "../constants/VideoConstants";

class FFVideo extends React.Component {
	constructor(props) {
		super(props);
		this.videoRef = React.createRef();
	}

	static propTypes = {
		loop: PropTypes.bool,
		muted: PropTypes.bool,
		seek: PropTypes.number,
		state: PropTypes.string,
		fullscreen: PropTypes.bool,
		controls: PropTypes.bool,
		onVideoCanPlay: PropTypes.func,
		onVideoLoadedData: PropTypes.func,
		onVideoLoadedMetaData: PropTypes.func,
		onVideoStarted: PropTypes.func,
		onVideoEnded: PropTypes.func,
		onVideoPlaying: PropTypes.func,
		onVideoPlay: PropTypes.func,
		onVideoPaused: PropTypes.func,
		onVideoPause: PropTypes.func,
		onVideoTimeUpdate: PropTypes.func,
		onVideoError: PropTypes.func,
		onVideoFullscreenChange: PropTypes.func,
	};

	static defaultProps = {
		loop: false,
		muted: false,
		autoPlay: false,
		seek: 0,
		fullscreen: false,
		controls: false,
		state: VideoConstants.IDLE,
	};

	seek(newCurrentTime) {
		this.videoRef.current.currentTime = newCurrentTime;
	}

	componentDidMount() {
		this.videoRef.current.addEventListener("play", this._onVideoPlay);
		this.videoRef.current.addEventListener("pause", this._onVideoPause);
		// this.videoRef.current.addEventListener("playing", this._onVideoPlaying);
		this.videoRef.current.addEventListener(
			"loadeddata",
			this._onVideoLoadedData
		);
		this.videoRef.current.addEventListener(
			"loadedmetadata",
			this._onVideoLoadedMetaData
		);
		this.videoRef.current.addEventListener(
			"timeupdate",
			this._onVideoTimeUpdate
		);
		this.videoRef.current.addEventListener("ended", this._onVideoEnded);
		this.videoRef.current.addEventListener("error", this._onVideoError);
		this.videoRef.current.addEventListener("canplay", this._onVideoCanPlay);

		/* event supported but needs prefixes */
		this.videoRef.current.addEventListener(
			"webkitfullscreenchange",
			this._onVideoFullscreenChange
		);
		this.videoRef.current.addEventListener(
			"mozfullscreenchange",
			this._onVideoFullscreenChange
		);
		this.videoRef.current.addEventListener(
			"fullscreenchange",
			this._onVideoFullscreenChange
		);
		// this.videoRef.current.addEventListener("seeked", this._onVideoSeeked);
		// this.videoRef.current.addEventListener("waiting", this.videoWaitingHandler);
		// this.videoRef.current.addEventListener("durationchange", this.onVideoDurationChangeHandler);

		this.throttledOnVideoTimeUpdate = throttle(this.onVideoTimeUpdate, 100);
	}

	componentWillUnmount() {
		this.throttledOnVideoTimeUpdate.cancel();
		this.videoRef.current.removeEventListener("play", this._onPlayingHandler);
		this.videoRef.current.removeEventListener("pause", this._onVideoPause);
		// this.videoRef.current.removeEventListener("playing", this._onVideoPlaying);
		this.videoRef.current.removeEventListener(
			"loadeddata",
			this._onVideoLoadedData
		);
		this.videoRef.current.removeEventListener(
			"loadedmetadata",
			this._onVideoLoadedMetaData
		);
		this.videoRef.current.removeEventListener(
			"timeupdate",
			this._onVideoTimeUpdate
		);
		this.videoRef.current.removeEventListener("ended", this._onVideoEnded);
		this.videoRef.current.removeEventListener("canplay", this._onVideoCanPlay);
		this.videoRef.current.removeEventListener(
			"webkitfullscreenchange",
			this._onVideoFullscreenChange
		);
		this.videoRef.current.removeEventListener(
			"mozfullscreenchange",
			this._onVideoFullscreenChange
		);
		this.videoRef.current.removeEventListener(
			"fullscreenchange",
			this._onVideoFullscreenChange
		);
		// this.videoRef.current.removeEventListener("seeked", this._onVideoSeekedHandler);
		// this.videoRef.current.removeEventListener("waiting", this._videoWaitingHandler);
		// this.videoRef.current.removeEventListener("durationchange", this._onVideoDurationChangeHandler);
		this.videoRef.current.src = "";
		this.videoRef.current.load();
		this.videoRef = null;
	}

	componentDidUpdate(prevProps, prevState) {
		const video = this.videoRef.current;

		// Play || Pause
		if (prevProps.state != this.props.state) {
			if (this.props.state === VideoConstants.PLAYING) {
				this.playVideo();
			}

			if (this.props.state === VideoConstants.PAUSED) {
				this.pauseVideo();
			}

			if (
				(this.props.state === VideoConstants.PLAYING &&
					prevProps.state === VideoConstants.ENDED) ||
				(this.props.state === VideoConstants.PLAYING &&
					prevProps.state === VideoConstants.IDLE)
			) {
				this.props.onVideoStarted &&
					this.props.onVideoStarted(this.videoRef.current);
			}
		}

		// Seek
		if (this.props.seek != prevProps.seek) {
			this.videoRef.current.currentTime = this.props.seek;
		}

		// Fullscreen
		if (prevProps.fullscreen !== this.props.fullscreen) {
			if (this.props.fullscreen === true) {
				if (video.webkitSupportsFullscreen) {
					video.webkitEnterFullscreen();
				}
			} else if (this.props.fullscreen === false) {
				if (video.webkitSupportsFullscreen) {
					video.webkitExitFullscreen();
				}
			}
		}
	}

	playVideo() {
		const promise = this.videoRef.current.play();
		// If video does not start playing, the locomotive should not stay in its "playing" state
		if (promise !== undefined) {
			promise
				.then((_) => {
					this.props.onVideoPlaying &&
						this.props.onVideoPlaying(this.videoRef.current);
					this.props.onVideoPlay &&
						this.props.onVideoPlay(this.videoRef.current);
				})
				.catch((error) => {
					console.error(
						`FFVideo can't play '${this.props.src}'. current.play() resulted in and caught error `,
						error
					);
					this.pauseVideo();
				});
		} else {
			if (this.videoRef.current.paused) {
				this.pauseVideo();
			} else {
				this.props.onVideoPlaying &&
					this.props.onVideoPlaying(this.videoRef.current);
				this.props.onVideoPlay && this.props.onVideoPlay(this.videoRef.current);
			}
		}
	}

	pauseVideo() {
		if (this.videoRef && this.videoRef.current) {
			this.videoRef.current.pause();
			this.props.onVideoPaused &&
				this.props.onVideoPaused(this.videoRef.current);
		}
	}

	// when native video controls
	_onVideoPlay = (e) => {
		this.props.onVideoPlay && this.props.onVideoPlay(e);
	};

	// when native video controls
	_onVideoPause = (e) => {
		this.props.onVideoPause && this.props.onVideoPause(e);
	};

	_onVideoCanPlay = (e) => {
		this.props.onVideoCanPlay && this.props.onVideoCanPlay(e);
	};

	_onVideoLoadedData = (e) => {
		this.props.onVideoLoadedData && this.props.onVideoLoadedData(e);
	};

	_onVideoLoadedMetaData = (e) => {
		this.props.onVideoLoadedMetaData && this.props.onVideoLoadedMetaData(e);
	};

	_onVideoTimeUpdate = (e) => {
		const _this = this;
		requestAnimationFrame(() => {
			if (this.props.state === VideoConstants.ENDED) {
				// Don't execute any cuepoints or do anything time related if video has ended.
				// currentTime will be set to the duration of the video if the video has
				// ENDED state. This will trigger an timeupdate event that we suppress
				// here by exiting the function.
				return;
			}
			// this.props.onVideoTimeUpdate && this.props.onVideoTimeUpdate(e);
			_this.throttledOnVideoTimeUpdate(e);
		});
	};
	_onVideoFullscreenChange = (e) => {
		// https://stackoverflow.com/a/34897680/4763083
		const self = this.videoRef.current;
		const fullscreenElement =
			document.webkitFullscreenElement || document.fullscreenElement;
		if (e.target === self) {
			const isFullscreen = self === fullscreenElement;
			this.props.onVideoFullscreenChange &&
				this.props.onVideoFullscreenChange(e, { isFullscreen: isFullscreen });
		}
	};

	onVideoTimeUpdate = (e) => {
		this.props.onVideoTimeUpdate && this.props.onVideoTimeUpdate(e);
	};

	_onVideoEnded = (e) => {
		this.props.onVideoEnded && this.props.onVideoEnded(e);
	};

	_onVideoError = (e) => {
		this.props.onVideoError && this.props.onVideoError(e);
	};

	render() {
		const {
			className,
			style,
			src,
			width,
			height,
			muted,
			controls,
			loop,
			preload,
			poster,
			children,
			autoPlay,
			rest,
		} = this.props;
		return (
			<video
				ref={this.videoRef}
				className={classnames("ff-video", className)}
				style={{ ...style }}
				src={src}
				width={width}
				height={height}
				muted={muted}
				autoPlay={autoPlay}
				controls={controls}
				loop={loop}
				preload={preload}
				poster={poster}
				playsInline={true}
				{...rest}
			>
				{children}
			</video>
		);
	}
}

export default FFVideo;
