import * as StoreUtils from "../../utils/StoreUtils";

import {
	UPDATE_QUIDDITY_SESSIONS,
	UPDATE_QUIDDITY_SESSION,
	REMOVE_QUIDDITY_SESSION,
} from "../constants/ActionConstants";

export function updateQuidditySessions(items) {
	StoreUtils.getStore().dispatch(_updateQuidditySessions(items));
}

function _updateQuidditySessions(items) {
	return (dispatch: (action) => void) => {
		dispatch({ items, type: UPDATE_QUIDDITY_SESSIONS });
	};
}

export function updateQuidditySession(id, updates) {
	StoreUtils.getStore().dispatch(
		_updateQuidditySession(Object.assign(updates, { id }))
	);
}

function _updateQuidditySession(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_QUIDDITY_SESSION });
	};
}

export function removeQuidditySession(id) {
	StoreUtils.getStore().dispatch(_removeQuidditySession({ id }));
}

function _removeQuidditySession(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_QUIDDITY_SESSION });
	};
}
