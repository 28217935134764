import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	removeItemInStateItems,
	removeItemsInStateItems,
	addItemInStateItems,
	makeStateSnapshot,
	restoreStateSnapshot,
} from "../utils/reducerUtils";

import {
	UPDATE_TEXTS,
	UPDATE_TEXT,
	ADD_TEXT,
	REMOVE_TEXT,
	REMOVE_TEXTS,
	MAKE_TEXTS_SNAPSHOT,
	RESTORE_FROM_TEXTS_SNAPSHOT,
} from "../constants/ActionConstants";

const texts = (state = initialStateObjectCollection(), action) => {
	switch (action.type) {
		case UPDATE_TEXTS:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_TEXT:
			return updateItemInStateItems(state, action.item);
		case ADD_TEXT:
			return addItemInStateItems(state, action.item);
		case REMOVE_TEXT:
			return removeItemInStateItems(state, action.item);
		case REMOVE_TEXTS:
			return removeItemsInStateItems(state, action.item);
		case MAKE_TEXTS_SNAPSHOT:
			return makeStateSnapshot(state, action.item);
		case RESTORE_FROM_TEXTS_SNAPSHOT:
			return restoreStateSnapshot(state, action.item);
		default:
			return state;
	}
};

export default texts;
