const operators = {
	">": (a, b) => a > b,
	">=": (a, b) => a >= b,
	"<": (a, b) => a < b,
	"<=": (a, b) => a <= b,
	"=": (a, b) => a === b,
	"≠": (a, b) => a !== b,
	"AND": (a, b) => a && b,
	"OR": (a, b) => a || b,
};

const convertStr = {
	"OR": "||",
	"AND": "&&",
	"=": "===",
	"≠": "!==",
};
export const validate = ({ operator, valueA, valueB }) => {
	console.log("operator", operator);
	return (operators[operator] && operators[operator](valueA, valueB)) || true;
};
export const convert = (str) => convertStr[str] || str;
