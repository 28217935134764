import React from "react";
import classnames from "classnames";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";
import { connect } from "../../../utils/reduxUtils";
import { getBootstrapColor } from "../../../utils/colorUtils";
import Text from "../../../components/core/Text";
import Image from "../../../components/core/Image";
import { makeGetValueForCurrentPage } from "../../../../selectors/playerSelectors";

import Style from "./PageItemPageHeader.module.css";
import { getText } from "../../../utils/textUtils";

const PageItemPageHeader = (props) => {
	const { pageItem, cmtPageType } = props;
	const { cmtPage } = pageItem;

	if (cmtPageType === "print") {
		return <div />;
	}

	const textColor = getBootstrapColor(cmtPage.page_header_color);
	const backgroundImage = cmtPage.page_header_image || cmtPage.page_image;
	const backgroundColor = getBootstrapColor(
		cmtPage.page_header_background_color || "transparent"
	);
	const marginBottom = cmtPage.page_header_margin_bottom || 0;
	const hasBackground = backgroundImage || backgroundColor !== "transparent";
	const shouldHideSubheader =
		!getText(cmtPage.sub_title_1_txt_id) ||
		cmtPage.show_sub_header_on_page === "no";
	const isNakedPageHeader = !hasBackground && shouldHideSubheader;
	return (
		<PageItemScrollBase
			{...props}
			showNextButton={false}
			containerClassName="w-100"
			rootClassName={classnames(
				"page-item-page-header bg-transparant",
				Style.root,
				Style.fullBleed
			)}
			verticalPadding={false}
			revealEnabled={false}
			showDivider={false}
			renderFunction={({ viewVisible }) => {
				return (
					<Image
						src={backgroundImage}
						className={classnames(
							`pt-5 mb-${marginBottom} text-white animated fadeIn`,

							isNakedPageHeader
								? Style.nakedContentContainer
								: Style.contentContainer
						)}
						size="crop"
						align="center"
						autoSize={false}
						style={{ backgroundColor }}
					>
						<div className="container-fluid page-container-portrait">
							<div
								className={classnames(
									"content-container row m-0 align-items-center align-content-center"
								)}
							>
								{/* HEADER */}
								{cmtPage.show_header_on_page !== "no" && (
									<Text
										tagName="h2"
										textId={cmtPage.title_txt_id}
										className={classnames(
											"col-10 font-weight-bold animated",
											(getText(cmtPage.sub_title_1_txt_id) || hasBackground) &&
												"mb-1",
											viewVisible ? "fadeIn delay-500ms" : "fadeOut"
										)}
										style={{ color: textColor }}
									/>
								)}
								{/* SUB HEADER */}
								{cmtPage.show_sub_header_on_page !== "no" && (
									<Text
										tagName="h4"
										textId={cmtPage.sub_title_1_txt_id}
										className={classnames(
											"p-medium col-8 font-weight-bold animated",
											viewVisible ? "fadeIn delay-750ms" : "fadeOut"
										)}
										style={{ color: textColor }}
									/>
								)}
							</div>
						</div>
					</Image>
				);
			}}
		></PageItemScrollBase>
	);
};

const makeMapStateToProps = () => {
	const getValueForCurrentPage = makeGetValueForCurrentPage();
	const mapStateToProps = (state, props) => {
		return {
			cmtPageType: getValueForCurrentPage(
				state,
				{ ...props, path: "data.cmtPage.page_type" },
				"app"
			),
		};
	};
	return mapStateToProps;
};

export default connect(PageItemPageHeader, makeMapStateToProps);
