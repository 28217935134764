import Button from "components/Button/Button";
import Slider from "components/Slider/Slider";
import Stack from "components/Stack/Stack";
import Media, {
	MediaCrop,
	MediaFit,
} from "funkis-template/components/core/Media";
import Text from "funkis-template/components/core/Text";
import { RateContentArray } from "funkis-template/models/contentarray";
import { PageItemMedia, TextId } from "funkis-template/models/player";
import _ from "lodash";
import React, { FC } from "react";
import { GlobalText } from "./RateInput.constants";

type RateInputVariant =
	| "poll"
	| "question with feedback"
	| "question with feedback and correction";

export type RateInputProps = {
	contentArray: { label: TextId; id: string; value: number }[];
	onChange: (value: number) => void;
	onSubmit: (value: number) => void;
	value: number;
	loading: boolean;
	submitted: boolean;
	correctMinValue?: number;
	correctMaxValue?: number;
	correctValues?: number[];
	variant: RateInputVariant;
	redoable: boolean;
	feedbackCorrectText: TextId;
	feedbackIncorrectText: TextId;
	bodyText: TextId;
	headingText: TextId;
	instructionText: TextId;
	sliderMedia: PageItemMedia;
	media: PageItemMedia;
	sliderPadding: "0" | "1" | "2" | "3" | "4" | "5" | "6";
	sliderHandleColor?: string;
	sliderRailColor?: string;
	track: boolean;
};

type RangeAnswer = {
	type: "range";
	min: number;
	max: number;
};

type ValuesAnswer = {
	type: "values";
	values: number[];
};

type CorrectAnswer = RangeAnswer | ValuesAnswer;

function composeCorrectAnswer(
	correctValues?: number[],
	correctMinValue?: number,
	correctMaxValue?: number
): CorrectAnswer | undefined {
	if (_.isNumber(correctMinValue) && _.isNumber(correctMaxValue)) {
		return { type: "range", min: correctMinValue!, max: correctMaxValue! };
	}

	if (_.isArray(correctValues)) {
		return { type: "values", values: correctValues };
	}
}

const RateInput: FC<RateInputProps> = ({
	contentArray,
	value,
	onChange,
	onSubmit,
	loading = false,
	submitted,
	correctMinValue,
	correctMaxValue,
	correctValues,
	redoable,
	headingText,
	bodyText,
	instructionText,
	feedbackCorrectText,
	feedbackIncorrectText,
	media,
	sliderMedia,
	sliderPadding = "0",
	sliderHandleColor,
	sliderRailColor,
	track,
	variant,
}) => {
	const marks = contentArray.reduce(
		(acc, caItem) => ({ ...acc, [caItem.value]: { label: caItem.label } }),
		{}
	);

	const markKeys = Object.keys(marks).map((k) => parseInt(k, 10));

	const minValue = _.min(markKeys) ?? 0;
	const maxValue = _.max(markKeys) ?? 100;

	const correctAnswers = composeCorrectAnswer(
		correctValues,
		correctMinValue,
		correctMaxValue
	);

	const isAnswerCorrect =
		correctAnswers &&
		(correctAnswers.type === "range"
			? value >= correctAnswers.min && value <= correctAnswers.max
			: correctAnswers.values.includes(value));

	const isSubmittable =
		variant === "question with feedback" ||
		variant === "question with feedback and correction";

	const handleStyle = sliderHandleColor
		? ({ "--slider-color": sliderHandleColor } as React.CSSProperties)
		: {};

	const railStyle = sliderRailColor
		? ({ "--slider-rail-color": sliderRailColor } as React.CSSProperties)
		: {};

	return (
		<Stack spacing="medium" direction={"vertical"}>
			{media && (
				<Media
					className="col-sm-12"
					src={media}
					mediaFit={MediaFit.FullWidth}
					mediaCrop={MediaCrop.ResizeToFit}
				/>
			)}

			<Text
				className="col-sm-12"
				tagName="h2"
				textId={headingText}
				balance={true}
			/>

			<Text className="col-sm-9" tagName="div" textId={bodyText} />

			<Text
				className="col-sm-9 instruction font-italic"
				textId={instructionText}
			/>

			{sliderMedia && (
				<Media
					className="col-sm-12"
					src={sliderMedia}
					mediaFit={MediaFit.FullWidth}
					mediaCrop={MediaCrop.ResizeToFit}
				/>
			)}

			<Stack className="col-sm-12" spacing="small" direction={"vertical"}>
				<Text
					className="font-weight-bold"
					textId={GlobalText.HeadingYourAnswer}
				/>
				<div
					className={`px-${sliderPadding}`}
					style={{ ...handleStyle, ...railStyle }}
				>
					<Slider
						marks={marks}
						min={minValue}
						max={maxValue}
						value={value}
						onChange={onChange}
						disabled={loading || (!redoable && submitted)}
						track={track}
						tone={
							submitted && variant === "question with feedback and correction"
								? isAnswerCorrect
									? "positive"
									: "critical"
								: sliderHandleColor
								? "neutral"
								: "brand"
						}
					/>
				</div>
			</Stack>
			{isSubmittable && !submitted && (
				<Stack align="center">
					<Button
						onClick={() => {
							onSubmit && onSubmit(value);
						}}
					>
						<Text textId={GlobalText.ButtonSubmit} />
					</Button>
				</Stack>
			)}
			{isSubmittable && submitted && correctAnswers && (
				<Stack spacing="large">
					{variant === "question with feedback and correction" && (
						<Stack className="col-sm-12" spacing="small" direction={"vertical"}>
							<Text
								className="font-weight-bold"
								textId={GlobalText.HeadingCorrectAnswer}
							/>
							<div className={`px-${sliderPadding}`}>
								<Slider
									marks={marks}
									min={minValue}
									max={maxValue}
									tone="positive"
									value={
										correctAnswers.type === "range"
											? [correctAnswers.min, correctAnswers.max]
											: correctAnswers.values
									}
									track={correctAnswers.type === "range"}
									disabled={true}
									onChange={_.noop}
								/>
							</div>
						</Stack>
					)}

					<div className="col-sm-12">
						{isAnswerCorrect ? (
							<Text tagName="div" textId={feedbackCorrectText} />
						) : (
							<Text tagName="div" textId={feedbackIncorrectText} />
						)}
					</div>
				</Stack>
			)}
		</Stack>
	);
};

export default RateInput;
