import _ from "lodash";
import { useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { createSelector } from "reselect";
import { getQueryForContext } from "../../utils/QuidUtils";

export const makeUseQuidTemplate = () =>
	createSelector(
		(state) => state.quids.items,
		(_, props) => props.type,
		(_, props) => props.template,
		(quids, type, template) => {
			const quidTemplates = _.filter(quids, { type, template });
			return _.get(quidTemplates[0], "id");
		}
	);

export const useQuidTemplate = ({ quidType, type, template = true }) => {
	const makedUseQuidTemplate = useMemo(makeUseQuidTemplate, []);
	return useSelector((state) =>
		makedUseQuidTemplate(state, { type: quidType, template })
	);
};

export const makeUseQuids = () =>
	createSelector(
		(state) => state.quids.items,
		(state, props) =>
			getQueryForContext(
				props.quidContext,
				{ ...props, ...state.quiddity.item },
				["limit", "data"]
			),
		(quids, query) => _.filter(quids, query).map((item) => item.id)
	);

export const useQuids = (props) => {
	const makedUseQuids = useMemo(makeUseQuids, []);
	return useSelector(
		(state) =>
			makedUseQuids(state, { ...props, type: props.quidType || props.type }),
		shallowEqual
	);
};
