import React from "react";

const IconTime = ({ color = "#00005A", style = {}, variant = "normal" }) => {
	if (variant === "normal") {
		return (
			<svg
				width="24px"
				height="24px"
				viewBox="0 0 24 24"
				version="1.1"
				style={style}
			>
				<g
					id="Symbols"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
				>
					<g id="time">
						<circle
							id="Oval-Copy-17"
							fill={color}
							cx="12"
							cy="12"
							r="12"
						></circle>
						<polyline
							id="Rectangle"
							stroke="#FFFFFF"
							strokeWidth="2"
							points="12 6 12 12 18 12"
						></polyline>
					</g>
					<rect
						id="Rectangle-Copy-4"
						stroke="#CA0000"
						strokeWidth="11"
						x="-140"
						y="-2081"
						width="4075"
						height="5819"
					></rect>
				</g>
			</svg>
		);
	}

	if (variant === "large") {
		return (
			<svg
				width="30px"
				height="30px"
				viewBox="0 0 30 30"
				version="1.1"
				style={style}
			>
				<g
					id="Symbols"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
				>
					<g
						id="maIn-menu/page-menu-item/time"
						transform="translate(-3.000000, -3.000000)"
					>
						<g id="time" transform="translate(3.000000, 3.000000)">
							<circle
								id="Oval-Copy-17"
								fill={color}
								cx="15"
								cy="15"
								r="15"
							></circle>
							<polyline
								id="Rectangle"
								stroke="#FFFFFF"
								strokeWidth="2"
								points="14.4827586 7.24137931 14.4827586 14.4827586 21.7241379 14.4827586"
							></polyline>
						</g>
					</g>
				</g>
			</svg>
		);
	}

	return <></>;
};

export default IconTime;
