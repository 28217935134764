// _FETCH_STATUS
// Quiddity Organization Constants
export const UPDATE_QUIDDITY_ORGANIZATIONS = "UPDATE_QUIDDITY_ORGANIZATIONS";
export const UPDATE_QUIDDITY_ORGANIZATION = "UPDATE_QUIDDITY_ORGANIZATION";
export const REMOVE_QUIDDITY_ORGANIZATION = "REMOVE_QUIDDITY_ORGANIZATION";
// Quiddity Title Constants
export const UPDATE_QUIDDITY_TITLES = "UPDATE_QUIDDITY_TITLES";
export const UPDATE_QUIDDITY_TITLE = "UPDATE_QUIDDITY_TITLE";
export const REMOVE_QUIDDITY_TITLE = "REMOVE_QUIDDITY_TITLE";
// Quiddity Event Constants
export const UPDATE_QUIDDITY_EVENTS = "UPDATE_QUIDDITY_EVENTS";
export const UPDATE_QUIDDITY_EVENT = "UPDATE_QUIDDITY_EVENT";
export const REMOVE_QUIDDITY_EVENT = "REMOVE_QUIDDITY_EVENT";
// Quiddity Session Constants
export const UPDATE_QUIDDITY_SESSIONS = "UPDATE_QUIDDITY_SESSIONS";
export const UPDATE_QUIDDITY_SESSION = "UPDATE_QUIDDITY_SESSION";
export const REMOVE_QUIDDITY_SESSION = "REMOVE_QUIDDITY_SESSION";
// Unit Constants
export const UPDATE_UNITS = "UPDATE_UNITS";
export const UPDATE_UNIT = "UPDATE_UNIT";
export const REMOVE_UNIT = "REMOVE_UNIT";
// User Constants
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";
// Member Constants
export const UPDATE_MEMBERS = "UPDATE_MEMBERS";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const REMOVE_MEMBER = "REMOVE_MEMBER";
// Group Constants
export const UPDATE_GROUPS = "UPDATE_GROUPS";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const REMOVE_GROUP = "REMOVE_GROUP";
// Quid Constants
export const UPDATE_QUIDS = "UPDATE_QUIDS";
export const UPDATE_QUID = "UPDATE_QUID";
export const REMOVE_QUID = "REMOVE_QUID";
// Quid Input Constants
export const UPDATE_QUID_INPUTS = "UPDATE_QUID_INPUTS";
export const UPDATE_QUID_INPUT = "UPDATE_QUID_INPUT";
export const REMOVE_QUID_INPUT = "REMOVE_QUID_INPUT";
export const REMOVE_ALL_QUID_INPUTS = "REMOVE_ALL_QUID_INPUTS";
export const MAKE_QUID_INPUTS_SNAPSHOT = "MAKE_QUID_INPUTS_SNAPSHOT";
export const RESTORE_FROM_QUID_INPUTS_SNAPSHOT =
	"RESTORE_FROM_QUID_INPUTS_SNAPSHOT";
// Quiddity Constants
export const UPDATE_QUIDDITY = "UPDATE_QUIDDITY";
export const UPDATE_QUIDDITY_VALUE = "UPDATE_QUIDDITY_VALUE";
export const REMOVE_QUIDDITY_VALUE = "REMOVE_QUIDDITY_VALUE";
