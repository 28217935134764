import {
	initialStateObject,
	updateStateItem,
	undoStateItem,
	redoStateItem,
} from "../utils/reducerUtils";

import {
	UPDATE_LOCATION,
	UNDO_LOCATION,
	REDO_LOCATION,
} from "../constants/ActionConstants";

const location = (state = initialStateObject(), action) => {
	switch (action.type) {
		case UPDATE_LOCATION:
			return updateStateItem(state, action.item);
		case UNDO_LOCATION:
			return undoStateItem(state);
		case REDO_LOCATION:
			return redoStateItem(state);
		default:
			return state;
	}
};

export default location;
