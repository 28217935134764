import React from "react";
import classNames from "classnames";
const Page = (props) => {
	const { page, className, style, children } = props;
	return (
		<div
			className={classNames(
				"page",
				`page-id-${page.friendlyId || page.id}`,
				className,
				page.className
			)}
			data-page-id={page.id}
			data-location-path={page.locationPath}
			data-page-name={page.name}
			style={{ ...page.style, ...style }}
		>
			{children}
		</div>
	);
};

export default Page;
