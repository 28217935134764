import React from "react";
import { connect } from "../../utils/reduxUtils";
import classnames from "classnames";
import {
	ReduxCollectionSelectors,
	PageItemMenuButton,
	FFText,
	LocationSelectors,
	PageItemImage,
} from "funkis-foundation";
import Text from "../../components/core/Text";
import StatusIcon from "../../components/core/StatusIcon";
import Style from "./MainMenuPageButton.module.css";
import IconWithLabel from "../core/IconWithLabel";
import IconTime from "../../assets/svg/IconTime";
import { getBootstrapColor } from "../../utils/colorUtils";
import Hover from "../core/Hover";
import IOBadgeOutput from "../core/IOBadgeOutput";

const { makeGetItemByIdSelector } = ReduxCollectionSelectors;
const { makeGetLocationSelector } = LocationSelectors;

let _component;

export const setComponent = (component) => {
	_component = component;
};

const MainMenuPageButton = (props) => {
	if (_component) {
		return _component(props);
	}
	const { page, app, index, chapterIndex, chapterStatus, className } = props;
	const { cmtPage } = page.data;
	const bgImage = cmtPage.main_menu_image || cmtPage.menu_image;
	// main_menu_page_button_bg_color;
	// the first "child" of the first "parent chapter" should aways be clickable
	// and if a "parent chapter" is  set to incomplete and has a first "child", it too should be clickable
	const menuButtonEnabled =
		page.status === "unlocked" ||
		page.status !== "not_attempted" ||
		((chapterIndex === 0 || chapterStatus == "incomplete") && index === 0);
	const pageButtonIconColor = getBootstrapColor(
		app.main_menu_page_icon_color || "primary"
	);

	return (
		<Hover className={className}>
			{({ hover }) => (
				<PageItemMenuButton
					pageItem={{ pageId: page.id, enabled: menuButtonEnabled }}
					className={classnames("chapter-button border-bottom h-100")}
				>
					{/* INNER CONTAINER */}
					<div
						className={classnames(
							"d-flex w-100 h-100 pt-3 pb-6",
							!menuButtonEnabled && "opacity-50"
						)}
						style={{
							backgroundColor: getBootstrapColor(
								app.main_menu_page_button_bg_color || "transparent"
							),
							color: hover
								? getBootstrapColor("interaction")
								: getBootstrapColor(
										app.main_menu_page_button_text_color || "white"
								  ),
						}}
					>
						{/* BG IMAGE */}
						{bgImage && (
							<PageItemImage
								pageItem={{
									src: bgImage,
									backgroundImage: true,
									autoSize: false,
								}}
								className={Style.image}
							/>
						)}
						{/* CONTENT*/}
						<div
							className={classnames(
								Style.textContainer,
								"px-3 d-flex flex-column align-content-stretch"
							)}
						>
							{/* PAGE TITLE */}
							<FFText
								textId={cmtPage.title_txt_id}
								tagName="span"
								className="main-menu-page-button-header font-weight-bold flex-grow-0"
								style={{ marginTop: "-0.1875rem", marginBottom: "0.1875rem" }}
							/>
							{/* PAGE SUB TITLE 1 */}
							<Text
								textId={cmtPage.sub_title_1_txt_id}
								tagName="span"
								className="main-menu-page-button-subheader flex-grow-0"
							/>
							{/* ICONS */}
							<div
								className="d-flex flex-wrap mt-4"
								style={{
									color: getBootstrapColor(
										app.main_menu_page_button_text_color || "white"
									),
								}}
							>
								{/* DURATION */}
								{cmtPage.duration && (
									<IconWithLabel
										Icon={IconTime}
										labelText={cmtPage.duration}
										iconColor={pageButtonIconColor}
									/>
								)}
								{/* STATUS */}
								<StatusIcon
									status={page.status}
									className={Style.iconAndLabel}
									color={pageButtonIconColor}
								/>
								{/* GAMIFICATION - BADGES */}
								{app.main_menu_show_gamification_badges === "yes" && (
									<IOBadgeOutput
										pageId={page.id}
										mode="count"
										backgroundColorBadgeCounter="primary"
										colorBadgeCounter="white"
									/>
								)}
							</div>
						</div>
					</div>
				</PageItemMenuButton>
			)}
		</Hover>
	);
};

const makeMapStateToProps = () => {
	const getItemByIdSelector = makeGetItemByIdSelector();
	const getLocationSelector = makeGetLocationSelector();
	const mapStateToProps = (state, props) => {
		return {
			page: getItemByIdSelector(state, props, "pages"),
			location: getLocationSelector(state, props),
			app: state.app.item,
		};
	};
	return mapStateToProps;
};

export default connect(MainMenuPageButton, makeMapStateToProps);
