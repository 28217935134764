import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { getPageItem } from "../../PageItems";

import PageItems from "./PageItems";
import * as StatusConstants from "../../constants/StatusConstants";
import * as PageItemActions from "../../redux/actions/PageItemActions";
import { getDefaultPageItemComponent } from "../../utils/PageItemsUtils";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { GLOBAL_TEXTS } from "constants/texts";

import { getText } from "funkis-template/utils/textUtils";

const PageItem = ({ layer, id, pageItemOverrides = [] }) => {
	const pageItem = useSelector((state) => state.pageItems.itemsById[id]);

	useEffect(() => {
		if (pageItem.status === StatusConstants.NOT_ATTEMPTED || !pageItem.status) {
			PageItemActions.updatePageItem(pageItem.id, {
				status: StatusConstants.INCOMPLETE,
			});
		}
	}, [pageItem.status]);

	const PageItemElement =
		getPageItem(pageItem.type, false) || getPageItem(pageItem.proxyType);
	const DefaultPageItemComponent = getDefaultPageItemComponent();

	/*
   "pageItemOverrides" is an Array for legacy reasons. 
   In template project "pageItemOverrides" only used by PageItemLinkedBlock to override/add "isPrintPage": true, when showing PageItems in a print context
  */
	const firstPageItemOverrides = pageItemOverrides[0];
	const selfPageItemOverrides =
		firstPageItemOverrides &&
		firstPageItemOverrides.id === id &&
		firstPageItemOverrides;
	return (
		<ErrorBoundary pageItemId={pageItem.id}>
			<Suspense fallback={<div>{getText(GLOBAL_TEXTS.LOADING)}</div>}>
				<DefaultPageItemComponent
					PageItemElement={PageItemElement}
					pageItem={
						selfPageItemOverrides
							? { ...pageItem, ...selfPageItemOverrides }
							: pageItem
					}
				>
					<PageItems parentPageItemId={pageItem.id} layer={layer} />
				</DefaultPageItemComponent>
			</Suspense>
		</ErrorBoundary>
	);
};

export default PageItem;
