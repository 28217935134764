import { LocationUtils, Actions, LocalizationUtils } from "funkis-foundation";
import React from "react";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import _ from "lodash";

const locationSelector = (state) => _.get(state, "location.item", []);
const locationPageClassesSelector = createSelector(
	locationSelector,
	(locationItem) => {
		const locationPages = LocationUtils.getPagesFromLocation(locationItem);
		const pagesClassNames = locationPages
			.map((p) => `location-${p.friendlyId}`)
			.join(" ");
		return pagesClassNames;
	}
);

const mapStateToProps = (state) => {
	const language = _.get(state, "config.item.language");
	const langDirection = LocalizationUtils.getLanguageDirection(language);

	return {
		// Add more class props here if needed. adding the new and removing the old value is handled automatically by the component
		size: _.get(state, "app.item.size.className"),
		pagesClassNames: locationPageClassesSelector(state),
		language: language,
		devMode:
			_.get(state, "config.item.devMode") === true ? "dev-mode" : "production",
		textDirection: `text-direction-${langDirection}`,
	};
};

const ConnectedBodyClassUpdater = connect(mapStateToProps)(
	class BodyClassUpdater extends React.Component {
		constructor(props) {
			super(props);
			for (const propKey of this.getPropKeys(props)) {
				this.handleBodyClassUpdate("", props[propKey]);
			}
		}

		getPropKeys(props) {
			return _.keysIn(props).filter((pk) => pk != "dispatch");
		}

		componentDidUpdate = (prevProps, prevState) => {
			let classNamesToRemove = [];
			let classNamesToAdd = [];
			for (let propKey of this.getPropKeys(this.props)) {
				const oldValue = prevProps[propKey] || "";
				const newValue = this.props[propKey] || "";
				if (oldValue !== newValue) {
					classNamesToRemove.push(oldValue);
					classNamesToAdd.push(newValue);
				}
			}
			if (classNamesToRemove.length > 0 || classNamesToAdd.length > 0) {
				this.handleBodyClassUpdate(
					classNamesToRemove.join(" "),
					classNamesToAdd.join(" ")
				);
			}
		};

		handleBodyClassUpdate(oldToRemove = "", newToAdd = "") {
			Actions.removeClassNamesFromBody(oldToRemove);
			Actions.addClassNamesToBody(newToAdd);
		}

		render() {
			return null;
		}
	}
);

export default ConnectedBodyClassUpdater;
