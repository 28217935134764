import { Howler, Howl } from "howler";

/* Adjust "global" sound settings */
// attempt to silently unlock audio playback by playing an empty buffer on the first touchend event. This seems to work on iOS
Howler.autoUnlock = true;
const sounds = {};

export function registerSound({ src, loop, volume }) {
	return new Howl({
		src,
		loop,
		preload: true,
		volume,
	});
}

export const set = (id, { src, loop = false, volume = 1 }) => {
	sounds[id] = registerSound({ id, src, loop, volume });
};

export const get = (id) => {
	return sounds[id];
};

export const play = (id, { delay = 0 } = {}) => {
	setTimeout(() => {
		!get(id).playing() && get(id).play();
	}, delay);
	return get(id);
};

export const stop = (id, { delay = 0 } = {}) => {
	setTimeout(() => {
		get(id).stop();
	}, delay);
	return get(id);
};

export const fade = (id, { from = 0, to = 1, duration = 1000 } = {}) => {
	return get(id).fade(from, to, duration);
};

export function setGlobalVolume(volume) {
	const makeOutOfRangeError = (defaultValue) =>
		new Error(
			`Volume ${volume} is not in the valid range (0.0 - 1.0) defaulting to ${defaultValue}`
		);
	if (volume < 0) {
		console.error(makeOutOfRangeError(0));
		volume = 0;
	}
	if (volume > 1) {
		console.error(makeOutOfRangeError(1));
		volume = 1;
	}

	Howler.volume(volume);
}

export function muteAllSounds() {
	Howler.mute(true);
}
export function unmuteAllSounds() {
	Howler.mute(false);
}
