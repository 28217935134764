import React from "react";
import { PageItemModal } from "funkis-foundation";
import { useSelector } from "react-redux";
import _ from "lodash";

const LoginErrorDialog = ({ open }) => {
	const quiddityItem = useSelector((state) =>
		_.get(state, "quiddity.item", {})
	);
	const appItem = useSelector((state) => _.get(state, "app.item", {}));
	const titleName = useSelector((state) =>
		_.get(state, ["texts", "itemsById", appItem.title_txt_id, "value"], "N/A")
	);

	const shouldHaveGroup = appItem.program_type === "slide";
	const hasGroup = !!quiddityItem.group;
	const hasMember = !!quiddityItem.member;
	const sessionKey = quiddityItem.code;

	const adminSiteUrl = window.location.origin +  "/funkis-admin/#/user";

	const linkStyle = { cursor: "pointer", textDecoration: "underline" };

	let error;

	if (!hasMember) {
		error = {
			heading: "",
			body: (
				<React.Fragment>
					<p>{`"${titleName}" couldn't be accessed because you're not yet enrolled in the session "${sessionKey}".`}</p>
					<br />
					<p>
						<a href="mailto:" style={linkStyle}>
							Contact the session administrator
						</a>{" "}
						to enroll.
					</p>
					<br />
					<p>
						Or{" "}
						<a href={adminSiteUrl} style={linkStyle}>
							go to your profile page
						</a>{" "}
						to see the sessions you are enrolled in.`
					</p>
				</React.Fragment>
			),
		};
	} else if (shouldHaveGroup && !hasGroup) {
		error = {
			heading: "",
			body: (
				<React.Fragment>
					<p>{`"${titleName}" couldn't be accessed because you're not assigned to a group that is enrolled in the session "${sessionKey}".`}</p>
					<br />
					<br />
					<p>
						<a href="mailto:">Contact the session administrator</a> to enroll.
					</p>
					<br />
					<br />
					<p>
						Or <a href={adminSiteUrl}>go to your profile page</a> to see the
						sessions you are enrolled in.
					</p>
				</React.Fragment>
			),
		};
	}

	if (!open) {
		return null;
	}
	console.log({ error });

	return (
		<PageItemModal
			pageItem={{ enabled: true, show: open, unmontChildrenOnHide: true }}
		>
			<div
				style={{ backgroundColor: "white", pointerEvents: "all" }}
				className="position-absolute position-top w-100 h-100 d-flex justify-content-center align-items-center"
			>
				<div
					style={{ maxWidth: "100%", maxHeight: "100%" }}
					className="d-flex flex-column bg-white p-4 mb-5"
				>
					{error.body}
				</div>
			</div>
		</PageItemModal>
	);
};
export default LoginErrorDialog;
