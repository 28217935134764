import * as StoreUtils from "../../utils/StoreUtils";

import {
	UPDATE_QUIDDITY_EVENTS,
	UPDATE_QUIDDITY_EVENT,
	REMOVE_QUIDDITY_EVENT,
} from "../constants/ActionConstants";

export function updateQuiddityEvents(items) {
	StoreUtils.getStore().dispatch(_updateQuiddityEvents(items));
}

function _updateQuiddityEvents(items) {
	return (dispatch: (action) => void) => {
		dispatch({ items, type: UPDATE_QUIDDITY_EVENTS });
	};
}

export function updateQuiddityEvent(id, updates) {
	StoreUtils.getStore().dispatch(
		_updateQuiddityEvent(Object.assign(updates, { id }))
	);
}

function _updateQuiddityEvent(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_QUIDDITY_EVENT });
	};
}

export function removeQuiddityEvent(id) {
	StoreUtils.getStore().dispatch(_removeQuiddityEvent({ id }));
}

function _removeQuiddityEvent(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_QUIDDITY_EVENT });
	};
}
