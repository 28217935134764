import classNames from "classnames";
import Heading from "components/Heading/Heading";
import Stack from "components/Stack/Stack";
import Text from "funkis-template/components/core/Text";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { ParticipantsScrollBlock } from "funkis-template/models/pageitem";
import { PageItem } from "funkis-template/models/player";
import _ from "lodash";
import { getMembers } from "quiddity";
import { CSSProperties, FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { GlobalText } from "./Participants.constants";
import Styles from "./Participants.module.css";

const selectMembersForSession = (sessionId) => (state) =>
	state.members.items.filter((member) => (member.quidditySession = sessionId));

type AvatarProps = {
	backgroundColor?: string;
	textColor?: string;
	user: {
		firstName: string;
		lastName: string;
	};
};
const Avatar: FC<AvatarProps> = ({ user: { firstName, lastName } }) => {
	return (
		<div className={classNames(Styles.avatar)}>
			{firstName.charAt(0) + lastName.charAt(0)}
		</div>
	);
};

const PageItemParticipants: FC<{
	pageItem: PageItem<ParticipantsScrollBlock>;
}> = (props) => {
	const {
		pageItem: { cmtPageItem },
	} = props;
	const apiUrl = useSelector((state: any) => {
		const quiddity = state.quiddity.item;
		return quiddity.url + quiddity.api;
	});
	const quidditySessionId = useSelector((state) =>
		_.get(state, "quiddity.item.session.id")
	);

	const quiddityGroupId = useSelector((state) =>
		_.get(state, "quiddity.item.group.id")
	);

	// Get all members for session...
	useEffect(() => {
		getMembers(apiUrl, {
			quidditySession: quidditySessionId,
			populate: "user",
		});
	}, [apiUrl, quidditySessionId]);

	const members = useSelector(selectMembersForSession(quidditySessionId));

	const sortedMembers = [...members];
	sortedMembers.sort((a) => {
		const isGroupLeader = _.get(a, "properties.isGroupLeader");
		if (isGroupLeader) {
			return -1;
		} else {
			return 1;
		}
	});

	const membersWithRole = sortedMembers.map((member) => ({
		...member,
		role: _.get(member, "properties.isGroupLeader")
			? GlobalText.LabelFacilitator
			: GlobalText.LabelParticipant,
	}));

	const filteredMembers =
		cmtPageItem.show_members === "Group members"
			? _.filter(membersWithRole, { group: quiddityGroupId })
			: membersWithRole;

	const colors = {
		"--participants-avatar-background-color":
			cmtPageItem.avatar_background_color !== ""
				? cmtPageItem.avatar_background_color
				: undefined,
		"--participants-avatar-text-color":
			cmtPageItem.avatar_text_color !== ""
				? cmtPageItem.avatar_text_color
				: undefined,
	} as CSSProperties;

	return (
		<PageItemScrollBase
			{...props}
			style={colors}
			className="container-fluid"
			renderFunction={() => (
				<div className="row m-0">
					<div className="col-sm-12">
						<Stack>
							<Heading level="2" textId={cmtPageItem.header} />
							<Text tagName="p" textId={cmtPageItem.body} />
							<ul className={classNames("list-unstyled", Styles.groupMembers)}>
								{filteredMembers.map((member) => {
									const fullName = `${member.user.firstName} ${member.user.lastName}`;
									return (
										<li
											className={classNames(Styles.liReset, Styles.groupMember)}
											title={fullName}
										>
											<Stack direction="horizontal" spacing="small">
												<Avatar user={member.user} />
												<Stack spacing="none">
													<div className={Styles.name}>{fullName}</div>
													<Text
														className={Styles.roleHeader}
														textId={GlobalText.RoleHeader}
													/>
													<Text className={Styles.role} textId={member.role} />
												</Stack>
											</Stack>
										</li>
									);
								})}
							</ul>
						</Stack>
					</div>
				</div>
			)}
		/>
	);
};

export default PageItemParticipants;
