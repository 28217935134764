import useInterval from "hooks/useInterval";
import { useState } from "react";
import { getBootstrapColor } from "../../../../utils/colorUtils";

const QuizTimer = ({
	state = "paused",
	duration,
	onTime,
	baseColor = "primary",
}) => {
	const [time, setTime] = useState(0);

	const isRunning = state === "playing";

	useInterval(() => {
		const nextCurrentTime = time + 0.1;
		if (nextCurrentTime <= duration && isRunning) {
			setTime(nextCurrentTime);
			onTime && onTime({ time: nextCurrentTime });
		}
	}, 100);

	const progress = time / duration;

	return (
		<div className="position-relative">
			{/* PROGRESS BAR */}
			<div
				className="progress-bar-background w-100"
				style={{ backgroundColor: "#FFC256" }}
			>
				<div
					className="progress-bar"
					style={{
						width: duration === 0 ? "100%" : progress * 100 + "%",
						height: "0.5625rem",
						backgroundColor: getBootstrapColor(baseColor),
					}}
				/>
			</div>
		</div>
	);
};

export default QuizTimer;
