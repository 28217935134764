import React, { useState } from "react";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import IOText from "../../components/scroll/IOText";
import Backend from "../../components/backend/Backend";

const PageItemQuiddityComment = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;
	const ioID = cmtPageItem.io_id;
	const [completed, setCompleted] = useState();
	const [canSave, setCanSave] = useState();

	return (
		<PageItemScrollBase {...props} showNextButton={completed}>
			<Backend
				{...props}
				className="row m-0"
				quidType="comment"
				mode="input"
				targetDataKey={ioID}
				renderInput={(renderProps) => {
					const { hasSavedDataForKeys, hasUnsavedDataForKeys } = renderProps;
					setCompleted(hasSavedDataForKeys(["value"]));
					setCanSave(hasUnsavedDataForKeys(["value"]));
					return (
						<IOText {...props} {...renderProps} showSendButton={canSave} />
					);
				}}
			/>
		</PageItemScrollBase>
	);
};

export default PageItemQuiddityComment;
