import _ from "lodash";
import { convertStatusConstantToScormStatus } from "../utils/StatusUtils";
import * as StatusConstants from "../constants/StatusConstants";
import * as StoreUtils from "../utils/StoreUtils";
import * as MockScormConstants from "./Scorm2004Constants";

const getConfigObject = () => {
	const config = StoreUtils.getReducer("config");
	return config.item;
};

export default class SessionStorage {
	save(name, domain, data) {
		this.setData(name, domain, data);
	}

	restore(name, domain, restoreFunction) {
		const data = this.getData(domain);
		const restoredData = restoreFunction(data[name]);
	}

	getData(domain) {
		const data = window.sessionStorage.getItem(`${domain}_data`);
		const pData = JSON.parse(data);
		return (data && JSON.parse(data)) || {};
	}

	setData(name, domain, data) {
		const oldData = this.getData(domain);
		const newData = Object.assign({}, oldData, { [name]: data });
		window.sessionStorage.setItem(`${domain}_data`, JSON.stringify(newData));
	}

	clearData(domain) {
		console.log("SessionStorage: clearData()");
		sessionStorage.removeItem(`${domain}_data`);
	}

	setCourseStatus(domain, value) {
		console.log("SessionStorage: setCourseStatus", value);

		sessionStorage.setItem(`${domain}-course-status`, value);

		if (
			value === StatusConstants.COMPLETED ||
			value === convertStatusConstantToScormStatus(StatusConstants.COMPLETED)
		) {
			console.log(
				"SessionStorage: course is completed and success_status is therefore set to passed"
			);
			sessionStorage.setItem(
				`${domain}-success-status`,
				convertStatusConstantToScormStatus(StatusConstants.PASSED)
			);
		} else {
			console.log(
				"SessionStorage: course is not completed and success_status is therefore set to failed"
			);
			sessionStorage.setItem(
				`${domain}-success-status`,
				convertStatusConstantToScormStatus(StatusConstants.PASSED)
			);
		}
	}

	getCourseStatus(domain) {
		const value = sessionStorage.getItem(`${domain}-course-status`);
		console.log("SessionStorage: getCourseStatus", value);
		return value;
	}

	setCompletionStatus(domain, value) {
		console.log(
			`SessionStorage: setCompletionStatus: Setting completion status with value:`,
			value
		);
		sessionStorage.setItem(`${domain}-completion-status`, value);
	}

	getCompletionStatus(domain) {
		console.log(
			`SessionStorage: getCompletionStatus: Getting completion status'. Value:`,
			value
		);
		return sessionStorage.getItem(`${domain}-completion-status`);
	}

	setSuccessStatus(domain, value) {
		console.log(
			`sessionStorage: setSuccessStatus: Setting success status with value:`,
			value
		);
		sessionStorage.setItem(`${domain}-success-status`, value);
	}

	getSuccessStatus(domain) {
		console.log(
			`SessionStorage: getSuccessStatus: Getting success status'. Value:`,
			value
		);
		return sessionStorage.getItem(`${domain}-success-status`);
	}

	setScore(domain, rawScore, minScore, maxScore) {
		console.log("SessionStorage: setScore", rawScore, minScore, maxScore);
		sessionStorage.setItem(`${domain}-score-raw`, rawScore);
		sessionStorage.setItem(`${domain}-score-min`, minScore);
		sessionStorage.setItem(`${domain}-score-max`, maxScore);
		sessionStorage.setItem(
			`${domain}-score-scaled`,
			(rawScore / maxScore).toFixed(2)
		);
	}

	getScore(domain) {
		var value = sessionStorage.getItem(`${domain}-score-raw`);
		console.log("SessionStorage: getScore", value);
		return value;
	}

	getUserName(domain) {
		var userName = sessionStorage.getItem(`${domain}-user-name`);
		console.log(`SessionStorage: getUserName(). User name:`, userName);
		return userName;
	}

	getUserId(domain) {
		var userId = sessionStorage.getItem(`${domain}-user-id`);
		console.log(`SessionStorage: getUserId(). User ID:`, userId);
		return userId;
	}

	getQuiddityTitleId() {
		const config = getConfigObject();
		const quiddityTitleId = _.get(
			config,
			["lms", MockScormConstants.QUIDDITY_TITLE_ID],
			"no title id found"
		);
		console.log(
			`SessionStorage: getQuiddityTitleId(). Quiddity Title ID:`,
			quiddityTitleId
		);
		return quiddityTitleId;
	}
	getQuiddityTitleOrganizationId() {
		const config = getConfigObject();
		const quiddityTitleOrganizationId = _.get(
			config,
			["lms", MockScormConstants.QUIDDITY_TITLE_ORGANIZATION_ID],
			"no title organization id found"
		);
		console.log(
			`SessionStorage: getQuiddityTitleOrganizationId(). Quiddity Title Organization ID:`,
			quiddityTitleOrganizationId
		);
		return quiddityTitleOrganizationId;
	}

	getQuiddityTitleName() {
		const config = getConfigObject();
		const quiddityTitleName = _.get(
			config,
			["lms", MockScormConstants.QUIDDITY_TITLE_NAME],
			"no title name found"
		);
		console.log(
			`SessionStorage: getQuiddityTitleName(). Quiddity Title name:`,
			quiddityTitleName
		);
		return quiddityTitleName;
	}
}
