import * as StoreUtils from "../../utils/StoreUtils";

// Action constants
import {
	UPDATE_GROUPS,
	UPDATE_GROUP,
	REMOVE_GROUP,
} from "../constants/ActionConstants";

export function updateGroups(items) {
	StoreUtils.getStore().dispatch(_updateGroups(items));
}

function _updateGroups(items) {
	return (dispatch: (action) => void) => {
		dispatch({ items, type: UPDATE_GROUPS });
	};
}

export function updateGroup(id, updates) {
	StoreUtils.getStore().dispatch(_updateGroup(Object.assign(updates, { id })));
}

function _updateGroup(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_GROUP });
	};
}

export function removeGroup(id) {
	StoreUtils.getStore().dispatch(_removeGroup({ id }));
}

function _removeGroup(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_GROUP });
	};
}
