import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import * as StoreUtils from "../../utils/StoreUtils";
import * as LocationUtils from "../../utils/LocationUtils";
import * as LocationActions from "../../redux/actions/LocationActions";
import { updatePages } from "../../redux/actions/PageActions";
import { updateConfig } from "../../redux/actions/ConfigActions";

import { clearStorage, loadLanguage } from "../../actions/MiscActions";

const getInferredSearchParameters = () => {
	const sessionKey = StoreUtils.getReducer("quiddity")?.item?.session?.code;
	const hasValue = (value) => value !== undefined;
	return _.pickBy({sessionKey}, hasValue);
}

class Location extends React.Component {
	constructor(props) {
		super(props);
		this.state = { initialized: false };
	}

	componentDidMount() {
		const { config } = this.props;
		// Use location from url or default location
		const location = LocationUtils.hashToLocation(window.location.hash);
		const inferredSearchParameters = getInferredSearchParameters();
		const query = Object.assign(inferredSearchParameters, LocationUtils.getQueryObjectFromCurrentUrl());

		const languageMenuLocation =
			LocationUtils.getLocationFromPageFriendlyId("select-language");

		// This is where the empty path "#" / "/" etc cases  is handled
		LocationActions.updateLocation(location || languageMenuLocation, query);

		// Set initial hash if not set
		if (!location) {
			LocationUtils.updateWindowHash(languageMenuLocation, query);
		}

		// Clear storage from query
		if (query.clearStorage === "true") {
			clearStorage();
		}

		// Change language from query
		if (query.language) {
			loadLanguage(query.language, true);
		}

		// Set all pages status from query
		if (query.chaptersStatus) {
			const pages = StoreUtils.getReducer("pages").items;
			pages.forEach((page) => {
				page.status = query.chaptersStatus;
			});
			updatePages(pages);
		}

		// Set inspector status from query
		if (query.showInspector === "true") {
			updateConfig({ showInspector: true });
		}

		this.addLocationStoreObserver(config);
		window.addEventListener("hashchange", this._onWindowHashChange);

		this.setState({ initialized: true });
	}

	addLocationStoreObserver(config) {
		StoreUtils.addStoreObserver(
			"location",
			"localStorage",
			config.projectPrefix,
			function restoreFunction(location) {
				// Restore location?
			},
			function saveFunction(newState) {
				const location = (newState && newState.item) || [];
				const previousQueries = _.get(newState, "pastItems", [])
					.map((prevItem) => prevItem.query)
					.map((query) =>
						_.pick(query, ["sessionKey", "language", "email", "project"])
					);

				const inferredParameters = getInferredSearchParameters();
				// reverse to give the latest one precedence
				const query = Object.assign(
					{},
					inferredParameters,
					...previousQueries.reverse(),
					location.query || {}
				);
				LocationUtils.updateWindowHash(location.location, query);
			}
		);
	}

	_onWindowHashChange() {
		const locationReducer = StoreUtils.getReducer("location");
		const currentLocation = locationReducer.item.location;
		const currentQuery = locationReducer.item.query;

		const browserLocation = LocationUtils.hashToLocation(window.location.hash);
		const browserQuery = LocationUtils.getQueryObjectFromCurrentUrl();

		const pastLocation =
			locationReducer.pastItems[locationReducer.pastItems.length - 1];
		const futureLocation = locationReducer.futureItems[0];

		// Update location if navigation has occured in browser (back/forward)
		// BUT ONLY if the browser location is valid. This check for "undefined" solves previous bug resulting in
		// trying to navigate to #undefined in some circumstances
		const hasValidBrowserLocation = browserLocation !== undefined;
		if (
			hasValidBrowserLocation &&
			!_.isEqual(browserLocation, currentLocation)
		) {
			if (
				_.isEqual(browserLocation, pastLocation && pastLocation.location) &&
				_.isEqual(browserQuery, pastLocation.query)
			) {
				LocationActions.undoLocation();
			} else if (
				_.isEqual(browserLocation, futureLocation && futureLocation.location) &&
				_.isEqual(currentQuery, browserQuery)
			) {
				LocationActions.redoLocation();
			} else {
				LocationActions.updateLocation(browserLocation, browserQuery);
			}
		}
	}

	static propTypes = {
		config: PropTypes.object.isRequired,
	};

	static defaultProps = {};

	render() {
		return (
			<React.Fragment>
				{this.state.initialized && this.props.children}
			</React.Fragment>
		);
	}
}

export default Location;
