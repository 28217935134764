import _ from "lodash";

export const filterPageItemsByParentPageId = (pageItems, parentPageId) =>
	pageItems.filter((pageItem) => pageItem.parentPageId === parentPageId);

export const filterPageItemsByParentPageItemId = (
	pageItems,
	parentPageItemId
) =>
	pageItems.filter(
		(pageItem) => pageItem.parentPageItemId === parentPageItemId
	);

export const filterPageItemsByLayer = (pageItems, layer) =>
	pageItems.filter(
		(pageItem) => pageItem.parentPageItemId || pageItem.layer === layer
	);

export const filterPageItemsByVisibility = (pageItems, visible) =>
	pageItems.filter((pageItem) => pageItem.visible === visible);

export const filterPageItemsBySize = (pageItems, size) => {
	return pageItems.filter((pageItem) => {
		const sizes = Array.isArray(pageItem.size)
			? pageItem.size
			: (pageItem.size && pageItem.size.split(" ")) || [];
		const intersects = _.intersection(
			sizes,
			size ? size.split(" ").length : [null]
		);
		return !pageItem.size || intersects;
	});
};

const _filters = {
	parentPageId: filterPageItemsByParentPageId,
	parentPageItemId: filterPageItemsByParentPageItemId,
	visibility: filterPageItemsByVisibility,
	layer: filterPageItemsByLayer,
	size: filterPageItemsBySize,
};

export function getPageItemsFilter(id) {
	return _filters[id];
}
