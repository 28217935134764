import * as StatusConstants from "../constants/StatusConstants";

export function convertStatusConstantToScormStatus(status) {
	if (status === StatusConstants.COMPLETED) {
		return "completed";
	} else if (status === StatusConstants.INCOMPLETE) {
		return "incomplete";
	} else if (status === StatusConstants.NOT_ATTEMPTED) {
		return "not attempted";
	} else if (status === StatusConstants.PASSED) {
		return "passed";
	} else if (status === StatusConstants.FAILED) {
		return "failed";
	}
}

export function convertStatusToClassName(status) {
	if (status === StatusConstants.COMPLETED) {
		return "completed";
	} else if (status === StatusConstants.INCOMPLETE) {
		return "incomplete";
	} else if (status === StatusConstants.NOT_ATTEMPTED) {
		return "not-attempted";
	} else if (status === StatusConstants.PASSED) {
		return "passed";
	} else if (status === StatusConstants.FAILED) {
		return "failed";
	}
}

export function convertStatusToFriendlyName(status) {
	if (status === StatusConstants.COMPLETED) {
		return "Completed";
	} else if (status === StatusConstants.INCOMPLETE) {
		return "Incomplete";
	} else if (status === StatusConstants.NOT_ATTEMPTED) {
		return "Not attempted";
	} else if (status === StatusConstants.PASSED) {
		return "Passed";
	} else if (status === StatusConstants.FAILED) {
		return "Failed";
	}
}
