import React, { useEffect } from "react";
import classnames from "classnames";
import {
	PageItemModal,
	FFButton,
	Actions,
	PageItemPage,
	FFText,
} from "funkis-foundation";

import Style from "./PrintPage.module.css";

const { addClassNamesToBody, removeClassNamesFromBody, openURL, print } =
	Actions;

const PrintPage = ({ friendlyId, show, onCloseClick }) => {
	useEffect(() => {
		if (show) {
			addClassNamesToBody("print");
		} else {
			removeClassNamesFromBody("print");
		}
	}, [show]);

	return (
		<PageItemModal
			pageItem={{
				show,
				enabled: true,
				className: "",
				unmontChildrenOnHide: true,
			}}
		>
			<div
				className={classnames(
					Style.toolBar,
					"container-fluid d-flex justify-content-center no-print border-bottom position-fixed"
				)}
			>
				<div className="row justify-content-around p-1">
					<FFButton
						className="col-sm-2 btn btn-sm btn-dark rounded-pill"
						onClick={onCloseClick}
					>
						{/* same text ids as "print" and "back" text used in TopBarPrint  */}
						<FFText textId="499B10D5-CFF0-4665-A070-05A4BEDCEB3E" />
					</FFButton>
					<FFButton
						className="col-sm-2 btn btn-sm btn-dark rounded-pill"
						onClick={() => {
							print();
						}}
					>
						<FFText textId="FD4798BC-FC24-4D87-8AEC-2B33D2D7126A" />
					</FFButton>
				</div>
			</div>
			<PageItemPage pageItem={{ friendlyId }} />
		</PageItemModal>
	);
};

export default PrintPage;
