import classNames from "classnames";
import { FC } from "react";
import Style from "./Divider.module.css";

export type DividerProps = {
	weight: "regular" | "strong";
};

const Divider: FC<DividerProps> = ({ weight }) => (
	<hr className={classNames(Style.divider, Style[weight])}></hr>
);

export default Divider;
