
import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	removeItemInStateItems,
	resetItemsInStateItems,
	makeStateSnapshot,
	restoreStateSnapshot,
} from "../utils/reducerUtils";

import {
	UPDATE_QUID_INPUTS,
	UPDATE_QUID_INPUT,
	REMOVE_QUID_INPUT,
	REMOVE_ALL_QUID_INPUTS,
	MAKE_QUID_INPUTS_SNAPSHOT,
	RESTORE_FROM_QUID_INPUTS_SNAPSHOT,
} from "../constants/ActionConstants";

const quidInputs = (state = initialStateObjectCollection(), action) => {
	switch (action.type) {
		case UPDATE_QUID_INPUTS:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_QUID_INPUT:
			return updateItemInStateItems(state, action.item);
		case REMOVE_QUID_INPUT:
			return removeItemInStateItems(state, action.item);
		case REMOVE_ALL_QUID_INPUTS:
			return resetItemsInStateItems(initialStateObjectCollection());
		case MAKE_QUID_INPUTS_SNAPSHOT:
			return makeStateSnapshot(state, action.item);
		case RESTORE_FROM_QUID_INPUTS_SNAPSHOT:
			return restoreStateSnapshot(state, action.item);
		default:
			return state;
	}
};

export default quidInputs;
