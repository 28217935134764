import * as StoreUtils from "../../utils/StoreUtils";
import {
	UPDATE_TEXTS,
	UPDATE_TEXT,
	REMOVE_TEXT,
	REMOVE_TEXTS,
	ADD_TEXT,
	MAKE_TEXTS_SNAPSHOT,
	RESTORE_FROM_TEXTS_SNAPSHOT,
} from "../constants/ActionConstants";

export function updateTexts(updates) {
	StoreUtils.getStore().dispatch(_updateTexts(updates));
}

function _updateTexts(items) {
	return (dispatch: (action) => void) => {
		dispatch({ items, type: UPDATE_TEXTS });
	};
}

export function updateText(id, updates = {}) {
	StoreUtils.getStore().dispatch(_updateText(Object.assign(updates, { id })));
}

function _updateText(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_TEXT });
	};
}

export function addText(item) {
	StoreUtils.getStore().dispatch(_addText(Object.assign(item)));
}

export function makeTextsSnapshot(item) {
	StoreUtils.getStore().dispatch(_makeTextsSnapshot(item));
}

export function restoreFromTextsSnapshot(item) {
	StoreUtils.getStore().dispatch(_restoreFromTextsSnapshot(item));
}

function _addText(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: ADD_TEXT });
	};
}

export function removeText(id) {
	StoreUtils.getStore().dispatch(_removeText({ id }));
}

function _removeText(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_TEXT });
	};
}

export function removeTexts(ids) {
	StoreUtils.getStore().dispatch(_removeTexts({ ids }));
}

function _removeTexts(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_TEXTS });
	};
}

function _makeTextsSnapshot(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: MAKE_TEXTS_SNAPSHOT });
	};
}

function _restoreFromTextsSnapshot(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: RESTORE_FROM_TEXTS_SNAPSHOT });
	};
}
