import classnames from "classnames";
import {
	Actions,
	CorePageItems,
	ReduxCollectionSelectors,
} from "funkis-foundation";
import React, { useEffect } from "react";
import ImageVideoOrDiv from "../components/core/ImageVideoOrDiv";
import PromptBrowserSwitch from "../components/core/PromptBrowserSwitch";
import SimpleReveal from "../components/core/SimpleReveal";
import MainMenuChapterContainer from "../components/scroll/MainMenuChapterContainer";
import MainMenuPageButton from "../components/scroll/MainMenuPageButton";
import Page from "../components/scroll/Page";
import { connect } from "../utils/reduxUtils";

import PromptResumeOrRestart from "../components/core/PromptResumeOrRestart";
import Text from "../components/core/Text";
import RevealMainMenuButton from "../components/scroll/RevealMainMenuButton";
import { render } from "../RenderFunctions";
import { getBootstrapColor } from "../utils/colorUtils";
import Style from "./PageMenu.module.css";

const { makeGetItemIdsSelector } = ReduxCollectionSelectors;

const PageMenuScroll = (props) => {
	const { chapterIds, pageIds, app, menuPageIds } = props;
	const hideTopBar = app.top_bar_hide_in_main_menu === "yes";

	useEffect(() => {
		Actions.addClassNamesToBody("page-menu");
		return () => {
			Actions.removeClassNamesFromBody("page-menu");
		};
	}, []);

	// color only applies to the page content, not the full <body>. Body color is handled in MainMenuChapterContainer (and funkis-foundation)
	const pageContentBackgroundColor = getBootstrapColor(
		app.main_menu_background_color || "transparent"
	);
	return (
		<React.Fragment>
			<PromptBrowserSwitch />
			<PromptResumeOrRestart />
			<Page
				{...props}
				className={classnames("page-main-menu", !hideTopBar && Style.topMargin)}
				style={{ background: pageContentBackgroundColor }}
			>
				{/* MAIN MENU HEADER */}
				{app.main_menu_show_header === "yes" ||
				app.show_main_menu_header === "yes" ? (
					render(
						"mainMenuHeader",
						props,
						<div
							className={classnames(
								"main-menu-header-wrapper row m-0 px-4",
								app.main_menu_header_background_media && "mb-3"
							)}
							style={{
								color: getBootstrapColor(
									app.main_menu_header_text_color || "black"
								),
							}}
						>
							<ImageVideoOrDiv
								src={app.main_menu_header_background_media}
								className="mx-1 pt-5 pb-5 w-100 d-flex flex-column align-items-center"
							>
								<Text
									textId={app.title_txt_id}
									tagName="h1"
									className="main-menu-header font-weight-bold text-center"
								/>
								<Text
									textId={app.sub_title_1_txt_id}
									tagName="h2"
									className="main-menu-subheader font-weight-bold text-center"
								/>
							</ImageVideoOrDiv>
						</div>
					)
				) : (
					<div className="row m-0 mb-3" />
				)}
				{/* MENU PAGE ITEMS/BLOCKS */}
				{menuPageIds.map((pageId) => (
					<CorePageItems key={pageId} parentPageId={pageId} layer="mid" />
				))}
				{/* REVEAL MENU BUTTONS BUTTON */}
				<RevealMainMenuButton />
				{/* MENU BUTTONS */}
				<SimpleReveal
					reveal={app.main_menu_revealed !== "no" || app.mainMenuRevealedByUser}
					autoScroll={app.mainMenuRevealedByUser}
					pageItem={{ id: "main-menu" }}
				>
					{chapterIds
						? chapterIds.map((id, index) => (
								<MainMenuChapterContainer
									key={`MainMenuChapterContainer-${id}`}
									id={id}
									index={index}
								/>
						  ))
						: pageIds.map((id, index) => (
								<MainMenuPageButton
									key={`MainMenuPageButton-${id}`}
									id={id}
									index={index}
								/>
						  ))}
				</SimpleReveal>
			</Page>
		</React.Fragment>
	);
};

const makeMapStateToProps = () => {
	const getItemIdsSelector = makeGetItemIdsSelector();
	const mapStateToProps = (state, props) => {
		return {
			chapterIds: getItemIdsSelector(
				state,
				{
					match: (item) =>
						item.data && item.data.cmt && item.data.cmtPage.type === "chapter",
				},
				"pages"
			),
			pageIds: getItemIdsSelector(
				state,
				{
					match: (item) =>
						item.data && item.data.cmt && item.data.cmtPage.type === "page",
				},
				"pages"
			),

			menuPageIds: getItemIdsSelector(
				state,
				{
					match: (item) =>
						item.data &&
						item.data.cmt &&
						item.data.cmtPage.page_type === "main menu",
				},
				"pages"
			),
			app: state.app.item,
		};
	};
	return mapStateToProps;
};

export default connect(PageMenuScroll, makeMapStateToProps);
