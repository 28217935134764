import { getBasePath } from "utils/ConfigUtils";

export function getURLForConfigFile(url = "config.json", basePath) {
	return basePath + url;
}

export function getURLForTextFile(config, basePath) {
	const { projectPrefix, language, paths } = config;
	return `${getBasePath(basePath)}${
		paths.languagePath
	}${language}/${projectPrefix}_text_${language}.json`;
}

export function getURLForCMTTextFile(config, basePath) {
	const { language, paths } = config;
	return `${getBasePath(basePath)}${
		paths.languagePath
	}${language}/cmt_text_${language}.json`;
}

export function getURLForPagesFile(config, basePath) {
	return `${getBasePath(basePath) + config.paths.jsonPath}pages.json`;
}

export function getURLForPageItemsFile(config, basePath) {
	return `${getBasePath(basePath) + config.paths.jsonPath}page_items.json`;
}

export function getURLForSubtitleFile(config, basePath) {
	const { projectPrefix, language, paths } = config;
	return `${getBasePath(basePath)}${
		paths.languagePath
	}${language}/${projectPrefix}_subtitles_${language}.json`;
}

export function getURLForCMTSubtitleFile(config, basePath) {
	const { language, paths } = config;
	return `${getBasePath(basePath)}${
		paths.languagePath
	}${language}/cmt_subtitles_${language}.json`;
}

export function loadJSON(url) {
	return new Promise((resolve, reject) => {
		var xobj = new XMLHttpRequest();
		xobj.overrideMimeType("application/json");
		xobj.open("GET", url, true); // Replace 'my_data' with the path to your file
		xobj.onreadystatechange = function () {
			if (xobj.readyState == 4 && xobj.status == "200") {
				// Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
				try {
					return resolve(JSON.parse(xobj.responseText));
				} catch (error) {
					return reject(`Parsing ${url} failed!`);
				}
			}
			const firstStatusNumber = ("" + xobj.status)[0];
			if (firstStatusNumber == "4" || firstStatusNumber == "5") {
				// reject 400 or 500 statuses
				return reject(xobj.statusText);
			}
		};
		xobj.onerror = () => {
			console.log(xobj.statusText);
			return reject(xobj.statusText);
		};
		xobj.send(null);
	});
}

export function loadConfig(url = "config.json", basePath) {
	return new Promise((resolve, reject) => {
		return loadJSON(getURLForConfigFile(url, getBasePath(basePath)))
			.then((json) => {
				resolve(json);
			})
			.catch((reson) => {
				reject(reson);
			});
	});
}

export function loadText(config, basePath) {
	const url = getURLForTextFile(config, getBasePath(basePath));
	return new Promise((resolve, reject) => {
		return loadJSON(url)
			.then((json) => {
				resolve(json);
			})
			.catch((reson) => {
				reject(reson);
			});
	});
}

export function loadCMTText(config, basePath) {
	const url = getURLForCMTTextFile(config, getBasePath(basePath));
	return new Promise((resolve, reject) => {
		return loadJSON(url)
			.then((json) => {
				resolve(json);
			})
			.catch((reson) => {
				reject(reson);
			});
	});
}

export function loadPages(config, basePath) {
	return new Promise((resolve, reject) => {
		return loadJSON(getURLForPagesFile(config, getBasePath(basePath)))
			.then((json) => {
				resolve(json);
			})
			.catch((reson) => {
				reject(reson);
			});
	});
}

export function loadPageItems(config, basePath) {
	return new Promise((resolve, reject) => {
		return loadJSON(getURLForPageItemsFile(config, getBasePath(basePath)))
			.then((json) => {
				resolve(json);
			})
			.catch((reson) => {
				reject(reson);
			});
	});
}

export function loadSubtitles(config, basePath) {
	const url = getURLForSubtitleFile(config, getBasePath(basePath));
	return new Promise((resolve, reject) => {
		return loadJSON(url)
			.then((json) => {
				resolve(json);
			})
			.catch((reson) => {
				reject(reson);
			});
	});
}

export function loadCMTSubtitles(config, basePath) {
	const url = getURLForCMTSubtitleFile(config, getBasePath(basePath));
	return new Promise((resolve, reject) => {
		return loadJSON(url)
			.then((json) => {
				resolve(json);
			})
			.catch((reson) => {
				reject(reson);
			});
	});
}
