import React, { useState } from "react";
import classnames from "classnames";
import Article from "./Article";
import SimpleReveal from "../core/SimpleReveal";
import { FFButton, TextUtils } from "funkis-foundation";
import IconArrow from "../../assets/svg/IconArrow";
import { useSelector } from "react-redux";
import Styles from "./ReadMore.module.css";
import HorizontalLine from "../../components/scroll/HorizontalLine";
import { getText } from "../../utils/textUtils";
import { getBootstrapColor } from "../../utils/colorUtils";

const PageItemReadMore = (props) => {
	const { pageItem, viewVisible, className, onOpenToggle } = props;
	const { cmtPageItem } = pageItem;
	const dividerColor = useSelector(
		(state) =>
			cmtPageItem.divider_color ||
			state.app.item.color_block_divider ||
			state.app.item.block_divider
	);
	const dividerTextColor = getBootstrapColor(
		cmtPageItem.divider_text_color || dividerColor
	);
	const [open, setOpen] = useState(false);

	const onOpenClickHandler = () => {
		onOpenToggle && onOpenToggle(!open);
		setOpen(!open);
	};

	const readMoreTexts =
		props.readMoreTexts ||
		(cmtPageItem.video_src !== "" &&
			TextUtils.getTextForVideoSource(cmtPageItem.video_src, true));

	return (
		<div className={className}>
			<div className="container-fluid">
				<div className="row m-0">
					<div className="col-sm-12">
						<HorizontalLine
							className="mb-1"
							size={4}
							color={getBootstrapColor(dividerColor)}
						/>
					</div>
				</div>
			</div>
			<div className="container-fluid">
				<div className="row m-0 ">
					<FFButton
						onClick={onOpenClickHandler}
						className="col-sm-12 d-flex align-items-center"
					>
						{({ hover }) => (
							<React.Fragment>
								<IconArrow
									style={{ width: "16px" }}
									color={
										hover ? getBootstrapColor("interaction") : dividerTextColor
									}
									className={classnames(open ? Styles.open : Styles.closed)}
								/>

								<span
									className={classnames(
										"d-block ml-1 instruction-sm font-italic",
										hover && "text-underline"
									)}
									style={{
										marginTop: "0.125rem",
										color: hover
											? getBootstrapColor("interaction")
											: dividerTextColor,
									}}
									dangerouslySetInnerHTML={{
										__html: open
											? getText(cmtPageItem.label_open) ||
											  getText("DA52AF78-369B-4F1E-9581-EA2478882AA3")
											: getText(cmtPageItem.label_closed) ||
											  getText("DA52AF78-369B-4F1E-9581-EA2478882AA3"),
									}}
								></span>
							</React.Fragment>
						)}
					</FFButton>
				</div>
			</div>
			<div className="container-fluid">
				<div className="row m-0">
					<div className="col-sm-12 p-md-0">
						<SimpleReveal reveal={open} className={Styles.mx_n3}>
							<div className="mb-3" />
							<Article
								{...props}
								viewVisible={true}
								bodyColumn1={
									(readMoreTexts &&
										Array.isArray(readMoreTexts) &&
										readMoreTexts
											.slice(0, Math.floor(readMoreTexts.length * 0.5))
											.join(" ")) ||
									readMoreTexts
								}
								bodyColumn2={
									readMoreTexts &&
									Array.isArray(readMoreTexts) &&
									readMoreTexts
										.slice(
											Math.floor(
												readMoreTexts.length * 0.5,
												readMoreTexts.length
											)
										)
										.join(" ")
								}
							></Article>
						</SimpleReveal>
					</div>
				</div>
			</div>
			<div className="container-fluid">
				<div className="row m-0">
					<div className="col-sm-12">
						<HorizontalLine
							className="mt-1"
							size={4}
							color={getBootstrapColor(dividerColor)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageItemReadMore;
