import React, { useEffect, useRef } from "react";
import tinycolor from "tinycolor2";
import _ from "lodash";
import styled from "styled-components";
import { gsap } from "gsap";
import {getLocalizedMediaPath} from "utils/MediaUtils";
import { getText } from "../../utils/textUtils";
import { getBootstrapColor } from "../../utils/colorUtils";
import PropTypes from "prop-types";

const IkeaEyeIcon = (props) => (
	<svg
		width="100%"
		height="100%"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...props}
	>
		<defs>
			<path id="a" d="M0 0h20.586v13.675H0z" />
		</defs>
		<g transform="rotate(-2 30.565 -7.145)" fill="none" fillRule="evenodd">
			<mask id="b" fill="#fff">
				<use xlinkHref="#a" />
			</mask>
			<path
				d="M10.293 4.911a1.93 1.93 0 0 0-1.927 1.927 1.93 1.93 0 0 0 1.927 1.927 1.93 1.93 0 0 0 1.927-1.927 1.93 1.93 0 0 0-1.927-1.927m0 5.854a3.932 3.932 0 0 1-3.927-3.927 3.932 3.932 0 0 1 3.927-3.927 3.932 3.932 0 0 1 3.927 3.927 3.932 3.932 0 0 1-3.927 3.927M2.3 6.838c1.097 1.69 3.971 4.837 7.993 4.837s6.896-3.147 7.993-4.837C17.188 5.147 14.315 2 10.293 2S3.398 5.147 2.3 6.838m7.993 6.837C3.762 13.675.291 7.376.256 7.312L0 6.838l.256-.475C.291 6.3 3.763 0 10.293 0c6.53 0 10.002 6.3 10.037 6.363l.256.475-.256.474c-.035.064-3.506 6.363-10.037 6.363"
				fill="#FFF"
				mask="url(#b)"
			/>
		</g>
	</svg>
);

const Root = styled.div`
	position: relative;
	height: auto;
	transform: scale(1);
	@media (hover: hover) {
		:hover {
			transform: scale(${({ disabled }) => (disabled ? 1 : 1.05)});
			button {
				box-shadow: rgb(0 0 0 / 20%) 5px 5px 6px 0px;
			}
		}
	}
	transition: ${({ disabled }) => (disabled ? "0s" : "0.2s")};
`;

const StyledButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem ${({ buttonIcon }) => (buttonIcon ? "3rem" : "0.75rem")} !important;
	border: ${({ selected, hasSelectionBorder }) =>
		selected && hasSelectionBorder ? "3px solid black" : "0"};
	box-shadow: rgb(0 0 0 / 20%) 2px 2px 1px;
	background-color: ${({
		color,
		selectedColor,
		selected,
		hasSelectionBorder,
	}) =>
		getBootstrapColor(selected && !hasSelectionBorder ? selectedColor : color)};
	color: ${({ textColor, selected, selectedLabelColor }) =>
		selected && selectedLabelColor ? selectedLabelColor : textColor};
	width: 100%;
	height: 100%;
	min-height: 5rem;

	span {
		font-weight: bold;
		font-size: 1.125rem; // 18 px in desktop
		line-height: 1.11;
		word-break: break-word;
		letter-spacing: 0;
	}
	@media (hover: hover) {
		:hover:enabled {
			background-color: ${({
				selected,
				selectedColor,
				color,
				hasSelectionBorder,
			}) =>
				tinycolor(
					(selected && !hasSelectionBorder && selectedColor) || color
				).lighten(5)};
		}
	}
	background-image: ${({ selectedBackgroundImage, selected }) =>
		selected && selectedBackgroundImage
			? `url(${getLocalizedMediaPath(selectedBackgroundImage)})`
			: "none"};
`;

const ButtonIcon = styled(({ className }) => (
	<div className={className}>
		<IkeaEyeIcon />
	</div>
))`
	position: absolute;
	top: 0.5rem;
	right: 1rem;
	z-index: 1;
	width: 22px;
	height: 15px;
`;

const TraitOption = ({
	labelTextId,
	labelText,
	index,
	selected,
	delayShow = true,
	color,
	buttonIcon = false,
	textColor,
	maxRotation,
	maxTranslation,
	...rest
}) => {
	const ref = useRef(null);

	useEffect(() => {
		gsap.set(ref.current, {
			opacity: 0,
			y: -50,
			x: _.random(-maxTranslation, maxTranslation),
		});
		gsap.to(ref.current, {
			duration: 0.5,
			delay: delayShow ? 0.5 + index * 0.1 : 0,
			rotation: _.random(-maxRotation, maxRotation),
			y: 0,
			opacity: (selected && 1) || 0.3,
			onComplete: () => {
				gsap.set(ref.current, {
					clearProps: "opacity",
				});
			},
		});
	}, []);

	useEffect(() => {
		if (selected) {
			gsap.to(ref.current, {
				duration: 0.2,
				scale: 1.2,
				yoyo: true,
				repeat: 1,
				rotation: _.random(-maxRotation, maxRotation),
			});
		} else {
			gsap.to(ref.current, {
				duration: 0.1,
				scale: 0.95,
				yoyo: true,
				repeat: 1,
				rotation: _.random(-maxRotation, maxRotation),
			});
		}
	}, [selected]);

	return (
		<Root disabled={rest.disabled}>
			{buttonIcon && !selected && <ButtonIcon />}
			<StyledButton
				ref={ref}
				color={color}
				textColor={textColor}
				selected={selected}
				buttonIcon={buttonIcon}
				{...rest}
			>
				<span className="">{labelText || getText(labelTextId)}</span>
			</StyledButton>
		</Root>
	);
};

TraitOption.propTypes = {
	labelTextId: PropTypes.string,
	labelText: PropTypes.string,
	index: PropTypes.number,
	selected: PropTypes.bool,
	delayShow: PropTypes.bool,
	color: PropTypes.string,
	selectedColor: PropTypes.string,
	mouseOverColor: PropTypes.string,
	textColor: PropTypes.string,
	maxRotation: PropTypes.number,
	maxTranslation: PropTypes.number,
};

TraitOption.defaultProps = {
	selected: false,
	delayShow: false,
	color: "black",
	selectedColor: "gray",
	textColor: "white",
	maxRotation: 1.5,
	maxTranslation: 3,
};

export default TraitOption;
