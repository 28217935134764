import React from "react";

import { makeGetValueForCurrentPage } from "../../../../selectors/playerSelectors";
import { connect } from "../../../utils/reduxUtils";

import TopBarDefault from "../../../components/core/TopBarDefault_v2";
import TopBarPrint from "../../../components/core/TopBarPrint";

const PageItemTopBar = (props) => {
	const { cmtPageType } = props;

	if (cmtPageType == "print") {
		return <TopBarPrint />;
	} else {
		return <TopBarDefault />;
	}
};

const makeMapStateToProps = () => {
	const getValueForCurrentPage = makeGetValueForCurrentPage();

	const mapStateToProps = (state, props) => {
		return {
			cmtPageType: getValueForCurrentPage(
				state,
				{ ...props, path: "data.cmtPage.page_type" },
				"app"
			),
		};
	};
	return mapStateToProps;
};

export default connect(PageItemTopBar, makeMapStateToProps);
