import Stack from "components/Stack/Stack";
import DeprecatedPageItemError from "errors/DeprecatedPageItemError";
import MissingIOError from "errors/MissingIOError";
import MissingListError from "errors/MissingListError";
import React, { ReactNode } from "react";

type ErrorBoundaryProps = {
	pageItemId: string;
};

type ErrorBoundaryState = {
	error: Error | undefined;
};

function composeFATLink(pageItemId: string): string {
	return `https://biz.funkismultimedia.se/utils/open-fmp-link.html#fmp://biz.funkismultimedia.se/Funkis%20Authoring%20Tool?script=linkTriggeredOpenPageItem&param=${pageItemId}`;
}

function composeErrorMessage(
	error: Error,
	props: ErrorBoundaryProps
): ReactNode {
	if (
		error instanceof DeprecatedPageItemError ||
		error instanceof MissingIOError ||
		error instanceof MissingListError
	) {
		return (
			<Stack>
				<h1>{error.title}</h1>
				<p>{error.message}</p>
				{props.pageItemId && (
					<a
						href={composeFATLink(props.pageItemId)}
						target="_blank"
						rel="noreferrer"
					>
						<u>Edit Page Item in FAT</u>
					</a>
				)}
			</Stack>
		);
	} else {
		return (
			<Stack>
				<h1>Error.</h1>
				<p>{error.message}</p>
			</Stack>
		);
	}
}

class ErrorBoundary extends React.Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	state: ErrorBoundaryState = { error: undefined };

	static getDerivedStateFromError(error): ErrorBoundaryState {
		return { error };
	}

	componentDidCatch(error, errorInfo) {
		console.error(error, errorInfo);
	}

	render() {
		if (this.state.error) {
			return (
				<div className="container-fluid">
					{composeErrorMessage(this.state.error, this.props)}
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
