import { useEffect, useRef } from "react";

function useBodyClassName(className?: string) {
	const prevSizeClassNameRef = useRef<string>();

	useEffect(() => {
		const prevSizeClassNames = prevSizeClassNameRef.current
			? prevSizeClassNameRef.current.split(" ")
			: [];
		document.body.classList.remove(...prevSizeClassNames);

		const nextSizeClassNames = className ? className.split(" ") : [];
		document.body.classList.add(...nextSizeClassNames);

		prevSizeClassNameRef.current = className;
	}, [className]);
}

export default useBodyClassName;
