import classnames from "classnames";
import { Subtitle, useSubtitle } from "funkis-template/hooks/subtitles";
import { PageItemMedia } from "funkis-template/models/player";
import React, { useEffect, useRef, useState } from "react";
import Style from "./VideoPlayerSubtitles.module.css";
import gsap from "gsap";
import { VideoState } from "./VideoPlayer";

type VideoPlayerSubtitlesProps = {
	src: PageItemMedia;
	currentTime: number;
	variant: "overlay" | "block";
	videoState: VideoState;
};

const VideoPlayerSubtitles: React.FC<VideoPlayerSubtitlesProps> = ({
	currentTime,
	src,
	variant,
	videoState,
}) => {
	const containerRef = React.useRef<HTMLDivElement>(null);
	const prevSubtitleElementRef = React.useRef<HTMLParagraphElement>(null);
	const prevSubtitle = useSubtitle(src, currentTime - 0.5);
	const subtitle = useSubtitle(src, currentTime);

	useEffect(() => {
		if (subtitle?.text !== prevSubtitle?.text) {
			gsap.fromTo(
				prevSubtitleElementRef.current,
				{ autoAlpha: 1, position: "absolute" },
				{
					autoAlpha: 0,
					duration: 0.25,
				}
			);
		}
	}, [subtitle?.text, prevSubtitle?.text]);

	useEffect(() => {
		if (variant !== "block") return;
		if (!containerRef.current) return;

		if (videoState === "ended") {
			gsap.to(containerRef.current, {
				height: 0,
				minHeight: 0,
				padding: 0,
				duration: 0.3,
				delay: 0.5,
			});
		} else if (videoState === "idle") {
			gsap.set(containerRef.current, {
				minHeight: 0,
				height: 0,
				padding: 0,
			});
		} else {
			gsap.to(containerRef.current, {
				duration: 0.5,
				height: "auto",
				minHeight: "var(--video-player-subtitles-min-height)",
				clearProps: "padding",
			});
		}
	}, [videoState, variant]);

	return (
		<div
			ref={containerRef}
			className={classnames(Style.subtitles, Style[variant], {
				[Style.hidden]: subtitle === undefined,
				[Style.collapsed]: videoState === "ended" || videoState === "idle",
			})}
		>
			<div style={{ position: "relative" }}>
				<div ref={prevSubtitleElementRef}>
					{prevSubtitle && (
						<p
							className={classnames(Style.subtitle)}
							dangerouslySetInnerHTML={{ __html: prevSubtitle.text }}
						/>
					)}
				</div>
			</div>

			{subtitle && (
				<p
					className={classnames(Style.subtitle)}
					dangerouslySetInnerHTML={{ __html: subtitle.text }}
				/>
			)}
		</div>
	);
};

export default VideoPlayerSubtitles;
