import * as StoreUtils from "../../utils/StoreUtils";
import {
	UPDATE_CONFIG,
	MAKE_CONFIG_SNAPSHOT,
	RESTORE_FROM_CONFIG_SNAPSHOT,
} from "../constants/ActionConstants";

export function updateConfig(item) {
	StoreUtils.getStore().dispatch(_updateConfig(item));
}

export function updateConfigValue(item) {
	StoreUtils.getStore().dispatch(_updateConfig(item));
}

export function makeConfigSnapshot(item) {
	StoreUtils.getStore().dispatch(_makeConfigSnapshot(item));
}

export function restoreFromConfigSnapshot(item) {
	StoreUtils.getStore().dispatch(_restoreFromConfigSnapshot(item));
}

function _updateConfig(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_CONFIG });
	};
}

function _makeConfigSnapshot(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: MAKE_CONFIG_SNAPSHOT });
	};
}

function _restoreFromConfigSnapshot(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: RESTORE_FROM_CONFIG_SNAPSHOT });
	};
}
