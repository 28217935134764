import _ from "lodash";
import { PageActions, StoreUtils, PagesUtils } from "funkis-foundation";
const { getPageByPath } = PagesUtils;

export const handleStatusAndUnlockingNextPage = ({ cmtPage, programType }) => {
	// SET CURRENT PAGE STATUS TO COMPLETED
	const parentPage = getPageByPath([{ parent: 1 }], cmtPage.id);
	PageActions.updatePage(cmtPage.id, { status: "completed" });
	// SET PARENT PAGE STATUS TO COMPLETED
	if (parentPage) {
		PageActions.updatePage(parentPage.id, { status: "completed" });
	}
	// UNLOCK NEXT PAGE (only scroll)
	const getNextPageInChapter = (relativeToPageId) =>
		getPageByPath([{ sibling: 1 }], relativeToPageId);
	const getNextPageInNextChapter = (relativeToPageId) =>
		getPageByPath(
			[{ parent: 1 }, { sibling: 1 }, { child: 0 }],
			relativeToPageId
		);
	const nextPageInChapter = getNextPageInChapter(cmtPage.id);
	const nextPageInNextChapter =
		!nextPageInChapter && getNextPageInNextChapter(cmtPage.id);

	const nextPageToUnlock = nextPageInChapter || nextPageInNextChapter;
	if (
		programType === "scroll" &&
		nextPageToUnlock &&
		nextPageToUnlock.status === "not_attempted"
	) {
		PageActions.updatePage(nextPageToUnlock.id, { status: "unlocked" });
		console.log("Unlocking next page ", nextPageToUnlock);
	}

	// UNLOCK NEXT CHAPTER
	const pages = StoreUtils.getReducer("pages").items;
	const chapterPages = pages.filter(
		(page) => _.get(page, "data.cmtPage.type") === "chapter"
	);
	let nextChapter = getPageByPath([{ parent: 1 }, { sibling: 1 }], cmtPage.id);
	// CHEKC TO SE IF THERE IS UNLOCKED PAGES THAT HAS TO BE DONE BEFORE NEXT IS SET TO "INCOMPLETE"...
	const nextChapterIndex = chapterPages.findIndex((page) => {
		return nextChapter && page.id === nextChapter.id;
	});

	const previousUnLockedChapters = chapterPages
		.slice(0, nextChapterIndex - 1)
		.filter((page) => _.get(page, "data.cmtPage.locked") === "no");

	if (previousUnLockedChapters.length) {
		if (previousUnLockedChapters.every((page) => page.status === "completed")) {
			if (nextChapter && nextChapter.status === "not_attempted") {
				PageActions.updatePage(nextChapter.id, { status: "unlocked" });
			}
		}
	} else {
		// ...ELSE SET NEXT CHAPTER TO "UNLOCKED"
		if (nextChapter && nextChapter.status === "not_attempted") {
			PageActions.updatePage(nextChapter.id, { status: "unlocked" });
		}
	}
	return { nextPageInChapter, nextChapter };
};
