import React from "react";

const IconWatch = (props) => {
	const { color = "white" } = props;

	switch (color) {
		case "white":
			return <IconWhite {...props} />;
		case "gray":
		case "grey":
			return <IconGray {...props} />;
	}
};

const IconWhite = ({ className, style }) => {
	return (
		<svg
			width="16px"
			height="16px"
			className={className}
			style={style}
			viewBox="0 0 16 16"
			version="1.1"
		>
			<defs>
				<polygon
					id="path-1"
					points="0 6.66666667e-05 15.9998667 6.66666667e-05 15.9998667 16 0 16"
				></polygon>
				<polygon
					id="path-3"
					points="0 6.66666667e-05 15.9998667 6.66666667e-05 15.9998667 16 0 16"
				></polygon>
			</defs>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="menu-/-time">
					<g id="Group-7">
						<g id="Group-3">
							<g id="Clip-2"></g>
							<path
								d="M7.99986667,6.66666667e-05 C3.58186667,6.66666667e-05 -0.000133333333,3.5814 -0.000133333333,8.00006667 C-0.000133333333,12.4180667 3.58186667,16.0000667 7.99986667,16.0000667 C12.4178667,16.0000667 15.9998667,12.4180667 15.9998667,8.00006667 C15.9998667,3.5814 12.4178667,6.66666667e-05 7.99986667,6.66666667e-05"
								id="Fill-1"
								fill="#FAFAFA"
								mask="url(#mask-2)"
							></path>
						</g>
						<g id="Group-6">
							<g id="Clip-5"></g>
							<path
								d="M-0.000133333333,8.00006667 C-0.000133333333,12.4180667 3.58186667,16.0000667 7.99986667,16.0000667 C12.4178667,16.0000667 15.9998667,12.4180667 15.9998667,8.00006667 C15.9998667,3.5814 12.4178667,6.66666667e-05 7.99986667,6.66666667e-05 C3.58186667,6.66666667e-05 -0.000133333333,3.5814 -0.000133333333,8.00006667 Z M7.99986667,2.0534 C9.58853333,2.0534 11.0818667,2.67206667 12.2052,3.79473333 C13.3278667,4.91806667 13.9465333,6.4114 13.9465333,8.00006667 C13.9465333,9.58873333 13.3278667,11.0820667 12.2052,12.2054 C11.0818667,13.3280667 9.58853333,13.9467333 7.99986667,13.9467333 C6.41186667,13.9467333 4.91786667,13.3280667 3.7952,12.2054 C2.67186667,11.0820667 2.0532,9.58873333 2.0532,8.00006667 C2.0532,6.4114 2.67186667,4.91806667 3.7952,3.79473333 C4.91786667,2.67206667 6.41186667,2.0534 7.99986667,2.0534"
								id="Fill-4"
								fill="#000000"
								mask="url(#mask-4)"
							></path>
						</g>
					</g>
					<path
						d="M7,10 L7,4 L8.5,4 L8.5,8.285 L13,8.28571429 L13,10 L7,10 Z"
						id="Combined-Shape"
						fill="#000000"
					></path>
				</g>
			</g>
		</svg>
	);
};

const IconGray = ({ className, style }) => {
	return (
		<svg
			width="26px"
			height="26px"
			className={className}
			style={style}
			viewBox="0 0 26 26"
			version="1.1"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<rect
					id="Rectangle"
					stroke="#FF0D0D"
					strokeWidth="10"
					x="-1918"
					y="-2137"
					width="2681"
					height="6727"
				></rect>
				<g
					id="slide-menu/page-item-not-started"
					transform="translate(0.000000, -220.000000)"
				>
					<g id="icon_time" transform="translate(0.000000, 220.000000)">
						<circle
							id="Oval-Copy-17"
							fill="#616164"
							cx="13"
							cy="13"
							r="13"
						></circle>
						<polyline
							id="Rectangle"
							stroke="#AAAAAF"
							strokeWidth="2"
							points="13 6 13 13 20 13"
						></polyline>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default IconWatch;
