import classnames from "classnames";
import { FFMultiSelect, FFText, StoreUtils } from "funkis-foundation";
import _ from "lodash";
import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import ImageVideoOrDiv from "../components/core/ImageVideoOrDiv";
import Logo from "../components/slide/Logo";
import MemberListItem from "../components/slide/MemberListItem";
import PageNavigation from "../components/slide/PageNavigation";
import { getAppQuidInputs } from "../requests/AppQuidInputRequests";
import { updateMemberPresence } from "../requests/MemberRequests";
import Styles from "./PageWorkshopSetup.module.css";
import bgQuiddity from "assets/images/bg_quiddity.jpg";

const GLOBAL_TEXT_IDS_BY_FUNCTION = {
	SUBHEADER: "15749145-1CC1-4CBB-8E97-32C3B66EEC89",
	TABLE_COL_NAME: "CC780B66-5925-4D3F-BEC8-EBBDAB3870FD",
	TABLE_COL_EMAIL: "71FB5694-6D28-447A-85DF-68DF58E55A28",
	TABLE_COL_PROGRESS: "1A5AB0F1-06B8-43FD-BB58-C40461AEBCAB",
};

const PageWorkshopSelectGroupMembers = () => {
	const backend = useSelector((state) => _.get(state, "app.item.backend"));
	const preparatoryAppId = useSelector(
		(state) =>
			_.get(state, "app.item.preparatory_package_id") ||
			_.get(state, "app.item.preparatory_app_id")
	);
	const titleTextId = useSelector((state) =>
		_.get(state, "app.item.title_txt_id")
	);
	const appId = useSelector((state) => _.get(state, "app.item.id"));
	const currentMember = useSelector((state) =>
		_.get(state, "quiddity.item.member.id")
	);

	const appQuid = useSelector((state) =>
		_.get(state, "quids.items").find(
			(quid) => quid.type === "app" && quid.template
		)
	);
	const groupId = useSelector((state) =>
		_.get(state, "quiddity.item.group.id")
	);
	// Get member ids...
	const memberIds = useSelector((state) => {
		const _members = _.get(state, "members.items").filter(
			(member) => member.group === groupId
		);
		return _members
			.map((member) => ({ ...member, status: "present" }))
			.map((member) => member.id);
	}, shallowEqual);
	// Get members presence ans statuses...
	const membersData = useSelector((state) => {
		const statuses = memberIds.map((id) => {
			// Get the quidInput (app) for member.
			const appQuidInput = _.get(state, "quidInputs.items").find(
				(quidInput) =>
					quidInput.targetDataKey === appId &&
					quidInput.sourceQuid === appQuid.id &&
					quidInput.member === id
			);
			// Get the quidInput from the "preparatoryApp" quidInput fot the member...
			const preparatoryAppQuidInput = _.get(state, "quidInputs.items").find(
				(quidInput) =>
					quidInput.targetDataKey === preparatoryAppId &&
					quidInput.sourceQuid === appQuid.id &&
					quidInput.member === id
			);

			return {
				memberId: id,
				present: _.get(appQuidInput, "data.presenceStatus"),
				completionStatus: _.get(
					preparatoryAppQuidInput,
					"data.completionStatus",
					"not_attempted"
				),
			};
		});
		return statuses;
	});

	// Get app quidInputs (status) for members...
	useEffect(() => {
		if (memberIds) {
			getAppQuidInputs({ memberIds });
			// get preparatory app statuses...
			if (preparatoryAppId) {
				getAppQuidInputs({
					memberIds,
					query: { targetDataKey: preparatoryAppId },
				}).then((res) => {
					console.log("preparatoryAppId", res);
				});
			}
		}
	}, [memberIds, preparatoryAppId]);

	// Update the "presenceStatus" for the current member...
	useEffect(() => {
		if (memberIds) {
			updateMemberPresence({
				memberId: currentMember,
				presenceStatus: "presence",
			});
		}
	}, [memberIds, currentMember]);

	const selectedIndexes = memberIds.reduce((acc, id, index) => {
		return membersData[index].present === "present" ? [...acc, index] : acc;
	}, []);

	const onSelectMember = (_, index) => {
		updateMemberPresence({ memberId: memberIds[index] });
	};

	let members = memberIds.map(
		(id) => StoreUtils.getReducer("members").itemsById[id]
	);
	// Sort by name...
	members = members.sort((memberA, memberB) => {
		if (_.get(memberA, "user.firstName") > _.get(memberB, "user.firstName")) {
			return 1;
		} else {
			return -1;
		}
	});

	// Sort by current...
	members = members.sort((memberA) => {
		if (memberA.id === currentMember) {
			return -1;
		} else {
			return 1;
		}
	});

	const sortedMemberIds = members.map((member) => member.id);
	const disabledIndexes = [
		sortedMemberIds.findIndex((id) => id === currentMember),
	];

	return (
		<ImageVideoOrDiv
			className="absolute-top w-100 h-100 d-flex justify-content-center flex-wrap bg-black"
			src={backend && bgQuiddity}
		>
			<div className="w-100 position-relative flex-grow-0">
				<Logo className="position-absolute" />
			</div>
			<div
				className="absolute-top text-white"
				style={{ width: "1280px", hight: "720px", top: "286px" }}
			>
				<div className={classnames("row m-0 mb-5")}>
					<FFText
						tagName="h1"
						className="message-lg d-block col-sm-12 font-weight-bold mb-1"
						textId={titleTextId}
					/>
					<FFText
						tagName="h2"
						className="col-sm-12 font-weight-bold"
						textId={GLOBAL_TEXT_IDS_BY_FUNCTION.SUBHEADER}
					/>
				</div>

				<div className="">
					<div className="row m-0 border-bottom border-bottom-1 border-gray">
						<div className="col-sm-1" />
						<FFText
							tagName="h4"
							className="font-weight-bold col-sm-2"
							textId={GLOBAL_TEXT_IDS_BY_FUNCTION.TABLE_COL_NAME}
						/>
						<FFText
							tagName="h4"
							className="font-weight-bold col-sm-7"
							textId={GLOBAL_TEXT_IDS_BY_FUNCTION.TABLE_COL_EMAIL}
						/>
						<div className="col-sm-2">
							<FFText
								tagName="h4"
								className="font-weight-bold"
								textId={GLOBAL_TEXT_IDS_BY_FUNCTION.TABLE_COL_PROGRESS}
							/>
						</div>
					</div>
				</div>
				<FFMultiSelect
					className={classnames(
						Styles.groupUnitListItems,
						memberIds.length > 6 && Styles.groupUnitListItemsMask
					)}
					buttonClassName={
						"border-bottom border-bottom-1 border-gray align-items-center"
					}
					toggle={true}
					maxSelections={memberIds.length}
					minSelections={0}
					selectedIndexes={selectedIndexes}
					disableWhenMaxSelectionsReached={false}
					autoDeselectWhenMaxSelectionsReached={false}
					onSelect={onSelectMember}
					alwaysTriggerOnSelect
					disabledIndexes={disabledIndexes}
				>
					{sortedMemberIds.map((id, index) => {
						const status = _.get(
							membersData.find((item) => item.memberId === id),
							"completionStatus"
						);
						return (
							<MemberListItem
								key={id}
								id={id}
								active={id === currentMember}
								selected={selectedIndexes.includes(index)}
								status={status === "completed" ? status : "incomplete"}
							/>
						);
					})}
				</FFMultiSelect>
			</div>
			<PageNavigation show={selectedIndexes.length > 0} color="white" />
		</ImageVideoOrDiv>
	);
};

export default PageWorkshopSelectGroupMembers;
