import gsap from "gsap";
import { FC, useEffect, useRef, useState } from "react";
import Style from "./Collapsible.module.css";

type CollapsibleProps = {
	open: boolean;
	variant?: "collapse" | "fade-out-collapse";
};

const Collapsible: FC<CollapsibleProps> = ({
	children,
	open,
	variant = "collapse",
}) => {
	const elementRef = useRef<HTMLDivElement>(null);
	const timeline = useRef<gsap.core.Timeline>();
	const [initiallyOpen] = useState(open);
	const [isStillOpen, setIsStillOpen] = useState(open);

	useEffect(() => {
		let ctx = gsap.context(() => {
			timeline.current = gsap
				.timeline({
					paused: true,
					onReverseComplete: () => setIsStillOpen(false),
					onStart: () => setIsStillOpen(true),
				})
				.fromTo(
					elementRef.current,
					{
						height: 0,
					},
					{ height: "auto", duration: 0.3, ease: "power4.inOut" }
				);

			if (variant === "fade-out-collapse") {
				timeline.current.add(
					gsap.fromTo(
						elementRef.current,
						{
							opacity: 0,
						},
						{ opacity: 1, duration: 0.3, delay: 0.2, ease: "power4.inOut" }
					)
				);
			}

			timeline.current.progress(initiallyOpen ? 1 : 0);
		}, elementRef);

		return () => ctx.revert();
	}, [initiallyOpen, variant]);

	useEffect(() => {
		open ? timeline.current?.play() : timeline.current?.reverse();
	}, [open]);

	const shouldShowChildren = open || isStillOpen;

	return (
		<div className={Style.collapsible} ref={elementRef} data-open={open}>
			{shouldShowChildren ? children : undefined}
		</div>
	);
};

export default Collapsible;
