import { StoreUtils } from "funkis-foundation";
import _ from "lodash";
import {
	createQuidInput,
	getQuidInputs,
	getQuids,
	QuiddityBackend,
	QuidInputUtils,
	upsertQuidInput,
} from "quiddity";
import { useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
	Backend,
	BackendError,
	IOContext,
	Quid,
	QuidInput,
	QuidInputParams,
	QuidInputUpdateParams,
	QuidType,
} from "../backend";

type QuiddityBackendProps = {
	targetDataKey: string;
	inputContext: IOContext;
	quidType: QuidType;
	url: string;
	api: string;
	shouldIncludeArchivedQuidInputs: boolean;
};

export const useBackend = ({
	targetDataKey,
	inputContext,
	quidType,
	url = "",
	api = "/api",
	shouldIncludeArchivedQuidInputs = false,
}: QuiddityBackendProps): Backend => {
	const quiddity = useSelector(
		(state: any) => state.quiddity.item,
		shallowEqual
	);

	const {
		isLoading: isQuidTemplateLoading,
		error: quidTemplateError,
		quidTemplate,
	} = useQuidTemplate(url, api, quidType);

	const {
		isLoading: isQuidInputsLoading,
		isSuccess: isQuidInputsSuccess,
		error: quidInputsError,
		quidInputs,
	} = useQuidInputs(
		url,
		api,
		targetDataKey,
		inputContext,
		shouldIncludeArchivedQuidInputs,
		quiddity,
		quidTemplate
	);

	const createQuidInputFunc = useMemo(
		() =>
			makeCreateQuidInputFunc({
				url,
				api,
				inputContext,
				quidTemplate,
				quiddity,
				targetDataKey,
			}),
		[url, api, inputContext, quidTemplate, quiddity, targetDataKey]
	);

	const updateQuidInputFunc = useMemo(
		() => makeUpdateQuidInput(url, api),
		[url, api]
	);

	return {
		isLoading: isQuidTemplateLoading || isQuidInputsLoading,
		isSuccess: isQuidInputsSuccess,
		error: quidTemplateError || quidInputsError,
		quidInputs,
		createQuidInput: createQuidInputFunc,
		updateQuidInput: updateQuidInputFunc,
		deleteQuidInput: removeQuidInput,
	};
};

const useQuidTemplate = (
	url: string,
	api: string,
	quidType: QuidType,
	throttle = false,
	throttleWait = 0
) => {
	const [quidTemplate, setQuidTemplate] = useState<Quid | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<BackendError | undefined>(undefined);

	useEffect(() => {
		async function fetchQuidTemplate() {
			setIsLoading(true);
			const quidTemplates = await getQuids(url + api, {
				type: quidType,
				template: true,
				throttle,
				throttleWait,
			});

			if (quidTemplates.length > 0) {
				setQuidTemplate(quidTemplates[0]);
			} else {
				setError({
					status: "MISSING_QUID_TEMPLATE_ERROR",
					data: `No template found for quidType '${quidType}'`,
				});
			}

			setIsLoading(false);
		}

		fetchQuidTemplate();
	}, [quidType]);

	return { isLoading, error, quidTemplate };
};

const selectMembers = (state: any) => state.members.items;
const selectGroupMembers = createSelector(
	selectMembers,
	(_: any, { groupId }) => groupId,
	(members: any[], groupId: string) =>
		members.filter((member) => member.group === groupId)
);

const makeSelectQuidInputs = () =>
	createSelector(
		[
			(state: any) => state.quidInputs.items,
			selectGroupMembers,
			(_: any, { targetDataKey }) => targetDataKey,
			(_: any, { quiddity }) => quiddity,
			(_: any, { inputContext }) => inputContext,
			(_: any, { shouldIncludeArchivedQuidInputs }) =>
				shouldIncludeArchivedQuidInputs,
			(_: any, { quidTemplate }) => quidTemplate,
		],
		(
			allQuidInputs: QuidInput[],
			groupMembers: any[],
			targetDataKey: string,
			quiddity: any,
			inputContext: IOContext,
			shouldIncludeArchivedQuidInputs: boolean,
			quidTemplate?: Quid
		) => {
			const targetDataKeys = targetDataKey.split(",");

			const omitKeys = ["limit", "throttle", "throttleWait", "data"];
			const query = QuidInputUtils.getQueryForContext(
				inputContext,
				{
					...quiddity,
					sourceQuid: quidTemplate && quidTemplate.id,
					archived: shouldIncludeArchivedQuidInputs ? [true, false] : false,
				},
				omitKeys
			);

			// Concatenate all quidInputs when using multiple targetDataKeys
			let quidInputs = targetDataKeys
				.map((targetDataKey: string) => ({ ...query, targetDataKey }))
				.map((matchObject) => _.filter(allQuidInputs, matchObject))
				.flat() as QuidInput[];

			switch (inputContext) {
				case IOContext.CurrentGroupMembers:
					const groupMemberIds: string[] = groupMembers.map(({ id }) => id);
					quidInputs = quidInputs.filter(
						(quidInput: QuidInput) =>
							quidInput.member && groupMemberIds.includes(quidInput.member)
					);
					break;
			}

			return quidInputs;
		}
	);

const useQuidInputs = (
	url: string,
	api: string,
	targetDataKey: string,
	inputContext: IOContext,
	shouldIncludeArchivedQuidInputs: boolean,
	quiddity: any,
	quidTemplate?: Quid,
	throttle = false,
	throttleWait = 0
) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isSuccess, setIsSuccess] = useState(false);
	const [error, setError] = useState<BackendError | undefined>(undefined);

	const maybeQuidTemplateId = quidTemplate && quidTemplate.id;

	useEffect(() => {
		async function fetchQuidInputs(quidTemplate: Quid) {
			setIsLoading(true);

			let query = QuidInputUtils.getQueryForContext(inputContext, {
				...quiddity,
				sourceQuid: quidTemplate.id,
				targetDataKey: targetDataKey.split(","),
				throttle,
				throttleWait,
			});

			if (shouldIncludeArchivedQuidInputs) {
				query = {
					...query,
					archived: shouldIncludeArchivedQuidInputs ? [true, false] : false,
				};
			}

			// We intentionally ignore the result of this request. `getQuidInputs` will add the result
			// to redux and we fetch return the data from redux through a selector.
			await getQuidInputs(url + api, query);

			setIsLoading(false);
			setIsSuccess(true);
		}

		if (quidTemplate && quidTemplate.id) {
			fetchQuidInputs(quidTemplate);
		}
	}, [targetDataKey, inputContext, maybeQuidTemplateId, isSuccess]);

	const groupId: string = quiddity.group.id;
	const selectQuidInputs = useMemo(makeSelectQuidInputs, []);

	const quidInputs = useSelector((state) =>
		selectQuidInputs(state, {
			groupId,
			targetDataKey,
			inputContext,
			quiddity,
			quidTemplate,
			shouldIncludeArchivedQuidInputs,
		})
	);

	return { isLoading, isSuccess, error, quidInputs };
};

const makeCreateQuidInputFunc =
	({ url, api, inputContext, quidTemplate, quiddity, targetDataKey }) =>
	(newQuidInput: QuidInputParams) => {
		const query = QuidInputUtils.getQueryForContext(inputContext, {
			...quiddity,
			targetDataKey,
			sourceQuid: quidTemplate.id,
			...newQuidInput,
		});

		return createQuidInput(url + api, query) as Promise<QuidInput>;
	};

const makeUpdateQuidInput =
	(url: string, api: string) =>
	/*
	 * Returns a promise when update request completes.
	 *
	 * @param quidInputProps.data - The full data object that will be written to QuidInput.
	 */
	({
		id,
		targetDataKey,
		data,
		archived,
		targetQuidInput,
	}: QuidInputUpdateParams) => {
		const currentQuidInput = StoreUtils.getReducer("quidInputs").itemsById[id];

		if (!currentQuidInput) {
			return Promise.reject(
				`No quidinput found in redux with id '${id}'. No update will be sent to the server.`
			);
		}

		// Omit undefined so that we don't override the current QuidInput fields with undefined values
		const updatedProps = _.omitBy(
			{ id, targetDataKey, data, archived, targetQuidInput },
			_.isUndefined
		);
		const newQuidInput = _.assign({}, currentQuidInput, updatedProps);

		return upsertQuidInput(url + api, newQuidInput) as Promise<QuidInput>;
	};

const removeQuidInput = ({ id }) => {
	return QuiddityBackend.remove({ id });
};

export const createMediaUploadQuidInput = async (
	file: File,
	inputContext: IOContext,
	params: Record<string, string>
	//onProgress: (e: ProgressEvent<XMLHttpRequestEventTarget>) => void
) => {
	const state: any = StoreUtils.getState();
	const quiddity = state.quiddity.item;
	let query = QuidInputUtils.getQueryForContext(inputContext, {
		...quiddity,
		...params,
	});

	var formData = new FormData();
	formData.append("file", file);
	for (let key in query) {
		if (query.hasOwnProperty(key)) {
			formData.append(key, query[key]);
		}
	}

	return new Promise((resolve, reject) => {
		var request = new XMLHttpRequest();

		// request.upload.addEventListener(
		// 	"progress",
		// 	(e) => {
		// 		onProgress && onProgress(e);
		// 	},
		// 	false
		// );

		request.onreadystatechange = function () {
			if (request.readyState === 4) {
				const response = JSON.parse(request.response);
				resolve(response);
			}
		};

		request.upload.addEventListener("error", function (err) {
			reject(err);
		});

		request.open("POST", "/api/quidInputs/mediaUpload");
		request.send(formData);
	});
};
