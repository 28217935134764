import React, { useRef } from "react";
import classnames from "classnames";
import Image from "../../../core/Image";
import Style from "./QuizImage.module.css";

const QuizImage = ({
	src,
	align = "center-center",
	rotate = false,
	size,
	show,
	className,
	height = "180px",
}) => {
	const refImage = useRef(null);

	const shouldAnimate = rotate || !src;

	return (
		<div
			ref={refImage}
			className={classnames(
				"quiz-image",
				className,
				Style.root,
				shouldAnimate && Style.animateRotation
			)}
			style={{ height }}
		>
			{show && (
				<Image
					src={src || "quiz-question.png"}
					className={classnames(
						"animated",
						show ? "fadeIn" : "fadeOut delay-2000ms"
					)}
					maxHeight={height}
					size={(size === "contain" && "ratio") || (size === "cover" && "crop")}
					aling={align}
				/>
			)}
		</div>
	);
};

export default QuizImage;
