// Location Constants
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UNDO_LOCATION = "UNDO_LOCATION";
export const REDO_LOCATION = "REDO_LOCATION";

// Pages Constants
export const UPDATE_PAGES = "UPDATE_PAGES";
export const UPDATE_PAGE = "UPDATE_PAGE";
export const ADD_PAGE = "ADD_PAGE";
export const REMOVE_PAGE = "REMOVE_PAGE";
export const REMOVE_PAGES = "REMOVE_PAGES";
export const CHANGE_INDEX_FOR_PAGE = "CHANGE_INDEX_FOR_PAGE";
export const MAKE_PAGES_SNAPSHOT = "MAKE_PAGES_SNAPSHOT";
export const RESTORE_FROM_PAGES_SNAPSHOT = "RESTORE_FROM_PAGES_SNAPSHOT";

// PageItems Constants
export const UPDATE_PAGE_ITEMS = "UPDATE_PAGE_ITEMS";
export const UPDATE_PAGE_ITEM = "UPDATE_PAGE_ITEM";
export const ADD_PAGE_ITEM = "ADD_PAGE_ITEM";
export const REMOVE_PAGE_ITEM = "REMOVE_PAGE_ITEM";
export const REMOVE_PAGE_ITEMS = "REMOVE_PAGE_ITEMS";
export const RESET_PAGE_ITEMS = "RESET_PAGE_ITEMS";
export const CHANGE_INDEX_FOR_PAGE_ITEM = "CHANGE_INDEX_FOR_PAGE_ITEM";
export const MAKE_PAGE_ITEMS_SNAPSHOT = "MAKE_PAGE_ITEMS_SNAPSHOT";
export const RESTORE_FROM_PAGE_ITEMS_SNAPSHOT =
	"RESTORE_FROM_PAGE_ITEMS_SNAPSHOT";

// Config Constants
export const UPDATE_CONFIG = "UPDATE_CONFIG";
export const UPDATE_CONFIG_VALUE = "UPDATE_CONFIG_VALUE";
export const REMOVE_CONFIG_VALUE = "REMOVE_CONFIG_VALUE";
export const MAKE_CONFIG_SNAPSHOT = "MAKE_CONFIG_SNAPSHOT";
export const RESTORE_FROM_CONFIG_SNAPSHOT = "RESTORE_FROM_CONFIG_SNAPSHOT";

// App Constants
export const UPDATE_APP = "UPDATE_APP_DATA";
export const UPDATE_APP_VALUE = "UPDATE_APP_DATA_VALUE";
export const REMOVE_APP_VALUE = "REMOVE_APP_DATA_VALUE";
export const MAKE_APP_SNAPSHOT = "MAKE_APP_SNAPSHOT";
export const RESTORE_FROM_APP_SNAPSHOT = "RESTORE_FROM_APP_SNAPSHOT";

// Text Constants
export const UPDATE_TEXTS = "UPDATE_TEXTS";
export const UPDATE_TEXT = "UPDATE_TEXT";
export const ADD_TEXT = "ADD_TEXT";
export const REMOVE_TEXT = "REMOVE_TEXT";
export const REMOVE_TEXTS = "REMOVE_TEXTS";
export const MAKE_TEXTS_SNAPSHOT = "MAKE_TEXTS_SNAPSHOT";
export const RESTORE_FROM_TEXTS_SNAPSHOT = "RESTORE_FROM_TEXTS_SNAPSHOT";

// Subtitle Constants
export const UPDATE_SUBTITLES = "UPDATE_SUBTITLES";
export const UPDATE_SUBTITLE = "UPDATE_SUBTITLE";
export const ADD_SUBTITLE = "ADD_SUBTITLE";
export const REMOVE_SUBTITLE = "REMOVE_SUBTITLE";
export const CLEAR_SUBTITLES = "CLEAR_SUBTITLES";

// Timer constants
export const UPDATE_TIMER = "UPDATE_TIMER";

// Sprig Stories
export const UPDATE_SPRIG_STORIES = "UPDATE_SPRIG_STORIES";
export const UPDATE_SPRIG_STORY = "UPDATE_SPRIG_STORY";
export const ADD_SPRIG_STORY = "ADD_SPRIG_STORY";
export const REMOVE_SPRIG_STORY = "REMOVE_SPRIG_STORY";
export const REMOVE_SPRIG_STORIES = "REMOVE_SPRIG_STORIES";
export const RESET_SPRIG_STORIES = "RESET_SPRIG_STORIES";
export const CHANGE_INDEX_FOR_SPRIG_STORY = "CHANGE_INDEX_FOR_SPRIG_STORY";

// Quid Input Constants
export const UPDATE_QUID_INPUTS = "UPDATE_QUID_INPUTS";
export const UPDATE_QUID_INPUT = "UPDATE_QUID_INPUT";
export const REMOVE_QUID_INPUT = "REMOVE_QUID_INPUT";
export const REMOVE_ALL_QUID_INPUTS = "REMOVE_ALL_QUID_INPUTS";
export const MAKE_QUID_INPUTS_SNAPSHOT = "MAKE_QUID_INPUTS_SNAPSHOT";
export const RESTORE_FROM_QUID_INPUTS_SNAPSHOT =
	"RESTORE_FROM_QUID_INPUTS_SNAPSHOT";
