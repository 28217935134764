import watch from "redux-watch";
import _ from "lodash";
import * as StoreObserver from "./core/StoreObserver";

let _store;

export function setStore(store) {
	_store = store;
}

export function getStore() {
	return _store;
}

export function getState() {
	return _store.getState();
}

export function getReducer(reducer) {
	return getState()[reducer];
}

export function watchStore(reducer, onChange) {
	let w = watch(getState, reducer);
	getStore().subscribe(
		w((newVal, oldVal, objectPath) => {
			onChange(newVal, oldVal, objectPath);
		})
	);
}

export function watchItem(reducer, id, onChange) {
	watchStore(reducer, (newVal, oldVal, objectPath) => {
		const newItem = newVal.items.find((newValItem) => newValItem.id === id);
		const oldItem = oldVal.items.find((oldVal) => oldVal.id === id);
		if (newItem && oldItem && !_.isEqual(newItem, oldItem)) {
			onChange(newItem, oldItem);
		}
	});
}

export function addStoreObserver(
	name,
	storage,
	domain,
	restoreFunction,
	saveFunction
) {
	const domainDependentStorages = [
		"local",
		"localStorage",
		"session",
		"sessionStorage",
	];

	// This keeps the local storages acting more like any other caching:
	// You expect nothing to be cached when accessing code on a new URL
	if (domainDependentStorages.includes(storage)) {
		const domainPrefix = location.hostname + location.pathname;
		StoreObserver.addStoreObserver(
			name,
			storage,
			domainPrefix + domain,
			restoreFunction,
			saveFunction
		);
	} else {
		StoreObserver.addStoreObserver(
			name,
			storage,
			domain,
			restoreFunction,
			saveFunction
		);
	}
}

export function restoreStore(name) {
	StoreObserver.restore(name);
}

export function saveStore(name) {
	StoreObserver.save(name);
}

export function clearStore(name) {
	StoreObserver.clear(name);
}

export function clearAllStores() {
	StoreObserver.clearAll();
}
