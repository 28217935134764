import React from "react";
import classnames from "classnames";
import { getBootstrapColor } from "../../../../utils/colorUtils";
import { getText } from "../../../../utils/textUtils";

const QuizIndex = ({
	className,
	style,
	baseColor = "primary",
	index,
	total,
	textIdQuizCard = "05FE06CB-E250-4E51-8DC3-9FE284497273",
}) => {
	const styleColor = { color: getBootstrapColor(baseColor) };
	return (
		<div className={classnames("d-flex flex-column", className)} style={style}>
			<span
				className="quiz-label d-block mb-n1 font-weight-bold text-uppercase"
				style={styleColor}
			>
				{`${getText(textIdQuizCard)} ${index + 1}/${total}`}
			</span>
			<h1 className="my-n1" style={styleColor}>{`#${index + 1}`}</h1>
		</div>
	);
};

export default QuizIndex;
