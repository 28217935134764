import React, { useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import PageItemModal from "../pageitems/PageItemModal";

const FFImage = ({
	style,
	className,
	src,
	width = "auto",
	height = "auto",
	children,
	rest,
	omitSrc = false,
	backgroundImage = false,
	autoSize = false,
	isZoomable = false,
	alt = "",
} = props) => {
	const [size, setSize] = useState({ width, height });

	useEffect(() => {
		if (autoSize) {
			loadImage();
		}
	}, [autoSize]);

	const loadImage = () => {
		var img = new Image();
		img.onload = (e) => {
			if (!e.target.width) {
				// IE11 will return dimensions of 0, for certain SVGs.
				// this let's us default to css styling, as these undefined values will be ignored from the inline styles
				setSize({ width: undefined, height: undefined });
				return;
			}
			setSize({ width: e.target.width + "px", height: e.target.height + "px" });
		};
		img.src = src;
	};

	const [showInModal, setShowInModal] = useState(false);

	const handleClick = useCallback(() => {
		if (isZoomable) {
			setShowInModal(!showInModal);
		}
	}, [isZoomable, showInModal]);

	function getComponent(styleOverrides) {
		if (omitSrc && src.indexOf("null") > -1) {
			return <div className="omited-ff-image" />;
		} else if (backgroundImage) {
			return (
				<div
					onClick={handleClick}
					className={classnames("ff-image", className)}
					style={{
						backgroundImage: `url(${src})`,
						...styles.image,
						...style,
						height: size.height,
						width: size.width,
						...styleOverrides,
					}}
				>
					{children}
				</div>
			);
		} else {
			return (
				<img
					onClick={handleClick}
					src={src}
					width={size.width}
					height={size.height}
					className={classnames("ff-image", className)}
					style={Object.assign({}, styles.image, style, styleOverrides)}
					alt={alt}
					{...rest}
				/>
			);
		}
	}

	let component = null;

	if (showInModal) {
		component = (
			<React.Fragment>
				<PageItemModal pageItem={{ enabled: true, show: true }}>
					<div
						className="d-flex flex-column justify-content-center animated fadeIn faster zoomed-image-container px-1 px-sm-4"
						style={{
							cursor: "zoom-out",
							paddingTop: 52,
							pointerEvents: "all",
							position: "fixed",
							top: 0,
							left: 0,
							background: "rgba(255,255,255,0.8)",
							width: "100vw",
							height: "100vh",
						}}
						onClick={handleClick}
					>
						{getComponent({
							backgroundSize: "contain",
							objectFit: "contain",
							maxHeight: "calc(100vh - 52px)",
							height: "100%",
							width: "100%",
						})}
					</div>
				</PageItemModal>
				{getComponent()}
			</React.Fragment>
		);
	} else {
		const cursorStyles = isZoomable ? { cursor: "zoom-in" } : {};
		component = getComponent(cursorStyles);
	}

	return component;
};

const styles = {
	image: {
		WebkitUserDrag: "none",
		KhtmlUserDrag: "none",
		MozUserDrag: "none",
		OUserDrag: "none",
		userDrag: "none",
	},
};

export default FFImage;
