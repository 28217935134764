class DeprecatedPageItemError extends Error {
	title: string;
	symbolName: string;
	version: string;
	replacementSymbolName: string | undefined;

	constructor(
		symbolName: string,
		version: string,
		replacementSymbolName?: string,
		reaplacementNotice?: string
	) {
		const message =
			`"${symbolName}" is outdated and was removed in Funkis Player version ${version}. ` +
			(replacementSymbolName
				? `Please replace the page item with "${replacementSymbolName}" or use a previous Funkis Player version. `
				: `Please downgrade to a previous Funkis Player version. `) +
			(reaplacementNotice ? reaplacementNotice : "");

		super(message);

		this.name = "DeprecatedPageItemError";
		this.title = "Block is outdated";
		this.symbolName = symbolName;
		this.version = version;
		this.replacementSymbolName = replacementSymbolName;
	}
}

export default DeprecatedPageItemError;
