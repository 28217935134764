import React from "react";

const IconMove = ({ color = "#FFF" }) => {
	return (
		<svg
			width="70px"
			height="69px"
			viewBox="0 0 70 69"
			version="1.1"
			style={{ transform: "scale(1)" }}
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Group-Copy"
					transform="translate(-12.000000, 0.000000)"
					fill={color}
				>
					<g id="Group" transform="translate(12.500000, 0.000000)">
						<polygon
							id="Fill-4"
							points="8.625 38.8125 60.375 38.8125 60.375 28.4625 8.625 28.4625"
						></polygon>
						<polygon
							id="Fill-6"
							points="29.325 59.5125 39.675 59.5125 39.675 7.7625 29.325 7.7625"
						></polygon>
						<polygon
							id="Fill-4"
							transform="translate(8.625000, 34.500000) rotate(180.000000) translate(-8.625000, -34.500000) "
							points="0 21.5625 17.25 34.5 0 47.4375"
						></polygon>
						<polygon
							id="Fill-4-Copy"
							points="51.75 21.5625 69 34.5 51.75 47.4375"
						></polygon>
						<polygon
							id="Fill-4-Copy-2"
							transform="translate(34.500000, 60.375000) rotate(90.000000) translate(-34.500000, -60.375000) "
							points="25.875 47.4375 43.125 60.375 25.875 73.3125"
						></polygon>
						<polygon
							id="Fill-4-Copy-3"
							transform="translate(34.500000, 8.625000) rotate(270.000000) translate(-34.500000, -8.625000) "
							points="25.875 -4.3125 43.125 8.625 25.875 21.5625"
						></polygon>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default IconMove;
