import React from "react";
import classnames from "classnames";
import Page from "../components/core/Page";

import { friendlyLocationPathToLocation } from "../utils/LocationUtils";
import { getLocationPathForPage } from "../utils/PagesUtils";
import { getReducer } from "../utils/StoreUtils";

const PageItemPage = ({ pageItem, style, className } = props) => {
	const { friendlyId } = pageItem;
	const pages = getReducer("pages").items;

	const page = friendlyId && pages.find((p) => p.friendlyId === friendlyId);

	if (!page) {
		return (
			<p className="text-warning">{`No page found for friendlyId "${friendlyId}"!`}</p>
		);
	}

	const location = friendlyLocationPathToLocation(friendlyId);
	const locationPath = getLocationPathForPage(pages, page);

	return (
		<div
			style={{ ...pageItem.style, ...style }}
			className={classnames(
				className,
				"page-item page-item-page",
				pageItem.className
			)}
		>
			<Page
				key={`page-${pageItem.id}`}
				page={page}
				locationPath={locationPath}
				location={location}
			/>
		</div>
	);
};

export default PageItemPage;
