import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import _ from "lodash";
import uuid from "uuid/v4";
import { StoreUtils, QuidInputActions } from "funkis-foundation";

import LocalInput from "./LocalInput";
import LocalInputs from "./LocalInputs";

const LocalBase = (props) => {
	let {
		quidType,
		targetDataKey = "",
		mode,
		renderFunction,
		renderInput,
		renderInputs,
		renderOutput,
		renderOutputs,
		renderInputIfNoInputs = false,
		sort = ({ ids = [], items = [] }) => ids,
		quidInputFilter = (ids = []) => ids,
		className,
		onFetchComplete,
		children,
	} = props;

	// Local database query, equivalent to Quiddity server query using filters on sourceQuid id and targetDataKey
	const _quidInputIds = useSelector((state) => {
		const targetDataKeys = targetDataKey.split(",");
		const result = targetDataKeys.map((tdk) => {
			const isValidKeyValue = (val) => val !== "" && val !== undefined;
			const tdkMatch = _.pickBy(
				{ targetDataKey: tdk, quidType: props.quidType },
				isValidKeyValue
			);
			return _.filter(state.quidInputs.items, tdkMatch).map((item) => item.id);
		});
		return _.flatten(result);
	}, shallowEqual);

	// Run through filter...
	const quidInputIds = quidInputFilter(_quidInputIds);

	useEffect(() => {
		// Simulate the "onFetchComplete" server function.
		const quidInputs = StoreUtils.getReducer("quidInputs").items.filter(
			(item) => quidInputIds.includes(item.id)
		);
		if (targetDataKey && quidType) {
			onFetchComplete &&
				onFetchComplete({
					...props,
					quidInputIds,
					quidInputs,
					save: ({ quidInput, data, alwaysCreate = false }) => {
						return new Promise((resolve) => {
							const id =
								(alwaysCreate ? uuid() : quidInput && quidInput.id) || uuid();
							const _quidInput = quidInput || quidInputs[0] || {};
							const updates = {
								targetDataKey: targetDataKey,
								quidType,
								..._quidInput,
								data: { ..._quidInput.data, ...data },
							};
							QuidInputActions.updateQuidInput(id, updates);
							resolve();
						});
					},
				});
		}
	}, [targetDataKey, quidType]);

	const newProps = { ...props, id: quidInputIds[0], quidInputIds };

	const getComponent = (props) => {
		const sortFunc = (quidInputIds) => {
			const items = quidInputIds.map(
				(id) => StoreUtils.getReducer("quidInputs").itemsById[id]
			);
			return sort({ ids: quidInputIds, items });
		};
		switch (mode) {
			case "bareInputs":
			case "bareOutputs":
				return <LocalInputs {...newProps} renderFunction={renderFunction} />;
			case "input":
				return <LocalInput {...newProps} renderFunction={renderInput} />;
			case "inputs":
				const ids = sortFunc(quidInputIds);
				if (renderInputIfNoInputs && !ids.length) {
					return <LocalInput {...newProps} renderFunction={renderInputs} />;
				}
				return ids.map((id, index) => {
					return (
						<LocalInput
							key={`LocalInput-${id}`}
							{...newProps}
							renderFunction={renderInputs}
							id={id}
							index={index}
						/>
					);
				});
			case "output":
				return <LocalInput {...newProps} renderFunction={renderOutput} />;
			case "outputs":
				if (renderInputIfNoInputs && !quidInputIds.length) {
					return (
						<LocalInput
							{...newProps}
							renderFunction={renderInputs || renderOutputs}
						/>
					);
				}
				return sortFunc(quidInputIds).map((id, index) => (
					<LocalInput
						key={`QuiddityUIBaseOutput-${index}`}
						{...newProps}
						id={id}
						index={index}
						renderFunction={renderOutputs}
					/>
				));
			default:
				return <p className="text-warning">{`Mode "${mode}" is not valid!`}</p>;
		}
	};

	return (
		<div className={className}>
			{getComponent(props)}
			{children}
		</div>
	);
};

export const save = ({ id, ioID, data }) => {
	const quidInput = StoreUtils.getReducer("quidInputs").itemsById[id];
	if (!quidInput) {
		return new Promise((resolve, reject) => {
			return reject("No quid input found!");
		});
	}

	return QuidInputActions.updateQuidInput(id, {
		...quidInput,
		targetDataKey: ioID || quidInput.targetDataKey,
		data: { ...quidInput.data, ...data },
	});
};

export const remove = ({ id }) => {
	return QuidInputActions.removeQuidInput(id);
};

export const saveItems = ({ items }) => {
	return QuidInputActions.updateQuidInputs(items);
};

export const getItems = ({ match }) => {
	return _.filter(StoreUtils.getReducer("quidInputs").items, {
		targetDataKey: match.ioID,
	});
};

export default LocalBase;
