import classnames from "classnames";
import { PageItemActions, ColorUtils } from "funkis-foundation";
import React, { useEffect, useState } from "react";
import Cuepoints from "../../components/core/Cuepoints";
import Scalable from "../../components/core/Scalable";
import Text from "../../components/core/Text";
import Cuepoint from "../../components/scroll/Cuepoint";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import ReadMore from "../../components/scroll/ReadMore";
import VideoPlayer from "../../components/core/VideoPlayer/VideoPlayer";
import { VideoPlayerScrollBlock } from "../../models/pageitem";
import { PageItem } from "../../models/player";

const { updatePageItem } = PageItemActions;

const CuepointContainer: React.FC<{ scalable: boolean }> = ({
	scalable,
	children,
}) => {
	if (scalable) {
		return (
			<Scalable
				width={800}
				height={450}
				objectFit="contain"
				className="absolute-top w-100 h-100"
				style={undefined}
				innerClassName={undefined}
			>
				{children}
			</Scalable>
		);
	} else {
		return <div className="absolute-top w-100 h-100">{children}</div>;
	}
};

const PageItemVideoPlayer: React.FC<{
	pageItem: PageItem<VideoPlayerScrollBlock>;
}> = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;

	const [readMoreOpened, setReadMoreOpened] = useState(false);
	const [showNextButton, setShowNextButton] = useState(
		cmtPageItem.show_next_button === "always"
	);

	const onOpenToggleHandler = () => {
		setReadMoreOpened(true);
	};

	useEffect(() => {
		if (
			cmtPageItem.show_next_button === "always" ||
			readMoreOpened ||
			((cmtPageItem.show_next_button === "on-video-end" ||
				cmtPageItem.show_next_button === "auto") &&
				pageItem.status === "completed")
		) {
			setShowNextButton(true);
		} else {
			setShowNextButton(false);
		}
	}, [pageItem.status, readMoreOpened, cmtPageItem.show_next_button]);

	return (
		<PageItemScrollBase
			{...props}
			className={classnames(
				cmtPageItem.cuepoints_scalable === "yes" && "cuepoints-scalable"
			)}
			containerClassName="w-100"
			showNextButton={showNextButton}
			renderFunction={(renderProps) => {
				return (
					<div className="container-fluid">
						<div className="row m-0">
							<Text
								textId={cmtPageItem.header}
								tagName="h2"
								className={classnames(
									"col-sm-12 mb-4 mb-sm-5 font-weight-bold animated",
									renderProps.viewVisible ? "fadeIn" : "fadeOut"
								)}
							/>
							<Text
								className={classnames(
									"col-sm-9 mb-6 animated",
									renderProps.viewVisible ? "fadeIn delay-250ms" : "fadeOut"
								)}
								tagName="p"
								textId={cmtPageItem.body}
							/>
						</div>
						<div className="row m-0">
							<div className="col-12 px-0 px-sm-3">
								<VideoPlayer
									crop={cmtPageItem.size === "crop"}
									maxHeight={cmtPageItem.max_height || "65vh"}
									autoplay={
										cmtPageItem.autoplay === "once" ||
										cmtPageItem.autoplay === "loop"
									}
									loop={cmtPageItem.autoplay === "loop"}
									controls={cmtPageItem.controls === "yes"}
									native={cmtPageItem.native === "yes"}
									muted={cmtPageItem.muted === "yes"}
									src={cmtPageItem.video ? cmtPageItem.video : "test.mp4"}
									videoId={cmtPageItem.id}
									posterFrame={cmtPageItem.poster_frame}
									borderColor={ColorUtils.getBootstrapColor(
										cmtPageItem.border_color || "#CCCCCC"
									)}
									signText={cmtPageItem.sign_text}
									signShowTime={cmtPageItem.sign_show_time}
									signHideTime={cmtPageItem.sign_hide_time}
									alwaysShowProgressBar={
										cmtPageItem.show_progress_bar === "always"
									}
									onEnded={() => {
										updatePageItem(pageItem.id, { status: "completed" });
									}}
								>
									{/* CUE POINT TEXTS */}
									{(currentTime: number) => (
										<CuepointContainer
											scalable={cmtPageItem.cuepoints_scalable === "yes"}
										>
											{cmtPageItem.content_array &&
												cmtPageItem.content_array.length > 0 && (
													<Cuepoints
														displayElement={Cuepoint}
														time={currentTime}
														contentArray={cmtPageItem.content_array}
														mapContentAttributes={{
															header: "headerTextId",
															body_column_1: "bodyTextId",
															media: "media",
														}}
														displayProps={{
															headerColor: cmtPageItem.cuepoint_header_color,
															bodyColor: cmtPageItem.cuepoint_body_color,
															textContainerColor:
																cmtPageItem.cuepoint_text_background_color,
															textContainerOpacity:
																cmtPageItem.cuepoint_text_background_opacity,
														}}
													/>
												)}
										</CuepointContainer>
									)}
								</VideoPlayer>
							</div>
							{/* TRANSCRIBE SUBTITLE */}
							{cmtPageItem.transcribe === "yes" && (
								<ReadMore
									pageItem={{
										cmtPageItem: {
											video_src: cmtPageItem.video,
											label_open: "85ED8C97-F3D6-452F-A598-BA4DBBC42DA1",
											label_closed: "C7353ED0-A22A-4287-BFF2-95230DDFEE28",
										},
									}}
									onOpenToggle={onOpenToggleHandler}
									viewVisible={renderProps.viewVisible}
									className="mt-3 w-100"
								></ReadMore>
							)}
						</div>
					</div>
				);
			}}
		/>
	);
};

export default PageItemVideoPlayer;
