import { createSelector } from "reselect";
import _ from "lodash";
import * as LocationUtils from "funkis-foundation/utils/LocationUtils";
import * as PageItemsUtils from "funkis-foundation/utils/PageItemsUtils";
import * as StoreUtils from "funkis-foundation/utils/StoreUtils";

const { getPageIdsFromLocation, getPagesFromLocation } = LocationUtils;
const { getPageItemByPath } = PageItemsUtils;

const getApp = (state, props) => state.app.item;
const getPages = (state, props) => state.pages.items;
const getPageItems = (state, props) => state.pageItems.items;
const getLocation = (state, props) => state.location.item || {};
const getPageItemId = (state, props) => _.get(props, "pageItem.id");
const getPageItemParentPageId = (state, props) =>
	_.get(props, "pageItem.parentPageId");
const getPath = (_, props) => props.path;

export const makeGetScrollProgress = () => {
	return createSelector(
		getPageItems,
		getLocation,
		(pageItems, locationItem) => {
			const { location = [], query = {} } = locationItem;
			const pageIds = getPageIdsFromLocation(location);
			const omitTypes = ["page-header", "page-footer", "reveal"];
			const scrollPageItems = pageItems.filter(
				(pageItem) =>
					pageItem.data &&
					pageItem.data.cmt &&
					!omitTypes.includes(pageItem.type) &&
					pageItem.cmtPageItem &&
					pageIds.includes(pageItem.cmtPageItem.parent_id)
			);
			const totalScrollPageItems = scrollPageItems.length;
			const completedScrollPageItems = scrollPageItems.filter(
				(pageItem) => pageItem.wasViewComplete
			).length;
			return completedScrollPageItems / totalScrollPageItems;
		}
	);
};

export const makeGetFirstPage = () => {
	return createSelector(getPages, (pages) => {
		const chapterPages = pages.filter(
			(page) =>
				page.data && page.data.cmtPage && page.data.cmtPage.type === "chapter"
		);
		const pageOfTypeIntro = pages.find(
			(p) => p.data?.cmtPage?.page_type === "introduction"
		);
		const firstChapter = chapterPages[0];
		const firstChapterPages = pages.filter(
			(page) => page?.parentPageId && page.parentPageId === firstChapter?.id
		);
		return pageOfTypeIntro || firstChapterPages[0];
	});
};

// Saveable or restorable
export const makeGetIsLocationSaveablePage = () =>
	createSelector(
		[getApp, makeGetFirstPage(), makeGetCurrentPage()],
		(appItem, firstPage, currentPage) => {
			// Note: type "intro" is the splash page, not same as page_type "introduction" from FAT
			const pageTypes = ["language-menu", "intro", "menu"];
			const FATPageTypes = ["introduction", "print"];
			const currentPageIsFirstPage = currentPage.id === firstPage?.id;
			const shouldSkipSavingFirstPage = appItem.skip_menu === "yes";

			const isLocationSaveablePage =
				!(currentPageIsFirstPage && shouldSkipSavingFirstPage) &&
				!pageTypes.includes(_.get(currentPage, "type")) &&
				!FATPageTypes.includes(_.get(currentPage, "data.cmtPage.page_type"));

			return isLocationSaveablePage;
		}
	);

export const makeGetPageProgress = () => {
	return createSelector(
		getPages,
		makeGetCurrentPage(),
		(pages, currentPage) => {
			const chapterPages = pages.filter(
				(page) => currentPage && page.parentPageId === currentPage.parentPageId
			);
			// const completedChapterPages = chapterPages.filter((page) => page.status !== "not_attempted");
			const pageIndex = chapterPages.findIndex(
				(page) => page.id === currentPage.id
			);
			const showProgress = chapterPages.length > 1;
			return showProgress && `${pageIndex + 1}/${chapterPages.length}`;
		}
	);
};

export const makeGetLocationData = () => {
	return createSelector(getLocation, (item) => {
		const { location = [], query = {} } = item;
		const pageIds = getPageIdsFromLocation(location);
		return { pageIds };
	});
};

export const makeGetCurrentPage = () => {
	return createSelector(getLocation, (item) => {
		const { location = [] } = item;
		const pages = getPagesFromLocation(location);
		return pages[pages.length - 1];
	});
};

export const makeGetValueForCurrentPage = () => {
	return createSelector(makeGetCurrentPage(), getPath, (page, path) => {
		return _.get(page, path);
	});
};

export const makeGetShowTopBar = () => {
	return createSelector(
		getApp,
		getPages,
		makeGetLocationData(),
		(app, pages, locationData) => {
			if (app.program_type === "slide") {
				return false;
			}

			const pagesById = StoreUtils.getReducer("pages").itemsById;
			const omitPages = ["welcome-splash", "select-language", "group-setup"];
			const omitPageTypes = ["library"];
			const omitFATPageTypes = ["introduction"]; // NOTE: NOT same as page.type === "intro"
			const { pageIds } = locationData;
			const friendlyIds = pageIds.map((pageId) => pagesById[pageId].friendlyId);
			const pageTypes = pageIds.map((pageId) => pagesById[pageId].type);
			const FATpageTypes = pageIds.map((pageId) =>
				_.get(pagesById[pageId], "data.cmtPage.page_type")
			);
			const intersectionByFriendlyId = _.intersection(omitPages, friendlyIds);
			const intersectionByType = _.intersection(omitPageTypes, pageTypes);
			const intersectionByFATPageType = _.intersection(
				omitFATPageTypes,
				FATpageTypes
			);
			const intersections = intersectionByFriendlyId
				.concat(intersectionByType)
				.concat(intersectionByFATPageType);
			return intersections.length === 0;
		}
	);
};

export const makeGetChapterTitleId = () => {
	return createSelector(makeGetLocationData(), (locationData) => {
		const pagesById = StoreUtils.getReducer("pages").itemsById;
		const { pageIds } = locationData;
		const pages = pageIds.map((pageId) => pagesById[pageId]);
		const cmtChapterPage = pages[1] && pages[1].data && pages[1].data.cmtPage;
		return cmtChapterPage && cmtChapterPage.title_txt_id;
	});
};

export const makeGetNextBlock = () => {
	return createSelector(getPageItemId, (pageItemId) => {
		const nextPageItem = getPageItemByPath(
			[{ parent: 0 }, { sibling: 1 }],
			pageItemId
		);
		return nextPageItem;
	});
};

export const makeGetParentBlock = () => {
	return createSelector(getPageItemId, (pageItemId) => {
		return getPageItemByPath([{ parent: 0 }, { sibling: -1 }], pageItemId);
	});
};

export const makeGetIsNextBlockRevealed = () => {
	return createSelector(makeGetNextBlock(), (nextBlock) => {
		return nextBlock ? nextBlock.revealed : true;
	});
};

export const makeGetIsFirstBlock = () => {
	return createSelector(getPageItemId, (pageItemId) => {
		const parentPageItem = getPageItemByPath(
			[{ parent: 0 }, { sibling: -1 }],
			pageItemId
		);
		return parentPageItem === undefined || !parentPageItem.cmtPageItem;
	});
};

export const makeGetIsPrintPage = () => {
	return createSelector(getPageItemParentPageId, (pageItemParentPageId) => {
		const parentPage =
			StoreUtils.getReducer("pages").itemsById[pageItemParentPageId];
		return (
			parentPage && _.get(parentPage, "data.cmtPage.page_type") === "print"
		);
	});
};

export const makeGetPageType = () => {
	return createSelector(getPageItemParentPageId, (pageItemParentPageId) => {
		const parentPage =
			StoreUtils.getReducer("pages").itemsById[pageItemParentPageId];
		return parentPage && _.get(parentPage, "data.cmtPage.page_type");
	});
};
