import React from "react";
import classnames from "classnames";

import Style from "./MenuIcon.module.css";
import { getBootstrapColor } from "../../utils/colorUtils";
import { connect } from "../../utils/reduxUtils";
import {
	ReduxObjectSelectors,
	ReduxCollectionSelectors,
} from "funkis-foundation";

const { makeGetItemByIdSelector } = ReduxCollectionSelectors;
const { makeGetValueSelector } = ReduxObjectSelectors;

const MenuIcon = ({
	className,
	classNameLine,
	color = "light-gray",
	sideMenu,
}) => {
	const { open } = sideMenu;
	return (
		<div className={classnames(Style.root, className)}>
			<div
				className={classnames(
					Style.line,
					open && Style.open_line_1,
					classNameLine
				)}
				style={{ backgroundColor: getBootstrapColor(color) }}
			/>
			<div
				className={classnames(
					Style.line,
					open && Style.open_line_2,
					classNameLine
				)}
				style={{ backgroundColor: getBootstrapColor(color) }}
			/>
			<div
				className={classnames(
					Style.line,
					open && Style.open_line_3,
					classNameLine
				)}
				style={{ backgroundColor: getBootstrapColor(color) }}
			/>
			<div
				className={classnames(
					Style.line,
					open && Style.open_line_4,
					classNameLine
				)}
				style={{ backgroundColor: getBootstrapColor(color) }}
			/>
		</div>
	);
};

const makeMapStateToProps = () => {
	const getValueSelector = makeGetValueSelector();
	const getItemByIdSelector = makeGetItemByIdSelector();
	const mapStateToProps = (state, props) => {
		return {
			sideMenu: getItemByIdSelector(
				state,
				{ ...props, id: "e5186042-22c7-443a-862e-e370b74e44d7-cmt" },
				"pageItems"
			),
			//side-menu
		};
	};
	return mapStateToProps;
};

export default connect(MenuIcon, makeMapStateToProps);
