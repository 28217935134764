import classNames from "classnames";
import Button from "components/Button/Button";
import ButtonStyles from "components/Button/Button.module.css";
import Stack from "components/Stack/Stack";
import { QuidInput } from "funkis-template/hooks/backend";
import { useText } from "funkis-template/hooks/text";
import {
	useCallback,
	useState,
	DragEvent,
	FC,
	ChangeEvent,
	useEffect,
	useRef,
} from "react";
import { GlobalText } from "./FileDropArea.constants";
import Styles from "./FileDropArea.module.css";
import deleteIconHref from "./ic_trashcan.svg";
import dropIconHref from "./ic_drop_image.svg";
import Text from "funkis-template/components/core/Text";

const VALID_IMAGE_TYPES = ["image/png", "image/jpeg"];

function readFileAsync(file: File): Promise<FileReader["result"]> {
	return new Promise((resolve, reject) => {
		let reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result);
		};

		reader.onerror = reject;

		reader.readAsDataURL(file);
	});
}

export type FileDropAreaProps = {
	quidInputs: QuidInput[];
	onChange: (file: File) => void;
	onDelete: () => void;
	loading: boolean;
	aspectRatio?: string;
};

const FileDropArea: FC<FileDropAreaProps> = ({
	quidInputs = [],
	aspectRatio = "3/2",
	loading,
	onChange,
	onDelete,
}) => {
	const maxFileCount = 1;
	const quidInput = quidInputs.at(-1);
	const formRef = useRef<HTMLFormElement>(null);

	const [isHighlighting, setIsHighlighting] = useState(false);
	const [pendingImage, setPendingImage] = useState<{
		previewSrc?: string;
		file?: File;
	}>({ previewSrc: undefined });

	useEffect(() => {
		/**
		If quid input has changed it means that we have a new uploaded image.
		We should show that image instead of our preview.
		**/
		if (quidInput?.data.src) {
			setPendingImage({});
		}
	}, [quidInput?.data.src]);

	const handleHighlight = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsHighlighting(true);
	}, []);

	const handleUnHighlight = useCallback(() => {
		setIsHighlighting(false);
	}, []);

	const handleQuidInputDelete = useCallback(() => {
		setPendingImage({});
		onDelete();
	}, [onDelete]);

	const handleFiles = useCallback(
		async (fileList: FileList) => {
			const allFiles = Array.from(fileList);
			const validFiles = allFiles.filter((file) =>
				VALID_IMAGE_TYPES.includes(file.type)
			);

			const prevFile = pendingImage.file;
			const nextFile = validFiles.at(-1) ?? prevFile;

			if (nextFile) {
				const previewSrc = (await readFileAsync(nextFile)) as string;
				setPendingImage({ previewSrc, file: nextFile });
			}

			formRef.current?.reset();
		},
		[pendingImage]
	);

	const handleInputChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const target = e.target as HTMLInputElement;

			if (target.files) {
				handleFiles(target.files);
			}
		},
		[handleFiles]
	);

	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			setIsHighlighting(false);
			handleFiles(e.dataTransfer.files);
		},
		[handleFiles]
	);

	const handleUploadImages = useCallback(() => {
		if (pendingImage.file) {
			onChange(pendingImage.file);
		}
	}, [onChange, pendingImage.file]);

	const deleteTextTitle = useText(GlobalText.DeleteImage);

	return (
		<div
			className={classNames(
				Styles.fileDropArea,
				isHighlighting && Styles.highlight
			)}
			onDragEnter={handleHighlight}
			onDragOver={handleHighlight}
			onDragLeave={handleUnHighlight}
			onDrop={handleDrop}
		>
			<Stack align="center">
				<div className={Styles.previewArea}>
					<div className={Styles.toolbar}>
						{(quidInput?.data.src || pendingImage.previewSrc) && (
							<button
								title={deleteTextTitle}
								className={classNames(ButtonStyles.resetButton)}
								onClick={handleQuidInputDelete}
							>
								<img
									src={deleteIconHref}
									width="16px"
									height="22px"
									alt={deleteTextTitle}
								/>
							</button>
						)}
					</div>
					{(pendingImage.previewSrc && (
						<img
							className={Styles.uploadedImage}
							src={pendingImage.previewSrc}
							alt=""
						/>
					)) ||
						(quidInput?.data.src && (
							<img
								className={Styles.uploadedImage}
								src={quidInput.data.src}
								alt=""
							/>
						)) || (
							<div>
								<img src={dropIconHref} alt="" width="78px" height="89px" />
							</div>
						)}
				</div>

				<Stack align="center" width="fit-content-width">
					<form ref={formRef}>
						<label
							className={classNames(
								ButtonStyles.resetButton,
								ButtonStyles.commonButton,
								ButtonStyles.standardSizeButton,

								ButtonStyles.fluidButton,
								ButtonStyles.solidButton
							)}
						>
							<Text textId={GlobalText.SelectImage} />
							<input
								type="file"
								onChange={handleInputChange}
								multiple={maxFileCount > 1}
								accept={VALID_IMAGE_TYPES.join(", ")}
								disabled={pendingImage.previewSrc !== undefined}
							></input>
						</label>
					</form>
					<Button
						onClick={handleUploadImages}
						disabled={loading || pendingImage.previewSrc === undefined}
						fluid={true}
					>
						<Text textId={GlobalText.SubmitImage} />
					</Button>
				</Stack>
			</Stack>
		</div>
	);
};

export default FileDropArea;
