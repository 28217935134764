import React, {
	useState,
	useRef,
	useEffect,
	useCallback,
	useMemo,
} from "react";
import _ from "lodash";
import { FFMultiSelect, AudioUtils } from "funkis-foundation";
import QuizCard from "./core/QuizCard";
import { getBootstrapColor } from "../../../utils/colorUtils";
import { objectPropsToCamelCase } from "../../../utils/objectUtils";
import QuizOption from "./core/QuizOption";
import TextWriter from "../../core/TextWriter";
import QuizScoreCounter from "./core/QuizScoreCounter";
import QuizIndex from "./core/QuizIndex";
import QuizQuestionScoreBoard from "./core/QuizQuestionScoreBoard";
import QuizTimer from "./core/QuizTimer";
import QuizImage from "./core/QuizImage";
import { getText } from "../../../utils/textUtils";

const State = {
	IDLE: "idle",
	SHOW_QUESTION: "show_question",
	RUN_TIMER: "run_timer",
	OPTION_SELECTED: "option_selected",
	OPTION_COMPLETE: "option_complete",
	COMPLETE: "complete",
};

const isCorrect = (selectedIndexes, correctIndexes, compare = "includes") => {
	if (compare === "includes") {
		return _.intersection(selectedIndexes, correctIndexes).length > 0;
	}

	if (compare === "isEqual") {
		return _.isEqual(selectedIndexes.sort(), correctIndexes.sort());
	}
};

const QuizQuestion = (props) => {
	const {
		show,
		contentArray,
		randomizeOptions,
		question,
		duration,
		media,
		pointsMin,
		pointsMax,
		mediaSize = "contain",
		mediaAlign = "center",
		backgroundColor = "transparent",
		baseColor = "primary",
		selectedIndexes = [],
		onSelect,
		onComplete,
		index,
		numQuestions,
		score,
		visibleScore,
		setVisibleScore,
		points,
		contentHeight = "auto",
		contentMinHeight = "auto",
		interactionColor = "interaction",
		musicVolume = 1,
		musicStopOnAnswer = true,
		builtInSounds = true,
	} = props;

	const refOptions = useRef(null);
	const [state, setState] = useState(State.IDLE);

	const [options] = useState(
		randomizeOptions === "yes" ? _.shuffle(contentArray) : contentArray
	);
	const complete = selectedIndexes && selectedIndexes.length > 0;

	const correctIndexes = options
		.map((item, index) => (item.correct ? index : undefined))
		.filter((item) => item !== undefined);

	useEffect(() => {
		if (!correctIndexes.length) {
			alert(`No correct answer found for question "${getText(question)}"`);
		}
	}, [correctIndexes.length]);

	// WRITE QUESTION
	const onCardShowHandler = () => {
		setState(State.WRITE_QUESTION);
	};

	// RUN TIMER
	const onQuestionWriteComplete = () => {
		setTimeout(() => {
			setState(State.RUN_TIMER);
			builtInSounds && AudioUtils.play(`quiz-arpeggio-${_.random(1, 3)}`);
			AudioUtils.play(`quiz-music`, { delay: 500 }).fade(0, musicVolume, 2000);
		}, 2000);
	};

	const [time, setTime] = useState(0);

	// ON TIME UPDATE
	const onTimeHandler = useCallback((e) => {
		setTime(e.time);
	}, []);

	// ON OPTION SELECTED
	const onSelectHandler = (indexes) => {
		musicStopOnAnswer && AudioUtils.stop("quiz-music");
		builtInSounds && AudioUtils.play("quiz-click");

		setState(State.OPTION_SELECTED);

		const correct = isCorrect(indexes, correctIndexes);
		const timeValue = duration === 0 ? 1 : 1 - time / duration;

		builtInSounds &&
			AudioUtils.play(correct ? "quiz-answer-right" : "quiz-answer-wrong", {
				delay: 300,
			});

		onSelect &&
			onSelect({
				...props,
				options,
				selectedIndexes: indexes,
				time,
				points: correct
					? Math.round(timeValue * (pointsMax - pointsMin)) + pointsMin
					: 0,
				correct,
			});
	};

	// ON OPTION COMPLETE
	const onOptionCompleteHandler = () => {
		setState(State.OPTION_COMPLETE);
		// onComplete && onComplete();
	};

	const onCompleteTimeoutRef = useRef(null);
	useEffect(() => {
		return () => {
			clearTimeout(onCompleteTimeoutRef.current);
		};
	}, []);

	const onScoreBoardComplete = useCallback(() => {
		setVisibleScore(score);
		// 2 sec delay...
		onCompleteTimeoutRef.current = setTimeout(() => {
			setState(State.COMPLETE);
			onComplete && onComplete();
		}, 2000);
	}, [setVisibleScore, onComplete, setState, score]);

	const showQuestion = state !== State.IDLE;
	const showQuestionImage =
		state !== State.OPTION_SELECTED && state !== State.OPTION_COMPLETE;
	const showOptions = [
		State.RUN_TIMER,
		State.OPTION_SELECTED,
		State.OPTION_COMPLETE,
		State.COMPLETE,
	].includes(state);

	const camelCasedOptions = useMemo(
		() =>
			options.map((opt) => {
				return objectPropsToCamelCase(opt);
			}),
		[options]
	);

	return (
		<QuizCard
			show={show}
			cardContext="question"
			delimitters
			onShow={onCardShowHandler}
			contentHeight={contentHeight}
			contentMinHeight={contentMinHeight}
			baseColor={baseColor}
			backgroundColor={backgroundColor}
		>
			<div className="h-100 d-flex flex-column justify-content-stretch">
				{/* TOP ROW */}
				<div className="d-flex justify-content-between flex-grow-0 pt-2">
					{/* INDEX */}
					<QuizIndex
						index={index}
						total={numQuestions}
						score={score}
						baseColor={baseColor}
					/>
					{/* SCORE COUNTER */}
					<QuizScoreCounter
						key={question + index}
						score={visibleScore}
						baseColor={baseColor}
					/>
				</div>
				{/* TIMER */}
				<QuizTimer
					duration={duration}
					state={(state === State.RUN_TIMER && "playing") || "paused"}
					onTime={onTimeHandler}
					baseColor={baseColor}
					className="animated fadeIn flex-grow-0"
				/>
				{/* QUESTION & MEDIA */}
				<div className="py-3 flex-grow-0" style={{ height: "60px" }}>
					{showQuestion && (
						<TextWriter
							textId={question}
							textIdPrefix="9D6859D4-FF2D-4AB7-8981-72166225756D"
							className="p-medium font-weight-bold quiz-question-text"
							classNamePrefix="p-medium font-weight-bold quiz-question-text-label"
							style={{ color: getBootstrapColor(baseColor) }}
							delay={10}
							onComplete={onQuestionWriteComplete}
						/>
					)}
				</div>
				<div
					ref={refOptions}
					className="d-flex flex-column justify-content-end flex-grow-0"
				>
					{/* IMAGE*/}
					<QuizImage
						className="mb-3 flex-shrink-1 mt-5"
						src={media}
						align={mediaAlign}
						size={mediaSize}
						show={showQuestionImage}
					/>
					{/* OPTIONS*/}
					<FFMultiSelect
						className="d-flex flex-wrap mb-2"
						buttonClassName="w-50"
						toggle={false}
						maxSelections={1}
						minSelections={1}
						selectedIndexes={selectedIndexes}
						disableWhenMaxSelectionsReached={true}
						autoDeselectWhenMaxSelectionsReached={true}
						alwaysTriggerOnSelect={true}
						onSelect={onSelectHandler}
						enabled={showOptions}
						/* correctIndexes not used for correcting, just for getting debugging classnames */
						correctIndexes={correctIndexes}
					>
						{camelCasedOptions.map((option, index) => (
							<QuizOption
								{...{
									baseColor,
									interactionColor,
									show: showOptions,
									showDelay: index * 0.1,
									key: `muti-select-${index}`,
									style: { padding: "0.125rem" },
									index,
									complete,
									selected: selectedIndexes.includes(index),
									correct: isCorrect([index], correctIndexes),
									onComplete: onOptionCompleteHandler,
									...option,
								}}
							/>
						))}
					</FFMultiSelect>
				</div>
				{/* SCORE BOARD */}

				<QuizQuestionScoreBoard
					show={state === State.OPTION_COMPLETE}
					onComplete={onScoreBoardComplete}
					points={points}
					baseColor={baseColor}
				/>
			</div>
		</QuizCard>
	);
};

export default QuizQuestion;
