import React from "react";
import classnames from "classnames";
import { FFText } from "funkis-foundation";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";
import Text from "../../../components/core/Text";

import Style from "./PageItemBullets.module.css";
import Bullet from "../../../components/core/Bullet";

const MODES = {
	STANDARD: "standard",
	EMPHASIZED: "emphasized",
};

const PageItemBullets = (props) => {
	const { pageItem } = props;
	const { cmtPageItem = {} } = pageItem;
	const header = cmtPageItem.header;
	const body = cmtPageItem.body;
	const generalBulletImage = cmtPageItem.bullet;
	const bulletColor = cmtPageItem.bullet_color || "primary";
	const bullets = !generalBulletImage
		? cmtPageItem.content_array
		: cmtPageItem.content_array.map((bullet) => ({
				...bullet,
				image: generalBulletImage,
		  }));
	const mode = cmtPageItem.mode;

	return (
		<PageItemScrollBase
			{...props}
			className={Style.root}
			showNextButton={true}
			renderFunction={(renderProps) => (
				<div className="row m-0">
					{/* HEADER */}
					<Text
						className={classnames(
							"col-sm-12 mb-5 animated font-weight-bold",
							renderProps.viewVisible ? "fadeIn" : "fadeOut"
						)}
						tagName="h2"
						textId={header}
					/>
					{/* BODY */}
					<Text
						className={classnames(
							"p-medium col-sm-9 mb-4 animated",
							renderProps.viewVisible ? "dealy-250ms fadeIn" : "fadeOut"
						)}
						tagName="p"
						textId={body}
					/>
					<div className="col-sm-12">
						<ul className="list-style-none p-0 m-0">
							{bullets.map((bullet, index) => (
								<li
									key={index}
									className={classnames(
										"d-flex align-items-center flex-nowrap animated mb-3",
										renderProps.viewVisible ? "fadeInUp" : "fadeOut"
									)}
									style={{ animationDelay: 0.5 + index * 0.15 + "s" }}
								>
									<div className={classnames("d-flex", Style.bullet)}>
										<Bullet
											image={cmtPageItem.bullet || bullet.image}
											mode={mode}
											color={bulletColor}
											size={cmtPageItem.bullet_size}
										/>
									</div>
									<div
										className={classnames(
											mode != MODES.EMPHASIZED &&
												!bullet.image &&
												Style.standardBulletTextAdjust,
											bullet.image ? "pt-3" : "pt-0"
										)}
									>
										<FFText
											textId={bullet.text}
											className={classnames(
												"my-2",
												mode != MODES.EMPHASIZED
													? "p-medium"
													: "h4 font-weight-bold"
											)}
										/>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			)}
		/>
	);
};

export default PageItemBullets;
