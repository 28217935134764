import React, { useEffect } from "react";
import classnames from "classnames";
import {
	scrollPageItemToTop,
	scrollPageItemToBottom,
} from "../../actions/Actions";
import PropTypes from "prop-types";
import Style from "./SimpleReveal.module.css";

const SimpleReveal = ({
	reveal,
	children,
	pageItem,
	className,
	border,
	autoScroll,
	scrollTo,
	style,
}) => {
	useEffect(() => {
		if (reveal && autoScroll && !pageItem) {
			throw new Error(
				"Prop pageItem must be defined when autoScroll is set to true!"
			);
		}

		if (reveal && autoScroll && pageItem) {
			setTimeout(() => {
				switch (scrollTo) {
					case "top":
						scrollPageItemToTop({ pageItem });
						// console.log("scrollPageItemToTop");
						break;
					case "bottom":
						scrollPageItemToBottom({ pageItem, ignoreError: true });
						// console.log("scrollPageItemToBottom");
						break;
					default:
						throw new Error(`scrollTo ${scrollTo} is not supported!`);
				}
			}, 500);
		}
	}, [reveal]);

	return (
		<div
			className={classnames(
				Style.root,
				reveal ? `revealed ${Style.reveal}` : Style.unreveal,
				className
			)}
			style={style}
		>
			{border && (
				<div className="container-fluid">
					<div className="border-divider row m-0" />
				</div>
			)}
			{children}
		</div>
	);
};

SimpleReveal.propTypes = {
	reveal: PropTypes.bool,
	pageItem: PropTypes.object,
	className: PropTypes.string,
	border: PropTypes.bool,
	autoScroll: PropTypes.bool,
	scrollTo: PropTypes.oneOf(["top", "bottom"]),
	style: PropTypes.object,
};

SimpleReveal.defaultProps = {
	border: false,
	autoScroll: false,
	scrollTo: "bottom",
};

export default SimpleReveal;
