import React, { useRef } from "react";
import classNames from "classnames";
import styled from "styled-components";
import { getText } from "../../utils/textUtils";

const Root = styled.div``;

const TextInputStyled = styled.input`
	height: 3rem;
	/* use fixed font-size here because iOS zooms in on input if font-size is less than 16px */
	font-size: 16px;
	line-height: 1.5;
	padding: 0;
	padding-inline-start: 0.75rem;
	padding-inline-end: 0.75rem;
	border: 1px solid #929292;
	border-radius: 4px;
	transition: box-shadow 100ms cubic-bezier(0.4, 0, 0.4, 1),
		border 100ms cubic-bezier(0.4, 0, 0.4, 1);

	&:focus {
		outline: 2px solid #0058a3;
		/* "Skapa" used border (not outline) on a separate absolutely positioned element. */
		outline-offset: -2px;
	}
`;

const LabelStyled = styled.label`
	font-size: 0.875rem;
	line-height: 1.571;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0 0 0.125rem 0;
	width: auto;
`;

const ButtonAdd = styled.button``;

const FormStyled = styled.form`
	padding: 0.25rem !important;
`;

const TraitInput = ({
	value,
	color,
	className,
	onTextInputChange,
	onAdd,
	addDisabled = false,
	label,
	labelFor,
	confirmButtonTextId,
	propsInput = {},
}) => {
	const inputRef = useRef(null);

	return (
		<Root className={classNames("d-flex flex-column w-100", className)}>
			<FormStyled className="d-flex col-md-8 flex-row">
				<div className="col-md-6 p-0 pr-1">
					{label && (
						<LabelStyled htmlFor="custom_trait_input" className="">
							{label}
						</LabelStyled>
					)}
					<TextInputStyled
						ref={inputRef}
						id="custom_trait_input"
						className="w-100"
						defaultValue={value || undefined}
						value={value}
						maxLength={25}
						onChange={onTextInputChange}
						name={labelFor}
						{...propsInput}
					/>
				</div>
				{/* ADD BUTTON */}
				<ButtonAdd
					// all these classes simulates same theme overrides we had for the "next"-button
					className="ff-button btn btn-black rounded-pill col-sm-4 m-4 mx-md-6 button-small p-1"
					type="submit"
					color={color}
					disabled={addDisabled}
					onClick={(e) => {
						onAdd && onAdd(e);
						inputRef?.current?.focus();
					}}
				>
					{getText(confirmButtonTextId)}
				</ButtonAdd>
			</FormStyled>
		</Root>
	);
};

export default TraitInput;
