import { toLower } from "lodash";

const stripCountryCode = (langCode: string) => langCode.split("-").at(0) ?? "";

export const selectClosestNavigatorLanguage = (
	installedLanguages: string[]
) => {
	const bestMatch = navigator.languages.reduce(
		(
			matchedLanguage: string | null,
			navigatorLanguage: string
		): string | null => {
			if (matchedLanguage) return matchedLanguage;

			/**
			Will first attempt to do an exact match between language codes.
			E.g If navigator language is `pt` it will *not* match installed language `pt-BR`
			 **/
			const exactMatch = installedLanguages.find(
				(installedLanguage) =>
					toLower(installedLanguage) === toLower(navigatorLanguage)
			);

			if (exactMatch) {
				return exactMatch;
			}

			/**
			We will then try to do a check without country codes.
			E.g If navigator language is `pt` it will match installed language `pt-BR` since 
			we strip the country code "BR" from the comparison.
			 **/
			const langCodeMatch = installedLanguages.find(
				(installedLanguage) =>
					stripCountryCode(installedLanguage) ===
					stripCountryCode(navigatorLanguage)
			);

			if (langCodeMatch) {
				return langCodeMatch;
			}

			return null;
		},
		null
	);

	return bestMatch;
};
