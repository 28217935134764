import LocalStorage from "./storages/LocalStorage";
import SessionStorage from "./storages/SessionStorage";
import Scorm12Storage from "./storages/Scorm12Storage";
import Scorm2004Storage from "./storages/Scorm2004Storage";
import NoStorage from "./storages/NoStorage";


const localStorage = new LocalStorage();
const sessionStorage = new SessionStorage();
const scorm12Storage = new Scorm12Storage();
const scorm2004Storage = new Scorm2004Storage();
const noStorage = new NoStorage();

let _storages = {
	local: localStorage,
	localStorage: localStorage,
	session: sessionStorage,
	sessionStorage: sessionStorage,
	scorm12: scorm12Storage,
	scorm12Storage: Scorm12Storage,
	scorm2004: scorm2004Storage,
	scorm2004Storage: scorm2004Storage,
	empty: noStorage,
	none: noStorage,
};

export function getStorage(storageName) {
	const storage = _storages[storageName];
	if (!storage) {
		console.error(`Storage with name ${storageName} is not a valid storage!`);
	}
	return storage;
}

export function addStorage(storageName, storage) {
	_storages[storageName] = storage;
}
