
import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	removeItemInStateItems,
} from "../utils/reducerUtils";

import {
	UPDATE_QUIDDITY_ORGANIZATIONS,
	UPDATE_QUIDDITY_ORGANIZATION,
	REMOVE_QUIDDITY_ORGANIZATION,
} from "../constants/ActionConstants";

const quiddityEvents = (state = initialStateObjectCollection(), action) => {
	switch (action.type) {
		case UPDATE_QUIDDITY_ORGANIZATIONS:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_QUIDDITY_ORGANIZATION:
			return updateItemInStateItems(state, action.item);
		case REMOVE_QUIDDITY_ORGANIZATION:
			return removeItemInStateItems(state, action.item);
		default:
			return state;
	}
};

export default quiddityEvents;
