import React from "react";
import _ from "lodash";
import Backend from "../backend/Backend";
import { StoreUtils } from "funkis-foundation";
import Badge from "./Badge";

const filterByPage = (quidInputs, pageId) => {
	if (pageId) {
		return quidInputs.filter((item) => _.get(item, "data.pageId") === pageId);
	} else {
		return quidInputs;
	}
};

const filterByChapter = (quidInputs, chapterId) => {
	if (chapterId) {
		return quidInputs.filter(
			(item) => _.get(item, "data.chapterId") === chapterId
		);
	} else {
		return quidInputs;
	}
};

const filterBadges = (quidInputs, mode) => {
	if (!quidInputs || !quidInputs.length) {
		return [];
	}

	if (mode === "count") {
		const groups = _.groupBy(quidInputs, "data.id");
		const groupKeys = _.keys(groups);
		return groupKeys.map((key) => ({ id: key, count: groups[key].length }));
	}

	if (mode === "array") {
		return quidInputs.map((item) => ({ id: _.get(item, "data.id"), count: 1 }));
	}
};

const IOBadgeOutput = ({
	chapterId,
	pageId,
	inputContext = "member",
	targetDataKey,
	className,
	backgroundColor = "white",
	backgroundColorBadgeCounter = "white",
	colorBadgeCounter = "black",
	padding = "3px",
	size = "25px",
	mode = "count", // count || array
	iconStyle,
}) => {
	return (
		<Backend
			className={className}
			quidType="badge"
			inputContext={inputContext}
			throttle={true}
			throttleWait={5000}
			mode="bareOutputs"
			targetDataKey={targetDataKey}
			renderFunction={(renderProps) => {
				const { quidInputIds } = renderProps;
				const quidInputsById = StoreUtils.getReducer("quidInputs").itemsById;
				const quidInputs = quidInputIds.map((id) => quidInputsById[id]);
				const badges = filterBadges(
					filterByPage(filterByChapter(quidInputs, chapterId), pageId),
					mode
				);

				return badges.map((badge) => (
					<Badge
						imageUrl={`./content/images/badge-${badge.id}.png`}
						className="mr-1"
						backgroundColor={backgroundColor}
						backgroundColorCounter={backgroundColorBadgeCounter}
						colorCounter={colorBadgeCounter}
						size={size}
						padding={padding}
						count={badge.count}
						showCount={mode === "count"}
						style={iconStyle}
					/>
				));
			}}
		/>
	);
};

export default IOBadgeOutput;
