import _ from "lodash";
import {
	createSymbolPageItems,
	mapSymbolPageItems,
	getCMTContainerScheme,
	createContainerPageItems,
	createPageItemsForPage,
} from "../utils/SymbolUtils";
import { asyncForEach, forEachReverse } from "../../utils/ArrayUtils";
import { getReducer } from "../../utils/StoreUtils";

export const parseCMTPageItems = ({
	cmtParseSchemes,
	cmtPageItems,
	cmtPages,
	cmtTexts,
}) => {
	// console.log("cmtPageItems", cmtPageItems.map(p => p.parent_id));
	const oldPageItems = getReducer("pageItems").items.filter(
		(pi) => pi.data.diff
	);
	const oldPageItemIds = oldPageItems.map((pi) => pi.id);
	const oldPageItemsById = createItemsById(oldPageItems);
	const pageItemById = getReducer("pageItems").itemsById;

	// Settings
	const settings = cmtParseSchemes.find(
		(cmtParseScheme) => cmtParseScheme.settings
	);
	const { hideMissingContent } = settings ? settings.settings : {};

	const promises = [];

	cmtPages.forEach((cmtPage) => {
		const cmtPageItemsForPage = cmtPageItems.filter(
			(cmtPageItem) => cmtPageItem.parent_id === cmtPage.id
		);
		let rootPageItems = [];
		let pageItems = [];

		const promise = asyncForEach(
			cmtPageItemsForPage,
			(cmtPageItem, index) => {
				// console.log("cmtPageItem parent page", cmtPageItem.parent_id, cmtPageItem.sort_index);
				let symbolPageItems = createSymbolPageItems({
					cmtParseSchemes,
					cmtPageItem,
				});
				// Map symbolPageItems
				symbolPageItems = mapSymbolPageItems({
					cmtParseSchemes,
					cmtPageItem,
					symbolPageItems,
					cmtTexts,
					hideMissingContent,
				});
				// find container scheme
				const cmtContainerScheme = getCMTContainerScheme({
					cmtParseSchemes,
					cmtPageItem,
				});
				// Create container page items
				const containerPageItems = createContainerPageItems({
					cmtContainerScheme,
					cmtPageItem,
				});
				// Concat page items
				if (cmtContainerScheme && cmtContainerScheme.type === "addParent") {
					pageItems = _.flatten([
						pageItems,
						containerPageItems,
						symbolPageItems || [],
					]);
				} else if (
					cmtContainerScheme &&
					cmtContainerScheme.type === "addNext"
				) {
					pageItems = _.flatten([
						pageItems,
						symbolPageItems || [],
						containerPageItems,
					]);
				} else {
					//  type === "addSelf" || undefined
					pageItems = _.flatten([
						pageItems,
						containerPageItems,
						symbolPageItems || [],
					]);
				}

				// Merge created page items with store page items to make sure updates from Designer is applied
				pageItems = pageItems.map((pageItem) => {
					const storePageItem = oldPageItemsById[pageItem.id];
					const storePageItemData = Object.assign(
						{},
						pageItem.data,
						storePageItem && storePageItem.data
					);
					return Object.assign(
						{},
						pageItem,
						_.omit(storePageItem, ["parentPageItemId", "parentPageId"]),
						{
							data: storePageItemData,
						}
					);
				});
			},
			() => {
				// Create navigation and chapter title page items for each section page
				let chapterTitlePageItems = [];
				let navigationPageItems = [];

				if (cmtPage.type === "page" && cmtPage.page_type !== "main menu") {
					const cmtSecionPages = cmtPages.filter(
						(cmtSecionPage) => cmtSecionPage.parent_id === cmtPage.parent_id
					);
					const lastSectionPage = cmtSecionPages[cmtSecionPages.length - 1];
					const isLastSectionPage = cmtPage.id === lastSectionPage.id;

					if (!isLastSectionPage) {
						navigationPageItems = navigationPageItems.concat(
							createPageItemsForPage({
								cmtPage: cmtPage,
								symbolFriendlyId: "buttonNaviageToNextSection",
							})
						);
					}

					// Add navigation/footer for every page (bottom) where it's not explicitly disabled
					if (
						cmtPage.page_footer_navigation !== "none" ||
						cmtPage.page_footer_navigation === undefined
					) {
						navigationPageItems = navigationPageItems.concat(
							createPageItemsForPage({
								cmtPage: cmtPage,
								symbolFriendlyId: "buttonNavigateToMainMenu",
							})
						);
					}
					// Add chapter titles for each page
					chapterTitlePageItems = createPageItemsForPage({
						cmtPage: cmtPage,
						symbolFriendlyId: "cmtChapterTitle",
					});

					chapterTitlePageItems = mapSymbolPageItems({
						cmtParseScheme: cmtParseSchemes.find(
							(scheme) => scheme.symbolFriendlyId === "chapterTitle"
						),
						cmtPageItem: cmtPage,
						symbolPageItems: chapterTitlePageItems,
					});
					const rootChapterTitlePageItem = chapterTitlePageItems[0];
					rootChapterTitlePageItem &&
						(rootChapterTitlePageItem.parentPageId = cmtPage.id);
				}

				// Add top bar to root page
				if (!cmtPage.type) {
					// Add top bar to root page
					navigationPageItems = navigationPageItems.concat(
						createPageItemsForPage({
							cmtPage: cmtPage,
							symbolFriendlyId: "TopBar",
						})
					);
				}

				pageItems = pageItems.concat(navigationPageItems);
				pageItems = chapterTitlePageItems.concat(pageItems);

				const pageItemIds = pageItems.map((pi) => pi.id);
				const diffPageItemIds = _.difference(oldPageItemIds, pageItemIds);
				// Create updated hierarchy
				updateHierarchy({ pageItems });
				return { pageItems, diffPageItemIds };
			}
		);

		promises.push(promise);
	});

	return new Promise((resolve) => {
		Promise.all(promises).then((results) => {
			const result = { pageItems: [], diffPageItemIds: [] };
			results.forEach((res) => {
				result.pageItems = result.pageItems.concat(res.pageItems);
				result.diffPageItemIds = result.diffPageItemIds.concat(
					res.diffPageItemIds
				);
			});

			resolve(result);
		});
	});
};

const updateHierarchy = ({ pageItems }) => {
	let childPageItems = [];
	let rootPageItems = pageItems.filter((pi) => pi.data.isRootPageItem);
	const linksFriendlyIds = ["link-docfactory", "link-external"];

	//-------------------------//
	// PLACE LINKS IN CONTAINERS;
	forEachReverse(rootPageItems, (item, index, prevItem, nextItem) => {
		// page change
		if (prevItem && prevItem.parentPageId != item.parentPageId) {
			childPageItems = [];
		}
		// if links

		if (linksFriendlyIds.includes(item.friendlyId)) {
			childPageItems.push(item);
		}
		// if links container
		if (item.friendlyId === "link-section") {
			let { containerPageItemId, cmtContainerScheme } = item.data;
			if (containerPageItemId) {
				{
					childPageItems.forEach((childPageItem) => {
						childPageItem.parentPageId = null;
						childPageItem.parentPageItemId = containerPageItemId;
					});
				}
				childPageItems = [];
			}
		}
	});

	// Remove links from rootPageItems
	rootPageItems = rootPageItems.filter(
		(rootPageItem) => !linksFriendlyIds.includes(rootPageItem.friendlyId)
	);

	//--------------------------------//
	// PLACE ROOT PAGE ITEMS IN REVEALS
	forEachReverse(rootPageItems, (item, index, prevItem, nextItem) => {
		// page change
		if (prevItem && prevItem.parentPageId != item.parentPageId) {
			childPageItems = [];
		}
		// if not reveal
		if (item.friendlyId !== "reveal") {
			childPageItems.push(item);
		}
		// if reveal
		if (item.friendlyId === "reveal") {
			let { containerPageItemId, cmtContainerScheme } = item.data;
			if (containerPageItemId) {
				{
					childPageItems.forEach((childPageItem) => {
						childPageItem.parentPageId = null;
						childPageItem.parentPageItemId = containerPageItemId;
					});
				}
				childPageItems = [];
			}
		}
	});

	return pageItems;
};

const createItemsById = (items) => {
	const itemsById = {};
	items.forEach((item) => {
		itemsById[item.id] = item;
	});
	return itemsById;
};
