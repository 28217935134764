import canAutoPlay from "can-autoplay";
import { useEffect, useMemo, useState } from "react";

export const useMediaPlayabilityStatus = ({skip} = {skip: false}) => {
	const [canAutoplayVideoWithSound, setCanAutoplayVideoWithSound] =
		useState(undefined);
	const [canAutoplayVideoMuted, setCanAutoplayVideoMuted] = useState(undefined);
	const [canAutoplaySound, setCanAutoplaySound] = useState(undefined);

	useEffect(() => {
		if(skip) return;
		canAutoPlay.video({ muted: false }).then(({ result, error }) => {
			if (result === false) {
				console.warn("Error did occur, trying video play with sound: ", error);
				setCanAutoplayVideoWithSound(false);
				return;
			}
			setCanAutoplayVideoWithSound(true);
		});

		canAutoPlay.video({ muted: true }).then(({ result, error }) => {
			if (result === false) {
				console.warn("Error did occur, trying video play muted: ", error);
				setCanAutoplayVideoMuted(false);
				return;
			}
			setCanAutoplayVideoMuted(true);
		});

		canAutoPlay.audio().then(({ result, error }) => {
			if (result === false) {
				console.warn("Error did occur, trying audio play: ", error);
				setCanAutoplaySound(false);
				return;
			}
			setCanAutoplaySound(true);
		});
	}, [skip]);

	const isLoading = [
		canAutoplayVideoWithSound,
		canAutoplayVideoMuted,
		canAutoplaySound,
	].some((status) => status === undefined);
	const state = useMemo(() => {
		return {
			isLoading,
			canAutoplaySound,
			canAutoplayVideoMuted,
			canAutoplayVideoWithSound,
		};
	}, [
		isLoading,
		canAutoplaySound,
		canAutoplayVideoMuted,
		canAutoplayVideoWithSound,
	]);

	return state;
};
