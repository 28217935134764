import MissingIOError from "errors/MissingIOError";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { QuidType, useBackend } from "funkis-template/hooks/backend";
import { CommentInputScrollBlock } from "funkis-template/models/pageitem";
import { PageItem } from "funkis-template/models/player";
import CommentInput, {
	CommentValue,
} from "funkis-template/pageitems/slide/CommentInputSingle/CommentInput";
import { isQuidInputValidComment } from "funkis-template/pageitems/slide/CommentInputSingle/PageItemCommentInputSingle.utils";
import { getBootstrapColor } from "funkis-template/utils/colorUtils";
import { FC } from "react";
import Text from "funkis-template/components/core/Text";
import Image from "funkis-template/components/core/Image";
import Stack from "components/Stack/Stack";
import classnames from "classnames";

const PageItemCommentInput: FC<{
	pageItem: PageItem<CommentInputScrollBlock>;
}> = (props) => {
	const {
		pageItem: { cmtPageItem },
	} = props;

	if (!cmtPageItem.ios.comments) {
		throw new MissingIOError("comments", cmtPageItem.symbol_name);
	}

	const { quidInputs, updateQuidInput, createQuidInput, isSuccess } =
		useBackend({
			quidType: QuidType.Comment,
			inputContext: cmtPageItem.ios.comments.context,
			targetDataKey: cmtPageItem.ios.comments.id,
		});

	const commentQuidInput = quidInputs.at(0);
	const isCommentValid = isQuidInputValidComment(commentQuidInput, cmtPageItem);

	const shouldShowNextButton =
		cmtPageItem.show_next_button === "always" ||
		(cmtPageItem.show_next_button === "auto" && isCommentValid);

	return (
		<PageItemScrollBase {...props} showNextButton={shouldShowNextButton}>
			<div className="row m-0">
				<Stack>
					{cmtPageItem.image && (
						<Image
							src={cmtPageItem.image}
							className="col-12"
							maxHeight={undefined}
							size={undefined}
							align={undefined}
							children={undefined}
							autoSize={undefined}
							background={undefined}
							isZoomable={undefined}
						/>
					)}
					<Text className="col-12" tagName="h2" textId={cmtPageItem.header} />
					<Text className="col-sm-9" tagName="p" textId={cmtPageItem.body} />
					<Text
						className="col-sm-9 instruction font-italic"
						textId={cmtPageItem.instruction}
					/>

					{isSuccess ? (
						<div className="row m-0">
							<div
								className={classnames("col-12", {
									"col-md-8": cmtPageItem.desktop_width === "column",
								})}
							>
								<CommentInput
									initialHeaderValue={commentQuidInput?.data.header}
									initialBodyValue={commentQuidInput?.data.body}
									labelHeader={cmtPageItem.header_label}
									labelBody={cmtPageItem.body_label}
									backgroundColor={
										cmtPageItem.input_background_color !== ""
											? getBootstrapColor(cmtPageItem.input_background_color)
											: undefined
									}
									textColor={
										cmtPageItem.input_text_color !== ""
											? getBootstrapColor(cmtPageItem.input_text_color)
											: undefined
									}
									rowsHeader={cmtPageItem.rows_input_header}
									rowsBody={cmtPageItem.rows_input_body}
									maxCharactersHeader={cmtPageItem.max_char_input_header}
									maxCharactersBody={cmtPageItem.max_char_input_body}
									onFinished={function (value: CommentValue): void {
										if (commentQuidInput) {
											updateQuidInput({
												id: commentQuidInput.id,
												data: {
													...commentQuidInput,
													header: value.header,
													body: value.body,
												},
											});
										} else {
											createQuidInput({
												data: {
													header: value.header,
													body: value.body,
												},
											});
										}
									}}
								/>
							</div>
						</div>
					) : undefined}
				</Stack>
			</div>
		</PageItemScrollBase>
	);
};

export default PageItemCommentInput;
