import React from "react";
import classnames from "classnames";
import posed from "react-pose";
import ReactResizeDetector from "react-resize-detector";

import { updatePageItem } from "../redux/actions/PageItemActions";
import { COMPLETED, INCOMPLETE } from "../constants/StatusConstants";

const RevealConstants = {
	VERTICAL: "vertical",
	HORIZONTAL: "horizontal",
	OPACITY: "opacity",
	INSTANT: "instant",
};

const RevealVertical = posed.div({
	visible: {
		height: ({ size, sizeVisible }) => sizeVisible.height || size.height,
		transition: ({
			revealTransition,
			initialTransition,
			initialTransitionComplete,
		}) => (initialTransitionComplete ? revealTransition : initialTransition),
	},
	hidden: {
		height: ({ sizeHidden }) => sizeHidden.height || 0,
		transition: ({
			unrevealTransition,
			initialTransition,
			initialTransitionComplete,
		}) => (initialTransitionComplete ? unrevealTransition : initialTransition),
	},
});

const RevealHorizontal = posed.div({
	visible: {
		width: ({ size, sizeVisible }) => sizeVisible.width || size.width,
		transition: ({
			revealTransition,
			initialTransition,
			initialTransitionComplete,
		}) => (initialTransitionComplete ? revealTransition : initialTransition),
	},
	hidden: {
		width: ({ sizeHidden }) => sizeHidden.width || 0,
		transition: ({
			unrevealTransition,
			initialTransition,
			initialTransitionComplete,
		}) => (initialTransitionComplete ? unrevealTransition : initialTransition),
	},
});

const RevealOpacity = posed.div({
	visible: {
		opacity: 1,
		transition: ({
			revealTransition,
			initialTransition,
			initialTransitionComplete,
		}) => (initialTransitionComplete ? revealTransition : initialTransition),
	},
	hidden: {
		opacity: 0,
		transition: ({
			unrevealTransition,
			initialTransition,
			initialTransitionComplete,
		}) => (initialTransitionComplete ? unrevealTransition : initialTransition),
	},
});

const RevealInstant = (props) => {
	const { reveal } = props;
	return (
		<div style={props.style} className={props.className}>
			{reveal && props.children}
		</div>
	);
};

class PageItemReveal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			enabled: true,
			size: { width: 0, height: 0 },
			revealed: false,
			initialTransitionComplete: false,
		};
	}

	UNSAFE_componentWillMount() {
		const { pageItem } = this.props;
		const { disableIfRevealed, reveal } = pageItem;
		const disabled = disableIfRevealed && reveal;
		this.setState({ revealed: reveal, enabled: !disabled });
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.pageItem.reveal && this.props.pageItem.reveal) {
			this.setState({ revealed: true });
		}

		if (
			prevProps.pageItem.status !== this.props.pageItem.status &&
			this.props.pageItem.status === "incomplete"
		) {
			this.setState({ revealed: false });
		}
	}

	getRevealElement = (type) => {
		switch (type) {
			case RevealConstants.VERTICAL:
				return RevealVertical;
			case RevealConstants.HORIZONTAL:
				return RevealHorizontal;
			case RevealConstants.OPACITY:
				return RevealOpacity;
			case RevealConstants.INSTANT:
				return RevealInstant;
				break;
			default:
				return RevealOpacity;
		}
	};

	_onResize = (width, height) => {
		const { pageItem } = this.props;
		const { initialTransition, styleCompleted, styleIncomplete } = pageItem;
		this.setState({ size: { width, height } });
	};

	_onPoseComplete = () => {
		const { pageItem, updateStatus = true } = this.props;
		const { revealed } = this.state;
		let { initialTransition, styleCompleted, styleIncomplete } = pageItem;

		const newState = { initialTransitionComplete: true, revealed: true };
		// together with `withParent={false}`, this seems to make it possible to nest Reveals within Reveals
		if (pageItem.revealType === RevealConstants.VERTICAL) {
			styleIncomplete = styleCompleted || { height: 0 };
			styleCompleted = styleCompleted || {
				height: "auto",
				overflow: "visible",
			};
			newState.stateStyleOverride = pageItem.reveal
				? styleCompleted
				: revealed
				? styleIncomplete
				: {};
		}

		if (pageItem.revealType === RevealConstants.HORIZONTAL) {
			styleIncomplete = styleCompleted || { width: 0 };
			styleCompleted = styleCompleted || { width: "auto" };
			newState.stateStyleOverride = pageItem.reveal
				? styleCompleted
				: revealed
				? styleIncomplete
				: {};
		}

		if (!pageItem.reveal) {
			newState.revealed = false;
		}

		if (
			!this.state.initialTransitionComplete &&
			initialTransition &&
			initialTransition.duration === 0
		) {
			// skip page item status update
		} else {
			if (pageItem.reveal) {
				updateStatus && updatePageItem(pageItem.id, { status: COMPLETED });
			} else {
				updateStatus && updatePageItem(pageItem.id, { status: INCOMPLETE });
			}
		}

		updatePageItem(pageItem.id, { revealed: newState.revealed });

		this.setState(newState);
	};

	render() {
		const { pageItem, style, className, children, rest } = this.props;
		const {
			size,
			revealed,
			initialTransitionComplete,
			stateStyleOverride,
			enabled,
		} = this.state;
		const {
			unmountChildrenWhenUnrevealed,
			disableIfRevealed,
			sizeVisible = {},
			sizeHidden = {},
			initialTransition = {},
			revealTransition = {},
			unrevealTransition = {},
			containerClassNames = "",
		} = pageItem;

		const RevealElement = this.getRevealElement(
			enabled ? pageItem.revealType : "instant"
		);
		const calcSize = { ...size, ...stateStyleOverride };

		return (
			<RevealElement
				style={Object.assign({}, pageItem.style, style, stateStyleOverride)}
				className={classnames(
					className,
					"page-item page-item-reveal",
					pageItem.className,
					revealed ? "revealed" : "unrevealed"
				)}
				pose={pageItem.reveal && size.height ? "visible" : "hidden"}
				reveal={pageItem.reveal}
				initialPose="hidden"
				size={calcSize}
				withParent={false}
				onPoseComplete={this._onPoseComplete}
				sizeVisible={sizeVisible}
				sizeHidden={sizeHidden}
				initialTransitionComplete={initialTransitionComplete}
				initialTransition={initialTransition}
				revealTransition={revealTransition}
				unrevealTransition={unrevealTransition}
				{...rest}
			>
				<div className={classnames("reveal-container", containerClassNames)}>
					{enabled && (
						<ReactResizeDetector
							handleWidth
							handleHeight
							onResize={this._onResize}
						/>
					)}
					{unmountChildrenWhenUnrevealed && !revealed ? [] : children}
				</div>
			</RevealElement>
		);
	}
}

export default PageItemReveal;
