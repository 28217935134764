import IconCheck from "funkis-template/assets/svg/IconCheck";
import IconCheckIncorrect from "funkis-template/assets/svg/IconCheckIncorrect";
import Text from "funkis-template/components/core/Text";
import { useSelectedLanguage } from "funkis-template/hooks/player";
import { useText } from "funkis-template/hooks/text";
import React, { useEffect, useState } from "react";
import { TextId } from "../../funkis-template/models/player";
import { KnowledgeQuizAnswersModel } from "./KnowledgeQuiz";
import { GlobalText } from "./KnowledgeQuiz.constants";
import {
	KnowledgeQuizQuestionModel,
	KnowledgeQuizQuestionOption,
} from "./KnowledgeQuizQuestion";

function composeListFormatter(languageCode: string): Intl.ListFormat {
	return new Intl.ListFormat(languageCode, {
		style: "long",
		type: "conjunction",
	});
}

function useListFormatter() {
	const selectedLanguage = useSelectedLanguage();
	const selectedLanguageCode = selectedLanguage.split("-")[0];

	const [listFormatter, setListFormatter] = useState(
		composeListFormatter(selectedLanguageCode)
	);

	useEffect(() => {
		setListFormatter(composeListFormatter(selectedLanguageCode));
	}, [selectedLanguageCode]);

	return listFormatter;
}

export type KnowledgeQuizSummaryQuestionProps = {
	question: KnowledgeQuizQuestionModel;
	answeredOption?: KnowledgeQuizQuestionOption;
	questionIndex: number;
	questionsCount: number;
};
const KnowledgeQuizSummaryQuestion: React.FC<KnowledgeQuizSummaryQuestionProps> =
	({ question, questionIndex, questionsCount, answeredOption }) => {
		const correctOptions = question.options.filter((option) => option.correct);

		const correctOptionTextIds = correctOptions.reduce(
			(acc, option) => ({ ...acc, [option.id]: option.textId }),
			{}
		);

		const answeredOptionText = useText(answeredOption?.textId ?? "") || "-";
		const correctOptionsTexts = useText(correctOptionTextIds);
		const listFormatter = useListFormatter();

		const correctOptionsStrings = Object.entries(correctOptionsTexts).map(
			([key, value]) =>
				key === answeredOption?.id ? `<em>"${value}"</em>` : `"${value}"`
		) as string[];

		const correctText = listFormatter.format(correctOptionsStrings);

		const textVariables = {
			answer: answeredOptionText,
			correctAnswer: correctText,
		};

		const isAnswerCorrect = answeredOption?.correct ?? false;

		return (
			<div key={question.id} className="row m-0">
				<div className="col-sm">
					<Text
						tagName="h3"
						className="font-weight-bold m-0 p-md"
						textId={question.title}
						variables={{
							questionIndex: questionIndex + 1,
							questionsCount: questionsCount,
						}}
					/>
					<Text textId={question.question} tagName="p" />

					<div>
						<Text textId={GlobalText.UserAnswer} variables={textVariables} />
					</div>
					<div>
						<Text
							textId={
								correctOptions.length > 1
									? GlobalText.CorrectAnswers
									: GlobalText.CorrectAnswer
							}
							variables={textVariables}
						/>
					</div>
					{isAnswerCorrect ? (
						<IconCheck
							className={undefined}
							style={undefined}
							color="var(--fp-success)"
						/>
					) : (
						<IconCheckIncorrect
							color="var(--fp-fail)"
							className={undefined}
							style={undefined}
						/>
					)}
				</div>
			</div>
		);
	};

export type KnowledgeQuizSummaryProps = {
	questions: KnowledgeQuizQuestionModel[];
	answers: KnowledgeQuizAnswersModel;
	title?: TextId;
	feedback?: TextId;
};
const KnowledgeQuizSummary: React.FC<KnowledgeQuizSummaryProps> = ({
	questions,
	answers,
	title,
	feedback,
}) => {
	const correctlyAnsweredQuestions = questions.filter((q) => {
		const answerValue = answers[q.id];
		const answeredOption = q.options.find((o) => o.id === answerValue);

		return answeredOption && answeredOption.correct;
	});

	const textVariables = {
		correctAnswersCount: correctlyAnsweredQuestions.length,
		questionsCount: questions.length,
	};

	return (
		<div className="d-flex flex-column" style={{ gap: "1rem" }}>
			{title && (
				<div className="row m-0">
					<div className="col-sm">
						<Text
							textId={title}
							tagName="h2"
							className="font-weight-bold"
							variables={textVariables}
						/>
					</div>
				</div>
			)}

			{questions.map((question, i) => {
				const answeredOption = question.options.find(
					(option) => option.id === answers[question.id]
				);

				return (
					<KnowledgeQuizSummaryQuestion
						key={question.id}
						question={question}
						answeredOption={answeredOption}
						questionIndex={i}
						questionsCount={questions.length}
					/>
				);
			})}

			{feedback && (
				<div className="row m-0 mt-4">
					<div className="col-sm">
						<Text
							textId={feedback}
							tagName="h2"
							className="font-weight-bold"
							variables={textVariables}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default KnowledgeQuizSummary;
