import DialogSprig from "./components/core/sprigs/DialogSprig";
import StorySprig from "./components/core/sprigs/StorySprig";
import SelectionSprig from "./components/core/sprigs/SelectionSprig";

const _sprigs = {
	paragraph: DialogSprig,
	dialog: DialogSprig,
	story: StorySprig,
	selection: SelectionSprig,
};

export const add = (type, sprig) => {
	_sprigs[type] = sprig;
};

export const get = (type, storyType) => {
	if (!type || type === "") {
		return _sprigs[storyType];
	}

	if (!_sprigs[type]) {
		throw new Error(`No sprig for type "${type}" found!`);
	}
	return _sprigs[type];
};
