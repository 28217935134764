import React from "react";
import PropTypes from "prop-types";

import PageListItems from "./core/PageListItems";

class TabPages extends React.Component {
	static propTypes = {
		filters: PropTypes.arrayOf(
			PropTypes.shape({
				path: PropTypes.string.isRequired,
				value: PropTypes.number.isRequired,
			})
		),
		pageRenderFunction: PropTypes.func,
	};

	render() {
		const { filters, pageRenderFunction } = this.props;
		return (
			<PageListItems
				parentPageId={null}
				filters={filters}
				className="tab-pages"
				renderFunction={pageRenderFunction}
			/>
		);
	}
}

export default TabPages;
