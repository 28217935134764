import { getReducer } from "../../utils/StoreUtils";
import { asyncForEach } from "../../utils/ArrayUtils";

export const parseCMTPages = ({
	cmtPages,
	projectActions,
	cmtParseSchemes,
	template,
}) => {
	const pagesById = getReducer("pages").itemsById;
	const pages = [];
	const settings = cmtParseSchemes.find(
		(cmtParseScheme) => cmtParseScheme.settings
	);

	const { unlockAll, setFirstPageToIncomplete = true } = settings
		? settings.settings
		: {};
	// Remove course pages.
	// cmtPages = cmtPages.filter(cmtPage => cmtPage.type !== "course");

	// Find first section page
	const cmtSectionPages = cmtPages.filter((cmtPage) => cmtPage.type === "page");
	const firstSectionPage = cmtSectionPages[0];

	const onPageExitAction = projectActions.find(
		(action) => action.friendlyId === "onPageExit"
	);

	return asyncForEach(
		cmtPages,
		(cmtPage) => {
			let status = "not_attempted";

			if (cmtPage.type === "chapter" && cmtPage.locked === "no") {
				status = "unlocked";
			}

			if (cmtPage.type === "page") {
				if (unlockAll || cmtPage.locked === "no") {
					status = "unlocked";
				} else {
					status = "not_attempted";
					if (setFirstPageToIncomplete && firstSectionPage.id === cmtPage.id) {
						status = "unlocked";
					}
				}
			}

			// If program type is workshop the top page will be scalable
			const pageBehaviour =
				cmtPage.page_behaviour !== undefined ? cmtPage.page_behaviour : "auto";
			const pageType =
				pageBehaviour === "auto"
					? (cmtPage.program_type === "slide" && "scalable") || "default"
					: pageBehaviour;

			// console.log("cmtPage", cmtPage.parent_id, "behaviour:", cmtPage.page_behaviour, "type:", pageType);

			const page = {
				...{
					id: cmtPage.id,
					friendlyId: cmtPage.friendly_id,
					parentPageId: cmtPage.parent_id || null,
					data: {
						cmt: true,
						cmtPage,
					},
					sort_index: cmtPage.sort_index,
					type: pageType,
					name: cmtPage.friendly_id || "Page",
					layer: "back",
					status,
					layers: ["back", "mid", "front"],
					className: `cmt-type-${cmtPage.type} cmt-page-type-${cmtPage.page_type}`,
					style: {},
					winScrollBehaviour: template
						? "persist-if-incomplete"
						: cmtPage.type === "page"
						? "top"
						: null,
					mandatory: cmtPage.type === "page",
					width: (pageType === "scalable" && 1920) || undefined,
					height: (pageType === "scalable" && 1080) || undefined,
				},
				...pagesById[cmtPage.id],
			};

			pages.push(page);
		},
		() => {
			return { pages };
		}
	);
};
