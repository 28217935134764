import React, { useEffect } from "react";
import { connect } from "../../utils/reduxUtils";
import classnames from "classnames";
import {
	ReduxCollectionSelectors,
	PageItemMenuButton,
	FFText,
	FFButton,
	LocationSelectors,
	PageItemImage,
	PageActions,
	AppActions,
	StoreUtils,
} from "funkis-foundation";
import MainMenuPageButton from "./MainMenuPageButton";
import Text from "../../components/core/Text";

const { makeGetItemByIdSelector, makeGetItemIdsSelector } =
	ReduxCollectionSelectors;
const { makeGetLocationSelector } = LocationSelectors;
const { updatePage } = PageActions;

import Collapsible from "components/Collapsible/Collapsible";

import Style from "./MainMenuChapterContainer.module.css";
import IconArrow from "../../assets/svg/IconArrow";
import StatusIcon from "../core/StatusIcon";
import DurationIcon from "../core/DurationIcon";
import Hover from "../core/Hover";
import { getBootstrapColor } from "../../utils/colorUtils";
import IOBadgeOutput from "../core/IOBadgeOutput";

let _component;
let _children;

export const setComponent = (component) => {
	_component = component;
};

export const setChildren = (children) => {
	_children = children;
};

const MainMenuChapterContainer = (props) => {
	if (_component) {
		return _component(props);
	}

	const { page, pageIds, index, className, children } = props;
	const { cmtPage } = page.data;
	const app = StoreUtils.getReducer("app").item;
	const menuOpen = page.menuOpen || {};
	const pageOpen =
		app.reveal_menu_chapters === "always" ||
		(app.reveal_menu_chapters === "all" &&
			(menuOpen[index] === undefined ? true : menuOpen[index])) ||
		(app.reveal_menu_chapters === "first" &&
			index === 0 &&
			menuOpen[index] === undefined) ||
		(app.reveal_menu_chapters === "closed" && menuOpen[index] === true) ||
		(app.reveal_menu_chapters === "off" && false) ||
		menuOpen[index];

	const chapterIdentifier = cmtPage.chapter_identifier || index + 1 + ".";
	const mainMenuMedia =
		cmtPage.main_menu_media || cmtPage.main_menu_image || cmtPage.media;
	const textColor = getBootstrapColor(
		cmtPage.main_menu_chapter_button_text_color ||
			app.main_menu_chapter_button_text_color ||
			cmtPage.menu_item_text_color ||
			app.main_menu_text_color ||
			"black"
	);
	const backgroundColor = getBootstrapColor(
		cmtPage.main_menu_chapter_button_bg_color ||
			app.main_menu_chapter_button_bg_color ||
			"white"
	);
	const numPageButtonColumns =
		app.main_menu_num_page_button_columns || app.number_of_menu_columns;
	const pageButtonIconColor = getBootstrapColor(
		app.main_menu_page_icon_color || "primary"
	);

	useEffect(() => {
		// value used by funkis-foundation ContentPageContainer.jsx
		AppActions.updateApp({
			backgroundColor: getBootstrapColor(app.main_menu_background_color),
		});
		return () => {
			AppActions.updateApp({ backgroundColor: "transparent" });
		};
	}, []);

	return (
		<PageItemMenuButton
			pageItem={{
				pageId: pageIds[0] || page.id,
				enabled:
					app.reveal_menu_chapters === "off" && page.status !== "not_attempted",
			}}
			className={classnames(
				"position-relative",
				page.status === "not_attempted" && "opacity-30",
				className
			)}
		>
			<div
				className={classnames(
					"main-menu-chapter-container container-fluid mb-2 mt-1"
				)}
				style={{ color: textColor }}
			>
				{/* CHAPTER BUTTON */}
				<div className="row m-0">
					<div className="col-sm-12">
						<PageItemImage
							pageItem={{
								src: mainMenuMedia,
								backgroundImage: true,
								autoSize: false,
							}}
							className={classnames(Style.image, "w-100 px-4 pb-3 pt-1")}
							style={{
								backgroundColor,
								color: textColor,
								backgroundPosition:
									app.main_menu_chapter_button_bg_image_position || "center",
							}}
						>
							{/* CHAPTER IDENTIFIER */}
							<FFText
								tagName="h1"
								textId={chapterIdentifier}
								placeholder={`${index + 1}. `}
								className={classnames(
									"col-sm-9 m-0 p-0 mb-n1 text-uppercase font-weight-bold main-menu-chapter-identifier"
								)}
							/>
							{/* HEADER */}
							<FFText
								tagName="h3"
								textId={cmtPage.title_txt_id}
								className="col-sm-9 m-0 p-0 mb-1 font-weight-bold main-menu-chapter-button-header"
							/>
							{/* SUB HEADER 1 */}
							<Text
								tagName="h5"
								textId={cmtPage.sub_title_1_txt_id}
								className="col-sm-9 m-0 p-0 mb-1 font-weight-bold"
							/>
							{/* PROGRESS - HE: This is maybe nor aplicable for scroll productions... */}
							<div className="d-flex">
								{cmtPage.duration && (
									<DurationIcon
										className="mt-1 mr-2"
										labelText={cmtPage.duration}
										labelTextId={cmtPage.duration}
										color={pageButtonIconColor}
									/>
								)}
								{app.reveal_menu_chapters === "off" && (
									<StatusIcon
										className="mt-1"
										color={pageButtonIconColor}
										status={page.status}
									/>
								)}
							</div>

							<div className="d-flex justify-content-between">
								{/* ARROW BUTTON */}
								<Hover
									className={classnames("mt-1 d-flex")}
									enabled={app.reveal_menu_chapters !== "always"}
								>
									{({ hover }) => (
										<FFButton
											enabled={app.reveal_menu_chapters !== "always"}
											onClick={() => {
												menuOpen[index] = !pageOpen;
												updatePage(page.id, { menuOpen });
											}}
										>
											{app.reveal_menu_chapters !== "always" &&
												app.reveal_menu_chapters !== "off" && (
													<div
														className={classnames(
															Style.arrow,
															pageOpen && Style.arrowOpen
														)}
													>
														<IconArrow
															color={
																hover
																	? getBootstrapColor("interaction")
																	: textColor
															}
														/>
													</div>
												)}
										</FFButton>
									)}
								</Hover>
								{/* GAMIFICATION - BADGES */}
								{app.main_menu_show_gamification_badges === "yes" && (
									<IOBadgeOutput
										chapterId={page.id}
										mode="count"
										backgroundColorBadgeCounter="primary"
										colorBadgeCounter="white"
									/>
								)}
							</div>
						</PageItemImage>
					</div>
				</div>
			</div>
			{/* PAGE BUTTONS */}
			{app.reveal_menu_chapters !== "off" && (
				<div className={"container-fluid"}>
					<Collapsible open={pageOpen}>
						<div className="row m-0 align-items-stretch">
							{pageIds.map((id, i) => (
								<MainMenuPageButton
									key={`MainMenuPageButton-${id}`}
									className={classnames(
										(numPageButtonColumns === "1" && "col-sm-12") ||
											(numPageButtonColumns === "2" && "col-md-6") ||
											"col-md-6",
										"mb-2"
									)}
									id={id}
									index={i}
									chapterIndex={index}
									chapterStatus={page.status}
								/>
							))}
						</div>
					</Collapsible>
				</div>
			)}
			{(_children && _children(props)) || children}
		</PageItemMenuButton>
	);
};

const makeMapStateToProps = () => {
	const getItemByIdSelector = makeGetItemByIdSelector();
	const getLocationSelector = makeGetLocationSelector();
	const getItemIdsSelector = makeGetItemIdsSelector();
	const mapStateToProps = (state, props) => {
		return {
			page: getItemByIdSelector(state, props, "pages"),
			location: getLocationSelector(state, props),
			pageIds: getItemIdsSelector(
				state,
				{ match: { parentPageId: props.id } },
				"pages"
			),
		};
	};
	return mapStateToProps;
};

export default connect(MainMenuChapterContainer, makeMapStateToProps);
