import React from "react";

function IconHotspot({
	fillColor,
	showOuterCircle = false,
	plusSignColor = "#FFF",
	background = "#FFF",
	color = "#000",
	...props
}) {
	const offset = 40;
	return (
		<svg
			viewBox="10 10 60 60"
			width="100%"
			height="100%"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g fill="none" fillRule="evenodd">
				<circle
					id="hotspot-inner-circle"
					fill={fillColor}
					cx={offset}
					cy={offset}
					r={16}
				/>
				{!showOuterCircle && (
					<circle
						id="hotspot-outer-circle"
						cx={offset}
						cy={offset}
						r={25}
						stroke={fillColor}
						fillOpacity={0.5}
						fill="none"
					/>
				)}
				<path
					id="hotspot-cross"
					d="M41.324 33l-.02 5.695 5.696-.02-.005 2.602-5.699.019-.02 5.699-2.6.005.019-5.696-5.695.02.005-2.6 5.698-.021.02-5.698L41.324 33z"
					fill={plusSignColor}
				/>
			</g>
		</svg>
	);
}

export default IconHotspot;
