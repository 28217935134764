import { PageItemMedia } from "funkis-template/models/player";
import _ from "lodash";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

export type Subtitle = {
	src: string;
	enter: number;
	exit: number;
	text: string;
};

const selectAllSubtitles = (state: any) => state.subtitles.items;

const selectSubtitle: (
	state: any,
	src: PageItemMedia,
	currentTime: number
) => Subtitle | undefined = createSelector(
	[
		selectAllSubtitles,
		(_state: any, src: PageItemMedia) => src,
		(_state: any, _src: PageItemMedia, currentTime: number) => currentTime,
	],
	(
		subtitles: Subtitle[],
		src: PageItemMedia,
		currentTime: number
	): Subtitle | undefined => {
		const subtitlesForVideoId = subtitles.filter((subtitle) => {
			if (!subtitle || !src) return false;

			if (_.isString(src)) {
				return subtitle.src === src;
			} else if (_.isPlainObject(src)) {
				// src is localized source - this logic assumes all localized files use the same filename
				return src.src === subtitle.src;
			} else {
				return false;
			}
		});

		const subtitle = subtitlesForVideoId.find(
			(subtitle) =>
				currentTime >= subtitle.enter && currentTime <= subtitle.exit
		);

		return subtitle;
	}
);

export const useSubtitle = (src: PageItemMedia, time: number) => {
	return useSelector((state) => selectSubtitle(state, src, time), _.isEqual);
};
