import _ from "lodash";
import { useSelector } from "react-redux";
import { useBackend as useLmsBackend } from "./backend/backendLms";
import { useBackend as useQuiddityBackend } from "./backend/backendQuiddity";
import { usePackageIOContext } from "./player";

export enum IOContext {
	Auto = "auto",
	Event = "event",
	Session = "session",
	Group = "group",
	CurrentGroupMembers = "current_group_members",
	Member = "member",
	Undefined = "",
}

export enum QuidType {
	ContentSelection = "contentSelection",
	Action = "action",
	Badge = "badge",
	Comment = "comment",
	Dilemma = "dilemma",
	FormData = "formData",
	PostIt = "postIt",
	Quiz = "quiz",
	Rate = "rate",
	Result = "result",
	SprigStory = "sprigStory",
	App = "app",
	Package = "package",
	Video = "video",
	Mail = "mail",
	MediaUpload = "mediaUpload",

	/* @deprecated replaced by QuidType.ContentSelection */
	MultiSelect = "multi-select",
}

export type Quid = {
	id: string;
};

export type QuidInput = {
	id: string;
	targetDataKey: string;
	targetQuidInput?: string;
	sourceQuid: string;
	data: any;
	member?: string;
	group?: string;
	archived: boolean;
	createdAt: string;
	updatedAt: string;

	/**
	 * @deprecated Use sourceQuid instead. "quidType" is used by
	 * legacy <Backend /> component when querying for local data.
	 */
	quidType?: QuidType;
};

export type QuidInputParams = Partial<
	Omit<QuidInput, "id" | "createdAt" | "updatedAt">
>;

export type QuidInputUpdateParams = QuidInputParams & { id: string };

export type Backend = {
	quidInputs: QuidInput[];
	error?: BackendError;
	isLoading: boolean;
	isSuccess: boolean;
	createQuidInput: (params: QuidInputParams) => Promise<QuidInput>;
	updateQuidInput: (params: QuidInputUpdateParams) => Promise<QuidInput>;
	deleteQuidInput: (params: { id: string }) => Promise<void>;
};

export type BackendError = {
	status: string | number;
	data: string;
};

type BackendProps = {
	targetDataKey: string;
	inputContext?: IOContext;
	quidType: QuidType;
	url?: string;
	api?: string;
	shouldIncludeArchivedQuidInputs?: boolean;
};

export const useBackend = ({
	targetDataKey,
	inputContext = IOContext.Auto,
	quidType,
	url = "",
	api = "/api",
	shouldIncludeArchivedQuidInputs = false,
}: BackendProps): Backend => {
	const backend: string = useSelector((state: any) =>
		_.get(state.config.item, "backend")
	);

	const fallbackPackageIOContext = usePackageIOContext();
	const actualInputContext =
		inputContext === IOContext.Auto || inputContext === IOContext.Undefined
			? fallbackPackageIOContext
			: inputContext;

	switch (backend) {
		case "quiddity":
			return useQuiddityBackend({
				targetDataKey,
				inputContext: actualInputContext,
				quidType,
				url,
				api,
				shouldIncludeArchivedQuidInputs,
			});

		case "lms":
			return useLmsBackend({
				targetDataKey,
				quidType,
				shouldIncludeArchivedQuidInputs,
			});

		default:
			throw new Error(`Backend type '${backend}' not supported`);
	}
};
