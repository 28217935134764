import classnames from "classnames";
import Button from "components/Button/Button";
import Collapsible from "components/Collapsible/Collapsible";
import { makeGetIsNextBlockRevealed } from "funkis-template/../selectors/playerSelectors";
import { revealNext } from "funkis-template/actions/Actions";
import Text from "funkis-template/components/core/Text";
import { PageItem, TextId } from "funkis-template/models/player";
import { connect } from "funkis-template/utils/reduxUtils";
import { forwardRef, useState } from "react";
import Style from "./RevealNextButton.module.css";

type RevealNextButtonProps = {
	pageItem: PageItem<any>;
	textId: TextId;
	show: boolean;
	onClick: () => void;
	isNextBlockRevealed?: boolean;
};

const RevealNextButton = forwardRef<HTMLDivElement, RevealNextButtonProps>(
	(props, ref) => {
		const {
			pageItem,
			textId,
			show: shouldShowAccordingToParent = true,
			onClick,
			isNextBlockRevealed,
		} = props;

		// NOTE: This should not be needed but something is buggy with the connected prop "isNextBlockRevealed"
		const [nextBlockRevealed, setNextBlockRevealed] =
			useState(isNextBlockRevealed);

		const isShowing = shouldShowAccordingToParent && !nextBlockRevealed;

		return (
			<div
				ref={ref}
				className={classnames(
					"reveal-next-button no-print row w-100 m-0 justify-content-center"
				)}
				aria-hidden={!isShowing}
			>
				<Collapsible open={isShowing} variant="fade-out-collapse">
					<div className={classnames(Style.revealContainer, "p-4")}>
						<Button
							disabled={!isShowing}
							onClick={() => {
								revealNext({ pageItem });
								onClick && onClick();
								setNextBlockRevealed(true);
							}}
						>
							<Text textId={textId} />
						</Button>
					</div>
				</Collapsible>
			</div>
		);
	}
);

const makeMapStateToProps = () => {
	const getIsNextBlockRevealed = makeGetIsNextBlockRevealed();
	const mapStateToProps = (state, props) => {
		return {
			isNextBlockRevealed: getIsNextBlockRevealed(state, props),
		};
	};
	return mapStateToProps;
};

export default connect(RevealNextButton, makeMapStateToProps);
