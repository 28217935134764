import classnames from "classnames";
import Divider from "components/Divider/Divider";
import React, { CSSProperties, ReactElement } from "react";
import { FC, ReactNode } from "react";
import Style from "./Stack.module.css";

type Spacing =
	| "none"
	| "xxsmall"
	| "xsmall"
	| "small"
	| "medium"
	| "large"
	| "xlarge"
	| "xxlarge";

export type StackProps = {
	className?: string;
	children: ReactNode;
	spacing?: Spacing;
	direction?: "vertical" | "horizontal";
	align?: "start" | "center" | "end";
	dividers?: false | "regular" | "strong";
	width?: "fit-content-width" | "full-width";
	height?: "fit-content-height" | "full-height";
	style?: CSSProperties;
};

function intersperse(children: ReactNode[], dividerElement: ReactElement) {
	return children.flatMap((child, i) =>
		i < children.length - 1
			? [child, React.cloneElement(dividerElement, { key: `divider-${i}` })]
			: [child]
	);
}

const Stack: FC<StackProps> = ({
	className,
	children,
	style,
	spacing = "medium",
	direction = "vertical",
	dividers,
	align = "start",
	width = "full-width",
	height = "fit-content-height",
}) => {
	return (
		<div
			className={classnames(
				Style.stack,
				Style[spacing],
				Style[direction],
				Style[align],
				Style[width],
				Style[height],
				className
			)}
			style={style}
		>
			{dividers
				? intersperse(
						React.Children.toArray(children),
						<Divider weight={dividers} />
				  )
				: children}
		</div>
	);
};

export default Stack;
