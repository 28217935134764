import _ from "lodash";
import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import FFImage from "../components/FFImage";
import FFVideo from "../components/FFVideo";
import { getBootstrapColor } from "../utils/ColorUtils";
import { getLocalizedMediaPath } from "utils/MediaUtils";
import { getCurrentPage } from "../utils/PagesUtils";
import bgQuiddity from "assets/images/bg_quiddity.jpg";

const isVideo = (src) => {
	return src && src.indexOf(".mp4") > -1;
};

const Wrapper = (props) => {
	const { className, children } = props;

	const [bgImage, bgVideo, bgColor] = useSelector((state) => {
		const { program_type, backend } = _.get(state, "app.item");
		const location = _.get(state, "location.item.location");
		const currentPage = getCurrentPage();

		const getBgMedia = () => {
			const isScroll = program_type === "scroll";
			const isLanguageMenu = currentPage.friendlyId === "select-language";
			const isQuiddityBackend = backend === "quiddity";
			const isQuiddityScrollLanguageMenu =
				isQuiddityBackend && isScroll && isQuiddityBackend && isLanguageMenu;

			const isScrollMainMenu = isScroll && currentPage.friendlyId === "menu";
			const mainMenuBackgroundMediaScroll = _.get(
				state,
				"app.item.main_menu_background_media_scroll"
			);

			if (isQuiddityScrollLanguageMenu) return bgQuiddity;
			else if (isScrollMainMenu && mainMenuBackgroundMediaScroll)
				return mainMenuBackgroundMediaScroll;
			else {
				return (
					_.get(state, "app.item.backgroundImage") ||
					_.get(state, "app.item.page_bg_image") ||
					(location && _.get(currentPage, "data.cmtPage.page_background_media"))
				);
			}
		};

		const _bgImage = getBgMedia();

		const _bgColor =
			(currentPage.friendlyId === "select-language" &&
				program_type === "scroll" &&
				"rgb(31, 31, 31)") ||
			(location && _.get(currentPage, "data.cmtPage.page_background_color")) ||
			_.get(state, "app.item.backgroundColor") ||
			_.get(
				state,
				"app.item.page_background_color",
				program_type === "slide" ? "black" : "white"
			);

		return [
			!isVideo(_bgImage) && _bgImage,
			isVideo(_bgImage) && _bgImage,
			_bgColor,
		];
	}, shallowEqual);

	return (
		<div
			className={className}
			style={{ backgroundColor: getBootstrapColor(bgColor) }}
		>
			{bgImage && (
				<FFImage
					className="background-image"
					width="100%"
					height="100%"
					backgroundImage
					src={getLocalizedMediaPath(bgImage)}
					style={{ position: "fixed", top: 0, left: 0 }}
				/>
			)}
			{bgVideo && (
				<FFVideo
					className="background-video"
					src={getLocalizedMediaPath(bgVideo)}
					loop
					muted
					controls={false}
					autoPlay
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						minWidth: "100%",
						minHeight: "100%",
					}}
				/>
			)}
			{children}
		</div>
	);
};

const ContentPageContainer = (props) => {
	const { children } = props;
	return (
		<Wrapper className="content-page-container" {...props}>
			{children}
		</Wrapper>
	);
};

export default ContentPageContainer;
