import React from "react";
import classnames from "classnames";
import FFImage from "../components/FFImage";

import { getLocalizedMediaPath } from "utils/MediaUtils";

const PageItemImage = ({
	pageItem,
	style,
	className,
	children,
	alt = "",
	rest,
} = props) => {
	return (
		<FFImage
			style={Object.assign({}, pageItem.style, style)}
			className={classnames(
				className,
				"page-item page-item-image",
				pageItem.className
			)}
			alt={alt}
			src={
				pageItem.url ||
				(pageItem.src && getLocalizedMediaPath(pageItem.src)) ||
				""
			}
			width={pageItem.width}
			height={pageItem.height}
			omitSrc={pageItem.omitSrc}
			backgroundImage={pageItem.backgroundImage}
			autoSize={pageItem.autoSize}
			rest={rest}
			isZoomable={pageItem.isZoomable}
		>
			{children}
		</FFImage>
	);
};

export default PageItemImage;
