import React from "react";
import { FFText } from "funkis-foundation";
import IOScoreOutput from "./IOScoreOutput";
import IOBadgeOutput from "./IOBadgeOutput";

const GamificationPanel = ({
	context = "member",
	textIdBadge,
	textIdScore,
	backgroundColor = "black",
	badgeMode = "array",
}) => {
	if (!context || context === "none") {
		return <React.Fragment />;
	}

	return (
		<div
			className="w-100 d-flex justify-content-between p-3"
			style={{ backgroundColor }}
		>
			{/* BADGES */}
			<div className="d-flex flex-column flex-grow-0">
				<FFText textId={textIdBadge} className="mb-1 p-sm text-white" />
				<IOBadgeOutput
					inputContext={context}
					className="mb-1 d-flex flex-wrap"
					mode={badgeMode}
				/>
			</div>
			{/* SCORE */}
			<div className="d-flex flex-column align-items-end">
				<FFText textId={textIdScore} className="p-sm text-white" />
				<IOScoreOutput inputContext={context} className="d-flex" />
			</div>
		</div>
	);
};

export default GamificationPanel;
