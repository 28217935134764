import * as StoreUtils from "../../utils/StoreUtils";

// Action constants
import {
	UPDATE_QUID_INPUTS,
	UPDATE_QUID_INPUT,
	REMOVE_QUID_INPUT,
	REMOVE_ALL_QUID_INPUTS,
	MAKE_QUID_INPUTS_SNAPSHOT,
	RESTORE_FROM_QUID_INPUTS_SNAPSHOT,
} from "../constants/ActionConstants";

export function updateQuidInputs(items) {
	StoreUtils.getStore().dispatch(_updateQuidInputs(items));
}

function _updateQuidInputs(items) {
	return (dispatch: (action) => void) => {
		dispatch({ items, type: UPDATE_QUID_INPUTS });
	};
}

export function updateQuidInput(id, updates) {
	StoreUtils.getStore().dispatch(
		_updateQuidInput(Object.assign(updates, { id }))
	);
}

function _updateQuidInput(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_QUID_INPUT });
	};
}

export function removeQuidInput(id) {
	StoreUtils.getStore().dispatch(_removeQuidInput({ id }));
}

export function makeQuidInputsSnapshot(item) {
	StoreUtils.getStore().dispatch(_makeQuidInputsSnapshot(item));
}

export function restoreFromQuidInputsSnapshot(item) {
	StoreUtils.getStore().dispatch(_restoreFromQuidInputsSnapshot(item));
}

function _removeQuidInput(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_QUID_INPUT });
	};
}

export function removeAllQuidInputs() {
	StoreUtils.getStore().dispatch(_removeAllQuidInputs());
}

function _removeAllQuidInputs() {
	return (dispatch: (action) => void) => {
		dispatch({ type: REMOVE_ALL_QUID_INPUTS });
	};
}

function _makeQuidInputsSnapshot(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: MAKE_QUID_INPUTS_SNAPSHOT });
	};
}

function _restoreFromQuidInputsSnapshot(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: RESTORE_FROM_QUID_INPUTS_SNAPSHOT });
	};
}
