import _ from "lodash";

export const normalize = (target) => {
	return JSON.parse(JSON.stringify(target));
};

export const getByPaths = (obj, paths) => {
	let result = false;
	paths.every((path) => {
		result = _.get(obj, path, false);
		if (result && typeof result === "string") {
			return false;
		} else {
			return true;
		}
	});
	return result;
};

export const pickByTypes = (obj, types) => {
	const pairs = _.toPairs(types);
	const picks = pairs
		.filter((pair) => {
			const key = pair[0];
			const predicate = pair[1];
			return predicate === undefined || predicate(obj[key]);
		})
		.map((pair) => pair[0]);
	return _.pick(obj, picks);
};
