
import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	removeItemInStateItems,
} from "../utils/reducerUtils";

import {
	UPDATE_QUIDS,
	UPDATE_QUID,
	REMOVE_QUID,
} from "../constants/ActionConstants";

const quids = (state = initialStateObjectCollection(), action) => {
	switch (action.type) {
		case UPDATE_QUIDS:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_QUID:
			return updateItemInStateItems(state, action.item);
		case REMOVE_QUID:
			return removeItemInStateItems(state, action.item);
		default:
			return state;
	}
};

export default quids;
