import React from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";

let modalRoot = document.getElementById("modal-page-item");

class PageItemModal extends React.Component {
	constructor(props) {
		super(props);
		this.el = document.createElement("div");
	}
	componentDidMount() {
		const { pageItem } = this.props;
		modalRoot =
			document.getElementsByClassName(
				`page-item-id-${pageItem.elementId}`
			)[0] || document.getElementById(pageItem.elementId || "modal-page-item");
		if (!modalRoot) {
			console.warn(
				`No parent (modalRoot) found for modal page item ${
					pageItem.name || pageItem.id
				}. Did you forgot to add an elementId or adding an element with id "modal-page-item"?`
			);
		} else {
			modalRoot.appendChild(this.el);
		}
	}

	componentWillUnmount() {
		const { onHide } = this.props;
		onHide && onHide(this.props);
		modalRoot && modalRoot.removeChild(this.el);
	}

	render() {
		const { pageItem, style, className, children, rest } = this.props;
		const { enabled, show, unmontChildrenOnHide } = pageItem;
		const hideChildren = unmontChildrenOnHide && !show;

		if (enabled) {
			return ReactDOM.createPortal(
				<div
					key={pageItem.id}
					style={Object.assign({}, pageItem.style, style)}
					className={classnames(
						className,
						"page-item page-item-modal",
						pageItem.className,
						show ? "show" : "hide"
					)}
					{...rest}
				>
					{!hideChildren && children}
				</div>,
				this.el
			);
		} else {
			return <React.Fragment>{children}</React.Fragment>;
		}
	}
}

export default PageItemModal;
