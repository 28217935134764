import * as API from "../api";
import { getCode } from "../utils/QuiddityUtils";
import * as UserActions from "../redux/actions/UserActions";
import { getReducer } from "../utils/StoreUtils";

export const getUsers = async (url, query, addToStore = true) => {
	try {
		const users = await API.getUsers(url, query, getCode());
		addToStore && UserActions.updateUsers(users);
		return users;
	} catch (error) {
		return [];
	}
};

export const getUser = async (url, query, addToStore = true) => {
	const users = await getUsers(url, query, addToStore);
	return users[0];
};

export const upsertUser = async (url, query, addToStore = true) => {
	const usersById = getReducer("users").itemsById;
	const user = usersById[query.id] || (await getUser(url, query));
	let upsertedUser = { ...user, ...query };
	addToStore && user && UserActions.updateUser(upsertedUser.id, upsertedUser);
	upsertedUser = await API.postOrPutUser(url, upsertedUser, getCode());
	addToStore && !user && UserActions.updateUser(upsertedUser.id, upsertedUser);
	return upsertedUser;
};

// "CHECK IF USER LOGGED IN"
export const getCurrentUser = async (url, acceptSessionLogin = false) => {
	let user;
	try {
		user = await API.getCurrentUser(url, getCode());
		if (!acceptSessionLogin && !user.email) {
			// we got a session back, not a user
			user = undefined;
		}
	} catch (error) {}
	return user;
};
