import classnames from "classnames";
import { FFText, PageItemActions, TextUtils } from "funkis-foundation";
import { useViewportSize, ViewportSize } from "funkis-template/hooks/player";
import { VideoConversationScrollBlock } from "funkis-template/models/pageitem";
import { PageItem } from "funkis-template/models/player";
import gsap from "gsap";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import IOMultiSelect from "../../components/core/IOMultiSelect";
import SpeechBubble from "../../components/core/SpeechBubble";
import Text from "../../components/core/Text";
import VideoPlayer, {
	VideoState,
} from "../../components/core/VideoPlayer/VideoPlayer";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import ReadMore from "../../components/scroll/ReadMore";
import { getBootstrapColor } from "../../utils/colorUtils";
import { getText } from "../../utils/textUtils";
import Style from "./PageItemVideoConversation.module.css";

const { updatePageItem } = PageItemActions;

const Option = ({
	option,
	index,
	color,
	textColor = "black",
	textClassName,
	mode,
}) => {
	return (
		<SpeechBubble
			key={`video-conv-${index}`}
			color={color}
			bgTransparent={false}
			arrowDirection="down"
			className={classnames("pointer-events-none")}
		>
			<FFText
				className={classnames(
					"d-block w-100",
					textClassName,
					mode === "emphasized" &&
						"speech-bubble-emphasized text-center text-uppercase"
				)}
				textId={option.option}
				style={{ color: getBootstrapColor(textColor) }}
			/>
		</SpeechBubble>
	);
};

const Options = ({ showOptions, pageItem, isMobile, colorOption }) => {
	const [visible, setVisibility] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current) {
			gsap.set(ref.current, { opacity: showOptions ? 1 : 0 });
		}
	}, []);

	useEffect(() => {
		if (ref.current) {
			if (showOptions) {
				gsap.to(ref.current, {
					duration: 0.6,
					opacity: 1,
					onStart: () => {
						setVisibility(true);
					},
				});
			} else {
				gsap.to(ref.current, {
					duration: 0.6,
					opacity: 0,
					onComplete: () => {
						setVisibility(false);
					},
				});
			}
		}
	}, [showOptions]);

	const { cmtPageItem } = pageItem;

	return (
		<div
			className="w-100"
			ref={ref}
			style={{ height: visible ? "auto" : "0px" }}
		>
			<IOMultiSelect
				className={classnames("row m-0 p-0")}
				enabled={showOptions}
				pageItem={pageItem}
				maxSelections={1}
				minSelections={1}
				disableDeselect={true}
				buttonClassName={classnames(
					Style.buttonAnswer,
					isMobile ? "col-sm-12 mb-1" : "col",
					cmtPageItem.content_array.length === 3 ? "col col-md-4" : "col-md-6"
				)}
				onSelect={undefined}
				autoDeselectWhenMaxSelectionsReached={true}
				optionRender={(renderProps) => {
					return (
						<Option
							key={renderProps.option.id}
							{...renderProps}
							color={colorOption}
							textColor={cmtPageItem.option_text_color}
							mode={cmtPageItem.mode}
						/>
					);
				}}
				backendClassName={undefined}
			/>
		</div>
	);
};

enum ConversationState {
	ShowingQuestionVideo,
	ShowingQuestion,
	ShowingFeedbackVideo,
	Completed,
}

const PageItemVideoConversation: React.FC<{
	pageItem: PageItem<VideoConversationScrollBlock> & {
		selectedIndexes?: number[];
	};
}> = (props) => {
	const { pageItem } = props;
	const { cmtPageItem, status, selectedIndexes } = pageItem;

	const [videoState, setVideoState] = useState<VideoState>("idle");

	const posterFrame = cmtPageItem.poster_frame;
	const selectedIndex = selectedIndexes && selectedIndexes[0];
	const selectedOption = _.isNumber(selectedIndex)
		? cmtPageItem.content_array.at(selectedIndex)
		: undefined;

	const hasMadeSelection = selectedOption !== undefined;

	const isCompleted = hasMadeSelection;

	const video = hasMadeSelection ? selectedOption.video : cmtPageItem.video;

	const showQuestion = !hasMadeSelection && videoState === "ended";
	const showOptions = showQuestion;

	const viewportSize = useViewportSize();
	const isMobile = viewportSize <= ViewportSize.Mobile;

	const colorQuestion = getBootstrapColor(
		cmtPageItem.question_background_color !== ""
			? cmtPageItem.question_background_color
			: "light-grey"
	);

	const colorOption = getBootstrapColor(
		cmtPageItem.option_background_color !== ""
			? cmtPageItem.option_background_color
			: "interaction"
	);

	useEffect(() => {
		isCompleted &&
			updatePageItem(pageItem.id, {
				status: "completed",
			});
	}, [isCompleted]);

	const pageItemId = pageItem.id;

	const handleVideoStateChange = React.useCallback(
		(newState: VideoState) => {
			setVideoState(newState);
		},
		[pageItemId]
	);

	const handlePreviousClick = React.useCallback(() => {
		updatePageItem(pageItemId, {
			selectedIndexes: undefined,
			status: "incomplete",
		});
	}, [pageItemId]);

	const getReadMoreTexts = () => {
		let readMoreTexts = TextUtils.getTextForVideoSource(
			cmtPageItem.video,
			true
		);
		readMoreTexts = readMoreTexts.concat([
			`<br><br><strong>${getText(cmtPageItem.question)}</strong>`,
		]);
		cmtPageItem.content_array.forEach((option) => {
			readMoreTexts = readMoreTexts.concat([
				`<br><br><strong>- ${getText(option.option)}</strong><br><br>`,
			]);
			readMoreTexts = readMoreTexts.concat(
				TextUtils.getTextForVideoSource(option.video, true)
			);
		});
		return readMoreTexts;
		// First video
	};

	const showNextButton = Boolean(
		status === "completed" && cmtPageItem.reveal_next_block_event !== "on-done"
	);
	return (
		<PageItemScrollBase
			{...props}
			showNextButton={showNextButton}
			containerClassName="w-100"
			renderFunction={(renderProps) => {
				return (
					<div className="page-item-video-coversation">
						<div className="container-fluid">
							<div className="row m-0">
								{/* HEADER */}
								<Text
									textId={cmtPageItem.header}
									tagName="h2"
									className={classnames(
										"col-sm-12 mb-5 font-weight-bold animated",
										renderProps.viewVisible ? "fadeIn" : "fadeOut"
									)}
									style={{
										color: getBootstrapColor(
											cmtPageItem.header_text_color || "black"
										),
									}}
								/>
								{/* BODY */}
								<Text
									className={classnames(
										"mb-6 col-sm-9 animated",
										renderProps.viewVisible ? "fadeIn" : "fadeOut"
									)}
									tagName="p"
									textId={cmtPageItem.body}
									style={{
										color: getBootstrapColor(
											cmtPageItem.body_text_color || "black"
										),
									}}
								/>
							</div>
						</div>
						{/* VIDEO PLAYER */}
						<div className={"container-fluid"}>
							<div
								className={classnames(
									"row m-0",
									renderProps.viewVisible ? "fadeIn" : "fadeOut"
								)}
							>
								<div className="col-12 px-0 px-sm-3">
									<div className="position-relative">
										<VideoPlayer
											videoId={cmtPageItem.id}
											src={video}
											controls={!showQuestion}
											borderColor={getBootstrapColor(
												cmtPageItem.border_color || "#CCCCCC"
											)}
											maxHeight={"65vh"}
											onPreviousClick={
												cmtPageItem.resetable === "yes" && hasMadeSelection
													? handlePreviousClick
													: undefined
											}
											posterFrame={posterFrame}
											onStateChange={handleVideoStateChange}
										></VideoPlayer>
										<div
											className={classnames(
												isMobile && "m-0",
												"container-fluid m-0 p-0 pb-4 d-flex flex-column justify-content-end",
												"position-absolute w-100 h-100"
											)}
											style={{ top: 0, left: 0, pointerEvents: "none" }}
										>
											<div className="row m-0">
												<SpeechBubble
													color={colorQuestion}
													bgTransparent={false}
													arrowDirection="none"
													className={classnames(
														"pointer-events-none col-sm-12",
														showQuestion ? "animated" : "opacity-0",
														showQuestion ? "fadeInUp" : "fadeOut"
													)}
												>
													<FFText
														textId={cmtPageItem.question}
														style={{
															color: getBootstrapColor(
																cmtPageItem.question_text_color || "black"
															),
														}}
													/>
												</SpeechBubble>
											</div>
											{/* OPTIONS - TABLET */}
											<div className="mt-2">
												{!isMobile && (
													<Options
														{...{
															showOptions,
															pageItem,
															isMobile,
															colorOption,
														}}
													/>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* OPTIONS - MOBILE */}
						<div className="w-100 mt-2">
							{isMobile && (
								<Options
									{...{ showOptions, pageItem, isMobile, colorOption }}
								/>
							)}
						</div>
						{/* TRANSCRIBE SUBTITLE */}
						{cmtPageItem.transcribe === "yes" && (
							<ReadMore
								readMoreTexts={getReadMoreTexts()}
								pageItem={{
									cmtPageItem: {
										video_src: cmtPageItem.video,
										label_open: "85ED8C97-F3D6-452F-A598-BA4DBBC42DA1",
										label_closed: "C7353ED0-A22A-4287-BFF2-95230DDFEE28",
									},
								}}
								viewVisible={renderProps.viewVisible}
								className="mt-3 col-sm-12 m-0"
							></ReadMore>
						)}
					</div>
				);
			}}
		/>
	);
};

export default PageItemVideoConversation;
