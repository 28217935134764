import React from "react";
import classnames from "classnames";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import Backend from "../../components/backend/Backend";
import Text from "../../components/core/Text";
import Image from "../../components/core/Image";
import { getText } from "../../utils/textUtils";
import { getBootstrapColor } from "../../utils/colorUtils";
import IconPostIt from "../../assets/svg/IconPostIt";

const PageItemIOPostItCategorizeOutput = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;
	return (
		<PageItemScrollBase
			{...props}
			containerClassName="w-100"
			showNextButton={cmtPageItem.show_next_button !== "no"}
			renderFunction={(renderProps) => {
				const { io_id, content_array } = cmtPageItem;
				return (
					<Backend
						className={classnames("container-fluid")}
						pageItem={pageItem}
						quidType="postIt"
						mode="bareOutputs"
						targetDataKey={io_id}
						match={{ targetDataKey: io_id }}
						renderFunction={(backendRenderProps) => {
							const { quidInputs } = backendRenderProps;
							return (
								<React.Fragment>
									{/* HEADER */}
									<Text
										className={classnames(
											"col-12 mb-5 font-weight-bold animated",
											renderProps.viewVisible ? "fadeIn" : "fadeOut"
										)}
										tagName="h2"
										textId={cmtPageItem.header}
									/>
									{/* BODY */}
									<Text
										className="col-md-10 mb-4"
										tagName="p"
										textId={cmtPageItem.body}
									/>
									{content_array.map((content) => {
										const category = getText(content.category);
										const quidInputsForCategory = quidInputs.filter(
											(quidInput) => quidInput.data.category === category
										);
										return (
											<React.Fragment>
												{/* HR */}
												<div className="row m-0">
													<div className="col-12">{/* <hr /> */}</div>
												</div>
												{/* GRID */}
												<div className="row m-0 px-3 align-items-stretch">
													<div
														className="col-6 border border-light-gray py-4"
														style={{
															backgroundColor: getBootstrapColor(
																cmtPageItem.background_color || "white"
															),
														}}
													>
														<Text
															tagName="p"
															textId="52C0DADF-7DEF-468F-A58E-5E41D5D6C316"
															className="p-sm font-weight-bold w-100"
															style={{
																color: getBootstrapColor(
																	cmtPageItem.text_color || "black"
																),
															}}
														/>
														<Text
															tagName="h3"
															textId={content.category}
															className="font-weight-bold w-100"
															style={{
																color: getBootstrapColor(
																	cmtPageItem.text_color || "black"
																),
															}}
														/>
													</div>
													<div
														className="col-6 border border-light-gray"
														style={{
															backgroundColor: getBootstrapColor(
																cmtPageItem.background_color || "white"
															),
														}}
													>
														<Image
															src={content.media}
															background
															maxHeight="200px"
															align="center-center"
														/>
													</div>
												</div>
												{/* HR */}
												<div className="row m-0">
													<div className="col-12 mb-5">{/* <hr /> */}</div>
												</div>
												{/* POST-ITS */}
												<div className="row m-0">
													{quidInputsForCategory.map((quidInput) => (
														<div className="col-sm-6 mb-5">
															<IconPostIt
																color={getBootstrapColor(
																	cmtPageItem.post_it_color || "primary"
																)}
															/>
															<p className="p-sm">{quidInput.data.body}</p>
														</div>
													))}
												</div>
												{/* HR */}
												<div className="row m-0">
													<div className="col-12 mb-5">{/* <hr /> */}</div>
												</div>
											</React.Fragment>
										);
									})}
								</React.Fragment>
							);
						}}
					></Backend>
				);
			}}
		/>
	);
};

export default PageItemIOPostItCategorizeOutput;
