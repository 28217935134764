import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import classnames from "classnames";
import {
	Main,
	Location,
	StoreUtils,
	Inspector,
	TabPages,
	TabGeneral,
	TabCmt,
	LocationUtils,
	addStoreMiddleware,
	Storages,
} from "funkis-foundation";
import {
	setup,
	addStoreObservers,
	setInitialCourseStatus,
} from "funkis-template/actions/TemplateSetup";
import * as quiddityUtils from "funkis-template/utils/quiddityUtils";

import {
	Provider as QuiddityProvider,
	TabFunkisCloud,
	LoginTypes,
	QuiddityStorage,
} from "quiddity";
import { videoManager } from "./middlewares/StoreMiddleWares";
import Reveals from "./inspector/components/Reveals";
import BodyClassUpdater from "./BodyClassUpdater";

const { MEMBER_GROUP_LOGIN } = LoginTypes;

const App = ({ basePath = "" }) => {
	const [config, app] = useSelector((state) => {
		return [state.config.item, state.app.item];
	}, shallowEqual);

	// Get session key...
	const localStorageKey = app?.id && app.id + "_session_key";
	const sessionKey = quiddityUtils.getSessionKey({
		config: config,
		cmtConfig: app,
		localStorageKey,
	});

	const host = window.location.host;
	const okHosts = [
		"localhost",
		"lvh.me",
		"192.168",
		"aws.quiddity.se",
		"test.quiddity.se",
		"test.funkis.nu",
	];
	const isCookieTrustedHost = okHosts.some((okHost) => host.includes(okHost));
	const quiddityEnabled = config && config.backend === "quiddity";

	const [isCompletedSetup, setIsCompletedSetup] = useState(false);
	const [isLoggedInToQuiddity, setIsLoggedInToQuiddity] = useState(false);

	useEffect(() => {
		LocationUtils.redirectIfLinkIsShared();

		// Add store middlewares...
		addStoreMiddleware(videoManager); // Paueses playing videos if other video is started.

		// Add storages
		Storages.addStorage("quiddity", new QuiddityStorage());

		// Initialize the funkis-template project...
		setup({ basePath: basePath }).then((setupProps) => {
			setIsCompletedSetup(true);
		});
	}, []);

	// Setup store observers...
	useEffect(() => {
		if (isCompletedSetup) {
			// If quiddity us used as backend, Wait for user is logged in
			if (quiddityEnabled) {
				if (isLoggedInToQuiddity) {
					setInitialCourseStatus({ config });
					addStoreObservers({ config });
				}
			} else {
				// If not quiddity....
				addStoreObservers({ config });
				setInitialCourseStatus({ config });
			}
		}
	}, [quiddityEnabled, isCompletedSetup, isLoggedInToQuiddity]);

	if (!config || !app || !isCompletedSetup) {
		return <div />;
	}

	return (
		<div className={classnames("App")}>
			<Location config={config}>
				<Main
					config={config}
					app={app}
					renderContent={
						(quiddityEnabled && isLoggedInToQuiddity) || !quiddityEnabled
					}
				>
					<BodyClassUpdater />
					<QuiddityProvider
						loginType={MEMBER_GROUP_LOGIN}
						store={StoreUtils.getStore()}
						url={isCookieTrustedHost ? "" : app.quiddity_url}
						api={config.quiddity.api}
						socketUrl={app.quiddity_socket_url}
						loginUrl={config.quiddity.loginUrl}
						st={!isCookieTrustedHost}
						code={sessionKey}
						enabled={quiddityEnabled}
						loginDialog={config.backend === "quiddity" && "user"}
						autoAttemptMicrosoftLogin={false}
						onLoginSuccess={() => {
							setIsLoggedInToQuiddity(true);
						}}
					>
						<Inspector
							tabs={[
								{
									title: "Pages",
									element: TabPages,
									props: {
										pageRenderFunction: Reveals,
									},
								},
								{
									title: "General",
									element: TabGeneral,
								},
								{
									title: "FAT",
									element: TabCmt,
								},
								...(config.backend == "quiddity"
									? [
											{
												title: "Funkis Cloud",
												element: TabFunkisCloud,
											},
									  ]
									: []),
							]}
						/>
					</QuiddityProvider>
				</Main>
			</Location>
		</div>
	);
};

export default App;
