import React from "react";
import classnames from "classnames";
import { ReduxObjectSelectors } from "funkis-foundation";
import Style from "./SpeechBubble.module.css";
import SquareBubbleArrow from "./../../assets/svg/SqareBubbleArrow";
import { getBootstrapColor } from "../../utils/colorUtils";
import { connect } from "../../utils/reduxUtils";

const { makeGetValueSelector } = ReduxObjectSelectors;

const SpeechBubble = (props) => {
	const { variant = "default" } = props;
	switch (variant) {
		case "square":
			return <SpeechBubbleSquare {...props} />;
		default:
			return <SpeechBubbleDefault {...props} />;
	}
};

const SpeechBubbleDefault = ({
	children,
	className,
	classNameContent,
	classNameSpeechBubble,
	arrowDirection = "up",
	color = "primary",
	bgTransparent = false,
	noFillet = false,
	onHoverStart,
	onHoverEnd,
	programType,
}) => {
	const bubbleColor = getBootstrapColor(color);
	const styleArrowDown = arrowDirection === "down" && {
		borderTopColor: bubbleColor,
	};
	const styleArrowUp = arrowDirection === "up" && {
		borderBottomColor: bubbleColor,
	};

	return (
		<div
			className={classnames(
				"speech-bubble",
				arrowDirection === "down" && "mb-4",
				className,
				Style.root
			)}
			onMouseEnter={onHoverStart}
			onMouseLeave={onHoverEnd}
		>
			<div className={classnames(Style.speechBubbleContainer)}>
				<div
					className={classnames(
						bgTransparent,
						(arrowDirection === "up" && Style.arrowUp) || Style.arrowNo
					)}
					style={{ borderColor: bubbleColor }}
				/>
				<div
					className={classnames(
						"speech-bubble-container",
						Style.speechBubble,
						programType === "slide" && Style.speechBubbleSlide,
						bgTransparent && "opacity-60",
						classNameSpeechBubble,
						noFillet && Style.noFillet
					)}
					style={{ background: bubbleColor }}
				/>
				<div
					className={classnames(
						Style.contentContainer,
						"px-6 py-2 w-100 speech-bubble-sm font-weight-bold",
						classNameContent
					)}
				>
					{children}
				</div>
				<div
					className={classnames(
						"speech-bubble-arrow",
						bgTransparent && "opacity-60",
						(arrowDirection === "down" && Style.arrowDown) || Style.arrowNo
					)}
					style={{
						borderTopColor:
							arrowDirection === "down" ? bubbleColor : "transparent",
					}}
					style={styleArrowDown || styleArrowUp || {}}
				/>
			</div>
		</div>
	);
};

const SpeechBubbleSquare = ({
	children,
	className,
	classNameContentContainer,
	classNameArrow,
	arrowDirection = "down",
	fillColor,
	strokeColor,
}) => {
	return (
		<div className={classnames("speech-bubble mb-4", Style.root, className)}>
			<div
				className={classnames(
					Style.square,
					"px-2 py-1 container-container",
					classNameContentContainer
				)}
				style={{
					backgroundColor: getBootstrapColor(fillColor || "white"),
					border: `1px solid ${getBootstrapColor(strokeColor || "gray")}`,
				}}
			>
				{children}
			</div>
			<SquareBubbleArrow
				className={classnames(
					"arrow",
					arrowDirection === "down" && Style.smallArrowDown,
					arrowDirection === "up" && Style.smallArrowUp,
					classNameArrow
				)}
				fillColor={getBootstrapColor(fillColor || "white")}
				strokeColor={strokeColor}
			/>
		</div>
	);
};

const makeMapStateToProps = () => {
	const getValueSelector = makeGetValueSelector();

	const mapStateToProps = (state, props) => {
		return {
			programType: getValueSelector(
				state,
				{ ...props, path: "program_type" },
				"app"
			),
		};
	};
	return mapStateToProps;
};

export default connect(SpeechBubble, makeMapStateToProps);
