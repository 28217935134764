export enum GlobalText {
	NextQuestionButton = "304AEA82-DE4E-4B88-9FEE-4B4A93BDA945",
	ValidationMissingAnswers = "51796185-5CC9-43B5-BE15-7132BB650772",
	ShowResult = "38D82364-95A6-4243-8D18-3EC6725FE8B4",
	QuestionTitle = "FD3E9E13-5BBC-4B2E-B61E-3A90E2C67629",
	OptionsTitle = "E840E68E-C3A6-4749-B4F2-EAD1CA30FD8B",
	UserAnswer = "2675B8DF-520F-4154-A640-ABDF708EBFD7",
	CorrectAnswer = "AB16041A-F631-4199-ACAA-0AE65CB5C3EA",
	CorrectAnswers = "21748F99-BEE7-41BB-AF67-E7F350D416E3",
	ResetQuiz = "917FBA9F-1960-4678-88AA-8D79F3272AFE",
}
