import React, { useRef } from "react";
import classnames from "classnames";
import { FFButton, FFImage } from "funkis-foundation";
import Text from "funkis-template/components/core/Text";
import SpeechBubble from "funkis-template/components/core/SpeechBubble";
import Style from "./DilemmaCard.module.css";
import { getLetterByIndex } from "funkis-template/utils/stringUtils";
import IconCross from "funkis-template/assets/svg/IconCross";
import IconCheck from "funkis-template/assets/svg/IconCheck";
import IconNeutral from "funkis-template/assets/svg/IconNeutral";
import { getBootstrapColor } from "funkis-template/utils/colorUtils";
import styled from "styled-components";
import Button from "components/Button/Button";

const BackgroundImage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(${({ src }) => src});
	background-size: cover;
`;

const Icon = ({
	selected = false,
	score = 0,
	mode = "scroll",
	color,
	imageCorrect,
	imageIncorrect,
	imageNeutral,
}) => {
	const IconComp =
		(selected && score > 0 && !imageCorrect && IconCheck) ||
		(selected && score < 0 && !imageIncorrect && IconCross) ||
		(selected && score === 0 && !imageNeutral && IconNeutral);

	const imageSrc =
		(selected && score > 0 && imageCorrect) ||
		(selected && score < 0 && imageIncorrect) ||
		(selected && score === 0 && imageNeutral);

	if (IconComp) {
		return (
			<IconComp
				size="lg"
				color={color}
				className="animated fadeIn delay-2500ms"
				scale={mode === "slide" ? 2 : 1}
				styleCheck={{ marginRight: mode === "slide" ? "100px" : "20px" }}
			/>
		);
	} else if (imageSrc) {
		return <FFImage src={`./content/images/${imageSrc}`} />;
	} else {
		return <React.Fragment />;
	}
};

const DilemmaCard = (props) => {
	const {
		index,
		header,
		body,
		buttonLabel,
		onChooseClick,
		onReadMoreClick,
		className,
		score = 0,
		selected = false,
		mode = "scroll",
		imageCorrect,
		imageIncorrect,
		imageNeutral,
		backgroundImage,
	} = props;

	const outerTextContainer = useRef(null);
	const innerTextContainer = useRef(null);

	const innerHeight =
		(innerTextContainer.current && innerTextContainer.current.clientHeight) ||
		0;
	const outerHeight =
		(outerTextContainer.current && outerTextContainer.current.clientHeight) ||
		0;
	const hasMoreText = innerHeight - outerHeight > 10;
	const dim = selected && score < 1;

	const color =
		(selected && score > 0 && getBootstrapColor("success")) ||
		(selected && score < 0 && getBootstrapColor("danger")) ||
		(selected && score === 0 && getBootstrapColor("gray"));

	return (
		<div
			className={classnames(
				"dilemma-card",
				dim && "opacity-30",
				Style.root,
				mode === "scroll" && Style.modeScroll,
				mode === "slide" &&
					index === 0 &&
					"border-gray border-right border-bottom",
				mode === "slide" && index === 1 && "border-gray border-bottom",
				mode === "slide" && index === 2 && "border-gray border-right",
				className
			)}
		>
			{backgroundImage && (
				<BackgroundImage src={`./content/images/${backgroundImage}`} />
			)}
			<div
				className={
					"position-absolute p-3 w-100 h-100 d-flex flex-column justify-content-stretch"
				}
				style={{ top: 0, left: 0, zIndex: 1 }}
			>
				<div className="overflow-hidden flex-grow-1" ref={outerTextContainer}>
					<div ref={innerTextContainer}>
						{mode === "scroll" && (
							<h2 className="d-inline font-weight-bold">
								{getLetterByIndex(index, true) + ". "}
							</h2>
						)}
						{mode === "slide" && (
							<h1 className="d-inline font-weight-bold">
								{getLetterByIndex(index, true) + ". "}
							</h1>
						)}
						{mode === "slide" && <br />}
						<Text
							className="d-inline font-weight-bold"
							tagName={mode === "slide" ? "h2" : "span"}
							textId={header}
						/>
						<br />
						<Text
							textId={body}
							className={classnames(mode === "slide" && "p-lg")}
						/>
					</div>
				</div>
			</div>

			{hasMoreText && (
				<FFButton
					className={classnames(Style.readMoreBubble, "read-more-button m-2")}
					onClick={() => {
						onReadMoreClick && onReadMoreClick(props);
					}}
				>
					<SpeechBubble
						variant="square"
						arrowDirection="down"
						classNameContent="p-1 px-3"
					>
						<Text textId="D7167001-86A5-4A4E-901A-1D754F2C-42A5-4FAD-AD20-AAACAD0701A8" />
					</SpeechBubble>
				</FFButton>
			)}

			{/* BUTTON (choose) */}
			<div className={classnames("mb-2", Style.buttonChooseContainer)}>
				<Button
					onClick={() => {
						onChooseClick && onChooseClick(props);
					}}
				>
					<Text
						textId={buttonLabel || "C06A9D6E-297C-4E12-BF9B-4B62AA0BE048"}
					/>
				</Button>
			</div>
			{/* OVERLAY ICONS */}
			<div className={Style.overlayIcons}>
				<Icon
					color={color}
					mode={mode}
					selected={selected}
					score={score}
					imageCorrect={imageCorrect}
					imageIncorrect={imageIncorrect}
					imageNeutral={imageNeutral}
				/>
			</div>
		</div>
	);
};

export default DilemmaCard;
