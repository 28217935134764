import Text, { TextVariables } from "funkis-template/components/core/Text";
import { TextId } from "funkis-template/models/player";
import React, { FC } from "react";
import Style from "./Heading.module.css";

export type HeadingProps = {
	textId: TextId;
	level: "1" | "2" | "3" | "4" | "5";
	variables?: TextVariables;
	balance?: boolean;
	weight?: "regular" | "weak";
};

const tagNameForLevel: Record<HeadingProps["level"], React.ElementType> = {
	"1": "h1",
	"2": "h2",
	"3": "h3",
	"4": "h4",
	"5": "h5",
};

const Heading: FC<HeadingProps> = ({
	textId,
	level,
	variables,
	balance = true,
}) => (
	<Text
		className={Style.heading}
		tagName={tagNameForLevel[level]}
		textId={textId}
		variables={variables}
		balance={balance}
	/>
);

export default Heading;
