import React from "react";

const IconStatusIncomplete = ({
	className,
	style,
	color = "#00005A",
	secondaryColor = "#BABABA",
	variant = "normal",
}) => {
	if (variant === "large") {
		<svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="maIn-menu/page-menu-item/status-in-progress"
					transform="translate(-3.000000, -3.000000)"
				>
					<g id="Group-4" transform="translate(3.000000, 3.000000)">
						<path
							d="M14.999,0 L15,15 L27.4495984,23.369799 C24.7554669,27.369284 20.1849518,30 15,30 C6.71572875,30 0,23.2842712 0,15 C0,6.71572875 6.71572875,0 15,0 L14.999,0 Z"
							id="Combined-Shape"
							fill={secondaryColor}
						></path>
						<path
							d="M15,0 C23.2842712,0 30,6.71572875 30,15 C30,18.0993195 29.060023,20.9790956 27.4495984,23.369799 L15,15 Z"
							id="Combined-Shape"
							fill={color}
						></path>
					</g>
				</g>
			</g>
		</svg>;
	}

	return (
		<svg
			width="24px"
			height="24px"
			className={className}
			style={style}
			viewBox="0 0 24 24"
			version="1.1"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="Group-4">
					<path
						d="M12,0 L12,12 L21.9594742,18.6961428 C19.8041422,21.8955603 16.1478302,24 12,24 C5.372583,24 -5.32907052e-15,18.627417 -5.32907052e-15,12 C-5.32907052e-15,5.372583 5.372583,0 12,0 L12,0 Z"
						id="Combined-Shape"
						fill={secondaryColor}
					></path>
					<path
						d="M12,-1.56319402e-13 C18.627417,-1.56319402e-13 24,5.372583 24,12 C24,14.4795868 23.2479388,16.7835203 21.9594742,18.6961428 L12,12 Z"
						id="Combined-Shape"
						fill={color}
					></path>
				</g>
				<rect
					id="Rectangle-Copy-4"
					stroke="#CA0000"
					strokeWidth="11"
					x="-140"
					y="-2188"
					width="4075"
					height="5819"
				></rect>
			</g>
		</svg>
	);
};

export default IconStatusIncomplete;
