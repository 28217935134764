import React, { useState } from "react";
import classnames from "classnames";
import _ from "lodash";
import { FFButton } from "funkis-foundation";
import TextareaAutosize from "react-textarea-autosize";

import Style from "./TextInput.module.css";
import { getBootstrapColor } from "../../utils/colorUtils";

const TextInput = ({
	className,
	style,
	classNameTextArea,
	styleTextArea,
	onChange,
	value = "",
	rows,
	maxLength,
	placeholder,
	autoComplete,
	editable = true,
	borderColor = "gray",
	borderColorFocus = "interaction",
	onClick,
	onEnter,
	onBlur,
	onFocus,
	refFwd,
	...rest
}) => {
	const overrideBackgroundColor =
		styleTextArea &&
		(styleTextArea.background || styleTextArea.backgroundColor);

	const [focus, setFocus] = useState(false);

	/* uggly fix for when we don't want any resize and instead want a fixed height. see issue #1082 */
	const onHeightChange = () => {
		if (_.get(styleTextArea, "height") && _.get(refFwd, "current")) {
			/* "refFwd.current.style.height = styleTextArea.height" should work, but somehow looses old styles. */
			const oldInlineStyle = refFwd.current.style.cssText;
			const heightOverride = `${styleTextArea.height}`.replace("px", "");
			const newStyle = oldInlineStyle.replace(
				/height:(.+?);/,
				`height:${heightOverride}px;`
			);
			refFwd.current.style.cssText = newStyle;
		}
	};

	return (
		<div className={classnames("position-relative", className)} style={style}>
			{editable ? (
				<React.Fragment>
					<TextareaAutosize
						ref={refFwd}
						minRows={rows}
						className={classnames(
							Style.root,
							"py-2 px-2 w-100 input-text input",
							!value && "font-italic",
							!overrideBackgroundColor && "bg-light-gray",
							classNameTextArea
						)}
						style={{
							...styleTextArea,
							borderColor: getBootstrapColor(
								focus ? borderColorFocus : borderColor
							),
						}}
						autoComplete={autoComplete}
						onChange={(e) => {
							onChange && onChange(e.target.value);
						}}
						onHeightChange={onHeightChange}
						onKeyDown={(e) => {
							const actionKey = e.altKey || e.shiftKey;
							if (!actionKey && e.key === "Enter" && onEnter) {
								onEnter && onEnter({ value });
								onEnter && e.preventDefault();
							}
						}}
						onFocus={() => {
							setFocus(true);
							onFocus && onFocus();
						}}
						onBlur={() => {
							setFocus(false);
							onBlur && onBlur();
						}}
						placeholder={placeholder}
						maxLength={maxLength}
						value={value}
						{...rest}
					/>
					{maxLength && focus && (
						<span className="d-block absolute-bottom absolute-right mr-4 mb-1 instruction font-italic">{`${value.length}/${maxLength}`}</span>
					)}
				</React.Fragment>
			) : (
				<FFButton onClick={onClick} enabled={Boolean(onClick)}>
					<p className="animated fadeIn font-italic">{value}</p>
				</FFButton>
			)}
		</div>
	);
};

export default TextInput;
