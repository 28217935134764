import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import * as PageItemActions from "../redux/actions/PageItemActions";
import * as LocationActions from "../redux/actions/LocationActions";
import * as StatusConstants from "../constants/StatusConstants";
import * as StoreUtils from "../utils/StoreUtils";
import * as PagesUtils from "../utils/PagesUtils";
import * as LocationUtils from "../utils/LocationUtils";
import { camelToDash } from "../utils/StringUtils";
import FFButton from "../components/FFButton";
import { makeGetPageByIdSelector } from "../selectors/PageSelectors";

class PageItemMenuButton extends React.Component {
	_onClick = () => {
		const { page, pageItem, onClick } = this.props;
		if (!pageItem.enabled) {
			/* 
      TODO: 
      this shouldn't happen because the button gets "pointer-events: none"; 
      But somehow it does
      */
			return;
		}

		PageItemActions.updatePageItem(pageItem.id, {
			status: StatusConstants.COMPLETED,
		});
		if (page && !pageItem.disableUpdateLocation) {
			const pages = StoreUtils.getReducer("pages").items;
			const locationPath = PagesUtils.getLocationPathForPage(pages, page);
			const location =
				LocationUtils.convertLocationPathToLocation(locationPath);
			LocationActions.updateLocation(location);
		}
		onClick && onClick(this.props);
	};

	render() {
		const { pageItem, page, style, className, children, rest } = this.props;
		const pageStatus =
			page && page.status && `page-status-${camelToDash(page.status)}`;
		return (
			<FFButton
				onClick={this._onClick}
				style={Object.assign({}, pageItem.style, style)}
				className={classnames(
					className,
					"page-item page-item-menu-button",
					pageItem.className,
					pageStatus
				)}
				children={children}
				enabled={pageItem.enabled}
				stopPropagation={pageItem.stopPropagation}
				tagName={pageItem.tagName}
				rest={rest}
			/>
		);
	}
}

const makeMapStateToProps = () => {
	const getPageByIdSelector = makeGetPageByIdSelector();
	const mapStateToProps = (state, props) => {
		const { pageItem } = props;
		const pageId = pageItem.pageId;
		return {
			page: getPageByIdSelector(state, { pageId }),
		};
	};
	return mapStateToProps;
};

export default connect(makeMapStateToProps)(PageItemMenuButton);
