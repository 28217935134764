import { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { ReduxCollectionSelectors } from "funkis-foundation";
import { getQueryForContext } from "../../utils/QuidInputUtils";
import {
	upsertQuidInput,
	createQuidInput,
	deleteQuidInput,
} from "../../requests/QuidInputRequests";
import { getReducer } from "../../utils/StoreUtils";
import { useSelector, shallowEqual } from "react-redux";
const { makeGetItemByIdSelector } = ReduxCollectionSelectors;

const QuiddityBaseInput = (props) => {
	const {
		renderFunction,
		quidInput,
		quidTemplateId,
		quidInputContext,
		onRemove,
		quidInputIds,
		match,
	} = props;

	const [data, setData] = useState((quidInput && quidInput.data) || {});
	const [isLoading, setIsLoading] = useState(false);

	const quidInputs = useSelector((state) => {
		let quidInputsById = state.quidInputs.itemsById;
		// If quidInputIds specified...
		if (quidInputIds && quidInputIds.length) {
			return quidInputIds.map((id) => quidInputsById[id]);
		}
		// If match object specified...
		if (match) {
			return _.filter(state.quidInputs.items, match, []);
		}

		return [];
	}, shallowEqual);

	useEffect(() => {
		if (quidInput) {
			setData(quidInput.data);
		}
	}, [quidInput]);

	const _updateData = (updates) => {
		setData({ ...data, ...updates });
	};

	const _hasDataForKeys = (keys) => {
		return keys.every((key) => data.hasOwnProperty(key) && data[key] !== "");
	};

	const _hasSavedDataForKeys = (keys) => {
		return keys.every(
			(key) =>
				quidInput && quidInput.data.hasOwnProperty(key) && data[key] !== ""
		);
	};

	const _hasUnsavedDataForKeys = (keys) => {
		if (!quidInput && _hasDataForKeys(keys)) {
			return true;
		} else if (quidInput && quidInput.data && _hasDataForKeys(keys)) {
			return !_.isEqual(data, quidInput.data);
		} else {
			return false;
		}
	};

	const _postOrPutQuidInput = async (alwaysCreate, createdData) => {
		setIsLoading(true);
		const quiddity = getReducer("quiddity").item;
		const { url, api } = quiddity;
		const query = getQueryForContext(quidInputContext, {
			...props,
			...quiddity,
			id: quidInput && quidInput.id,
			data: { ...(alwaysCreate ? {} : data), ...createdData },
			sourceQuid: quidTemplateId,
		});
		const result = alwaysCreate
			? await createQuidInput(url + api, query)
			: await upsertQuidInput(url + api, query);
		setIsLoading(false);
		return result;
	};

	const _remove = async (quidInputId) => {
		setIsLoading(true);
		const quiddity = getReducer("quiddity").item;
		const { url, api } = quiddity;
		const res = await deleteQuidInput(url + api, quidInputId || quidInput.id);
		onRemove && onRemove({ data: quidInput.data });
		setIsLoading(false);
		return quidInput;
	};

	if (!quidTemplateId) {
		return "loading...";
	} else {
		return renderFunction({
			...props,
			isLoading,
			children: null,
			id: props.id || props.quidInputId,
			input: quidInput,
			updateData: (updates) => {
				_updateData(updates);
			},
			save: _postOrPutQuidInput,
			create: (createdData = {}) => {
				return _postOrPutQuidInput(true, createdData);
			},
			remove: (quidInputId) => {
				return _remove(quidInputId);
			},
			hasDataForKeys: _hasDataForKeys,
			hasSavedDataForKeys: _hasSavedDataForKeys,
			hasUnsavedDataForKeys: _hasUnsavedDataForKeys,
			data,
			quidInputs,
		});
	}
};

const makeMapStateToProps = () => {
	const getItemByIdSelector = makeGetItemByIdSelector();
	const mapStateToProps = (state, props) => {
		return {
			quidInput: getItemByIdSelector(
				state,
				{
					...props,
					id: props.quidInputId,
				},
				"quidInputs"
			),
		};
	};
	return mapStateToProps;
};

export default connect(makeMapStateToProps)(QuiddityBaseInput);
