import React from "react";

const IconMustach = () => {
	return (
		<svg
			width="375px"
			height="51px"
			viewBox="0 0 375 51"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>bracket copy</title>
			<g
				id="VUXENSYSKON-WORK-**********************"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="6.-RELATIONER-DESKTOP"
					transform="translate(-453.000000, -5105.000000)"
				>
					<g id="Group-3" transform="translate(453.000000, 5105.000000)">
						<g id="Clip-2"></g>
						<path
							d="M374.790737,50.6142164 C372.136942,38.2296 361.046987,31.121737 338.965513,31.121737 L225.210603,31.121737 C214.788281,31.121737 206.587165,29.5246685 200.61596,26.3403123 C194.645424,23.1510658 190.052344,15.9160521 187.395201,4.6415589 C184.738058,15.9160521 180.145647,23.1510658 174.174442,26.3403123 C168.203237,29.5246685 160.00279,31.121737 149.580469,31.121737 L35.8248884,31.121737 C13.7440848,31.121737 2.65345982,38.2296 0.000334821429,50.6142164 C0.000334821429,45.8411753 0.475111607,41.3238329 1.42198661,37.0656822 C2.37220982,32.8040384 4.31350446,29.0978055 7.24988839,25.9455863 C10.1909598,22.7905726 14.3139509,20.2782986 19.618192,18.4045726 C24.9271205,16.5280521 32.0347098,15.5932849 40.9429688,15.5932849 L154.129353,15.5932849 C163.605469,15.5932849 170.855692,14.5704904 175.880692,12.5249014 C180.901004,10.4807096 184.738728,6.30290137 187.395201,-0.000139726027 C190.051674,6.30290137 193.890067,10.4807096 198.910379,12.5249014 C203.93471,14.5704904 211.184933,15.5932849 220.661049,15.5932849 L333.848103,15.5932849 C342.755692,15.5932849 349.863281,16.5280521 355.17221,18.4045726 C360.476451,20.2782986 364.599442,22.7905726 367.540513,25.9455863 C370.477567,29.0978055 372.418192,32.8040384 373.369085,37.0656822 C374.31529,41.3238329 374.790737,45.8411753 374.790737,50.6142164"
							id="Fill-1"
							fill="#000000"
							mask="url(#mask-4)"
						></path>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default IconMustach;
