import React from "react";

function IconIncorrectCross({ className }) {
	return (
		<div className={className}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g transform="translate(-717 -6961)">
						<path fill="transparent" d="M0 0H1280V11164H0z"></path>
						<g transform="translate(500 6951)">
							<path
								stroke="#EEE"
								strokeWidth="10"
								d="M5.247 5H266.753V151H5.247z"
							></path>
						</g>
						<g transform="translate(717 6961)">
							<path fill="transparent" d="M0 44.5L44.5 44.5 44.5 0 0 0z"></path>
							<path
								fill="#C24400"
								d="M39.1135 9.3924L33.9205 4.2004 20.8755 17.2454 7.8305 4.2004 2.6355 9.3924 15.6805 22.4384 2.6355 35.4844 7.8305 40.6774 20.8755 27.6314 33.9205 40.6774 39.1135 35.4844 26.0685 22.4384z"
							></path>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
}

export default IconIncorrectCross;
