import React from "react";
import classnames from "classnames";
import { FFText, FFButton } from "funkis-foundation";
import TextInput from "../core/TextInput";
import Text from "../core/Text";
import ImageWithCaption from "../core/ImageWithCaption";

const IOText = (props) => {
	const { pageItem, updateData, save, data, showSendButton } = props;
	const { cmtPageItem } = pageItem;

	return (
		<React.Fragment>
			{cmtPageItem.image && (
				<ImageWithCaption src={cmtPageItem.image} className="col-sm-12 mb-2" />
			)}
			<Text
				className="col-md-8 mb-5"
				tagName="h2"
				textId={cmtPageItem.header}
			/>
			<Text className="col-md-10 mb-4" tagName="p" textId={cmtPageItem.body} />
			<TextInput
				className="col-sm-12 mb-4"
				rows={5}
				value={data.value}
				onChange={(value) => {
					updateData({ value });
				}}
			/>
			<div className="row m-0 justify-content-center">
				<FFButton
					className={classnames(
						"col-sm-4 rounded-pill btn btn-black col animated",
						showSendButton ? "fadeIn" : "fadeOut"
					)}
					onClick={() => {
						save();
					}}
				>
					<FFText
						textId="8C7F6802-68DF-4221-9F77-C649DC7E72A2"
						className="text-uppercase"
					/>
				</FFButton>
			</div>
		</React.Fragment>
	);
};

export default IOText;
