import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

const SimpleJsonRender = ({
	json,
	commaSeparatedFieldSelection,
	title,
	link,
}) => {
	return (
		<div className="simple-json-render text-white border-bottom py-1 d-flex flex-column">
			<h4
				onClick={() => console.log(json)}
				className="font-weight-bold text-white btn text-left pl-0"
			>
				{title}
			</h4>
			{/* <pre className="text-white p-1">{JSON.stringify(_.pick(json, commaSeparatedFieldSelection.split(",")), null, 1)}</pre> */}
			<table>
				<tbody>
					{commaSeparatedFieldSelection.split(",").map((key) => (
						<tr>
							<td className="w-25 pr-3">{key}</td>
							<td className="w-75">{_.get(json, key)}</td>
						</tr>
					))}
				</tbody>
			</table>
			{link && (
				<a target="_blank" rel="noreferrer" href={link}>
					🌐 Funkis cloud admin
				</a>
			)}
		</div>
	);
};

const TabFunkisCloud = ({ isInspectorOpen }) => {
	if (!isInspectorOpen) return <div></div>;

	const user = useSelector((state) => _.get(state, "quiddity.item.user"));
	const member = useSelector((state) => _.get(state, "quiddity.item.member"));
	const group = useSelector((state) => _.get(state, "quiddity.item.group"));
	const session = useSelector((state) => _.get(state, "quiddity.item.session"));
	const event = useSelector((state) => _.get(state, "quiddity.item.event"));
	const title = useSelector((state) => _.get(state, "quiddity.item.title"));

	// only localhost has port
	const isLocalhost = window.location.host.includes(":");
	const getOriginFromTitle = (title, fallback = window.location.origin) => {
		const launchUrl = _.get(title, "launchInfo.url", fallback);
		const origin = new URL(launchUrl).origin;
		return origin;
	};
	const origin = isLocalhost
		? getOriginFromTitle(title)
		: window.location.origin;
	const adminBaseUrl = `${origin}/funkis-admin/#`;
	const titleAdminLink = `${adminBaseUrl}/titles/${title?.id}`;
	const eventAdminLink = `${titleAdminLink}/${event?.id}`;
	const sessionAdminLink = `${eventAdminLink}/${session?.id}`;

	const qUser = user || _.get(member, "user");
	return (
		<div className="p-3 text-white">
			<p>Click header to log full object</p>
			{qUser && (
				<SimpleJsonRender
					key="1"
					title="User"
					json={qUser}
					commaSeparatedFieldSelection="firstName,lastName,email,id"
				/>
			)}
			{member && (
				<SimpleJsonRender
					key="2"
					title="Member"
					json={member}
					commaSeparatedFieldSelection="id,group,quidditySession"
				/>
			)}
			{group && (
				<SimpleJsonRender
					key="3"
					title="Group"
					json={group}
					commaSeparatedFieldSelection="id,group,name,quidditySession"
				/>
			)}
			{session && (
				<SimpleJsonRender
					key="4"
					title="Session"
					json={session}
					commaSeparatedFieldSelection="id,code,title"
					link={sessionAdminLink}
				/>
			)}
			{event && (
				<SimpleJsonRender
					key="5"
					title="Event"
					json={event}
					commaSeparatedFieldSelection="id,title,organization"
					link={eventAdminLink}
				/>
			)}
			{title && (
				<SimpleJsonRender
					key="6"
					title="Title"
					json={title}
					commaSeparatedFieldSelection="id,title,organization,launchInfo.url"
					link={titleAdminLink}
				/>
			)}
		</div>
	);
};

export default TabFunkisCloud;
