import { QuidInput } from "funkis-template/hooks/backend";
import _ from "lodash";
import { sortQuidInputsByTime } from "utils/TimeUtils";

export function calculateRateDataFromQuidInputs(quidInputs: QuidInput[]) {
	const quidInputsSortedByTime = sortQuidInputsByTime(
		quidInputs
	) as QuidInput[];

	// Filter out potential duplicates
	const uniqueQuidInputs = _.uniqBy(
		quidInputsSortedByTime,
		(quidInput) => quidInput.member + quidInput.targetDataKey
	);

	return {
		averageValue: calculateAverage(uniqueQuidInputs),
		medianValue: calculateMedian(uniqueQuidInputs),
		coefficientOfVariation: calculateCoefficientOfVariation(uniqueQuidInputs),
	};
}

function calculateAverage(quidInputs: QuidInput[]): number {
	return _.sumBy(quidInputs, "data.value") / quidInputs.length;
}

function calculateDeviation(quidInputs: QuidInput[]): number {
	const mean = calculateAverage(quidInputs);

	const newMean =
		_.chain(quidInputs)
			.map("data.value")
			.map((val) => (val - mean) ** 2)
			.sum()
			.value() / quidInputs.length;

	const deviation = Math.sqrt(newMean);

	return deviation;
}

function calculateCoefficientOfVariation(quidInputs: QuidInput[]): number {
	const mean = calculateAverage(quidInputs);
	const standardDeviation = calculateDeviation(quidInputs);

	return standardDeviation / mean;
}

function calculateMedian(quidInputs: QuidInput[]): number {
	const sortedValues = _.chain(quidInputs).map("data.value").sort().value();

	const medianIndex = Math.ceil(quidInputs.length / 2);
	return sortedValues[medianIndex];
}
