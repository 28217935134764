import React from "react";
import { getBootstrapColor } from "../../utils/colorUtils";
import classnames from "classnames";

const Badge = ({
	className,
	backgroundColor = "white",
	backgroundColorCounter = "white",
	colorCounter = "black",
	padding = "3px",
	size = "25px",
	imageUrl,
	showCount = false,
	count = 1,
	style,
}) => {
	return (
		<div
			className={classnames("badge position-relative", className)}
			style={style}
		>
			<div
				className={classnames(
					"absolute-top absolute-right",
					!showCount && "display-none"
				)}
			>
				<div
					className="d-flex absolute-top justify-content-center align-items-center"
					style={{
						padding: "0.25rem",
						height: "1rem",
						minWidth: "1rem",
						borderRadius: "0.5rem",
						background: getBootstrapColor(backgroundColorCounter),
						marginLeft: "-1rem",
					}}
				>
					<span
						className="topbar-header-sm font-weight-bold"
						style={{
							color: getBootstrapColor(colorCounter),
							paddingTop: "2px",
						}}
					>
						{count}
					</span>
				</div>
			</div>
			<div
				style={{
					borderRadius: "50%",
					padding,
					background: getBootstrapColor(backgroundColor),
				}}
			>
				<img src={imageUrl} height={size} width={size} />
			</div>
		</div>
	);
};

export default Badge;
