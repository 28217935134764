import iconSaveStatusDirty from "assets/images/icon_save_status_dirty.svg";
import iconSaveStatusSaved from "assets/images/icon_save_status_saved.svg";
import { useText } from "funkis-template/hooks/text";
import { FC } from "react";

type StatusIndicatorProps = {
	status: "saved" | "saving" | "idle";
};

const statusTexts: Record<StatusIndicatorProps["status"], string> = {
	idle: "",
	saving: "73E6DA40-5A00-4F13-9E64-48F331DE15D9",
	saved: "361398F2-B058-4908-83F5-52C7832D9248",
};

const StatusIndicator: FC<StatusIndicatorProps> = ({ status }) => {
	const statusTextId = statusTexts[status];
	const text = useText(statusTextId);

	switch (status) {
		case "idle":
			return <div style={{ width: 15, height: 17 }} />;

		case "saving":
			return (
				<img
					title={text}
					width={15}
					height={17}
					src={iconSaveStatusDirty}
					alt={text}
				/>
			);

		case "saved":
			return (
				<img
					title={text}
					width={15}
					height={17}
					src={iconSaveStatusSaved}
					alt={text}
				/>
			);
	}
};

export default StatusIndicator;
