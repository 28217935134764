import React, { useEffect } from "react";
import DeprecatedPageItemError from "errors/DeprecatedPageItemError";
import { PageItems, Pages } from "funkis-foundation";
import {
	// Pages
	PageIntro_v3,
	PageSelfStudyGroupSetup,
	PageMenuScroll,
	PageLanguageMenu,
	//  Misc
	PageItemPageHeader,
	PageItemPageFooter,
	PageItemReveal,
	PageItemTopBar,
	PageItemSideMenu,
	PageItemChapterButton,
	PageItemPageButton,
	// Blocks
	PageItemBullets_v2,
	PageItemQuote_v2,
	PageItemImage,
	PageItemArticle,
	PageItemArticleCompact,
	PageItemConversation_v2,
	PageItemVideoConversation,
	PageItemVideoPlayer,
	PageItemCourseStatus,
	PageItemLinks,
	PageItemEmbeddedVideo,
	PageItemLinkedBlock,
	PageItemMatchPairs,
	PageItemReadMore,
	PageItemTable,
	PageItemHotspots,
	PageItemNavigationButtons,
	PageItemIOCommentInput,
	PageItemIOCommentOutput,
	PageItemIOPostItInput,
	PageItemIOText,
	PageItemRateInput,
	PageItemRateOutput,
	PageItemIOActionPriorityMatrixInput,
	PageItemIOListsInput,
	PageItemIOMultipleChoiceInput_v2,
	PageItemIOMultipleChoiceInput_v3,
	PageItemIODilemmaInput,
	PageItemIODilemmaOutput,
	PageItemIOSprigStory,
	PageItemIOQuizInput,
	PageItemSoundPlayer,
	PageItemIOPostItCategorizeOutput,
	PageItemIOPostItOutput,
	PageItemIOActionOutput,
	PageItemIOListsOutput,
	PageItemIOCommentSelectInput,
	PageItemIOMediaUploadOutput,
	PageItemIOSprigStoryTraitSelection,
	PageItemFactBox,
	PageItemNoteCategorizeOutput,
	PageItemTechstormXYOutput,
	PageItemSVGDynamicText,
	PageItemLanguageSelect,
	PageItemKnowledgeQuiz,
	PageItemInfoBox,
	PageItemMediaUpload,
} from "funkis-template/scroll";
import PageItemActivityLink from "funkis-template/pageitems/scroll/ActivityLink/PageItemActivityLink";
import PageItemDivider from "funkis-template/pageitems/scroll/Divider/PageItemDivider";
import PageItemParticipants from "funkis-template/pageitems/scroll/Participants/PageItemParticipants";

const LazyLoadedPageItemIOMultipleChoiceOutput = React.lazy(() =>
	import(
		"funkis-template/pageitems/scroll/MultipleChoiceOutput/v1/PageItemIOMultipleChoiceOutput"
	)
);

const LazyLoadedPageItemIOMultipleChoiceOutputV3 = React.lazy(() =>
	import(
		"funkis-template/pageitems/scroll/MultipleChoiceOutput/v3/PageItemIOMultipleChoiceOutput"
	)
);

import "../assets/css/themes/version-2/default/scroll-xl.css";

export const registerScrollBlocks = () => {
	PageItems.addPageItem("video-player", PageItemVideoPlayer);
	PageItems.addPageItem("image", PageItemImage);
	PageItems.addPageItem("article", PageItemArticle);
	PageItems.addPageItem("article-compact", PageItemArticleCompact);
	PageItems.addPageItem("conversation", PageItemConversation_v2);
	PageItems.addPageItem("video-conversation", PageItemVideoConversation);
	PageItems.addPageItem("multiple-choice", () => {
		throw new DeprecatedPageItemError(
			"multiple-choice",
			"1.5.0",
			"io-multiple-choice-input-v3"
		);
	});
	PageItems.addPageItem("course-status", PageItemCourseStatus);
	PageItems.addPageItem("links", PageItemLinks);
	PageItems.addPageItem("print-button", () => {
		throw new DeprecatedPageItemError("print-button", "1.5.0");
	});
	PageItems.addPageItem("embedded-video", PageItemEmbeddedVideo);
	PageItems.addPageItem("linked-block", PageItemLinkedBlock);
	PageItems.addPageItem("match-pairs", PageItemMatchPairs);
	PageItems.addPageItem("read-more", PageItemReadMore);
	PageItems.addPageItem("video-sequence", () => {
		throw new DeprecatedPageItemError("video-sequence", "1.5.0");
	});
	PageItems.addPageItem("info-box", PageItemInfoBox);
	PageItems.addPageItem("table", PageItemTable);
	PageItems.addPageItem("section-divider", () => {
		throw new DeprecatedPageItemError("section-divider", "1.5.0");
	});
	PageItems.addPageItem("quote", PageItemQuote_v2);
	PageItems.addPageItem("bullets", PageItemBullets_v2);
	PageItems.addPageItem("hotspots", PageItemHotspots);
	PageItems.addPageItem("navigation-buttons", PageItemNavigationButtons);
	PageItems.addPageItem("io-comment-input", () => {
		throw new DeprecatedPageItemError(
			"io-comment-input",
			"1.5.0",
			"io-comment-input-v2"
		);
	});
	PageItems.addPageItem("io-comment-input-v2", PageItemIOCommentInput);
	PageItems.addPageItem("io-comment-output", PageItemIOCommentOutput);
	PageItems.addPageItem("io-post-it-create", PageItemIOPostItInput); // Renamed
	PageItems.addPageItem("post-it-create", PageItemIOPostItInput); // Renamed
	PageItems.addPageItem("io-post-it-input", PageItemIOPostItInput);
	PageItems.addPageItem("io-text", PageItemIOText);
	PageItems.addPageItem("io-rate-input", PageItemRateInput);
	PageItems.addPageItem("io-rate-output", PageItemRateOutput);
	PageItems.addPageItem(
		"io-action-priority-matrix-input",
		PageItemIOActionPriorityMatrixInput
	);
	PageItems.addPageItem("io-lists-input", PageItemIOListsInput);
	PageItems.addPageItem("io-multiple-choice-input", () => {
		throw new DeprecatedPageItemError(
			"io-multiple-choice-input",
			"1.5.0",
			"io-multiple-choice-input-v3"
		);
	});
	PageItems.addPageItem(
		"io-multiple-choice-input-v2",
		PageItemIOMultipleChoiceInput_v2
	);
	PageItems.addPageItem(
		"io-multiple-choice-input-v3",
		PageItemIOMultipleChoiceInput_v3
	);
	PageItems.addPageItem(
		"io-multiple-choice-output",
		LazyLoadedPageItemIOMultipleChoiceOutput
	);
	PageItems.addPageItem(
		"io-multiple-choice-output-v3",
		LazyLoadedPageItemIOMultipleChoiceOutputV3
	);
	PageItems.addPageItem("io-dilemma-input", () => {
		throw new DeprecatedPageItemError("io-dilemma-input", "1.5.0");
	});
	PageItems.addPageItem("io-dilemma-input-v2", PageItemIODilemmaInput);
	PageItems.addPageItem("io-dilemma-output-v2", PageItemIODilemmaOutput);
	PageItems.addPageItem("io-sprig-story", PageItemIOSprigStory);
	PageItems.addPageItem("quiddityPostIts", PageItemIOPostItInput);
	PageItems.addPageItem("io-quiz-input", PageItemIOQuizInput);
	PageItems.addPageItem("io-value-list-selector", () => {
		throw new DeprecatedPageItemError("io-value-list-selector", "1.5.0");
	});
	PageItems.addPageItem("io-value-list-selector-input", () => {
		throw new DeprecatedPageItemError("io-value-list-selector", "1.5.0");
	});
	PageItems.addPageItem("sound-player", PageItemSoundPlayer);
	PageItems.addPageItem(
		"io-post-it-categorize-output",
		PageItemIOPostItCategorizeOutput
	);
	PageItems.addPageItem("io-post-it-output", PageItemIOPostItOutput);
	PageItems.addPageItem("io-action-output", PageItemIOActionOutput);
	PageItems.addPageItem("io-lists-output", PageItemIOListsOutput);
	PageItems.addPageItem(
		"io-comment-select-input",
		PageItemIOCommentSelectInput
	);
	PageItems.addPageItem("io-media-upload-output", PageItemIOMediaUploadOutput);
	PageItems.addPageItem(
		"io-sprig-story-trait-selection",
		PageItemIOSprigStoryTraitSelection
	);
	PageItems.addPageItem("fact-box", PageItemFactBox);
	PageItems.addPageItem(
		"io-note-categorize-output",
		PageItemNoteCategorizeOutput
	);
	PageItems.addPageItem("techstorm-xy-output", PageItemTechstormXYOutput);
	PageItems.addPageItem("svg-dynamic-text", PageItemSVGDynamicText);
	PageItems.addPageItem("language-select", PageItemLanguageSelect);
	PageItems.addPageItem("knowledge-quiz", PageItemKnowledgeQuiz);
	PageItems.addPageItem("io-media-upload", PageItemMediaUpload);
	PageItems.addPageItem("site-activity-link", PageItemActivityLink);
	PageItems.addPageItem("participants", PageItemParticipants);
	PageItems.addPageItem("divider", PageItemDivider);
};

const ScrollModules = ({ children }) => {
	useEffect(() => {
		//Pages
		Pages.addPage("intro", PageIntro_v3);
		Pages.addPage("menu", PageMenuScroll);
		Pages.addPage("language-menu", PageLanguageMenu);
		Pages.addPage("group-setup", PageSelfStudyGroupSetup);
		// Misc
		PageItems.addPageItem("reveal", PageItemReveal);
		PageItems.addPageItem("page-header", PageItemPageHeader);
		PageItems.addPageItem("chapter-button", PageItemChapterButton);
		PageItems.addPageItem("page-button", PageItemPageButton);
		PageItems.addPageItem("page-footer", PageItemPageFooter);
		PageItems.addPageItem("top-bar", PageItemTopBar);
		PageItems.addPageItem("side-menu", PageItemSideMenu);
		// Blocks
		registerScrollBlocks();
	}, []);

	return children;
};

export default ScrollModules;
