import classnames from "classnames";
import {
	AppActions,
	FFButton,
	ReduxCollectionSelectors,
	ReduxObjectSelectors,
} from "funkis-foundation";
import _ from "lodash";
import React from "react";
import { connect } from "../../utils/reduxUtils";

import IconArrow from "../../assets/svg/IconArrow";
import { getBootstrapColor } from "../../utils/colorUtils";
import MemberListItem from "../scroll/MemberListItem";
import Style from "./GroupMembersPanel.module.css";
import SimpleReveal from "./SimpleReveal";

const { updateApp } = AppActions;
const { makeGetValueSelector } = ReduxObjectSelectors;
const { makeGetItemsSelector } = ReduxCollectionSelectors;

const GroupMembersPanel = ({
	members,
	backgroundColor = "black",
	app,
	quiddity,
}) => {
	const { isGroupMembersPanelOpen = false } = app;
	const groupName = _.get(quiddity, "group.name");

	// Sort members by groupLeader
	const sortedMembers = _.sortBy(
		members,
		(member) => member?.properties?.isGroupLeader === true
	).reverse();
	const memberIds = sortedMembers.map((member) => member.id);

	return (
		<div
			className="group-members-panel w-100 d-flex flex-column p-3"
			style={{ backgroundColor }}
		>
			{/* TOP BAR */}
			<FFButton
				className="d-flex"
				onClick={() => {
					updateApp({ isGroupMembersPanelOpen: !isGroupMembersPanelOpen });
				}}
			>
				{({ hover }) => {
					return (
						<React.Fragment>
							<p className="mr-2  text-white"> {groupName}</p>
							<div
								className={classnames(
									Style.arrow,
									isGroupMembersPanelOpen && Style.arrowOpen
								)}
							>
								<IconArrow
									color={hover ? getBootstrapColor("interaction") : "white"}
									style={{ width: "16px", height: "16px" }}
								/>
							</div>
						</React.Fragment>
					);
				}}
			</FFButton>
			{/* MEMBERS */}
			<SimpleReveal
				reveal={isGroupMembersPanelOpen}
				className="d-flex flex-column pt-3"
			>
				{memberIds.map((id) => (
					<MemberListItem key={id} id={id} />
				))}
			</SimpleReveal>
		</div>
	);
};

const makeMapStateToProps = () => {
	const getValueSelector = makeGetValueSelector();
	const getItemsSelector = makeGetItemsSelector();
	const mapStateToProps = (state, props) => {
		const currentMemberId = getValueSelector(
			state,
			{ path: "member.id" },
			"quiddity"
		);
		const members = getItemsSelector(state, { match: {} }, "members").filter(
			(id) => id !== currentMemberId
		);
		return {
			app: state.app.item,
			quiddity: state.quiddity.item,
			members,
		};
	};
	return mapStateToProps;
};

export default connect(GroupMembersPanel, makeMapStateToProps);
