import React from "react";
import classnames from "classnames";
import Styles from "./DraggableBullet.module.css";

const DraggableBullet = ({
	style,
	className,
	draggable = false,
	number = 0,
	dragData = {},
	onDragStart,
	onDragEnd,
}) => {
	const onDragStartHandler = (e) => {
		e.dataTransfer.effectAllowed = "move";
		e.dataTransfer.setData("Text", JSON.stringify(dragData));
		onDragStart && onDragStart(e);
	};

	const onDragEndHandler = (e) => {
		e.dataTransfer.effectAllowed = "move";
		e.dataTransfer.setData("Text", JSON.stringify(dragData));
		onDragEnd && onDragEnd(e);
	};

	return (
		<div
			draggable={draggable}
			className={classnames(Styles.draggableBullet, className)}
			onDragStart={onDragStartHandler}
			onDragEnd={onDragEndHandler}
		>
			<div
				className={classnames(
					className,
					Styles.draggableBullet,
					draggable ? "bg-primary" : "bg-light-gray"
				)}
				style={style}
			>
				{number}
			</div>
		</div>
	);
};

export default DraggableBullet;
