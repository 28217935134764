export function isEven(n) {
	return n === parseFloat(n) ? !(n % 2) : void 0;
}

type Dimension = {
	width: number;
	height: number;
};

export const calculateScaleToFitDimensions = (
	dimensionsToFit: Dimension,
	dimensionsToScale: Dimension
) => {
	let widthScale = dimensionsToFit.width / dimensionsToScale.width;
	let heightScale = dimensionsToFit.height / dimensionsToScale.height;

	let scale = Math.min(heightScale, widthScale);

	const getTopLeftOffsetsForCentering = () => {
		const availableHeight = dimensionsToFit.height;
		const availableWidth = dimensionsToFit.width;
		const padding = 0;

		const top = Math.round(
			availableHeight / 2 - (dimensionsToScale.height * scale) / 2 + padding
		);
		const left = Math.round(
			availableWidth / 2 - (dimensionsToScale.width * scale) / 2 + padding
		);
		return { top, left };
	};
	const { top: topOffsetForCentering, left: leftOffsetForCentering } =
		getTopLeftOffsetsForCentering();
	return { scale, topOffsetForCentering, leftOffsetForCentering };
};

export const scaleToFit = (
	source: Dimension & { scaleX?: number; scaleY?: number },
	target: Dimension,
	objectFit
) => {
	const result = {
		scaleX: 1,
		scaleY: 1,
		transformScale: "",
		transformTranslate: "",
		translateX: undefined,
		translateY: undefined,
	};
	const sourceRatio = source.height / source.width;
	const targetRatio = target.height / target.width;
	let translateX;
	let translateY;

	switch (objectFit) {
		case "fill":
			result.scaleX = target.width / source.width;
			result.scaleY = target.height / source.height;
			result.transformScale = `scale(${result.scaleX}, ${result.scaleY})`;
			return result;
		case "contain":
			if (targetRatio > sourceRatio) {
				result.scaleX = target.width / source.width;
				result.scaleY = (target.width * sourceRatio) / source.height;
			} else {
				result.scaleX = (target.height * (1 / sourceRatio)) / source.width;
				result.scaleY = target.height / source.height;
			}
			translateX = Math.round(
				(target.width - source.width * result.scaleX) / 2
			);
			translateY = Math.round(
				(target.height - source.height * result.scaleY) / 2
			);
			result.transformScale = `scale(${result.scaleX}, ${result.scaleY})`;
			result.transformTranslate = `translate(${translateX}px, ${translateY}px)`;
			break;
		case "cover":
			if (targetRatio < sourceRatio) {
				result.scaleX = target.width / source.width;
				result.scaleY = (target.width * sourceRatio) / source.height;
			} else {
				result.scaleX = (target.height * (1 / sourceRatio)) / source.width;
				result.scaleY = target.height / source.height;
			}
			translateX = Math.round(
				(target.width - source.width * result.scaleX) / 2
			);
			translateY = Math.round(
				(target.height - source.height * result.scaleY) / 2
			);
			result.transformTranslate = `translate(${translateX}px, ${translateY}px)`;
			result.transformScale = `scale(${result.scaleX}, ${result.scaleY})`;
			break;
		default:
			if (source.scaleX) {
				result.scaleX = (target.width * source.scaleX) / source.width;
			}

			if (source.scaleY) {
				result.scaleY = (target.height * source.scaleY) / source.height;
			}

			if (!source.scaleX) {
				result.scaleX = result.scaleY;
			}

			if (!source.scaleY) {
				result.scaleY = result.scaleX;
			}

			result.transformScale = `scale(${result.scaleX}, ${result.scaleY})`;
	}

	result.translateX = translateX;
	result.translateY = translateY;

	return result;
};
