import * as API from "../api";
import { getCode } from "../utils/QuiddityUtils";
import * as UnitActions from "../redux/actions/UnitActions";

export const getUnits = async (url, query, addToStore = true) => {
	try {
		const units = await API.getUnits(url, query, getCode());
		// Filter base nodes...
		const bareUnits = units.filter((unit) => unit.nodeId !== "units");
		addToStore && UnitActions.updateUnits(bareUnits);
		return bareUnits;
	} catch (error) {
		return [];
	}
};

export const getUnit = async (url, query, addToStore = true) => {
	const units = await getUnits(url, query, addToStore);
	return units[0];
};
