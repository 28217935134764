import * as React from "react";

function Participant({ className, color = "#616164" }) {
	return (
		<svg
			className={className}
			width="31px"
			height="40px"
			viewBox="0 0 31 40"
			version="1.1"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="Group-3">
					<g id="Clip-2"></g>
					<path
						d="M16.5449,26.0001 L16.5449,40.0001 L23.2819,40.0001 L23.2819,24.0851 L21.2819,22.1271 L21.2819,14.8701 L28.8899,19.3571 L30.9829,15.9421 L21.5699,10.0001 L9.4129,10.0001 L-0.0001,15.9421 L2.0939,19.3571 L9.7009,14.8701 L9.7009,22.1271 L7.7009,24.0851 L7.7009,40.0001 L14.4389,40.0001 L14.4389,26.0001 L16.5449,26.0001 Z M19.9919,4.5001 C19.9919,6.9851 17.9769,9.0001 15.4919,9.0001 C13.0069,9.0001 10.9919,6.9851 10.9919,4.5001 C10.9919,2.0151 13.0069,0.0001 15.4919,0.0001 C17.9769,0.0001 19.9919,2.0151 19.9919,4.5001 L19.9919,4.5001 Z"
						id="Fill-1"
						fill={color}
					></path>
				</g>
			</g>
		</svg>
	);
}

export default Participant;
