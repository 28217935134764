import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

// if trying to rename to .ts, you may have luck with this
// type GetStateFromSelector<S> = S extends Selector<infer State> ? State : never;
// https://github.com/reduxjs/reselect/issues/534#issuecomment-956708953

export const useValueListOptionsOrContentArray = (
	cmtPageItem,
	templateName
) => {
	const { valuelist_id, content_array } = cmtPageItem;

	const valueLists = useSelector((state) =>
		_.get(state, "app.item.value_lists", [])
	);
	const valueList = valueLists.find((list) => list.id === valuelist_id);

	const valueListIsValid = valueList && valueList.template === templateName;

	const options = valueListIsValid ? valueList.values : content_array;
	return options;
};

const selectValueLists = (state) => _.get(state, "app.item.value_lists", []);

const selectValueListsById = createSelector(
	[selectValueLists],
	(valueLists) => {
		return valueLists.reduce((acc, next) => {
			acc[next.id] = next;
			return acc;
		}, {});
	}
);

const selectValueListItemId = (state, props) => _.get(props, "valueListItemId");

const selectValueListId = (state, props) => _.get(props, "valueListId");

const selectValueListItemIds = (state, props) =>
	_.get(props, "valueListItemIds", []);

const selectAllValueListItems = createSelector(
	[selectValueLists],
	(valueLists) => {
		return valueLists.flatMap((valueList) => valueList.values);
	}
);

const selectAllValueListItemsById = createSelector(
	[selectAllValueListItems],
	(allValueListItems) => {
		return allValueListItems.reduce((acc, next) => {
			acc[next.id] = next;
			return acc;
		}, {});
	}
);

// parent id by child ids, ie  { "child-1-id" : "parent-1-id", "child-2-id": "parent-1-id" }
const selectValueListIdsByItemIds = createSelector(
	[selectValueLists],
	(valueLists) => {
		return valueLists.reduce((acc, next) => {
			for (const item of next.values) {
				acc[item.id] = next.id;
			}
			return acc;
		}, {});
	}
);

const makeGetValueListByItemId = () =>
	createSelector(
		[selectValueListsById, selectValueListIdsByItemIds, selectValueListItemId],
		(valueListsById, valueListIdsByItemIds, valueListItemId) => {
			const valueListId = valueListIdsByItemIds[valueListItemId];
			return valueListsById[valueListId];
		}
	);

const makeGetValueListItemById = () =>
	createSelector(
		[selectAllValueListItemsById, selectValueListItemId],
		(valueListItemsById, valueListItemId) => {
			return valueListItemsById[valueListItemId];
		}
	);

export const makeGetValueListById = () =>
	createSelector(
		[selectValueListsById, selectValueListId],
		(valueListsById, valueListId) => {
			return valueListsById[valueListId];
		}
	);

const makeGetValueListItemsByIds = () =>
	createSelector(
		[selectAllValueListItemsById, selectValueListItemIds],
		(valueListItemsById, valueListItemIds) => {
			return valueListItemIds.map((itemId) => valueListItemsById[itemId]);
		}
	);

export const useValueListItem = (valueListItemId) => {
	const getValueListItemById = useMemo(makeGetValueListItemById, []);
	const item = useSelector((state) =>
		getValueListItemById(state, { valueListItemId })
	);
	return item;
};

export const useValueList = (valueListId) => {
	const selector = useMemo(makeGetValueListById, []);
	const valueList = useSelector((state) => selector(state, { valueListId }));
	return valueList;
};

export const useParentValueList = (valueListItemId) => {
	const selector = useMemo(makeGetValueListByItemId, []);
	const valueList = useSelector((state) =>
		selector(state, { valueListItemId })
	);
	return valueList;
};

export const useValueListItems = (valueListItemIds) => {
	const selector = useMemo(makeGetValueListItemsByIds, []);
	const valueListItems = useSelector((state) =>
		selector(state, { valueListItemIds })
	);
	return valueListItems;
};

// From a selection of valueListItemIds, this can give you all items in their parent title content array (one or multiple)
export const useTotalItemsFromMixedItems = (valueListItemIds) => {
	// no useMemo, it won't be memoized when given different values anyway
	const selectValueListByItemId = makeGetValueListByItemId();
	const parentArraysById = useSelector((state) => {
		return valueListItemIds.reduce((acc, next) => {
			const parentArray = selectValueListByItemId(state, {
				valueListItemId: next,
			});
			if (parentArray) {
				acc[parentArray.id] = parentArray;
			}
			return acc;
		}, {});
	});
	const items = Object.values(parentArraysById).flatMap((tca) => tca.values);
	return items;
};
