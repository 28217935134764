import classnames from "classnames";
import Button from "components/Button/Button";
import Stack from "components/Stack/Stack";
import { useProgramType } from "funkis-template/hooks/player";
import React, { useEffect, useState } from "react";
import { makeGetValueForCurrentPage } from "../../../../selectors/playerSelectors";
import { updateLocation } from "../../../actions/Actions";
import Text from "../../../components/core/Text";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";
import { handleStatusAndUnlockingNextPage } from "../../../utils/pageUtils";
import { connect } from "../../../utils/reduxUtils";

const PageItemPageFooter = (props) => {
	const { pageItem, cmtPageType } = props;
	const { cmtPage } = pageItem;
	const [hasNextPage, setHasNextPage] = useState();
	const [hasNextChapter, setHasNextChapter] = useState();

	const programType = useProgramType();

	const [hasBeenShown, setHasBeenShown] = useState(false);

	useEffect(() => {
		if (programType === "slide") {
			setHasBeenShown(true);
		}
	}, [programType]);

	useEffect(() => {
		if (!hasBeenShown) return;
		const { nextPageInChapter, nextChapter } = handleStatusAndUnlockingNextPage(
			{ cmtPage, programType }
		);
		nextPageInChapter && setHasNextPage(nextPageInChapter);
		nextChapter && setHasNextChapter(nextChapter);
	}, [hasBeenShown]);

	const onNextPageClick = () => {
		updateLocation({ pageId: cmtPage.id, path: [{ sibling: 1 }] });
	};

	const onNextChapterClick = () => {
		updateLocation({
			pageId: cmtPage.id,
			path: [{ parent: 1 }, { sibling: 1 }, { child: 0 }],
		});
	};

	const onMainMenuClick = () => {
		updateLocation({ friendlyId: "menu" });
	};

	if (cmtPageType === "print") {
		return <div />;
	}

	const shouldShowNextChapterButton =
		cmtPage.show_next_chapter_button === "yes" && hasNextChapter;

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={false}
			onScrollEnterStart={() => setHasBeenShown(true)}
			renderFunction={({ viewVisible }) => {
				return (
					<React.Fragment>
						<div className="row m-0">
							<Text
								className={classnames(
									"col-md-12 mb-4 font-weight-bold animated",
									viewVisible ? "fadeIn" : "fadeOut"
								)}
								tagName="h2"
								textId="65E56043-876A-4173-9E07-8AA6E27649D6"
								balance={true}
							/>
							<Text
								className={classnames(
									"col-md-8 mb-6 font-weight-bold animated",
									viewVisible ? "fadeIn delay-250ms" : "fadeOut"
								)}
								tagName="h4"
								textId="24071938-1D6B-4C20-8B4D-A7D6974F33B5"
							/>
							<div className="row m-0 justify-content-center">
								<div className="col-sm-7 d-flex flex-column align-items-center">
									<Stack width="fit-content-width">
										{/* NEXT PAGE */}
										{hasNextPage && (
											<Button
												className={classnames(
													"animated",
													viewVisible ? "fadeIn delay-500ms" : "fadeOut"
												)}
												onClick={onNextPageClick}
												fluid={true}
											>
												<Text textId={"A9A8074E-9A76-4170-AB6D-9DD5C567ABA5"} />
											</Button>
										)}

										{/* NEXT CHAPTER */}
										{!hasNextPage && shouldShowNextChapterButton && (
											<Button
												className={classnames(
													"animated",
													viewVisible ? "fadeIn delay-500ms" : "fadeOut"
												)}
												onClick={onNextChapterClick}
												fluid={true}
											>
												<Text textId={"A9A8074E-9A76-4170-AB6D-9DD5C567ABA5"} />
											</Button>
										)}

										{/* MAIN MENU */}

										<Button
											className={classnames(
												"animated",
												viewVisible ? "fadeIn delay-750ms" : "fadeOut"
											)}
											variant={
												hasNextPage || shouldShowNextChapterButton
													? "outline"
													: "solid"
											}
											onClick={onMainMenuClick}
											fluid={true}
										>
											<Text textId={"B6649ED3-CB74-4F39-B8B6-E18308F5EA05"} />
										</Button>
									</Stack>
								</div>
							</div>
						</div>
					</React.Fragment>
				);
			}}
		></PageItemScrollBase>
	);
};

const makeMapStateToProps = () => {
	const getValueForCurrentPage = makeGetValueForCurrentPage();
	const mapStateToProps = (state, props) => {
		return {
			cmtPageType: getValueForCurrentPage(
				state,
				{ ...props, path: "data.cmtPage.page_type" },
				"app"
			),
		};
	};
	return mapStateToProps;
};

export default connect(PageItemPageFooter, makeMapStateToProps);
