import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import numberFormatter from "number-formatter";
import { TweenMax, TimelineMax, Power4 } from "gsap";
import { getBootstrapColor } from "../../../../utils/colorUtils";

const QuizQuestionScoreBoard = ({ points = 0, show = true, onComplete }) => {
	const ref = useRef(null);
	const pointsStr = points ? numberFormatter("#,###.", points) : "0";

	const reset = () => {
		TweenMax.set(ref.current, {
			transformStyle: "preserve-3d",
			opacity: 0,
			z: 2000,
		});
	};

	useEffect(() => {
		reset();
	}, []);

	useEffect(() => {
		if (show) {
			const tweenTimeline = new TimelineMax();
			tweenTimeline.to(ref.current, {
				duration: 3,
				opacity: 1,
				z: 500,
				ease: Power4.easeOut,
			});

			tweenTimeline.to(ref.current, {
				duration: 1,
				opacity: 0,
				z: -10000,
				ease: Power4.easeIn,
				onComplete,
			});
		} else {
			reset();
		}
	}, [show, onComplete]);

	return (
		<div
			className={classnames(
				"absolute-top w-100 h-100 d-flex justify-content-center align-items-center text-white font-weight-bold"
			)}
			style={{ perspective: 800 }}
		>
			<div ref={ref}>
				<h1
					className={classnames(!show && "display-none")}
					style={{ color: getBootstrapColor(points > 0 ? "success" : "fail") }}
				>
					{pointsStr}
				</h1>
			</div>
		</div>
	);
};

export default QuizQuestionScoreBoard;
