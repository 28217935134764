import * as StoreUtils from "../../utils/StoreUtils";

// Action constants
import {
	UPDATE_MEMBERS,
	UPDATE_MEMBER,
	REMOVE_MEMBER,
} from "../constants/ActionConstants";

export function updateMembers(items) {
	StoreUtils.getStore().dispatch(_updateMembers(items));
}

function _updateMembers(items) {
	return (dispatch: (action) => void) => {
		dispatch({ items, type: UPDATE_MEMBERS });
	};
}

export function updateMember(id, updates) {
	StoreUtils.getStore().dispatch(_updateMember(Object.assign(updates, { id })));
}

function _updateMember(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_MEMBER });
	};
}

export function removeMember(id) {
	StoreUtils.getStore().dispatch(_updatePage({ id }));
}

function _removeMember(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_MEMBER });
	};
}
