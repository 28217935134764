import React from "react";
import { connect } from "../../utils/reduxUtils";
import SlideLightBox from "../slide/LightBox";
import ScrollLightBox from "../scroll/LightBox";
import { ReduxObjectSelectors } from "funkis-foundation";

const { makeGetValueSelector } = ReduxObjectSelectors;

const LightBox = (props) => {
	const { programType } = props;

	switch (programType) {
		case "scroll":
			return <ScrollLightBox {...props} />;
		case "slide":
			return <SlideLightBox {...props} />;
	}
};

const makeMapStateToProps = () => {
	const getValueSelector = makeGetValueSelector();
	const mapStateToProps = (state, props) => {
		return {
			programType: getValueSelector(
				state,
				{ ...props, path: "program_type" },
				"app"
			),
		};
	};
	return mapStateToProps;
};

export default connect(LightBox, makeMapStateToProps);
