import * as API from "../api";
import { getCode } from "../utils/QuiddityUtils";

const getQuiddityTitles = async (url, query) => {
	try {
		const quiddityTitles = await API.getQuiddityTitles(url, query, getCode());
		return quiddityTitles;
	} catch (error) {
		return [];
	}
};

export const getQuiddityTitle = async (url, query, addToStore = true) => {
	const titles = await getQuiddityTitles(url, query, addToStore);
	return titles[0];
};
