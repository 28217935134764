import _ from "lodash";
import { TRAIT_MODE } from "../constants/traitConstants";

let traitsData = {};

export const calculateTraits = ({ sprigStory, sprigItem, traits, index }) => {
	// reset when index 0
	index === 0 && (traitsData = {});

	return traits.map((traitItem) => {
		const trait = { ...traitItem };

		const sprig = sprigStory.find((item) => item.id === sprigItem.sprigId);
		const selectedExit = sprig.exits.find(
			(exit) => exit.id === sprigItem.exitId
		);

		// Set value to previous value if no actions
		trait.value = traitsData[traitItem.id] || 0;

		// Actions "arrival"
		const actionsOnArrival = sprig.actionsOnArrival || [];
		actionsOnArrival.forEach((action) => {
			trait.value = calculateTraitValue({
				action,
				trait,
				prevValue: traitsData[traitItem.id],
				value: trait.value,
				min: trait.lowest_value,
				max: trait.highest_value,
			});
			traitsData[traitItem.id] = trait.value;
		});
		// Actions "on select"
		const actionsOnSelect = _.get(selectedExit, "actionsOnSelect", []);
		actionsOnSelect.forEach((action) => {
			trait.value = calculateTraitValue({
				action,
				trait,
				prevValue: traitsData[traitItem.id],
				value: trait.value,
				min: trait.lowest_value,
				max: trait.highest_value,
			});
			traitsData[traitItem.id] = trait.value;
		});

		// Actions "departure"
		const actionsOnDeparture =
			(sprigItem.exitId && sprig.actionsOnDeparture) || [];
		actionsOnDeparture.forEach((action) => {
			trait.value = calculateTraitValue({
				action,
				trait,
				prevValue: traitsData[traitItem.id],
				value: trait.value,
				min: trait.lowest_value,
				max: trait.highest_value,
			});
			traitsData[traitItem.id] = trait.value;
		});
		return trait;
	});
};

const calculateTraitValue = ({
	action,
	trait,
	prevValue = 0,
	value,
	min = Number.MIN_SAFE_INTEGER,
	max = Number.MAX_SAFE_INTEGER,
}) => {
	let newValue = value;

	if (action.trait_id === trait.id) {
		newValue = prevValue + action.value;
	} else {
		return prevValue;
	}

	return Math.min(Math.max(newValue, min), max);
};

export const getTraitResult = ({ sprigStory, traits, sprigs }) => {
	// Zeroing the traits before calculation...
	traits.forEach((trait) => {
		trait.value = 0;
	});
	// Calculate result for everey selected sprig...
	const results = sprigs.map((sprigItem, index) =>
		calculateTraits({ sprigStory, sprigItem, traits, index })
	);
	// Return only the result for the final sprig...
	return results[results.length - 1];
};

export const getTraitsFromResults = ({ data, traitMode, cmtPageItem }) => {
	const { result = [], selectedTraits = [] } = data;
	return (
		result
			//   Filter by category...
			.filter(
				(trait) =>
					trait.category === _.get(cmtPageItem, "filter_by_category", "")
			)
			//   Filter by value...
			.filter((trait) => trait.value >= cmtPageItem.value_treshold)
			//   Filter by traitMode...
			.filter((trait) =>
				traitMode === TRAIT_MODE.SUMMARY
					? _.find(
							selectedTraits,
							(selectedTrait) => selectedTrait.id === trait.id
					  )
					: true
			)
	);
};
