import _ from "lodash";
import React, { useState, useCallback, useMemo, useEffect } from "react";

type HoverProps = {
	children: (props: {
		hover: boolean;
		mouseDown: boolean;
		mouseMove: boolean;
	}) => React.ReactElement;
	className?: string;
	style?: React.CSSProperties;
	enabled?: boolean;
	mouseMoveTimeout?: number;
	role?: React.AriaRole;
};

const Hover: React.FC<HoverProps> = ({
	children,
	className = "",
	style = {},
	enabled = true,
	mouseMoveTimeout = 1000,
	role = "div",
}) => {
	const [hover, setHover] = useState(false);
	const [mouseDown, setMouseDown] = useState(false);
	const [mouseMove, setMouseMove] = useState(false);

	const autoDeactivateMouseMoveAfterDelay = useCallback(
		_.debounce(() => {
			setMouseDown(false);
		}, mouseMoveTimeout),
		[mouseMoveTimeout]
	);

	useEffect(() => {
		return () => {
			autoDeactivateMouseMoveAfterDelay.cancel();
		};
	}, [autoDeactivateMouseMoveAfterDelay]);

	const handleMouseMove = useCallback(() => {
		setMouseMove(true);
		autoDeactivateMouseMoveAfterDelay();
	}, [autoDeactivateMouseMoveAfterDelay]);

	const handleMouseOver = useCallback(() => {
		enabled && setHover(true);
	}, [enabled]);

	const handleMouseLeave = useCallback(() => {
		enabled && setHover(false);
	}, [enabled]);

	const handleMouseDown = useCallback(() => {
		enabled && setHover(true);
		enabled && setMouseDown(true);
	}, [enabled]);

	const handleMouseUp = useCallback(() => {
		enabled && setMouseDown(false);
	}, [enabled]);

	const styleObj = useMemo(
		() => ({ cursor: enabled ? "pointer" : "auto", ...style }),
		[style, enabled]
	);
	return (
		<div
			role={role}
			className={className}
			style={styleObj}
			onFocus={handleMouseOver}
			onMouseOver={handleMouseOver}
			onMouseLeave={handleMouseLeave}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
		>
			{children({ hover, mouseDown, mouseMove })}
		</div>
	);
};

export default Hover;
