import { createSelector } from "reselect";

const getConfig = (state) => state.config.item;

export const getConfigSelector = createSelector(getConfig, (config) => {
	return config;
});

export function makeGetConfig() {
	console.warn(
		`"makeGetConfig" is depricated. Please use "makeGetConfigSelector" `
	);
	return getConfigSelector;
}

export function makeGetConfigSelector() {
	return getConfigSelector;
}
