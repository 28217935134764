import React from "react";
import classnames from "classnames";
import { getText } from "../../utils/textUtils";
import ImageWithCaption from "../../components/core/ImageWithCaption";
import Text from "../../components/core/Text";
import { ArticleScrollBlock } from "../../models/pageitem";
import { PageItem } from "../../models/player";
import Stack from "components/Stack/Stack";

const Article: React.FC<{
	pageItem: PageItem<ArticleScrollBlock>;
	viewVisible: boolean;
	bodyColumn1?: string;
	bodyColumn2?: string;
	animate?: boolean;
}> = (props) => {
	const { pageItem, viewVisible } = props;
	const { cmtPageItem } = pageItem;

	const shouldAnimate = props.animate ?? true;
	const header = cmtPageItem.header;
	const imageSrc = cmtPageItem.image;
	const imageColumn2Src = cmtPageItem.image_column_2;
	const imageSize = cmtPageItem.image_size;
	const imageAlign = cmtPageItem.image_align;
	const imageMaxHeight: number = cmtPageItem.image_max_height;
	const imageMarginsMobile = cmtPageItem.image_margins_mobile !== "no";
	const imageSpan =
		cmtPageItem.image_span === undefined ? "2-column" : cmtPageItem.image_span;
	const imageCaption = cmtPageItem.image_caption;
	const imageCaptionTwo = cmtPageItem.image_caption_column_2;
	const bodyColumn1 = cmtPageItem.body_column_1;
	const bodyColumn2 = cmtPageItem.body_column_2;

	const showBodyColumn1 = getText(bodyColumn1) || props.bodyColumn1;
	const showBodyColumn2 = getText(bodyColumn2) || props.bodyColumn2;

	const isZoomable = cmtPageItem.zoomable === "yes";

	/* 
  if 2 pictures and 2 texts
    use 2 columns with one of each

  if 2 pictures and 1 text
    use 2 columns with 
  */
	return (
		<Stack>
			{/* IMAGE WITH CAPTION - if using only the first one */}
			{cmtPageItem.image_layout_variant === "image above heading" &&
				!imageColumn2Src &&
				imageSrc && (
					<div
						className={classnames(imageMarginsMobile ? "container-fluid" : "")}
					>
						<ImageWithCaption
							src={imageSrc}
							maxHeight={imageMaxHeight}
							className="row m-0"
							classNameImage={classnames(
								imageSpan === "1-column" && "col-sm-6",
								imageSpan === "2-column" &&
									!imageMarginsMobile &&
									"w-100 px-sm-0 px-md-5",
								imageSpan === "2-column" && imageMarginsMobile && "col-sm-12"
							)}
							classNameCaption={classnames(
								imageSpan === "1-column" && "col-sm-6",
								imageSpan === "2-column" &&
									!imageMarginsMobile &&
									"col-md-8 px-5",
								imageSpan === "2-column" && imageMarginsMobile && "col-md-8"
							)}
							caption={imageCaption}
							show={viewVisible}
							size={imageSize}
							align={imageAlign}
							isZoomable={isZoomable}
							videoAutoPlay={cmtPageItem.video_autoplay}
						/>
					</div>
				)}

			{/* HEADER */}
			<div className="container-fluid">
				<div className="row m-0">
					<Text
						className={classnames(
							"col-sm-12 font-weight-bold mb-0",
							{ animated: shouldAnimate },
							viewVisible ? "fadeInUp" : "fadeOut"
						)}
						tagName="h2"
						textId={header}
					/>
				</div>
			</div>

			{/* IMAGE WITH CAPTION - if using only the first one */}
			{cmtPageItem.image_layout_variant === "image below heading" &&
				!imageColumn2Src &&
				imageSrc && (
					<div
						className={classnames(imageMarginsMobile ? "container-fluid" : "")}
					>
						<ImageWithCaption
							src={imageSrc}
							maxHeight={imageMaxHeight}
							className="row m-0"
							classNameImage={classnames(
								imageSpan === "1-column" && "col-sm-6",
								imageSpan === "2-column" &&
									!imageMarginsMobile &&
									"w-100 px-sm-0 px-md-5",
								imageSpan === "2-column" && imageMarginsMobile && "col-sm-12"
							)}
							classNameCaption={classnames(
								imageSpan === "1-column" && "col-sm-6",
								imageSpan === "2-column" &&
									!imageMarginsMobile &&
									"col-md-8 px-5",
								imageSpan === "2-column" && imageMarginsMobile && "col-md-8"
							)}
							caption={imageCaption}
							show={viewVisible}
							size={imageSize}
							align={imageAlign}
							isZoomable={isZoomable}
							videoAutoPlay={cmtPageItem.video_autoplay}
						/>
					</div>
				)}

			{/* BODY */}

			{!imageColumn2Src ? (
				// Using only the first picture (or no picture)
				<div className="container-fluid">
					<div
						className={classnames(
							"row m-0 ",
							{ animated: shouldAnimate },
							viewVisible ? "fadeIn delay-1000ms" : "fadeOut"
						)}
					>
						{showBodyColumn1 && (
							<Text
								className={classnames(
									"p-medium",
									showBodyColumn2 ? "col-sm-6" : "col-sm-9"
								)}
								tagName="p"
								textId={bodyColumn1}
								placeholder={props.bodyColumn1}
								hideIfMissing={props.bodyColumn1 === undefined}
							/>
						)}
						{showBodyColumn2 && (
							<Text
								className="p-medium col-sm-6"
								tagName="p"
								textId={bodyColumn2}
								placeholder={props.bodyColumn2}
								hideIfMissing={props.bodyColumn2 === undefined}
							/>
						)}
					</div>
				</div>
			) : (
				// If two images, force 2 column system. Re-use all image settings from image 1
				<div className="container-fluid">
					<div
						className={classnames(
							"row m-0",
							{ animated: shouldAnimate },
							viewVisible ? "fadeIn delay-1000ms" : "fadeOut"
						)}
					>
						<div className="col-sm-6">
							<ImageWithCaption
								src={imageSrc}
								maxHeight={imageMaxHeight}
								className="mb-3 row m-0"
								classNameImage={classnames("w-100")}
								classNameCaption={classnames("w-100")}
								caption={imageCaption}
								show={viewVisible}
								size={imageSize}
								align={imageAlign}
								isZoomable={isZoomable}
								videoAutoPlay={cmtPageItem.video_autoplay}
							/>
							<Text
								className={classnames("p-medium")}
								tagName="p"
								textId={bodyColumn1}
								placeholder={props.bodyColumn1}
								hideIfMissing={props.bodyColumn1 === undefined}
							/>
						</div>

						<div className="col-sm-6">
							<ImageWithCaption
								src={imageColumn2Src}
								maxHeight={imageMaxHeight}
								className="mb-3 row m-0"
								classNameImage={classnames("w-100")}
								classNameCaption={classnames("w-100")}
								caption={imageCaptionTwo}
								show={viewVisible}
								size={imageSize}
								align={imageAlign}
								isZoomable={isZoomable}
								videoAutoPlay={cmtPageItem.video_autoplay}
							/>
							<Text
								className="p-medium"
								tagName="p"
								textId={bodyColumn2}
								placeholder={props.bodyColumn2}
								hideIfMissing={props.bodyColumn2 === undefined}
							/>
						</div>
					</div>
				</div>
			)}
		</Stack>
	);
};

export default Article;
