import { AppActions } from "funkis-foundation";
import { IO } from "funkis-template/models/pageitem";
import { PageItemMedia } from "funkis-template/models/player";
import _ from "lodash";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { IOContext } from "./backend";

const selectDevSettings = (state: any) => {
	return state.config.item.devSettings;
};

const selectDevMode = (state: any) => {
	return _.get(state, "config.item.devMode", false);
};

export const useDevSettings = (): { showMissinTextWarnings: boolean } => {
	return useSelector(selectDevSettings);
};

export const useDevMode = (): boolean => {
	return useSelector(selectDevMode);
};

const selectIOContext = (state: any): IOContext => {
	const { program_type } = state.app.item;
	switch (program_type) {
		case "slide":
			return IOContext.Group;

		case "scroll":
			return IOContext.Member;

		default:
			return IOContext.Session;
	}
};

export const usePackageIOContext = (): IOContext => {
	return useSelector(selectIOContext);
};

const selectApp = (state: any) => state.app.item;

const selectShouldShowTopBar = createSelector(
	[selectApp, (_state: any, pageType: string) => pageType],
	(app: any, pageType: string) => {
		const isMenu = pageType === "menu";

		return (
			(isMenu && app.top_bar_hide_in_main_menu === "no") ||
			(!isMenu && app.top_bar_hide_in_page === "no")
		);
	}
);

export const useIsShowingTopBar = (pageType: string) =>
	useSelector((state) => selectShouldShowTopBar(state, pageType));

export enum ViewportSize {
	MobileSmall = 0,
	Mobile,
	Tablet,
	Desktop,
	DesktopLarge,
}

const selectViewportSize = (state: any): ViewportSize => {
	const viewPortSizeString: string = state.app.item.size.name;

	switch (viewPortSizeString) {
		case "mobile small":
			return ViewportSize.MobileSmall;

		case "small":
			return ViewportSize.Mobile;

		case "tablet":
			return ViewportSize.Tablet;

		case "desktop":
			return ViewportSize.Desktop;

		case "desktop large":
			return ViewportSize.DesktopLarge;

		default:
			return ViewportSize.Mobile;
	}
};

export const useViewportSize = () => useSelector(selectViewportSize);

const selectCurrentlyPlayingVideo: (state: any) => string | undefined =
	createSelector([selectApp], (app: any) => {
		return app.currently_paying_video;
	});

const toSrcString = (src: PageItemMedia): string => {
	if (_.isString(src)) {
		return src;
	} else if (_.isPlainObject(src)) {
		return src.src;
	} else {
		return "";
	}
};

const setCurrentlyPlayingVideo = (src: PageItemMedia) => {
	AppActions.updateAppValue({ currently_paying_video: toSrcString(src) });
};

export const useIsCurrentlyActiveVideo = (
	src: PageItemMedia
): [boolean, (src: PageItemMedia) => void] => {
	const currentlyPlayingVideo = useSelector(selectCurrentlyPlayingVideo);

	const isCurrentlyPlayingVideo =
		currentlyPlayingVideo === undefined
			? true
			: toSrcString(src) === toSrcString(currentlyPlayingVideo);

	// console.log("useIsCurrentlyPlayingVideo", isCurrentlyPlayingVideo, src, currentlyPlayingVideo);

	return [isCurrentlyPlayingVideo, setCurrentlyPlayingVideo];
};

const selectShowNavigationMenu: (state: any) => boolean = createSelector(
	[selectApp],
	(app: any) => {
		return app.showNavigationMenu;
	}
);

export const useIsShowingNavigationMenu = () =>
	useSelector(selectShowNavigationMenu);

const selectAllLanguages = (state: any): string[] =>
	_.get(state, "config.item.installedLanguages");
export const useAllLanguages = () => useSelector(selectAllLanguages);

const selectSelectedLanguage = (state: any): string =>
	_.get(state, "config.item.language");
export const useSelectedLanguage = () => useSelector(selectSelectedLanguage);

const selectOriginalLocalizationCode = (state: any): string =>
	_.get(state, "app.item.original_localisation_code");
export const useOriginalLocalizationCode = () =>
	useSelector(selectOriginalLocalizationCode);

const selectLanguageMenuSort = (state: any): "native" | "english" | "none" =>
	_.get(state, "app.item.language_menu_sort");
export const useLanguageMenuSort = () => useSelector(selectLanguageMenuSort);

const selectIOsByIds = (state: any, ids: string[]): IO[] => {
	const ios = _.get(state, "app.item.ios", []);
	const iosById = _.keyBy(ios, "id");
	return ids.map((id) => iosById[id]);
};
export const useIOs = (ioIDs: string[]) =>
	useSelector((state) => selectIOsByIds(state, ioIDs), _.isEqual);

const selectProgramType = (state: any): "slide" | "scroll" => {
	const { program_type } = state.app.item;
	return program_type;
};

export const useProgramType = (): "slide" | "scroll" => {
	return useSelector(selectProgramType);
};
