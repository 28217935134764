import React from "react";

const IconCrossBig = ({ color = "#000000", style, className, scale = 1 }) => {
	return (
		<svg
			className={className}
			style={{ transform: `scale(${scale})`, ...style }}
			width="45px"
			height="52px"
			viewBox="0 0 45 52"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xlink="http://www.w3.org/1999/xlink"
		>
			<title>Combined Shape</title>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="symbols-/-btn-/-btn_close_black"
					transform="translate(-62.000000, 0.000000)"
					fill={color}
				>
					<path
						d="M100.532259,-9.83391146e-12 L107,5.49598605 L90.01,25.999 L107,46.504014 L100.532259,52 L84.499,32.65 L68.4677406,52 L62,46.504014 L78.989,26 L62,5.49598605 L68.4677406,1.36708422e-11 L84.5,19.349 L100.532259,-9.83391146e-12 Z"
						id="Combined-Shape"
					></path>
				</g>
			</g>
		</svg>
	);
};

export default IconCrossBig;
