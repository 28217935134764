import classnames from "classnames";
import Slider from "rc-slider";
import React from "react";
import _ from "lodash";

type VideoPlayerProgressBarProps = {
	currentTime: number;
	duration: number;
	onSeek: (newTime: number) => void;
	className?: string;
	style?: React.CSSProperties;
	trackStyle: React.CSSProperties;
	railStyle: React.CSSProperties;
	handleStyle: React.CSSProperties;
	sliderClassName?: string;
};

const VideoPlayerProgressBar: React.FC<VideoPlayerProgressBarProps> = (
	props
) => {
	const {
		currentTime,
		duration,
		sliderClassName,
		className,
		style,
		trackStyle,
		railStyle,
		handleStyle,
		onSeek,
	} = props;

	const [value, setValue] = React.useState<number>();

	React.useEffect(() => {
		if (value) {
			const nextTime = duration * (value / 100);
			onSeek(nextTime);
		}
	}, [value, duration, onSeek]);

	const handleChange = React.useCallback((newValue) => {
		setValue(newValue);
	}, []);

	const progress = (currentTime / duration) * 100;

	React.useEffect(() => {
		/* Clear the internal value 
		when we receive new progress from props */
		setValue(undefined);
	}, [progress]);

	return (
		<div style={style} className={classnames(className)}>
			<Slider
				className={sliderClassName}
				value={value ?? progress}
				trackStyle={trackStyle}
				railStyle={railStyle}
				onChange={handleChange}
				handleStyle={handleStyle}
			/>
		</div>
	);
};

export default VideoPlayerProgressBar;
