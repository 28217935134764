import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";

import { camelToDash } from "../../utils/StringUtils";
import { makeGetTextByIdSelector } from "../../selectors/TextSelectors";
import { getToggleClassNames } from "../../utils/PageItemsUtils";

class PageItemComponent extends React.Component {
	render() {
		const { PageItemElement, pageItem, children, className } = this.props;
		const { ariaLabel, ariaHidden, stateToggle } = pageItem;
		const rest = {
			"aria-label": ariaLabel,
			"aria-hidden": ariaHidden && "true",
		};

		if (!PageItemElement) {
			throw new Error(
				`The block "${pageItem.cmtPageItem.symbol_name}" is not available in this version of Funkis Player.`
			);
		}

		return (
			<PageItemElement
				pageItem={pageItem}
				className={classnames(
					`page-item-id-${pageItem.id}`,
					pageItem.enabled ? "enabled" : "disabled",
					pageItem.highlight && "highlight",
					pageItem.status && camelToDash(pageItem.status),
					pageItem.className,
					stateToggle && getToggleClassNames(stateToggle),
					className
				)}
				rest={rest}
			>
				{children}
			</PageItemElement>
		);
	}
}

const makeMapStateToProps = () => {
	const getTextByIdSelector = makeGetTextByIdSelector();
	const mapStateToProps = (state, props) => {
		const { pageItem } = props;
		return {
			ariaLabel:
				getTextByIdSelector(state, { id: pageItem.ariaLabel }) ||
				pageItem.ariaLabel,
		};
	};
	return mapStateToProps;
};

export default connect(makeMapStateToProps)(PageItemComponent);
