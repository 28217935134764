import classNames from "classnames";
import Choice, {
	ChoiceItemValue,
	ChoiceVariant,
} from "components/Choice/Choice";
import Media, {
	MediaCrop,
	MediaFit,
} from "funkis-template/components/core/Media";
import Text from "funkis-template/components/core/Text";
import { PageItemMedia, TextId } from "funkis-template/models/player";
import React from "react";
import Style from "./KnowledgeQuizQuestion.module.css";

export type KnowledgeQuizQuestionModel = {
	id: string;
	title: TextId;
	question: TextId;
	options: KnowledgeQuizQuestionOption[];
	media?: PageItemMedia;
};

export type KnowledgeQuizQuestionOption = {
	id: string;
	textId: TextId;
	correct: boolean;
};

export type KnowledgeQuizQuestionProps = KnowledgeQuizQuestionModel & {
	onChange: (questionId: string, value: ChoiceItemValue) => void;
	questionNumber: number;
	questionsCount: number;
	disabled: boolean;
	selectedOption?: ChoiceItemValue;
	optionVariant: ChoiceVariant;
	optionColor?: string;
	optionsTitle: TextId;
};

const KnowledgeQuizQuestion: React.FC<KnowledgeQuizQuestionProps> = ({
	id,
	title: questionTitle,
	question,
	media,
	onChange,
	options,
	questionNumber,
	questionsCount,
	disabled,
	selectedOption,
	optionVariant,
	optionColor,
	optionsTitle,
}) => {
	const handleChange = React.useCallback(
		(value) => {
			onChange(id, value);
		},
		[id, onChange]
	);

	const choices = options.map((option) => ({
		value: option.id,
		title: option.textId,
	}));

	return (
		<div className={classNames(Style.question)}>
			<div className="row m-0 mb-4 ">
				<div className="col-sm mb-4">
					<Text
						textId={questionTitle}
						tagName="h2"
						className="font-weight-bold"
						variables={{
							questionIndex: questionNumber,
							questionsCount: questionsCount,
						}}
					/>
					<Text textId={question} tagName="p" />
				</div>
				<div className="col-sm">
					{media && (
						<Media
							src={media}
							mediaCrop={MediaCrop.ResizeToFit}
							mediaFit={MediaFit.FullWidth}
						/>
					)}
				</div>
			</div>
			<div className="row m-0">
				<div className="col-sm">
					<Text
						textId={optionsTitle}
						tagName="h2"
						className="font-weight-bold"
						variables={{
							questionIndex: questionNumber,
							questionsCount: questionsCount,
						}}
					/>
					<Choice
						items={choices}
						onChange={handleChange}
						disabled={disabled}
						selectedValue={selectedOption}
						variant={optionVariant}
						idleBackgroundColor={optionColor}
					/>
				</div>
			</div>
		</div>
	);
};

export default KnowledgeQuizQuestion;
