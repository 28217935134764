import React from "react";
import classnames from "classnames";
import styled from "styled-components";
import PropTypes from "prop-types";

const Div = styled.div`
	height: ${({ size }) => size}px;
	background-color: ${({ color }) => color};
	width: 100%;
`;

const HorizontalLine = ({ size, color, className, ...rest }) => {
	return (
		<Div
			className={classnames("horizontal-line", className)}
			size={size}
			color={color}
			{...rest}
		/>
	);
};

HorizontalLine.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
};

HorizontalLine.defaultProps = {
	size: 5,
	color: "black",
};

export default HorizontalLine;
