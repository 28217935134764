import _ from "lodash";
import {
	getPageItemIdsFromQuery,
	getSibling,
	getPageItemIdFromQuery,
} from "../utils/PageItemsUtils";
import { getReducer } from "../utils/StoreUtils";
import {
	addWords,
	removeWords,
	toggleState as toggleStateUtil,
} from "../utils/StringUtils";
import {
	updatePageItem as _updatePageItem,
	updatePageItems as _updatePageItems,
} from "../redux/actions/PageItemActions";

export function updatePageItem(query, updates) {
	if (typeof query === "string") {
		return _updatePageItem(query, updates);
	} else {
		const ids = getPageItemIdsFromQuery(query, this);
		return _updatePageItems(
			ids.map((id) => {
				const obj = Object.assign({}, updates);
				obj.id = id;
				return obj;
			})
		);
	}
}

export function updatePageItems(ids, updates) {
	return updatePageItem(ids, updates);
}

export function updatePageItemSelf(updates) {
	const selfId = _.get(this, "pageItem.id") || this.parentPageItemId;
	return updatePageItem(selfId, updates);
}

export function updatePageItemParent(updates) {
	return updatePageItem(this.pageItem.parentPageItemId, updates);
}

export function updatePageItemSibling(offset = 1, updates) {
	const pageItems = getReducer("pageItems").items;
	const pageItemSibling = getSibling(this.pageItem.id, pageItems, offset);
	if (pageItemSibling) {
		return updatePageItem(pageItemSibling.id, updates);
	} else {
		console.warn(`No sibling found for pageItem!`);
	}
}

export function updatePageItemByFriendlyId(
	friendlyId,
	updates,
	updateAll = false
) {
	const pageItems = getReducer("pageItems").items;
	const foundPageItems = pageItems.filter((pi) => pi.friendlyId === friendlyId);

	if (!foundPageItems.length) {
		console.warn(`No pagw item(s) found for friendlyId:${friendlyId}!`);
		return;
	}

	if (updateAll) {
		const updates = foundPageItems.map((foundPageItem) =>
			Object.assign({ id: foundPageItem.id }, updates)
		);
		updatePageItems(updates);
	} else {
		if (foundPageItems.length > 1) {
			console.warn(
				`Several page items with friendlyId:${friendlyId} wher found! Dif you forget to pass updateAll=true?`
			);
		}
		updatePageItem(foundPageItems[0].id, updates);
	}
}

export function addClassNamesToPageItem(pageItemId, clasNames) {
	pageItemId = getPageItemIdFromQuery(pageItemId, this);

	// Single
	const pageItemsById = getReducer("pageItems").itemsById;
	let pageItem;
	if (typeof pageItemId === "string") {
		pageItem = pageItemsById[pageItemId];
		return updatePageItem(pageItemId, {
			className: addWords(pageItem.className, clasNames),
		});
	}
	// Batch
	const ids = getPageItemIdsFromQuery(pageItemId, this);
	const updates = ids.map((id) => {
		pageItem = pageItemsById[id];
		const obj = Object.assign(
			{},
			{ className: addWords(pageItem.className, clasNames) }
		);
		obj.id = id;
		return obj;
	});
	return updatePageItems(updates);
}

export function removeClassNamesInPageItem(pageItemId, clasNames) {
	pageItemId = getPageItemIdFromQuery(pageItemId, this);

	const pageItemsById = getReducer("pageItems").itemsById;
	let pageItem;
	if (typeof pageItemId === "string") {
		pageItem = pageItemsById[pageItemId];
		return updatePageItem(pageItemId, {
			className: removeWords(pageItem.className, clasNames),
		});
	}
	const ids = getPageItemIdsFromQuery(pageItemId, this);
	const updates = ids.map((id) => {
		pageItem = pageItemsById[id];
		const obj = Object.assign(
			{},
			{ className: removeWords(pageItem.className, clasNames) }
		);
		obj.id = id;
		return obj;
	});
	return updatePageItems(updates);
}

export function showNextPageItem(pageItemViewerId) {
	showNextOrPreviousPageItem(pageItemViewerId, 1);
}

export function showPreviousPageItem(pageItemViewerId) {
	showNextOrPreviousPageItem(pageItemViewerId, -1);
}

export function toggleState(pageItemId, params) {
	pageItemId = getPageItemIdFromQuery(pageItemId, this);
	const pageItemsById = getReducer("pageItems").itemsById;
	const pageItem = pageItemsById[pageItemId];
	const toggles = Array.isArray(params) ? params : [params];
	const stateToggle = toggleStateUtil(toggles, pageItem.stateToggle);
	return updatePageItem(pageItemId, { stateToggle });
}

// HELPER FUNCTIONS

function showNextOrPreviousPageItem(pageItemViewerId, direction) {
	const pageItemsById = getReducer("pageItems").itemsById;
	const pageItemViewer = pageItemsById[pageItemViewerId];
	const currIndex = pageItemViewer.index;
	let newIndex;
	if (direction >= 1) {
		newIndex =
			pageItemViewer.index < pageItemViewer.pageItemIds.length - 1
				? currIndex + 1
				: 0;
	} else {
		newIndex =
			pageItemViewer.index > 0
				? currIndex - 1
				: pageItemViewer.pageItemIds.length - 1;
	}
	updatePageItem(pageItemViewerId, { index: newIndex });
}
