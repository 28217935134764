import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import classnames from "classnames";
import { TweenMax } from "gsap";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";
import SimpleReveal from "../../../components/core/SimpleReveal";
import IOMultiSelect from "../../../components/core/version-2/IOMultiSelect";
import SelectItem from "../../../components/core/version-2/SelectItem";
import Image from "../../../components/core/Image";
import Text from "../../../components/core/Text";
import { getText } from "../../../utils/textUtils";
import { useSelector } from "react-redux";

const PageItemIOMultipleChoiceInput = (props) => {
	const { pageItem, correctDelay = 0, onSelect } = props;
	const { cmtPageItem = {} } = pageItem;
	const blurImage = cmtPageItem.blur_image_before_answer === "yes";

	const valueLists = useSelector((state) =>
		_.get(state, "app.item.value_lists", [])
	);
	const valueList = valueLists.find(
		(list) => list.id === cmtPageItem.valuelist_id
	);
	const valueListIsValid =
		valueList && valueList.template === "multiple-choice";

	const instruction = cmtPageItem.instruction;
	const imageSize = cmtPageItem.image_size;
	const imageAlign = cmtPageItem.image_align;
	const maxHeight = cmtPageItem.image_max_height;
	const imageMarginsMobile = cmtPageItem.image_margins_mobile !== "no";
	const options = valueListIsValid
		? valueList.values
		: cmtPageItem.content_array;
	const [selectedIndexes, setSelectedIndexes] = useState([]);
	const [completed, setCompleted] = useState(false);
	const mode = cmtPageItem.theme || cmtPageItem.mode;
	const correct = cmtPageItem.correct === "yes";
	const [showNextButton, setShowNextButton] = useState(
		cmtPageItem.show_next_button === "always"
	);
	const refFeedback = useRef(null);

	const [blurDuration] = useState(!completed && blurImage ? 1.5 : 0);

	useEffect(() => {
		if (completed && refFeedback.current) {
			const delay = correct ? correctDelay * options.length : 0.6;
			TweenMax.set(refFeedback.current, { opacity: 0, rotateY: 180 });
			TweenMax.to(refFeedback.current, 0.5, { opacity: 1, rotateY: 0, delay });
			TweenMax.set(refFeedback.current, { opacity: 0, rotateY: 180 });

			cmtPageItem.show_next_button === "on-done" &&
				setTimeout(() => {
					setShowNextButton(true);
				}, delay * 1000);
		} else {
			cmtPageItem.show_next_button === "on-done" && setShowNextButton(false);
		}
	}, [completed, cmtPageItem.show_next_button, refFeedback.current]);

	// cmtPageItem.show_next_button === "auto" is deprecated. Is here because of backward compability.
	useEffect(() => {
		if (
			cmtPageItem.show_next_button === "on-select" ||
			cmtPageItem.show_next_button === "auto"
		) {
			setShowNextButton(selectedIndexes.length ? true : false);
		}
	}, [selectedIndexes, cmtPageItem.show_next_button]);

	useEffect(() => {
		refFeedback.current && TweenMax.set(refFeedback.current, { opacity: 0 });
	}, [refFeedback.current]);

	const minSelections =
		(mode === "radio" && 1) || Number(cmtPageItem.min_selections) || 1;
	const maxSelections =
		(mode === "radio" && 1) ||
		Number(cmtPageItem.max_selections) ||
		Number.MAX_VALUE;

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={showNextButton}
			className="px-0 mx-0"
			containerClassName="w-100"
			renderFunction={({ viewVisible }) => (
				<React.Fragment>
					{/* IMAGE */}
					{cmtPageItem.image && (
						<div
							className={classnames(
								imageMarginsMobile ? "container-fluid" : ""
							)}
						>
							<div
								className={classnames(
									"row m-0 mb-5 animated",
									viewVisible ? "fadeIn" : "fadeOut"
								)}
							>
								<Image
									src={cmtPageItem.image}
									className={classnames(
										imageMarginsMobile ? "col-12" : "w-100 px-sm-0 px-md-5"
									)}
									size={imageSize}
									maxHeight={maxHeight}
									align={imageAlign}
									style={{
										filter: blurImage ? !completed && "blur(20px)" : "blur(0)",
										transition: blurImage && `filter ${blurDuration}s`,
									}}
								/>
							</div>
						</div>
					)}

					<div className="container-fluid">
						{/* TEXT BLOCK */}
						<div
							className={classnames(
								"row m-0 mb-5 animated",
								viewVisible ? "fadeIn" : "fadeOut"
							)}
						>
							{/* QUESTION */}
							<Text
								tagName="h2"
								textId={cmtPageItem.question || cmtPageItem.header}
								className="col-sm-12 mb-4 font-weight-bold"
							/>
							{/* BODY */}
							<Text
								tagName="p"
								textId={cmtPageItem.body}
								className="col-sm-12"
							/>
							{/* INSTRUCTION */}
							<Text
								textId={instruction}
								className="col-sm-9 instruction font-italic"
							/>
						</div>
						{/* OPTIONS */}
						<IOMultiSelect
							className={classnames("row m-0")}
							options={options}
							pageItem={pageItem}
							maxSelections={maxSelections}
							minSelections={minSelections}
							onSelect={onSelect}
							doneButtonEnabled={
								cmtPageItem.show_done_button === "yes" ||
								cmtPageItem.correct === "yes"
							}
							autoDeselectWhenMaxSelectionsReached={
								mode === "radio" || maxSelections > 0
							}
							buttonClassName={classnames(
								"mb-4",
								options.length > 10 ? "col-sm-4" : "col-sm-9"
							)}
							optionRender={(renderProps) => {
								const { option, selected, index, enabled, isCorrect } =
									renderProps;

								// Equal test to prevent rerender.
								if (!_.isEqual(renderProps.selectedIndexes, selectedIndexes)) {
									setSelectedIndexes(renderProps.selectedIndexes);
								}
								setCompleted(renderProps.completed);

								const status =
									(completed &&
										correct &&
										selected &&
										isCorrect &&
										"selected-correct") ||
									(completed &&
										correct &&
										selected &&
										!isCorrect &&
										"selected-incorrect") ||
									(completed &&
										correct &&
										!selected &&
										isCorrect &&
										"unselected-correct") ||
									(completed &&
										correct &&
										!selected &&
										!isCorrect &&
										"unselected-incorrect") ||
									undefined;
								// console.log({ completed, correct, selected, isCorrect, status });
								return (
									<SelectItem
										key={`multi-choice-${index}`}
										mode={mode}
										index={index}
										enabled={enabled}
										correct={cmtPageItem.correct !== "no"}
										correctDelay={correctDelay}
										textId={option.option}
										image={option.image}
										selected={selected}
										status={status}
										completed={completed}
										className={classnames(
											"animated",
											viewVisible ? "fadeInUp" : "fadeOut"
										)}
										style={{ animationDelay: index * 0.15 + "s" }}
									/>
								);
							}}
						>
							{(renderProps) => {
								//  ANSWER - Shows if COMPLETED and if there is feedback text
								return (
									<div ref={refFeedback}>
										<SimpleReveal
											reveal={
												completed &&
												(getText(cmtPageItem.feedback_correct) ||
													getText(cmtPageItem.feedback_incorrect))
											}
											className="row m-0 mt-2"
										>
											<Text
												className={classnames(
													"col-9 p-md",
													completed ? "animated fadeInUp" : "opacity-0"
												)}
												textId={
													renderProps.isCorrect
														? cmtPageItem.feedback_correct
														: cmtPageItem.feedback_incorrect
												}
											/>
										</SimpleReveal>
									</div>
								);
							}}
						</IOMultiSelect>
					</div>
				</React.Fragment>
			)}
		/>
	);
};

export default PageItemIOMultipleChoiceInput;
