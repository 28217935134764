import * as StoreUtils from "../../utils/StoreUtils";
import {
	UPDATE_APP,
	UPDATE_APP_VALUE,
	REMOVE_APP_VALUE,
	MAKE_APP_SNAPSHOT,
	RESTORE_FROM_APP_SNAPSHOT,
} from "../constants/ActionConstants";

export function updateApp(item) {
	StoreUtils.getStore().dispatch(_updateApp(item));
}

export function updateAppValue(item) {
	StoreUtils.getStore().dispatch(_updateAppValue(item));
}

export function removeAppValue(item) {
	StoreUtils.getStore().dispatch(_removeAppValue(item));
}

export function makeAppSnapshot(item) {
	StoreUtils.getStore().dispatch(_makeAppSnapshot(item));
}

export function restoreFromAppSnapshot(item) {
	StoreUtils.getStore().dispatch(_restoreFromAppSnapshot(item));
}

function _updateApp(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_APP });
	};
}

function _updateAppValue(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_APP_VALUE });
	};
}

function _removeAppValue(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_APP_VALUE });
	};
}

function _makeAppSnapshot(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: MAKE_APP_SNAPSHOT });
	};
}

function _restoreFromAppSnapshot(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: RESTORE_FROM_APP_SNAPSHOT });
	};
}
