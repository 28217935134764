import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { PageItemMenuButton } from "funkis-foundation";
import { isPageInLocation } from "../../../utils/locationUtils";
import { getText } from "../../../utils/textUtils";

import IconStatusIncomplete from "../../../assets/svg/IconStatusIncomplete";
import IconStatusCompleted from "../../../assets/svg/IconStatusCompleted";
import IconTarget from "../../../assets/svg/IconTarget";
import IconPadlock from "../../../assets/svg/IconPadlock";
import Hover from "../../../components/core/Hover";

import StylesGlobal from "../../../StylesGlobal.module.css";
import { getBootstrapColor } from "../../../utils/colorUtils";
import _ from "lodash";

const statusIcons = {
	not_attempted: IconPadlock,
	incomplete: IconStatusIncomplete,
	unlocked: IconStatusIncomplete,
	completed: IconStatusCompleted,
	active: IconTarget,
};

const StatusIcon = ({ status, className, style, color, secondaryColor }) => {
	const Icon = statusIcons[status];
	return (
		<Icon
			className={className}
			style={style}
			color={color}
			secondaryColor={secondaryColor}
		/>
	);
};

const SideMenuPageButton = (props) => {
	const { index, chapterIndex, onClick, id, pageId, isChapterEnabled } = props;
	const location = useSelector((state) =>
		_.get(state, "location.item.location")
	);
	const page = useSelector(
		({ pages: { itemsById } }) => itemsById[pageId || id]
	);

	const { cmtPage } = page.data;
	const isCurrentPage = isPageInLocation({ pageId: page.id, location });
	const enabled =
		isChapterEnabled && (page.status !== "not_attempted" || index === 0);
	const status = (isCurrentPage && "active") || page.status;

	return (
		<Hover
			enabled={enabled}
			style={{
				backgroundColor: getBootstrapColor(
					(isCurrentPage && "white") || "#ECECEC"
				),
			}}
		>
			{({ hover }) => {
				const iconColor = getBootstrapColor(
					((hover || status === "active") && "interaction") || "primary"
				);
				return (
					<PageItemMenuButton
						pageItem={{ pageId: page.id, enabled }}
						className={classnames(
							"pl-5 py-3 pr-3 d-flex align-items-center",
							StylesGlobal.button,
							`page-status-${page.status}`
						)}
						enabled={enabled}
						onClick={() => {
							onClick && onClick(props);
						}}
						style={{
							opacity: isChapterEnabled ? 1 : 0.25,
						}}
					>
						<StatusIcon
							className={classnames(status === "active" && "pulsate")}
							status={status}
							color={status === "incomplete" ? "white" : iconColor}
							secondaryColor={getBootstrapColor("primary")}
						/>
						<span
							className={"ml-1 p-medium d-block"}
							style={{
								color: getBootstrapColor((hover && "interaction") || "black"),
							}}
						>
							{/* {`${chapterIndex + 1}.${index + 1} ${getText(cmtPage.title_txt_id)}`} */}
							{getText(cmtPage.title_txt_id)}
						</span>
					</PageItemMenuButton>
				);
			}}
		</Hover>
	);
};

export default SideMenuPageButton;
