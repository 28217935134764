import React from "react";

const IconPlay = () => {
	return (
		<svg
			width="19px"
			height="30px"
			viewBox="0 0 19 30"
			version="1.1"
			style={{ transform: "translate(3px,1px)" }}
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="Group-5" transform="translate(0, 0)">
					<g id="Fill-4" transform="translate(0, 0)" fill="#FFFFFF">
						<polygon points="3.19744231e-13 2.48689958e-14 18.896 14.172 3.19744231e-13 28.344"></polygon>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default IconPlay;
