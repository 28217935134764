
import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	removeItemInStateItems,
} from "../utils/reducerUtils";

import {
	UPDATE_QUIDDITY_EVENTS,
	UPDATE_QUIDDITY_EVENT,
	REMOVE_QUIDDITY_EVENT,
} from "../constants/ActionConstants";

const quiddityEvents = (state = initialStateObjectCollection(), action) => {
	switch (action.type) {
		case UPDATE_QUIDDITY_EVENTS:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_QUIDDITY_EVENT:
			return updateItemInStateItems(state, action.item);
		case REMOVE_QUIDDITY_EVENT:
			return removeItemInStateItems(state, action.item);
		default:
			return state;
	}
};

export default quiddityEvents;
