import React from "react";
import classnames from "classnames";
import Style from "./SidePanel.module.css";
import { getBootstrapColor } from "../../../utils/colorUtils";

const SidePanel = (props) => {
	const {
		open,
		align,
		children,
		backgroundColor = "dark-gray",
		className,
		innerClassName,
	} = props;

	const styleOpen = align === "right" ? Style.openRight : Style.openLeft;
	const styleClosed = align === "right" ? Style.closedRight : Style.closedLeft;

	return (
		<div
			className={classnames(
				Style.outerContainer,
				"no-print side-menu outer-container",
				open,
				className
			)}
		>
			<div
				className={classnames(
					innerClassName,
					Style.innerContainer,
					open ? styleOpen : styleClosed
				)}
				style={{ backgroundColor: getBootstrapColor(backgroundColor) }}
			>
				{children}
			</div>
		</div>
	);
};

export default SidePanel;
