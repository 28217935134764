import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { makeGetPageItemIdsByParentPageId } from "../selectors/PageItemSelectors";
import PageItem from "../components/core/PageItem";

class GlobalContainer extends React.Component {
	static propTypes = {
		pageItemIds: PropTypes.array.isRequired, // comming from connect
		parentPageId: PropTypes.string,
		parentPageItemId: PropTypes.string,
		layer: PropTypes.string,
	};

	static defaultProps = { parentPageId: "global-container", layer: "mid" };

	render() {
		const { pageItemIds, layer, className, parentPageId } = this.props;
		return (
			<div className={classnames(className, parentPageId)}>
				{pageItemIds.map((pageItemId) => (
					<PageItem
						key={`page-it em-${pageItemId}`}
						id={pageItemId}
						layer={layer}
					/>
				))}
			</div>
		);
	}
}

const makeMapStateToProps = () => {
	const getPageItemIdsByParentPageId = makeGetPageItemIdsByParentPageId();
	const mapStateToProps = (state, props) => {
		return {
			pageItemIds: getPageItemIdsByParentPageId(state, {
				...props,
				layer: "mid",
			}),
		};
	};
	return mapStateToProps;
};

export default connect(makeMapStateToProps)(GlobalContainer);
