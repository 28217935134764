import Details from "components/Details/Details";
import Text from "funkis-template/components/core/Text";
import Article from "funkis-template/components/scroll/Article";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { getBootstrapColor } from "funkis-template/utils/colorUtils";
import { CSSProperties, useState } from "react";
import { useSelector } from "react-redux";

const PageItemReadMore = (props) => {
	const {
		pageItem: { cmtPageItem },
	} = props;

	const dividerColor = useSelector(
		(state: any) =>
			cmtPageItem.divider_color ||
			state.app.item.color_block_divider ||
			state.app.item.block_divider
	);
	const [isExpanded, setIsExpanded] = useState(false);

	const dividerTextColor = cmtPageItem.divider_text_color || dividerColor;

	const styleOverrides = {
		"--details-text-color": getBootstrapColor(dividerTextColor),
		"--details-icon-color": getBootstrapColor(dividerTextColor),
		"--details-divider-color": getBootstrapColor(dividerColor),
	} as CSSProperties;

	return (
		<PageItemScrollBase
			{...props}
			containerClassName="w-100"
			renderFunction={(renderProps) => {
				return (
					<div className="container-fluid" style={styleOverrides}>
						<div className="row m-0">
							<div className="col-sm-12">
								<Details
									open={isExpanded}
									onToggle={() => setIsExpanded(!isExpanded)}
									expandLabel={<Text textId={cmtPageItem.label_open} />}
									collapseLabel={<Text textId={cmtPageItem.label_open} />}
								>
									<div className="mx-sm-n5 mx-n4">
										<Article
											{...props}
											viewVisible={true}
											animate={false}
										></Article>
									</div>
								</Details>
							</div>
						</div>
					</div>
				);
			}}
		/>
	);
};

export default PageItemReadMore;
