import * as StoreUtils from "./StoreUtils";
import { convertStatusConstantToScormStatus } from "./StatusUtils";
import * as Storages from "../Storages";

export function getStorage(storageName) {
	console.warn(
		`StorageUtils function getStorage is depricated. Pleas use Storages getStorage!`
	);
	return Storages.getStorage(storageName);
}

export function getData() {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;

	var data = storage.getData(domain);

	if (!data || data === "null") {
		return {};
	}

	try {
		return JSON.parse(data);
	} catch (e) {
		return data || {};
	}

	return data;
}

export function setData(name, data) {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;

	storage.setData(name, domain, JSON.stringify(data));
}

export function clearData() {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;
	return storage.clearData(domain);
}

export function getCourseStatus() {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;
	return storage.getCourseStatus(domain);
}

export function setCourseStatus(value) {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;

	// Our status constants are not proper scorm status string. They must
	// be converted when saved to storage.
	const status = convertStatusConstantToScormStatus(value);
	storage.setCourseStatus(domain, status);
}

export function getCompletionStatus() {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;

	return storage.getCompletionStatus(domain);
}

export function setCompletionStatus(value) {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;

	// Our status constants are not proper scorm status string. They must
	// be converted when saved to storage.
	const status = convertStatusConstantToScormStatus(value);
	storage.setCompletionStatus(domain, status);
}

export function getSuccessStatus() {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;
	return storage.getSuccessStatus(domain);
}

export function setSuccessStatus(value) {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;

	// Our status constants are not proper scorm status string. They must
	// be converted when saved to storage.
	let status = convertStatusConstantToScormStatus(value);
	if (!status) {
		console.error("unknown status - defaulting to 'passed'", value);
		status = "passed";
	}
	storage.setSuccessStatus(domain, status);
}

export function getScore() {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;
	return storage.getScore(domain);
}

export function setScore(rawScore, minScore, maxScore) {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;
	storage.setScore(domain, rawScore, minScore, maxScore);
}

export function getUserName() {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;
	storage.getUserName(domain);
}

export function getUserId() {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;
	return storage.getUserId(domain);
}
export function getQuiddityTitleId() {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;
	return storage.getQuiddityTitleId(domain);
}
export function getQuiddityTitleOrganizationId() {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;
	return storage.getQuiddityTitleOrganizationId(domain);
}
export function getQuiddityTitleName() {
	const config = StoreUtils.getReducer("config").item;
	const storage = Storages.getStorage(config.storage);
	const domain = config.projectPrefix;
	return storage.getQuiddityTitleName(domain);
}
