import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { MathUtils } from "funkis-foundation";
import { useWindowResize } from "../../hooks/resize";

const { scaleToFit } = MathUtils;

const calcStyles = ({ width, height, transform, result }) => {
	return {
		outerContainer: {
			overflow: "hidden",
			height: `${height * result.scaleY}px`,
		},
		source: {
			transformOrigin: "top left",
			transform: transform,
			width: `${width}px`,
			height: `${height}px`,
		},
	};
};

const Scalable = ({
	className,
	innerClassName,
	style,
	children,
	targetWindow = false,
	objectFit = "cover",
	translate = false,
	translateX = false,
	translateY = false,
	width,
	height,
}) => {
	const [isMounted, setIsMounted] = useState(false);
	const [result, setResult] = useState({});
	const refTarget = useRef(null);
	const windowSize = useWindowResize();

	// Calculate transform.
	useEffect(() => {
		if (isMounted && windowSize) {
			const targetBoundingRect = refTarget.current.getBoundingClientRect();

			const target = {
				width: targetWindow
					? window.offsetWidth || window.innerWidth
					: targetBoundingRect.width,
				height: targetWindow
					? window.offsetHeight || window.innerHeight
					: targetBoundingRect.height,
			};
			const source = { width, height };
			// console.log("scalable", { target }, scaleToFit(source, target, objectFit));
			setResult(scaleToFit(source, target, objectFit));
		}
	}, [windowSize, isMounted]);

	// Wait for component to be mounted.
	useEffect(() => {
		setIsMounted(true);
	}, []);

	// Translate...
	const transformTranslate =
		(translate && result.transformTranslate) ||
		(translateY && `translate(${0}px, ${result.translateY}px)`) ||
		(translateX && `translate(${result.translateX}px, ${0}px)`);

	// Update styles...
	const styles = calcStyles({
		transform: transformTranslate
			? `${transformTranslate} ${result.transformScale}`
			: result.transformScale,
		result,
		width,
		height,
	});

	// console.log("scalable", styles.source);

	return (
		<div className={className} style={styles.outerContainer}>
			<div ref={refTarget} style={style}>
				<div className={innerClassName} style={styles.source}>
					{children}
				</div>
			</div>
		</div>
	);
};

Scalable.propTypes = {
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
};

export default Scalable;
