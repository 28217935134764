import _ from "lodash";
import * as StoreUtils from "./StoreUtils";
import uuid from "uuid/v4";
import * as EventConstants from "../constants/EventConstants";
import PageItemComponent from "../components/core/PageItemComponent";

let _pageItemSeeds = [];
let _defaultPageItemComponent = PageItemComponent;

export function setDefaultPageItemComponent(component) {
	_defaultPageItemComponent = component;
}

export function getDefaultPageItemComponent() {
	return _defaultPageItemComponent;
}

export function findPageItem(pageItems, key, value) {
	return pageItems.find((pageItem) => pageItem[key] === value);
}

export function filterPageItems(pageItems, key, value) {
	return pageItems.filter((pageItem) => pageItem[key] === value);
}

export function isPageItemPropsEqual(id, params) {
	const ids = getPageItemIdsFromQuery(id, this);

	if (ids.length === 1) {
		id = ids[0];
	} else {
		throw new Error(`The query ${id} is not valid.`);
	}

	const pageItem = StoreUtils.getReducer("pageItems").items.find(
		(item) => item.id === id
	);

	const paramsKeys = _.keys(params);
	const omitedPageItem = _.pick(pageItem, paramsKeys);

	return _.isEqual(params, omitedPageItem);
}

export function isEveryPageItemsStatus(pageItemIds, status) {
	const pageItems = StoreUtils.getReducer("pageItems").items;
	const pageItemsToTest = [];

	pageItemIds.forEach((pageItemId) => {
		const pi = pageItems.find((pageItem) => pageItem.id === pageItemId);
		if (!pi) {
			console.warn(`Can not find any pageItem with id: ${pageItemId}!`);
			return false;
		} else {
			pageItemsToTest.push(pi);
		}
	});

	return pageItemsToTest.every((pageItem) => pageItem.status === status);
}

export function getPageItemsForPageId(pageId, pageItems) {
	const pageItemsForParentPageItemId = pageItems.filter(
		(pageItem) => pageItem.parentPageId === pageId && !pageItem.parentPageItemId
	);
	const pageItemsChildren = _.flatten(
		pageItemsForParentPageItemId.map((pageItem) =>
			getPageItemsChildrenForPageItemId(pageItem.id, pageItems)
		)
	);
	return _.flatten([pageItemsForParentPageItemId, pageItemsChildren]);
}

export function getPageItemsChildrenForPageItemId(
	pageItemId,
	pageItems,
	recursivly = true
) {
	let pageItemsChildren = pageItems.filter(
		(pageItem) => pageItem.parentPageItemId === pageItemId
	);
	if (recursivly) {
		pageItemsChildren = getPageItemsChildrenForPageItemIdRecursivly(
			pageItemsChildren,
			pageItems,
			pageItemsChildren
		);
	}
	return pageItemsChildren;
}

function getPageItemsChildrenForPageItemIdRecursivly(
	pageItemsChildren,
	pageItems,
	result = []
) {
	pageItemsChildren.forEach((pageItemsChild) => {
		const foundPageItemsChildren = pageItems.filter(
			(pageItem) => pageItem.parentPageItemId === pageItemsChild.id
		);
		result = result.concat(foundPageItemsChildren);
		if (foundPageItemsChildren.length) {
			result = getPageItemsChildrenForPageItemIdRecursivly(
				foundPageItemsChildren,
				pageItems,
				result
			);
		}
	});
	return result;
}

export function hasChildren(pageItemId) {
	const pageItems = StoreUtils.getReducer("pageItems").items;
	return (
		pageItems.find((pageItem) => pageItem.parentPageItemId === pageItemId) !==
		undefined
	);
}

export function getTimeEventActionsForTimeSpan(
	actions,
	pageItem,
	timeStart,
	timeEnd
) {
	const onTimeActions = actions.filter(
		(action) =>
			action.parentPageItemId === pageItem.id &&
			action.event === EventConstants.ON_TIME
	);
	return onTimeActions.filter(
		(action) =>
			action.event === "onTime" &&
			action.time > timeStart &&
			action.time <= timeEnd
	);
}

export function createUniquePageItemId() {
	const pageItems = StoreUtils.getReducer("pageItems").items;
	let pageItemId = uuid();
	while (pageItems.find((item) => item.id === pageItemId)) {
		pageItemId = uuid();
	}
	return pageItemId;
}

export function copyPageItem(
	pageItemId,
	newParentPageId,
	newParentPageItemId = undefined,
	newPageItemId = undefined,
	pageItems = null
) {
	pageItems = pageItems || StoreUtils.getReducer("pageItems").items;
	const pageItem = pageItems.find((pi) => pi.id === pageItemId);
	const pageItemCopy = Object.assign({}, pageItem, {
		parentPageId: newParentPageId || pageItem.parentPageId,
		parentPageItemId:
			newParentPageItemId === undefined
				? pageItem.parentPageItemId
				: newParentPageItemId,
	});
	const pageItemChildren = pageItems.filter(
		(pi) => pi.parentPageItemId === pageItem.id
	);
	const copiedPageItems = copyPageItemsRecursevly(
		pageItemCopy,
		pageItemChildren,
		pageItems
	);

	if (newPageItemId) {
		const parentPageItemId = copiedPageItems[0].id;
		copiedPageItems.forEach((pi) => {
			if (pi.id === parentPageItemId) {
				pi.id = newPageItemId;
			}
			if (pi.parentPageItemId === parentPageItemId) {
				pi.parentPageItemId = newPageItemId;
			}
		});
	}

	// Make sure that there will be no referencing
	return JSON.parse(JSON.stringify(copiedPageItems));
}

function copyPageItemsRecursevly(
	pageItem,
	pageItemChildren,
	pageItems,
	copiedPageItems
) {
	const pageItemCopy = copiedPageItems
		? pageItem
		: Object.assign({}, pageItem, {
				id: createUniquePageItemId(),
				originalPageItemId: pageItem.id,
		  });
	const pageItemChildrenCopy = pageItemChildren.map((pi) =>
		Object.assign({}, pi, {
			id: createUniquePageItemId(),
			originalPageItemId: pi.id,
			parentPageItemId: pageItemCopy.id,
			parentPageId: pageItemCopy.parentPageId,
		})
	);

	copiedPageItems = copiedPageItems || [pageItemCopy];
	copiedPageItems = copiedPageItems.concat(pageItemChildrenCopy);

	pageItemChildrenCopy.forEach((pi) => {
		const piChildren = pageItems.filter(
			(piChild) => piChild.parentPageItemId === pi.originalPageItemId
		);
		copiedPageItems = copyPageItemsRecursevly(
			pi,
			piChildren,
			pageItems,
			copiedPageItems
		);
	});

	return copiedPageItems;
}

export function addPageItemSeeds(pageItemSeeds, overwrite = true) {
	pageItemSeeds.forEach((pageItem) => {
		const index = _pageItemSeeds.findIndex((pi) => {
			return pi.type === pageItem.type;
		});
		if (index === -1) {
			_pageItemSeeds.push(pageItem);
		} else if (overwrite) {
			_pageItemSeeds[index] = pageItem;
		}
	});
}

export function getPageItemSeedByType(type) {
	const seed = _pageItemSeeds.find((pageItem) => pageItem.type === type);
	if (seed) {
		return seed;
	} else {
		console.warn(
			`No seed found for type "${type}"! Please add a page item seed to "page-item-seeds.json"`
		);
		return { events: [] };
	}
}

export function createPageItemFromType(type, props) {
	let pageItem = getDefaultPageItemProps();
	pageItem = Object.assign(
		{ type },
		pageItem,
		getPageItemSeedByType(type).defaultProps,
		props
	);
	return pageItem;
}

export function getSibling(pageItemId, pageItems, offset = -1) {
	offset = Number(offset);
	const pageItem = pageItems.find((pi) => pi.id === pageItemId);
	let siblings;

	if (pageItem.parentPageId && !pageItem.parentPageItemId) {
		siblings = pageItems.filter(
			(pi) =>
				pi.parentPageId === pageItem.parentPageId &&
				pi.parentPageItemId === null
		);
	} else if (pageItem.parentPageItemId) {
		siblings = pageItems.filter(
			(pi) => pi.parentPageItemId === pageItem.parentPageItemId
		);
	}

	if (!siblings) {
		return undefined;
	}

	const pageItemSiblingIndex = siblings.findIndex(
		(pi) => pi.id === pageItem.id
	);
	if (
		pageItemSiblingIndex + offset >= 0 &&
		pageItemSiblingIndex + offset <= siblings.length - 1
	) {
		return siblings[pageItemSiblingIndex + offset];
	} else {
		return undefined;
	}
}

export function getSiblingIndex(pageItemId, pageItems, offset = -1) {
	const pageItem = getSibling(pageItemId, pageItems, offset);
	return pageItem ? pageItems.findIndex((pi) => pi.id === pageItem.id) : -1;
}

const test = [{ parent: 0 }, { friendlyId: "" }];

export function getPageItemByPath(path, relativeToPageItemId) {
	if (!relativeToPageItemId) {
		console.warn(`Second parameter "relativeToPageItemId" to defined!`);
		return;
	}

	const pageItems = StoreUtils.getReducer("pageItems").items;
	const pageItemsById = StoreUtils.getReducer("pageItems").itemsById;
	let pageItem = pageItemsById[relativeToPageItemId];
	path.forEach((pathObj, index) => {
		const key = _.keys(pathObj)[0];
		switch (key) {
			case "friendlyId":
				if (!Array.isArray(pageItem)) {
					pageItem = getPageItemsChildrenForPageItemId(pageItem.id, pageItems);
				}
				pageItem = pageItem.filter((pi) => pi.friendlyId === pathObj[key]);
				if (index === path.length - 1) {
					pageItem = pageItem.length === 1 ? pageItem[0] : null;
				}
				break;
			case "parent":
				pageItem =
					pageItem && getParentPageItemForPageItemId(pageItem.id, pathObj[key]);
				// console.log(`${index}: Page item "${pageItem.name}" found for path ${JSON.stringify(pathObj)} were found!`);
				break;
			case "sibling":
				pageItem = pageItem && getSibling(pageItem.id, pageItems, pathObj[key]);
				break;
			case "child":
				const pageItemChildren = Array.isArray(pageItem)
					? pageItem
					: pageItem &&
					  getPageItemsChildrenForPageItemId(pageItem.id, pageItems, false);
				pageItem = pageItemChildren && pageItemChildren[pathObj[key]];
				break;
		}
	});

	if (!pageItem) {
		// console.warn(`No page item for path ${JSON.stringify(path)} were found!`);
	} else {
		// console.log(`Page item "${pageItem.name}" found for path ${JSON.stringify(path)} were found!`);
	}
	return pageItem;
}

export function getPageItemIdFromQuery(query, context) {
	const pageItems = StoreUtils.getReducer("pageItems").items;

	// friendlyId
	if (typeof query === "string") {
		const pageItem = pageItems.find(
			(pi) => pi.friendlyId === query && !pi.symbol
		);
		if (pageItem) {
			return pageItem.id;
		}
	}

	const ids = getPageItemIdsFromQuery(query, context);
	if (ids.length) {
		return ids[0];
	} else {
		return query;
	}
}

export function getPageItemIdsFromQuery(query, context) {
	if (query === "self" && context) {
		return [(context.pageItem && context.pageItem.id) || context.id];
	} else if (typeof query === "string") {
		return [query];
	}

	if (Array.isArray(query)) {
		if (query.length && typeof query[0] === "string") {
			// array of id:s
			return query;
		} else {
			// path to id
			if (!context.pageItem) {
				throw new Error(
					`Path can´t be used for this action because context pageItem could not be found.`
				);
			}
			const pageItem = getPageItemByPath(query, context.pageItem.id);
			return (pageItem && [pageItem.id]) || [];
		}
	}
}

export function getRootPageItemForPageItemId(pageItemId) {
	let parentPageItem = getParentPageItemForPageItemId(pageItemId);

	// If page item is root page item;
	if (!parentPageItem) {
		const pageItem = StoreUtils.getReducer("pageItems").itemsById[pageItemId];
		if (pageItem && !pageItem.parentPageItemId && pageItem.parentPageId) {
			return pageItem;
		}
	}

	let prevParentPageItem;
	while (parentPageItem) {
		prevParentPageItem = parentPageItem;
		parentPageItem = getParentPageItemForPageItemId(parentPageItem.id);
	}
	return prevParentPageItem;
}

export function getToggleClassNames(toggle) {
	const classNames = [];
	const toggleKeys = _.keys(toggle);
	toggleKeys.forEach((key) => {
		toggle[key]
			.filter((obj) => obj.active)
			.forEach((obj) => {
				!classNames.includes(obj.className) && classNames.push(obj.className);
			});
	});

	return classNames.join(" ");
}

function getParentPageItemForPageItemId(pageItemId, offset = -1) {
	if (offset === "page") {
		const rootPageItem = getRootPageItemForPageItemId(pageItemId);
		return getPageItemsForPageId(
			rootPageItem.parentPageId,
			StoreUtils.getReducer("pageItems").items
		);
	}

	if (offset === 0) {
		return getRootPageItemForPageItemId(pageItemId);
	}

	const pageItemsById = StoreUtils.getReducer("pageItems").itemsById;
	const iterations = Math.abs(offset);
	let iteration = 0;
	let pageItem = pageItemsById[pageItemId];
	while (iteration < iterations && pageItem) {
		pageItem = pageItemsById[pageItem.parentPageItemId];
		iteration++;
	}
	return pageItem;
}

export function getDefaultPageItemProps() {
	return {
		friendlyId: "",
		visible: true,
		enabled: true,
		symbol: false,
		status: "notAttempted",
		proxyType: null,
		enabled: true,
		layer: "mid",
		style: {},
		className: "",
		events: ["onIncomplete", "onCompleted", "onEnter", "onExit"],
		size: null,
		data: {},
		mapPropsToFunction: null,
		mapPropsToState: null,
		renderFunction: null,
	};
}

export function getAllPageItemSeeds() {
	return _pageItemSeeds;
}
