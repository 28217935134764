import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";

import { makeGetTextByIdSelector } from "../selectors/TextSelectors";
import { spliceVariables } from "../utils/TextUtils";
import { makeGetValueSelector } from "../redux/selectors/ObjectSelectors";

class FFText extends React.Component {
	render() {
		const {
			placeholder,
			text,
			textId,
			tagName: CustomTag = "span",
			style,
			className,
			children,
			variables,
			maxChar,
			rest,
			showMissinTextWarnings,
		} = this.props;
		let htmlText = spliceVariables(text || placeholder, variables);
		htmlText = maxChar ? htmlText.slice(0, maxChar) : htmlText;
		htmlText =
			textId && !htmlText && showMissinTextWarnings
				? `<span style="font-size:10px;color:red">No text for textId:"${textId}" found!</span>`
				: htmlText;
		return (
			<CustomTag
				style={style}
				className={classnames(className, "ff-text")}
				dangerouslySetInnerHTML={{ __html: htmlText }}
				{...rest}
			/>
		);
	}
}

const makeMapStateToProps = () => {
	const getTextByIdSelector = makeGetTextByIdSelector();
	const getValueSelector = makeGetValueSelector();
	const mapStateToProps = (state, props) => {
		const showMissinTextWarnings = getValueSelector(
			state,
			{ ...props, path: "devSettings.showMissinTextWarnings" },
			"config"
		);
		return {
			text: getTextByIdSelector(state, props),
			showMissinTextWarnings: showMissinTextWarnings !== false,
		};
	};
	return mapStateToProps;
};

export default connect(makeMapStateToProps)(FFText);
