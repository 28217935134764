import React from "react";
import classnames from "classnames";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import Text from "../../components/core/Text";

const PageItemEmbeddedVideo = (props) => {
	const { pageItem } = props;
	const { cmtPageItem = {} } = pageItem;
	const className = (props.className || "") + " px-0 px-md-3";
	return (
		<PageItemScrollBase
			{...props}
			className={className}
			showNextButton
			renderFunction={(renderProps) => {
				return (
					<div className="row m-0">
						<Text
							textId={cmtPageItem.header}
							tagName="h2"
							className={classnames(
								"col-sm-12 mb-5 font-weight-bold animated",
								renderProps.viewVisible ? "fadeIn" : "fadeOut"
							)}
						/>
						<Text
							className={classnames(
								"mb-6 col-sm-12 animated",
								renderProps.viewVisible ? "fadeIn delay-250ms" : "fadeOut"
							)}
							tagName="p"
							textId={cmtPageItem.body}
						/>
						<div
							className="w-100 mx-0 mx-md-3 embed-responsive embed-responsive-16by9"
							dangerouslySetInnerHTML={{
								__html:
									decodeURIComponent(cmtPageItem.video_url) ||
									"<h3>Missing embeded link</h3>",
							}}
						/>
					</div>
				);
			}}
		/>
	);
};

export default PageItemEmbeddedVideo;
