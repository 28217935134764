import React, { useEffect, useRef } from "react";

const FFScrollIntoView = ({ children, behavior = "smooth", ...rest }) => {
	const ref = useRef(null);

	useEffect(() => {
		ref.current &&
			ref.current.scrollIntoView({
				behavior,
			});
	}, []);

	return (
		<div ref={ref} {...rest}>
			{children}
		</div>
	);
};

export default FFScrollIntoView;
