import React from "react";

const IconCheck = ({
	className,
	color = "#000000",
	size = "small",
	scale = 1,
	style,
}) => {
	switch (size) {
		case "small":
		case "sm":
			return (
				<svg width="16px" height="18px" viewBox="0 0 16 18" version="1.1">
					<g
						id="Symbols"
						stroke="none"
						strokeWidth="1"
						fill="none"
						fillRule="evenodd"
					>
						<g
							id="components/multiple-choice/elements/checkbox-active-selected"
							transform="translate(-7.000000, -6.000000)"
							fill="#000000"
						>
							<g
								fill={color}
								id="symbols-/-multiple-choice-/-right"
								transform="translate(7.000000, 6.000000)"
							>
								<path
									d="M11.5951058,6.13598061e-12 L15.2727273,1.39696771 L11.083,13.408 L11.0903113,13.4118628 L9.75176657,17.25 L8.31980949e-13,13.54573 L1.33854475,9.70759275 L7.405,12.011 L11.5951058,6.13598061e-12 Z"
									id="Combined-Shape"
								></path>
							</g>
						</g>
					</g>
				</svg>
			);

		case "medium":
		case "md":
			return (
				<svg
					width="44px"
					height="48px"
					className={className}
					style={{ transform: `scale(${scale})`, ...style }}
					viewBox="0 0 44 48"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xlink="http://www.w3.org/1999/xlink"
				>
					<g
						id="Symbols"
						stroke="none"
						strokeWidth="1"
						fill="none"
						fillRule="evenodd"
					>
						<g id="components/dilemma/elements/feedback_good" fill={color}>
							<path
								d="M33.04053,1.20081722e-11 L43.52,3.88721449 L31.583,37.312 L31.6021062,37.319966 L27.787891,48 L1.69109171e-12,37.692466 L3.81421514,27.012432 L21.103,33.425 L33.04053,1.20081722e-11 Z"
								id="Combined-Shape"
							></path>
						</g>
					</g>
				</svg>
			);

		case "large":
		case "lg":
			return (
				<svg
					width="88px"
					height="96px"
					className={className}
					style={{ transform: `scale(${scale})`, ...style }}
					viewBox="0 0 88 96"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xlink="http://www.w3.org/1999/xlink"
				>
					<g
						id="Symbols"
						stroke="none"
						strokeWidth="1"
						fill="none"
						fillRule="evenodd"
					>
						<g
							id="components/dilemma/elements/feedback_good-center"
							transform="translate(-52.000000, 0.000000)"
							fill={color}
						>
							<path
								d="M118.809895,5.13225018e-11 L140,7.77442898 L115.862,74.625 L115.901318,74.6399319 L108.18875,96 L52,75.3849321 L59.7125674,54.024864 L94.671,66.85 L118.809895,5.13225018e-11 Z"
								id="Combined-Shape"
							></path>
						</g>
					</g>
				</svg>
			);

		default:
			return null;
	}
};

export default IconCheck;
