import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

class TabCmt extends React.Component {
	render() {
		const { filemakerPackageId } = this.props;
		const style = { color: "lightgray", fontWeight: "bold" };
		const link = `fmp://biz.funkismultimedia.se/Funkis%20Authoring%20Tool?script=linkTriggeredOpenPackage&param=${filemakerPackageId}`;
		if (!filemakerPackageId) {
			return <h3>No app.item.id found.</h3>;
		}
		return (
			<div className="p-2">
				<div className="ff-button">
					<a style={style} target="_blank" rel="noreferrer" href={link}>
						Open FAT package
					</a>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		filemakerPackageId: _.get(state, "app.item.id"),
	};
};

export default connect(mapStateToProps)(TabCmt);
