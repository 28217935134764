import classNames from "classnames";
import ButtonStyle from "components/Button/Button.module.css";
import Collapsible from "components/Collapsible/Collapsible";
import Divider from "components/Divider/Divider";
import Stack from "components/Stack/Stack";
import { CSSProperties, FC, ReactNode, useCallback } from "react";
import Style from "./Details.module.css";

export type DetailsProps = {
	open: boolean;
	onToggle: (open: boolean) => void;
	dividers?: boolean;
	children: ReactNode;
	expandLabel: ReactNode;
	collapseLabel: ReactNode;
};

const Details: FC<DetailsProps> = ({
	open,
	onToggle,
	children,
	expandLabel,
	collapseLabel,
	dividers = true,
}) => {
	const handleToggleButtonClick = useCallback(() => {
		onToggle(!open);
	}, [open, onToggle]);

	return (
		<div
			className={classNames(
				Style.details,
				open ? Style.expanded : Style.collapsed
			)}
		>
			{dividers ? <Divider weight="regular" /> : undefined}
			<div>
				<button
					className={classNames(ButtonStyle.resetButton, Style.toggleButton)}
					onClick={handleToggleButtonClick}
				>
					<span className={Style.icon} />
					{open ? collapseLabel : expandLabel}
				</button>

				<div>
					<Collapsible open={open}>
						<Stack>
							{/*Empty div in stack to ensure consistent whitespace*/}
							<div />
							{children}
							<div />
						</Stack>
					</Collapsible>
				</div>
			</div>

			{dividers ? <Divider weight="regular" /> : undefined}
		</div>
	);
};

export default Details;
