import React, { useRef, useEffect } from "react";
import { Power2, TweenMax } from "gsap";
import classnames from "classnames";
import { ReduxCollectionSelectors } from "funkis-foundation";
import { connect } from "../../../../utils/reduxUtils";
import { getBootstrapColor } from "../../../../utils/colorUtils";
const { makeGetItemByIdSelector } = ReduxCollectionSelectors;

const ScoreItemComponent = ({
	index,
	show = true,
	showDelay = 0,
	highlight,
	score,
	memberId,
	member,
	baseColor = "primary",
}) => {
	const name = (member && member.user && member.user.firstName) || memberId;
	const border = `1px solid ${getBootstrapColor("light-gray")}`;
	const scoreStyle = { color: getBootstrapColor(baseColor) };
	const ref = useRef(null);

	const resetTween = () => {
		TweenMax.set(ref.current, {
			rotateX: -90,
			opacity: 0,
		});
	};

	useEffect(() => {
		if (show) {
			resetTween();
			TweenMax.to(ref.current, 0.4, {
				delay: showDelay,
				rotateX: 0,
				opacity: 1,
				ease: Power2.easeOut,
				onComplete: () => {
					highlight &&
						TweenMax.to(ref.current, 0.4, {
							opacity: 0.5,
							yoyo: true,
							repeat: -1,
						});
				},
			});
		} else {
			resetTween();
		}
	}, [show]);

	return (
		<div
			ref={ref}
			className={classnames(
				"position-relative d-flex w-100 justify-content-stretch",
				highlight && "font-weight-bold"
			)}
			style={{
				borderBottom: border,
				borderLeft: "none",
				borderRight: "none",
				borderTop: index === 0 ? border : "none",
			}}
		>
			<span className="d-block flex-grow-0 " style={{ width: "32px" }}>{`${
				index + 1
			}.`}</span>
			<span className="d-block flex-grow-0">{name}</span>
			<span
				className="d-block flex-grow-1 text-right font-weight-bold"
				style={scoreStyle}
			>
				{score}
			</span>
		</div>
	);
};

const makeMapStateToProps = () => {
	const getItemByIdSelector = makeGetItemByIdSelector();
	const mapStateToProps = (state, props) => {
		return {
			member: getItemByIdSelector(
				state,
				{ ...props, id: props.memberId },
				"members"
			),
		};
	};
	return mapStateToProps;
};

export default connect(ScoreItemComponent, makeMapStateToProps);
