export const defaultPages = [
	{
		type: "language-menu",
		friendlyId: "select-language",
		name: "Language Menu",
		layer: "back",
		parentPageId: null,
		status: "not_attempted",
		layers: ["back", "mid", "front"],
		className: "language-menu",
		style: {},
		winScrollBehaviour: "persist",
		data: {},
		id: "38ac5c50-2cb8-4793-90ad-d3b124ef53a9-lang-menu-cmt",
		project: "REPLACE_PROJECT_PREFIX",
	},
	{
		type: "intro",
		friendlyId: "welcome-splash",
		name: "Intro",
		layer: "back",
		parentPageId: null,
		status: "not_attempted",
		layers: ["back", "mid", "front"],
		className: "",
		style: {},
		winScrollBehaviour: null,
		data: {},
		id: "6e536162-0663-4618-a23b-21e9a4af4176-cmt",
		project: "REPLACE_PROJECT_PREFIX",
	},
	{
		type: "menu",
		friendlyId: "menu",
		name: "Menu",
		layer: "back",
		parentPageId: null,
		status: "not_attempted",
		layers: ["back", "mid", "front"],
		className: "menu",
		style: {},
		winScrollBehaviour: "persist",
		data: {},
		id: "38ac5c50-2cb8-4793-90ad-d3b124ef53a9-cmt",
		project: "REPLACE_PROJECT_PREFIX",
	},
];

export const workshopPages = [
	{
		type: "select-group-members",
		friendlyId: "select-group-members",
		name: "Select group members",
		layer: "back",
		parentPageId: null,
		status: "not_attempted",
		layers: ["back", "mid", "front"],
		className: "select-group-members",
		style: {},
		winScrollBehaviour: "persist",
		data: {},
		id: "38ac5c50-2cb8-4793-90ad-d3b124ef53a9-select-group-members-cmt",
		project: "REPLACE_PROJECT_PREFIX",
	},
];

const disabledWorkshopPages = [
	{
		type: "group-setup",
		friendlyId: "group-setup",
		name: "Group Setup",
		layer: "back",
		parentPageId: null,
		status: "not_attempted",
		layers: ["back", "mid", "front"],
		className: "group-setup",
		style: {},
		winScrollBehaviour: "persist",
		data: {},
		id: "38ac5c50-2cb8-4793-90ad-d3b124ef53a9-group-setup-cmt",
		project: "REPLACE_PROJECT_PREFIX",
	},
];

export const defaultPageItems = [
	{
		type: "div",
		friendlyId: "chapterMenuButton",
		visible: true,
		enabled: true,
		symbol: false,
		status: "notAttempted",
		proxyType: null,
		layer: "mid",
		style: {},
		className: "chapter-menu-button-container",
		events: ["onIncomplete", "onCompleted", "onEnter", "onExit"],
		size: null,
		data: {},
		mapPropsToFunction: null,
		mapPropsToState: null,
		renderFunction: null,
		name: "Chapter Menu Buttons Container",
		tagName: "div",
		id: "33eb1ddc-f9a4-429c-84c2-127a37a7f60a-cmt",
		parentPageId: "38ac5c50-2cb8-4793-90ad-d3b124ef53a9-cmt",
		parentPageItemId: null,
	},
];

export const defaultSymbols = [
	{
		type: "chapter-button",
		friendlyId: "chapterButton",
		visible: true,
		enabled: true,
		symbol: true,
		status: "notAttempted",
		proxyType: null,
		layer: "mid",
		style: {},
		className: "chapter-button",
		events: ["onIncomplete", "onCompleted", "onEnter", "onExit"],
		size: null,
		data: {},
		mapPropsToFunction: null,
		mapPropsToState: null,
		renderFunction: null,
		name: "Chapter button",
		stopPropagation: false,
		tagName: "div",
		disableUpdateLocation: false,
		pageId: null,
		id: "e5414bf2-fd09-46a4-a74a-761db17b6eff-cmt",
		parentPageId: null,
		parentPageItemId: null,
	},
	{
		type: "page-button",
		friendlyId: "pageButton",
		visible: true,
		enabled: true,
		symbol: true,
		status: "notAttempted",
		proxyType: null,
		layer: "mid",
		style: {},
		className: "page-button",
		events: ["onIncomplete", "onCompleted", "onEnter", "onExit"],
		size: null,
		data: {},
		mapPropsToFunction: null,
		mapPropsToState: null,
		renderFunction: null,
		name: "CMT - Chapter Button",
		stopPropagation: false,
		tagName: "div",
		disableUpdateLocation: false,
		pageId: null,
		id: "a3914cb2-d117-41ae-af3e-08b6be79b0c3-cmt",
		parentPageId: null,
		parentPageItemId: null,
	},
	{
		type: "page-header",
		friendlyId: "cmtChapterTitle",
		visible: true,
		enabled: true,
		symbol: true,
		status: "notAttempted",
		proxyType: null,
		layer: "mid",
		style: {},
		className: "page-header",
		events: ["onIncomplete", "onCompleted", "onEnter", "onExit"],
		size: null,
		data: {},
		mapPropsToFunction: null,
		mapPropsToState: null,
		renderFunction: null,
		name: "Chpater Title",
		tagName: "div",
		id: "69afb44e-e801-45cd-85a2-7b2d71ba5cde-cmt",
		parentPageId: null,
		parentPageItemId: null,
	},
	{
		type: "page-footer",
		friendlyId: "buttonNavigateToMainMenu",
		visible: true,
		enabled: true,
		symbol: true,
		status: "notAttempted",
		proxyType: null,
		layer: "mid",
		style: {},
		className: "page-footer",
		events: ["onIncomplete", "onCompleted", "onEnter", "onExit"],
		size: null,
		data: {},
		mapPropsToState: null,
		renderFunction: null,
		name: "Page ",
		tagName: "div",
		id: "54c9a4d6-7851-4655-9280-14cf8f9bd087-cmt",
		parentPageId: null,
		parentPageItemId: null,
	},
	{
		type: "top-bar",
		friendlyId: "top-bar",
		visible: true,
		enabled: true,
		symbol: true,
		status: "incomplete",
		proxyType: null,
		layer: "mid",
		style: {},
		className: "top-bar",
		events: ["onIncomplete", "onCompleted", "onEnter", "onExit"],
		size: null,
		data: {},
		mapPropsToFunction: null,
		mapPropsToState: null,
		renderFunction: null,
		name: "CMT Top Bar",
		tagName: "div",
		id: "e418b532-13e0-401f-bacb-7286d6674e34-cmt",
		parentPageId: "global-container",
		parentPageItemId: null,
	},
	{
		type: "side-menu",
		friendlyId: "side-menu",
		visible: true,
		enabled: true,
		symbol: false,
		status: "notAttempted",
		proxyType: null,
		layer: "mid",
		style: {},
		className: "side-menu",
		events: ["onIncomplete", "onCompleted", "onEnter", "onExit"],
		size: null,
		data: {},
		mapPropsToFunction: null,
		mapPropsToState: null,
		renderFunction: null,
		name: "Div",
		tagName: "div",
		id: "e5186042-22c7-443a-862e-e370b74e44d7-cmt",
		parentPageId: "global-left-container",
		parentPageItemId: null,
	},
];

export const defaultSchemes = [
	// {
	//   cmtPageType: "chapter",
	//   symbolFriendlyId: "chapterButton",
	//   descr: "",
	//   parentPageItem: "chapterMenuButton",
	//   items: [],
	// },
	// {
	//   cmtPageType: "page",
	//   symbolFriendlyId: "pageButton",
	//   descr: "",
	//   items: [],
	// },
];
