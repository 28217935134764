import React, { useState, useMemo } from "react";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import Backend from "../../components/backend/Backend";
import { FFButton, PageItemActions } from "funkis-foundation";
import IOSprigStory from "../../components/scroll/IOSprigStory";

const PageItemIOSprigStory = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;
	const { io_id } = cmtPageItem;
	const file = cmtPageItem.sst_json_file || cmtPageItem.file;
	// The last paragraph has no interaction (we don't know when the user has completed reading it),
	// so the Sprig is set to "completed" as soon as the last parahraph is shown.
	// When a block status === completed, the default behavior of ScrollBase is to scroll
	// the newly revealed Next-button into view.
	// If the last paragraph is long enough to approach the height of the device (not unthinkable in mobile),
	// this means that the Next-button scrolling into view will scroll past
	// a portion of the last paragraph.
	// Therefore, the default of `scroll_to_final_next_button_when_revealed` is "no"
	const autoScrollOnShowNextButton =
		cmtPageItem.scroll_to_final_next_button_when_revealed === "yes"; // default is "no"
	const ioID = io_id || pageItem.id;
	const [completed, setCompleted] = useState(false);
	const [quidInputId, setQuidInputId] = useState(); // type === "sprigStory"
	const [numSlots, setNumSlots] = useState(0);

	const newPageItem = useMemo(
		() => ({ ...pageItem, autoScrollOnShowNextButton }),
		[autoScrollOnShowNextButton, pageItem]
	);

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={completed}
			pageItem={newPageItem}
		>
			{/* "CREATE NEW SLOT" & "SELECT A SLOT" kanske skulle göras till ett eget block? */}
			{/* CREATE NEW SLOT */}
			<Backend
				{...props}
				className="row m-0 d-none"
				quidType="sprigStory"
				mode="input"
				targetDataKey={ioID}
				renderInput={(renderProps) => {
					const { data, create, save } = renderProps;
					//   console.log("renderProps", renderProps);
					return (
						//   Här borde det vara ett inputfält där man kan skriva i namnet på "save filen"...
						<FFButton
							onClick={() => {
								save(true, {
									name: `slot ${numSlots + 1}`,
									sprigStoryId: ioID,
									selectedSprigs: [],
								});
								setNumSlots(numSlots + 1);
							}}
						>
							<span className="button-labal-sm">Create new save slot</span>
						</FFButton>
					);
				}}
			/>

			{/* SELECT A SLOT */}
			<ul>
				<Backend
					{...props}
					className="d-none"
					quidType="sprigStory"
					mode="outputs"
					targetDataKey={ioID}
					renderOutputs={(renderProps) => {
						const { data, quidInput, index } = renderProps;
						setNumSlots(index + 1);
						return (
							<li>
								<FFButton
									onClick={() => {
										setQuidInputId(quidInput.id);
									}}
								>
									{data.name || data.id}
								</FFButton>
							</li>
						);
					}}
				/>
			</ul>
			{/* SPRIG STORY... */}

			<IOSprigStory
				ioID={ioID}
				paragraphBackgroundColor={
					cmtPageItem.paragraph_background_color || "light-gray"
				}
				exitBackgroundColor={cmtPageItem.exit_background_color || "primary"}
				paragraphTextColor={cmtPageItem.paragraph_text_color || "black"}
				exitTextColor={cmtPageItem.exit_text_color || "black"}
				quidInputId={quidInputId}
				path={`content/json/${file}`}
				loadText={cmtPageItem.load_text === "yes"}
				onComplete={() => {
					setCompleted(true);
					pageItem.status !== "completed" &&
						PageItemActions.updatePageItem(pageItem.id, {
							status: "completed",
						});
				}}
			/>
		</PageItemScrollBase>
	);
};

export default PageItemIOSprigStory;
