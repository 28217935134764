import { createSelector } from "reselect";
import _ from "lodash";

const getValue = (state, props, reducer) =>
	_.get(state[reducer].item, props.key || props.path);

// Create

export const getValueSelector = () =>
	createSelector(getValue, (value) => {
		return value;
	});

// Make

export function makeGetValueSelector() {
	return getValueSelector();
}
