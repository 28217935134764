import React from "react";

import IconStatusNotAttempted from "../../assets/svg/IconStatusNotAttempted";
import IconStatusIncomplete from "../../assets/svg/IconStatusIncomplete";
import IconStatusCompleted from "../../assets/svg/IconStatusCompleted";
import IconPadlock from "../../assets/svg/IconPadlock";
import IconWithLabel from "./IconWithLabel";

const statusIcons = {
	not_attempted: IconPadlock,
	incomplete: IconStatusIncomplete,
	unlocked: IconStatusNotAttempted,
	completed: IconStatusCompleted,
};

const statusTextIds = {
	not_attempted: "A05CB48E-BDBD-49DF-9F54-8A55A798D54B",
	incomplete: "7BE417C0-FBEB-4688-A8BB-7050FA73CAB8",
	completed: "D24115A3-B9A4-4B40-90F1-5E1213241A84",
	unlocked: "A05CB48E-BDBD-49DF-9F54-8A55A798D54B",
};

const StatusIcon = ({ status = "not_attempted", color, ...rest }) => {
	const Icon = statusIcons[status] || React.Fragment;

	return (
		<IconWithLabel
			icon={<Icon color={color} textColor={color} variant="large" />}
			textId={statusTextIds[status]}
			{...rest}
		/>
	);
};

export default StatusIcon;
