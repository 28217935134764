import * as StoreUtils from "../../utils/StoreUtils";
import {
	UPDATE_PAGE_ITEMS,
	UPDATE_PAGE_ITEM,
	REMOVE_PAGE_ITEM,
	REMOVE_PAGE_ITEMS,
	RESET_PAGE_ITEMS,
	ADD_PAGE_ITEM,
	CHANGE_INDEX_FOR_PAGE_ITEM,
	MAKE_PAGE_ITEMS_SNAPSHOT,
	RESTORE_FROM_PAGE_ITEMS_SNAPSHOT,
} from "../constants/ActionConstants";

export function updatePageItems(updates) {
	StoreUtils.getStore().dispatch(_updatePageItems(updates));
}

function _updatePageItems(items) {
	return (dispatch: (action) => void) => {
		dispatch({ items, type: UPDATE_PAGE_ITEMS });
	};
}

export function updatePageItem(id, updates) {
	StoreUtils.getStore().dispatch(
		_updatePageItem(Object.assign(updates, { id }))
	);
}

function _updatePageItem(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_PAGE_ITEM });
	};
}

export function addPageItem(item) {
	StoreUtils.getStore().dispatch(_addPageItem(Object.assign(item)));
}

export function makePageItemsSnapshot(item) {
	StoreUtils.getStore().dispatch(_makePageItemsSnapshot(item));
}

export function restoreFromPageItemsSnapshot(item) {
	StoreUtils.getStore().dispatch(_restoreFromPageItemsSnapshot(item));
}

function _addPageItem(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: ADD_PAGE_ITEM });
	};
}

export function removePageItem(id) {
	StoreUtils.getStore().dispatch(_removePageItem({ id }));
}

function _removePageItem(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_PAGE_ITEM });
	};
}

export function removePageItems(ids) {
	StoreUtils.getStore().dispatch(_removePageItems({ ids }));
}

function _removePageItems(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_PAGE_ITEMS });
	};
}

export function resetPageItems() {
	StoreUtils.getStore().dispatch(_resetPageItems());
}

function _resetPageItems() {
	return (dispatch: (action) => void) => {
		dispatch({ type: RESET_PAGE_ITEMS });
	};
}

export function changeIndexForPageItem(id, toIndex) {
	StoreUtils.getStore().dispatch(_changeIndexForPageItem({ id }, toIndex));
}

function _changeIndexForPageItem(item, toIndex) {
	return (dispatch: (action) => void) => {
		dispatch({ item, toIndex, type: CHANGE_INDEX_FOR_PAGE_ITEM });
	};
}

function _makePageItemsSnapshot(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: MAKE_PAGE_ITEMS_SNAPSHOT });
	};
}

function _restoreFromPageItemsSnapshot(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: RESTORE_FROM_PAGE_ITEMS_SNAPSHOT });
	};
}
