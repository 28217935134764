import _ from "lodash";
import { getReducer } from "funkis-foundation/utils/StoreUtils";
import { PageItemMedia } from "funkis-template/models/player";
import { getBasePath } from "utils/ConfigUtils";

function getImagePath() {
	const config = getReducer("config").item;
	return getBasePath() + config.paths.imagePath;
}

function getAudioPath() {
	const config = getReducer("config").item;
	return getBasePath() + config.paths.audioPath;
}

function getVideoPath() {
	const config = getReducer("config").item;
	return getBasePath() + config.paths.videoPath;
}

function getCurrentLanguage() {
	return _.get(getReducer("config"), "item.language");
}

function getLanguagePath(languageCode) {
	const config = getReducer("config").item;
	return getBasePath() + config.paths.languagePath + languageCode;
}

function getOriginalLanguage() {
	return _.get(getReducer("app"), "item.original_localisation_code");
}

type MediaType = "video" | "audio" | "image" | "unknown";

const fileTypes: Record<string, MediaType> = {
	".mp4": "video",
	".webm": "video",
	".jpg": "image",
	".jpeg": "image",
	".png": "image",
	".svg": "image",
	".gif": "image",
	".webp": "image",
	".mp3": "audio",
};

function getExtension(str: string) {
	return str.slice(str.lastIndexOf("."));
}

export function getMediaType(
	src: PageItemMedia,
	preferredLanguage: string = getCurrentLanguage()
): MediaType {
	if (_.isEmpty(src)) return "unknown";

	const srcString = _.isString(src)
		? src
		: src.localization?.[preferredLanguage] ?? src.src;

	const fileExtension = getExtension(srcString);
	return fileTypes[fileExtension] ?? "unknown";
}

function getMediaPath(mediaType: MediaType): string {
	switch (mediaType) {
		case "video":
			return getVideoPath();

		case "audio":
			return getAudioPath();

		case "image":
			return getImagePath();

		case "unknown":
			return getImagePath();
	}
}

const localizedMediaFolder: Record<MediaType, string> = {
	video: "videos",
	audio: "sounds",
	image: "images",
	unknown: "images",
};

function getLocalizedPath(preferredLanguage: string, mediaType: MediaType) {
	const languagePath = getLanguagePath(preferredLanguage);
	const mediaFolder = localizedMediaFolder[mediaType];
	const fullDirPath = `${languagePath}/${mediaFolder}/`;

	return fullDirPath;
}

function isURL(str: string): boolean {
	return _.includes(str, "://");
}

function isStaticPath(str: string): boolean {
	return _.startsWith(str, "/static/");
}

export function getLocalizedMediaPath(
	mediaStringOrObject: PageItemMedia | undefined,
	preferredLanguage: string = getCurrentLanguage(),
	originalLanguage: string = getOriginalLanguage()
) {
	if (!mediaStringOrObject) {
		return "";
	}

	const mediaType = getMediaType(mediaStringOrObject);

	if (_.isString(mediaStringOrObject)) {
		return isURL(mediaStringOrObject) || isStaticPath(mediaStringOrObject)
			? mediaStringOrObject
			: getMediaPath(mediaType) + mediaStringOrObject;
	}

	const originalLanguageSrc = getMediaPath(mediaType) + mediaStringOrObject.src;

	if (preferredLanguage === originalLanguage) {
		return originalLanguageSrc;
	} else if (mediaStringOrObject.localization) {
		const localizedFileName =
			mediaStringOrObject.localization[preferredLanguage];

		return localizedFileName
			? getLocalizedPath(preferredLanguage, mediaType) + localizedFileName
			: originalLanguageSrc;
	} else {
		return originalLanguageSrc;
	}
}
