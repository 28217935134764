import * as StoreUtils from "../../utils/StoreUtils";
import {
	UPDATE_SUBTITLES,
	UPDATE_SUBTITLE,
	REMOVE_SUBTITLE,
	ADD_SUBTITLE,
	CLEAR_SUBTITLES,
} from "../constants/ActionConstants";

export function updateSubtitles(updates) {
	StoreUtils.getStore().dispatch(_updateSubtitles(updates));
}

function _updateSubtitles(items) {
	return (dispatch: (action) => void) => {
		dispatch({ items, type: UPDATE_SUBTITLES });
	};
}

export function updateSubtitle(id, updates) {
	StoreUtils.getStore().dispatch(
		_updateSubtitle(Object.assign(updates, { id }))
	);
}

function _updateSubtitle(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_SUBTITLE });
	};
}

export function addSubtitle(item) {
	StoreUtils.getStore().dispatch(_addSubtitle(Object.assign(item)));
}

function _addSubtitle(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: ADD_SUBTITLE });
	};
}

export function removeSubtitle(id) {
	StoreUtils.getStore().dispatch(_removeSubtitle({ id }));
}

function _removeSubtitle(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_SUBTITLE });
	};
}

export function clearSubtitles() {
	StoreUtils.getStore().dispatch(_clearSubtitles());
}

function _clearSubtitles(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: CLEAR_SUBTITLES });
	};
}
