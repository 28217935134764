import { asyncForEach } from "../../utils/ArrayUtils";
import { createSymbolPageItemsForPage } from "../utils/SymbolUtils";

export const parseCMTChapters = (cmtParseSchemes, cmtPages) => {
	let pageItems = [];

	const chapterScheme = cmtParseSchemes.find(
		(scheme) => scheme && scheme.cmtPageType === "chapter"
	);
	const pageScheme = cmtParseSchemes.find(
		(scheme) => scheme && scheme.cmtPageType === "page"
	);

	// No schemes for chapter or page found.
	if (!chapterScheme || !pageScheme) {
		return new Promise((resolve) => {
			resolve({ pageItems });
		});
	}

	let cmtChapterPages = cmtPages.filter(
		(cmtPage) =>
			cmtPage.type === "chapter" ||
			(cmtPage.type === "page" && cmtPage.hierarchy_level === 1)
	);

	return asyncForEach(
		cmtChapterPages,
		(cmtChapterPage, index) => {
			// Create chapter menu buttons containers
			let symbolPageItems = [];
			const cmtSecionPages = cmtPages.filter(
				(cmtPage) => cmtPage.parent_id === cmtChapterPage.id
			);
			symbolPageItems = createSymbolPageItemsForPage({
				cmtParseSchemes,
				cmtPage: cmtChapterPage,
			});
			symbolPageItems = mapChapterSymbolPageItems({
				index,
				cmtParseSchemes,
				cmtPage: cmtChapterPage,
				symbolPageItems,
			});
			const rootChapterPageItem = symbolPageItems[0];
			if (rootChapterPageItem) {
				// check needed to avoid crash for CMT posttest
				rootChapterPageItem.cmtPage = cmtChapterPage;
			}
			pageItems = pageItems.concat(symbolPageItems);

			// Create sections buttons for chapters
			cmtSecionPages.forEach((cmtPage) => {
				symbolPageItems = createSymbolPageItemsForPage({
					cmtParseSchemes,
					cmtPage,
				});
				symbolPageItems = mapChapterSymbolPageItems({
					cmtParseSchemes,
					cmtPage,
					symbolPageItems,
				});
				const rootPagePageItem = symbolPageItems[0];
				if (rootChapterPageItem && rootPagePageItem) {
					rootPagePageItem.parentPageItemId = rootChapterPageItem.id;
					rootPagePageItem.pageId = cmtPage.id;
					rootPagePageItem.cmtPage = cmtPage;
				}
				pageItems = pageItems.concat(symbolPageItems);
			});
		},
		() => {
			return { pageItems };
		}
	);
};

// MISC UTILS

const mapChapterSymbolPageItems = ({
	index,
	cmtParseSchemes,
	cmtPage,
	symbolPageItems,
}) => {
	const cmtParseScheme = cmtParseSchemes.find(
		(cmtParseScheme) => cmtParseScheme.cmtPageType === cmtPage.type
	);

	if (!cmtParseScheme) {
		console.warn(`No cmtParseScheme found for cmtPage type ${cmtPage.type}`);
		return;
	}

	// Update keys defined in scheme.items
	cmtParseScheme.items.forEach((item) => {
		const symbolPageItem = symbolPageItems.find(
			(pageItem) => pageItem.friendlyId === item.symbolFriendlyId
		);
		const cmtPageValue = cmtPage[item.cmtKey];
		if (!symbolPageItem) {
			console.warn(
				`No page item found for friendly id "${item.symbolFriendlyId}"`
			);
		} else if (cmtPageValue === "" || cmtPageValue === undefined) {
			console.warn(
				`Value for property ${item.cmtKey} is "${cmtPageValue}" in page "${cmtPage.friendly_id}"`
			);
		} else {
			symbolPageItem[item.symbolKey] = cmtPageValue;
		}
	});

	// Set chapter index
	const chapterSubTitlePageItem = symbolPageItems.find(
		(pageItem) => pageItem.friendlyId === "chapterSubTitle"
	);
	chapterSubTitlePageItem &&
		(chapterSubTitlePageItem.text = `CHAPTER ${index + 1}`);

	return symbolPageItems;
};
