import classnames from "classnames";
import _ from "lodash";
import React, { FC } from "react";
import IconPostIt from "../../../assets/svg/IconPostIt";
import Image from "../../../components/core/Image";
import Text from "../../../components/core/Text";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";
import { QuidInput, QuidType, useBackend } from "../../../hooks/backend";
import { useValueList } from "../../../hooks/valuelist";
import { CategoryContentArray } from "../../../models/contentarray";
import { NoteCategorizeScrollBlock } from "../../../models/pageitem";
import { getBootstrapColor } from "../../../utils/colorUtils";

type CategoryContentArrayItem = CategoryContentArray["values"][number];

const Category: FC<{
	category: CategoryContentArrayItem;
	backgroundColor: string;
	textColor: string;
	postItColor: string;
	notes: Record<string, QuidInput>;
}> = ({ category, backgroundColor, textColor, postItColor, notes }) => {
	const { quidInputs: selections } = useBackend({
		targetDataKey: category.io.id,
		quidType: QuidType.ContentSelection,
		inputContext: category.io.context,
	});

	const postItsForCategory = selections.reduce(
		(acc: QuidInput[], selection) =>
			selection.targetQuidInput && notes[selection.targetQuidInput]
				? [...acc, notes[selection.targetQuidInput]]
				: acc,
		[]
	);

	return (
		<>
			{/* HR */}
			<div className="row m-0">
				<div className="col-12">{/* <hr /> */}</div>
			</div>
			{/* GRID */}
			<div className="row m-0 px-3 align-items-stretch">
				<div
					className="col-6 border border-light-gray py-4"
					style={{ backgroundColor: backgroundColor }}
				>
					<Text
						tagName="p"
						textId="52C0DADF-7DEF-468F-A58E-5E41D5D6C316"
						className="p-sm font-weight-bold w-100"
						style={{ color: textColor }}
					/>
					<Text
						tagName="h3"
						textId={category.category}
						className="font-weight-bold w-100"
						style={{ color: textColor }}
					/>
				</div>
				<div
					className="col-6 border border-light-gray"
					style={{ backgroundColor: backgroundColor }}
				>
					<Image
						src={category.media}
						background
						maxHeight="200px"
						align="center-center"
						size={undefined}
						className={undefined}
						children={undefined}
						autoSize={undefined}
						isZoomable={undefined}
					/>
				</div>
			</div>
			{/* HR */}
			<div className="row m-0">
				<div className="col-12 mb-5">{/* <hr /> */}</div>
			</div>
			{/* POST-ITS */}
			<div className="row m-0">
				{postItsForCategory.map((quidInput) => (
					<div key={quidInput.id} className="col-sm-6 mb-5">
						<IconPostIt
							color={postItColor}
							style={undefined}
							className={undefined}
						/>
						<p className="p-sm">{quidInput.data.body}</p>
					</div>
				))}
			</div>
			{/* HR */}
			<div className="row m-0">
				<div className="col-12 mb-5">{/* <hr /> */}</div>
			</div>
		</>
	);
};

const PageItemIONoteCategorizeOutput: FC<{
	pageItem: { cmtPageItem: NoteCategorizeScrollBlock };
}> = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;

	const contentArray: CategoryContentArray = useValueList(
		cmtPageItem.valuelist_id
	);

	const textColor = getBootstrapColor(cmtPageItem.text_color);
	const backgroundColor = getBootstrapColor(cmtPageItem.background_color);
	const postItBackgroundColor = getBootstrapColor(
		cmtPageItem.note_background_color
	);

	const { quidInputs } = useBackend({
		targetDataKey: cmtPageItem.ios.notes.id,
		quidType: QuidType.PostIt,
		inputContext: cmtPageItem.ios.notes.context,
	});

	const notes: Record<string, QuidInput> = _.keyBy(quidInputs, "id");

	const shouldShowNextButton = cmtPageItem.show_next_button === "yes";

	return (
		<PageItemScrollBase
			{...props}
			containerClassName="w-100"
			showNextButton={shouldShowNextButton}
			renderFunction={(renderProps: { viewVisible: boolean }) => {
				return (
					<div className="container-fluid">
						{/* HEADER */}
						<Text
							className={classnames(
								"col-12 mb-5 font-weight-bold animated",
								renderProps.viewVisible ? "fadeIn" : "fadeOut"
							)}
							tagName="h2"
							textId={cmtPageItem.header}
						/>

						{/* BODY */}
						<Text
							className="col-md-10 mb-4"
							tagName="p"
							textId={cmtPageItem.body}
						/>

						{/* CATEGORIES */}
						{contentArray.values.map((option) => (
							<Category
								key={option.id}
								notes={notes}
								category={option}
								textColor={textColor}
								backgroundColor={backgroundColor}
								postItColor={postItBackgroundColor}
							/>
						))}
					</div>
				);
			}}
		/>
	);
};

export default PageItemIONoteCategorizeOutput;
