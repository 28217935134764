import React, { useEffect, useState, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend, getEmptyImage } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import MultiBackend, {
	TouchTransition,
	usePreview,
} from "react-dnd-multi-backend";
import classnames from "classnames";
import _ from "lodash";
import { FFText, FFButton, PageItemActions } from "funkis-foundation";
import { getLocalizedMediaPath } from "utils/MediaUtils";

import {
	getBootstrapOverridesForPresentValues,
	getBootstrapColor,
} from "../../utils/colorUtils";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import IconCorrectCheckmark from "../../assets/svg/IconCorrectCheckmark";
import IconIncorrectCross from "../../assets/svg/IconIncorrectCross";
import SimpleReveal from "../../components/core/SimpleReveal";
import Text from "../../components/core/Text";
import Image from "../../components/core/Image";
import Style from "./PageItemMatchPairs.module.css";

const { updatePageItem } = PageItemActions;

const props = { pageItem: {}, cmtPageItem: {} };

/* this pattern is taken from https://codesandbox.io/s/dnd-touch-bvgwy  */
const HTML5toTouch = {
	backends: [
		{
			backend: HTML5Backend,
			preview: true, // don't use default
		},
		{
			backend: TouchBackend, // Note that you can call your backends with options
			preview: true,
			transition: TouchTransition,
			// skipDispatchOnTransition: true
		},
	],
};

const ItemPreview = () => {
	const { display, itemType, item, style } = usePreview();
	if (!display) return null;
	const rotate = "rotate(2deg)";
	const newStyle = {
		...style,
		WebkitTransform: style.WebkitTransform + ` ${rotate}`,
		transform: style.transform + ` ${rotate}`,
		...getBootstrapOverridesForPresentValues({
			backgroundColor: item.backgroundColor,
			color: item.textColor,
		}),
	};

	return (
		<div
			className={classnames(
				Style.item,
				Style.itemMoving,
				`index-${item.index}`
			)}
			style={newStyle}
		>
			<Text className="font-weight-bold" textId={item.textId} />
		</div>
	);
};

const ITEM_TYPE = "GENERAL_ITEM";

/* Item */

const Item = ({
	textId,
	matchWithTextId,
	index,
	onDrop,
	onOutsideDrop,
	canDrag,
	shouldHide,
	backgroundColor,
	textColor,
}) => {
	const [{ isDragging }, dragRef, preview] = useDrag({
		type: ITEM_TYPE,
		item: {
			textId,
			matchWithTextId,
			backgroundColor,
			textColor,
			index,
			type: ITEM_TYPE,
		},
		canDrag: () => canDrag,
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult();
			if (item && dropResult) {
				onDrop({ targetId: dropResult.textId, sourceId: item.textId });
			} else if (item && !dropResult) {
				console.log("dropped outside!");
				onOutsideDrop && onOutsideDrop();
			}
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	useEffect(() => {
		// needed to hide default preview on desktop
		preview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	return (
		<div
			className={classnames(
				Style.item,
				isDragging && Style.itemDragging,
				`index-${index}`,
				shouldHide && Style.itemConsumed
			)}
			style={{
				...getBootstrapOverridesForPresentValues({
					backgroundColor: backgroundColor,
					color: textColor,
				}),
			}}
			ref={dragRef}
		>
			<Text className="font-weight-bold p-medium" textId={textId} />
		</div>
	);
};

/* Target */
const Target = ({
	textId,
	backgroundImage,
	index,
	selectedTextId,
	placeholderTextId,
	onItemMove,
	onOutsideDrop,
	isCorrect,
	itemBackgroundColor,
	itemTextColor,
	targetOutlineColor,
}) => {
	const [{ canDrop, isOver }, drop] = useDrop({
		accept: ITEM_TYPE,
		drop: () => ({ textId: textId }),
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	});
	const isActive = canDrop && isOver;
	const style = Object.assign(
		{ outlineColor: getBootstrapColor(targetOutlineColor) },
		backgroundImage && {
			backgroundImage: `url("${getLocalizedMediaPath(backgroundImage)}"`,
			backgroundRepeat: "no-repeat",
			backgroundSize: "auto 90%",
			backgroundPosition: "50% 60%",
		}
	);
	const shouldShowFeedback = isCorrect !== null;
	return (
		<div
			className={classnames(
				Style.target,
				isActive && Style.activeTarget,
				`index-${index}`,
				"col-12",
				"border-sm-0",
				selectedTextId && "has-child-item"
			)}
			ref={drop}
			style={{ ...style }}
		>
			{!shouldShowFeedback ? null : isCorrect ? (
				<IconCorrectCheckmark
					className={classnames(Style.itemFeedbackIcon, "animated flash")}
				/>
			) : (
				<IconIncorrectCross
					className={classnames(Style.itemFeedbackIcon, "animated flash")}
				/>
			)}
			<Text
				className={classnames(
					"drop-target-label-text p-medium mb-2 font-weight-bold",
					backgroundImage ? "d-none" : "mt-2 d-flex"
				)}
				textId={textId}
			/>
			{selectedTextId && (
				<Item
					textColor={itemTextColor}
					backgroundColor={itemBackgroundColor}
					shouldHide={false}
					canDrag={!_.isBoolean(isCorrect)}
					textId={selectedTextId}
					onDrop={({ targetId, sourceId }) =>
						onItemMove({ targetId, sourceId })
					}
					onOutsideDrop={onOutsideDrop}
				/>
			)}
			<Text
				className={classnames(
					Style.targetPlaceholder,
					backgroundImage && Style.targetPlaceholderWithImage,
					"small font-italic",
					selectedTextId ? "opacity-0" : "animated fadeIn"
				)}
				textId={placeholderTextId}
			/>
		</div>
	);
};

const PageItemMatchPairs = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;
	const { content_array, target_outline_color = "#d8d7d5" } = cmtPageItem;

	const itemBackgroundColor = cmtPageItem.item_background_color || "#ACA378";
	const itemTextColor = cmtPageItem.item_text_color || "white";

	// use a ref here just to hold data and make use we dont shuffle each re-render
	const optionsDataRef = useRef(
		_.shuffle(
			content_array.map((item, index) => ({
				textId: item.statement,
				matchWithTextId: item.match_with,
				key: item.statement,
				index,
				backgroundColor: itemBackgroundColor,
				textColor: itemTextColor,
			}))
		)
	);

	const optionsData = optionsDataRef.current;

	const targetsData = content_array.map((item, index) => ({
		textId: item.match_with,
		correctTextId: item.statement,
		key: item.match_with,
		index,
		placeholderTextId: cmtPageItem.drop_area_placeholder,
		backgroundImage: item.match_with_image && item.match_with_image,
		itemBackgroundColor: itemBackgroundColor,
		itemTextColor: itemTextColor,
	}));

	const [selectionsByTargetId, setSelectionsByTargetId] = useState({});

	const [hasConfirmed, setHasConfirmed] = useState(false);

	const showConfirm =
		Object.keys(selectionsByTargetId).length == optionsData.length &&
		Object.keys(selectionsByTargetId).every((key) => selectionsByTargetId[key]);

	const isCorrect = optionsData.every(
		({ textId, matchWithTextId }) =>
			selectionsByTargetId[matchWithTextId] == textId
	);

	/* image related  */

	const imageSize = cmtPageItem.image_size;
	const imageAlign = cmtPageItem.image_align;
	const maxHeight = cmtPageItem.image_max_height;
	const imageMarginsMobile = cmtPageItem.image_margins_mobile !== "no";

	let shouldShowNextButton = false;
	if (cmtPageItem.show_next_button === "always") {
		shouldShowNextButton = true;
	} else if (cmtPageItem.show_next_button === "on-select") {
		shouldShowNextButton = hasConfirmed;
	} else if (cmtPageItem.show_next_button === "on-done") {
		shouldShowNextButton = isCorrect && hasConfirmed;
	} else {
		shouldShowNextButton = pageItem.status === "completed";
	}

	return (
		<PageItemScrollBase
			{...props}
			containerClassName="w-100"
			showNextButton={shouldShowNextButton}
			renderFunction={(renderProps) => {
				return (
					<React.Fragment>
						<div className="w-100">
							{/* IMAGE */}
							{cmtPageItem.image && (
								<div
									className={classnames(
										imageMarginsMobile ? "container-fluid" : ""
									)}
								>
									<div
										className={classnames(
											"row m-0 mb-5 animated",
											renderProps.viewVisible ? "fadeIn" : "fadeOut"
										)}
									>
										<Image
											src={cmtPageItem.image}
											className={classnames(
												imageMarginsMobile ? "col-12" : "w-100 px-sm-0 px-md-5"
											)}
											size={imageSize}
											maxHeight={maxHeight}
											align={imageAlign}
										/>
									</div>
								</div>
							)}

							<div className="container-fluid">
								<div
									className={classnames(
										"row m-0 mb-5 animated",
										renderProps.viewVisible ? "fadeIn" : "fadeOut"
									)}
								>
									{/* QUESTION */}
									<Text
										tagName="h2"
										textId={cmtPageItem.header}
										className="col-sm-10 mb-5 font-weight-bold"
									/>
									{/* INSTRUCTION */}
									<Text
										textId={cmtPageItem.instruction}
										className="col-sm-10 caption"
									/>
								</div>
							</div>
						</div>
						<div
							className={classnames(
								"container-fluid",
								`number-of-options-${optionsData.length}`
							)}
						>
							<div className={classnames(Style.root, "col-12")}>
								<DndProvider backend={MultiBackend} options={HTML5toTouch}>
									<ItemPreview />
									<div className="d-flex">
										<div
											className={classnames(Style.itemsContainer, "ml-5 mb-4")}
										>
											{optionsData.map((option, index) => {
												const consumed = _.values(selectionsByTargetId).find(
													(selection) => selection === option.textId
												);
												const left = (index % 2 == 0 ? 1 : -1) * 30;
												return (
													<div
														key={index}
														style={{
															position: "absolute",
															top: 0 + index * 60,
															left: left,
														}}
													>
														<Item
															{...option}
															shouldHide={Object.values(
																selectionsByTargetId
															).includes(option.textId)}
															onDrop={({ targetId }) =>
																setSelectionsByTargetId({
																	...selectionsByTargetId,
																	[targetId]: option.textId,
																})
															}
															canDrag={!consumed}
														/>
													</div>
												);
											})}
										</div>
									</div>

									<div
										className={classnames(
											"row m-0",
											Style.targetsContainer,
											hasConfirmed && Style.targetsShowCorrect
										)}
									>
										{targetsData.map((item) => {
											const selectedText = selectionsByTargetId[item.textId];
											const isCorrect = !hasConfirmed
												? null
												: selectedText === item.correctTextId;
											return (
												<Target
													{...item}
													targetOutlineColor={target_outline_color}
													isCorrect={isCorrect}
													selectedTextId={selectionsByTargetId[item.textId]}
													onItemMove={({ targetId, sourceId }) => {
														const hasMoved =
															targetId && targetId !== item.textId;
														hasMoved &&
															setSelectionsByTargetId({
																...selectionsByTargetId,
																[item.textId]: null,
																[targetId]: sourceId,
															});
													}}
													onOutsideDrop={() => {
														setSelectionsByTargetId({
															...selectionsByTargetId,
															[item.textId]: null,
														});
													}}
												/>
											);
										})}
									</div>
								</DndProvider>

								<div className="flex-column align-items-center">
									{/* FEEDBACK */}
									<SimpleReveal
										reveal={hasConfirmed}
										className={classnames(
											"row animated justify-content-start mx-0 mb-2 pb-2",
											renderProps.viewVisible
												? "delay-1500ms fadeInUp"
												: "fadeOut"
										)}
									>
										<FFText
											className={classnames(
												"p-0 col-sm-10 mt-5 bread text-dark-gray font-weight-bold",
												hasConfirmed ? "animated fadeInUp" : "opacity-0"
											)}
											tagName="p"
											textId={
												isCorrect
													? cmtPageItem.feedback_correct
													: cmtPageItem.feedback_incorrect
											}
										/>
									</SimpleReveal>

									{/*  done/confirm button  */}
									<SimpleReveal
										reveal={showConfirm}
										className={classnames(
											"row animated justify-content-center mx-0 mt-5 mb-2 pb-2",
											showConfirm ? "fadeInUp" : "fadeOut",
											hasConfirmed && "d-none"
										)}
									>
										<FFButton
											className={classnames(
												"btn btn-sm btn-black rounded-pill col-sm-4"
											)}
											enabled={!hasConfirmed}
											onClick={() => {
												setHasConfirmed(true);
												updatePageItem(pageItem.id, {
													status: "completed",
												});
											}}
										>
											<FFText
												textId={"D24115A3-B9A4-4B40-90F1-5E1213241A84"}
												className="text-uppercase font-weight-bold"
											/>
										</FFButton>
									</SimpleReveal>
									{/* reset / retry  */}
									<SimpleReveal
										reveal={hasConfirmed && !isCorrect}
										className={classnames(
											"row animated justify-content-center mx-0 mb-1 pb-1",
											hasConfirmed ? "delay-1500ms fadeInUp" : "fadeOut",
											hasConfirmed && isCorrect && "d-none"
										)}
									>
										<FFButton
											className={classnames(
												"mt-0 btn btn-sm btn-black rounded-pill col-sm-4"
											)}
											onClick={() => {
												setSelectionsByTargetId({});
												setHasConfirmed(false);
											}}
										>
											<FFText
												// placeholder="global textId 6B05B9EA-1A52-4729-A2CA-22E73ACDFF10"
												textId={"6B05B9EA-1A52-4729-A2CA-22E73ACDFF10"}
												className="text-uppercase font-weight-bold"
											/>
										</FFButton>
									</SimpleReveal>
								</div>
							</div>
						</div>
					</React.Fragment>
				);
			}}
		/>
	);
};

export default PageItemMatchPairs;
