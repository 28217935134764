import { getReducer } from "./StoreUtils";

export function getCode() {
	const quiddityReducer = getReducer("quiddity");
	if (!quiddityReducer) {
		return;
	}

	const quiddity = quiddityReducer.item;
	const { session, user, userLoggedIn, userCode } = quiddity;

	if (user && userLoggedIn && userCode) {
		// If logged in with user using ?st=true
		return userCode;
	}

	return session && session.code;
}
