import classnames from "classnames";
import LanguageMenu from "funkis-template/components/core/LanguageMenu";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { LanguageSelectScrollBlock } from "funkis-template/models/pageitem";
import { PageItem } from "funkis-template/models/player";
import React, { FC } from "react";

const LanguageSelect: FC<{ pageItem: PageItem<LanguageSelectScrollBlock> }> = (
	props
) => {
	const { cmtPageItem } = props.pageItem;

	return (
		<PageItemScrollBase
			{...props}
			containerClassName="w-100"
			renderFunction={() => {
				return (
					<div className="container-fluid">
						<div
							className={classnames("row m-0", {
								"justify-content-start": cmtPageItem.align === "Align left",
								"justify-content-center": cmtPageItem.align === "Align center",
								"justify-content-end": cmtPageItem.align === "Align right",
							})}
						>
							<LanguageMenu
								className={classnames(
									"page-item-language-select col-sm-12",
									cmtPageItem.align === "Full width" ? "col-md-12" : "col-md-4"
								)}
							/>
						</div>
					</div>
				);
			}}
		/>
	);
};

export default LanguageSelect;
