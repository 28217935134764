import React from "react";

const IconCheckIncorrect = ({ className, color = "#000000", style }) => {
	return (
		<svg
			className={className}
			style={style}
			width="19px"
			height="16px"
			viewBox="0 0 19 19"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsxlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="components/multiple-choice/elements/feedback_wrong" fill={color}>
					<path
						d="M15.12,-3.55271368e-15 L18.6555339,3.53553391 L12.863,9.328 L18.6555339,15.12 L15.12,18.6555339 L9.327,12.863 L3.53553391,18.6555339 L2.35034214e-12,15.12 L5.792,9.327 L6.75015599e-14,3.53553391 L3.53553391,3.6628478e-12 L9.327,5.792 L15.12,-3.55271368e-15 Z"
						id="Combined-Shape"
					></path>
				</g>
			</g>
		</svg>
	);
};

export default IconCheckIncorrect;
