import React from "react";
import Styles from "./ActionPriorityMatrix.module.css";
import Image from "../core/Image";

const ActionPriorityMatrix = ({ children }) => {
	return (
		<div className={Styles.root}>
			<Image src={"priority-matrix.svg"} className={Styles.image}></Image>
			<div className={Styles.children}>{children}</div>
		</div>
	);
};

export default ActionPriorityMatrix;
