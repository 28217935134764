import Divider from "components/Divider/Divider";
import Media, {
	MediaCrop,
	MediaFit,
} from "funkis-template/components/core/Media";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { getBootstrapColor } from "funkis-template/utils/colorUtils";

const PageItemDivider = (props) => {
	const {
		pageItem: { cmtPageItem },
	} = props;

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={false}
			showDivider={false}
			revealEnabled={false}
			style={{
				"--divider-height":
					cmtPageItem.thickness > 0 ? `${cmtPageItem.thickness}px` : "8px",
				"--divider-regular-color":
					cmtPageItem.color !== ""
						? getBootstrapColor(cmtPageItem.color)
						: "var(--fp-color-block-divider, black)",
			}}
			className="container-fluid"
			renderFunction={(renderProps) => (
				<div className="row m-0">
					<div className="col-sm-12">
						{cmtPageItem.image ? (
							<Media
								src={cmtPageItem.image}
								mediaFit={MediaFit.FullWidth}
								mediaCrop={MediaCrop.ResizeToFit}
							/>
						) : (
							<Divider weight="regular" />
						)}
					</div>
				</div>
			)}
		/>
	);
};

export default PageItemDivider;
