import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { makeGetPageIdsByParentPageId } from "../../../selectors/PageSelectors";
import PageListItem from "./PageListItem";

class PageListItems extends React.Component {
	static propTypes = {
		parentPageId: PropTypes.string,
		filters: PropTypes.arrayOf(
			PropTypes.shape({
				path: PropTypes.string.isRequired,
				value: PropTypes.number.isRequired,
			})
		),
		renderFunction: PropTypes.func,
	};

	render() {
		const { ids, page, filters, className, renderFunction } = this.props;
		return (
			<ul className={className}>
				{ids.map((id) => (
					<PageListItem
						key={`PageListItem-${id}`}
						id={id}
						filters={filters}
						renderFunction={renderFunction}
					/>
				))}
			</ul>
		);
	}
}

const makeMapStateToProps = () => {
	const getPageIdsByParentPageId = makeGetPageIdsByParentPageId();
	const mapStateToProps = (state, props) => {
		return {
			ids: getPageIdsByParentPageId(state, props),
		};
	};
	return mapStateToProps;
};

export default connect(makeMapStateToProps)(PageListItems);
