import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getBootstrapColor } from "../../../../utils/colorUtils";
import classNames from "classnames";

const Header = styled.span`
	font-size: 28px;
	letter-spacing: 0;
	font-weight: bold;
	text-align: left;
`;

const Body = styled.span`
	font-weight: bold;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 18px;
	text-align: left;
`;

const Button = styled.button`
	position: relative;
	overflow: ${({ selected }) => (selected ? "visible" : "hidden")};
	display: flex;
	flex-direction: column;
	border: 0;
	background-color: transparent;
	padding: 0;
	padding-top: 0.6rem;
	padding-bottom: 1.5rem;
	width: 100%;

	/* stop color from being button default (blue on iOS) */
	color: inherit;

	:disabled {
		color: inherit;
	}
	:focus {
		color: ${() => getBootstrapColor("interaction")};
	}
	:hover:not(:disabled) {
		color: ${() => getBootstrapColor("interaction")};
	}
	.selected:disabled {
		color: ${() => getBootstrapColor("interaction")};
	}
`;

const SelectionItem = ({
	selected,
	index,
	unselected,
	indexIndicator,
	body,
	selectionMarker,
	className,
	...rest
}) => {
	const refH = useRef(null);
	const refButton = useRef(null);

	return (
		<Button
			ref={refButton}
			tabIndex={0}
			type="button"
			selected={selected}
			unselected={unselected}
			className={classNames(
				"selection-sprig__selection-item",
				className,
				selected && "selected"
			)}
			disabled={selected || unselected}
			index={index}
			{...rest}
		>
			<Header className="selection-sprig__selection-item-header" ref={refH}>
				{indexIndicator}
			</Header>
			<Body className="selection-sprig__selection-item-body">{body}</Body>
			{selectionMarker && selectionMarker({ selected })}
		</Button>
	);
};

SelectionItem.propTypes = {
	index: PropTypes.number,
	indexIndicator: PropTypes.string,
	body: PropTypes.string,
	selected: PropTypes.bool,
	selectionMarker: PropTypes.func,
};

SelectionItem.defaultProps = {
	selected: false,
};

export default SelectionItem;
