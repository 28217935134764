import FileDropArea from "./FileDropArea/FileDropArea";
import MissingIOError from "errors/MissingIOError";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { QuidType, useBackend } from "funkis-template/hooks/backend";
import { createMediaUploadQuidInput } from "funkis-template/hooks/backend/backendQuiddity";
import { MediaUploadScrollBlock } from "funkis-template/models/pageitem";
import { PageItem as PageItemModel } from "funkis-template/models/player";
import { QuidInputActions } from "quiddity";
import { FC, useCallback, useState } from "react";
import Stack from "components/Stack/Stack";
import Heading from "components/Heading/Heading";
import Text from "funkis-template/components/core/Text";
import PageItem, { Header } from "funkis-template/pageitems/scroll/PageItem";

const PageItemMediaUpload: FC<{
	pageItem: PageItemModel<MediaUploadScrollBlock>;
}> = (props) => {
	const {
		pageItem: { cmtPageItem },
	} = props;

	if (!cmtPageItem.ios.media)
		throw new MissingIOError("media", cmtPageItem.symbol_name);

	const [isLoading, setIsLoading] = useState(false);
	const { quidInputs, deleteQuidInput } = useBackend({
		targetDataKey: cmtPageItem.ios.media.id,
		inputContext: cmtPageItem.ios.media.context,
		quidType: QuidType.MediaUpload,
	});

	const handleDropAreaChange = useCallback(
		async (file: File) => {
			setIsLoading(true);

			await Promise.all(
				quidInputs.map((quidInput) => deleteQuidInput(quidInput))
			);

			const quidInput = await createMediaUploadQuidInput(
				file,
				cmtPageItem.ios.media.context,
				{
					targetDataKey: cmtPageItem.ios.media.id,
				}
			);

			QuidInputActions.updateQuidInputs([quidInput]);
			setIsLoading(false);
		},
		[
			cmtPageItem.ios.media.id,
			cmtPageItem.ios.media.context,
			quidInputs,
			deleteQuidInput,
		]
	);

	const handleDropAreaDelete = useCallback(async () => {
		setIsLoading(true);
		await Promise.all(
			quidInputs.map((quidInput) => deleteQuidInput(quidInput))
		);

		setIsLoading(false);
	}, [quidInputs, deleteQuidInput]);

	const shouldShowNextButton =
		cmtPageItem.show_next_button === "always" ||
		(cmtPageItem.show_next_button === "auto" && quidInputs.length > 0);

	return (
		<PageItemScrollBase
			{...props}
			containerClassName="container-fluid row m-0"
			showNextButton={shouldShowNextButton}
			renderFunction={(renderProps) => (
				<div className="col-sm-12">
					<PageItem>
						<Header heading={cmtPageItem.header} body={cmtPageItem.body} />

						<FileDropArea
							quidInputs={quidInputs}
							onChange={handleDropAreaChange}
							onDelete={handleDropAreaDelete}
							loading={isLoading}
						/>
					</PageItem>
				</div>
			)}
		/>
	);
};

export default PageItemMediaUpload;
