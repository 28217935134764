import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import FocusTrap from "focus-trap-react";
import { FFButton, Actions } from "funkis-foundation";
import Styles from "./LightBox.module.css";
import { Portal } from "../core/Portal";
import IconClose from "../../assets/svg/IconClose";
import Hover from "../core/Hover";
import { getBootstrapColor } from "../../utils/colorUtils";
import _ from "lodash";

/*
  Adds a "useless" query parameter that doesn't change the route, but that still counts as a navigation
  This way, the browser "back" button goes back to the hash without the useless query parameter and we can run
  our function "onHashChangeBack" to close a lightbox etc.

  #my-route -> (open light box) -> #my-route?xyz=0.123 -> (click browser back) -> #my-route (trigger callback to close lightbox)
*/
const useHashChangeIntercept = ({ onHashChangeBack, skip, reset }) => {
	const oldHashRef = useRef(null);
	useEffect(() => {
		if (!skip) {
			const oldHash = window.location.hash;
			oldHashRef.current = oldHash;
			const alreadyHasQueryValues = oldHash.includes("?");
			const newQueryValue =
				oldHash + `${alreadyHasQueryValues ? "&" : "?"}noopNav=true`;
			window.location.hash = newQueryValue;
			setTimeout(() => {
				window.addEventListener("hashchange", onHashChangeBack);
			}, 100);
			return () => {
				window.removeEventListener("hashchange", onHashChangeBack);
			};
		}
	}, [skip]);
	const resetUrl = () =>
		oldHashRef.current && (window.location.hash = oldHashRef.current);
	useEffect(() => {
		reset && resetUrl();
	}, [reset]);
};

const LightBox = (props) => {
	const {
		children,
		show,
		onCloseClick = _.noop,
		onOuterContainerClick = _.noop,
		className,
		classNameInnerContainer,
		classNameContentContainer,
		classNameCloseButton,
		classNameOuterBackground = "bg-dark-80",
		classNameInnerBackground = "bg-white",
		styleOuterContainer = {},
		skipUrlChange = false, // set this to true when LightBox may be triggered before a location change has affected the URL, meaning a hash change could be added on the PREVIOUS url instead of the current, possibly triggering a re-render on THAT page
	} = props;

	useEffect(() => {
		show && Actions.addClassNamesToBody("overflow-hidden");
		return () => {
			Actions.removeClassNamesFromBody("overflow-hidden");
		};
	}, [show]);

	const skip = !show || skipUrlChange;
	useHashChangeIntercept({
		onHashChangeBack: onCloseClick,
		skip: skip,
		reset: !show,
	});

	if (!show) {
		return <div />;
	}

	return (
		<Portal>
			<div
				style={styleOuterContainer}
				onClick={(e) => {
					// We don't want propagated clicks from the child containers to trigger callbacks.
					// Only clicks on this outer container should trigger callbacks.
					if (e.target === e.currentTarget) {
						onOuterContainerClick();
						onCloseClick();
					}
				}}
				className={classnames(
					"outer-container animated fadeIn position-absolute w-100 h-100 p-2",
					classNameOuterBackground,
					Styles.outerContainer,
					className
				)}
			>
				<FocusTrap
					focusTrapOptions={{
						allowOutsideClick: true,
						escapeDeactivates:
							function deactivateAndCloseLightBoxOnEscapeClick() {
								onCloseClick();
								return true;
							},
						initialFocus: false,
					}}
				>
					<div
						role="dialog"
						aria-modal="true"
						className={classnames(
							"inner-container position-relative w-100  d-flex flex-column",
							classNameInnerBackground,
							Styles.innerContainer,
							classNameInnerContainer
						)}
					>
						<FFButton
							tagName="button"
							className={classnames(
								"",
								Styles.closeButton,
								classNameCloseButton
							)}
							onClick={onCloseClick}
						>
							<Hover>
								{({ hover }) => (
									<IconClose
										crossFillColor={
											!hover ? "#000" : getBootstrapColor("interaction")
										}
										color="transparent"
									/>
								)}
							</Hover>
						</FFButton>
						<div
							className={classnames(
								"content-container position-relative d-flex flex-column pb-5 p-4",
								Styles.contentContainer,
								classNameContentContainer
							)}
						>
							{children}
						</div>
					</div>
				</FocusTrap>
			</div>
		</Portal>
	);
};

export default LightBox;
