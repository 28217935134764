import { QuidInput } from "funkis-template/hooks/backend";
import { CommentInputSingleSlideBlock } from "funkis-template/models/pageitem";

export function isQuidInputValidComment(
	quidInput: Pick<QuidInput, "data"> | undefined,
	cmtPageItem: Pick<
		CommentInputSingleSlideBlock,
		"rows_input_header" | "rows_input_body"
	>
): boolean {
	return (
		quidInput !== undefined &&
		(cmtPageItem.rows_input_header > 0
			? Boolean(quidInput.data.header && quidInput.data.header !== "")
			: true) &&
		(cmtPageItem.rows_input_body > 0
			? Boolean(quidInput.data.body && quidInput.data.body !== "")
			: true)
	);
}
