import { KnowledgeQuizAnswersModel } from "./KnowledgeQuiz";
import { KnowledgeQuizQuestionModel } from "./KnowledgeQuizQuestion";

export function getCorrectlyAnsweredQuestions(
	questions: KnowledgeQuizQuestionModel[],
	answers: KnowledgeQuizAnswersModel
) {
	const correctlyAnsweredQuestions = questions.filter((q) => {
		const answerValue = answers[q.id];
		const answeredOption = q.options.find((o) => o.id === answerValue);

		return answeredOption && answeredOption.correct;
	});

	return correctlyAnsweredQuestions;
}
