import React from "react";

const IconArrow = (props) => {
	const { type = "triangle" } = props;
	switch (type) {
		case "triangle":
			return <IconArrowTriangle {...props} />;
		case "lines":
			return <IconArrowLines {...props} />;
	}
};

const IconArrowTriangle = ({ color = "#FFFFFF", style, className }) => {
	return (
		<svg
			width="30px"
			height="21px"
			style={style}
			className={className}
			viewBox="0 0 30 21"
			version="1.1"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="menu-/-menu-item-open"
					transform="translate(-24.000000, -214.000000)"
				>
					<rect
						id="Rectangle"
						fill="none"
						opacity="0.596749442"
						x="0"
						y="0"
						width="1140"
						height="260"
					></rect>
					<polygon
						id="Rectangle"
						fill={color}
						transform="translate(39.000000, 224.500000) rotate(-270.000000) translate(-39.000000, -224.500000) "
						points="29 210 49 224.5 29 239"
					></polygon>
				</g>
			</g>
		</svg>
	);
};

const IconArrowLines = ({ color = "#FFFFFF", style, className }) => {
	return (
		<svg
			width="91px"
			height="77px"
			style={style}
			className={className}
			viewBox="0 0 91 77"
			version="1.1"
		>
			<defs>
				<rect
					id="path-1"
					x="0"
					y="-5.68434189e-14"
					width="1920"
					height="156"
				></rect>
			</defs>
			<g
				id="Blocks-Result"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="RESULT-PAGE" transform="translate(-49.000000, -39.000000)">
					<polygon
						id="Fill-1"
						fill={color}
						transform="translate(94.595547, 77.069232) scale(-1, 1) translate(-94.595547, -77.069232) "
						points="101.247334 39.0692318 93.3682103 46.8760942 118.26666 71.5482473 49.5955469 71.5482473 49.5955469 82.5895668 118.26666 82.5895668 93.3682103 107.26172 101.247334 115.069232 139.595547 77.068907"
					></polygon>
				</g>
			</g>
		</svg>
	);
};

export default IconArrow;
