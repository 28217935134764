import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

// The horizontal lines that are used in slide blocks to
// frame the interactive content. We use an hardcoded height
// to calculate the correct padding for the scroll wrapper.
// TODO: Replace with css variable value.
const HORIZONTAL_LINE_HEIGHT = "14px";

const OuterWrapper = styled.div`
	position: relative;
	height: calc(100% - 1rem - ${HORIZONTAL_LINE_HEIGHT});
	pointer-events: none;
`;

const InnerWrapper = styled.div`
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	pointer-events: auto;
`;

type OverlayProps = { opacity: number };
const Overlay = styled.div<OverlayProps>`
	position: absolute;
	width: 100%;
	opacity: ${(props) => props.opacity};
	transition: opacity 0.2s;
	background-blend-mode: multiply;
`;

const TopOverlay = styled(Overlay)`
	top: -2px;
	height: 24px;
	background-image: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0) 48%,
		rgba(0, 0, 0, 0.06) 73%,
		rgba(0, 0, 0, 0.1) 83%,
		rgba(0, 0, 0, 0.18) 100%
	);
`;

enum ScrollStatus {
	NotScrollable,
	ScrolledToStart,
	InBetween,
	ScrolledToEnd,
}

const calculateScollStatus = (el: HTMLElement): ScrollStatus => {
	const hasScrollableContent = el.scrollHeight > el.clientHeight;
	if (!hasScrollableContent) {
		return ScrollStatus.NotScrollable;
	} else {
		if (el.scrollTop === 0) {
			return ScrollStatus.ScrolledToStart;
		} else if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
			return ScrollStatus.ScrolledToEnd;
		} else {
			return ScrollStatus.InBetween;
		}
	}
};

const BottomOverlay = styled(Overlay)`
	bottom: 0;
	height: 24px;
	background-image: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0) 48%,
		rgba(0, 0, 0, 0.06) 73%,
		rgba(0, 0, 0, 0.1) 83%,
		rgba(0, 0, 0, 0.18) 100%
	);
`;

const Scroll: React.FC<{ className?: string }> = (props) => {
	const [currentScrollStatus, setCurrentScrollStatus] = useState(
		ScrollStatus.ScrolledToStart
	);
	const scrollRef = useRef<HTMLDivElement>(null);

	const scrollHeight = scrollRef?.current?.scrollHeight;
	const clientHeight = scrollRef?.current?.clientHeight;

	useEffect(() => {
		if (scrollRef.current) {
			const nextScrollStatus = calculateScollStatus(scrollRef.current);
			setCurrentScrollStatus(nextScrollStatus);
		}
	}, [scrollHeight, clientHeight]);

	const handleScroll = useCallback((e) => {
		const nextScrollStatus = calculateScollStatus(e.target);
		setCurrentScrollStatus(nextScrollStatus);
	}, []);

	const shouldShowTopIndicator =
		currentScrollStatus !== ScrollStatus.NotScrollable &&
		currentScrollStatus !== ScrollStatus.ScrolledToStart;
	const shouldShowBottomIndicator =
		currentScrollStatus !== ScrollStatus.NotScrollable &&
		currentScrollStatus !== ScrollStatus.ScrolledToEnd;

	return (
		<OuterWrapper className={props.className}>
			<InnerWrapper ref={scrollRef} onScroll={handleScroll}>
				{props.children}
			</InnerWrapper>
			<TopOverlay opacity={shouldShowTopIndicator ? 0.5 : 0} />
			<BottomOverlay opacity={shouldShowBottomIndicator ? 0.5 : 0} />
		</OuterWrapper>
	);
};

export default Scroll;
