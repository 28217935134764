import classNames from "classnames";
import { FC } from "react";
import Style from "./Badge.module.css";

export type BadgeTone =
	| "caution"
	| "critical"
	| "info"
	| "neutral"
	| "positive"
	| "promote";

export type BadgeProps = {
	tone?: BadgeTone;
	weight?: "regular" | "strong";
	title?: string;
};

const Badge: FC<BadgeProps> = ({
	tone = "info",
	weight = "regular",
	title,
	children,
}) => {
	const className = classNames(Style.badge, Style[tone], Style[weight]);
	return <span className={className}>{children}</span>;
};

export default Badge;
