import classnames from "classnames";
import _ from "lodash";
import { useEffect } from "react";
import DefaultButton from "../components/scroll/DefaultButton";
import Page from "../components/scroll/Page";

import {
	Actions as FoundationActions,
	FFImage,
	FFText,
	LocationUtils,
	PagesUtils,
	StoreUtils,
} from "funkis-foundation";
import { useSelector } from "react-redux";
import { getLocalizedMediaPath } from "utils/MediaUtils";
import { updateLocation } from "../actions/Actions";
import LanguageMenu from "../components/core/LanguageMenu";
import bgQuiddity from "assets/images/bg_quiddity.jpg";

const { convertLocationPathToLocation } = LocationUtils;
const { getLocationPathForPage } = PagesUtils;

const getCustomIntroPage = () => {
	const pages = StoreUtils.getReducer("pages").items;
	return pages.find((page) => page.friendlyId === "custom-welcome-splash");
};

const navigateToPageByPageObject = (pageObj) => {
	const pages = StoreUtils.getReducer("pages").items;
	const locationPath = getLocationPathForPage(pages, pageObj);
	const location = convertLocationPathToLocation(locationPath);
	FoundationActions.updateLocation(location);
};

const PageLanguageMenu = (props) => {
	const { page } = props;

	useEffect(() => {
		const config = StoreUtils.getReducer("config").item;
		const app = StoreUtils.getReducer("app").item;

		const languageMenuSkip:
			| "Always skip"
			| "Show if languages exist"
			| undefined = app.language_menu_skip;

		if (languageMenuSkip === "Always skip") {
			goToNextPage();
		} else if (config.installedLanguages.length < 2) {
			goToNextPage();
		}
	}, []);

	const programType = useSelector((state) =>
		_.get(state, "app.item.program_type")
	);
	const backend = useSelector((state) => _.get(state, "app.item.backend"));
	const bgImage =
		backend === "quiddity" && programType === "slide" && bgQuiddity;

	const goToNextPage = () => {
		const customIntroPage = getCustomIntroPage();
		if (customIntroPage && customIntroPage.enabled) {
			navigateToPageByPageObject(customIntroPage);
		} else {
			updateLocation({
				pageId: page.id,
				path: [{ sibling: 1 }],
			});
		}
	};

	return (
		<Page
			{...props}
			className={classnames(
				"d-flex flex-column justify-content-stretch absolute-top w-100 h-100",
				programType === "slide" && "bg-black"
			)}
			style={{ padding: programType === "slide" ? "0 118px" : "0 32px" }}
		>
			{bgImage && (
				<FFImage
					className="background-image"
					width="100%"
					height="100%"
					backgroundImage
					src={getLocalizedMediaPath(bgImage)}
					style={{ position: "fixed", top: 0, left: 0, zIndex: -1 }}
				/>
			)}
			<LanguageMenu
				className={classnames(
					"flex-grow-1 w-100 d-flex flex-column justify-content-end"
				)}
				classNameHeader="w-100 text-white font-weight-bold mb-3"
			/>

			<div
				className={classnames(
					"flex-grow-1 w-100 d-flex flex-column align-items-center",
					programType === "slide"
						? "justify-content-end pb-4"
						: "justify-content-center"
				)}
			>
				<DefaultButton
					enabled
					color="white"
					onClick={() => {
						const customIntroPage = getCustomIntroPage();
						// Navigate to custom intro page if found and enabled
						if (customIntroPage && customIntroPage.enabled) {
							navigateToPageByPageObject(customIntroPage);
						} else {
							goToNextPage();
						}
					}}
					className={classnames("text-uppercase w-100")}
					labelTextId={undefined}
					style={undefined}
				>
					<FFText
						className={classnames(`button-label-sm font-weight-bold`)}
						textId={"FAA5BD0C-8239-4FAF-8301-BCDC32ED8654"}
					/>
				</DefaultButton>
			</div>
		</Page>
	);
};

export default PageLanguageMenu;
