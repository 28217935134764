import React from "react";

const IconPadLock = ({
	className,
	style,
	color = "#00005A",
	secondaryColor = "#BABABA",
	variant = "normal",
}) => {
	if (variant === "normal") {
		return (
			<svg
				className={className}
				style={style}
				width="24px"
				height="24px"
				viewBox="0 0 24 24"
				version="1.1"
			>
				<g
					id="Symbols"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
				>
					<g id="Group-7">
						<path
							d="M12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24"
							id="Fill-1"
							fill={color}
						></path>

						<g id="Clip-6"></g>
						<path
							d="M8.9414,15.8286 L8.9414,8.2196 C8.9414,6.9996 10.3104,6.0116 12.0004,6.0116 C13.6894,6.0116 15.0584,6.9996 15.0584,8.2196 L15.0584,15.8286"
							id="Stroke-3"
							stroke="#FFFFFF"
							strokeWidth="2"
						></path>
						<polygon
							id="Fill-5"
							fill="#FFFFFF"
							points="6.7 18.633 17.3 18.633 17.3 11.818 6.7 11.818"
						></polygon>
					</g>
				</g>
			</svg>
		);
	}
	if (variant === "large") {
		return (
			<svg
				className={className}
				style={style}
				width="30px"
				height="30px"
				viewBox="0 0 30 30"
				version="1.1"
			>
				<defs>
					<polygon
						id="path-14a0vvhiun-1"
						points="0 30 30 30 30 0 0 0"
					></polygon>
				</defs>
				<g
					id="Symbols"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
				>
					<g id="Group-7">
						<path
							d="M15,30 C23.28375,30 30,23.28375 30,15 C30,6.71625 23.28375,0 15,0 C6.71625,0 0,6.71625 0,15 C0,23.28375 6.71625,30 15,30"
							id="Fill-1"
							fill={secondaryColor}
						></path>
						<g id="Clip-6"></g>
						<path
							d="M11.17675,19.78575 L11.17675,10.2745 C11.17675,8.7495 12.888,7.5145 15.0005,7.5145 C17.11175,7.5145 18.823,8.7495 18.823,10.2745 L18.823,19.78575"
							id="Stroke-3"
							stroke={color}
							strokeWidth="2"
							mask="url(#mask-14a0vvhiun-2)"
						></path>
						<polygon
							id="Fill-5"
							stroke={color}
							mask="url(#mask-14a0vvhiun-2)"
							points="8.375 23.29125 21.625 23.29125 21.625 14.7725 8.375 14.7725"
						></polygon>
					</g>
				</g>
			</svg>
		);
	}
};

export default IconPadLock;
