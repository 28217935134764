import React from "react";
import classnames from "classnames";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import ImageWithCaption from "../../components/core/ImageWithCaption";
import Text from "../../components/core/Text";
import { getText } from "../../utils/textUtils";

const PageItemArticleCompact = (props) => {
	const { pageItem } = props;
	const { cmtPageItem = {} } = pageItem;

	const header = cmtPageItem.header;
	const body = cmtPageItem.body;
	const imageSrc = cmtPageItem.image;
	const imagePosition = cmtPageItem.image_position;
	const imageMaxHeight = cmtPageItem.image_max_height;
	const isZoomable = cmtPageItem.zoomable == "yes";

	return (
		<PageItemScrollBase
			{...props}
			renderFunction={(renderProps) => (
				<div className={classnames("row m-0 mb-6")}>
					{/* HEADER */}
					{getText(header) ? (
						<Text
							className={classnames(
								"col-sm-8 mb-6 font-weight-bold animated",
								renderProps.viewVisible ? "fadeInUp" : "fadeOut"
							)}
							tagName="h2"
							textId={header}
						/>
					) : (
						<div className="col-sm-8 " />
					)}
					<div className="col-sm-4" />
					{/* BODY */}
					<Text
						className={classnames(
							"col-md-6 animated",
							renderProps.viewVisible ? "fadeIn delay-250ms" : "fadeOut"
						)}
						style={{ order: imagePosition === "left" ? 2 : 1 }}
						tagName="p"
						textId={body}
					/>
					{/* IMAGE WITH CAPTION */}
					<ImageWithCaption
						src={imageSrc}
						maxHeight={imageMaxHeight}
						style={{ order: imagePosition === "left" ? 1 : 2 }}
						className="col-md-6 "
						classNameImage={classnames(
							"w-100 delay-500ms",
							renderProps.viewVisible
								? imagePosition === "left"
									? "fadeInLeft"
									: "fadeInRight"
								: "fadeOut"
						)}
						show={renderProps.viewVisible}
						size={cmtPageItem.image_size}
						caption={cmtPageItem.image_caption}
						isZoomable={isZoomable}
					/>
				</div>
			)}
		/>
	);
};

export default PageItemArticleCompact;
