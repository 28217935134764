import React, { createRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";

import FFButton from "../../../components/FFButton";
import StatusSelect from "./StatusSelect";
import * as LocationActions from "../../../redux/actions/LocationActions";
import * as PageActions from "../../../redux/actions/PageActions";
import { getReducer } from "../../../utils/StoreUtils";
import { getLocationPathForPage } from "../../../utils/PagesUtils";
import {
	convertLocationPathToLocation,
	getPageIdsFromLocation,
} from "../../../utils/LocationUtils";
import { makeGetPageByIdSelector } from "../../../selectors/PageSelectors";
import { makeGetLocationSelector } from "../../../selectors/LocationSelectors";

import PageListItems from "./PageListItems";

class PageListItem extends React.Component {
	static propTypes = {
		id: PropTypes.string.isRequired,
		filters: PropTypes.arrayOf(
			PropTypes.shape({
				path: PropTypes.string.isRequired,
				value: PropTypes.number.isRequired,
			})
		),
		renderFunction: PropTypes.func,
	};
	constructor(props) {
		super(props);
		this.state = {
			selfOuterRef: createRef(),
		};
	}

	componentDidMount() {
		if (this.props.isOuterLeafPage) {
			this.scrollSelfIntoView();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.isOuterLeafPage !== this.props.isOuterLeafPage &&
			this.props.isOuterLeafPage
		) {
			const outerElement = _.get(this, "state.selfOuterRef.current");
			outerElement &&
				outerElement.scrollIntoView &&
				outerElement.scrollIntoView();
		}

		if (prevState.selfOuterRef.current == null) {
			debugger;
		}
	}

	scrollSelfIntoView() {
		const outerElement = _.get(this, "state.selfOuterRef.current");
		outerElement &&
			outerElement.scrollIntoView &&
			outerElement.scrollIntoView();
	}

	_onPageButtonClick = () => {
		const { page } = this.props;
		const pages = getReducer("pages").items;
		const locationPath = getLocationPathForPage(pages, page);
		const location = convertLocationPathToLocation(locationPath);
		LocationActions.updateLocation(location);
	};

	_onStatusSelect = (status) => {
		const { id } = this.props;
		PageActions.updatePage(id, { status });
	};

	applyFilters = () => {
		const { filters, page } = this.props;
		if (filters) {
			return filters.some(
				(filter) => _.get(page, filter.path) === filter.value
			);
		} else {
			return true;
		}
	};

	render() {
		const { page, id, filters, location, renderFunction, isSelectedLocation } =
			this.props;

		if (!page) {
			return <React.Fragment />;
		}

		if (this.applyFilters()) {
			return (
				<li ref={this.state.selfOuterRef} className="nav-item w-100 my-2">
					<div className="d-flex justify-content-between">
						<FFButton
							onClick={this._onPageButtonClick}
							className={classnames(
								"btn",
								isSelectedLocation ? "btn-light" : "btn-dark"
							)}
						>
							{page.name}
						</FFButton>
						<StatusSelect
							className=""
							onSelect={this._onStatusSelect}
							status={page.status}
						/>
					</div>
					{renderFunction && renderFunction({ page, location })}
					<PageListItems
						parentPageId={id}
						filters={filters}
						renderFunction={renderFunction}
					/>
				</li>
			);
		} else {
			return <PageListItems parentPageId={id} filters={filters} />;
		}
	}
}

const makeMapStateToProps = () => {
	const getPageByIdSelector = makeGetPageByIdSelector();
	const getLocation = makeGetLocationSelector();
	const mapStateToProps = (state, props) => {
		const location = getLocation(state, props);
		const locationPageIds = getPageIdsFromLocation(location);
		const isSelectedLocation = locationPageIds.includes(props.id);
		const isOuterLeafPage = _.last(locationPageIds) === props.id;
		return {
			page: getPageByIdSelector(state, props),
			location: location,
			isSelectedLocation,
			isOuterLeafPage,
		};
	};
	return mapStateToProps;
};

export default connect(makeMapStateToProps)(PageListItem);
