import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";

import GlobalContainer from "./pagecontainers/GlobalContainer";
import ContentPageContainer from "./pagecontainers/ContentPageContainer";
import * as AppActions from "./redux/actions/AppActions";
import * as ConfigActions from "./redux/actions/ConfigActions";
import { getSizeObjectForSize } from "utils/ConfigUtils";
import { getReducer } from "./utils/StoreUtils";
import { replaceLanguageClassOnBody } from "./utils/LocalizationUtils";
import { replaceClassOnElement } from "./utils/DomUtils";
import { connect as connectToElectron } from "./Electron";
import Pages from "funkis-foundation/components/core/Pages";

window.alertOnce = (message) => {
	if (!window.alertOnceHistory) {
		window.alertOnceHistory = {};
	}

	if (!window.alertOnceHistory[message]) {
		alert(message);
		window.alertOnceHistory[message] = true;
	}
};

const getOrientation = ({ width, height }) => {
	// Using save critria as window.matchMedia("(orientation: portrait)")
	if (height >= width) return "orientation-portrait";
	else return "orientation-landscape";
};
const replaceOrientationClassOnBody = (oldClass, newClass) => {
	replaceClassOnElement({
		elementSelector: "body",
		classToRemove: oldClass,
		classToAdd: newClass,
	});
};

class Main extends React.Component {
	constructor(props) {
		super(props);
		const width = window.innerWidth;
		const height = window.innerHeight;
		const orientation = getOrientation({ width, height });
		replaceOrientationClassOnBody("", orientation);
		replaceLanguageClassOnBody("", props.config.language);
		this.state = {
			size: {},
			width,
			height,
			orientation,
			devModeClicked: false,
		};
		window.onresize = (e) =>
			this._onResize(window.innerWidth, window.innerHeight);
	}

	static propTypes = {
		config: PropTypes.object,
		renderContent: PropTypes.bool,
	};

	static defaultProps = {
		renderContent: true,
	};

	componentDidMount() {
		this.updateSize();
		connectToElectron();
	}

	componentDidUpdate(prevProps, prevState) {
		const { config } = this.props;

		const size = this.getSize();
		if (!_.isEqual(size, prevState.size)) {
			this.updateSize();
		}

		/* Check for language change and replace language code css class on body  */
		const oldLang = _.get(prevProps, "config.language");
		const newLang = _.get(this.props, "config.language");
		if (!_.isEqual(oldLang, newLang)) {
			replaceLanguageClassOnBody(oldLang, newLang);
		}

		/* Check for Orientation change and replace Orientation css class on body  */
		const oldOrientation = _.get(prevState, "orientation");
		const newOrientation = _.get(this.state, "orientation");
		if (!_.isEqual(oldOrientation, newOrientation)) {
			replaceOrientationClassOnBody(oldOrientation, newOrientation);
		}

		window.devMode = config && config.devMode;
	}

	getSize = () => {
		const { config } = this.props;
		const { width, height } = this.state;
		return getSizeObjectForSize(config, width, height);
	};

	updateSize = () => {
		const size = this.getSize();
		this.setState({ size });
		AppActions.updateAppValue({ size });
	};

	_onResize = (width, height) => {
		const orientation = getOrientation({ width, height });
		this.setState({ width, height, orientation });
	};

	_onEnableDevModeClick = () => {
		const { devModeClicked } = this.state;
		const app = getReducer("app").item;
		// Show inspector on double click
		if (devModeClicked) {
			ConfigActions.updateConfig({ devMode: true, showInspector: true });
			const inspector = { ...app.inspector };
			inspector.open = true;
			AppActions.updateApp({ inspector: inspector });
		}
		// Double click
		this.setState({ devModeClicked: true });
		setTimeout(() => {
			this.setState({ devModeClicked: false });
		}, 500);
	};

	render() {
		const { size } = this.state;
		const { renderContent } = this.props;
		const { globalContinerProps = {}, globalLeftContinerProps = {} } =
			this.props.app || {};

		return (
			<div className={classnames(size.className, "provider-content")}>
				{renderContent && (
					<React.Fragment>
						<div className={classnames("content-container")}>
							<GlobalContainer
								parentPageId="global-left-container"
								{...globalContinerProps}
								className={classnames(globalContinerProps.className)}
							/>

							<ContentPageContainer>
								<div className="page-container page page-container-portrait">
									<Pages parentPageId={null} locationPath={[]} layer="back" />
								</div>
							</ContentPageContainer>

							<GlobalContainer
								parentPageId="global-right-container"
								{...globalContinerProps}
								className={globalContinerProps.className}
							/>
						</div>

						<GlobalContainer
							parentPageId="global-container"
							{...globalContinerProps}
							className={globalContinerProps.className}
						/>
					</React.Fragment>
				)}
				<div className="provider-children">{this.props.children}</div>
				<button
					className="enable-dev-mode"
					onClick={this._onEnableDevModeClick}
				>
					Double click to enable devMode
				</button>
			</div>
		);
	}
}

export default Main;
