import React, { useState } from "react";
import { connect } from "../../utils/reduxUtils";
import classnames from "classnames";
import {
	ReduxCollectionSelectors,
	ReduxObjectSelectors,
	PageItemMenuButton,
	FFText,
	LocationSelectors,
} from "funkis-foundation";
import Text from "../../components/core/Text";
import IconWithLabel from "../slide/IconWithLabel";
import StatusIcon from "../slide/StatusIcon";
import IOBadgeOutput from "../core/IOBadgeOutput";
import IconTime from "../../assets/svg/IconTime";

const { makeGetItemByIdSelector, makeGetItemIdsSelector } =
	ReduxCollectionSelectors;
const { makeGetValueSelector } = ReduxObjectSelectors;
const { makeGetLocationSelector } = LocationSelectors;

import Style from "./MainMenuChapterContainer.module.css";
import { getBootstrapColor } from "../../utils/colorUtils";
import ImageVideoOrDiv from "../core/ImageVideoOrDiv";
import { useSelector } from "react-redux";
import _ from "lodash";

let _component;
let _children;

export const setComponent = (component) => {
	_component = component;
};

export const setChildren = (children) => {
	_children = children;
};

const ChapterStatus = ({ children, textId, type = "tilted" }) => {
	if (type === "tilted") {
		return (
			<div
				className={Style.ChapterStatusTilted}
				style={{
					transform: "rotate(-30deg)",
					transformOrigin: "center center",
				}}
			>
				<Text
					textId={textId}
					className="menu-locked font-weight-bold text-uppercase my-2"
				/>
			</div>
		);
	} else if (type === "cover") {
		return (
			<div className="absolute-top w-100 h-100 d-flex justify-content-center align-items-center">
				<div className="absolute-top w-100 h-100 opacity-50 bg-black"></div>
				<Text
					textId={textId}
					className="menu-locked font-weight-bold text-uppercase my-2 position-relative"
				/>
			</div>
		);
	} else {
		return <div />;
	}
};

const SlideChapterIdentifier = ({
	cmtPage,
	chapterIdentifier,
	theme = "text",
	index = 0,
}) => {
	if (theme === "text") {
		return (
			<React.Fragment>
				<Text
					textId={chapterIdentifier}
					className={classnames(
						"menu-id mr-1 font-weight-bold",
						theme === "dark" && "text-white",
						Style.slideChapterIdentifier
					)}
				/>
				{/* HEADER */}
				<FFText
					textId={cmtPage.title_txt_id}
					className={classnames(
						"menu-title font-weight-bold mb-3",
						theme === "dark" && "text-white"
					)}
				/>
			</React.Fragment>
		);
	} else if (theme === "thumb-nails") {
		return (
			<span className="mb-3">
				<Text
					textId={chapterIdentifier}
					className={classnames(
						"menu-title mr-1 text-uppercase font-weight-bold",
						theme === "dark" && "text-white",
						Style.slideChapterIdentifier
					)}
				/>
				{/* HEADER */}
				<FFText
					textId={cmtPage.title_txt_id}
					className={classnames(
						"menu-title font-weight-bold",
						theme === "dark" && "text-white"
					)}
				/>
			</span>
		);
	} else {
		return <div />;
	}
};

const MainMenuChapterContainer = (props) => {
	if (_component) {
		return _component(props);
	}
	const { page, pageIds, index, className, children, mainMenuTextColor } =
		props;
	const { cmtPage } = page.data;
	const app = useSelector((state) => _.get(state, "app.item"));
	const textColor =
		cmtPage.menu_item_text_color || mainMenuTextColor || "black";
	const backgroundColor = cmtPage.menu_item_background_color || "transparent";
	const mainMenuMedia =
		cmtPage.main_menu_media || cmtPage.main_menu_image || cmtPage.media;
	const pageButtonIconColor = getBootstrapColor(
		app.main_menu_page_icon_color || "primary"
	);

	const [hover, setHover] = useState(false);

	const badges = app.main_menu_show_gamification_badges === "yes" && (
		<IOBadgeOutput
			chapterId={page.id}
			mode="count"
			backgroundColorBadgeCounter="rgba(255,255,255,0.25)"
			backgroundColor="rgba(255,255,255,0.25)"
			colorBadgeCounter={textColor}
			size="30px"
		/>
	);

	return (
		<div
			className={classnames(
				"main-menu-chapter-container__wrapper",
				`chapter-index-${index}`
			)}
			onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}}
		>
			<PageItemMenuButton
				pageItem={{
					pageId: pageIds[0] || page.id,
					enabled: page.status !== "not_attempted",
				}}
				className={classnames(
					Style.slideChapterButton,
					!pageIds.length && "pointer-events-none visibility-hidden",
					className
				)}
				style={{
					color: getBootstrapColor(textColor),
					backgroundColor: getBootstrapColor(backgroundColor),
					borderColor: hover ? getBootstrapColor(textColor) : "transparent",
				}}
			>
				{/* THUMB NAIL IMAGE */}
				{mainMenuMedia && (
					<ImageVideoOrDiv
						src={mainMenuMedia}
						imageClassName=""
						className={classnames("position-relative", Style.slideImage)}
					>
						{/* <PageItemImage
              pageItem={{ src: mainMenuMedia, backgroundImage: true, autoSize: false }}
              className={classnames("position-relative", Style.slideImage)}
            > */}
						{mainMenuMedia && page.status === "not_attempted" && (
							<div className="position-absolute absolute-top w-100 h-100 d-flex justify-content-center align-items-center text-white">
								<ChapterStatus
									textId="63C77141-1DFE-48B8-9234-97735023DAED"
									type="cover"
								/>
							</div>
						)}
						{/* </PageItemImage> */}
					</ImageVideoOrDiv>
				)}
				{/* TEXTS */}
				<div className="text-container d-flex flex-column">
					{/* CHAPTER IDENTIFIER AND HEADER */}
					<SlideChapterIdentifier
						chapterIdentifier={cmtPage.chapter_identifier}
						cmtPage={cmtPage}
						index={index}
						theme={mainMenuMedia ? "thumb-nails" : "text"}
					/>

					{/* SUB HEADER 1 */}
					<Text
						tagName="h5"
						textId={cmtPage.sub_title_1_txt_id}
						className={classnames("menu-sub-title")}
					/>
					{/* SUB HEADER 2 */}
					{/* <Text tagName="h5" textId={cmtPage.sub_title_2_txt_id} className={classnames("menu-sub-title")} /> */}
					{/* ICONS */}
					<div
						className="d-flex flex-wrap align-items-center mt-4 main-menu-icon"
						style={{
							color: getBootstrapColor(
								app.main_menu_page_button_text_color || "white"
							),
						}}
					>
						{/* DURATION */}
						{cmtPage.duration && (
							<IconWithLabel
								icon={<IconTime color={pageButtonIconColor} variant="large" />}
								text={cmtPage.duration}
								textColor={textColor}
							/>
						)}
						{/* STATUS */}
						<StatusIcon
							status={page.status}
							color={pageButtonIconColor}
							textColor={textColor}
						/>
						{/* GAMIFICATION - BADGES */}
						{!mainMenuMedia && badges}
					</div>
					{/* GAMIFICATION - BADGES (when thumbnail) */}
					<div className="mt-2" style={{ marginLeft: "-5px" }}>
						{mainMenuMedia && badges}{" "}
					</div>
				</div>

				{(_children && _children(props)) || children}
				{!mainMenuMedia && page.status === "not_attempted" && (
					<div className="position-absolute absolute-top w-100 h-100 d-flex justify-content-center align-items-center text-white">
						<ChapterStatus
							textId="63C77141-1DFE-48B8-9234-97735023DAED"
							type="tilted"
						/>
					</div>
				)}
			</PageItemMenuButton>
		</div>
	);
};

const iconStyle = ({ textColor }) => ({
	border: `3px solid ${textColor}`,
	borderRadius: "16px",
});

const makeMapStateToProps = () => {
	const getItemByIdSelector = makeGetItemByIdSelector();
	const getLocationSelector = makeGetLocationSelector();
	const getValueSelector = makeGetValueSelector();
	const getItemIdsSelector = makeGetItemIdsSelector();
	const mapStateToProps = (state, props) => {
		return {
			page: getItemByIdSelector(state, props, "pages"),
			location: getLocationSelector(state, props),
			pageIds: getItemIdsSelector(
				state,
				{ match: { parentPageId: props.id } },
				"pages"
			),
			theme:
				getValueSelector(state, { path: "main_menu_theme" }, "app") || "light",
			numberOfMenuColumns: getValueSelector(
				state,
				{ path: "number_of_menu_columns" },
				"app"
			),
			programType: getValueSelector(state, { path: "program_type" }, "app"),
			mainMenuTextColor:
				getValueSelector(state, { path: "main_menu_text_color" }, "app") ||
				"black",
		};
	};
	return mapStateToProps;
};

export default connect(MainMenuChapterContainer, makeMapStateToProps);
