import * as StoreUtils from "../../utils/StoreUtils";

// Action constants
import {
	UPDATE_UNITS,
	UPDATE_UNIT,
	REMOVE_UNIT,
} from "../constants/ActionConstants";

export function updateUnits(items) {
	StoreUtils.getStore().dispatch(_updateUnits(items));
}

function _updateUnits(items) {
	return (dispatch: (action) => void) => {
		dispatch({ items, type: UPDATE_UNITS });
	};
}

export function updateUnit(id, updates) {
	StoreUtils.getStore().dispatch(_updateUnit(Object.assign(updates, { id })));
}

function _updateUnit(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_UNIT });
	};
}

export function removeUnit(id) {
	StoreUtils.getStore().dispatch(_removeUnit({ id }));
}

function _removeUnit(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_UNIT });
	};
}
