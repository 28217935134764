import React from "react";
import _ from "lodash";
import classnames from "classnames";
import Text from "../../components/core/Text";
import Image from "../../components/core/Image";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import { getText } from "../../utils/textUtils";
import { getBootstrapColor } from "../../utils/colorUtils";
import Styles from "./PageItemTable.module.css";

// https://stackoverflow.com/a/494348/4763083
function createElementFromHTML(rowDataHtmlString) {
	var div = document.createElement("tr");
	div.innerHTML = rowDataHtmlString.trim();
	return div.childNodes;
}

function getRowContent({ cmtRow, rowIndex, isHeaderRow }) {
	const simpleRowTds = Array.from(createElementFromHTML(cmtRow.text));
	const rowData = simpleRowTds.map((td) => {
		return {
			image: cmtRow.image,
			color: td.className && getBootstrapColor(td.className.trim()),
			text: td.innerText,
		};
	});

	const rowContent = rowData.map((rowData, index) => {
		let innerContent;
		const styles = {
			opacity: isHeaderRow ? 1 : 0,
			animationDelay: `${rowIndex * 700 + index * 150}ms`,
		};
		const className = "word-or-color animated fadeIn";
		if (rowData.color && !rowData.text) {
			innerContent = (
				<div
					className={classnames(className, Styles.dot)}
					style={{ ...styles, backgroundColor: rowData.color }}
				></div>
			);
		} else {
			// is text
			const imageIsAllowed = index == 0 && !isHeaderRow;
			const textAlignStyles =
				index != 0 && !isHeaderRow
					? { textAlign: "center", margin: "0 auto" }
					: { textAlign: "left" };
			innerContent = (
				<div
					style={styles}
					className={classnames(
						className,
						rowData.image && "d-flex align-items-center"
						// index != 0 && !isHeaderRow ? "text-center" : "text-left"
					)}
				>
					{imageIsAllowed && rowData.image && (
						<Image
							className={classnames("d-inline-block mr-1", Styles.rowImage)}
							maxHeight={32}
							src={rowData.image}
						/>
					)}
					<span style={{ ...textAlignStyles }} className="d-inline-block">
						{rowData.text || ""}
					</span>
				</div>
			);
		}
		return innerContent;
	});

	return (
		<tr>
			{rowContent.map((contentTd, index) => {
				return isHeaderRow ? (
					<th className="p-1">{contentTd}</th>
				) : (
					<td
						className={classnames(
							index != 0 && "p-1",
							index == 0 && ["font-weight-bold", Styles.firstCol]
						)}
					>
						{" "}
						{contentTd}
					</td>
				);
			})}
		</tr>
	);
}

const TableComponent = ({ rows }) => {
	const head = _.head(rows);
	return (
		<table className="col-12">
			<thead>
				{/* <RowComponent row={head} isHeaderRow></RowComponent> */}
				{getRowContent({ cmtRow: head, rowIndex: 0, isHeaderRow: true })}
			</thead>
			<tbody>
				{_.tail(rows).map(
					(row, index) =>
						getRowContent({ cmtRow: row, rowIndex: index, isHeaderRow: false })
					// <RowComponent key={index} rowIndex={index} row={row} />
				)}
			</tbody>
		</table>
	);
};

const Table = (props) => {
	const { pageItem, viewVisible } = props;
	const { cmtPageItem = {} } = pageItem;

	const header = cmtPageItem.header;
	const body = cmtPageItem.body;
	const imageSrc = cmtPageItem.image;
	const imageSize = cmtPageItem.image_size;
	const imageAlign = cmtPageItem.image_align;
	const imageMaxHeight = cmtPageItem.image_max_height;
	const imageMarginsMobile = cmtPageItem.image_margins_mobile !== "no";

	return (
		<React.Fragment>
			{/* HEADER */}
			<div className="container-fluid">
				<div className="row m-0">
					<Text
						className={classnames(
							"col-sm-12 mb-4 font-weight-bold animated",
							viewVisible ? "fadeInUp" : "fadeOut"
						)}
						tagName="h2"
						textId={header}
					/>
				</div>
				<div className="row m-0">
					<Text
						className={classnames(
							"font-italic col-sm-12 mb-4 animated",
							viewVisible ? "fadeInUp" : "fadeOut"
						)}
						tagName="p"
						textId={body}
					/>
				</div>

				{imageSrc && (
					<div className="row m-0 px-3">
						<Image
							src={imageSrc}
							maxHeight={imageMaxHeight}
							align={imageAlign}
							className="mb-2"
						/>
					</div>
				)}
			</div>

			<div className="container-fluid">
				<div className={classnames("row m-0 p-3", Styles.tableWrapper)}>
					<TableComponent
						rows={cmtPageItem.content_array.map((item) => ({
							image: item.media,
							text: getText(item.text_1),
						}))}
					/>
				</div>
			</div>
		</React.Fragment>
	);
};

const PageItemTable = (props) => {
	return (
		<PageItemScrollBase
			{...props}
			className={Styles.root}
			containerClassName="w-100"
			renderFunction={(renderProps) => (
				<Table {...props} viewVisible={renderProps.viewVisible}></Table>
			)}
		/>
	);
};

export default PageItemTable;
