import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import classnames from "classnames";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import styled from "styled-components";
import { toJpeg as htmlToJpeg } from "html-to-image";
import Text from "../../components/core/Text";
import { TRAIT_MODE } from "../../constants/traitConstants";
import TraitOption from "../../components/scroll/TraitOption";
import { getText } from "../../utils/textUtils";
import LightBox from "../../components/scroll/LightBox";
import { getTraitsFromResults } from "../../utils/sprigStoryUtils";
import {
	Actions as FoundationActions,
	PageItemPage,
	PagesUtils,
	PageActions,
} from "funkis-foundation";
import TraitInput from "../../components/scroll/TraitInput";
import { useStore, useSelector } from "react-redux";
import { IOContext, QuidType, useBackend } from "../../hooks/backend";
import { useValueListOptionsOrContentArray } from "../../hooks/valuelist";

const LightBoxStyled = styled(LightBox)`
	&.overrideOuterContainerPadding {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.overrideInnerContainerPadding {
		padding: 0 !important;
	}

	.overrideInnerContainerWidth {
		max-width: 800px !important;
	}

	& .animated {
		/* speed up animations and skip sequences like the one in article and quote block */
		animation-duration: 200ms !important;
		animation-delay: 0ms !important;
		animation-name: fadeIn;
	}
`;

// icon = "arrowDownToBase"   https://skapa.ikea.net/components/core-components/button
const IconShare = ({ fill = "black" }) => (
	<svg
		focusable="false"
		viewBox="0 0 24 24"
		className="ikea_svg_icon"
		aria-hidden="true"
	>
		<path
			fill={fill}
			d="m11 7.8294-3.242 3.242-1.4143-1.4142L12 4l5.6575 5.6572-1.4143 1.4142L13 7.8284V16h-2V7.8294z"
		></path>
		<path fill={fill} d="M2 21.0029h20v-9h-2v7H4v-7H2v9z"></path>
	</svg>
);

// icon = "arrowUpFromBase"   https://skapa.ikea.net/components/core-components/button
const IconDownload = ({ fill = "black" }) => (
	<svg
		focusable="false"
		viewBox="0 0 24 24"
		className="ikea_svg_icon btn__icon"
		aria-hidden="true"
	>
		<path
			fill={fill}
			d="m13 12.1696 3.243-3.243 1.4142 1.4142L12 16l-5.6565-5.6592 1.4142-1.4142L11 12.169V4h2v8.1696z"
		></path>
		<path fill={fill} d="M22 20.999H2v-9h2v7h16v-7h2v9z"></path>
	</svg>
);

const IconButton = styled(
	({ onClick, disabled, primary, className, text, type }) => {
		const Icon = type === "download" ? IconDownload : IconShare;

		const fill = disabled ? "#929292" : primary ? "white" : "black";
		return (
			<button
				onClick={onClick}
				disabled={disabled}
				className={classnames(
					"btn rounded-pill button-small p-1 mx-2 mb-2 h-100",
					primary && "btn-black",
					disabled && "ikea_disabled",
					className
				)}
			>
				<span className="ikea_btn_inner">
					<Icon fill={fill} />
					<span className="ikea_btn_label">{text}</span>
				</span>
			</button>
		);
	}
)`
  position: relative;
  font-size: 0.875rem;
  border: 0;
  padding: 0;
  line-height: 1.4285714286;
  font-weight: bold;
  text-align: center;
  display: inline-flex;
  align-items: center;
  border-radius: 64px;
  cursor: pointer;
  vertical-align: top;
  max-width: none;
  background-color: ${({ primary }) => (primary ? "#111" : "white")};
  box-shadow: ${({ primary, disabled }) =>
		primary || disabled ? "none" : "0 0 0 1px #111"};

  order: ${({ primary }) => (primary ? 1 : 2)};

  &:disabled,
  &:disabled:hover {
    background-color: ${({ primary, disabled }) =>
			disabled ? "#dfdfdf" : primary ? "#dfdfdf" : "white"}!important;
    cursor: default;
    box-shadow:: none;
  }
  &:disabled .ikea_btn_label {
    color: #929292 !important;
  }

  &:hover:not(.ikea_disabled) {
    box-shaddow: ${({ primary }) => (primary ? "none" : "0 0 0 2px #111")};
    background: ${({ primary, disabled }) =>
			disabled ? "#dfdfdf" : primary ? "inherit" : "white"};
  }

  & .ikea_btn_inner {
    padding: 0 2rem;
    padding-inline-start: 1.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  & .ikea_svg_icon {
    display: inline-block;
    margin-inline-end: 8px;
    height: 1.5rem;
    width: 1.5rem;
  }
  & .ikea_btn_label {
    letter-spacing: 0;
    color: ${({ primary }) => (primary ? "white" : "#111")};
  }
`;

const Option = ({ theme, ...rest }) => {
	switch (theme) {
		default:
			return <TraitOption {...rest} />;
	}
};

const ResponsiveGrid = styled.div`
	display: grid;
	grid-template-columns: 300px;
	justify-content: center;
	column-gap: 0.5rem;
	row-gap: 0.5rem;
	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@media (min-width: 575px) and (max-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
	@media (max-width: 575px) {
		grid-template-columns: 1fr 1fr;
	}
`;

const OptionWrapper = styled.div`
	height: 100%;
	@media (hover: hover) {
		:hover {
			z-index: 3;
		}
	}
`;

const RotatedWrapper = styled(
	React.forwardRef(({ degres, backgroundColor, children, className }, ref) => {
		return (
			<div ref={ref} className={className + " h-100"}>
				<div className="inner-wrapper h-100">{children}</div>
			</div>
		);
	})
)`
	padding: 3rem 1rem;
	background-color: ${({ backgroundColor }) => backgroundColor};
	transform: rotate(${({ degres }) => degres}deg);
	.inner-wrapper {
		transform: rotate(${({ degres }) => degres * -1}deg);
	}
	.hide-when-saving {
		visibility: ${({ isSaving }) => (isSaving ? "hidden" : "visible")};
	}
`;

const NoBulletIndentTextContainer = styled.div`
	ul,
	ol {
		list-style-position: inside;
		padding-left: 0;
	}
`;

const PageItemIOSprigStoryTraitSelection = (props) => {
	const store = useStore();
	const { pageItem } = props;
	const { cmtPageItem, status } = pageItem;
	const [inputText, setInputText] = useState("");
	const [delayShowTrait, setDelayShowTrait] = useState(true);
	const [numTraitsSelected, setNumTraitsSelected] = useState(0);
	const traitMode = cmtPageItem.mode;
	const [modalPageFriendlyId, setModalPageFriendlyId] = useState(null);
	const showInput = cmtPageItem.show_add_words_input === "yes";
	const isMobile = useSelector((state) => {
		const size = _.get(state, "app.item.size.name");
		return size === "mobile" || size === "mobile small";
	});

	const onTextInputChange = (e) => {
		setInputText(e.target.value);
	};

	const contentArray = useValueListOptionsOrContentArray(
		cmtPageItem,
		"navigation"
	);

	const {
		quidInputs: sprigstoryQuidInputs,
		createQuidInput: createSprigstoryQuidInput,
		updateQuidInput: updateSprigstoryQuidInput,
	} = useBackend({
		targetDataKey: cmtPageItem.io_id,
		quidType: QuidType.SprigStory,
		inputContext: IOContext.Auto,
	});

	const quidInput = sprigstoryQuidInputs[0] || {};
	const data = _.get(quidInput, "data", {});
	const save = (data) => {
		const oldData = quidInput.data;

		const updates = { ...quidInput, data: data };
		if (!updates.id) {
			createSprigstoryQuidInput(updates);
		} else {
			updateSprigstoryQuidInput(updates);
		}
	};
	const quidInputIds = sprigstoryQuidInputs.map((qi) => qi.id);
	// const { data, save, quidInputIds } = renderProps;
	let { result = [], selectedTraits: originalSelectedTraits = [] } = data;

	let selectedTraits = [...originalSelectedTraits];

	// UGLY FIX! This section fixes an issue when we want to collect selectedTraits from a list of IO ID:s
	if (quidInputIds.length > 1) {
		const quidInputs = quidInputIds.map((quidInputId) =>
			store
				.getState()
				.quidInputs.items.find((quidInput) => quidInput.id === quidInputId)
		);
		const selectedTraitsCollection = quidInputs.map((item) =>
			_.get(item, "data.selectedTraits", [])
		);
		selectedTraits = _.flatten(selectedTraitsCollection);
	}

	// Filter traits differently from value and mode
	let traits = getTraitsFromResults({
		data: { result, selectedTraits },
		cmtPageItem,
		traitMode,
	});

	// Add traits from content array...
	const traitsFromContentArray = [];
	contentArray.forEach((item) =>
		traitsFromContentArray.push({
			name_text: getText(item.button_text),
			id: item.id,
			value: item.value,
			category: cmtPageItem.filter_by_category,
			exploreBody: item.explore_body,
			explorePageFriendlyId: item.page_friendly_id,
		})
	);

	// Merge traits from sprigs with traits from content array if mode != "SUMMARY"
	if (traitMode === TRAIT_MODE.JOINED_MATCH) {
		traits = selectedTraits;
	} else {
		traits = traits.concat(traitsFromContentArray);
	}

	// Calculate numbber of traits selected...
	useEffect(() => {
		setNumTraitsSelected(selectedTraits.length);
	}, [selectedTraits.length]);

	const onOptionSelect = (e) => {
		let newSelectedTraits = [...selectedTraits];

		// Toggle...
		const isAlreadySelected = _.find(
			newSelectedTraits,
			(trait) => trait.id === e.trait.id
		);
		if (isAlreadySelected) {
			_.remove(newSelectedTraits, (trait) => e.trait.id === trait.id);
		} else {
			newSelectedTraits.push(e.trait);
		}
		// Remove duplicates...
		newSelectedTraits = _.uniqBy(newSelectedTraits, "id");

		// Check to se if max selections reached...
		if (
			newSelectedTraits.length >
			(cmtPageItem.max_selections || Number.MAX_SAFE_INTEGER)
		) {
			return;
		}

		let result = data.result;
		const isUserGeneratedTrait = !e.trait.friendly_id;
		if (
			cmtPageItem.show_add_words_input === "yes" &&
			isUserGeneratedTrait &&
			isAlreadySelected
		) {
			result = _.filter(result, (trait) => e.trait.id !== trait.id);
			console.log("removing custom trait", e.trait.id);
		}
		save({
			...data,
			selectedTraits: newSelectedTraits,
			result,
		});
	};

	const addTrait = (value) => {
		if (!value || !value.trim()) {
			setInputText("");
			return;
		}

		setDelayShowTrait(false);
		let newResult = [...result];

		const trait = {
			name_text: value,
			id: value,
			value: 1,
			category: cmtPageItem.filter_by_category,
		};
		const alreadyExists = newResult.find((t) => t.id === value);
		!alreadyExists && newResult.push(trait);

		let newSelectedTraits = [...selectedTraits, trait];

		save({
			...data,
			selectedTraits: newSelectedTraits,
			result: newResult,
		});
		setInputText("");
	};

	// states for Explore
	const [exploredOptions, setExploredOptions] = useState([]);

	const onOptionExplore = (e) => {
		const { trait: selectedTrait } = e;
		const page = PagesUtils.getPageByPath([
			{ friendlyId: selectedTrait.explorePageFriendlyId },
		]);
		// uggly hack to disable scoll-to-top-behavior that wrongly affects the current page instead of just the one opened in LightBox
		page && PageActions.updatePage(page.id, { winScrollBehaviour: null });
		setExploredOptions(_.uniqBy(exploredOptions.concat(selectedTrait), "id"));
		setModalPageFriendlyId(selectedTrait.explorePageFriendlyId);
	};

	// States for matched values
	const [matchedValues, setMatchedValues] = useState([]);
	const onMatchedValueClick = ({ trait: selectedValue }) => {
		const shouldToggleOff = Boolean(
			matchedValues.find((val) => val.id === selectedValue.id)
		);
		setMatchedValues(
			shouldToggleOff
				? matchedValues.filter((val) => val.id !== selectedValue.id)
				: matchedValues.concat(selectedValue)
		);
		// trigger creation of result image
		setShouldSaveImage(true);
	};
	const [hasTriggeredSaveFile, sethasTriggeredSaveFile] = useState(false);

	const hasReachedMinSelections = () => {
		if (traitMode === TRAIT_MODE.JOINED_MATCH) {
			return matchedValues.length >= _.get(cmtPageItem, "min_selections", 0);
		} else {
			return numTraitsSelected >= _.get(cmtPageItem, "min_selections", 0);
		}
	};
	const showNextButton =
		cmtPageItem.reveal_next_block === "no" && hasReachedMinSelections();

	// saveing image stuff
	const [shouldSaveImage, setShouldSaveImage] = useState(); // shouldSave / isSaving
	const resultElementRef = useRef(null);
	const [resultImageUrl, setResultImageUrl] = useState();
	const [resultImageFilename, setResultImageFilename] = useState();

	useEffect(() => {
		// save image here to be certain that the hiding of "non-printing" elements has had time to take place
		if (shouldSaveImage) {
			const sectionBackgroundColor = "#EEEEEE";
			htmlToJpeg(resultElementRef.current, {
				quality: 0.95,
				pixelRatio: 2,
				backgroundColor: sectionBackgroundColor,
			})
				.then(function (dataUrl) {
					const dateTimeString = new Date()
						.toJSON()
						.slice(0, 19)
						.replace("T", "_")
						.replace(/:/g, "_");
					const filename = `our-shared-values_${dateTimeString}.jpeg`;
					setResultImageFilename(filename);
					setResultImageUrl(dataUrl);
				})
				.then(() => {
					// set state
					setShouldSaveImage(false);
					sethasTriggeredSaveFile(true);
				});
		}
	}, [shouldSaveImage]);

	const handleDownloadClick = () => {
		const link = document.createElement("a");
		link.target = "_blank";
		link.rel = "noreferrer";
		link.href = resultImageUrl;
		// target filename
		link.download = resultImageFilename;
		link.click();
	};

	const handleShareClick = async () => {
		const blob = await (await fetch(resultImageUrl)).blob();
		const file = new File([blob], resultImageFilename, {
			type: "image/jpeg",
			lastModified: new Date(),
		});
		// NOTE: Do NOT use the "title" attribute, as this doesn't work with iOS,
		// resulting in a strange error where .share would seemingly work (except on WhatsApp), but could only be triggered once
		const shareData = { files: [file] };
		if (window.navigator.canShare && window.navigator.canShare(shareData)) {
			try {
				await window.navigator
					.share(shareData)
					.then(() => {
						// do nothing
					})
					.catch((err) => {
						console.error("share failed with error: " + err);
					});
			} catch (err) {
				// do nothing..
			}
		}
	};

	const isShareSupported = window.navigator.canShare;

	return (
		<PageItemScrollBase
			{...props}
			onNextClick={() => {
				FoundationActions.updatePageItem(pageItem.id, { status: "completed" });
			}}
			// don't auto-scroll to show the NextButton, just reveal it
			pageItem={{ ...pageItem, autoScrollOnShowNextButton: false }}
			showNextButton={showNextButton}
			renderFunction={({ isNextBlockRevealed }) => {
				return (
					<React.Fragment>
						<React.Fragment>
							<div className="row m-0">
								<Text
									className="col-md-11 mb-5"
									tagName="h2"
									textId={cmtPageItem.header}
								/>
								<Text
									className="col-md-10 mb-4"
									tagName="p"
									textId={cmtPageItem.body_1 || cmtPageItem.body}
								/>
							</div>
							<RotatedWrapper
								ref={resultElementRef}
								backgroundColor={cmtPageItem.plate_background_color}
								degres={cmtPageItem.plate_rotation}
								isSaving={shouldSaveImage}
							>
								<ResponsiveGrid traitMode={traitMode} className="row m-0">
									{traits.map((trait, index) => {
										let isSelected = false;
										switch (traitMode) {
											case TRAIT_MODE.EXPLORE:
												isSelected = Boolean(
													exploredOptions.find((opt) => opt.id === trait.id)
												);
												break;
											default:
												isSelected = Boolean(
													_.find(
														selectedTraits,
														(selectedTrait) => selectedTrait.id === trait.id
													)
												);
										}

										return (
											<OptionWrapper key={index}>
												<Option
													//   className="col-md-4 m-1"
													color={cmtPageItem.trait_background_color || "gray"}
													selectedColor={
														cmtPageItem.trait_selected_background_color ||
														"black"
													}
													hasSelectionBorder={false}
													selectedBackgroundImage={
														cmtPageItem.trait_selected_background_image
													}
													buttonIcon={traitMode === TRAIT_MODE.EXPLORE}
													selectedLabelColor={
														cmtPageItem.trait_selected_label_color
													}
													textColor={cmtPageItem.trait_label_color || "white"}
													index={index}
													delayShow={
														traitMode !== TRAIT_MODE.JOINED_MATCH
															? delayShowTrait
															: false
													}
													key={index}
													selected={isSelected}
													theme={cmtPageItem.theme}
													labelTextId={trait.name_text_id}
													labelText={trait.name_text}
													disabled={traitMode === TRAIT_MODE.JOINED_MATCH}
													onClick={() => {
														if (traitMode === TRAIT_MODE.EXPLORE) {
															onOptionExplore({ trait, index });
														} else {
															onOptionSelect({ trait, index });
														}
													}}
												/>
											</OptionWrapper>
										);
									})}
								</ResponsiveGrid>
								{/* JOINED MATCH (last exercise ) */}
								{traitMode === TRAIT_MODE.JOINED_MATCH && (
									<React.Fragment>
										<ResponsiveGrid
											traitMode={traitMode}
											className="row m-0 mt-4"
										>
											{traitsFromContentArray.map((value, index) => {
												let isSelected = Boolean(
													matchedValues.find((val) => val.id === value.id)
												);

												return (
													<OptionWrapper key={index}>
														<Option
															//   className="col-md-4 m-1"
															color={cmtPageItem.ikea_value_background_color}
															selectedColor={
																cmtPageItem.ikea_value_selected_background_color ||
																"black"
															}
															textColor={isSelected ? "#000" : "#FFF"}
															index={index}
															delayShow={false}
															key={index}
															selected={isSelected}
															theme={cmtPageItem.theme}
															labelTextId={value.name_text_id}
															labelText={value.name_text}
															disabled={shouldSaveImage}
															onClick={() => {
																onMatchedValueClick({ trait: value });
															}}
														/>
													</OptionWrapper>
												);
											})}
										</ResponsiveGrid>
										<NoBulletIndentTextContainer
											className="d-flex flex-column mt-2"
											dangerouslySetInnerHTML={{
												__html: getText(cmtPageItem.body_2),
											}}
										/>
										<div className="d-flex flex-column mt-2 hide-when-saving">
											<p
												className={classnames(
													"animated p-0 col-md-9",
													hasTriggeredSaveFile ? "fadeIn fast" : "opacity-0"
												)}
												dangerouslySetInnerHTML={{
													__html: getText(cmtPageItem.body_3),
												}}
											/>
										</div>
										<div className="my-2 mx-auto d-flex flex-wrap justify-content-center hide-when-saving">
											<IconButton
												primary={!isMobile || !isShareSupported}
												type="download"
												disabled={matchedValues.length < 1}
												text={getText(cmtPageItem.save_result_button)}
												onClick={handleDownloadClick}
											></IconButton>
											{isShareSupported && (
												<IconButton
													onClick={handleShareClick}
													disabled={matchedValues.length < 1}
													type="share"
													primary={isMobile}
													text={getText(cmtPageItem.label_input_field)}
												/>
											)}
										</div>
									</React.Fragment>
								)}
								{showInput && (
									<React.Fragment>
										<div className="col-md-12 mt-3 p-0 animated fadeIn delay-1500ms">
											<TraitInput
												value={inputText}
												placeholder={getText(
													cmtPageItem.placeholder_input_field
												)}
												label={getText(cmtPageItem.label_input_field)}
												labelFor={cmtPageItem.id}
												color={
													cmtPageItem.traits_input_backgound_color || "gray"
												}
												confirmButtonTextId={cmtPageItem.confirm_button}
												onTextInputChange={onTextInputChange}
												onAdd={() => {
													addTrait(inputText);
												}}
												addDisabled={inputText.length < 1}
											/>
										</div>
										<Text
											className="col-md-10 mb-4"
											tagName="p"
											textId={cmtPageItem.body_3 || cmtPageItem.instruction_add}
										/>
									</React.Fragment>
								)}
							</RotatedWrapper>
						</React.Fragment>

						<LightBoxStyled
							className="overrideOuterContainerPadding"
							classNameInnerContainer="overrideInnerContainerPadding overrideInnerContainerWidth"
							show={modalPageFriendlyId !== null}
							onCloseClick={() => {
								setModalPageFriendlyId(null);
							}}
						>
							<PageItemPage pageItem={{ friendlyId: modalPageFriendlyId }} />
						</LightBoxStyled>
					</React.Fragment>
				);
			}}
		></PageItemScrollBase>
	);
};

export default PageItemIOSprigStoryTraitSelection;
