import React from "react";

const IconMissed = ({ color, className }) => {
	return (
		<div
			style={{ width: "11px", height: "11px", background: color }}
			className={className}
		/>
	);
};

export default IconMissed;
