import classnames from "classnames";
import { FFButton, FFText } from "funkis-foundation";
import Backend from "funkis-template/components/backend/Backend";
import Image from "funkis-template/components/core/Image";
import LightBox from "funkis-template/components/core/LightBox";
import Text from "funkis-template/components/core/Text";
import { VALUE_LIST_TYPE } from "funkis-template/constants/valueListConstants";
import { useProgramType } from "funkis-template/hooks/player";
import { useValueListOptionsOrContentArray } from "funkis-template/hooks/valuelist";
import {
	DilemmaInputScrollBlock,
	DilemmaInputSlideBlock,
} from "funkis-template/models/pageitem";
import { PageItem } from "funkis-template/models/player";
import { getLetterByIndex } from "funkis-template/utils/stringUtils";
import { getText } from "funkis-template/utils/textUtils";
import _ from "lodash";
import React, { FC, ReactNode, useEffect, useState } from "react";
import SpeechBubble from "../SpeechBubble";
import DilemmaCard from "./DilemmaCard";
import DilemmaFeedback from "./DilemmaFeedback";
import Styles from "./DilemmaInput.module.css";

const getPointsForIndex = ({ index, cmtPageItem, options }) => {
	return (
		(options[index] &&
			options[index].points.split(",").map((point) => Number(point))) || [0]
	);
};

type DilemmaInputProps = {
	pageItem: PageItem<DilemmaInputSlideBlock | DilemmaInputScrollBlock>;
	classNameTopContainer?: string;
	classNameReadMore?: string;
	classNameDilemmaCard?: string;
	classNameHeading?: string;
	onComplete?: () => void;
	onReadMoreCloseClick?: () => void;
	onBackendUpdate?: (props: {
		correctIndexes: number[];
		selectedIndexes: number[];
	}) => void;
	showReadMore?: boolean;
};

const DilemmaInput: FC<DilemmaInputProps> = (props) => {
	const {
		pageItem,
		classNameTopContainer,
		classNameReadMore,
		classNameDilemmaCard,
		classNameHeading,
		onComplete,
		onBackendUpdate,
		onReadMoreCloseClick,
		showReadMore = false,
	} = props;
	const { cmtPageItem } = pageItem;
	const programType = useProgramType();

	const [readMore, setReadMore] =
		useState<{ children: ReactNode } | undefined>(undefined);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [complete, setComplete] = useState(false);

	useEffect(() => {
		if (complete) {
			onComplete && onComplete();
		}
	}, [complete, onComplete]);

	const categoriesText =
		(cmtPageItem.point_categories && getText(cmtPageItem.point_categories)) ||
		"";
	const pointCategories = categoriesText.split(",");

	const pointMultiplierPerAttempt = cmtPageItem.point_multiplier_per_attempt
		? cmtPageItem.point_multiplier_per_attempt
				.split(",")
				.map((item) => Number(item))
		: [];

	const options = useValueListOptionsOrContentArray(
		cmtPageItem,
		VALUE_LIST_TYPE.DILEMMA
	);

	const points = getPointsForIndex({
		index: selectedIndex,
		cmtPageItem,
		options,
	});
	const selectedIndexCorrect = points[0] > 0;

	const selectedOption = options[selectedIndex];

	const correctIndexes: number[] = [];
	options.forEach((ca, index) => {
		const caPoints = getPointsForIndex({ index, cmtPageItem, options });
		caPoints[0] > 0 && correctIndexes.push(index);
	});

	const onCardReadMoreClick = ({ index }) => {
		setReadMore({
			children: (
				<div>
					<span>
						<h2 className="d-inline font-weight-bold">
							{getLetterByIndex(index, true) + ". "}
						</h2>
						<Text
							tagName="h3"
							textId={options[index].header}
							className="d-inline font-weight-bold"
						/>
						<br />
						<br />
						<Text tagName="h3" textId={options[index].body} />
					</span>
					<br />
					<br />
					{cmtPageItem.symbol_name === "io-dilemma-input-v2" && (
						<Text textId={cmtPageItem.read_more} />
					)}
				</div>
			),
		});
	};

	const onReadMoreClick = () => {
		setReadMore({
			children:
				cmtPageItem.symbol_name === "io-dilemma-input-v2" ? (
					<Text
						className={classnames(
							"d-inline",
							programType === "slide" && "p-md"
						)}
						textId={cmtPageItem.read_more}
					/>
				) : undefined,
		});
	};

	const onReadMoreCloseClickHandler = () => {
		setReadMore(undefined);
		onReadMoreCloseClick && onReadMoreCloseClick();
	};

	useEffect(() => {
		if (showReadMore && cmtPageItem.symbol_name === "io-dilemma-input-v2") {
			setReadMore({
				children: (
					<Text
						className={classnames(
							"d-inline",
							programType === "slide" && "p-md"
						)}
						textId={cmtPageItem.read_more}
					/>
				),
			});
		} else {
			setReadMore(undefined);
		}
	}, [showReadMore, cmtPageItem.symbol_name, programType]);

	return (
		<Backend
			{...props}
			quidType="dilemma"
			mode="input"
			targetDataKey={cmtPageItem.ios.answers?.id}
			renderInput={(renderProps) => {
				const { save, updateData, data } = renderProps;
				const { selectedIndexes = [], score = 0, attempts = 0 } = data;

				console.log({ renderProps });

				onBackendUpdate &&
					onBackendUpdate({
						selectedIndexes,
						correctIndexes,
					});

				// Check if completed
				if (
					(!complete && score > 0) ||
					(attempts > 0 && attempts >= pointMultiplierPerAttempt.length)
				) {
					setComplete(true);
				}

				return (
					<React.Fragment>
						<div
							className={classnames(
								programType === "slide" && Styles.topContainer,
								classNameTopContainer
							)}
						>
							{/* READ MORE */}
							{cmtPageItem.symbol_name === "io-dilemma-input-v2" &&
								cmtPageItem.read_more &&
								getText(cmtPageItem.read_more) && (
									<div
										className={classnames(
											"w-100 position-relative pb-5",
											classNameReadMore
										)}
									>
										{/* @ts-expect-error FFButton's auto generated types are wonky */}
										<FFButton
											className="col-xs-3 absolute-top absolute-right"
											onClick={onReadMoreClick}
										>
											<SpeechBubble
												variant="square"
												arrowDirection="down"
												classNameContent="p-1 px-3"
											>
												<Text textId="D7167001-86A5-4A4E-901A-E2874D23C4B5" />
											</SpeechBubble>
										</FFButton>
									</div>
								)}
							<div className={classnames("row m-0", classNameHeading)}>
								{/* HEADER */}
								<FFText
									tagName="h2"
									textId={cmtPageItem.header}
									className={classnames(
										"col-sm-12 mb-5 font-weight-bold animated fadeInUp delay-500ms"
									)}
								/>
								{/* SUB HEADER */}
								<Text
									textId={cmtPageItem.sub_header}
									className={classnames(
										"instruction col-sm-9 font-italic mb-3 animated fadeInLeft"
									)}
								/>
								{cmtPageItem.symbol_name === "io-dilemma-input-v2" &&
									cmtPageItem.image && (
										<Image
											src={cmtPageItem.image}
											maxHeight={cmtPageItem.image_max_height}
											className="mb-3"
											size={undefined}
											align={undefined}
											children={undefined}
											autoSize={undefined}
											background={undefined}
											isZoomable={undefined}
										/>
									)}
							</div>
						</div>
						<div className={classnames("row m-0")}>
							{options.map((content, index) => {
								const dilemmaPoints = content.points
									.split(",")
									.map((point) => Number(point));
								const dilemmaScoreAnsweredAtAttempt =
									selectedIndexes.indexOf(index) + 1;
								const scoreMultiplier = pointMultiplierPerAttempt.length
									? pointMultiplierPerAttempt[
											dilemmaScoreAnsweredAtAttempt - 1
									  ] || 0
									: 1;
								const dilemmaScore = dilemmaPoints[0] * scoreMultiplier;
								const dilemmaCorrect = selectedIndexes[attempts - 1] === index;
								const dilemmaSelected = selectedIndexes.includes(index);

								return (
									<DilemmaCard
										key={`dilemma-card-${index}`}
										className={classnames(
											"col-sm-6",
											programType === "slide" && Styles.dilemmaCard,
											classNameDilemmaCard
										)}
										mode={programType}
										score={dilemmaScore}
										selected={dilemmaSelected}
										correct={dilemmaCorrect}
										index={index}
										header={content.header}
										body={content.body}
										readMore={content.read_more}
										imageCorrect={cmtPageItem.graphics_correct}
										imageIncorrect={cmtPageItem.graphics_incorrect}
										imageNeutral={cmtPageItem.graphics_neutral}
										backgroundImage={content.image}
										onChooseClick={({ index }) => {
											let newselectedIndexes = [...selectedIndexes];
											newselectedIndexes.push(index);
											newselectedIndexes = _.uniq(newselectedIndexes);

											const newScoreMultiplier =
												pointMultiplierPerAttempt.length
													? pointMultiplierPerAttempt[
															newselectedIndexes.length - 1
													  ] || 0
													: 1;
											const newScore =
												dilemmaPoints[0] * newScoreMultiplier || 0;
											const oldScore = score || 0;
											const newAttempts = dilemmaSelected
												? attempts
												: newselectedIndexes.length;

											const newData = {
												selectedIndexes: newselectedIndexes,
												attempts: newAttempts,
												score: Math.max(newScore, oldScore),
											};

											if (pointCategories.length) {
												pointCategories.forEach((category, index) => {
													newData["score_" + index] =
														dilemmaPoints[index] * scoreMultiplier;
												});
											}

											updateData(newData);
											save(false, newData);
											setSelectedIndex(index);
										}}
										onReadMoreClick={onCardReadMoreClick}
									/>
								);
							})}
						</div>

						{/* READ MORE */}
						<LightBox
							className="read-more"
							show={readMore !== undefined}
							buttonType="cross"
							onCloseClick={onReadMoreCloseClickHandler}
						>
							{readMore && readMore.children}
						</LightBox>

						{/* FEEDBACK */}
						<DilemmaFeedback
							show={selectedOption}
							onCloseClick={() => {
								setSelectedIndex(-1);
							}}
							correct={selectedIndexCorrect}
							score={points[0]}
							imageCorrect={cmtPageItem.graphics_correct}
							imageIncorrect={cmtPageItem.graphics_incorrect}
							imageNeutral={cmtPageItem.graphics_neutral}
							body={selectedOption && selectedOption.feedback}
						/>
					</React.Fragment>
				);
			}}
		/>
	);
};

export default DilemmaInput;
