import classnames from "classnames";
import Button from "components/Button/Button";
import Stack from "components/Stack/Stack";
import { PageActions, PageItemsUtils, StoreUtils } from "funkis-foundation";
import Text from "funkis-template/components/core/Text";
import _ from "lodash";
import { useEffect, useState } from "react";
import { updateLocation } from "funkis-template/actions/Actions";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { handleStatusAndUnlockingNextPage } from "funkis-template/utils/pageUtils";
import { getText } from "funkis-template/utils/textUtils";

const PageItemNavigationButtons = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;
	const { content_array, header, body } = cmtPageItem;

	const [isLastPageItem, setIsLastPageItem] = useState(false);
	useEffect(() => {
		const nextPageItem = PageItemsUtils.getPageItemByPath(
			[{ sibling: 1 }],
			pageItem.id
		);
		setIsLastPageItem(!nextPageItem);
	}, [pageItem.id]);

	const [hasBeenShown, setHasBeenShown] = useState(false);

	// unlocking the pages that the buttons point to
	useEffect(() => {
		if (!hasBeenShown) return;
		const pages = StoreUtils.getReducer("pages").items;
		content_array.forEach(({ page_friendly_id }) => {
			const page = pages.find((p) => p.friendlyId === page_friendly_id);
			if (!page) {
				console.error(
					"PageItemNavigationButton, found no match for page.friendlyId",
					page_friendly_id
				);
				return;
			}
			if (page.status === "not_attempted") {
				PageActions.updatePage(page.id, { status: "unlocked" });
			}
		});
	}, [hasBeenShown]);

	// Copied from PageItemPageFooter, to keep expected behavior when Navigation buttons is used as manual PageFooter
	useEffect(() => {
		if (!(hasBeenShown && isLastPageItem && cmtPageItem)) return;

		const parentPageId = cmtPageItem.parent_id;
		// Set current page to completed
		PageActions.updatePage(parentPageId, { status: "completed" });
		// Update current chapter status if needed
		const pages = _.get(StoreUtils.getReducer("pages"), "items", []);
		const currentPage = pages.find((p) => p.id === cmtPageItem.parent_id);

		handleStatusAndUnlockingNextPage({
			cmtPage: _.get(currentPage, "data.cmtPage"),
			programType: "scroll",
		});
	}, [hasBeenShown, isLastPageItem, cmtPageItem]);

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={!isLastPageItem}
			onScrollEnterStart={() => setHasBeenShown(true)}
			renderFunction={({ viewVisible }) => {
				return (
					<div className="d-flex flex-column">
						<Text
							textId={header}
							className="navigation-buttons-header col-12 mb-4"
							tagName="h2"
						/>
						<Text
							textId={body}
							className="navigation-buttons-body p-md col-sm-8"
							tagName="div"
						/>

						<div
							className={classnames(
								"col-12 navigation-buttons-buttons-container d-flex flex-column align-items-center",
								getText(body) ? "mt-5" : getText(header) ? "mt-2" : "mt-7"
							)}
						>
							<Stack width="fit-content-width">
								{content_array.map((item, itemIndex) => (
									<Button
										variant={itemIndex === 0 ? "solid" : "outline"}
										onClick={() =>
											updateLocation({ friendlyId: item.page_friendly_id })
										}
									>
										<Text textId={item.button_text}></Text>
									</Button>
								))}
							</Stack>
						</div>
					</div>
				);
			}}
		></PageItemScrollBase>
	);
};

export default PageItemNavigationButtons;
