import React, { useEffect, useState } from "react";
import _ from "lodash";
import classnames from "classnames";
import Text from "../core/Text";
import Styles from "./MessageTexts.module.css";
import { getBootstrapColor } from "../../utils/colorUtils";
import Link from "../../components/core/Link";
import QRCode from "qrcode.react";
import { useSelector } from "react-redux";
import { QuiddityAPI } from "quiddity";
import { getLocalizedMediaPath } from "utils/MediaUtils";

const QRCodeComp = ({ className, baseQrCodeUrl, width }) => {
	const quiddityItem = useSelector((state) => _.get(state, "quiddity.item"));
	const quidditySessionKey = quiddityItem.code;
	const quiddityUrl = quiddityItem.url;
	const quiddityApiPath = quiddityItem.api;

	const [shortUrl, setShortUrl] = useState();

	/*
		Handles:
		https://mysite.com/title/package-1
		https://mysite.com/title/package-1/
		https://mysite.com/title/package-1/?existing-flag=true
		https://mysite.com/title/package-1/#some-spa-path
		https://mysite.com/title/package-1/#some-path/
		https://mysite.com/title/package-1/#some-path/?existing-flag=true

		Valid results :
		https://mysite.com/title/package-1/#?sessionKey=ABC // root path: needs trailing slash and # is added before query parameter
		https://mysite.com/title/package-1/#some-spa-path?sessionKey=ABC, // non-root path: can't have trailing slash, and no extra # should be added 
	*/
	const getTransformedUrl = (baseQrCodeUrl, quidditySessionKey) => {
		if (!quidditySessionKey) return baseQrCodeUrl;
		let url = baseQrCodeUrl;
		const regExpQueryParamsString = /(\?.*)/;
		const [existingQueryParamsString] = regExpQueryParamsString.exec(url) || [];
		const queryParams = new URLSearchParams(existingQueryParamsString);
		queryParams.set("sessionKey", quidditySessionKey);
		url = url.replace(regExpQueryParamsString, "");

		const includesHash = url.includes("#");
		const endsWithSlash = url[url.length - 1] === "/";

		// only add slash to root path (where index.html is)
		const shouldAddSlash = !includesHash && !endsWithSlash;
		const shouldRemoveEndHash = includesHash && endsWithSlash;
		if (shouldRemoveEndHash) {
			// paths like  mysite/#/my-spa-page/ is not handled correctly, so we remove the trailing slash
			url = url.slice(0, url.length - 1);
		}

		const shouldAddHash = !includesHash;

		url += `${shouldAddSlash ? "/" : ""}${
			shouldAddHash ? "#" : ""
		}?${queryParams.toString()}`;
		return url;
	};
	const url = getTransformedUrl(baseQrCodeUrl, quidditySessionKey);

	useEffect(() => {
		if (url && !shortUrl) {
			const prefix = quidditySessionKey ? `${quidditySessionKey}-` : "";
			QuiddityAPI.shortURL(quiddityUrl + quiddityApiPath, {
				sourceUrl: url,
				prefix: prefix,
				usePrefixOnly: false,
			}).then((resp) => {
				const { shortUrl_v2 } = resp || { shortUrl_v2: url };
				setShortUrl(shortUrl_v2);
			});
		}
	}, [url, shortUrl]);

	// max-height because else it may push the text above the allowed area
	console.log("Showing qr code for url", url);
	const parsedUrl = new URL(shortUrl || url);
	const URLPartComponent = ({ children, part }) => (
		<div className={classnames(`qr-code-url part-${part}`, Styles.qrCodeText)}>
			{children}
		</div>
	);
	return (
		<div
			className={classnames(className, Styles.qrCode)}
			style={{ minWidth: width, height: 300 }}
		>
			<QRCode value={parsedUrl.href} renderAs="svg" size="100%" />
			<div
				style={{ cursor: "pointer" }}
				className="qr-code-url-wrapper d-flex flex-column align-items-center mt-3"
				onClick={() => window.open(parsedUrl.href, "_blank")}
			>
				<URLPartComponent part="origin">{parsedUrl.host}</URLPartComponent>
				<URLPartComponent part="pathname">
					{parsedUrl.pathname + (parsedUrl.search || "")}
				</URLPartComponent>
			</div>
		</div>
	);
};

const getForegroudImageComponent = ({ qrCodeUrl, imageSrc, imagePosition }) => {
	const className = classnames(
		"message-texts-foreground-image",
		imagePosition === "left" && "mr-6",
		imagePosition === "right" && "ml-6",
		imagePosition === "top" && "mb-6"
	);
	const widthByImagePosition = {
		top: "auto",
		bottom: "auto",
		left: "624px",
		right: "624px",
	};
	const heightByImagePosition = {
		top: "136px",
		bottom: "136px",
		left: "590px",
		right: "590px",
	};
	const width = widthByImagePosition[imagePosition];
	const height = heightByImagePosition[imagePosition];
	if (!qrCodeUrl) {
		const localizedImageSrc = getLocalizedMediaPath(imageSrc);
		return (
			<img
				src={localizedImageSrc}
				style={{ width: width, height: height }}
				className={className}
			/>
		);
	} else {
		return (
			<QRCodeComp
				baseQrCodeUrl={qrCodeUrl}
				className={className}
				width={width}
			/>
		);
	}
};

// Default values based on "Slide Discussion"
const MessageTexts = ({
	headerTextId,
	bodyTextId,
	imageSrc,
	qrCodeUrl,
	imagePosition = "right",
	headerSize = "xl",
	bodySize = "sm",
	headerAlign = "left",
	bodyAlign = "left",
	headerColor = "black",
	bodyColor = "black",
	headerMargin = "0 0 36px",
	height = "1000px",
	textContainerWidth = "1472px",
	textContainerPadding = "48px 64px",
	textContainerColor = "black",
	textContainerOpacity = 0,
	className,
	classNameHeader,
	classNameBody,
	style,
	links = [],
	children,
	childrenBackground,
}) => {
	return (
		//  OUTER CONTAINER
		<div
			className={classnames(Styles.outerContainer, className)}
			style={{ height, ...style }}
		>
			{/* INNER CONTAINER */}
			<div className={Styles.innerContainer}>
				{/* BACKGROUND COLOR */}
				<div
					className={classnames("absolute-top w-100 h-100")}
					style={{
						opacity: textContainerOpacity,
						backgroundColor: getBootstrapColor(textContainerColor),
					}}
				/>
				{childrenBackground}
				{/* TEXT CONTAINER */}
				<div
					className={Styles.textContainer}
					style={{ width: textContainerWidth, padding: textContainerPadding }}
				>
					{/* HEADER */}
					<Text
						textId={headerTextId}
						className={classnames(
							"w-100",
							`message-${headerSize} text-${headerAlign} d-block`,
							classNameHeader
						)}
						style={{
							margin: headerMargin,
							color: getBootstrapColor(headerColor),
						}}
					/>
					{/* BODY */}
					<div
						className={classnames(
							"d-flex",
							imagePosition === "right" && Styles.imagePosRight,
							imagePosition === "left" && Styles.imagePosLeft,
							imagePosition === "top" && Styles.imagePosTop,
							imagePosition === "bottom" && Styles.imagePosBottom
						)}
					>
						<Text
							textId={bodyTextId}
							className={classnames(
								"w-100",
								`message-${bodySize} text-${bodyAlign} d-block`,
								classNameBody
							)}
							style={{ color: getBootstrapColor(bodyColor) }}
						/>

						{/* LINKS */}
						{links.length > 0 && (
							<ul className={"list-style-none"}>
								{links.map((link, index) => (
									<li
										key={`links-${index}`}
										className="mb-3"
										style={{
											animationDelay: 0.5 + index * 0.15 + "s",
											pointerEvents: "auto",
										}}
									>
										<Link
											classNameHeader="p-lg text-decoration-none"
											classNameBullet="mr-5"
											href={link.url}
											icon={link.icon}
											header={link.header}
											body={link.body}
											textColor={bodyColor}
											modal={false}
										/>
									</li>
								))}
							</ul>
						)}
						{/* FOREFROUND IMAGE */}
						{(imageSrc || qrCodeUrl) &&
							getForegroudImageComponent({
								qrCodeUrl,
								imageSrc,
								imagePosition,
							})}
					</div>
					{children}
				</div>
			</div>
		</div>
	);
};

export default MessageTexts;
