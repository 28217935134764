import { get, mapValues, isEqual } from "lodash";
import { useSelector } from "react-redux";
import { TextId } from "funkis-template/models/player";

const selectTextById = (state: any, textId: string, fallback?: string) =>
	get(state, ["texts", "itemsById", textId, "value"], fallback);

export function useText(textId?: null): "";
export function useText<T = undefined>(textId?: null, fallback?: T): T | "";
export function useText<T = undefined>(
	textId: TextId,
	fallback?: T
): string | T;
export function useText<T = undefined>(
	textIds: TextId[],
	fallback?: T
): (string | T)[];
export function useText<
	KeyType extends string | number | symbol,
	T = undefined
>(textObj: Record<KeyType, TextId>, fallback?: T): Record<KeyType, string | T>;

export function useText(someTextIds: unknown, fallback?: string): unknown {
	return useSelector((state) => {
		const isNullish = someTextIds === undefined || someTextIds === null;
		if (isNullish && fallback) {
			return fallback;
		} else if (isNullish && !fallback) {
			return "";
		} else if (typeof someTextIds == "string") {
			const textId = someTextIds;
			return selectTextById(state, textId, fallback);
		} else if (Array.isArray(someTextIds)) {
			return someTextIds.map((textId) =>
				selectTextById(state, textId, fallback)
			);
		} else if (typeof someTextIds == "object") {
			return mapValues(someTextIds, (textId) =>
				selectTextById(state, textId, fallback)
			);
		}
	}, isEqual); // _.isEquals provides referential stability for the _consumer_ of the hook, even if the selector logic runs with every redux update
}
