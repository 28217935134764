import classnames from "classnames";
import FormattedDate from "components/FormattedDate/FormattedDate";
import { StoreUtils } from "funkis-foundation";
import SelectedIcon from "funkis-template/components/core/SelectedIcon";
import {
	getBootstrapColor,
	getBootstrapOverridesForPresentValues,
} from "funkis-template/utils/colorUtils";
import _ from "lodash";
import { getGroup, getMembers } from "quiddity";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const SelectedIconStyled = styled(SelectedIcon)`
	position: absolute;
	top: 12px;
	right: 30px;
`;

const IOCommentOutput = (props) => {
	const {
		className,
		backgroundColor = "light-gray",
		barColor = "primary",
		textColor = "black",
		data,
		header,
		quidInput,
		showUser = true,
		showDate = true,
		showSelectionIcon = false,
		...rest
	} = props;

	/**
	Quiddy api will only be used when quidinput has a member or a group associated
	with it. Therefore it won't be used when the backend is "lms" since the quid-inputs
	never gets a group/member value.
	**/
	const quiddity = StoreUtils.getReducer("quiddity").item;
	const { url, api } = quiddity;

	const member = useSelector(
		(state) => quidInput && state.members.itemsById[quidInput.member]
	);
	const user = _.get(quidInput, "user") ?? _.get(member, "user");
	const group = useSelector(
		(state) => quidInput && state.groups.itemsById[quidInput.group]
	);

	useEffect(() => {
		if (!user || typeof user === "string") {
			getMembers(url + api, {
				id: quidInput.member,
				quidditySession: quidInput.quidditySession,
				populate: "user",
			});
		}
	}, [user, quidInput.member, quidInput.quidditySession, url, api]);

	useEffect(() => {
		if (!group && quidInput.group) {
			getGroup(url + api, {
				id: quidInput.group,
				quidditySession: quidInput.quidditySession,
			});
		}
	}, [quidInput.group, quidInput.quidditySession, group, url, api]);

	const firstName = (user && user.firstName) || "";
	const lastName = (user && user.lastName) || "";
	const name =
		firstName || lastName
			? `${firstName} ${lastName}`
			: group?.name
			? group.name
			: "";

	return (
		<div
			className={className}
			style={{ color: getBootstrapColor(textColor) }}
			{...rest}
		>
			<div
				className={classnames(
					"w-100 d-flex flex-column pt-3 pb-3 background",
					!showSelectionIcon ? "px-3" : "pl-3"
				)}
				style={{
					borderBottom: `5px solid ${getBootstrapColor(barColor)}`,
					...getBootstrapOverridesForPresentValues({
						backgroundColor: backgroundColor,
					}),
					...(showSelectionIcon && { paddingRight: 45 }), // offset to not overlap selection icon with text
				}}
			>
				{(data.header || header) && (
					<h4 className="h4-sm mb-1 font-weight-bold">
						{header || data.header}
					</h4>
				)}
				<span className="mb-2 p-medium" style={{ whiteSpace: "pre-line" }}>
					{data.body || data.header}
				</span>
				<span className="p-small font-italic">
					{showUser && name}
					{showUser && name && showDate && ", "}
					{showDate && (
						<FormattedDate date={quidInput.updatedAt ?? quidInput.createdAt} />
					)}
				</span>
				{showSelectionIcon && <SelectedIconStyled show={data.selected} />}
			</div>
		</div>
	);
};

export default IOCommentOutput;
