import React, { useEffect, useRef } from "react";
import { FFText } from "funkis-foundation";
import { TweenMax, TimelineMax } from "gsap";
import classnames from "classnames";
import IconCheck from "../../assets/svg/IconCheck";
import IconCheckIncorrect from "../../assets/svg/IconCheckIncorrect";
import { getBootstrapColor } from "../../utils/colorUtils";
import Style from "./RadioItem.module.css";
import Hover from "./Hover";

const IconDot = ({ className, color }) => {
	return (
		<div
			className={classnames(Style.iconDot, className)}
			style={{ background: color }}
		/>
	);
};

const RadioItem = ({
	className,
	textId,
	text,
	selected,
	index = 0,
	correctDelay = 0.6,
	status,
	style,
	completed,
	forceHover,
}) => {
	const refIcon = useRef(null);
	const refOuterCircle = useRef(null);

	useEffect(() => {
		if (!completed) {
			return;
		}
		const delay = correctDelay * index;
		switch (status) {
			case "correct":
			case "incorrect":
				const incorrectTimeLine = new TimelineMax();
				incorrectTimeLine.to(refIcon.current, 0.4, {
					scale: 3,
					delay,
					ease: "bounce.out",
				});
				incorrectTimeLine.to(refIcon.current, 0.4, { scale: 1 });
				incorrectTimeLine.play();
				break;
			case "missed":
				const missedTimeLine = new TimelineMax();
				missedTimeLine.to(refIcon.current, 0.4, {
					opacity: 1,
					scale: 2,
					delay,
					ease: "bounce.out",
				});
				missedTimeLine.to(refIcon.current, 0.4, { opacity: 0.5, scale: 1 });
				missedTimeLine.play();
				break;
			default:
				TweenMax.to(refOuterCircle.current, 0.4, { opacity: 0.5, delay });
		}
	}, [status, completed]);

	useEffect(() => {
		if (selected) {
			TweenMax.fromTo(
				refIcon.current,
				0.4,
				{ scale: 3 },
				{ scale: 1, ease: "bounce.out" }
			);
		}
	}, [selected]);

	const iconColor =
		(status === "correct" && "success") ||
		(status === "incorrect" && "danger") ||
		(status === "missed" && "success") ||
		(selected && "gray") ||
		"transparent";

	const innerCircleColor =
		(status === "missed" && "success") ||
		(completed && "transparent") ||
		(selected && "black") ||
		"gray";

	const Icon =
		(status === "correct" && IconCheck) ||
		(status === "incorrect" && IconCheckIncorrect) ||
		IconDot;

	return (
		<Hover
			className={classnames(
				Style.root,
				selected && Style.rootSelected,
				className
			)}
			style={style}
		>
			{({ hover }) => {
				const isHovering = hover || forceHover;
				return (
					<React.Fragment>
						<div
							ref={refOuterCircle}
							className={classnames(Style.circleOuter)}
							style={{
								borderColor: getBootstrapColor(
									isHovering ? "interaction" : "gray"
								),
							}}
						>
							<div
								ref={refIcon}
								className={classnames(Style.circleInner)}
								style={{
									borderColor: getBootstrapColor(
										isHovering ? "interaction" : innerCircleColor
									),
									opacity: selected ? 1 : 0.5,
								}}
							>
								<div>
									<Icon
										color={getBootstrapColor(iconColor)}
										style={{ marginTop: status === "incorrect" ? "-2px" : 0 }}
										scale={1.2}
									/>
								</div>
							</div>
						</div>
						<FFText
							textId={textId}
							placeholder={text}
							className={classnames(
								"option ml-2 font-weight-bold",
								isHovering && "text-underline"
							)}
							style={{
								color: getBootstrapColor(isHovering ? "interaction" : "black"),
							}}
						/>
					</React.Fragment>
				);
			}}
		</Hover>
	);
};

export default RadioItem;
