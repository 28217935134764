import classnames from "classnames";
import { TextUtils } from "funkis-foundation";
import { useText } from "funkis-template/hooks/text";
import { TextId } from "funkis-template/models/player";
import _ from "lodash";
import React, { useMemo } from "react";
import { Backend, QuidInput, QuidType, useBackend } from "../../hooks/backend";
import { useDevSettings } from "../../hooks/player";
import { useValueListItems } from "../../hooks/valuelist";
import {
	getIOTextTemplateVariables,
	getText,
	IOTextTemplateVariable,
	stripHTMLTags,
} from "../../utils/textUtils";
import BalancedText from "./Text/BalancedText";
import Style from "./Text/Text.module.css";
import { useGlobalTextVariables } from "./Text/Text.utils";

const IO_TEMPLATE_LOADING = "___________";
const IO_TEMPLATE_ERROR = "___________";

export type TextTone =
	| "caution"
	| "critical"
	| "positive"
	| "brandAccent"
	| "info"
	| "neutral"
	| "promote";

type TextVariableValue = string | number;
type TextVariableComplex = {
	value: TextVariableValue;
	className: string;
};

type TextVariable = TextVariableValue | TextVariableComplex;
export type TextVariables = Record<string, TextVariable>;

type TextProps = {
	textId: TextId;
	tagName?: React.ElementType;
	style?: React.CSSProperties;
	className?: string;
	variables?: TextVariables;
	hideIfMissing?: boolean;
	placeholder?: string;
	balance?: boolean;

	tone?: TextTone;
};

const useQuidInputValue = (
	quidInputs: QuidInput[],
	ioTextItem: IOTextTemplateVariable
): string => {
	const quidInputIndex = ioTextItem.index ? ioTextItem.index - 1 : 0;

	switch (ioTextItem.quidType) {
		case QuidType.ContentSelection:
			const selectionIds = _.chain(quidInputs)
				.sortBy("updatedAt")
				.map("data.contentItemId")
				.value();
			const contentArrayItems = useValueListItems(selectionIds);
			const textId = _.get(contentArrayItems, `[${quidInputIndex}].option`);
			return getText(textId) ?? IO_TEMPLATE_ERROR;

		default:
			return _.get(
				quidInputs,
				`[${quidInputIndex}].data.${ioTextItem.data}`,
				IO_TEMPLATE_ERROR
			);
	}
};

type IOTextBackend = {
	backend: Backend;
	item: IOTextTemplateVariable;
};

const useIOTemplateString = (text: string): string => {
	const prevText = text;

	const ioTextTemplateVariables = getIOTextTemplateVariables(prevText);
	const textBackends: Record<string, IOTextBackend> =
		ioTextTemplateVariables.reduce(
			(acc, ioTextItem): Record<string, IOTextBackend> => ({
				...acc,
				[ioTextItem.originalTemplateString]: {
					backend: useBackend({
						targetDataKey: ioTextItem.ioId,
						inputContext: ioTextItem.ioContext,
						quidType: ioTextItem.quidType,
					}),
					item: ioTextItem,
				},
			}),
			{}
		);

	const nextText = Object.keys(textBackends).reduce(
		(nextText, originalTemplateString) => {
			const textBackend: IOTextBackend = textBackends[originalTemplateString];
			const { isLoading, quidInputs } = textBackend.backend;

			const quidInputValue = useQuidInputValue(quidInputs, textBackend.item);
			const value = isLoading ? IO_TEMPLATE_LOADING : quidInputValue;

			return nextText.replace(originalTemplateString, value);
		},
		prevText
	);

	return nextText;
};

const composeStringFromVariables = (
	text: string,
	variables: Record<string, any>
): string => {
	return TextUtils.spliceVariables(text, variables);
};

const Text: React.FC<TextProps> = ({
	textId,
	tagName: CustomTag = "span",
	className,
	style,
	variables,
	hideIfMissing = true,
	placeholder,
	balance,
	tone,
}) => {
	const { showMissinTextWarnings } = useDevSettings();

	const rawText = useText(textId) ?? placeholder ?? "";
	let templatedText = useIOTemplateString(rawText);

	const globalVariables = useGlobalTextVariables();

	templatedText = useMemo(
		() =>
			composeStringFromVariables(templatedText, {
				...globalVariables,
				...variables,
			}),
		[templatedText, globalVariables, variables]
	);

	const isEmpty = _.isEmpty(stripHTMLTags(templatedText));

	if (isEmpty && hideIfMissing) {
		return <React.Fragment />;
	} else if (isEmpty && showMissinTextWarnings) {
		templatedText = `<span style="font-size:10px;color:red">Text is missing: "${textId}"</span>`;
	}

	return balance ? (
		<BalancedText
			tagName={CustomTag}
			className={classnames(
				className,
				Style.text,
				tone && Style[tone],
				"ff-text",
				"ff-text-balanced"
			)}
			style={style}
			text={templatedText}
		/>
	) : (
		<CustomTag
			className={classnames(
				className,
				Style.text,
				tone && Style[tone],
				"ff-text"
			)}
			style={style}
			dangerouslySetInnerHTML={{ __html: templatedText }}
			data-text-id={textId}
			data-variables={variables && JSON.stringify(variables)}
		/>
	);
};

export default Text;
