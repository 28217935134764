import classNames from "classnames";
import { AllHTMLAttributes, forwardRef, ReactNode } from "react";
import Styles from "./Button.module.css";

type NativeButtonProps = AllHTMLAttributes<HTMLButtonElement>;

type ButtonSize = "standard" | "small";
type ButtonVariant = "solid" | "inverted" | "overlay" | "outline";
export type ButtonProps = {
	id?: NativeButtonProps["id"];
	onClick?: NativeButtonProps["onClick"];
	children?: ReactNode;
	tabIndex?: NativeButtonProps["tabIndex"];
	loading?: boolean;
	disabled?: boolean;
	size?: ButtonSize;
	variant?: ButtonVariant;
	fluid?: boolean;
	className?: string;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			onClick,
			id,
			children,
			disabled,
			loading,
			size = "standard",
			variant = "solid",
			fluid,
			className,
		},
		ref
	) => {
		return (
			<button
				ref={ref}
				id={id}
				disabled={loading || disabled}
				className={classNames(
					Styles.resetButton,
					Styles.commonButton,
					{
						[Styles.standardSizeButton]: size === "standard",
						[Styles.smallSizeButton]: size === "small",
						[Styles.solidButton]: variant === "solid",
						[Styles.invertedButton]: variant === "inverted",
						[Styles.outlineButton]: variant === "outline",
						[Styles.fluidButton]: fluid,
					},
					className
				)}
				onClick={onClick}
			>
				{children}
			</button>
		);
	}
);

export default Button;
