import React, { FC } from "react";
import classnames from "classnames";
import _ from "lodash";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";

import Style from "./Links.module.css";
import Hover from "../../../components/core/Hover";
import Link, { Wrapper as LinkWrapper } from "../../../components/core/Link";
import Text from "../../../components/core/Text";
import { getText } from "../../../utils/textUtils";
import { PageItem } from "../../../models/player";
import { LinksScrollBlock } from "../../../models/pageitem";
import Media, { MediaCrop, MediaFit } from "../../../components/core/Media";
import { getBootstrapColor } from "../../../utils/colorUtils";
import { useViewportSize, ViewportSize } from "../../../hooks/player";

const LinksList: FC<{
	viewVisible: boolean;
	pageItem: PageItem<LinksScrollBlock>;
}> = ({ viewVisible, pageItem: { cmtPageItem } }) => {
	return (
		<div className="col-11">
			<ul className={"list-style-none p-0 w-100"}>
				{cmtPageItem.content_array.map((link, index) => {
					const hasIcon = link.icon || cmtPageItem.icon;
					const hasBody = getText(link.body);
					const hasHeader = getText(link.header);
					const hasFile = _.endsWith(link.icon || "", ".pdf");
					// edge case where Links block is used more like Bullets
					const shouldDisableAnchorStyling = !getText(link.url) && !hasFile;
					const icon = !hasFile
						? link.icon || cmtPageItem.icon
						: cmtPageItem.icon;
					return (
						<li
							key={`links-${index}`}
							className={classnames(
								"d-flex align-items-center animated",
								Style.ListItem,
								shouldDisableAnchorStyling && Style.DisabledAnchorStyling,
								viewVisible ? "fadeInUp" : "fadeOut",
								hasBody && hasIcon && "mb-3"
							)}
							style={{ animationDelay: 0.5 + index * 0.15 + "s" }}
						>
							<Link
								className="text-decoration-none"
								classNameHeader="p-medium font-weight-bold text-decoration-underline"
								classNameBody={classnames(
									"p-medium",
									!hasHeader
										? "text-decoration-underline"
										: "text-decoration-none"
								)}
								bulletSize={cmtPageItem.icon_size}
								href={link.url}
								icon={icon}
								file={hasFile && link.icon}
								header={link.header}
								body={link.body}
								classNameTextContainer={undefined}
								classNameBullet={undefined}
							/>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

const LinksGrid: FC<{
	viewVisible: boolean;
	pageItem: PageItem<LinksScrollBlock>;
}> = ({ viewVisible, pageItem: { cmtPageItem } }) => {
	const viewportSize = useViewportSize();

	return (
		<div className="col-12">
			<ul
				className={classnames("list-style-none p-0 w-100 links links--grid", {
					[`${Style.GridSm} links--grid--sm`]:
						viewportSize <= ViewportSize.MobileSmall,
					[`${Style.GridLg} links--grid--lg`]:
						viewportSize > ViewportSize.MobileSmall,
				})}
			>
				{cmtPageItem.content_array.map((link, index) => {
					const hasFile = _.endsWith(link.icon || "", ".pdf");
					// edge case where Links block is used more like Bullets
					const shouldDisableAnchorStyling = !getText(link.url) && !hasFile;
					const media = link.icon ?? cmtPageItem.icon;

					return (
						<li
							key={`links-${index}`}
							className={classnames(
								"animated",
								shouldDisableAnchorStyling && Style.DisabledAnchorStyling,
								viewVisible ? "fadeInUp" : "fadeOut"
							)}
							style={{ animationDelay: 0.5 + index * 0.15 + "s" }}
						>
							<LinkWrapper
								url={getText(link.url)}
								modal={true}
								className={classnames("text-decoration-none links__item")}
							>
								<Hover className={undefined} style={undefined}>
									{({ hover }) => (
										<React.Fragment>
											<Media
												src={media}
												mediaFit={MediaFit.FullWidth}
												mediaCrop={MediaCrop.ResizeToFit}
												className="mb-1 links__item__media"
											/>
											<Text
												tagName="p"
												className="font-weight-bold links__item__heading"
												style={{
													color: hover
														? getBootstrapColor("interaction")
														: "inherit",
													textDecoration: hover ? "underline" : "unset",
												}}
												textId={link.header}
												hideIfMissing={true}
											/>
											<Text
												tagName="p"
												className="links__item__body"
												textId={link.body}
												style={{
													color: hover
														? getBootstrapColor("interaction")
														: "inherit",
												}}
												hideIfMissing={true}
											/>
										</React.Fragment>
									)}
								</Hover>
							</LinkWrapper>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

const PageItemLinks: FC<{ pageItem: PageItem<LinksScrollBlock> }> = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;

	return (
		<PageItemScrollBase
			{...props}
			className="overflow-hidden"
			showNextButton={true}
			renderFunction={(renderProps: { viewVisible: boolean }) => (
				<div className="row m-0">
					{/* HEADER */}
					<Text
						className={classnames(
							"col-12 mb-5 font-weight-bold animated",
							renderProps.viewVisible ? "fadeIn" : "fadeOut"
						)}
						tagName="h2"
						textId={cmtPageItem.header}
					/>
					{/* BODY */}
					<Text
						className={classnames(
							"col-12 mb-5 mb-sm-4 animated",
							renderProps.viewVisible ? "dealy-250ms fadeIn" : "fadeOut"
						)}
						tagName="p"
						textId={cmtPageItem.body}
					/>
					{/* INSTRUCTION */}
					<Text
						className={classnames(
							"instruction font-italic col-12 mb-5 mb-sm-4 animated",
							renderProps.viewVisible ? "dealy-250ms fadeIn" : "fadeOut"
						)}
						textId={cmtPageItem.instruction}
					/>
					{/* LINKS */}
					{(() => {
						switch (cmtPageItem.item_layout) {
							case "List":
								return (
									<LinksList
										viewVisible={renderProps.viewVisible}
										pageItem={pageItem}
									/>
								);

							case "Grid":
								return (
									<LinksGrid
										viewVisible={renderProps.viewVisible}
										pageItem={pageItem}
									/>
								);
						}
					})()}
				</div>
			)}
		/>
	);
};

export default PageItemLinks;
