import React from "react";
import classnames from "classnames";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import Backend from "../../components/backend/Backend";
import Text from "../../components/core/Text";
import { getBootstrapColor } from "../../utils/colorUtils";
import IconPostIt from "../../assets/svg/IconPostIt";

const PageItemIOPostItOutput = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;
	return (
		<PageItemScrollBase
			{...props}
			containerClassName="w-100"
			showNextButton={cmtPageItem.show_next_button !== "no"}
			renderFunction={(renderProps) => {
				const { io_id } = cmtPageItem;
				return (
					<Backend
						className={classnames("container-fluid")}
						pageItem={pageItem}
						quidType="postIt"
						mode="bareOutputs"
						targetDataKey={io_id}
						match={{ targetDataKey: io_id }}
						renderFunction={(backendRenderProps) => {
							const { quidInputs } = backendRenderProps;
							return (
								<React.Fragment>
									{/* HEADER */}
									<Text
										className={classnames(
											"col-12 mb-5 font-weight-bold animated",
											renderProps.viewVisible ? "fadeIn" : "fadeOut"
										)}
										tagName="h2"
										textId={cmtPageItem.header}
									/>
									{/* BODY */}
									<Text
										className="col-md-10 mb-4"
										tagName="p"
										textId={cmtPageItem.body}
									/>

									{/* POST-ITS */}
									<div className="row m-0">
										{quidInputs.map((quidInput) => (
											<div className="col-sm-6 mb-5">
												<IconPostIt
													color={getBootstrapColor(
														cmtPageItem.post_it_color || "primary"
													)}
												/>
												<p className="p-sm">{quidInput.data.body}</p>
											</div>
										))}
									</div>
									{/* HR */}
									<div className="row m-0">
										<div className="col-12 mb-5">{/* <hr /> */}</div>
									</div>
								</React.Fragment>
							);
						}}
					></Backend>
				);
			}}
		/>
	);
};

export default PageItemIOPostItOutput;
