import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";

import { FFText, FFMultiSelect } from "funkis-foundation";
import SpeechBubble from "../SpeechBubble2";
import RadioItem from "../RadioItem";
import TextWriter from "../TextWriter";
import { getBootstrapColor } from "../../../utils/colorUtils";
import Image from "../Image";
import styled from "styled-components";
import { getText } from "../../../utils/textUtils";
import Text from "../Text";

const StyledTitle = styled(Text)`
	p {
		font-weight: inherit;
		font-size: inherit;
		line-height: inherit;
	}
`;

const DialogSprig = (props) => {
	const {
		sprigId,
		sprigStory,
		exits,
		onExitClick,
		onShow,
		onShowExit,
		selectedExitId,
		paragraphBackgroundColor,
		exitBackgroundColor,
		paragraphTextColor,
		exitTextColor,
		animate,
		scrollIntoView = true,
		showParagraphTitles = false,
		titleId,
	} = props;
	const sprig = sprigStory.find((sp) => sp.id === sprigId);
	const [showExit, setShowExit] = useState(false);
	const media = sprig.media && sprig.media !== "" && sprig.media;
	const ref = useRef(null);

	useEffect(() => {
		if (animate) {
			onShow && onShow(props);
			scrollIntoView &&
				ref.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
					inline: "nearest",
				});
		}
	}, [animate]);

	useEffect(() => {
		if (showExit) {
			onShowExit && onShowExit(props);
			scrollIntoView &&
				ref.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
					inline: "nearest",
				});
		}
	}, [showExit]);

	const shouldHideExits = animate && !showExit;
	const hasTitleText = getText(sprig.txt_title_id);
	return (
		<div ref={ref} className="row m-0 mb-1 align-items-end type-dialog-sprig">
			{/* Title  */}
			{showParagraphTitles && hasTitleText && (
				<StyledTitle
					tagName="h4"
					className={classnames("font-weight-bold col-12 mt-4 mb-3", {
						"animated fadeIn faster": !selectedExitId,
					})}
					textId={titleId || sprig.txt_title_id}
				/>
			)}
			{/* PARAGRAPH */}
			<SpeechBubble
				className={classnames(
					"col-md-6 mb-4 sprig-paragraph",
					animate && "animated fadeInUp",
					media && "align-self-start"
				)}
				classNameContent="pt-4 pb-5 pl-4 pr-4 px-4"
				color={paragraphBackgroundColor}
				arrowDirection="downLeft"
				borderRadius="19px"
			>
				<TextWriter
					textId={sprig.txt_story_id}
					className="p-md"
					enabled={animate}
					startDelay={500}
					style={{ color: getBootstrapColor(paragraphTextColor) }}
					onStart={() => {
						setShowExit(false);
					}}
					onComplete={() => {
						setShowExit(true);
					}}
				/>
			</SpeechBubble>
			{/* MEDIA */}
			{media && (
				<Image src={media} className="col-md-6 my-4 mt-sm-0 align-self-start" />
			)}
			{media && <div className="col-md-6 mb-4" />}
			{/* EXIT */}
			<SpeechBubble
				className={classnames(
					"col-md-6 ml-6 ml-md-0 mb-5 mb-sm-4 sprig-exit mt-3 mt-mb-0",
					animate && "animated",
					showExit && "flipInY",
					shouldHideExits && "opacity-0 pointer-events-none",
					!exits.length && "d-none"
				)}
				classNameContent="pt-4 pb-3 pl-4 pr-4 px-4"
				color={exitBackgroundColor}
				arrowDirection="downRight"
				borderRadius="19px"
			>
				<FFMultiSelect
					maxSelections={1}
					minSelections={0}
					selectedIndexes={[]}
					buttonClassName="d-flex align-items-center mb-4"
					onSelect={(indexes) => {
						const index = indexes[0];
						const exit = exits[index];
						onExitClick({ sprig, exit });
					}}
					enabled={!shouldHideExits}
				>
					{exits.map((exit, index) => (
						<React.Fragment key={`sprig-item-${exit.id}`}>
							<RadioItem
								selected={exit.id === selectedExitId}
								className={classnames(
									"align-self-start",
									selectedExitId && selectedExitId !== exit.id && "opacity-50"
								)}
							/>
							<FFText
								textId={exit.text_id}
								placeholder={exit.text_string}
								style={{ color: getBootstrapColor(exitTextColor) }}
								className={classnames(
									"p-md font-italic",
									selectedExitId && selectedExitId !== exit.id && "opacity-50"
								)}
							/>
						</React.Fragment>
					))}
				</FFMultiSelect>
			</SpeechBubble>
		</div>
	);
};

export default DialogSprig;
