import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as LoginActions from "../actions/LoginActions";
import {
	MEMBER_GROUP_LOGIN,
	UNIT_USER_GROUP_LOGIN,
} from "../constants/LoginTypes";
import { updateQuiddity } from "../redux/actions/QuiddityActions";
import { setStore } from "../utils/StoreUtils";
import LoginErrorDialog from "./LoginErrorDialog";

const LOGIN_TYPE = {
	user: "USER",
	session: "SESSION",
};

const Provider = (props) => {
	const {
		enabled = true,
		store,
		url = "",
		api = "/api",
		st,
		socketUrl,
		code,
		loginUrl,
		loginType = LOGIN_TYPE.none,
		onLoginSuccess,
		onLoginFail,
		autoAttemptMicrosoftLogin = false,
		children,
	} = props;
	const [showLoginUserDialog, setShowLoginUserDialog] = useState();
	const [
		showAnonymousUserFromLoginDialog,
		setShowAnonymousUserFromLoginDialog,
	] = useState();
	const [showLoginError, setShowLoginError] = useState(false);

	const app = useSelector((state) => {
		return state.app.item;
	});

	if (!enabled) {
		return children;
	}
	// Set store and update quidity reducer...
	useEffect(() => {
		if (store) {
			setStore(store);
			updateQuiddity({ enabled, url, api, st, socketUrl, code, loginType });
		} else {
			throw new Error("Store must be provided to Provider!");
		}
	}, [store]);

	// Redirect if not logged in...

	const { item: reduxLocationItem, pastItems: reduxPastLocationItems } =
		useSelector((state) => _.get(state, "location"));

	useEffect(() => {
		if (showLoginUserDialog) {
			const getLoginUrlWithParams = () => {
				const currentUrl = window.location.href;
				const hasExistingSearchParams = currentUrl.indexOf("?") !== -1;
				// keep existing flags, like "lang=sv-SE"
				const searchParams = new URLSearchParams(
					hasExistingSearchParams
						? currentUrl.slice(currentUrl.indexOf("?"))
						: ""
				);
				searchParams.set("sessionKey", code);
				// recover params that may have been lost from URL in auto-navigations
				const reduxSearchParamObjects = [
					reduxLocationItem,
					...reduxPastLocationItems,
				].map((locationItem) => locationItem.query || {});
				const reduxSearchParams = Object.assign(
					{},
					...reduxSearchParamObjects.reverse()
				); // reverse to have the latest params overide the previous
				// email is a special case that is relevant to funkis-admin (for pre-filling email field), and not only in the redirectUrl
				const email = "" + _.get(reduxSearchParams, "email", "");
				delete reduxSearchParams["sessionKey"]; // "code" prop handles this already and takes more factors into account than just URL params
				Object.entries(reduxSearchParams).forEach(([key, value]) => {
					searchParams.set(key, value);
				});
				const currentUrlWithoutSearchParams = hasExistingSearchParams
					? currentUrl.slice(0, currentUrl.indexOf("?"))
					: currentUrl;
				// encodeURIComponent to escape "#" and other characters that may confuse parsing of redirectUrl in fukis-admin
				const urlSafeReturnLocation = encodeURIComponent(
					currentUrlWithoutSearchParams + "?" + searchParams.toString()
				);
				const redirectUrl =
					loginUrl +
					`?redirect=${urlSafeReturnLocation}` +
					(email ? `&email=${email}` : "");
				return redirectUrl;
			};

			window.location.href = getLoginUrlWithParams();
		}
	}, [showLoginUserDialog]);

	const handleLoginFail = () => {
		onLoginFail && onLoginFail();
		setShowLoginError(true);
	};

	// Login...
	useEffect(() => {
		switch (loginType) {
			case MEMBER_GROUP_LOGIN:
				LoginActions.loginMemberGroup({
					url,
					api,
					code,
					onLoginSuccess,
					onLoginFail: handleLoginFail,
					getUserFromLoginDialog,
					allowAutoUpgradeOfUser: false,
					allowMissingGroup: app.program_type !== "slide"
				});
				break;
			case UNIT_USER_GROUP_LOGIN:
				LoginActions.loginUnitUserAndGroup({
					url,
					api,
					code,
					onLoginSuccess,
					onLoginFail,
					getUserFromLoginDialog,
				});
				break;
			default:
				throw new Error(`Login type ${loginType} is not valid!`);
		}
	}, [loginType]);

	const getUserFromLoginDialog = () => {
		return new Promise((resolve, reject) => {
			setShowLoginUserDialog({ resolve, reject });
		});
	};

	const getAnonymousUserFromLoginDialog = () => {
		return new Promise((resolve, reject) => {
			setShowAnonymousUserFromLoginDialog({ resolve, reject });
		});
	};

	if (showLoginUserDialog) {
		return "Redirecting...";
	}

	return (
		<React.Fragment>
			{children}

			<LoginErrorDialog loginUrl={loginUrl} open={showLoginError} />
		</React.Fragment>
	);
};

export default Provider;
