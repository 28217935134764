import _ from "lodash";

import { getReducer } from "../../utils/StoreUtils";
import {
	copyPageItem,
	getDefaultPageItemProps,
} from "../../utils/PageItemsUtils";
import { addWords } from "../../utils/StringUtils";

// GET SYMBOL

export const createSymbolPageItems = ({
	cmtParseSchemes,
	cmtPageItem,
	cmtPage,
}) => {
	const pageItems = getReducer("pageItems").items;

	let cmtParseScheme = cmtParseSchemes.find(
		(cmtParseScheme) => cmtParseScheme.cmtSymbolName === cmtPageItem.symbol_name
	);
	let copiedPageItems;

	// Try to create page item from symboltem
	if (cmtParseScheme && !cmtParseScheme.projectPageItem) {
		const symbolPageItem = pageItems.find(
			(pageItem) =>
				pageItem.friendlyId === cmtParseScheme.symbolFriendlyId &&
				pageItem.symbol
		);
		copiedPageItems = symbolPageItem && copyPageItem(symbolPageItem.id);
	}

	// Try to create page item with same name as cmt page item
	if (!copiedPageItems) {
		const pageItemBySymbolName = createPageItemBySymbolName({
			symbolName: cmtPageItem.symbol_name,
		});
		pageItemBySymbolName.id = cmtPageItem.id;
		copiedPageItems = [pageItemBySymbolName];
		cmtParseScheme =
			copiedPageItems &&
			createSchemForCMTPageItem({
				cmtParseSchemes,
				cmtPageItem,
				pageItemBySymbolName,
				cmtParseScheme,
			});
	}

	if (!cmtParseScheme) {
		console.warn(
			`No cmtParseScheme found for cmtPageItem ${cmtPageItem.symbol_name}`
		);
		return [];
	}

	if (!copiedPageItems || !copiedPageItems.length) {
		console.warn(
			`No symbolPageItem found for cmt page item "${cmtPageItem.symbol_name}"!`
		);
		return [];
	}

	// replace ids (to make sure that copied pageItems always gets the same ids)
	replaceIds({ copiedPageItems, cmtPageItem });

	copiedPageItems.forEach((pageItem, index) => {
		pageItem.symbol = false;
		pageItem.symbolId = pageItem.originalPageItemId;
		pageItem.data = { cmt: true, diff: true, isRootPageItem: index === 0 };
		pageItem.sort_index = cmtPageItem.sort_index;
	});

	if (cmtParseScheme.appendCMTPageItem) {
		const rootPageItem = copiedPageItems[0];
		if (rootPageItem) {
			rootPageItem.cmtPageItem = cmtPageItem;
		}
	}

	return copiedPageItems;
};

// GET SYMBOL

export const createSymbolPageItemsForPage = ({ cmtParseSchemes, cmtPage }) => {
	const pageItems = getReducer("pageItems").items;
	const cmtParseScheme = cmtParseSchemes.find(
		(cmtParseScheme) => cmtParseScheme.cmtPageType === cmtPage.type
	);

	if (!cmtParseScheme) {
		console.warn(`No cmtParseScheme found for cmtPage type ${cmtPage.type}`);
		return;
	}

	const symbolPageItem = pageItems.find(
		(pageItem) =>
			pageItem.friendlyId === cmtParseScheme.symbolFriendlyId && pageItem.symbol
	);

	if (!symbolPageItem) {
		console.warn(
			`No symbolPageItem with symbolFriendlyId "${cmtParseScheme.symbolFriendlyId}"" found for cmt page item "${cmtPage.type}"!`
		);
		return [];
	}

	const copiedPageItems = copyPageItem(symbolPageItem.id);
	// replace ids (to make sure that copied pageItems always gets the same ids)
	replaceIds({ copiedPageItems, cmtPageItem: cmtPage });

	copiedPageItems.forEach((pageItem, index) => {
		pageItem.symbol = false;
		pageItem.symbolId = pageItem.originalPageItemId;
		pageItem.data = { cmt: true, diff: false, isRootPageItem: index === 0 };
	});

	// update hierarchy
	const { parentPageItem } = cmtParseScheme;
	if (parentPageItem) {
		const pageItem = pageItems.find(
			(pageItem) =>
				pageItem.id === parentPageItem || pageItem.friendlyId === parentPageItem
		);
		const rootPageItem = copiedPageItems[0];
		if (pageItem && rootPageItem) {
			rootPageItem.parentPageItemId = pageItem.id;
		}
	}

	return copiedPageItems;
};

// MAP SYMBOL PAGE ITEMS

export const mapSymbolPageItems = ({
	cmtParseSchemes,
	cmtParseScheme,
	cmtPageItem,
	symbolPageItems,
	cmtTexts,
	hideMissingContent,
}) => {
	if (!cmtParseSchemes) {
		return symbolPageItems;
	}

	cmtParseScheme =
		cmtParseScheme ||
		cmtParseSchemes.find(
			(cmtParseScheme) =>
				cmtParseScheme.cmtSymbolName === cmtPageItem.symbol_name
		);

	if (!cmtParseScheme) {
		console.warn(
			`No cmtParseScheme found for cmtPageItem ${cmtPageItem.symbol_name}`
		);
		return symbolPageItems;
	}

	// Update keys defined in scheme.items
	cmtParseScheme.items.forEach((item) => {
		const symbolPageItem = symbolPageItems.find(
			(pageItem) => pageItem.friendlyId === item.symbolFriendlyId
		);
		symbolPageItem &&
			(symbolPageItem[item.symbolKey] = unescape(cmtPageItem[item.cmtKey]));

		// Hide symbol if no content - text, image or video - found for symbol
		if (
			hideMissingContent &&
			hideSymbol({ symbolPageItem, cmtPageItem, item, cmtTexts })
		) {
			symbolPageItem && (symbolPageItem.visible = false);
		}
	});

	// Update root symbol (pageItem)
	const rootSymbolPageItem = symbolPageItems[0];
	if (rootSymbolPageItem) {
		rootSymbolPageItem.parentPageId = cmtPageItem.parent_id;
		cmtPageItem["friendly-id"] &&
			(rootSymbolPageItem.friendlyId = cmtPageItem["friendly-id"]);
		addCMTClassName(rootSymbolPageItem, cmtPageItem);
	}

	// Update state toggle
	if (rootSymbolPageItem && rootSymbolPageItem.stateToggle) {
		const stateToggles = _.keys(rootSymbolPageItem.stateToggle);
		stateToggles.forEach((stateToggle) => {
			if (cmtPageItem.hasOwnProperty(stateToggle)) {
				const stateValue = cmtPageItem[stateToggle];
				const states = rootSymbolPageItem.stateToggle[stateToggle];
				states.forEach((state) => {
					state.active = false;
				});
				const stateObj = states.find(
					(stateObj) => stateObj.state === stateValue
				);
				stateObj && (stateObj.active = true);
			}
		});
	}

	return symbolPageItems;
};

export const getCMTContainerScheme = ({ cmtParseSchemes, cmtPageItem }) => {
	const cmtParseScheme = cmtParseSchemes.find(
		(scheme) => scheme.cmtSymbolName === cmtPageItem.symbol_name
	);
	return cmtParseScheme && cmtParseScheme.container;
};

// GET PAGE ITEM CONTAINER

/**
 * @param  {} cmtParseSchemes - Passed parse scheme
 * @return {} containerPageItems - Created page items
 * @return {} containerPageItem - The pageItem to wich children should be added.
 * @return {} numParsed - If true the containerPageItems was added to store (pageItems reducer).
 * @return {} containerScheme - If true the containerPageItems was added to store (pageItems reducer).

 */

let previousCmtContainerScheme;
export const createContainerPageItems = ({
	cmtContainerScheme,
	cmtPageItem,
}) => {
	const pageItems = getReducer("pageItems").items;

	if (
		!cmtContainerScheme ||
		(previousCmtContainerScheme &&
			cmtContainerScheme.type === "addSelf" &&
			previousCmtContainerScheme.type === cmtContainerScheme.type)
	) {
		return [];
		// When page items not in same page
	} else {
		previousCmtContainerScheme = cmtContainerScheme;

		let containerSymbol = pageItems.find(
			(pageItem) => pageItem.friendlyId === cmtContainerScheme.symbolFriendlyId
		);
		let pageItemBySymbolName;

		if (!containerSymbol) {
			pageItemBySymbolName = createPageItemBySymbolName({
				symbolName: cmtContainerScheme.symbolFriendlyId,
			});
			if (pageItemBySymbolName) {
				pageItemBySymbolName.originalPageItemId = cmtPageItem.id + "-ctr";
				pageItemBySymbolName.cmtPageItem = cmtPageItem;
				containerSymbol = pageItemBySymbolName;
			}
		}

		if (!containerSymbol) {
			throw new console.error(
				`No symbol with friendlyId "${cmtContainerScheme.symbolFriendlyId}" found in project. Please make sure to add one!`
			);
		}

		const containerPageItems = pageItemBySymbolName
			? [pageItemBySymbolName]
			: copyPageItem(containerSymbol.id);
		// console.log("containerPageItems", containerPageItems, "cmtPageItem", cmtPageItem);

		// replace ids (to make sure that copied pageItems always gets the same ids)
		replaceIds({ copiedPageItems: containerPageItems, cmtPageItem });

		// find container
		const containerPageItem =
			containerPageItems.find(
				(pageItem) =>
					pageItem.data.container &&
					pageItem.data.container.numChildren <
						pageItem.data.container.maxChildren
			) || containerPageItems[0];

		const rootPageItem = containerPageItems[0];
		rootPageItem.parentPageId = cmtPageItem.parent_id;
		rootPageItem.id = concatIds(
			cmtPageItem.id,
			rootPageItem.originalPageItemId
		);

		containerPageItems.forEach((pageItem) => {
			pageItem.symbol = false;
			pageItem.symbolId = pageItem.originalPageItemId;
			pageItem.data = {
				cmt: true,
				containerPageItemId: containerPageItem.id,
				isRootPageItem: rootPageItem.id === pageItem.id,
				cmtContainerScheme: { ...cmtContainerScheme },
			};
		});

		return containerPageItems;
	}
};

export const createPageItemsForPage = ({ cmtPage, symbolFriendlyId }) => {
	const pageItems = getReducer("pageItems").items;
	const symbolPageItem = pageItems.find(
		(pageItem) => pageItem.friendlyId === symbolFriendlyId && pageItem.symbol
	);

	if (!symbolPageItem) {
		console.warn(
			`No symbolPageItem with symbolFriendlyId ${symbolFriendlyId} found for cmt page item ${cmtPage.type}!`
		);
		return [];
	}

	const copiedPageItems = copyPageItem(symbolPageItem.id);
	// replace ids (to make sure that copied pageItems always gets the same ids)
	replaceIds({ copiedPageItems, cmtPageItem: cmtPage });

	copiedPageItems.forEach((pageItem, index) => {
		pageItem.symbol = false;
		pageItem.symbolId = pageItem.originalPageItemId;
		pageItem.data = { cmt: true, diff: false, isRootPageItem: index === 0 };
	});

	// update hierarchy
	const rootPageItem = copiedPageItems[0];
	rootPageItem.parentPageId = cmtPage.id;
	rootPageItem.id = concatIds(cmtPage.id, rootPageItem.originalPageItemId);
	rootPageItem.cmtPage = cmtPage;

	return copiedPageItems;
};

// MISC HELPER FUNCTIONS

const hideSymbol = ({ symbolPageItem, cmtPageItem, item, cmtTexts }) => {
	// No source video || image
	if (item.symbolKey === "src") {
		const cmtSrc = cmtPageItem[item.cmtKey];
		if (!cmtSrc || cmtSrc === "") {
			return true;
		}
	}
	// No text
	if (item.symbolKey === "textId") {
		const cmtTextId = cmtPageItem[item.cmtKey];
		const cmtText = cmtTexts.find((cmtText) => cmtText.id === cmtTextId);
		if (!cmtText || !cmtText.value || cmtText.value === "") {
			return true;
		}
	}
};

export const concatIds = (idA = "", idB = "") => {
	return idA.slice(0, idA.length / 2) + idB.slice(0, idB.length / 2);
};

export const replaceIds = ({ copiedPageItems, cmtPageItem, trail = "" }) => {
	// replace ids (to make sure that copied pageItems always gets the same ids)
	const replacedIds = [];
	copiedPageItems.forEach((pageItem, index) => {
		const newId = concatIds(cmtPageItem.id, pageItem.originalPageItemId);
		const oldId = String(pageItem.id);
		const memento = { newId, oldId };
		replacedIds.push(memento);
		pageItem.id = memento.newId + trail;
	});
	// replace parent ids
	copiedPageItems.forEach((pageItem, index) => {
		const replacedId = replacedIds.find(
			(rid) => rid.oldId === pageItem.parentPageItemId
		);
		if (replacedId) {
			pageItem.parentPageItemId = replacedId.newId + trail;
		}
	});
};

const addCMTClassName = (symbolPageItem, cmtPageItem) => {
	// export function addWords(str, words, splitBy = " ")
	let symbolName = cmtPageItem.symbol_name;
	if (!symbolName) {
		return;
	}
	symbolName = symbolName.replace(/_/g, "-");
	symbolName = symbolName.toLowerCase();
	symbolPageItem.className = addWords(
		symbolPageItem.className,
		"symbol-name-" + symbolName
	);
};

const createPageItemBySymbolName = ({ symbolName }) => {
	return {
		...getDefaultPageItemProps(),
		type: symbolName,
		friendlyId: symbolName,
		name: symbolName,
		parentPageItemId: null,
	};
};

const createSchemForCMTPageItem = ({
	cmtParseSchemes,
	cmtPageItem,
	cmtParseScheme,
}) => {
	const isTemplate = cmtPageItem.symbol_template;
	const isScrollType = cmtPageItem.symbol_type;
	cmtParseScheme = {
		...{
			projectPageItem: true,
			cmtSymbolName: cmtPageItem.symbol_name,
			symbolFriendlyId: cmtPageItem.symbol_name,
			appendCMTPageItem: true,
			descr: "",
			items: [],
		},
		// container: isTemplate &&
		//   isScrollType && {
		//     symbolFriendlyId: "reveal",
		//     type: "addNext",
		//     clear: true,
		//   },
		...cmtParseScheme,
	};

	if (
		!cmtParseSchemes.find(
			(item) => item.cmtSymbolName === cmtPageItem.symbol_name
		)
	) {
		cmtParseSchemes.push(cmtParseScheme);
	}

	return cmtParseScheme;
};
