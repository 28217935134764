import React from "react";
import classnames from "classnames";
import { BarChart, Bar, XAxis, ResponsiveContainer } from "recharts";
import _ from "lodash";
import { getBootstrapColor } from "../../../utils/colorUtils";
// Don´t delete!!!

const renderCustomBarLabel = ({ x, y, width, value }) => {
	return (
		<text
			className="speech-bubble-text-emphasized font-weight-bold text-extra-light-gray"
			x={x + width / 2}
			y={y}
			fill={getBootstrapColor("light-gray")}
			textAnchor="middle"
			dy={-22}
		>{`${value}%`}</text>
	);
};

const renderCustomAxisTick = ({ x, y, payload }) => {
	return (
		<text
			className="speech-bubble-text-emphasized font-weight-bold"
			x={x}
			// offset from the divider line, but not too much offset to push it outside of the boundary
			y={y + 10}
			textAnchor={"middle"}
			dominantBaseline="central"
		>
			{`${payload.value}`}
		</text>
	);
};

const Chart = ({ data, color = "primary", className, style }) => {
	const hasData = !_.isEmpty(data);
	const renderNothing = () => <React.Fragment />;
	return (
		<div
			style={{ paddingBottom: "40px", ...style }}
			className={classnames("w-100", className)}
		>
			<ResponsiveContainer width="100%" height={400}>
				<BarChart data={data} style={{ overflow: "visible" }}>
					<XAxis
						dataKey="name"
						tickLine={false}
						tick={hasData ? renderCustomAxisTick : renderNothing}
					/>
					<Bar
						isAnimationActive={false}
						dataKey="percent"
						fill={getBootstrapColor(color)}
						labelLine={false}
						label={renderCustomBarLabel}
					/>
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

export default Chart;
