import Heading from "components/Heading/Heading";
import Text from "funkis-template/components/core/Text";
import { TextId } from "funkis-template/models/player";
import { FC } from "react";

type HeaderProps = {
	heading: TextId;
	body: TextId;
};

const Header: FC<HeaderProps> = ({ heading, body }) => {
	return (
		<>
			<Heading level="2" textId={heading} />
			<Text textId={body} />
		</>
	);
};

export default Header;
