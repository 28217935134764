import React from "react";

function IconMembers({ className, style, color = "#FFFFFF" }) {
	return (
		<svg
			className={className}
			style={style}
			width="59px"
			height="88px"
			viewBox="0 0 59 88"
			version="1.1"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="components/member-icon-neg" fill={color}>
					<path
						d="M41.0058831,22.8862385 L59,44.4475807 L53.3088996,49.2483933 L41.005,34.506 L41.0059738,88 L32.1364973,88 L32.1364973,51.9327753 L26.8635522,51.9327753 L26.8635522,88 L17.9941581,88 L17.994,34.506 L5.69110042,49.2483933 L-9.23705556e-14,44.4475807 L17.9941169,22.8862385 L41.0058831,22.8862385 Z M29.5,35 C28.1193283,35 27,36.1193283 27,37.5 C27,38.8807499 28.1193283,40 29.5,40 C30.8807499,40 32,38.8807499 32,37.5 C32,36.1193283 30.8807499,35 29.5,35 Z M29.4999505,6.92779167e-14 C34.3534212,6.92779167e-14 38.2879725,3.95576548 38.2879725,8.83532206 C38.2879725,13.7149615 34.3534212,17.670727 29.4999505,17.670727 C24.6465623,17.670727 20.712011,13.7149615 20.712011,8.83532206 C20.712011,3.95576548 24.6465623,6.92779167e-14 29.4999505,6.92779167e-14 Z"
						id="Combined-Shape"
					></path>
				</g>
			</g>
		</svg>
	);
}

export default IconMembers;
