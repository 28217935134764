import classnames from "classnames";
import { useText } from "funkis-template/hooks/text";
import _ from "lodash";
import React from "react";
import { getLocalizedMediaPath } from "utils/MediaUtils";
import InlineSVG, {
	SvgInlineProps,
} from "../../../components/core/InlineSVG/InlineSVG";
import { MediaCrop, MediaFit } from "../../../components/core/Media";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";
import { useViewportSize, ViewportSize } from "../../../hooks/player";
import { PageItemMedia } from "funkis-template/models/player";

function undefineEmptyValue<Type>(val: Type): Type | undefined {
	switch (typeof val) {
		case "number":
			return val <= 0 ? undefined : val;
		default:
			return _.isEmpty(val) ? undefined : val;
	}
}

type SVGTextContentArrayItem = {
	text: TextId;
	layer_id: string;
};

type SVGDynamicTextPageItem = ScrollPageItem & {
	desktop_media: PageItemMedia;
	desktop_media_fit: MediaFit;
	desktop_media_crop: MediaCrop;
	desktop_media_width: number;
	desktop_media_height: number;

	mobile_media: PageItemMedia;
	mobile_media_fit: MediaFit;
	mobile_media_crop: MediaCrop;
	mobile_media_width: number;
	mobile_media_height: number;

	content_array: SVGTextContentArrayItem[];
};

type AlignableSVGProps = Omit<SvgInlineProps, "href"> & {
	src: PageItemMedia;
	mediaFit: MediaFit;
	mediaCrop: MediaCrop;
	width?: number;
	height?: number;
	viewportSize: ViewportSize;
};

const composeMediaFitClassNames = (
	mediaFit: MediaFit,
	viewportSize: ViewportSize
): { wrapper?: string; component?: string } => {
	switch (mediaFit) {
		case MediaFit.FullWidth:
			return { component: "w-100" };

		case MediaFit.AlignCenter:
			return {
				wrapper: classnames("justify-content-center"),
			};

		case MediaFit.AlignLeft:
			return {
				wrapper: classnames("row m-0 "),
				component: "col-sm-12",
			};

		case MediaFit.AlignRight:
			return {
				wrapper: classnames("row m-0 justify-content-end"),
				component: "col-sm-12",
			};

		default:
			return {};
	}
};

const AlignableSVG: React.FC<AlignableSVGProps> = ({
	src,
	templatingVariables,
	mediaFit,
	mediaCrop,
	style,
	className,
	width,
	height,
	viewportSize,
}) => {
	if (_.isEmpty(src)) {
		return <></>;
	}

	const fullSrc = getLocalizedMediaPath(src);
	const mediaClassNames = composeMediaFitClassNames(mediaFit, viewportSize);

	return (
		<div
			style={style}
			className={classnames(className, "d-flex", mediaClassNames.wrapper)}
		>
			<div style={{ width, height, minWidth: width, minHeight: height }}>
				<InlineSVG
					href={fullSrc}
					templatingVariables={templatingVariables}
					style={{}}
					className={classnames(
						{
							"mw-100": mediaCrop === MediaCrop.ResizeToFit,
						},
						mediaClassNames.component
					)}
				/>
			</div>
		</div>
	);
};

const PageItemSVGDynamicText = (props: any) => {
	const { pageItem } = props;

	const {
		desktop_media,
		desktop_media_fit,
		desktop_media_crop,
		desktop_media_width,
		desktop_media_height,

		mobile_media,
		mobile_media_fit,
		mobile_media_crop,
		mobile_media_width,
		mobile_media_height,
		content_array,
	} = pageItem.cmtPageItem as SVGDynamicTextPageItem;

	const viewportSize = useViewportSize();
	const isMobile = viewportSize === ViewportSize.MobileSmall;

	const media = isMobile
		? {
				src: mobile_media,
				fit: mobile_media_fit,
				crop: mobile_media_crop,
				width: undefineEmptyValue(mobile_media_width),
				height: undefineEmptyValue(mobile_media_height),
		  }
		: {
				src: desktop_media,
				fit: desktop_media_fit,
				crop: desktop_media_crop,
				width: undefineEmptyValue(desktop_media_width),
				height: undefineEmptyValue(desktop_media_height),
		  };

	const variablesTemplate: Record<string, string> = content_array.reduce(
		(acc, contentArrayItem) => ({
			...acc,
			[contentArrayItem.layer_id]: contentArrayItem.text,
		}),
		{}
	);

	const textVariables = useText(variablesTemplate, "No text found");

	return (
		<PageItemScrollBase
			className="container-fluid"
			pageItem={pageItem}
			renderFunction={(_renderProps: any) => (
				<AlignableSVG
					src={media.src}
					templatingVariables={textVariables}
					mediaFit={media.fit}
					mediaCrop={media.crop}
					width={media.width}
					height={media.height}
					viewportSize={viewportSize}
				/>
			)}
		/>
	);
};

export default PageItemSVGDynamicText;
