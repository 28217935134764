import _ from "lodash";
import * as API from "../api";
import { getCode } from "../utils/QuiddityUtils";
import * as QuidInputActions from "../redux/actions/QuidInputActions";
import { getReducer } from "../utils/StoreUtils";

export const getQuidInputs = async (url, query, addToStore = true) => {
	try {
		const quidInputs = await API.getQuidInputs(url, query, getCode());
		addToStore && QuidInputActions.updateQuidInputs(quidInputs);
		return quidInputs;
	} catch (e) {
		return [];
	}
};

export const getQuidInput = async (url, query, addToStore = true) => {
	const quidInputs = await getQuidInputs(url, query, addToStore);
	return quidInputs[0];
};

export const createQuidInput = async (url, query, addToStore = true) => {
	const quidInput = await API.postOrPutQuidInput(
		url,
		_.omit(query, "id"),
		getCode()
	);
	addToStore && QuidInputActions.updateQuidInputs([quidInput]);
	return quidInput;
};

export const upsertQuidInput = async (url, query, addToStore = true) => {
	const quidInputsById = getReducer("quidInputs").itemsById;
	const quidInput =
		quidInputsById[query.id] || (await getQuidInput(url, query));
	let upsertedQuidInput = { ...quidInput, ...query };
	// optimistic update
	addToStore &&
		quidInput &&
		QuidInputActions.updateQuidInput(upsertedQuidInput.id, upsertedQuidInput);
	upsertedQuidInput = await API.postOrPutQuidInput(
		url,
		upsertedQuidInput,
		getCode()
	);
	// both POST and PUT need to update redux again, because both have server-calculated values (POST has "id", PUT has "updatedAt")
	addToStore &&
		QuidInputActions.updateQuidInput(upsertedQuidInput.id, upsertedQuidInput);
	return upsertedQuidInput;
};

export const deleteQuidInput = async (url, id, removeFromStore = true) => {
	try {
		const quidInput = await API.deleteQuidInput(url, id, getCode());
		removeFromStore && QuidInputActions.removeQuidInput(id);
		return quidInput;
	} catch (e) {
		return null;
	}
};
