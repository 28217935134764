import _ from "lodash";

export const sortQuidInputsByTime = (quidInputs) => {
	return _.orderBy(
		quidInputs,
		(quidInput) => quidInput.updatedAt ?? quidInput.createdAt,
		"desc"
	);
};

export type TimeFormat = "ss" | "mm" | "mm:ss" | "hh:mm" | "hh:mm:ss";

const formatterOptions: Record<TimeFormat, Intl.DateTimeFormatOptions> = {
	ss: { second: "numeric" },
	mm: { minute: "numeric" },
	"mm:ss": { minute: "2-digit", second: "2-digit" },
	"hh:mm": { hour: "2-digit", minute: "2-digit" },
	"hh:mm:ss": { hour: "2-digit", minute: "2-digit", second: "2-digit" },
};

const globalFormatterOptions: Intl.DateTimeFormatOptions = {
	hourCycle: "h23",
	timeZone: "UTC",
};

const formatters: Record<TimeFormat, Intl.DateTimeFormat> = {
	ss: new Intl.DateTimeFormat("en", {
		...formatterOptions["ss"],
		...globalFormatterOptions,
	}),
	mm: new Intl.DateTimeFormat("en", {
		...formatterOptions["mm"],
		...globalFormatterOptions,
	}),
	"mm:ss": new Intl.DateTimeFormat("en", {
		...formatterOptions["mm:ss"],
		...globalFormatterOptions,
	}),
	"hh:mm": new Intl.DateTimeFormat("en", {
		...formatterOptions["hh:mm"],
		...globalFormatterOptions,
	}),
	"hh:mm:ss": new Intl.DateTimeFormat("en", {
		...formatterOptions["hh:mm:ss"],
		...globalFormatterOptions,
	}),
};

export const secondsToFormattedTime = (
	value: number,
	format: TimeFormat = "mm:ss"
): string => {
	/**
	 * When we display minutes we want the displayed value to be "rounded" to the
	 * previous minute. E.g. we want to display "5 minutes" for a full minute if the
	 * timer duration is set to 5 minutes.
	 */
	const secondsToFormat =
		format === "mm" || format === "hh:mm" ? value + 59 : value;

	const date = new Date(secondsToFormat * 1000);
	const dateFormatter = formatters[format];

	return dateFormatter.format(date);
};
