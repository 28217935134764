import { PageItemActions } from "funkis-foundation";

export function videoManager({ action, store }) {
	if (
		action.type === "UPDATE_PAGE_ITEMS" ||
		action.type === "UPDATE_PAGE_ITEM"
	) {
		const pageItemsById = store.getState().pageItems.itemsById;
		const items = action.items || [action.item];
		const playingItems = items.filter(
			(item) =>
				pageItemsById[item.id] &&
				pageItemsById[item.id].type === "video" &&
				item.state === "playing"
		);

		if (playingItems.length) {
			const pageItems = store.getState().pageItems.items;
			const playingPageItemIds = playingItems.map((pageItem) => pageItem.id);
			const prevPlayingItems = pageItems
				.filter((pageItem) => pageItem.state === "playing")
				.filter((pageItem) => !playingPageItemIds.includes(pageItem.id));
			if (prevPlayingItems.length) {
				const updates = [];
				prevPlayingItems.forEach((pageItem) => {
					updates.push({ id: pageItem.id, state: "paused" });
				});
				PageItemActions.updatePageItems(updates);
			}
		}
	}
}
