import _ from "lodash";
import React, { FC, useMemo } from "react";
import styled from "styled-components";
import Media, { MediaCrop, MediaFit } from "../../../components/core/Media";
import Text from "../../../components/core/Text";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";
import {
	IOContext,
	QuidInput,
	QuidType,
	useBackend,
} from "../../../hooks/backend";
import { useViewportSize, ViewportSize } from "../../../hooks/player";
import {
	useTotalItemsFromMixedItems,
	useValueList,
} from "../../../hooks/valuelist";
import { MultipleChoiceContentArray } from "../../../models/contentarray";
import { TechstormXYOutputScrollBlock } from "../../../models/pageitem";
import { PageItem } from "../../../models/player";

const Wrapper = styled.div`
	position: relative;
`;

const ItemWrapper = styled.div<{ width: number; height: number }>`
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - ${(props) => props.width}px);
	height: calc(100% - ${(props) => props.height}px);
`;

type ItemProps = { x: number; y: number; scale: number };

const Item = styled.div<ItemProps>`
	position: absolute;
	left: ${(props) => props.x}%;
	top: ${(props) => 100 - props.y}%;
	transform: scale(${(props) => props.scale});
	transform-origin: top left;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.35);
	z-index: 1;
`;

const CoordItem: React.FC<{
	x: number;
	y: number;
	scale: number;
	className?: string;
}> = (props) => {
	const ref = React.useRef<HTMLDivElement>(null);
	const [size, setSize] = React.useState<{ width: number; height: number }>();

	React.useEffect(() => {
		if (ref.current) {
			const width = ref.current.offsetWidth;
			const height = ref.current.offsetHeight;
			setSize({ width, height });
		}
	}, []);

	return (
		<ItemWrapper
			width={(size?.width ?? 0) * props.scale}
			height={(size?.height ?? 0) * props.scale}
		>
			<Item
				ref={ref}
				className={props.className}
				x={props.x}
				y={props.y}
				scale={props.scale}
			>
				{props.children}
			</Item>
		</ItemWrapper>
	);
};

function normalize(min: number, max: number) {
	const delta = max - min;
	return (val: number): number => (val - min) / delta;
}

const TechstormXYOutput: FC<{
	pageItem: PageItem<TechstormXYOutputScrollBlock>;
}> = (props) => {
	const { cmtPageItem } = props.pageItem;

	const contentArray: MultipleChoiceContentArray = useValueList(
		cmtPageItem.valuelist_id
	);

	const { quidInputs: selections } = useBackend({
		targetDataKey: cmtPageItem.ios.selections.id,
		inputContext: cmtPageItem.ios.selections.context,
		quidType: QuidType.ContentSelection,
	});

	const { quidInputs: groupXRateValueQuidInputs } = useBackend({
		targetDataKey: cmtPageItem.ios.x_axis.id,
		inputContext: cmtPageItem.ios.x_axis.context,
		quidType: QuidType.Rate,
	});

	const { quidInputs: yAxisSelectionQuidInputs } = useBackend({
		targetDataKey: cmtPageItem.ios.read_ios.join(","),
		inputContext: IOContext.Group,
		quidType: QuidType.ContentSelection,
	});

	const uniqueYAxisCmtItems = useMemo(
		() => _.uniq(yAxisSelectionQuidInputs.map((qi) => qi.data.contentItemId)),
		[yAxisSelectionQuidInputs]
	);

	const totalYAxisContentArrayItems =
		useTotalItemsFromMixedItems(uniqueYAxisCmtItems);
	// some of them are headers - keep only questions
	const maxYAxisPoints = totalYAxisContentArrayItems.filter(
		(item) => item.correct
	).length;

	const aggregatedDataBySelectionId: Record<
		string,
		{ id: string; xRaw: number; yRaw: number; x: number; y: number }
	> = useMemo(() => {
		return selections.reduce((acc, mainSelectionQuidInput) => {
			const numberOfMatrixSelections = yAxisSelectionQuidInputs.filter(
				(qi) => qi.targetQuidInput === mainSelectionQuidInput.id
			).length;
			const xValueQuidInput = groupXRateValueQuidInputs.find(
				(qi) => qi.targetQuidInput === mainSelectionQuidInput.id
			);
			const xValue = xValueQuidInput ? xValueQuidInput.data.value : 0;
			const normalizeRate = normalize(
				xValueQuidInput?.data.min_value || 0,
				xValueQuidInput?.data.max_value || 100
			);
			const normalizedX = normalizeRate(xValue) * 100;
			acc[mainSelectionQuidInput.id] = {
				id: mainSelectionQuidInput.id,
				xRaw: xValue,
				yRaw: numberOfMatrixSelections,
				x: normalizedX,
				y: (numberOfMatrixSelections / maxYAxisPoints) * 100,
			};
			return acc;
		}, {});
	}, [
		selections,
		groupXRateValueQuidInputs,
		yAxisSelectionQuidInputs,
		maxYAxisPoints,
	]);

	const selectedOptions = contentArray.values.reduce(
		(acc, option) => {
			const selection = selections.find(
				(selection) => option.id === selection.data.contentItemId
			);
			return selection !== undefined ? [{ selection, option }, ...acc] : acc;
		},
		[] as {
			option: MultipleChoiceContentArray["values"][number];
			selection: QuidInput;
		}[]
	);

	const viewportSize = useViewportSize();
	const itemScale = viewportSize < ViewportSize.Mobile ? 0.5 : 1;

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={true}
			renderFunction={() => (
				<div className="row m-0 mt-6">
					<div className="col-12">
						<Wrapper>
							<Media
								src={cmtPageItem.background_media}
								mediaCrop={MediaCrop.ResizeToFit}
								mediaFit={MediaFit.FullWidth}
							/>

							{selectedOptions.map(({ selection, option }) => {
								const aggregatedData =
									aggregatedDataBySelectionId[selection.id];
								if (!aggregatedData) return;

								return (
									<CoordItem
										key={option.id}
										x={aggregatedData.x}
										y={aggregatedData.y}
										scale={itemScale}
										className="techstorm-x-y-output__item"
									>
										<Media
											src={option.image}
											mediaCrop={MediaCrop.ResizeToFit}
											mediaFit={MediaFit.FullWidth}
											className="techstorm-x-y-output__item__media"
										/>
									</CoordItem>
								);
							})}
						</Wrapper>
					</div>
					<ul className="col-12 mt-4 techstorm-x-y-output__legend">
						{selectedOptions.map(({ option }) => {
							return (
								<li
									key={option.id}
									className="techstorm-x-y-output__legend__item"
								>
									<Media
										src={option.image}
										mediaCrop={MediaCrop.ResizeToFit}
										mediaFit={MediaFit.FullWidth}
										className="techstorm-x-y-output__legend__item__media"
									/>
									<Text
										textId={option.option}
										className="techstorm-x-y-output__legend__item__heading"
									/>
								</li>
							);
						})}
					</ul>
				</div>
			)}
		/>
	);
};

export default TechstormXYOutput;
