import React from "react";
import _ from "lodash";
import classnames from "classnames";
import {
	FFButton,
	StoreUtils,
	Actions,
	PageItemImage,
} from "funkis-foundation";
import {
	makeGetScrollProgress,
	makeGetShowTopBar,
	makeGetChapterTitleId,
	makeGetPageProgress,
	makeGetCurrentPage,
} from "../../../selectors/playerSelectors";
import { connect } from "../../utils/reduxUtils";
import MenuIcon from "../scroll/MenuIcon";
import Style from "./TopBarDefault_v2.module.css";
import Image from "./Image";
import { getBootstrapColor } from "../../utils/colorUtils";
import Text from "./Text";
import { render } from "../../RenderFunctions";

const { updatePageItem } = Actions;

const Delimitter = ({ show, className }) => {
	return show ? (
		<span
			className={classnames(
				"topbar-header-sm topbar-header-delimiter",
				className
			)}
			style={{ margin: "0 0.375rem" }}
		>
			{"|"}
		</span>
	) : (
		<span />
	);
};

const TopBarDefault = (props) => {
	const {
		progress,
		chapterProgress,
		app,
		show,
		chapterTitleTextId,
		pageTitleTextId,
		page,
	} = props;

	if (!show) {
		return <div />;
	}

	const sideMenuIcon = app.side_menu_icon;
	const menuPosition = app.menu_position;
	const sideMenuEnabled =
		app.menu_position !== "none" && app.navigation_menu_hidden !== "yes";
	const logoSrc = app.program_logo;
	const pageIsMainMenu = page && page.type === "menu";
	const backgroundColor = getBootstrapColor(app.top_bar_bg_color || "white");

	const onSideMenuButtonClick = () => {
		const pageItems = StoreUtils.getReducer("pageItems").items;
		const sideMenu = pageItems.find(
			(pageItem) => pageItem.friendlyId === "side-menu"
		);
		const open = !sideMenu.open;
		updatePageItem(sideMenu.id, { open });
	};

	const shouldShowChapterTitle =
		(!app.hasOwnProperty("top_bar_show_chapter_title") &&
			!app.hasOwnProperty("show_top_bar_chapter_title") &&
			chapterTitleTextId) ||
		(app.hasOwnProperty("top_bar_show_chapter_title") &&
			app.top_bar_show_chapter_title === "yes" &&
			chapterTitleTextId) ||
		(app.hasOwnProperty("show_top_bar_chapter_title") &&
			app.show_top_bar_chapter_title === "yes" &&
			chapterTitleTextId);

	const shouldShowPageTitle =
		(!app.hasOwnProperty("top_bar_show_page_title") &&
			!app.hasOwnProperty("show_top_bar_page_title") &&
			pageTitleTextId) ||
		(app.hasOwnProperty("top_bar_show_page_title") &&
			app.top_bar_show_page_title === "yes" &&
			pageTitleTextId);
	app.hasOwnProperty("show_top_bar_page_title") &&
		app.show_top_bar_page_title === "yes" &&
		pageTitleTextId;

	const shouldShowStats =
		(!app.hasOwnProperty("top_bar_show_chapter_stats") &&
			!app.hasOwnProperty("show_top_bar_chapter_stats" && chapterProgress)) ||
		(app.hasOwnProperty("top_bar_show_chapter_stats") &&
			app.top_bar_show_chapter_stats === "yes" &&
			chapterProgress) ||
		(app.hasOwnProperty("show_top_bar_chapter_stats") &&
			app.show_top_bar_chapter_stats === "yes" &&
			chapterProgress);
	const shouldShowProgressBar =
		(!app.hasOwnProperty("top_bar_show_progress_bar") &&
			!app.hasOwnProperty("show_top_bar_progress_bar")) ||
		(app.hasOwnProperty("top_bar_show_progress_bar") &&
			app.top_bar_show_progress_bar === "yes") ||
		(app.hasOwnProperty("show_top_bar_progress_bar") &&
			app.show_top_bar_progress_bar === "yes");

	if (
		(app.top_bar_hide_in_main_menu === "yes" && pageIsMainMenu) ||
		(app.top_bar_hide_in_page === "yes" && !pageIsMainMenu)
	) {
		return <React.Fragment />;
	}

	return (
		<div
			className={classnames(Style.topBar, Style.dropShadow, "top-bar no-print")}
		>
			<div
				className={classnames(
					Style.innerContainer,
					"d-flex justify-content-between align-items-center",
					menuPosition === "right" && "flex-row-reverse",
					menuPosition === "right" ? "pr-3" : "pl-3"
				)}
				style={{
					backgroundColor,
					color: getBootstrapColor(app.top_bar_text_color || "black"),
				}}
			>
				<div
					className={classnames(
						Style.menuButton,
						menuPosition === "right" && Style.menuButtonRight,
						"menu-button"
					)}
				>
					{sideMenuEnabled &&
						render(
							"menuButton",
							{ ...props, onSideMenuButtonClick },
							<FFButton onClick={onSideMenuButtonClick}>
								{({ hover }) => {
									return sideMenuIcon ? (
										<Image src={sideMenuIcon} />
									) : (
										<MenuIcon color={hover ? "interaction" : "light-gray"} />
									);
								}}
							</FFButton>
						)}
				</div>
				{/* INFO - in the middle. Will not show when in main menu */}
				<div
					className={classnames(
						Style.titleAndProgressWrapper,
						"d-flex flex-column flex-nowrap mx-1 animated",
						pageIsMainMenu ? "fadeOut" : "fadeIn"
					)}
				>
					{/* BREAD CRUMBS & CHAPTER PROGRESS */}
					<div
						className={classnames(
							Style.breadCrumbs,
							"d-flex mt-md-1 justify-content-center align-items-center"
						)}
					>
						{/* CHAPTER TITLE - default visible */}
						{shouldShowChapterTitle && (
							<Text
								textId={chapterTitleTextId}
								className="topbar-header-sm font-italic overflow-hidden btn-ellipsis"
							/>
						)}
						{/* PAGE TITLE - default hidden */}
						{shouldShowPageTitle && (
							<React.Fragment>
								<Delimitter
									show={shouldShowChapterTitle}
									className="before-page-title"
								/>
								<Text
									textId={pageTitleTextId}
									className="topbar-header-sm font-italic overflow-hidden btn-ellipsis"
								/>
							</React.Fragment>
						)}
						{/* CHAPTER PROGRESS - default visible */}
						{shouldShowStats && (
							<React.Fragment>
								<Delimitter
									show={shouldShowChapterTitle || shouldShowPageTitle}
									className="before-stats"
								/>
								<span className="topbar-header-sm font-italic caption chapter-stats">
									{chapterProgress}
								</span>
							</React.Fragment>
						)}
					</div>
					{/* PROGRESS BAR */}
					{shouldShowProgressBar && (
						<div
							className={classnames("progress w-100 animated", Style.progress)}
						>
							<div
								className={classnames("progress-bar bg-dark")}
								role="progressbar"
								aria-valuenow={progress * 100}
								aria-valuemin="0"
								aria-valuemax="100"
								style={{ width: progress * 100 + "%" }}
							/>
						</div>
					)}
				</div>
				{/* LOGO */}
				<div
					className={classnames(
						Style.menuButton,
						"top-bar-logo",
						menuPosition === "left" && "position-relative"
					)}
				>
					{render(
						"topBarLogo",
						props,
						<PageItemImage
							pageItem={{
								src: logoSrc,
								backgroundImage: true,
								width: "100%",
								height: "100%",
							}}
							className={classnames(
								Style.logo,
								menuPosition === "left" ? Style.logoRight : Style.logoLeft
							)}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const makeMapStateToProps = () => {
	const getChapterTitleId = makeGetChapterTitleId();
	const getShowTopBar = makeGetShowTopBar();
	const getScrollProgress = makeGetScrollProgress();
	const getPageProgress = makeGetPageProgress();
	const getCurrentPage = makeGetCurrentPage();
	const mapStateToProps = (state, props) => {
		const currentPage = getCurrentPage(state, props);
		return {
			chapterTitleTextId: getChapterTitleId(state, props),
			pageTitleTextId: _.get(currentPage, "data.cmtPage.title_txt_id"),
			show: getShowTopBar(state, props),
			progress: getScrollProgress(state, props),
			page: currentPage,
			chapterProgress: getPageProgress(state, props),
			app: state.app.item,
		};
	};
	return mapStateToProps;
};

export default connect(TopBarDefault, makeMapStateToProps);
