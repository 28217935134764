import React from "react";
import classnames from "classnames";
import _ from "lodash";
import { FFText } from "funkis-foundation";
import Logo from "../components/slide/Logo";
import PageNavigation from "../components/slide/PageNavigation";
import { useSelector } from "react-redux";
import ImageVideoOrDiv from "../components/core/ImageVideoOrDiv";
import WorkshopGroupSetup from "../components/core/WorkshopGroupSetup";
import bgQuiddity from "assets/images/bg_quiddity.jpg";

const GLOBAL_TEXT_IDS_BY_FUNCTION = {
	HEADER: "0863704F-8138-40D2-89A9-708398474E7E", // Eg. "What group do you want to use for the workshop?"",
	SUBHEADER_INSTRUCTION: "0C0053DC-3AA3-44ED-B699-B6B8DB80FABC",
	TABLE_COL_STATUS: "479E2F25-2376-4578-A893-556628D77D94",
	TABLE_COL_GROUP: "C3B8454D-BC5B-4D48-B5B8-3CFCF387EF2E",
	TABLE_COL_CREATED: "56C9E988-8A0A-469C-839A-A8E3BA5C2DBF",
};

const PageWorkshopGroupSetup = (props) => {
	const backend = useSelector((state) => _.get(state, "app.item.backend"));

	return (
		<ImageVideoOrDiv
			className="absolute-top w-100 h-100 d-flex justify-content-center flex-wrap bg-black"
			src={backend && bgQuiddity}
		>
			<div className="w-100 position-relative flex-grow-0">
				<Logo className="position-absolute" />
			</div>

			<div
				className="absolute-top text-white"
				style={{ width: "1280px", hight: "720px", top: "286px" }}
			>
				<div className={classnames("row m-0 mb-5")}>
					<FFText
						className="d-block message-lg col-sm-12 font-weight-bold mb-1"
						textId={GLOBAL_TEXT_IDS_BY_FUNCTION.HEADER}
					/>
					<FFText
						tagName="h2"
						className="d-block col-sm-12 font-weight-bold"
						textId={GLOBAL_TEXT_IDS_BY_FUNCTION.SUBHEADER_INSTRUCTION}
					/>
				</div>

				<div className="row m-0 mb-1 border-bottom border-bottom-1 border-gray pb-3">
					<div className="col-sm-2" />
					<FFText
						tagName="h4"
						className="font-weight-bold col-sm-5"
						textId={GLOBAL_TEXT_IDS_BY_FUNCTION.TABLE_COL_GROUP}
					/>
					<div className="col-sm-2">
						{/* <FFText
              tagName="h4"
              className="font-weight-bold"
              textId={GLOBAL_TEXT_IDS_BY_FUNCTION.TABLE_COL_STATUS}
            /> */}
					</div>
					<div className="col-sm-3 justify-content-end">
						<FFText
							tagName="h4"
							className="font-weight-bold text-right"
							textId={GLOBAL_TEXT_IDS_BY_FUNCTION.TABLE_COL_CREATED}
						/>
					</div>
				</div>
				<WorkshopGroupSetup {...props} />
			</div>
			<PageNavigation show={true} color="white" />
		</ImageVideoOrDiv>
	);
};

export default PageWorkshopGroupSetup;
