import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import Typewriter from "typewriter-effect/dist/core";
import Text from "../core/Text";
import { getText } from "../../utils/textUtils";
import styled from "styled-components";
import _ from "lodash";

const Root = styled.div`
	opacity: ${({ show }) => (show ? 1 : 0)};
	height: ${({ height }) => height + "px"};
	position: relative;
`;

const GhostText = styled.span`
	opacity: 0;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
`;

const TextWriter = (props) => {
	const {
		enabled = true,
		startDelay = 500,
		speed = 5, // ms
		textId,
		textIdPrefix,
		style,
		className,
		classNamePrefix,
		onComplete,
		onReady, // Ready when height is calculated
		onStart,
		preCalculateHeight = true,
	} = props;

	const ref = useRef(null);
	const refGhost = useRef(null);
	const [height, setHeight] = useState();

	const textWithoutNonBreakingSpace = (getText(textId) || "").replace(
		/ /g,
		" "
	);
	const preText = getText(textIdPrefix) && getText(textIdPrefix) + " ";

	useEffect(() => {
		if (preCalculateHeight && refGhost.current) {
			setHeight(refGhost.current.getBoundingClientRect().height);
			onReady && onReady({ ...props, height });
		}
	}, [refGhost.current, preCalculateHeight]);

	useEffect(() => {
		if (enabled) {
			const typewriter = new Typewriter(ref.current, {
				loop: false,
				delay: speed,
				cursorClassName: "d-none",
				onCreateTextNode: (character) => document.createTextNode(character),
				stringSplitter: (str) => str.match(/.{1,3}/g),
			});
			typewriter
				.pauseFor(startDelay)
				.typeString(textWithoutNonBreakingSpace)
				.callFunction(() => {
					onComplete && onComplete();
				})
				.start();
			onStart && onStart();
		}
	}, [enabled]);

	if (!enabled) {
		return <Text textId={textId} style={style} className={className}></Text>;
	}

	return (
		<Root show={_.isNumber(height)} height={height}>
			<span className={classNamePrefix}>{preText}</span>
			<span ref={ref} style={style} className={classnames(className)} />
			<GhostText
				ref={refGhost}
				textId={textId}
				style={style}
				className={className}
				dangerouslySetInnerHTML={{ __html: getText(textId) }}
			></GhostText>
		</Root>
	);
};

export default TextWriter;
