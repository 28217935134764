import React from "react";
import classnames from "classnames";
import { PageItemImage } from "funkis-foundation";

import Style from "./Image.module.css";

const Image = ({
	src,
	maxHeight,
	size,
	style = {},
	className,
	align,
	alignBottom = false,
	children,
	autoSize,
	background,
	isZoomable,
}) => {
	// default argumments overriding all falsy values, including empty string which is what CMT produces
	align = align || "center";
	size = size || "ratio";

	const crop = size === "crop";
	const backgroundImage =
		background !== undefined ? background : crop || maxHeight;

	return (
		<PageItemImage
			pageItem={{
				src,
				backgroundImage,
				autoSize: autoSize != undefined ? autoSize : crop && !maxHeight,
				width: "auto",
				height: maxHeight ? maxHeight : "auto",
				isZoomable,
			}}
			style={{ height: maxHeight ? maxHeight : "auto", ...style }}
			className={classnames(
				"w-100",
				(crop && Style.cover) || (maxHeight && Style.contain),
				align === "center-center" && Style.alignCenterCenter,
				align === "center" &&
					Style[`align${alignBottom ? "Bottom" : ""}Center`],
				align === "left" && Style[`align${alignBottom ? "Bottom" : ""}Left`],
				align === "right" && Style[`align${alignBottom ? "Bottom" : ""}Right`],
				className
			)}
		>
			{children}
		</PageItemImage>
	);
};

export default Image;
