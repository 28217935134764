import { getReducer } from "funkis-foundation/utils/StoreUtils";

type SizeObject = {
	name: string;
	className: string;
	minWidth: number;
	maxWidth: number;
	pageContainer: "portrait" | "default";
};

export function getSizeObjectForSize(
	config: any,
	width: number,
	height: number
): SizeObject | undefined {
	return (
		(config &&
			config.sizes.find(
				(sizeClassObj: SizeObject) =>
					width > sizeClassObj.minWidth &&
					(width <= sizeClassObj.maxWidth || sizeClassObj.maxWidth === null)
			)) || { className: "", pageContainer: "default" }
	);
}

export function getBasePath(basePath?: string) {
	const config = getReducer("config").item;
	return (
		basePath ||
		(config &&
		config.paths &&
		config.paths.basePath &&
		config.paths.basePath !== ""
			? config.paths.basePath
			: "./")
	);
}
