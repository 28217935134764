
import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	removeItemInStateItems,
} from "../utils/reducerUtils";

import {
	UPDATE_QUIDDITY_SESSIONS,
	UPDATE_QUIDDITY_SESSION,
	REMOVE_QUIDDITY_SESSION,
} from "../constants/ActionConstants";

const quidditySessions = (state = initialStateObjectCollection(), action) => {
	switch (action.type) {
		case UPDATE_QUIDDITY_SESSIONS:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_QUIDDITY_SESSION:
			return updateItemInStateItems(action, action.item);
		case REMOVE_QUIDDITY_SESSION:
			return removeItemInStateItems(state, action.item);
		default:
			return state;
	}
};

export default quidditySessions;
