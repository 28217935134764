import { gsap } from "gsap";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import MultipleChoiceOption from "../../components/slide/MultipleChoiceOption";
import { Backend, IOContext, QuidType, useBackend } from "../../hooks/backend";
import { useValueListItem } from "../../hooks/valuelist";
import { getBootstrapColor } from "../../utils/colorUtils";
import { connect } from "../../utils/reduxUtils";

type SelectionGridProps = {
	layout: "1x2" | "1x3" | "2x2" | "2x3" | "2x5" | "6x8";
};

const SelectionGrid = styled.div<SelectionGridProps>`
	display: grid;
	grid-template-columns: ${({ layout }) =>
		(layout === "1x2" && "1fr 1fr") ||
		(layout === "1x3" && "1fr 1fr 1fr") ||
		(layout === "2x2" && "1fr 1fr") ||
		(layout === "2x3" && "1fr 1fr 1fr") ||
		(layout === "2x5" && "1fr 1fr 1fr 1fr 1fr") ||
		(layout === "6x8" && "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr") ||
		"auto"};
	grid-template-rows: ${({ layout }) =>
		(layout === "1x2" && "auto") ||
		(layout === "1x3" && "auto") ||
		(layout === "2x2" && "1fr 1fr") ||
		(layout === "2x3" && "1fr 1fr") ||
		(layout === "2x5" && "1fr 1fr") ||
		(layout === "6x8" && "1fr 1fr 1fr 1fr 1fr 1fr") ||
		"auto"};
	gap: ${({ layout }) => (layout === "6x8" && "24px") || "1px"};
`;

const Shadow = styled.div`
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
`;

type CuepointProps = {
	element: any;
	inTime: number;
	outTime: number;
	left: number;
	top: number;
	ioId?: string;
	ioQuidType?: QuidType;
	ioContext?: IOContext;
	ioIndex?: number;
} & ContentArrayItem;

type ContentArrayItem = {
	in_timeout_time_secxy_percent: string;
	body_column_1: TextId;
	body_column_2: TextId;
	header: TextId;
	id: string;
	media: string;
};

const composeCuepointProp = (
	element: any,
	content: ContentArrayItem
): CuepointProps => {
	const [inTime, outTime, left, top, ioId, ioQuidType, ioIndex] =
		content.in_timeout_time_secxy_percent.split(",");
	return {
		element,
		...content,
		inTime: Number(inTime),
		outTime: Number(outTime),
		left: Number(left),
		top: Number(top),
		ioId,
		ioQuidType: ioQuidType as QuidType,
		ioIndex: ioIndex ? Number(ioIndex) : undefined,
	};
};

const Display: React.FC<{ show: boolean }> = ({ children, show }) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		gsap.set(ref.current, { opacity: 0 });
	}, []);

	useEffect(() => {
		if (show) {
			gsap.to(ref.current, { opacity: 1, duration: 0.4 });
		} else {
			gsap.to(ref.current, { opacity: 0, duration: 0.4 });
		}
	}, [show]);

	return <div ref={ref}>{children}</div>;
};

type IOCuepointProps = {
	backend: Backend;
	quidType: QuidType;
	index?: number;
};

const IOCuepoint: React.FC<IOCuepointProps> = ({
	backend,
	quidType,
	index: preferredIndex,
}) => {
	const index = preferredIndex ? preferredIndex - 1 : 0;
	const quidInput = _.sortBy(backend.quidInputs, "updatedAt").at(index);

	if (!quidInput) {
		return <div>COULDN'T FIND CONTENT IN IO</div>;
	}

	switch (quidType) {
		case QuidType.ContentSelection:
			const contentArrayItem = useValueListItem(quidInput.data.contentItemId);
			const itemTextColor = getBootstrapColor("#2e4051");
			const itemBackgroundColor = getBootstrapColor("#f5f5f5");
			const layout = "6x8";

			return (
				<SelectionGrid className="h-100" layout={layout}>
					<Shadow>
						<MultipleChoiceOption
							index={index}
							layout={layout}
							optionTextId={contentArrayItem.option}
							image={contentArrayItem.image}
							backgroundColor={itemBackgroundColor}
							textAlign="bottom-center"
							selected={false}
							identifier={undefined}
						/>
					</Shadow>
				</SelectionGrid>
			);

		default:
			return <div>{`NO SUPPORT FOR "${quidType}" YET`}</div>;
	}
};

type CuepointsProps = {
	timeId?: string;
	displayElement: any;
	time?: number;
	contentArray: any[];
	classNameDisplayElement?: string;
	displayProps?: any;
	mapContentAttributes?: any;
};

const Cuepoints: React.FC<CuepointsProps> = ({
	timeId, // A time id (if video same as videId)
	displayElement,
	time = 0, // from connect
	contentArray = [],
	classNameDisplayElement,
	displayProps = {},
	mapContentAttributes = {
		header: "headerTextId",
		body_column_1: "bodyTextId",
	}, // default mapped to MessageText.jsx as display element
}) => {
	const cuepointItems = contentArray.map((contentArrayItem) =>
		composeCuepointProp(displayElement, contentArrayItem)
	);

	const backends: Record<string, Backend> = cuepointItems
		.filter((cuepoint) => cuepoint.ioId && cuepoint.ioQuidType)
		.reduce(
			(acc, cuepoint) => ({
				...acc,
				[cuepoint.id]: useBackend({
					targetDataKey: cuepoint.ioId!,
					quidType: cuepoint.ioQuidType!,
					inputContext: cuepoint.ioContext,
				}),
			}),
			{}
		);

	return (
		<React.Fragment>
			{cuepointItems.map((cuepointItem, index) => {
				const DisplayElement = cuepointItem.element;
				const shouldDisplay =
					time >= cuepointItem.inTime && time <= cuepointItem.outTime;

				// extracting attributes by map object (mapContentAttributes)
				const attributes = {};
				_.forEach(mapContentAttributes, (key, value) => {
					attributes[key] = cuepointItem[value];
				});

				const backend = backends[cuepointItem.id];

				return (
					<Display key={`cuepoints-${index}`} show={shouldDisplay}>
						{backend ? (
							<div
								style={{
									position: "absolute",
									left: cuepointItem.left + "%",
									top: cuepointItem.top + "%",
									width: "100%",
									height: "100%",
									pointerEvents: "none",
								}}
							>
								<IOCuepoint
									backend={backend}
									quidType={cuepointItem.ioQuidType!}
									index={cuepointItem.ioIndex}
								/>
							</div>
						) : (
							<DisplayElement
								className={classNameDisplayElement}
								{...attributes}
								{...displayProps}
								left={cuepointItem.left}
								top={cuepointItem.top}
							/>
						)}
					</Display>
				);
			})}
		</React.Fragment>
	);
};

export default Cuepoints;
