import React, { useState, useEffect } from "react";
import classnames from "classnames";
import _ from "lodash";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import IOCommentOutput from "funkis-template/pageitems/scroll/CommentOutput/IOCommentOutput";
import Backend, { save } from "../../components/backend/Backend";
import Text from "../../components/core/Text";
import styled from "styled-components";
import { getBootstrapColor } from "../../utils/colorUtils";

const Comment = styled(IOCommentOutput)`
	cursor: pointer;
	.background {
		:hover {
			background-color: ${() =>
				getBootstrapColor("interaction", 80)} !important;
		}
		transition: 0.4s background-color;
	}
`;

const PageItemIOCommentSelectInput = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;
	const ioID = cmtPageItem.io_id;
	const [numSelected, setNumSelected] = useState();
	const [showNextButton, setShowNextButton] = useState();
	const minSelections = cmtPageItem.min_selections || 0;
	const maxSelections = cmtPageItem.max_selections || Number.MAX_VALUE;
	const maxSelectionsReached = numSelected >= maxSelections;

	useEffect(() => {
		setShowNextButton(
			numSelected >= minSelections && numSelected <= maxSelections
		);
	}, [numSelected]);

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={showNextButton}
			renderFunction={(renderProps) => {
				return (
					<React.Fragment>
						<div className={classnames("row m-0")}>
							{/* HEADER */}
							<Text
								className={classnames(
									"col-sm-12 mb-5 font-weight-bold animated",
									renderProps.viewVisible ? "fadeIn" : "fadeOut"
								)}
								tagName="h2"
								textId={cmtPageItem.header}
							/>
							{/* BODY */}
							<Text
								className={classnames(
									"col-sm-12 mb-4 animated",
									renderProps.viewVisible ? "dealy-250ms fadeIn" : "fadeOut"
								)}
								tagName="p"
								textId={cmtPageItem.body}
							/>
						</div>
						<Backend
							{...props}
							className="row m-0"
							quidType="comment"
							mode="bareOutputs"
							inputContext={cmtPageItem.io_context}
							targetDataKey={ioID}
							renderFunction={(backendRenderProps) => {
								const { quidInputs } = backendRenderProps;
								setNumSelected(
									quidInputs.filter((quidInput) => quidInput.data.selected)
										.length
								);

								return quidInputs.map((quidInput) => (
									<Comment
										onClick={() => {
											if (!maxSelectionsReached || quidInput.data.selected) {
												save({
													id: quidInput.id,
													data: {
														...quidInput.data,
														selected: !quidInput.data.selected,
													},
												});
											}
										}}
										key={`comment-${quidInput.id}`}
										quidInput={quidInput}
										data={quidInput.data}
										className={classnames(
											"col-12 mb-2 mb-md-1",
											_.get(quidInput, "data.selected") && "selected-comment"
										)}
										backgroundColor={cmtPageItem.comment_background_color}
										textColor={cmtPageItem.comment_text_color}
										barColor={cmtPageItem.comment_bar_color}
										showUser={cmtPageItem.show_comment_author !== "no"}
										showDate={cmtPageItem.show_comment_date !== "no"}
										showSelectionIcon={true}
									/>
								));
							}}
						/>
					</React.Fragment>
				);
			}}
		></PageItemScrollBase>
	);
};

export default PageItemIOCommentSelectInput;
