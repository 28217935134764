import React from "react";
import _ from "lodash";
import classnames from "classnames";
import { connect } from "../../utils/reduxUtils";
import { AppActions, DomUtils } from "funkis-foundation";
import LightBox from "./LightBox";
import Text from "./Text";

const globalTextIdsByFunction = {
	promptHeader: "8E572AF6-1E09-49EA-82DB-0350EF1A02FD",
	promptBody: "18B4DCA6-C976-42A8-904C-DC2994D94874",
};

const PromptBrowserSwitch = ({
	hasShownPromptBrowserSwitch,
	shouldWarnInternetExplorerUsers,
}) => {
	const isInternetExplorer = DomUtils.checkIsInternetExplorer();
	return (
		<LightBox
			skipUrlChange={true}
			show={
				shouldWarnInternetExplorerUsers &&
				isInternetExplorer &&
				!hasShownPromptBrowserSwitch
			}
			onCloseClick={() =>
				AppActions.updateAppValue({ hasShownPromptBrowserSwitch: true })
			}
			className={classnames("prompt-browser-switch")}
			classNameContentContainer={classnames(
				"prompt-browser-switch-content-container"
			)}
		>
			<Text
				className="message-lg"
				textId={globalTextIdsByFunction.promptHeader}
				tagName="h2"
			/>
			{/* use span because the body text can be rich text with multiple paragraphs */}
			<Text
				className="p-md"
				textId={globalTextIdsByFunction.promptBody}
				tagName="span"
			/>
		</LightBox>
	);
};

export default connect(PromptBrowserSwitch, function mapStateToProps(state) {
	return {
		hasShownPromptBrowserSwitch: _.get(
			state,
			"app.item.hasShownPromptBrowserSwitch",
			false
		),
		// default is yes
		shouldWarnInternetExplorerUsers:
			_.get(state, "app.item.warn_if_internet_explorer") !== "no",
	};
});
