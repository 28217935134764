import React from "react";

const IconPrevious = () => {
	return (
		<svg width="25px" height="30px" viewBox="0 0 25 30" version="1.1">
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="video/button/reset"
					transform="translate(-31.000000, -30.000000)"
				>
					<g
						id="Group-6"
						transform="translate(45.000000, 45.000000) rotate(180.000000) translate(-45.000000, -45.000000) "
					>
						<g
							id="Group"
							transform="translate(34.074200, 30.827000)"
							fill="#FFFFFF"
						>
							<path
								d="M3.19744231e-13,0.0008 L3.19744231e-13,28.3448 L18.896,14.1728 L3.19744231e-13,0.0008 Z M5,10.0008 L10.563,14.1728 L5,18.3448 L5,10.0008 Z"
								id="Fill-4"
							></path>
							<polygon
								id="Fill-5"
								points="19.3968 28.18 24.3968 28.18 24.3968 3.19744231e-14 19.3968 3.19744231e-14"
							></polygon>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default IconPrevious;
