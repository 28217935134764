import classnames from "classnames";
import { QuidType, useBackend } from "funkis-template/hooks/backend";
import { PageItemMedia } from "funkis-template/models/player";
import React from "react";
import { getLocalizedMediaPath, getMediaType } from "utils/MediaUtils";
import { getText } from "../../utils/textUtils";

export enum MediaCrop {
	Crop = "Crop",
	ResizeToFit = "Resize to fit",
}

export enum MediaFit {
	FullWidth = "Full width",
	AlignCenter = "Align center",
	AlignLeft = "Align left",
	AlignRight = "Align right",
}

type MediaProps = MediaElementProps & {
	mediaFit: MediaFit;
	mediaCrop: MediaCrop;
};

type MediaElementProps = {
	src: PageItemMedia;
	width?: string | number;
	height?: string | number;
	alt?: string;
	style?: React.CSSProperties;
	className?: string;
};

const UserUpload = ({ io, style, className, fallbackSrc }) => {
	const { quidInputs } = useBackend({
		targetDataKey: io.id,
		inputContext: io.context,
		quidType: QuidType.MediaUpload,
	});

	const quidInput = quidInputs.at(-1);

	return (
		<img
			style={style}
			className={className}
			src={quidInput?.data.src ?? fallbackSrc}
			alt=""
			loading="lazy"
		/>
	);
};

const MediaElement: React.FC<MediaElementProps> = ({
	src,
	className,
	style,
	alt,
	width,
	height,
}) => {
	const mediaType = getMediaType(src);
	const fullSrc = getLocalizedMediaPath(src);
	const altText: string | undefined = alt && getText(alt);

	if (typeof src === "object" && src.io) {
		return (
			<UserUpload
				style={style}
				className={className}
				io={src.io}
				fallbackSrc={fullSrc}
			/>
		);
	}

	switch (mediaType) {
		case "image":
			return (
				<img
					src={fullSrc}
					style={style}
					className={className}
					alt={altText}
					width={width}
					height={height}
					loading="lazy"
				/>
			);

		case "video":
			return (
				<video
					src={fullSrc}
					style={style}
					className={className}
					width={width}
					height={height}
					autoPlay={true}
					muted={true}
					controls={false}
					loop={true}
					playsInline={true}
				/>
			);

		case "audio":
		case "unknown":
			return <div>{`Media of type "${mediaType}" is not supported.`}</div>;
	}
};

const composeMediaFitClassNames = (
	mediaFit: MediaFit
): { wrapper?: string; component?: string } => {
	switch (mediaFit) {
		case MediaFit.FullWidth:
			return { component: "w-100" };

		case MediaFit.AlignCenter:
			return { wrapper: "justify-content-center" };

		case MediaFit.AlignLeft:
			return { wrapper: "justify-content-start" };

		case MediaFit.AlignRight:
			return { wrapper: "justify-content-end" };

		default:
			return {};
	}
};

const Media: React.FC<MediaProps> = ({
	src,
	width,
	height,
	alt,
	className,
	style,
	mediaFit,
	mediaCrop,
}) => {
	const mediaClassNames = composeMediaFitClassNames(mediaFit);

	return (
		<div
			style={style}
			className={classnames(
				className,
				"d-flex",
				{ "overflow-hidden": mediaCrop === MediaCrop.Crop },
				mediaClassNames.wrapper
			)}
		>
			<MediaElement
				src={src}
				width={width}
				height={height}
				alt={alt}
				style={{
					// "scale-down" is interfering with the fullwidth images in very wide screen sizes
					// so we make sure to only use it when we're not explicitly using the full width.
					objectFit:
						mediaCrop === MediaCrop.ResizeToFit &&
						mediaFit !== MediaFit.FullWidth
							? "scale-down"
							: undefined,
					objectPosition:
						mediaCrop === MediaCrop.ResizeToFit ? "bottom center" : undefined,
				}}
				className={classnames(
					{
						"mw-100": mediaCrop === MediaCrop.ResizeToFit,
					},
					mediaClassNames.component
				)}
			/>
		</div>
	);
};

export default Media;
