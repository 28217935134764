import { asyncForEach } from "../../utils/ArrayUtils";
import {
	insertStylesForThemeColors,
	setDocumentTitle,
	insertStyleTagInHead,
} from "../../utils/DomUtils";
import { updateApp } from "../../redux/actions/AppActions";

export const parseCMTSettings = ({ cmtParseSchemes, cmtPages, texts }) => {
	let cmtSettingPages = [
		cmtPages.find((cmtPage) => cmtPage.hierarchy_level === 0),
	];

	return asyncForEach(
		cmtSettingPages,
		(cmtSettingPage, index) => {
			if (cmtSettingPage.primary && cmtSettingPage.secondary) {
				insertStylesForThemeColors({
					primary: cmtSettingPage.primary,
					secondary: cmtSettingPage.secondary,
					interaction: cmtSettingPage.interaction || cmtSettingPage.primary,
					info: cmtSettingPage.info || "#17a2b8",
					warning: cmtSettingPage.warning || "#ffc107",
					danger: cmtSettingPage.danger || "#dc3545",
					success: cmtSettingPage.success || "#6dc200",
					fail: cmtSettingPage.fail || "#ff548a",
				});
			}

			if (cmtSettingPage.style_overrides) {
				insertStyleTagInHead(cmtSettingPage.style_overrides);
			}
			const titleTextObj =
				texts.find((t) => t.id === cmtSettingPage.title_txt_id) || {};
			titleTextObj.value && setDocumentTitle(titleTextObj.value);
			updateApp({ ...cmtSettingPage });
		},
		() => {
			return { cmtSettingPages };
		}
	);
};
