import classnames from "classnames";
import { ColorUtils, FFButton } from "funkis-foundation";
import { PageItemMedia } from "funkis-template/models/player";
import gsap from "gsap";
import _ from "lodash";
import React from "react";
import IconPause from "../../../assets/svg/IconPause";
import IconPlay from "../../../assets/svg/IconPlay";
import IconPrevious from "../../../assets/svg/IconPrevious";
import IconReplay from "../../../assets/svg/IconReplay";
import IconSkip from "../../../assets/svg/IconSkip";
import Hover from "../Hover";
import { VideoState } from "./VideoPlayer";
import Style from "./VideoPlayerControls.module.css";
import VideoPlayerProgressBar from "./VideoPlayerProgressBar";
import VideoPlayerSubtitles from "./VideoPlayerSubtitles";

const isTouchDevice = () => {
	return "ontouchstart" in window;
};

const railStyle = {
	position: "absolute",
	height: "100%",
	width: "100%",
} as React.CSSProperties;

const getTrackStyle = (): React.CSSProperties => ({
	position: "absolute",
	height: "100%",
	width: "100%",
	backgroundColor: ColorUtils.getBootstrapColor("primary"),
});

const getHandleStyle = ({
	highlight = false,
	hover = false,
}): React.CSSProperties => {
	const size = hover ? 12 : 0;
	const color = hover ? "interaction" : "primary";
	return {
		position: "absolute",
		top: "-1px",
		borderRadius: size * 0.5,
		marginLeft: size * 0.5 * -1 + 1,
		width: size,
		height: size,
		transition: "opacity",
		opacity: hover ? 1 : 0,
		backgroundColor: ColorUtils.getBootstrapColor(color),
		border: hover
			? `solid 2px ${ColorUtils.getBootstrapColor(color)}`
			: undefined,
	};
};

type ButtonProps = {
	onClick: (e: Event) => void;
	state?: VideoState;
	style?: React.CSSProperties;
};

const PlayPauseButton: React.FC<ButtonProps> = ({ state, style, onClick }) => {
	return (
		<>
			{/* @ts-expect-error FFButton's auto generated types are wonky */}
			<FFButton
				onClick={onClick}
				style={style}
				className={classnames(
					"btn-md rounded-pill btn-video-controls btn-interaction-inverted mx-3",
					Style.buttonRoundedMedium,
					"page-item page-item-video-play-pause-button video-button",
					`action-playPause`,
					`video-state-${state}`
				)}
			>
				{state !== "playing" ? <IconPlay /> : <IconPause />}
			</FFButton>
		</>
	);
};

const SkipButton: React.FC<ButtonProps> = ({ onClick, style }) => {
	return (
		<>
			{/* @ts-expect-error FFButton's auto generated types are wonky */}
			<FFButton
				className={classnames(
					"btn-md rounded-pill btn-video-controls btn-interaction-inverted",
					Style.buttonRoundedMedium
				)}
				style={style}
				onClick={onClick}
			>
				<IconSkip />
			</FFButton>
		</>
	);
};

const PreviousButton: React.FC<ButtonProps> = ({ onClick, style }) => {
	return (
		<>
			{/* @ts-expect-error FFButton's auto generated types are wonky */}
			<FFButton
				className={classnames(
					"btn-md rounded-pill btn-video-controls btn-interaction-inverted mr-3",
					Style.buttonRoundedMedium
				)}
				style={style}
				onClick={onClick}
			>
				<IconPrevious />
			</FFButton>
		</>
	);
};

const ReplayButton: React.FC<ButtonProps> = ({ onClick, style }) => {
	return (
		<>
			{/* @ts-expect-error FFButton's auto generated types are wonky */}
			<FFButton
				className={classnames(
					"btn-md rounded-pill btn-video-controls btn-interaction-inverted",
					Style.buttonRoundedMedium
				)}
				onClick={onClick}
				style={style}
			>
				<IconReplay style={{ marginTop: "-6px" }} className={undefined} />
			</FFButton>
		</>
	);
};

export type ProgressBarPosition = "bottom" | "subtitle";

const ProgressBar = ({
	higlightProgressBar,
	className,
	show = true,
	showAlways = false,
	currentTime,
	duration,
	onSeek,
}) => {
	return (
		<Hover
			className={classnames(
				Style.progressBar,
				(showAlways || higlightProgressBar) && Style.progressBarBig,
				"video-progress-bar animated",
				showAlways || show ? "fadeIn" : "fadeOut",
				className
			)}
			style={undefined}
		>
			{({ hover }) => {
				return (
					<VideoPlayerProgressBar
						railStyle={railStyle}
						trackStyle={getTrackStyle()}
						handleStyle={getHandleStyle({
							highlight: showAlways || higlightProgressBar,
							hover: showAlways || hover,
						})}
						sliderClassName={Style.progressBarSlider}
						currentTime={currentTime}
						duration={duration}
						onSeek={onSeek}
					/>
				);
			}}
		</Hover>
	);
};

type VideoPlayerControlsProps = {
	src: PageItemMedia;
	videoState: VideoState;
	className?: string;
	showSubtitles: boolean;
	showProgressBar: boolean;
	currentTime: number;
	duration: number;
	onClickPrevious?: () => void;
	onClickReplay: () => void;
	onClickPlayPause: () => void;
	onClickSkip: () => void;
	onSeek: (nextTime: number) => void;
} & ClassNameOverrideProps;

export type ClassNameOverrideProps = {
	classNameSubtitle?: string;
	classNameSubtitleContainer?: string;
	classNameVideoControls?: string;
	classNameVideoButtonsContainer?: string;
};

const INTERACTION_TIMEOUT = 2500;

const VideoPlayerControls: React.FC<VideoPlayerControlsProps> = (props) => {
	const {
		src,
		videoState,
		showSubtitles,
		showProgressBar,
		className,
		onClickPrevious,
		onClickReplay,
		onClickPlayPause,
		onClickSkip,
		onSeek,
		currentTime,
		duration,
		classNameVideoControls,
		classNameVideoButtonsContainer,
	} = props;

	const [isInteracting, setIsInteracting] = React.useState(false);
	const buttonContainerRef = React.useRef<HTMLDivElement>(null);

	const debouncedDisableInteraction = React.useCallback(
		_.debounce(() => setIsInteracting(false), INTERACTION_TIMEOUT),
		[]
	);

	const throttledEnableInteraction = React.useCallback(
		_.throttle(() => {
			setIsInteracting(true);
			debouncedDisableInteraction();
		}, 300),
		[debouncedDisableInteraction]
	);

	React.useEffect(() => {
		return () => {
			debouncedDisableInteraction.cancel();
			throttledEnableInteraction.cancel();
		};
	}, [debouncedDisableInteraction, throttledEnableInteraction]);

	const handleMouseMove = React.useCallback(throttledEnableInteraction, [
		throttledEnableInteraction,
	]);

	const handleMouseLeave = React.useCallback(() => {
		throttledEnableInteraction.cancel();
		setIsInteracting(false);
	}, [throttledEnableInteraction]);

	const handleClick = React.useCallback(
		(e) => {
			// We only want to hide the controls when
			// user clicked/tapped on the overlay but not
			// on the buttons.
			if (e.target === e.currentTarget) {
				setIsInteracting(!isInteracting);
				debouncedDisableInteraction();
			}
		},
		[isInteracting, debouncedDisableInteraction]
	);

	const showControls = isInteracting || videoState !== "playing";

	React.useEffect(() => {
		const animation = gsap.to(buttonContainerRef.current, {
			autoAlpha: showControls ? 1 : 0,
			// Note: keep durations the same. see issue #44
			duration: showControls ? 0.3 : 0.3,
		});

		return () => {
			animation.progress(0).kill();
		};
	}, [showControls]);

	return (
		<div
			className={classnames(
				Style.videoControlsContainer,
				"video-controls-container",
				className,
				classNameVideoControls
			)}
			onMouseMove={isTouchDevice() ? undefined : handleMouseMove}
			onMouseLeave={handleMouseLeave}
			onClick={handleClick}
		>
			<div
				ref={buttonContainerRef}
				className={classnames(
					"video-buttons-container abolute-top w-100 h-100 d-flex justify-content-center align-items-center",
					Style.videoControlsButtonContainer,
					classNameVideoButtonsContainer
				)}
			>
				{onClickPrevious && (
					<PreviousButton
						onClick={() => {
							onClickPrevious();
						}}
						style={undefined}
					/>
				)}

				{/* REPLAY BUTTON */}
				{videoState !== "idle" && (
					<ReplayButton
						onClick={() => {
							onClickReplay();
						}}
					/>
				)}

				{/* PLAY/PAUSE BUTTON */}
				{videoState !== "ended" && (
					<PlayPauseButton
						state={videoState}
						onClick={() => {
							onClickPlayPause();
						}}
					/>
				)}

				{/* SKIP BUTTON */}
				{videoState !== "ended" && videoState !== "idle" && (
					<SkipButton
						onClick={() => {
							onClickSkip();
						}}
					/>
				)}
			</div>

			{showSubtitles && (
				<div className={Style.subtitleContainer}>
					<VideoPlayerSubtitles
						src={src}
						currentTime={currentTime}
						variant="overlay"
						videoState={videoState}
					/>
				</div>
			)}

			{/* progress bar */}
			<ProgressBar
				higlightProgressBar={showControls}
				show={showControls}
				showAlways={showProgressBar}
				className={undefined}
				currentTime={currentTime}
				duration={duration}
				onSeek={onSeek}
			/>
		</div>
	);
};

export default VideoPlayerControls;
