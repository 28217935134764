import React from "react";
import { connect } from "react-redux";

import PageItem from "../components/core/PageItem";
import {
	makeGetPageItemById,
	makeGetPageItemByRelativePathSelector,
} from "../selectors/PageItemSelectors";
import { makeGetItemsSelector } from "../redux/selectors/CollectionSelectors";

class PageItemLinked extends React.Component {
	render() {
		const { pageItem, style, sourcePageItem, className } = this.props;

		if (!sourcePageItem) {
			return <span>{"No sourcePageItem found"}</span>;
		}
		const { pageItemOverrides } = pageItem;

		return (
			<PageItem id={sourcePageItem.id} pageItemOverrides={pageItemOverrides} />
		);
	}
}

const makeMapStateToProps = () => {
	const getPageItemById = makeGetPageItemById();
	const getItemsSelector = makeGetItemsSelector();
	const getPageItemByRelativePathSelector =
		makeGetPageItemByRelativePathSelector();
	const mapStateToProps = (state, props) => {
		const { pageItem } = props;
		const { sourcePageItemId } = pageItem;

		let pageItemsByFriendlyId;
		let pageItemByPath;

		if (Array.isArray(sourcePageItemId)) {
			pageItemByPath = getPageItemByRelativePathSelector(state, {
				...props,
				path: sourcePageItemId,
				relativeToPageItemId: pageItem.id,
			});
		}

		if (!pageItemByPath) {
			pageItemsByFriendlyId = getItemsSelector(
				state,
				{ ...props, match: { friendlyId: sourcePageItemId } },
				"pageItems"
			);
		}

		return {
			sourcePageItem:
				getPageItemById(state, { id: sourcePageItemId }) ||
				pageItemByPath ||
				pageItemsByFriendlyId[0],
		};
	};
	return mapStateToProps;
};

export default connect(makeMapStateToProps)(PageItemLinked);
