import React, { Suspense } from "react";
import classnames from "classnames";
import _ from "lodash";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import Text from "../../components/core/Text";
import Image from "../../components/core/Image";
import { getBootstrapColor } from "../../utils/colorUtils";
import { getText } from "../../utils/textUtils";
import PaperClip from "../../assets/svg/PaperClip";

const LazyLoadedJaggedBottom = React.lazy(() => import("funkis-template/assets/svg/JaggedBottom"));

import Styles from "./PageItemInfoBox.module.css";

const MODES = {
	CLIP: "Clip",
	RIPPED_PAPER: "RippedPaper",
};

const InfoBox = (props) => {
	const { pageItem, viewVisible } = props;
	const { cmtPageItem = {} } = pageItem;
	const {
		header,
		body,
		image = "test.png",
		image_max_height = 0,
		image_align = "left",
		mode,
		text_color,
		text_background_color,
		rotation = 0,
	} = cmtPageItem;

	const maxRotation = 3;
	const rotateDeg = _.clamp(rotation, maxRotation * -1, maxRotation);

	const backgroundColor = getBootstrapColor(
		text_background_color || "light-grey"
	);
	const textColor = getBootstrapColor(text_color || "black");

	return (
		<div
			className={classnames(
				"mb-4",
				Styles.root,
				mode == MODES.RIPPED_PAPER ? Styles.RippedPaper : Styles.Clip
			)}
		>
			<div className="row m-0">
				<div className="col-12">
					<Text
						className={classnames(
							"mb-6 font-weight-bold animated",
							viewVisible ? "fadeInUp" : "fadeOut"
						)}
						tagName="h2"
						textId={header}
					/>
				</div>

				<div
					style={{ transform: `rotate(${rotateDeg}deg)` }}
					className={classnames("col", Styles.box, {
						"mt-4": !getText(header),
					})}
				>
					{mode == MODES.CLIP && (
						<PaperClip
							className={classnames("w-100", Styles.clipImage, mode)}
							fillColor={backgroundColor}
						/>
					)}
					<div
						className={classnames(
							"px-5 px-md-6 pt-6",
							mode == MODES.CLIP ? "pb-6" : "pb-5",
							Styles.info
						)}
						style={{ backgroundColor: backgroundColor }}
					>
						<Text
							className={classnames(
								"col-12 col-sm-10 animated",
								viewVisible ? "fadeIn delay-1" : "fadeOut"
							)}
							tagName="div"
							style={{ color: textColor }}
							textId={body}
						/>
						{image && (
							<Image
								className="mt-4 p-0 col-md-10"
								align={image_align}
								maxHeight={image_max_height}
								src={image}
							></Image>
						)}
					</div>
					{mode === MODES.RIPPED_PAPER && (
						<Suspense fallback={<></>}>
							<LazyLoadedJaggedBottom
								className={classnames("w-100", Styles.jaggedBottom)}
								fillColor={backgroundColor}
							/>
						</Suspense>
					)}
				</div>
			</div>
		</div>
	);
};

const PageItemInfoBox = (props) => {
	return (
		<PageItemScrollBase
			{...props}
			renderFunction={(renderProps) => (
				<InfoBox {...props} viewVisible={renderProps.viewVisible}></InfoBox>
			)}
		/>
	);
};

export default PageItemInfoBox;
