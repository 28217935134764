import React from "react";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import ImageWithCaption from "../../components/core/ImageWithCaption";

const PageItemImage = (props) => {
	const { pageItem } = props;
	const { cmtPageItem = {} } = pageItem;

	const imageSrc = cmtPageItem.image;
	const imageCaption = cmtPageItem.image_caption;
	const imageMaxHeight = cmtPageItem.image_max_height;
	const imageAlign = cmtPageItem.image_align;

	return (
		<PageItemScrollBase
			{...props}
			className="px-0 mx-0"
			renderFunction={(renderProps) => (
				<React.Fragment>
					{/* IMAGE WITH CAPTION */}
					<ImageWithCaption
						src={imageSrc}
						maxHeight={imageMaxHeight}
						className="mb-5 row m-0"
						classNameImage="w-100 px-sm-0 px-md-5"
						classNameCaption="col-md-7 px-5"
						caption={imageCaption}
						show={renderProps.viewVisible}
						size={cmtPageItem.image_size}
						align={imageAlign}
					/>
				</React.Fragment>
			)}
		/>
	);
};

export default PageItemImage;
