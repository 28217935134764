import React, { useState, useEffect } from "react";
import QuizPoints from "./QuizPoints";

const QuizScoreCounter = ({
	baseColor = "primary",
	score,
	textIdTotalScore = "D614632A-7DE6-4EED-9F9B-5514481D31D2",
}) => {
	const [currentScore, setCurrentScore] = useState(score);
	const [previousScore, setPreviousScore] = useState(score);
	const [showPoints, setShowPoints] = useState(true); // Fulhack för att trigga restart av count up

	useEffect(() => {
		// never animate from x TO zero. 
		const previousScore = score === 0 ? 0 : currentScore;
		setPreviousScore(previousScore);
		setCurrentScore(score);
		setShowPoints(false);
		setTimeout(() => {
			setShowPoints(true);
		}, 0);
	}, [score]);

	return (
		<div className="position-relative">
			<QuizPoints
				labelId={textIdTotalScore}
				duration={1}
				color={baseColor}
				show={showPoints}
				from={previousScore}
				to={currentScore}
			/>
		</div>
	);
};

export default QuizScoreCounter;
