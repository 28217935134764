import Scorm12Constants from "./Scorm12Constants";

var scormCommunicator = window.pipwerks && window.pipwerks.SCORM;

export default class Scorm12Storage {
	constructor() {}

	save(name, domain, data) {
		this.setData(name, domain, data);
	}

	restore(name, domain, restoreFunction) {
		const data = this.getData(domain);
		const restoredData = restoreFunction(data[name]);
	}

	getData(domain) {
		var data = scormCommunicator.get(Scorm12Constants.SUSPEND_DATA);
		console.log(
			`Scorm12Storage: getData: Getting scorm variable '${Scorm12Constants.SUSPEND_DATA}'. Data:`,
			data
		);

		let jsonData;
		try {
			jsonData = JSON.parse(data);
		} catch (e) {
			console.log(
				`Scorm12Storage: getData: Error! Conld not parse. Data:`,
				data
			);
		}

		return jsonData || {};
	}

	setData(name, domain, data) {
		const oldData = this.getData(domain);
		const newData = Object.assign({}, oldData, { [name]: data });
		console.log(
			`Scorm12Storage: setData: Setting scorm variable '${Scorm12Constants.SUSPEND_DATA}' with data:`,
			newData
		);
		scormCommunicator.set(
			Scorm12Constants.SUSPEND_DATA,
			JSON.stringify(newData)
		);
		scormCommunicator.save();
	}

	clearData() {
		console.log("Scorm 1.2 Storage: clearData()");
		scormCommunicator.set(Scorm12Constants.SUSPEND_DATA, JSON.stringify({}));
		scormCommunicator.save();
	}

	setCourseStatus(domain, value) {
		console.log(
			`Scorm12Storage: setCourseStatus: Setting scorm variable '${Scorm12Constants.LESSON_STATUS}' with value:`,
			value
		);
		scormCommunicator.set(Scorm12Constants.LESSON_STATUS, value);
		scormCommunicator.save();
	}

	getCourseStatus(domain) {
		var value = scormCommunicator.get(Scorm12Constants.LESSON_STATUS);
		console.log(
			`Scorm12Storage: getCourseStatus: Getting scorm variable '${Scorm12Constants.LESSON_STATUS}'. Value:`,
			value
		);
		return value;
	}

	setCompletionStatus(domain, value) {
		console.log(`Scorm12Storage: setCompletionStatus: Not supported!`);
	}

	getCompletionStatus(domain) {
		console.log(`Scorm12Storage: getCompletionStatus: Not supported!`);
	}

	setSuccessStatus(domain, value) {
		console.log(`Scorm12Storage: setSuccessStatus: Not supported!`);
	}

	getSuccessStatus(domain) {
		console.log(`Scorm12Storage: getSuccessStatus: Not supported!`);
	}

	setScore(domain, rawScore, minScore, maxScore) {
		console.log("Scorm12Storage: setScore", rawScore, minScore, maxScore);
		scormCommunicator.set(Scorm12Constants.SCORE_RAW, rawScore);
		scormCommunicator.set(Scorm12Constants.SCORE_MIN, minScore);
		scormCommunicator.set(Scorm12Constants.SCORE_MAX, maxScore);
		scormCommunicator.save();
	}

	getScore(domain) {
		var value = scormCommunicator.get(Scorm12Constants.SCORE_RAW);
		console.log("Scorm12Storage: getScore", value);
		return value;
	}

	getUserName(domain) {
		var userName = scormCommunicator.get(Scorm12Constants.STUDENT_NAME);
		console.log(
			`Scorm12Storage: getUserName: Getting scorm variable '${Scorm12Constants.STUDENT_NAME}'. User name:`,
			userName
		);
		return userName;
	}

	getUserId(domain) {
		var userId = scormCommunicator.get(Scorm12Constants.STUDENT_ID);
		console.log(
			`Scorm12Storage: getUserId: Getting scorm variable '${Scorm12Constants.STUDENT_ID}'. User ID:`,
			userId
		);
		return userId;
	}

	getQuiddityTitleId() {
		console.log(`Scorm12Storage: getQuiddityTitleId: Not supported!`);
		return "";
	}
	getQuiddityTitleOrganizationId() {
		console.log(
			`Scorm12Storage: getQuiddityTitleOrganizationId: Not supported!`
		);
		return "";
	}

	getQuiddityTitleName() {
		console.log(`Scorm12Storage: getQuiddityTitleName: Not supported!`);
		return "";
	}
}
