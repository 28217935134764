import classNames from "classnames";
import Spacer from "components/Spacer/Spacer";
import Stack from "components/Stack/Stack";
import StatusIndicator from "components/StatusIndicator/StatusIndicator";
import Text from "funkis-template/components/core/Text";
import { TextId } from "funkis-template/models/player";
import _ from "lodash";
import {
	ChangeEvent,
	CSSProperties,
	FC,
	useCallback,
	useEffect,
	useState,
} from "react";
import Style from "./CommentInput.module.css";

const TIME_TO_DEBOUNCE = 1000;

export type CommentValue = {
	header: string | undefined;
	body: string | undefined;
};

export type CommentInputProps = {
	initialHeaderValue: string | undefined;
	initialBodyValue: string | undefined;
	onFinished: (value: CommentValue) => void;
	backgroundColor?: string;
	textColor?: string;
	rowsHeader: number;
	rowsBody: number;
	labelHeader: TextId;
	labelBody: TextId;
	maxCharactersHeader?: number;
	maxCharactersBody?: number;
};

const CommentInput: FC<CommentInputProps> = ({
	initialHeaderValue,
	initialBodyValue,
	onFinished,
	backgroundColor,
	textColor,
	rowsHeader,
	rowsBody,
	labelHeader,
	labelBody,
	maxCharactersHeader = 0,
	maxCharactersBody = 0,
}) => {
	const [value, setValue] = useState<CommentValue>({
		header: initialHeaderValue,
		body: initialBodyValue,
	});

	const [isFinished, setIsFinished] = useState<boolean | undefined>(undefined);
	const state = _.isEqual(value, {
		header: initialHeaderValue,
		body: initialBodyValue,
	})
		? "saved"
		: "saving";

	useEffect(() => {
		if (isFinished && onFinished && value) {
			setIsFinished(false);
			onFinished(value);
		}
	}, [isFinished, onFinished, value]);

	const debouncedSetFinished = useCallback(
		_.debounce(() => setIsFinished(true), TIME_TO_DEBOUNCE),
		[]
	);

	const handleHeaderTextAreaChange = useCallback(
		(e: ChangeEvent<HTMLTextAreaElement>) => {
			if (
				maxCharactersHeader === 0 ||
				e.target.value.length <= maxCharactersHeader
			) {
				setValue((prevValue) => ({ ...prevValue, header: e.target.value }));
			}
			setIsFinished(false);
			debouncedSetFinished();
		},
		[debouncedSetFinished]
	);

	const handleBodyTextAreaChange = useCallback(
		(e: ChangeEvent<HTMLTextAreaElement>) => {
			if (
				maxCharactersBody === 0 ||
				e.target.value.length <= maxCharactersBody
			) {
				setValue((prevValue) => ({ ...prevValue, body: e.target.value }));
			}
			setIsFinished(false);
			debouncedSetFinished();
		},
		[debouncedSetFinished]
	);

	const handleTextAreaBlur = useCallback(() => {
		debouncedSetFinished.flush();
	}, [debouncedSetFinished]);

	const customStyles = {
		"--comment-input-background-color": backgroundColor,
		"--comment-input-text-color": textColor,
	} as CSSProperties;

	const shouldUseFullHeight = rowsBody > 5;

	return (
		<div
			style={customStyles}
			className={classNames(Style.commentInput, {
				[Style.fullHeight]: shouldUseFullHeight,
				[Style.centered]: !shouldUseFullHeight,
			})}
		>
			<Stack height={"full-height"}>
				{rowsHeader > 0 && (
					<div className="position-relative">
						<label>
							<Stack spacing="xsmall">
								<Stack direction="horizontal">
									<Text textId={labelHeader} tagName="h2" />
									<Spacer />
									{maxCharactersHeader > 0 && (
										<div className={Style.characterCount}>{`${
											value.header?.length ?? 0
										}/${maxCharactersHeader}`}</div>
									)}
								</Stack>

								<textarea
									value={value.header}
									onChange={handleHeaderTextAreaChange}
									onBlur={handleTextAreaBlur}
									rows={rowsHeader}
								></textarea>
							</Stack>
						</label>
					</div>
				)}
				{rowsBody > 0 && (
					<div className="position-relative" style={{ flexGrow: 1 }}>
						<label>
							<Stack spacing="xsmall" height="full-height">
								<Stack direction="horizontal">
									<Text textId={labelBody} tagName="h2" />
									<Spacer />
									{maxCharactersBody > 0 && (
										<div className={Style.characterCount}>{`${
											value.body?.length ?? 0
										}/${maxCharactersBody}`}</div>
									)}
								</Stack>

								<textarea
									style={{ height: "100%" }}
									value={value.body}
									onChange={handleBodyTextAreaChange}
									onBlur={handleTextAreaBlur}
									rows={!shouldUseFullHeight ? rowsBody : undefined}
								></textarea>
							</Stack>
						</label>
					</div>
				)}
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<StatusIndicator status={isFinished === undefined ? "idle" : state} />
				</div>
			</Stack>
		</div>
	);
};

export default CommentInput;
