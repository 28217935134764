import _ from "lodash";
import { StoreUtils } from "funkis-foundation";
import { getColorArray } from "./colorUtils";
import { getLetterByIndex } from "./stringUtils";

export const createQuidInputsFromContentArray = ({
	contentArray,
	quidInputIds,
	targetDataKey,
	forEachContentItem,
}) => {
	const quidInputsbyId = StoreUtils.getReducer("quidInputs").itemsById;
	const quidInputs = quidInputIds.map((id) => quidInputsbyId[id]);

	const cmtContentIds = quidInputs
		.filter((quidInput) => quidInput.data.cmtContentId)
		.map((quidInput) => quidInput.data.cmtContentId);

	const contentArrayIds = contentArray.map((content) => content.id);
	const idsWithout = _.without(contentArrayIds, cmtContentIds);

	return idsWithout.map((id) => {
		const content = contentArray.find((content) => content.id === id);
		const storeQuidInput = quidInputsbyId[id];
		const storeQuidInputData = (storeQuidInput && storeQuidInput.data) || {};
		return forEachContentItem({
			quidInput: {
				id,
				targetDataKey,
				data: {
					...storeQuidInputData,
					cmtContentId: id,
				},
			},
			content,
		});
	});
};

export const getChartDataFromQuidInputs = ({ quidInputs, options }) => {
	let data = [];
	const allSelectedIndexes = quidInputs.map((quidInput) =>
		_.get(quidInput, "data.selectedIndexes")
	);
	const selectedIndexes = _.flatten(allSelectedIndexes);

	selectedIndexes.forEach((index) => {
		let dataItem = data.find((item) => item.id === index);
		if (!dataItem) {
			//   const name = getText(options[index].option);
			const name = getLetterByIndex(index, true);
			dataItem = {
				id: index,
				name,
				value: 0,
				color: getColorArray({ color: "primary", length: options.length })[
					index
				],
			};
			data.push(dataItem);
		}
		dataItem.value++;
	});

	data = _.sortBy(data, "name");

	const values = data.map((item) => item.value);
	const valuesTotal = values.length
		? values.reduce(
				(accumulator, currentValue = 0) => accumulator + currentValue
		  )
		: 0;
	data.forEach((item) => {
		item.percent = Math.round((item.value / valuesTotal) * 100);
	});

	return data;
};
