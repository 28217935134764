import {
	createSelector,
	createSelectorCreator,
	defaultMemoize,
} from "reselect";
import _ from "lodash";

const createDeepEqualSelector = createSelectorCreator(
	defaultMemoize,
	_.isEqual
);

const getItems = (state, props, reducer) =>
	state[reducer] ? _.filter(state[reducer].items, props.match) : [];
const getItemById = (state, props, reducer) =>
	state[reducer] && state[reducer].itemsById[props[`${reducer}Id`] || props.id];
const getItemValueByIdAndPath = (state, props, reducer) =>
	_.get(getItemById(state, props, reducer), props.path);

// Create

export const getItemsSelector = () =>
	createSelector(getItems, (items) => {
		return items;
	});

export const getItemIdsSelector = () =>
	createDeepEqualSelector(getItems, (items) => {
		return items.map((item) => item.id);
	});

export const getItemByIdSelector = () =>
	createSelector(getItemById, (item) => {
		return item;
	});

export const getItemValueByIdAndPathSelector = () =>
	createSelector(getItemValueByIdAndPath, (value) => {
		return value;
	});

// Make

export function makeGetItemsSelector() {
	return getItemsSelector();
}

export function makeGetItemIdsSelector() {
	return getItemIdsSelector();
}

export function makeGetItemByIdSelector() {
	return getItemByIdSelector();
}

export function makeGetItemValueByIdAndPathSelector() {
	return getItemValueByIdAndPathSelector();
}
