import { getStore } from "./utils/StoreUtils";

export const connect = () => {
	if (window.ipcRenderer) {
		window.connectToElectron && addElectronListeners();
	} else {
		window.addEventListener("ELECTRON_READY", (e) => {
			window.ipcRenderer = e.detail.ipcRenderer;
			e.detail.connectToElectron && addElectronListeners();
		});
	}

	addWindowListeners();
};

// Add ipc listener
const addElectronListeners = () => {
	window.ipcRenderer.on("REDUX_ACTION", (e, arg) => {
		const { action } = arg;
		const { items, item, type } = action;
		console.log("REDUX_ACTION", JSON.stringify(type));
		if (item) {
			getStore().dispatch(dispatchItem(item, type));
		}
		if (items) {
			getStore().dispatch(dispatchItems(items, type));
		}
	});
};

// add message listener (IFrame)
const addWindowListeners = () => {
	window.addEventListener(
		"message",
		(e) => {
			// alert(`message ${JSON.stringify(e.data)}`);
			let data;

			try {
				data = JSON.parse(e.data);
			} catch (e) {
				return;
			}

			if (!data || !data.action) {
				return;
			}

			const { action } = data;
			const { items, item, type } = action;
			if (item) {
				getStore().dispatch(dispatchItem(item, type));
			}
			if (items) {
				getStore().dispatch(dispatchItems(items, type));
			}
		},
		false
	);
};

function dispatchItem(item, type) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type });
	};
}

function dispatchItems(items, type) {
	return (dispatch: (action) => void) => {
		dispatch({ items, type });
	};
}
