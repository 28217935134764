import _ from "lodash";
import {
	Actions as FoundationActions,
	QuidInputActions as FFQuidInputActions,
	PagesUtils,
	Actions,
	LocationUtils,
	StoreUtils,
	AppActions,
	PageActions,
	PageItemActions,
	ConfigActions,
} from "funkis-foundation";

import { QuidInputActions } from "quiddity";

const { getLocationPathForPage, getPageByPath } = PagesUtils;
const foundationUpdateLocation = Actions.updateLocation;
const { convertLocationPathToLocation } = LocationUtils;

export const revealNext = ({ pageItem, offset = 1 }) => {
	FoundationActions.updatePageItem.bind({ pageItem })(
		[{ parent: 0 }, { sibling: offset }],
		{ reveal: true }
	);
};

export function scrollPageItemToTop({
	pageItem,
	behavior = "smooth",
	offsetTop = 0,
}) {
	const elements = document.getElementsByClassName(
		`page-item-id-${pageItem.id}`
	);
	const element = elements[0];
	if (element) {
		const clientRect = element.getBoundingClientRect();
		window.scrollBy({ behavior, top: clientRect.top + offsetTop });
	}
}

export const scrollPageItemToBottom = () => {
	FoundationActions.scrollByWindowHeight();
};

export const updateLocation = ({ pageId, path, friendlyId = undefined }) => {
	if (path && !getPageByPath(path, pageId)) {
		console.warn(
			`Page with id  "${pageId}" and path "${JSON.stringify(path)}" not found`
		);
		return;
	}

	const pages = StoreUtils.getReducer("pages").items;
	const pagesById = StoreUtils.getReducer("pages").itemsById;

	const id =
		(path && getPageByPath(path, pageId).id) || // by path
		pageId || // by id
		(friendlyId && pages.find((page) => page.friendlyId === friendlyId).id); // by friendlyId
	const locationPath = getLocationPathForPage(pages, pagesById[id]);
	const location = convertLocationPathToLocation(locationPath);

	foundationUpdateLocation(location);
};

export const resetStores = () => {
	AppActions.restoreFromAppSnapshot({ key: "initialSetup" });
	PageActions.restoreFromPagesSnapshot({ key: "initialSetup" });
	PageItemActions.restoreFromPageItemsSnapshot({ key: "initialSetup" });
	ConfigActions.restoreFromConfigSnapshot({ key: "initialSetup" });
	const backend = _.get(StoreUtils.getReducer("app").item, "backend");
	if (backend === "lms") {
		FFQuidInputActions.restoreFromQuidInputsSnapshot({ key: "initialSetup" });
	} else if (backend === "quiddity") {
		QuidInputActions.restoreFromQuidInputsSnapshot({ key: "initialSetup" });
	} else {
		console.error(
			"restoreFromQuidInputsSnapshot not implemented for backend ",
			backend
		);
	}
};
