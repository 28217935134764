import React from "react";
import _ from "lodash";

const windowResizeHOC = (WrappedComponent) => {
	class Wrapper extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				window: this.getCurrentWindowSize(),
			};
		}

		componentDidMount() {
			this.debouncedOnResizeHandler = _.debounce(this._onResize.bind(this), 50);
			window.addEventListener("resize", this.debouncedOnResizeHandler);
		}

		componentWillUnmount() {
			window.removeEventListener("resize", this.debouncedOnResizeHandler);
		}

		_onResize() {
			this.setState({ window: this.getCurrentWindowSize() });
		}

		getCurrentWindowSize() {
			return { width: window.innerWidth, height: window.innerHeight };
		}

		render() {
			return <WrappedComponent {...this.props} {...this.state} />;
		}
	}
	return Wrapper;
};

export default windowResizeHOC;
