import React from "react";
import classnames from "classnames";
import { PageItemImage, FFVideo } from "funkis-foundation";
import ImageStyle from "./Image.module.css";
import { getLocalizedMediaPath, getMediaType } from "utils/MediaUtils";

const ImageVideoOrDiv = ({
	src,
	style = {},
	className,
	imageClassName = "w-100 h-100",
	children,
	videoOptions = {},
	imageOptions = {},
}) => {
	const mediaSrc = getLocalizedMediaPath(src);
	const type = getMediaType(src);

	if (type === "video") {
		return (
			<div
				className={classnames(className, "image-video-or-div")}
				style={style}
			>
				<div className="absolute-top w-100 h-100">
					<FFVideo
						src={mediaSrc}
						loop
						muted
						controls={false}
						autoPlay
						width="100%"
						height="100%"
						{...videoOptions}
					/>
				</div>
				{children}
			</div>
		);
	} else if (type === "image") {
		return (
			<PageItemImage
				pageItem={{
					src,
					backgroundImage: true,
					autoSize: true,
					width: "auto",
					height: "auto",
					...imageOptions,
				}}
				style={style}
				className={classnames(
					imageClassName,
					ImageStyle.cover,
					ImageStyle.alignCenterCenter,
					className
				)}
			>
				{children}
			</PageItemImage>
		);
	} else {
		return (
			<div className={className} style={style}>
				{children}
			</div>
		);
	}
};

export default ImageVideoOrDiv;
