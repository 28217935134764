import classnames from "classnames";
import {
	Actions,
	FFVideo,
	LocalizationUtils,
	StoreUtils,
} from "funkis-foundation";
import {
	useAllLanguages,
	useIsCurrentlyActiveVideo,
	useIsShowingNavigationMenu,
	useOriginalLocalizationCode,
	useSelectedLanguage,
	useViewportSize,
	ViewportSize,
} from "funkis-template/hooks/player";
import { PageItemMedia } from "funkis-template/models/player";
import gsap from "gsap";
import React, {
	SyntheticEvent,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { getLocalizedMediaPath } from "utils/MediaUtils";
import { getBootstrapColor } from "../../../utils/colorUtils";
import Text from "../../core/Text";
import Style from "./VideoPlayer.module.css";
import VideoPlayerControls, {
	ClassNameOverrideProps,
	ProgressBarPosition,
} from "./VideoPlayerControls";
import VideoPlayerSubtitles from "./VideoPlayerSubtitles";

const { addClassNamesToBody, removeClassNamesFromBody } = Actions;

export type VideoState =
	| "playing"
	| "paused"
	| "auto-paused"
	| "started"
	| "ended"
	| "idle";

const NativeSubtitleTrack = ({ defaultLang, video, lang }) => {
	//removes .mp4 from video source and adds .vtt to get track file
	let subFile = video.substring(0, video.length - 4);
	subFile = subFile + "." + lang + ".vtt";

	return (
		<track
			default={defaultLang === lang}
			kind="subtitles"
			srcLang={lang}
			label={LocalizationUtils.getNativeLanguageNameForLanguageCode(lang)}
			src={subFile}
		/>
	);
};

const Sign = ({
	backgroundColor = "black",
	textColor = "white",
	textId,
	show = true,
	className,
	classNameText,
	style,
}) => {
	const ref = useRef(null);

	useEffect(() => {
		gsap.set(ref.current, { left: -100, opacity: 0 });
	}, []);

	useEffect(() => {
		if (show) {
			gsap.to(ref.current, { left: 0, autoAlpha: 1, duration: 0.4 });
		} else {
			gsap.to(ref.current, { left: -100, autoAlpha: 0, duration: 0.4 });
		}
	}, [show]);

	return (
		<div
			ref={ref}
			className={classnames(Style.sign, "m-5 px-1 ", className)}
			style={{ ...style, backgroundColor: getBootstrapColor(backgroundColor) }}
		>
			<Text
				className={classnames("p-small font-weight-bold", classNameText)}
				textId={textId}
				style={{ color: getBootstrapColor(textColor) }}
			/>
		</div>
	);
};

type VideoPlayerProps = {
	src: PageItemMedia;

	videoId?: string;

	className?: string;
	classNameVideo?: string;
	classNameSign?: string;
	classNameSignText?: string;
	classNameChildrenContainer?: string;
	children?: (currentTime: number, totalDuration: number) => React.ReactElement;
	progressBarPosition?: ProgressBarPosition;
	onStateChange?: (newState: VideoState) => void;
	onEnded?: () => void;
	onReplayClick?: (props: VideoPlayerProps) => void;
	onSkipClick?: (props: VideoPlayerProps) => void;
	onPreviousClick?: (props: VideoPlayerProps) => void;

	crop?: boolean;
	maxHeight?: string | number;
	autoplay?: boolean;
	loop?: boolean;
	controls?: boolean;
	native?: boolean;
	muted?: boolean;
	borderColor?: string;
	alwaysShowProgressBar?: boolean;

	signText?: string;
	signShowTime?: number;
	signHideTime?: number;

	posterFrame?: PageItemMedia;
} & ClassNameOverrideProps;

export type VideoPlayerHandler = {
	seek: (time: number) => void;
	pause: () => void;
	getDuration: () => number;
};

const VideoPlayer: React.ForwardRefRenderFunction<
	VideoPlayerHandler,
	VideoPlayerProps
> = (props, forwardedRef) => {
	const {
		className,
		classNameVideo,
		classNameSign,
		classNameSignText,
		classNameChildrenContainer,
		children,

		progressBarPosition = "bottom",
		onStateChange,
		onEnded,
		onReplayClick,
		onSkipClick,
		onPreviousClick,

		crop,
		maxHeight,

		autoplay = false,
		loop = false,
		controls = true,
		native = false,
		muted = false,
		src,
		videoId,
		borderColor,
		alwaysShowProgressBar,

		signText,
		signShowTime = 0,
		signHideTime = 5,

		posterFrame,

		classNameSubtitle,
		classNameSubtitleContainer,
		classNameVideoControls,
		classNameVideoButtonsContainer,
	} = props;

	const [currentState, setCurrentState] = useState<VideoState>("idle");
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);

	const ffVideoRef = useRef<FFVideo>(null);

	const srcRef = useRef<PageItemMedia>(src);

	const config = StoreUtils.getReducer("config").item;
	const vidpath = config.paths.videoPath;

	const height = crop && maxHeight ? maxHeight : "auto";

	const viewportSize = useViewportSize();
	const isMobile = viewportSize <= ViewportSize.Mobile;

	const shouldShowSign =
		currentTime >= signShowTime && currentTime <= signHideTime;

	const uniqueId = videoId ?? src;

	const isBeingUsedAsGif = loop;
	const isPausableVideo = !isBeingUsedAsGif;

	/** Imperative API to control the seek and read the duration in parent component **/
	useImperativeHandle(forwardedRef, () => ({
		seek(newTime) {
			ffVideoRef.current?.seek(newTime);
		},

		getDuration() {
			return duration;
		},

		pause() {
			setCurrentState("paused");
		},
	}));

	useEffect(() => {
		/**
		If the src is changed and the video has been playing or played to end. We want 
		the new src to automatically play.
		**/
		if (src !== srcRef.current && currentState !== "idle") {
			srcRef.current = src;
			setCurrentState("playing");
		}
	}, [src, currentState]);

	useEffect(() => {
		onStateChange && onStateChange(currentState);
	}, [onStateChange, currentState]);

	/**
  Pause the current video if it's *not* the currently active video.
  **/
	const [isCurrentlyActiveVideo, setCurrentlyPlayingVideo] =
		useIsCurrentlyActiveVideo(uniqueId);
	useEffect(() => {
		let timeoutId = setTimeout(() => {
			if (
				!isCurrentlyActiveVideo &&
				currentState === "playing" &&
				isPausableVideo
			) {
				setCurrentState("auto-paused");
			}
		}, 100);

		return () => clearTimeout(timeoutId);
	}, [isPausableVideo, isCurrentlyActiveVideo, currentState]);

	/**
	Pause the current video if navigation menu is showing.
	**/
	const isShowingNavigationMenu = useIsShowingNavigationMenu();
	useEffect(() => {
		let timeoutId = setTimeout(() => {
			if (
				isShowingNavigationMenu &&
				isCurrentlyActiveVideo &&
				isPausableVideo &&
				currentState === "playing"
			) {
				setCurrentState("auto-paused");
			} else if (
				!isShowingNavigationMenu &&
				isCurrentlyActiveVideo &&
				isPausableVideo &&
				currentState === "auto-paused"
			) {
				setCurrentState("playing");
			}
		}, 10);

		return () => clearTimeout(timeoutId);
	}, [
		isPausableVideo,
		isCurrentlyActiveVideo,
		currentState,
		isShowingNavigationMenu,
	]);

	const videoElementStyleOverrides = {
		...(maxHeight && { maxHeight: maxHeight }),
		...(crop && { height: height }),
	};

	const installedLanguages = useAllLanguages();
	const selectedLanguage = useSelectedLanguage();
	const originalLocalizationCode = useOriginalLocalizationCode();

	const handleVideoLoadedMetadata = React.useCallback(
		(e: SyntheticEvent<HTMLVideoElement>) => {
			const { duration } = e.currentTarget;
			setDuration(duration);
		},
		[]
	);

	const handleVideoTimeUpdate = React.useCallback((e: any) => {
		const currentTime = e.srcElement.currentTime as number;
		setCurrentTime(currentTime);
	}, []);

	const handleVideoPlay = React.useCallback(() => {
		isPausableVideo && setCurrentlyPlayingVideo(uniqueId);
		setCurrentState("playing");
	}, [isPausableVideo, setCurrentlyPlayingVideo, uniqueId]);

	const handleVideoPlaying = React.useCallback(() => {
		isPausableVideo && setCurrentlyPlayingVideo(uniqueId);
		if (currentState !== "playing") {
			setCurrentState("playing");
		}
	}, [currentState, setCurrentlyPlayingVideo, isPausableVideo, uniqueId]);

	const handleVideoEnded = React.useCallback(() => {
		if (loop) {
			setCurrentState("playing");
		} else {
			setCurrentState("ended");
		}

		onEnded && onEnded();
	}, [loop, onEnded]);

	const handleVideoPause = React.useCallback(() => {
		if (currentState !== "paused" && currentState !== "auto-paused") {
			setCurrentState("paused");
		}
	}, [currentState]);

	const handleVideoPaused = React.useCallback(() => {
		if (currentState !== "paused" && currentState !== "auto-paused") {
			setCurrentState("paused");
		}
	}, [currentState]);

	const handleVideoCanPlay = React.useCallback(() => {
		if (autoplay) {
			setCurrentState("playing");
		}
	}, [autoplay]);

	const handleFullscreenChange = React.useCallback((_e, { isFullscreen }) => {
		if (isFullscreen) {
			// Fix for Safari showing topbar and side menu even over full screen.
			addClassNamesToBody("fullscreen-video-active");
		} else {
			removeClassNamesFromBody("fullscreen-video-active");
		}
	}, []);

	const videoSrc = getLocalizedMediaPath(src, selectedLanguage);

	const posterSrc = posterFrame
		? getLocalizedMediaPath(posterFrame, selectedLanguage)
		: undefined;

	const handleVideoControlsSeek = React.useCallback(
		(newTime) => {
			ffVideoRef.current?.seek(newTime);

			if (currentState === "ended") {
				setCurrentState("paused");
			}
		},
		[currentState]
	);

	return (
		<React.Fragment>
			<div className={classnames(Style.root, "video-player", className)}>
				{/* NATIVE VIDEO */}
				{native && (
					<React.Fragment>
						<FFVideo
							ref={ffVideoRef}
							style={{
								border: "solid 1px",
								borderColor,
								...videoElementStyleOverrides,
							}}
							className={classnames(
								"page-item page-item-video",
								Style.video,
								crop && Style.crop,
								classNameVideo
							)}
							src={videoSrc}
							state={currentState === "auto-paused" ? "paused" : currentState}
							height={height}
							muted={muted}
							autoPlay={autoplay}
							controls={controls}
							poster={posterSrc}
							onVideoCanPlay={handleVideoCanPlay}
							onVideoEnded={handleVideoEnded}
							onVideoPlay={handleVideoPlay}
							onVideoPlaying={handleVideoPlaying}
							onVideoPaused={handleVideoPaused}
							onVideoPause={handleVideoPause}
							onVideoFullscreenChange={handleFullscreenChange}
						>
							{(installedLanguages ?? []).map((lang) => (
								<NativeSubtitleTrack
									defaultLang={selectedLanguage}
									key={lang}
									lang={lang}
									video={vidpath + src}
								></NativeSubtitleTrack>
							))}
						</FFVideo>
					</React.Fragment>
				)}
				{/* CUSTOM VIDEO */}
				{!native && (
					<React.Fragment>
						<FFVideo
							ref={ffVideoRef}
							style={{
								border: "solid 1px",
								borderColor,
								...videoElementStyleOverrides,
							}}
							className={classnames(
								className,
								"page-item page-item-video",
								Style.video,
								crop && Style.crop,
								classNameVideo
							)}
							src={videoSrc}
							state={currentState === "auto-paused" ? "paused" : currentState}
							height={height}
							muted={muted}
							autoPlay={autoplay}
							controls={false}
							poster={posterSrc}
							onVideoCanPlay={handleVideoCanPlay}
							onVideoLoadedMetaData={handleVideoLoadedMetadata}
							onVideoTimeUpdate={handleVideoTimeUpdate}
							onVideoEnded={handleVideoEnded}
							onVideoPlay={handleVideoPlay}
							onVideoPlaying={handleVideoPlaying}
							onVideoPaused={handleVideoPaused}
							onVideoPause={handleVideoPause}
						/>

						{/* SIGN */}
						<Sign
							textId={signText}
							show={shouldShowSign}
							className={classNameSign}
							classNameText={classNameSignText}
							style={undefined}
						/>

						{/* Children container */}
						<div
							className={classnames(
								"children-container pointer-events-none",
								Style.childrenContainer,
								classNameChildrenContainer
							)}
						>
							{children && children(currentTime, duration)}
						</div>

						{/* Controls */}
						{!isBeingUsedAsGif && controls && (
							<VideoPlayerControls
								videoState={currentState}
								showProgressBar={alwaysShowProgressBar ?? false}
								onClickPrevious={
									onPreviousClick
										? () => {
												onPreviousClick(props);
										  }
										: undefined
								}
								onClickReplay={() => {
									if (onReplayClick) {
										onReplayClick(props);
									} else {
										setCurrentState("playing");
										ffVideoRef.current?.seek(0.1);
									}
								}}
								onClickPlayPause={() => {
									const nextState =
										currentState !== "playing" ? "playing" : "paused";
									setCurrentState(nextState);
								}}
								onClickSkip={() => {
									if (onSkipClick) {
										onSkipClick(props);
									} else {
										setCurrentState("playing");
										ffVideoRef.current?.seek(duration - 0.2);
									}
								}}
								src={src}
								showSubtitles={!isMobile}
								currentTime={currentTime}
								duration={duration}
								onSeek={handleVideoControlsSeek}
								classNameVideoControls={classNameVideoControls}
								classNameVideoButtonsContainer={classNameVideoButtonsContainer}
							/>
						)}
					</React.Fragment>
				)}
			</div>
			{isMobile && (
				<VideoPlayerSubtitles
					src={src}
					currentTime={currentTime}
					variant={"block"}
					videoState={currentState}
				/>
			)}
		</React.Fragment>
	);
};

export default React.forwardRef(VideoPlayer);
