import React from "react";

const IconRemove = ({ color = "#00005A", style = {}, ref }) => {
	return (
		<div ref={ref}>
			<svg
				width="48px"
				height="48px"
				viewBox="0 0 48 48"
				version="1.1"
				style={style}
			>
				<g
					id="Symbols"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
				>
					<g id="Group">
						<circle
							id="Oval-Copy-20"
							fill={color}
							cx="24"
							cy="24"
							r="24"
						></circle>
						<line
							x1="36.6867478"
							y1="19.142479"
							x2="11.3781983"
							y2="28.857521"
							id="Rectangle-Copy-34"
							stroke="#FFFFFF"
							strokeWidth="6"
							transform="translate(24.032473, 24.000000) rotate(21.000000) translate(-24.032473, -24.000000) "
						></line>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default IconRemove;
