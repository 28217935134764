import React, { useState, useEffect } from "react";
import _ from "lodash";
import Backend from "../backend/Backend";
import { LoadUtils, TextActions, StoreUtils } from "funkis-foundation";
import { get as getSprig } from "../../Sprigs";
import { convert } from "../../utils/operatorUtils";
import { getText } from "../../utils/textUtils";
import { calculateTraits, getTraitResult } from "../../utils/sprigStoryUtils";

// Devmode view to view calculated values for traits...
const TraitData = ({ traits }) => {
	return (
		<ul style={{ border: "1px dashed red" }}>
			{traits.map((trait, index) => (
				<li>{`${getText(trait.name_text_id)} value:${trait.value}`}</li>
			))}
		</ul>
	);
};

const Sprig = (props) => {
	const { sprigId, sprigStory, storyType, traits = [] } = props;
	const [show, setShow] = useState(false);

	const sprig = sprigStory.find((sp) => sp.id === sprigId);
	const SprigComponent = getSprig(sprig.type, storyType);

	useEffect(() => {
		if (sprig) {
			setTimeout(() => {
				setShow(true);
			}, (SprigComponent.delay || 0) * 1000);
		}
	}, [sprig]);

	if (!show) {
		return <React.Fragment />;
	}

	const exits = sprig.exits.filter((exit) => {
		const conditions = exit.conditions || [];

		let evalStr = "";
		conditions.forEach((condition, index) => {
			const trait = traits.find(
				(traitItem) => traitItem.id === condition.target_id
			);
			if (!trait) {
				return true;
			}

			const hasNext = index < conditions.length - 1;
			evalStr =
				evalStr +
				`${trait.value} ${convert(condition.numerical_operator)} ${
					condition.value
				}`;
			hasNext &&
				(evalStr = evalStr + ` ${convert(condition.logical_operator)} `);
		});

		return evalStr.length > 0
			? eval("function calc(){return " + evalStr + "}; calc()")
			: true;
	});

	return (
		<React.Fragment>
			<SprigComponent {...props} exits={exits} />
			{/* {window.devMode && traits.length > 0 && <TraitData traits={traits} />} */}
		</React.Fragment>
	);
};

const loadSprig = async ({
	paragraphPath = "content/json/sst_paragraph.json",
	loadText,
} = {}) => {
	const config = StoreUtils.getReducer("config").item;
	const { language } = config;
	let paragraphs;
	let texts;
	const basePath = config.paths.basePath;
	try {
		paragraphs = await LoadUtils.loadJSON(basePath + paragraphPath);
	} catch (error) {
		throw new Error(
			`Sprigstory ${paragraphPath} could not be loaded! Error:${error}`
		);
	}

	const textPath = `content/language/${language}/sst_text_${language}.json`;
	try {
		texts = loadText ? await LoadUtils.loadJSON(textPath) : [];
	} catch (error) {
		throw new Error(
			`Sprigstory text ${textPath} could not be loaded! Error:${error}`
		);
	}

	return { paragraphs, texts };
};

const IOSprigStory = (props) => {
	const {
		quidInputId,
		path,
		ioID,
		paragraphBackgroundColor,
		exitBackgroundColor,
		paragraphTextColor,
		exitTextColor,
		onShowSprig,
		onShowExit,
		onComplete,
		onExitClick,
		loadText = false,
	} = props;
	const [sprigStory, setSprigStory] = useState();
	const [storyType, setStoryType] = useState();
	const [traits, setTraits] = useState([]);
	const [exits, setExits] = useState({});
	const [showParagraphTitles, setShowParagraphTitles] = useState(false);

	useEffect(() => {
		loadSprig({ paragraphPath: path, loadText }).then((e) => {
			TextActions.updateTexts(e.texts);
			// Find storyType
			const storyType = _.get(
				e.paragraphs.find((jsonObj) => jsonObj.hasOwnProperty("story_type")),
				"story_type",
				"story"
			);
			setStoryType(storyType);
			// Find exits
			const exits = _.flatten(
				e.paragraphs.map((paragraph) => paragraph.exits || [])
			);
			setExits(_.keyBy(exits, "id"));
			// Find traits
			const traits = _.get(
				e.paragraphs.find((jsonObj) => jsonObj.hasOwnProperty("traits")),
				"traits",
				[]
			);
			setTraits(traits);
			// Find showParagraphTitles
			const showParagraphTitles = _.get(
				e.paragraphs.find((jsonObj) =>
					jsonObj.hasOwnProperty("show_paragraph_titles")
				),
				"show_paragraph_titles",
				false
			);
			setShowParagraphTitles(showParagraphTitles);
			const paragraphs = e.paragraphs.filter((jsonObj) =>
				jsonObj.hasOwnProperty("type")
			);
			setSprigStory(paragraphs);
		});
	}, [path]);

	if (!sprigStory) {
		return <div />;
	}

	return (
		<Backend
			{...props}
			className=""
			quidType="sprigStory"
			mode="input"
			targetDataKey={ioID}
			quidInputId={quidInputId}
			renderInput={(renderProps) => {
				const { data, save } = renderProps;
				const { selectedSprigs = [], completedSprigs = [] } = data;

				// PUSH SPRIG
				const pushSprig = ({ sprigId, exitId }) => {
					const updatedSelectedSprigs = [...selectedSprigs];
					updatedSelectedSprigs.push({ sprigId, exitId });
					const result = getTraitResult({
						sprigStory,
						traits,
						sprigs: updatedSelectedSprigs,
					});
					save(false, {
						...data,
						selectedSprigs: updatedSelectedSprigs,
						result,
					});
				};

				// CUT SPRIG
				const updateSprig = ({ atIndex, sprigId, exitId }) => {
					const updatedSelectedSprigs = selectedSprigs.slice(0, atIndex);
					updatedSelectedSprigs.push({ sprigId, exitId });
					const result = getTraitResult({
						sprigStory,
						traits,
						sprigs: updatedSelectedSprigs,
					});
					save(false, {
						...data,
						selectedSprigs: updatedSelectedSprigs,
						completedSprigs: updatedSelectedSprigs,
						result,
					});
				};

				// UPDATE COMPLETED SPRIGS
				const addCompletedSprigs = ({ sprigId }) => {
					const completedSprigsCopy = [...completedSprigs];
					completedSprigsCopy.push(sprigId);
					save(false, {
						...data,
						completedSprigs: _.uniq(completedSprigsCopy),
					});
				};

				// Find last selected exit id and next sprig...
				const lastSprigId =
					selectedSprigs.length &&
					selectedSprigs[selectedSprigs.length - 1].sprigId;
				const lastExitId =
					selectedSprigs.length &&
					selectedSprigs[selectedSprigs.length - 1].exitId;
				const lastSprig =
					lastSprigId && sprigStory.find((sprig) => sprig.id === lastSprigId);
				const lastSelectedExit =
					lastExitId &&
					lastSprig &&
					lastSprig.exits.find((exit) => exit.id === lastExitId);
				const nextSprig = lastSelectedExit
					? sprigStory.find(
							(sprig) => sprig.id === lastSelectedExit.paragraph_id
					  )
					: sprigStory[0];

				const prevSelectedExitIds = selectedSprigs.map((sprig) => sprig.exitId);
				// Execute onComplete...

				const isEndOfSprigStoryWithNoExists =
					nextSprig && !nextSprig.exits.length;
				const isEndOfSprigStoryAfterLastChoice = !nextSprig;
				// user has selected an option for the last paraphraph and no more sprigs exist
				isEndOfSprigStoryAfterLastChoice && onComplete && onComplete();

				// Selected sprigs + next sprig...
				const sprigs = nextSprig
					? selectedSprigs.concat({ sprigId: nextSprig.id })
					: selectedSprigs;

				// Zeroing the traits before calculation...
				traits.forEach((trait) => {
					trait.value = 0;
				});

				return (
					<div>
						{sprigs.map((sprigItem, index) => {
							const isLastSprig = sprigs.length - 1 === index;
							const prevSelectedExitId = prevSelectedExitIds[index - 1];
							const prevSelectedExit = exits[prevSelectedExitId];

							return (
								<Sprig
									key={`Sprig-${sprigItem.sprigId}`}
									sprigs={sprigs}
									sprigId={sprigItem.sprigId}
									sprigStory={sprigStory}
									storyType={storyType}
									showParagraphTitles={showParagraphTitles}
									traits={calculateTraits({
										sprigStory,
										sprigItem,
										traits,
										index,
									})}
									animate={
										isLastSprig && nextSprig
											? !completedSprigs.includes(nextSprig.id)
											: false
									}
									paragraphBackgroundColor={paragraphBackgroundColor}
									exitBackgroundColor={exitBackgroundColor}
									paragraphTextColor={paragraphTextColor}
									exitTextColor={exitTextColor}
									selectedExitId={sprigItem.exitId}
									prevSelectedExitIds={prevSelectedExitIds}
									titleId={
										prevSelectedExit &&
										prevSelectedExit.next_paragraph_header_text_id
									} // Get previous selected exits header if exitst. Else header will be set inside the sprig component.
									onShow={(e) => {
										onShowSprig && onShowSprig(e);
									}}
									onShowExit={(e) => {
										isLastSprig &&
											nextSprig &&
											addCompletedSprigs({ sprigId: e.sprigId });
										onShowExit && onShowExit(e);
										if (isLastSprig && isEndOfSprigStoryWithNoExists) {
											// fires when animation of the very last paragraph is done
											onComplete && onComplete();
										}
									}}
									onExitClick={({ sprig, exit }) => {
										isLastSprig && nextSprig
											? pushSprig({ sprigId: sprig.id, exitId: exit.id })
											: updateSprig({
													atIndex: index,
													sprigId: sprig.id,
													exitId: exit.id,
											  });
										onExitClick && onExitClick({ sprig, exit });
									}}
								/>
							);
						})}
					</div>
				);
			}}
		></Backend>
	);
};

export default IOSprigStory;
