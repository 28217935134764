import React, { FC, useState } from "react";
import DilemmaInput from "funkis-template/components/core/DilemmaInput/DilemmaInput";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { DilemmaInputScrollBlock } from "funkis-template/models/pageitem";
import { PageItem } from "funkis-template/models/player";

const PageItemIODilemmaInput: FC<{
	pageItem: PageItem<DilemmaInputScrollBlock>;
}> = (props) => {
	const [showNextButton, setShowNextButton] = useState(false);
	return (
		<PageItemScrollBase {...props} showNextButton={showNextButton}>
			<DilemmaInput
				{...props}
				onComplete={() => {
					setShowNextButton(true);
				}}
			/>
		</PageItemScrollBase>
	);
};

export default PageItemIODilemmaInput;
