import React from "react";
import classnames from "classnames";
import { ReduxObjectSelectors, PageItemImage } from "funkis-foundation";
import { connect } from "../../utils/reduxUtils";

import Style from "./Logo.module.css";
import { render } from "../../RenderFunctions";

const { makeGetValueSelector } = ReduxObjectSelectors;

const Logo = (props) => {
	const {
		logoPosition,
		logoBackground,
		logo,
		logoOverride,
		className,
		style,
		classNameImage,
		backgroundImage = false,
		ignoreLogoPositionSetting = false,
	} = props;
	return render(
		"logo",
		props,
		<div
			className={classnames(
				Style.root,
				!ignoreLogoPositionSetting && Style[logoPosition],
				"logo-container",
				className
			)}
			style={{ backgroundColor: logoBackground, ...style }}
		>
			<PageItemImage
				pageItem={{ src: logoOverride || logo, backgroundImage }}
				className={classnames(classNameImage)}
			/>
		</div>
	);
};

const makeMapStateToProps = () => {
	const getValueSelector = makeGetValueSelector();
	const mapStateToProps = (state, props) => {
		const programType = getValueSelector(
			state,
			{ ...props, path: "program_type" },
			"app"
		);
		return {
			logoBackground: getValueSelector(
				state,
				{ ...props, path: "logo_background" },
				"app"
			),
			logoPosition: getValueSelector(
				state,
				{ ...props, path: "logo_position" },
				"app"
			),
			logo: getValueSelector(
				state,
				{
					...props,
					path: programType === "slide" ? "program_logo_slide" : "program_logo",
				},
				"app"
			),
		};
	};
	return mapStateToProps;
};

export default connect(Logo, makeMapStateToProps);
