import tinycolor from "tinycolor2";
import { StoreUtils } from "..";

const colorCache: Record<string, string> = {};

export const getBootstrapColor = (name: string, lighten = 0): string => {
	const cacheKey = name + lighten;
	if (colorCache[cacheKey]) {
		return colorCache[cacheKey];
	}

	// Transparent
	if (name === "transparent") {
		return name;
	}

	const app = StoreUtils.getReducer("app").item;
	const appColor = app[name];

	const style = getComputedStyle(document.body);
	const styleColor =
		name && name.indexOf("#") > -1 ? name : style.getPropertyValue(`--${name}`);

	// Convert to hex
	// Because of lacking css-variable support, in IE11 styleColor will be "". Use "name"  to fallback to the html named color instead )
	const convertedColor = tinycolor(appColor || styleColor || name)
		.lighten(lighten)
		.toString();

	colorCache[cacheKey] = convertedColor;
	return colorCache[cacheKey];
};
