import React from "react";
import _ from "lodash";
import classnames from "classnames";
import { FFMultiSelect, FFButton, FFText } from "funkis-foundation";
import Backend from "../../backend/Backend";
import Styles from "./IOMultiSelect.module.css";

const IOMultiSelect = (props) => {
	const {
		pageItem,
		options: contentArrayOptions,
		maxSelections,
		minSelections,
		onSelect,
		optionRender,
		className,
		buttonClassName,
		doneButtonContainerClassName = "row m-0 justify-content-center",
		doneButtonClassName = "btn btn-sm btn-black rounded-pill col-sm",
		doneButtonLabelClassName = "text-uppercase text-white font-weight-bold",
		doneButtonLabelTextId = "D24115A3-B9A4-4B40-90F1-5E1213241A84",
		doneButtonEnabled = true,
		autoDeselectWhenMaxSelectionsReached = false,
		disableDeselect = false,
		alwaysTriggerOnSelect = true,
		toggle = true,
		children,
	} = props;
	const { cmtPageItem = {} } = pageItem;
	const options = contentArrayOptions || cmtPageItem.content_array;
	const correctIndexes = options
		.map((option, index) => option.correct && index)
		.filter((val) => val !== false);

	const ioID = cmtPageItem.valuelist_id || cmtPageItem.io_id || cmtPageItem.id;

	return (
		<Backend
			pageItem={pageItem}
			quidType="multi-select"
			mode="input"
			targetDataKey={ioID}
			renderInput={(renderProps) => {
				const { data, save, updateData } = renderProps;
				const completed = data.completed;
				const selectedIndexes = data.selectedIndexes || [];
				// keep the clone! .sort mutates the array, which we don't want
				const isAnswerCorrect =
					maxSelections === 1
						? _.intersection(selectedIndexes, correctIndexes).length > 0
						: _.isEqual(
								[...selectedIndexes].sort(),
								[...correctIndexes].sort()
						  );

				const revealDoneButton =
					!completed &&
					selectedIndexes.length >= minSelections &&
					selectedIndexes.length <= maxSelections;
				const enabled = !completed || cmtPageItem.redoable !== "no";

				return (
					<React.Fragment>
						<FFMultiSelect
							className={className}
							enabled={enabled}
							buttonClassName={buttonClassName}
							isCorrect={isAnswerCorrect}
							toggle={toggle}
							maxSelections={maxSelections}
							minSelections={minSelections}
							selectedIndexes={selectedIndexes}
							disableWhenMaxSelectionsReached={false}
							disableDeselect={disableDeselect}
							alwaysTriggerOnSelect={alwaysTriggerOnSelect}
							autoDeselectWhenMaxSelectionsReached={
								autoDeselectWhenMaxSelectionsReached
							}
							onSelect={(indexes) => {
								const isCompleted =
									indexes.length >= minSelections &&
									indexes.length <= maxSelections;
								doneButtonEnabled
									? updateData({ selectedIndexes: indexes, completed: false })
									: save(false, {
											selectedIndexes: indexes,
											completed: isCompleted,
									  });
								onSelect &&
									onSelect({
										selectedIndexes: indexes,
										option: indexes.length === 1 && options[indexes[0]],
										isCorrect: isAnswerCorrect,
										completed: isCompleted,
									});
							}}
						>
							{options.map((option, index) =>
								optionRender({
									index,
									selectedIndexes,
									option,
									selected: selectedIndexes.includes(index),
									completed,
									isCorrect: correctIndexes.includes(index),
									enabled,
								})
							)}
						</FFMultiSelect>
						{/* DONE BUTTON */}
						<div
							className={classnames(
								!doneButtonEnabled && "d-none",
								revealDoneButton ? Styles.reveal : Styles.unreveal,
								doneButtonContainerClassName
							)}
						>
							<FFButton
								className={doneButtonClassName}
								onClick={() => {
									save(false, { selectedIndexes, completed: true });
								}}
							>
								<FFText
									textId={doneButtonLabelTextId}
									className={doneButtonLabelClassName}
								/>
							</FFButton>
						</div>
						{/* CHILDREN RENDER FUNCTION */}
						{children &&
							children({
								...props,
								...renderProps,
								isCorrect: isAnswerCorrect,
							})}
					</React.Fragment>
				);
			}}
		/>
	);
};

export default IOMultiSelect;
