import React, { useEffect, useState } from "react";
import classnames from "classnames";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";
import QuiddityPostItsOutput from "../../../components/scroll/version-2/QuiddityPostItsOutput";
import Backend from "../../../components/backend/Backend";
import Text from "../../../components/core/Text";
import ButtonPlus from "funkis-template/components/core/buttons/ButtonPlus";

const PageItemIOPostItCreate = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;
	const ioID = cmtPageItem.io_id;
	const showNextButtonMode = cmtPageItem.show_next_button;
	const [editQuidInputId, setEditQuidInputId] = useState();
	const [completed, setCompleted] = useState(false);
	const [numPostIts, setNumPostIts] = useState(0);

	const numberOfRequiredPostItsByNextButtonMode = {
		auto: 0,
		always: 0,
		"after-1-post-it": 1,
		"after-2-post-it": 2,
		"after-4-post-it": 4,
		"after-6-post-it": 6,
		"after-8-post-it": 8,
	};
	const numberOfRequiredPostIts =
		numberOfRequiredPostItsByNextButtonMode[showNextButtonMode] ?? 0;
	const isNumberOfRequiredPostItsReached =
		numPostIts >= numberOfRequiredPostIts;

	useEffect(() => {
		if (isNumberOfRequiredPostItsReached && !completed) {
			setCompleted(true);
		} else if (!isNumberOfRequiredPostItsReached && completed) {
			setCompleted(false);
		}
	}, [isNumberOfRequiredPostItsReached, completed]);

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={completed}
			renderFunction={(renderProps) => {
				return (
					<React.Fragment>
						<div className="row m-0">
							{/* HEADER */}
							<Text
								className={classnames(
									"col-sm-12 mb-5 animated font-weight-bold",
									renderProps.viewVisible ? "fadeIn" : "fadeOut"
								)}
								tagName="h2"
								textId={cmtPageItem.header}
							/>
							{/* BODY */}
							<Text
								className={classnames(
									"p-medium col-sm-9 mb-4 animated",
									renderProps.viewVisible ? "dealy-250ms fadeIn" : "fadeOut"
								)}
								tagName="p"
								textId={cmtPageItem.body}
							/>
						</div>
						<Backend
							className="row ml-4 ml-sm-3 mt-3 mb-5 d-flex "
							quidType="postIt"
							mode="input"
							targetDataKey={cmtPageItem.io_id || cmtPageItem.id}
							renderInput={(backendRenderProps) => {
								const { create } = backendRenderProps;
								const SCROLL_ADD_A_NOTE_TEXT =
									"86125BF6-031E-48E2-B04D-F4173FF627E8";
								return (
									<ButtonPlus
										variant="tiny"
										labelTextId={SCROLL_ADD_A_NOTE_TEXT}
										onClick={() => {
											create().then((res) => {
												setEditQuidInputId(res.id);
											});
										}}
									/>
								);
							}}
						/>
						<Backend
							{...props}
							className={classnames(
								"row m-0 p-sm-0 p-2 mt-1 justify-content-center",
								renderProps.viewVisible ? "dealy-400ms fadeIn" : "fadeOut"
							)}
							quidType="postIt"
							mode="outputs"
							targetDataKey={ioID}
							match={{ targetDataKey: ioID }}
							renderOutputs={(backendRenderProps) => {
								const { data, save, updateData, id, remove, quidInputIds } =
									backendRenderProps;
								// this only seems to work when adding
								setNumPostIts(quidInputIds.length);
								return (
									<QuiddityPostItsOutput
										{...props}
										{...backendRenderProps}
										removeEnabled
										style={{
											display: "flex",
											flexDirection: "column",
											flexBasis: "410px",
											minWidth: "none",
											maxWidth: "100%",
										}}
										styleRemoveButton={{ top: "-12px" }}
										focus={id === editQuidInputId}
										body={data.body}
										color={cmtPageItem.post_it_color}
										textColor={cmtPageItem.post_it_text_color}
										viewVisible={true}
										className="col-4 p-1"
										classNameBody="p-md"
										maxRotation={4}
										maxTranslateX={0}
										onRemove={() => {
											remove(id);
											setNumPostIts(numPostIts - 1);
										}}
										onChange={(value) => {
											updateData({ ...data, body: value });
										}}
										onBlur={() => {
											save();
											setEditQuidInputId(null);
										}}
										onFocus={() => {
											setEditQuidInputId(id);
										}}
									/>
								);
							}}
						/>
					</React.Fragment>
				);
			}}
		></PageItemScrollBase>
	);
};

export default PageItemIOPostItCreate;
