import _ from "lodash";
import { useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { createSelector } from "reselect";

export const makeUseMembersForGroup = () =>
	createSelector(
		(state) => state.members.items,
		(state, groupId) => groupId || _.get(state, "quiddity.item.group.id"),
		(members, groupId) =>
			members
				.filter((member) => member.group === groupId)
				.map((member) => member.id)
	);

export const useMembersForGroup = ({ id, groupId } = {}) => {
	const makedMembersForGroup = useMemo(makeUseMembersForGroup, []);
	// console.log("makedMembersForGroup recomputations", makedMembersForGroup.recomputations());
	return useSelector(
		(state) => makedMembersForGroup(state, id || groupId),
		shallowEqual
	);
};

export const _useMembersForGroup = ({ id, groupId } = {}) => {
	return useSelector((state) => {
		const _id = id || groupId || _.get(state, "quiddity.item.group.id");
		return _.get(state, "members.items", [])
			.filter((member) => member.group === _id)
			.map((member) => member.id);
	}, shallowEqual);
};
