import React from "react";
import classnames from "classnames";
import { FFButton } from "funkis-foundation";
import Text from "../core/Text";

const DefaultButton = ({
	color = "black",
	transparent = false,
	show = true,
	enabled = true,
	onClick,
	labelTextId,
	children,
	className,
	classNameShow = "fadeIn",
	classNameHide = "fadeOut",
	size = "sm",
	round = false,
	style,
}) => {
	return (
		<FFButton
			enabled={enabled}
			onClick={onClick}
			style={{ transition: "0.2s", minWidth: "200px", ...style }}
			className={classnames(
				className,
				size === "xs" ? "btn-xs" : "btn",
				"rounded-pill animated text-uppercase",
				round ? "btn-round" : "rounded-pill",
				`btn-${color}`,
				transparent && `bg-${color}-60`,
				!enabled && "opacity-20",
				show ? classNameShow : classNameHide
			)}
		>
			<Text
				textId={labelTextId}
				className={`button-label-${size} font-weight-bold`}
			/>
			{children}
		</FFButton>
	);
};

export default DefaultButton;
