import React from "react";

const IconCross = ({
	color = "#000000",
	style,
	className,
	size = "md",
	scale = 1,
}) => {
	switch (size) {
		case "medium":
		case "md":
			return (
				<svg
					width="48px"
					height="48px"
					className={className}
					style={{ transform: `scale(${scale})`, ...style }}
					viewBox="0 0 48 48"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xlink="http://www.w3.org/1999/xlink"
				>
					<g
						id="Symbols"
						stroke="none"
						strokeWidth="1"
						fill="none"
						fillRule="evenodd"
					>
						<g id="components/dilemma/elements/feedback_bad" fill={color}>
							<path
								d="M38.3844924,-3.92930133e-12 L47.36,9.09679821 L32.655,24 L47.36,38.9032018 L38.3844924,48 L23.679,33.096 L8.97550757,48 L6.8378081e-12,38.9032018 L14.704,24 L-1.1439738e-12,9.09679821 L8.97550757,1.46371804e-11 L23.68,14.903 L38.3844924,-3.92930133e-12 Z"
								id="Combined-Shape"
							></path>
						</g>
					</g>
				</svg>
			);
		case "large":
		case "lg":
			return (
				<svg
					width="94px"
					height="96px"
					className={className}
					style={{ transform: `scale(${scale})`, ...style }}
					viewBox="0 0 94 96"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xlink="http://www.w3.org/1999/xlink"
				>
					<g
						id="Symbols"
						stroke="none"
						strokeWidth="1"
						fill="none"
						fillRule="evenodd"
					>
						<g
							id="components/dilemma/elements/feedback_neutral-center"
							transform="translate(-97.000000, 0.000000)"
							fill={color}
						>
							<path
								d="M173.185437,-1.20309096e-11 L191,18.1935964 L161.814,48 L191,77.8064036 L173.185437,96 L143.999,66.193 L114.814563,96 L97,77.8064036 L126.185,48 L97,18.1935964 L114.814563,2.26577868e-11 L144,29.806 L173.185437,-1.20309096e-11 Z"
								id="Combined-Shape"
							></path>
						</g>
					</g>
				</svg>
			);
	}
};

export default IconCross;
