import * as StoreUtils from "../../utils/StoreUtils";
import {
	UPDATE_QUIDDITY,
	UPDATE_QUIDDITY_VALUE,
} from "../constants/ActionConstants";

export function updateQuiddity(item) {
	StoreUtils.getStore().dispatch(_updateQuiddity(item));
}

function _updateQuiddity(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_QUIDDITY });
	};
}

export function updateQuiddityValue(item) {
	StoreUtils.getStore().dispatch(_updateQuiddityValue(item));
}

function _updateQuiddityValue(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_QUIDDITY_VALUE });
	};
}
