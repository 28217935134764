class MissingIOError extends Error {
	title: string;
	friendlyId: string;
	symbolName: string;

	constructor(friendlyId: string, symbolName: string) {
		const message = `This Page Item (${symbolName}) requires an IO named "${friendlyId}" to save and fetch data. Please add an IO in FAT and try again.`;
		super(message);

		this.name = "MissingIOError";
		this.title = `Page Item is missing an IO`;
		this.friendlyId = friendlyId;
		this.symbolName = symbolName;
	}
}

export default MissingIOError;
