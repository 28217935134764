import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { AudioUtils, PageItemActions } from "funkis-foundation";

import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import QuizIntro from "../../components/scroll/quiz/QuizIntro";
import { objectPropsToCamelCase } from "../../utils/objectUtils";
import QuizQuestion from "../../components/scroll/quiz/QuizQuestion";
import QuizScore from "../../components/scroll/quiz/QuizScore";
import QuizHighScore from "../../components/scroll/quiz/QuizHighScore";
import QuizSummary from "../../components/scroll/quiz/QuizSummary";
import { findText, getText } from "../../utils/textUtils";
import { useSelector } from "react-redux";
import { IOContext, QuidType, useBackend } from "funkis-template/hooks/backend";
const { updatePageItem } = PageItemActions;

const PageItemQuiz = (props) => {
	const { pageItem, height = "35.625rem", minHeight = "570px" } = props;
	const { cmtPageItem } = pageItem;
	const [viewVisible, setViewVisible] = useState();
	const baseColor = cmtPageItem.color_base || "primary";
	const musicVolume = cmtPageItem.hasOwnProperty("music_volume")
		? cmtPageItem.music_volume / 100
		: 1;

	// Find quiz pageItems (blocks)
	const quizBlocks = useSelector((state) =>
		// @ts-expect-error
		state.pageItems.items
			.filter(
				(item) => item.parentPageId === cmtPageItem.page_id && item.cmtPageItem
			)
			.map((item) => item.cmtPageItem)
	);

	// load data
	const {
		quidInputs: resultDataRaw,
		createQuidInput: createResultQuidInput,
		updateQuidInput: updateResultQuidInput,
	} = useBackend({
		quidType: QuidType.Result,
		targetDataKey: cmtPageItem.io_id,
		inputContext: IOContext.Member,
	});
	const saveResultQuidInput = (alwaysCreate, resultQuidInput) => {
		if (!resultQuidInput.id || alwaysCreate) {
			createResultQuidInput(resultQuidInput);
		} else {
			updateResultQuidInput(resultQuidInput);
		}
	};

	const resultData = (_.last(resultDataRaw) || ({} as any))?.data ?? {};

	const {
		quidInputs: quizQuidInputs = [],
		createQuidInput: createQuizQuidInput,
		updateQuidInput: updateQuizQuidInput,
	} = useBackend({
		quidType: QuidType.Quiz,
		targetDataKey: cmtPageItem.io_id,
		inputContext: IOContext.Member,
	});

	const quizQuidInput = quizQuidInputs[quizQuidInputs.length - 1] || {};

	const saveQuizQuidInput = useCallback(
		(alwaysCreate, quizQuidInputUpdates) => {
			const updatedQuizQuidInput = {
				...quizQuidInput,
				...quizQuidInputUpdates,
			};
			if (!quizQuidInput.id || alwaysCreate) {
				createQuizQuidInput(updatedQuizQuidInput);
			} else {
				updateQuizQuidInput(updatedQuizQuidInput);
			}
		},
		[createQuizQuidInput, updateQuizQuidInput, quizQuidInput]
	);

	const [hasLoadedSounds, setHasLoadedSounds] = useState(false);

	useEffect(() => {
		// Load sounds
		if (!hasLoadedSounds) {
			AudioUtils.set("quiz-music", {
				src: `./content/sounds/${cmtPageItem.music || "quiz_music.mp3"}`,
				volume: musicVolume,
				loop: cmtPageItem.music_loop === "yes",
			});

			AudioUtils.set("quiz-click", {
				src: "./content/sounds/quiz_answer_click.mp3",
			});
			AudioUtils.set("quiz-arpeggio-1", {
				src: "./content/sounds/quiz_arpeggio_1.mp3",
			});
			AudioUtils.set("quiz-arpeggio-2", {
				src: "./content/sounds/quiz_arpeggio_2.mp3",
			});
			AudioUtils.set("quiz-arpeggio-3", {
				src: "./content/sounds/quiz_arpeggio_3.mp3",
			});
			AudioUtils.set("quiz-answer-right", {
				src: `./content/sounds/${
					cmtPageItem.sound_correct || "quiz_answer_right.mp3"
				}`,
			});
			AudioUtils.set("quiz-answer-wrong", {
				src: `./content/sounds/${
					cmtPageItem.sound_incorrect || "quiz_answer_wrong.mp3"
				}`,
			});
			setHasLoadedSounds(true);
		}
		return () => {
			AudioUtils.stop("quiz-music");
		};
	}, [setHasLoadedSounds, hasLoadedSounds, cmtPageItem, musicVolume]);

	useEffect(() => {
		if (viewVisible) {
			AudioUtils.fade("quiz-music", { from: 0, to: musicVolume });
		} else {
			if (hasLoadedSounds) {
				AudioUtils.fade("quiz-music", {
					from: AudioUtils.get("quiz-music").volume(),
					to: 0,
				});
			}
		}
	}, [viewVisible, hasLoadedSounds]);

	// Code previously in renderInputs

	const { data: currentQuizInputData = {} } = quizQuidInput;
	const { answers = {}, currentRound = [] } = currentQuizInputData;
	const currentView =
		currentQuizInputData.currentView === null ||
		currentQuizInputData.currentView === undefined
			? "intro"
			: currentQuizInputData.currentView;

	// ON ANSWER COMPLETE
	const onAnswerCompleteHandler = useCallback(() => {
		const nextView =
			currentView < currentRound.length - 1 ? currentView + 1 : "score";
		saveQuizQuidInput(false, {
			data: {
				...currentQuizInputData,
				currentView: nextView,
			},
		});
	}, [currentView, currentRound, currentQuizInputData, saveQuizQuidInput]);
	// ON TRY AGAIN
	const onTryAgainClickHandler = () => {
		saveQuizQuidInput(true, { data: { currentView: "intro"}});
	};

	const [visibleScore, setVisibleScore] = useState(resultData.scoreRaw || 0);


	if (quizBlocks.length === 0) {
		return <span className="color-warning">{`No "page id" found!`}</span>;
	}

	if (!cmtPageItem.io_id) {
		alert(`No "io id" found!`);
		return <span className="color-warning">{`No "io id" found!`}</span>;
	}

	if (!hasLoadedSounds) return <div />;

	const quizIntroBlock = objectPropsToCamelCase(
		quizBlocks.find((item) => item.symbol_name === "quiz-intro")
	);
	const quizQuestionBlocks = quizBlocks
		.filter((item) => item.symbol_name === "quiz-question")
		.map((item) => objectPropsToCamelCase(item));
	const quizScoreBlock = quizBlocks.find(
		(item) => item.symbol_name === "quiz-score"
	);
	const quizHighScoreBlock = quizBlocks.find(
		(item) => item.symbol_name === "quiz-high-score"
	);
	const quizSummaryBlock = quizBlocks.find(
		(item) => item.symbol_name === "quiz-summary"
	);

	return (
		<PageItemScrollBase
			{...props}
			className="px-0 mx-0 position-relative"
			rootClassName="mb-6"
			style={{ height, minHeight }}
			rootStyle={{ minHeight: "610px" }}
			showNextButton={
				cmtPageItem.show_next_button === "always" ||
				pageItem.status === "completed"
			}
			centerNextButtonOnScroll={false}
			renderFunction={(renderProps) => {
				setViewVisible(renderProps.viewPartial);

				const isOrWasShowNextButtonInView =
					cmtPageItem.show_next_button_in_view === undefined ||
					cmtPageItem.show_next_button_in_view === "any" ||
					cmtPageItem.show_next_button_in_view === currentView;

				if (cmtPageItem.show_next_button === "passed") {
					// set pageItem to completed...
					isOrWasShowNextButtonInView &&
						currentQuizInputData.passed &&
						pageItem.status !== "completed" &&
						updatePageItem(pageItem.id, { status: "completed" });
				}

				if (cmtPageItem.show_next_button === "completed") {
					// set pageItem to completed...
					isOrWasShowNextButtonInView &&
						(currentQuizInputData.status === "completed" ||
							currentQuizInputData.passed) &&
						pageItem.status !== "completed" &&
						updatePageItem(pageItem.id, { status: "completed" });
				}

				// GET SCORE FEEDBACK
				const getScoreFeedback = (quizScoreBlock) => {
					const scorePercentage = resultData.scoreScaled * 100;

					// Due to misspell that can nor be corrected in a good way because CMT sucks.
					const feedback1Threshold =
						quizScoreBlock.feedback_1_threshold ||
						quizScoreBlock["feedback-1-threshold"];

					const feedbackNum =
						(scorePercentage >= feedback1Threshold && 1) ||
						(scorePercentage >= quizScoreBlock.feedback_2_threshold && 2) ||
						(scorePercentage >= quizScoreBlock.feedback_3_threshold && 3) ||
						(scorePercentage >= quizScoreBlock.feedback_4_threshold && 4) ||
						(scorePercentage >= quizScoreBlock.feedback_5_threshold && 5);

					const feedbacks = {
						0: undefined,
						1:
							findText(quizScoreBlock.feedback_1, {
								returnType: "id",
							}) || "7BBECAD2-79BD-4B39-9BDE-3FE316650261",
						2:
							findText(quizScoreBlock.feedback_2, {
								returnType: "id",
							}) || "A7E93EF7-5181-4BDE-AB7B-C7D28ED9FD6F",
						3:
							findText(quizScoreBlock.feedback_3, {
								returnType: "id",
							}) || "B082D1D6-7CDC-40A1-86AD-7AC939BECE8F",
						4:
							findText(quizScoreBlock.feedback_4, {
								returnType: "id",
							}) || "483D402D-2612-42CF-994C-D0B2C8797B81",
						5:
							findText(quizScoreBlock.feedback_5, {
								returnType: "id",
							}) || "65B90D52-159D-4363-AD24-63B7308398CE",
					};

					return feedbacks[feedbackNum || 0];
				};

				// ON START
				const onStart = () => {
					if (!quizQuestionBlocks.length) {
						alert("No qestions found!");
					}
					const numberOfQuestionsPerRound =
						cmtPageItem.number_of_questions_per_round || 5;
					// Warnin if not enough questions...
					if (quizQuestionBlocks.length < numberOfQuestionsPerRound) {
						alert(
							`Number of questions per round is set to ${numberOfQuestionsPerRound} but only ${quizQuestionBlocks.length} were found! Quiz can not be started!`
						);
					}
					// Set up round
					let newRound = quizQuestionBlocks.map((_, index) => index);
					if (cmtPageItem.randomize_questions === "yes") {
						newRound = _.shuffle(newRound);
					}
					newRound = newRound.slice(0, numberOfQuestionsPerRound);
					// Calc score min & max

					const pointsMinArr = newRound.map(
						(value) =>
							quizQuestionBlocks[value].points_min ||
							cmtPageItem.points_min_per_question
					);
					const pointsMaxArr = newRound.map(
						(value) =>
							quizQuestionBlocks[value].points_max ||
							cmtPageItem.points_max_per_question
					);
					const scoreMin = pointsMinArr.reduce((acc, val) => acc + val);
					const scoreMax = pointsMaxArr.reduce((acc, val) => acc + val);

					setVisibleScore(0);

					saveQuizQuidInput(false, {
						data: {
							...currentQuizInputData,
							status: currentQuizInputData.status || "incomplete",
							scoreMax,
							scoreMin,
							currentRound: newRound,
							currentView: 0,
						},
					});

					// create the first result
					saveResultQuidInput(true, {
						data: {
							...resultData,
							passed: currentQuizInputData.passed,
							completed: currentQuizInputData.status === "completed",
							scoreRaw: 0,
							scoreMax,
							scoreMin,
							scoreScaled: 0,
						},
					});
				};
				// ON ANSWER SELECT
				const onAnswerSelect = ({
					id,
					selectedIndexes,
					time,
					points,
					correct,
					pointsMax,
					pointsMin,
					options,
					question,
					contentArray,
				}) => {
					const selectedOption = options[selectedIndexes[0]];
					const correctOption = contentArray.find((item) => item.correct);
					const answer = {
						selectedIndexes,
						time,
						points,
						correct,
						correctOption: getText(correctOption.option),
						pointsMax,
						pointsMin,
						question: getText(question),
						answer: getText(selectedOption.option),
						questionIndex: currentRound[currentView],
						currentRound,
						questionNum: currentView + 1,
					};
					const newAnswers = { ...answers, [id]: answer };

					// calcutate total score...
					const currentAnswers = quizQuestionBlocks
						.filter((item) => newAnswers[item.id])
						.map((item) => newAnswers[item.id]);

					const scoresArr = currentAnswers.map((item) => item.points || 0);
					const score = scoresArr.reduce((acc, val) => acc + val); // Current total score

					const numCorrectAnswers = currentAnswers.filter(
						(item) => item.correct
					).length;
					const passedThreshold =
						cmtPageItem.passed_threshold || cmtPageItem.passed_treshold; // due to misspell...
					// status...
					const status =
						(numCorrectAnswers >= passedThreshold && "passed") ||
						(currentView < currentRound.length - 1 && "incomplete") ||
						"failed";
					// badge...
					const scorePercentage = (score / currentQuizInputData.scoreMax) * 100;
					// Due to misspell...
					const goldThreshold =
						cmtPageItem.badge_gold_score_threshold ||
						cmtPageItem.badge_gold_score_treshold;
					const silverThreshold =
						cmtPageItem.badge_silver_score_threshold ||
						cmtPageItem.badge_silver_score_treshold;
					const bronzeThreshold =
						cmtPageItem.badge_bronze_score_threshold ||
						cmtPageItem.badge_bronze_score_treshold;
					const badge =
						(status === "passed" &&
							scorePercentage >= goldThreshold &&
							"gold") ||
						(status === "passed" &&
							scorePercentage >= silverThreshold &&
							"silver") ||
						(status === "passed" &&
							scorePercentage >= bronzeThreshold &&
							"bronze") ||
						"none";
					const passed = currentQuizInputData.passed || status === "passed";
					const newStatus =
						(currentView + 1 === currentRound.length && "completed") ||
						currentQuizInputData.status;

					saveQuizQuidInput(false, {
						data: {
							...currentQuizInputData,
							answers: newAnswers,
							score,
							numQuestions: currentRound.length,
							numCorrectAnswers,
							passedThreshold,
							badge,
							status: newStatus,
							passed,
						},
					});

					const newResult = {
						data: {
							...resultData,
							passed,
							completed: newStatus === "completed",
							scoreRaw: score,
							scoreScaled: score / resultData.scoreMax,
						},
					};

					saveResultQuidInput(false, newResult);
				};

				return (
					<div className="mb-5 row m-0 h-100 position-relative">
						{[
							// COMPONENT: INTRO
							quizIntroBlock && (
								// @ts-expect-error TODO: fix with proper definition of introBlock
								<QuizIntro
									{...quizIntroBlock}
									musicVolume={musicVolume}
									baseColor={baseColor}
									onStart={onStart}
									show={currentView === "intro"}
									contentHeight={height}
									contentMinHeight={minHeight}
									soundOn={pageItem.status !== "completed"}
								/>
							),
							// COMPONENT: QUESTIONS
							!_.isNumber(currentView) ? null : quizQuestionBlocks.map((quizQuestionBlock, index) => {
								const answer = answers[quizQuestionBlock.id] || {};
								const show = currentRound[currentView] === index;
								// Key generation with blockId + quizQuidInput because
								// this ensures new keys  for each new round, even if
								// the same question appears twice or more
								const key = `${index} ${quizQuestionBlock.id} ${quizQuidInput?.id}`;
								return [(
									<QuizQuestion
										key={key}
										{...quizQuestionBlock}
										visibleScore={visibleScore} 
										setVisibleScore={setVisibleScore}
										builtInSounds={cmtPageItem.built_in_sounds !== "no"}
										musicVolume={musicVolume}
										musicStopOnAnswer={cmtPageItem.music_loop !== "yes"}
										baseColor={baseColor}
										backgroundColor={cmtPageItem.question_background_color}
										pointsMax={
											(!isNaN(quizQuestionBlock.points_max) &&
												quizQuestionBlock.points_max) ||
											cmtPageItem.points_max_per_question
										}
										pointsMin={
											(!isNaN(quizQuestionBlock.points_min) &&
												quizQuestionBlock.points_min) ||
											cmtPageItem.points_min_per_question
										}
										randomizeOptions={cmtPageItem.randomize_options}
										contentHeight={height}
										contentMinHeight={minHeight}
										index={currentView}
										numQuestions={currentRound.length}
										duration={
											quizQuestionBlock.timerDuration ||
											quizQuestionBlock.duration ||
											cmtPageItem.timer_duration
										}
										selectedIndexes={answer.selectedIndexes}
										score={resultData.scoreRaw}
										points={answer.points}
										onSelect={onAnswerSelect}
										onComplete={onAnswerCompleteHandler}
										show={show}
										interactionColor={
											cmtPageItem.option_buttons_interaction_color
										}
									/>
								)];
							}),
							// COMPONENT: SCORE
							quizScoreBlock && (
								<QuizScore
									{...currentQuizInputData}
									showScore={quizScoreBlock.show_score}
									feedbackTextId={getScoreFeedback(quizScoreBlock)}
									badgeIoId={cmtPageItem.io_id}
									showBadge={quizScoreBlock.show_badge !== "no"}
									baseColor={baseColor}
									backgroundColor={quizScoreBlock.background_color || "white"}
									show={currentView === "score"}
									contentHeight={height}
									contentMinHeight={minHeight}
									onTryAgainClick={onTryAgainClickHandler}
									onNextClick={() => {
										const nextView =
											(quizHighScoreBlock && "highScore") ||
											(quizSummaryBlock && "summary") ||
											"none";

										saveQuizQuidInput(false, {
											data: {
												...currentQuizInputData,
												currentView: nextView,
											},
										});
									}}
								/>
							),
							// HIGH SCORE
							quizHighScoreBlock && (
								<QuizHighScore
									{...currentQuizInputData}
									score={resultData.scoreRaw}
									baseColor={baseColor}
									backgroundColor="white"
									contentHeight={height}
									contentMinHeight={minHeight}
									show={currentView === "highScore"}
									currentQuidInput={resultData}
									ioId={cmtPageItem.io_id}
									ioContext={quizHighScoreBlock.io_context}
									onTryAgainClick={onTryAgainClickHandler}
									onNextClick={() => {
										saveQuizQuidInput(false, {
											data: {
												...currentQuizInputData,
												currentView: (quizSummaryBlock && "summary") || null,
											},
										});
									}}
								/>
							),
							quizSummaryBlock && (
								<QuizSummary
									{...quizSummaryBlock}
									{...currentQuizInputData}
									baseColor={baseColor}
									backgroundColor={quizSummaryBlock.background_color || "white"}
									contentHeight={height}
									contentMinHeight={minHeight}
									show={currentView === "summary"}
									onDoneClick={onTryAgainClickHandler}
									onPreviousClick={() => {
										const triggerTryAgain = !(
											quizHighScoreBlock || quizSummaryBlock
										);

										if (triggerTryAgain) {
											onTryAgainClickHandler();
										}
										saveQuizQuidInput(false, {
											data: {
												...currentQuizInputData,
												currentView:
													(quizHighScoreBlock && "highScore") ||
													(quizSummaryBlock && "score") ||
													undefined,
											},
										});
									}}
								/>
							),
						]}
					</div>
				);
			}}
		/>
	);
};

export default PageItemQuiz;
