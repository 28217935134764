import React from "react";
import _ from "lodash";
import { FFMultiSelect, PageItemActions } from "funkis-foundation";

const { updatePageItem } = PageItemActions;

const MultiSelect = ({
	pageItem,
	minSelections,
	maxSelections,
	onSelect,
	optionRender,
	className,
	buttonClassName,
	enabled = true,
	autoDeselectWhenMaxSelectionsReached,
}) => {
	const { cmtPageItem = {} } = pageItem;
	const options = cmtPageItem.content_array;
	const selectedIndexes = pageItem.selectedIndexes || [];
	const correctIndexes = options
		.map((option, index) => (option.correct || option.correct_option) && index)
		.filter((val) => val !== false);
	const isCorrect = _.isEqual(
		[...selectedIndexes].sort(),
		[...correctIndexes].sort()
	);
	const completed = pageItem.status === "completed";

	return (
		<FFMultiSelect
			className={className}
			enabled={enabled && !completed}
			buttonClassName={buttonClassName}
			isCorrect={isCorrect}
			toggle={true}
			maxSelections={
				(correctIndexes.length === 1 && 1) || maxSelections || Number.MAX_VALUE
			}
			minSelections={minSelections || 1}
			selectedIndexes={selectedIndexes}
			disableWhenMaxSelectionsReached={false}
			autoDeselectWhenMaxSelectionsReached={
				autoDeselectWhenMaxSelectionsReached || correctIndexes.length === 1
			}
			onSelect={(indexes) => {
				updatePageItem(pageItem.id, {
					selectedIndexes: indexes,
				});
				onSelect &&
					onSelect({
						selectedIndexes: indexes,
						option: indexes.length === 1 && options[indexes[0]],
						isCorrect,
						completed,
					});
			}}
		>
			{options.map((option, index) =>
				optionRender({
					key: `muti-select-${index}`,
					index,
					option,
					selected: selectedIndexes.includes(index),
					completed,
					isCorrect,
				})
			)}
		</FFMultiSelect>
	);
};

export default MultiSelect;
