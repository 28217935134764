import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";

class FFScrollVertical extends React.Component {
	constructor(props) {
		super(props);
		this.selector = React.createRef();
		this.state = {
			viewPartial: false,
			viewComplete: false,
			wasViewPartial: false,
			wasViewComplete: false,
		};
	}

	static propTypes = {
		onScroll: PropTypes.func,
		onScrollEnterStart: PropTypes.func,
		onScrollEnterEnd: PropTypes.func,
		onScrollExitStart: PropTypes.func,
		onScrollExitEnd: PropTypes.func,
	};

	componentDidMount() {
		this.debouncedOnScroll = _.debounce(this._onScroll, 100);
		window.addEventListener("scroll", this.debouncedOnScroll);
		this._onScroll();
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.debouncedOnScroll);
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			onScrollEnterStart,
			onScrollEnterEnd,
			onScrollExitStart,
			onScrollExitEnd,
		} = this.props;

		const prevViewPartial = prevState.viewPartial;
		const currViewPartial = this.state.viewPartial;
		const prevViewComplete = prevState.viewComplete;
		const currViewComplete = this.state.viewComplete;

		if (prevViewPartial !== currViewPartial) {
			if (currViewPartial) {
				// SCROLL ENTER START
				onScrollEnterStart && onScrollEnterStart();
			} else {
				// SCROLL EXIT END
				onScrollExitEnd && onScrollExitEnd();
			}
		}

		// SCROLL ENTER END
		if (prevViewComplete !== currViewComplete) {
			if (currViewComplete) {
				// SCROLL ENTER END
				onScrollEnterEnd && onScrollEnterEnd();
			} else {
				// SCROLL EXIT END
				onScrollExitStart && onScrollExitStart();
			}
		}
	}

	_onScroll = () => {
		const { onScroll } = this.props;
		const { wasViewPartial, wasViewComplete } = this.state;

		const { scrollingElement } = document;
		const { scrollTop, scrollHeight, clientHeight } = scrollingElement;

		const current = this.selector.current;
		if (!current) {
			return;
		}

		const rect = current.getBoundingClientRect();
		let { top, height } = rect;

		const viewPartial = top < clientHeight && top > -height;
		const viewComplete =
			rect.top >= 0 &&
			rect.bottom <=
				(window.innerHeight || document.documentElement.clientHeight);

		const clientPercent =
			(scrollTop / (scrollHeight - clientHeight) || 0) * 100;
		const viewPercent =
			100 - Math.max(Math.min((top / clientHeight) * 100, 100), 0);

		this.setState({
			viewPartial,
			viewComplete,
			wasViewPartial: viewPartial ? viewPartial : wasViewPartial,
			wasViewComplete: viewComplete ? viewComplete : wasViewComplete,
		});
		onScroll &&
			onScroll({
				clientPercent,
				viewPercent,
				wasViewPartial: viewPartial ? viewPartial : wasViewPartial,
				wasViewComplete: viewComplete ? viewComplete : wasViewComplete,
			});
	};

	render() {
		const {
			className,
			children,
			enabled,
			tagName: CustomTag = "div",
			style,
			rest,
		} = this.props;
		const { viewPartial, viewComplete, wasViewPartial, wasViewComplete } =
			this.state;

		return (
			<CustomTag
				ref={this.selector}
				className={classnames(
					"ff-scroll-vertical",
					className,
					enabled ? " enabled" : " disabled",
					viewPartial && "view-partial",
					viewComplete && "view-complete",
					wasViewPartial && "was-view-partial",
					wasViewComplete && "was-view-complete"
				)}
				style={style}
				{...rest}
			>
				{children}
			</CustomTag>
		);
	}
}

export default FFScrollVertical;
