import React from "react";

function IconCorrectCheckmark({ className }) {
	return (
		<div className={className}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
				<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g transform="translate(-455 -6961)">
						<path fill="transparent" d="M0 0H1280V11164H0z"></path>
						<g transform="translate(239 6951)">
							<path
								stroke="#EEE"
								strokeWidth="10"
								d="M5 5H266.506V151H5z"
							></path>
						</g>
						<g transform="translate(455 6961)">
							<path fill="transparent" d="M0 44.5L44.5 44.5 44.5 0 0 0z"></path>
							<path
								fill="#7FCC1B"
								d="M31.3469 5.284L18.7649 33.113 10.9469 29.276 7.7099 35.868 22.3339 43.049 38.0399 8.308z"
							></path>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
}

export default IconCorrectCheckmark;
