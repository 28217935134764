import z from "zod";
import { IOContext, QuidType } from "../hooks/backend";
import { makeGetValueListById } from "../hooks/valuelist";
import { StoreUtils } from "funkis-foundation";
import { TitleContentArray } from "./contentarray";

const selectValueList = makeGetValueListById();

const getTitleContentArray = (id: string): TitleContentArray => {
	const state: any = StoreUtils.getState();
	const valueList = selectValueList(state, {
		valueListId: id,
	}) as TitleContentArray;
	return valueList;
};

const TextId = z.string().uuid();

const BlockBase = z.object({ id: z.string().uuid() });
const ScrollBlockBase = BlockBase.extend({
	reveal_next_block: z.enum(["yes", "no"]),
});
const SlideBlockBase = BlockBase.extend({
	course_status: z.enum([
		"completed",
		"incomplete",
		"not_attempted",
		"unknown",
	]),
});

const OptionalUUID = z.string().optional();

const MediaCrop = z.enum(["Crop", "Resize to fit"]);
const MediaFit = z.enum([
	"Full width",
	"Align center",
	"Align left",
	"Align right",
]);
const TimerFormat = z.enum(["hh:mm:ss", "hh:mm", "mm:ss", "mm", "ss"]);
const Color = z.string();
const IO = z.object({
	context: z.nativeEnum(IOContext),
	friendly_label_id: z.string(),
	id: z.string().uuid(),
	type: z.nativeEnum(QuidType),
	text_1_id: z.string().uuid().optional(),
	text_2_id: z.string().uuid().optional(),
});

const IOGroup = z.object({
	context: z.nativeEnum(IOContext),
	friendly_label_id: z.string(),
	id: z.string().uuid(),
	type: z.literal("io-group"),
	io_group: z.object({
		id: z.string().uuid(),
		name: z.string(),
		values: z.array(
			z.object({
				friendly_id: z.string(),
				group_name: z.string().uuid(),
				id: z.string().uuid(),
				ios: z.array(z.string().uuid()),
			})
		),
	}),
});

const titleContentArrayOfType = (
	contentArrayType: TitleContentArray["template"]
) =>
	z
		.string()
		.nonempty()
		.uuid()
		.refine(
			(id) => {
				const contentArray = getTitleContentArray(id);
				return contentArray && contentArray.template == contentArrayType;
			},
			(id) => {
				const contentArray = getTitleContentArray(id);
				return {
					message: contentArray
						? `Title Content Array "${contentArray.name}" is the wrong type "${contentArray.template}". It's expected to be of type "${contentArrayType}".`
						: `Title Content Array with id "${id}" doesn't exist.`,
				};
			}
		);

const ActivityLinkScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("site-activity-link"),
	activity_identifier: TextId,
	preamble: TextId,
	header: TextId,
	body: TextId,
	label: TextId,
	duration: TextId,
	image: z.string(),
	date_context: z.enum(["group", "session"]),
	show_activity_icon: z.enum(["yes", "no"]),
	hide_warnings: z.enum(["yes", "no"]),
	activity_type: z.enum(["individual", "group", "q&a", "instructor-led"]),
	package_url: z.string().optional(),
	launch_url: z.string().optional(),
	package_id: z.string().uuid().optional(),
});

const ArticleScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("article"),
	header: TextId,
	body_column_1: TextId,
	body_column_2: TextId,

	image: z.string(),
	image_column_2: z.string(),
	image_caption: TextId,
	image_caption_column_2: TextId,
	image_size: z.enum(["keep ratio", "crop"]),
	image_span: z.enum(["1-column", "2-column"]),
	image_align: z.enum(["left", "center", "align"]),
	image_margins_mobile: z.enum(["yes", "no"]),

	image_layout_variant: z.enum(["image above heading", "image below heading"]),

	zoomable: z.enum(["yes", "no"]),
	video_autoplay: z.enum(["once", "loop"]),
	image_max_height: z.number(),
});

const KnowledgeQuizScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("knowledge-quiz"),
	quiz_page: z.string().uuid(),
	show_next_button: z.enum(["always", "passed", "completed"]),
	completion_criteria: z.enum(["passed", "after one run"]),
	passed_threshold: z.number(),
	variant: z.enum(["plain", "speech_bubble_round", "speech_bubble_angular"]),
	option_background_color: Color,
	ios: z.object({
		quiz_data: IO,
	}),
});

const CommentInputScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("io-comment-input-v2"),
	header: TextId,
	body: TextId,
	instruction: TextId,
	image: z.string(),

	header_label: TextId,
	body_label: TextId,

	max_char_input_header: z.number(),
	max_char_input_body: z.number(),

	rows_input_header: z.number(),
	rows_input_body: z.number(),

	input_text_color: Color,
	input_background_color: Color,

	desktop_width: z.enum(["full width", "column"]),

	show_next_button: z.enum(["always", "auto"]),

	ios: z.object({
		comments: IO,
	}),
});

const CommentOutputScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("io-comment-output"),
	header: TextId,
	body: TextId,
	comment_background_color: Color,
	comment_bar_color: Color,
	comment_text_color: Color,
	desktop_columns: z.enum(["one", "three"]),
	filter_selected: z.enum(["yes", "no"]),
	show_comment_author: z.enum(["yes", "no"]),
	show_comment_date: z.enum(["yes", "no"]),
	show_next_button: z.enum(["always", "auto"]),
	io_context: z.nativeEnum(IOContext),
	io_id: z.string(),
});

const DilemmaInputScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("io-dilemma-input-v2"),
	header: TextId,
	sub_header: TextId,
	read_more: TextId,

	image_max_height: z.number(),
	point_categories: TextId,
	point_multiplier_per_attempt: z.string(),
	graphics_correct: z.string(),
	graphics_incorrect: z.string(),
	graphics_neutral: z.string(),
	image: z.string(),
	image_size: z.enum(["keep ratio", "crop"]),

	valuelist_id: titleContentArrayOfType("dilemma"),
	ios: z.object({
		answers: IO,
	}),
});

const LanguageSelectScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("language-select"),
	align: MediaFit,
});

const LinksScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("links"),
	header: TextId,
	body: TextId,
	instruction: TextId,
	icon: z.string(),
	icon_size: z.number(),
	item_layout: z.enum(["List", "Grid"]),

	content_array: z.array(
		z.object({
			id: z.string().uuid(),
			header: TextId,
			body: TextId,
			url: TextId,
			icon: z.string(),
		})
	),
});

const MediaUploadScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("io-media-upload"),
	header: TextId,
	body: TextId,
	show_next_button: z.enum(["auto", "always"]),
	ios: z.object({
		media: IO,
	}),
});

const MultipleChoiceInputScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("io-multiple-choice-input-v3"),
	feedback_correct: TextId,
	feedback_incorrect: TextId,
	question: TextId,
	instruction: TextId,
	image: z.string(),
	validation_tooltip: TextId,
	image_size: z.enum(["keep ratio", "crop"]),
	image_margins_mobile: z.enum(["yes", "no"]),
	image_align: z.enum(["left", "center", "right"]),
	show_next_button: z.enum(["always", "on select", "on done"]),
	redoable: z.enum(["yes", "no"]),
	correct: z.enum(["yes", "no"]),
	blur_image_before_answer: z.enum(["yes", "no"]),
	show_done_button: z.enum(["yes", "no"]),
	number_of_option_columns: z.enum(["one", "two", "three"]),
	image_max_height: z.number(),
	max_selections: z.number(),
	min_selections: z.number(),
	ios: z.object({
		current_selection: IO,
		selection_basis: IO.optional(),
	}),
});

const MultipleChoiceOutputScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("io-multiple-choice-output-v3"),

	header: TextId,
	body: TextId,

	chart_type: z.enum(["pie", "bar"]),
	filter: z.enum(["show all options", "hide unselected options"]),
	base_color: Color,

	valuelist_id: titleContentArrayOfType("multiple-choice"),

	ios: z.object({
		selections: IO,
	}),
});

const NoteCategorizeScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("io-note-categorize-output"),
	header: TextId,
	body: TextId,
	valuelist_id: titleContentArrayOfType("category"),
	note_background_color: Color,
	background_color: Color,
	text_color: Color,
	ios: z.object({
		notes: IO,
	}),
	show_next_button: z.enum(["yes", "no"]),
});

const ParticipantsScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("participants"),
	show_members: z.enum(["Group members", "Session members"]),
	avatar_background_color: Color,
	avatar_text_color: Color,
	header: TextId,
	body: TextId,
});

const PostItCategorizeScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("io-post-it-categorize-output-v2"),
	header: TextId,
	body: TextId,
	valuelist_id: titleContentArrayOfType("category"),
	post_it_color: z.string(),
	background_color: z.string(),
	text_color: z.string(),
	io_id: z.string().uuid(),
	io_context: z.nativeEnum(IOContext),
	show_next_button: z.enum(["yes", "no"]),
});

const RateInputScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("io-rate-input"),

	body: TextId,

	content_array: z.array(
		z.object({
			id: z.string().uuid(),
			label: z.string().uuid(),
			value: z.number(),
		})
	),

	variant: z.enum([
		"poll",
		"question with feedback",
		"question with feedback and correction",
	]),

	correct_max_value: z.number(),
	correct_min_value: z.number(),
	correct_values: z.string(),

	feedback_correct: TextId,
	feedback_incorrect: TextId,

	header: TextId,
	instruction: TextId,

	show_next_button: z.enum(["always", "auto"]),
	redoable: z.enum(["yes", "no"]),
	slider_margins: z.enum(["1", "2", "3", "4", "5", "6"]),
	slider_steps: z.number(),
	slider_start_value: z.number(),
	slider_label_width: z.string(),
	slider_edge_label_width: z.string(),
	slider_handle_color: Color,
	slider_track_color: Color,
	slider_fill_mode: z.enum(["colored", "none"]),
	media: z.string(),
	slider_media: z.string(),

	io_id: z.string(),
	valuelist_id: titleContentArrayOfType("rate"),
});

const RateOutputScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("io-rate-output"),

	header: TextId,
	description: TextId,
	body: TextId,

	slider_handle_color: Color,
	slider_track_color: Color,
	slider_fill_mode: z.enum(["colored", "none"]),
	media: z.string(),
	slider_media: z.string(),

	io_id: z.string(),
	valuelist_id: titleContentArrayOfType("rate"),

	badge_tone: z.enum(["auto", "info"]),
	badge_style: z.enum(["badge", "text"]),
	variation_threshold_high: z.number(),
	variation_threshold_medium: z.number(),
	show_deviation: z.enum(["true", "false"]),
	show_average_result: z.enum(["true", "false"]),
	show_number_of_inputs: z.enum(["true", "false"]),
	io_context: z.nativeEnum(IOContext),
});

const TechstormXYOutputScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("techstorm-xy-output"),
	valuelist_id: titleContentArrayOfType("multiple-choice"),
	background_media: z.string(),
	ios: z.object({
		selections: IO,
		x_axis: IO,
		read_ios: z.array(z.string().uuid()),
	}),
});

const VideoPlayerScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("video-player"),
	header: TextId,
	body: TextId,
	sign_text: TextId,
	video: z.string(),
	poster_frame: z.string(),
	native: z.enum(["yes", "no"]).optional(),
	size: z.enum(["keep ratio", "crop"]),
	autoplay: z.enum(["no", "once", "loop"]),
	controls: z.enum(["yes", "no"]),
	transcribe: z.enum(["yes", "no"]),
	show_next_button: z.enum(["always", "on-video-end"]),
	muted: z.enum(["yes", "no"]),
	show_progress_bar: z.enum(["on-hover", "always"]),
	cuepoints_scalable: z.enum(["yes", "no"]),
	reveal_next_block_event: z.enum(["none", "on-done"]),
	max_height: z.number(),
	sign_background_color: Color,
	sign_text_color: Color,
	border_color: Color,
	sign_show_time: z.number(),
	sign_hide_time: z.number(),
	cuepoint_header_color: Color,
	cuepoint_body_color: Color,
	cuepoint_text_background_color: Color,
	cuepoint_text_background_opacity: z.number(),
	content_array: z.array(
		z.object({
			body_column_1: TextId,
			body_column_2: TextId,
			header: TextId,
			id: z.string().uuid(),
			in_timeout_time_secxy_percent: z
				.string()
				.refine(
					(val) => val.split(",").length === 4 || val.split(",").length === 7
				),
			media: z.string(),
		})
	),
});

const VideoConversationScrollBlock = ScrollBlockBase.extend({
	symbol_name: z.literal("video-conversation"),
	question: TextId,
	header: TextId,
	body: TextId,
	video: z.string(),
	poster_frame: z.string(),
	native: z.enum(["yes", "no"]).optional(),
	transcribe: z.enum(["yes", "no"]),
	resetable: z.enum(["yes", "no"]),
	mode: z.enum(["standard", "emphasized"]),
	show_next_button: z.enum(["always", "auto"]),
	reveal_next_block_event: z.enum(["none", "on-done"]),
	question_background_color: Color,
	option_background_color: Color,
	body_text_color: Color,
	question_text_color: Color,
	option_text_color: Color,
	header_text_color: Color,
	border_color: Color,

	content_array: z.array(
		z.object({
			id: z.string().uuid(),
			option: TextId,
			correct: z.boolean(),
			video: z.string(),
		})
	),
});

const ActionCreateSlideBlock = SlideBlockBase.extend({
	symbol_name: z.literal("slide-io-action-create"),
	show_menu_button: z.enum(["yes", "no"]),
	show_navigation: z.enum([
		"always",
		"after-timer-end",
		"after-timer-reminder",
		"after-half-time",
		"after-1-action",
		"after-2-actions",
		"after-4-actions",
		"after-6-actions",
		"after-8-actions",
	]),
	show_proposal: z.enum(["yes", "no"]),
	show_description: z.enum(["yes", "no"]),
	show_responsible: z.enum(["yes", "no"]),
	show_priority: z.enum(["yes", "no"]),
	show_due_date: z.enum(["yes", "no"]),
	timer_format: TimerFormat,

	background_color: Color,
	text_color: Color,
	action_background_color: Color,

	timer_duration: z.number().min(0),
	timer_reminder: z.number(),
	timer_reminder_duration: z.string(),
	timer_reminder_message: TextId,
	timer_id: z.string().uuid().optional(),
	header: TextId,
	sub_header: TextId,
	instruction: TextId,
	instruction_button_label: TextId,
	lightbox_body: TextId,

	background_media: z.string(),

	io_id: z.string().uuid(),
});

const DilemmaInputSlideBlock = SlideBlockBase.extend({
	symbol_name: z.literal("slide-io-dilemma-input-v2"),

	show_menu_button: z.enum(["yes", "no"]),
	show_navigation: z.enum([
		"always",
		"after-first-option",
		"after-correct-option",
		"after-all-options",
		"after-timer-end",
		"after-timer-reminder",
	]),

	background_color: Color,
	text_color: Color,

	point_categories: TextId,
	point_multiplier_per_attempt: z.string(),
	graphics_correct: z.string(),
	graphics_incorrect: z.string(),
	graphics_neutral: z.string(),

	timer_duration: z.number().min(0),
	timer_reminder: z.number(),
	timer_reminder_duration: z.string(),
	timer_reminder_message: TextId,
	timer_id: z.string().uuid().optional(),
	timer_format: TimerFormat,

	header: TextId,
	sub_header: TextId,
	instruction: TextId,
	instruction_button_label: TextId,

	valuelist_id: titleContentArrayOfType("dilemma"),
	ios: z.object({
		answers: IO,
	}),
});

const MultipleChoiceInputSlideBlock = SlideBlockBase.extend({
	symbol_name: z.literal("slide-io-multiple-choice-input-v2"),
	show_menu_button: z.enum(["yes", "no"]),
	show_navigation: z.enum([
		"always",
		"after_timer_end",
		"after-timer-reminder",
		"after-half-time",
		"auto",
	]),
	timer_format: TimerFormat,
	option_layout: z.enum(["1x2", "1x3", "2x2", "2x3", "2x5", "6x8"]),
	option_identifier: z.enum(["123", "abc", "none"]),
	option_text_align: z.enum(["top-left", "bottom-left", "bottom-center"]),
	checkmark: z.enum(["frame", "check"]),
	background_color: Color,
	option_text_color: Color,
	option_background_color: Color,
	timer_duration: z.number().min(0),
	timer_reminder: z.number(),
	timer_reminder_duration: z.number(),
	timer_reminder_message: TextId,
	header: TextId,
	sub_header: TextId,
	instruction: TextId,
	text_color: Color,
	max_selections: z.number(),
	min_selections: z.number(),
	valuelist_id: titleContentArrayOfType("multiple-choice"),
	timer_id: z.string().uuid().optional(),
	ios: z.object({
		current_selection: IO,
		selection_basis: IO.optional(),
	}),
});

const MultipleChoiceOutputSlideBlock = SlideBlockBase.extend({
	symbol_name: z.literal("slide-io-multiple-choice-output-v3"),
	show_navigation: z.enum([
		"always",
		"after_timer_end",
		"after-timer-reminder",
		"after-half-time",
		"auto",
	]),
	background_media: z.string(),
	timer_format: TimerFormat,
	list_layout: z.enum(["auto", "2x2", "3x4", "3x2"]),
	chart_type: z.enum(["pie", "bar", "list with percent", "list"]),
	io_item_order: z.enum(["original", "descending", "ascending"]),
	filter: z.enum(["show all options", "hide unselected options"]),

	base_color: Color,
	show_top_items: z.number(),

	timer_duration: z.number().min(0),
	timer_reminder: z.number(),
	timer_reminder_duration: z.number(),
	timer_reminder_message: TextId,

	grid_line_thickness: z.number(),
	grid_line_color: Color,

	header: TextId,
	sub_header: TextId,
	instruction: TextId,

	valuelist_id: titleContentArrayOfType("multiple-choice"),
	timer_id: z.string().uuid().optional(),
	ios: z.object({
		selections: IO,
	}),
});

const CommentInputSingleSlideBlock = SlideBlockBase.extend({
	symbol_name: z.literal("slide-io-comment-input-single"),
	show_menu_button: z.enum(["yes", "no"]),
	show_navigation: z.enum([
		"always",
		"after-timer-end",
		"after-timer-reminder",
		"after-half-time",
		"auto",
	]),

	timer_format: TimerFormat,
	background_color: Color,
	timer_duration: z.number(),
	timer_reminder: z.number(),
	timer_reminder_duration: z.number(),
	timer_reminder_message: TextId,
	text_color: Color,
	timer_id: z.string().uuid().optional(),

	header: TextId,
	sub_header: TextId,
	instruction: TextId,
	instruction_button_label: TextId,

	header_label: TextId,
	body_label: TextId,

	max_char_input_header: z.number(),
	max_char_input_body: z.number(),

	rows_input_header: z.number(),
	rows_input_body: z.number(),

	input_text_color: Color,
	input_background_color: Color,

	ios: z.object({
		comments: IO,
	}),
});

const DilemmaOutputSlideBlock = SlideBlockBase.extend({
	symbol_name: z.literal("slide-io-dilemma-output-v2"),
	chart_type: z.enum(["pie", "bar", "list with percent", "list"]),
	io_item_order: z.enum(["original", "descending", "ascending"]),
	list_layout: z.enum(["auto", "2x2", "3x4", "3x2"]),
	show_navigation: z.enum([
		"always",
		"after-half-time",
		"after-timer-reminder",
		"after-timer-ended",
	]),
	header: TextId,
	sub_header: TextId,
	instruction: TextId,
	timer_format: TimerFormat,
	base_color: Color,
	timer_duration: z.number(),
	timer_reminder_duration: z.number(),
	timer_reminder_message: z.string().uuid(),
	show_top_items: z.number().optional(),
	grid_line_thickness: z.number().optional(),
	grid_line_color: Color,
	timer_id: z.string().uuid().optional(),

	valuelist_id: titleContentArrayOfType("dilemma"),
	ios: z.object({
		answers: IO,
	}),
});

const NoteInputSlideBlock = SlideBlockBase.extend({
	symbol_name: z.literal("slide-io-note-input"),
	show_menu_button: z.enum(["yes", "no"]),
	show_navigation: z.enum([
		"always",
		"after-timer-end",
		"after-timer-reminder",
		"after-half-time",
		"auto",
	]),
	view: z.enum(["grid", "draggable"]),
	timer_format: TimerFormat,

	background_color: Color,
	timer_duration: z.number(),
	timer_reminder: z.number(),
	timer_reminder_duration: z.number(),
	timer_reminder_message: TextId,
	text_color: Color,
	timer_id: z.string().uuid().optional(),
	note_text_color: Color,
	note_background_color: Color,

	header: TextId,
	sub_header: TextId,
	instruction: TextId,
	instruction_button_label: TextId,

	max_notes: z.number(),
	min_notes: z.number(),

	target_index: z.number().min(1),

	ios: z.object({
		target: IO.optional(),
		notes: IO,
	}),
});

const NoteOutputSlideBlock = SlideBlockBase.extend({
	symbol_name: z.literal("slide-io-note-output"),
	show_menu_button: z.enum(["yes", "no"]),
	show_navigation: z.enum([
		"always",
		"after-timer-end",
		"after-timer-reminder",
		"after-half-time",
	]),
	timer_format: TimerFormat,

	background_color: Color,
	timer_duration: z.number(),
	timer_reminder: z.number(),
	timer_reminder_duration: z.number(),
	timer_reminder_message: TextId,
	text_color: Color,
	timer_id: z.string().uuid().optional(),
	note_text_color: Color,
	note_background_color: Color,

	header: TextId,
	sub_header: TextId,
	instruction: TextId,
	instruction_button_label: TextId,

	ios: z.object({
		notes: IO,
	}),
});

const NoteCategorizeSlideBlock = SlideBlockBase.extend({
	symbol_name: z.literal("slide-io-note-categorize"),

	show_navigation: z.enum([
		"always",
		"after-timer-end",
		"after-timer-reminder",
		"after-half-time",
		"after-one-post-it-categorized",
		"after-all-post-its-categorized",
	]),

	background_color: Color,
	background_media: z.string(),
	category_overlay_media: z.string(),
	timer_format: TimerFormat,
	timer_duration: z.number(),
	timer_reminder: z.number(),
	timer_reminder_duration: z.number(),
	timer_reminder_message: TextId,
	text_color: Color,
	timer_id: z.string().uuid().optional(),
	note_text_color: Color,
	note_background_color: Color,

	header: TextId,
	sub_header: TextId,
	instruction: TextId,
	instruction_button_label: TextId,
	lightbox_body: TextId,

	ios: z.object({
		notes_to_categorize: IO,
	}),

	valuelist_id: titleContentArrayOfType("category"),
});

const RateOutputSlideBlock = SlideBlockBase.extend({
	symbol_name: z.literal("slide-io-rate-output-v2"),

	show_navigation: z.enum([
		"always",
		"after-timer-end",
		"after-timer-reminder",
		"after-half-time",
	]),

	background_color: Color,
	background_media: z.string(),
	badge_tone: z.enum(["auto", "info"]),
	badge_style: z.enum(["badge", "text"]),
	timer_format: TimerFormat,
	timer_duration: z.number(),
	timer_reminder: z.number(),
	timer_reminder_duration: z.number(),
	timer_reminder_message: TextId,
	text_color: Color,
	result_color: Color,
	variant: z.enum(["slider", "bar chart"]),
	timer_id: z.string().uuid().optional(),
	header: TextId,
	sub_header: TextId,
	instruction: TextId,
	instruction_button_label: TextId,
	variation_threshold_high: z.number(),
	variation_threshold_medium: z.number(),
	row_marker: z.enum(["number", "letter", "none"]),
	show_max_number_of_rates: z.number(),
	show_deviation_badge: z.enum(["yes", "no"]),
	io_context: z.nativeEnum(IOContext),

	sort_by: z.enum([
		"Average value ascending",
		"Average value descending",
		"Median value ascending",
		"Median value descending",
		"Deviation ascending",
		"Deviation descending",
		"none",
	]),

	ios: z.object({
		io_group_for_multiple_rate_values: IOGroup,
		read_ios: z.array(z.string().uuid()).optional(),
	}),

	valuelist_id: titleContentArrayOfType("rate"),
});

const VideoPlayerSlideBlock = SlideBlockBase.extend({
	symbol_name: z.literal("slide-video-player"),
	auto_navigate: z.enum(["video end", "off"]),
	sign_text: TextId,
	logo: z.enum(["yes", "no"]),
	show_menu_button: z.enum(["yes", "no"]),
	show_navigation: z.enum(["yes", "no"]),
	autoplay: z.enum(["no", "once", "loop"]),
	muted: z.enum(["yes", "no"]),
	cuepoint_header_align: z.enum(["center", "left", "right"]),
	cuepoint_body_align: z.enum(["center", "left", "right"]),
	cuepoint_header_size: z.enum(["xs", "sm", "md", "lg", "xl", "xxl"]),
	cuepoint_body_size: z.enum(["xs", "sm", "md", "lg", "xl", "xxl"]),
	cuepoint_header_color: Color,
	cuepoint_body_color: Color,
	cuepoint_text_background_color: Color,
	cuepoint_text_background_opacity: z.number().min(0).max(1),
	poster_frame: z.string(),
	sign_background_color: Color,
	sign_text_color: Color,
	sign_show_time: z.number(),
	sign_hide_time: z.number(),
	video: z.string(),
	content_array: z.array(
		z.object({
			body_column_1: TextId,
			body_column_2: TextId,
			header: TextId,
			id: z.string().uuid(),
			in_timeout_time_secxy_percent: z
				.string()
				.refine(
					(val) => val.split(",").length === 4 || val.split(",").length === 7
				),
			media: z.string(),
		})
	),
});

const VideoConversationSlideBlock = SlideBlockBase.extend({
	symbol_name: z.literal("slide-video-conversation_v2"),
	poster_frame: z.string(),
	autoplay: z.enum(["yes", "no"]),
	option_background_color: z.string(),
	option_text_color: z.string(),
	question_text_color: z.string(),
	question_background_color: z.string(),
	options_background_media: z.string(),
	question: TextId,
	mode: z.enum(["choice", "explore"]),
	auto_navigate: z.enum(["video end", "off"]),
	video: z.string(),
	// TODO: remove, use title content array
	content_array: z.array(
		z.object({
			video: z.string(),
		})
	),
	ios: z.object({
		current_selection: IO,
	}),
});

const FATBlock = z.discriminatedUnion("symbol_name", [
	// Scroll blocks
	ActivityLinkScrollBlock,
	ArticleScrollBlock,
	CommentInputScrollBlock,
	CommentOutputScrollBlock,
	DilemmaInputScrollBlock,
	KnowledgeQuizScrollBlock,
	LanguageSelectScrollBlock,
	LinksScrollBlock,
	MediaUploadScrollBlock,
	MultipleChoiceInputScrollBlock,
	MultipleChoiceOutputScrollBlock,
	NoteCategorizeScrollBlock,
	ParticipantsScrollBlock,
	PostItCategorizeScrollBlock,
	RateInputScrollBlock,
	RateOutputScrollBlock,
	TechstormXYOutputScrollBlock,
	VideoConversationScrollBlock,
	VideoPlayerScrollBlock,

	// Slide blocks
	ActionCreateSlideBlock,
	CommentInputSingleSlideBlock,
	DilemmaInputSlideBlock,
	DilemmaOutputSlideBlock,
	MultipleChoiceInputSlideBlock,
	MultipleChoiceOutputSlideBlock,
	NoteCategorizeSlideBlock,
	NoteInputSlideBlock,
	NoteOutputSlideBlock,
	RateOutputSlideBlock,
	VideoPlayerSlideBlock,
	VideoConversationSlideBlock,

	// Scroll stub blocks
	ScrollBlockBase.extend({ symbol_name: z.literal("reveal") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("page-header") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("chapter-button") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("page-button") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("page-footer") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("top-bar") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("side-menu") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("image") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("article-compact") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("conversation") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("multiple-choice") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("multiple-choice-reflect") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("course-status") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("print-button") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("embedded-video") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("linked-block") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("match-pairs") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("read-more") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("function-block") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("info-box") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("table") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("section-divider") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("quote") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("bullets") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("hotspots") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("navigation-buttons") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("io-post-it-create") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("post-it-create") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("io-post-it-input") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("io-text") }),
	ScrollBlockBase.extend({
		symbol_name: z.literal("io-action-priority-matrix-input"),
	}),
	ScrollBlockBase.extend({ symbol_name: z.literal("io-lists-input") }),
	ScrollBlockBase.extend({
		symbol_name: z.literal("io-multiple-choice-input"),
	}),
	ScrollBlockBase.extend({
		symbol_name: z.literal("io-multiple-choice-input-v2"),
	}),
	ScrollBlockBase.extend({
		symbol_name: z.literal("io-multiple-choice-output"),
	}),
	ScrollBlockBase.extend({ symbol_name: z.literal("io-sprig-story") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("quiddityPostIts") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("io-quiz-input") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("io-value-list-selector") }),
	ScrollBlockBase.extend({
		symbol_name: z.literal("io-value-list-selector-input"),
	}),
	ScrollBlockBase.extend({ symbol_name: z.literal("sound-player") }),
	ScrollBlockBase.extend({
		symbol_name: z.literal("io-post-it-categorize-output"),
	}),
	ScrollBlockBase.extend({ symbol_name: z.literal("io-post-it-output") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("io-action-output") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("io-lists-output") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("io-comment-select-input") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("io-media-upload-output") }),
	ScrollBlockBase.extend({
		symbol_name: z.literal("io-sprig-story-trait-selection"),
	}),
	ScrollBlockBase.extend({ symbol_name: z.literal("fact-box") }),
	ScrollBlockBase.extend({ symbol_name: z.literal("svg-dynamic-text") }),

	// Slide stub blocks
	SlideBlockBase.extend({ symbol_name: z.literal("slide-universal") }),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-activity-intro") }),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-discussion") }),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-question-intro") }),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-io-dilemma-input") }),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-io-comment-output") }),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-io-comment-input") }),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-io-post-it-create") }),
	SlideBlockBase.extend({
		symbol_name: z.literal("slide-io-post-it-categorize"),
	}),
	SlideBlockBase.extend({
		symbol_name: z.literal("slide-io-post-it-categorize-v2"),
	}),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-io-post-it-select") }),
	SlideBlockBase.extend({
		symbol_name: z.literal("slide-io-post-it-select-output"),
	}),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-io-lists-input") }),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-video-conversation") }),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-linked-block") }),
	SlideBlockBase.extend({
		symbol_name: z.literal("slide-io-post-it-categorize-result"),
	}),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-io-action-result") }),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-io-media-upload") }),
	SlideBlockBase.extend({
		symbol_name: z.literal("slide-io-value-list-output"),
	}),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-io-rate-output") }),
	SlideBlockBase.extend({
		symbol_name: z.literal("slide-io-multiple-choice-input"),
	}),
	SlideBlockBase.extend({
		symbol_name: z.literal("slide-io-multiple-choice-output"),
	}),
	SlideBlockBase.extend({
		symbol_name: z.literal("slide-io-item-position-input"),
	}),
	SlideBlockBase.extend({ symbol_name: z.literal("slide-io-matrix-input") }),
	SlideBlockBase.extend({
		symbol_name: z.literal("slide-io-matrix-xy-output"),
	}),
]);

type FATBlock = z.infer<typeof FATBlock>;
type IO = z.infer<typeof IO>;

export const validatePageItems = (pageItems: any[]) => {
	return z.array(FATBlock).parse(pageItems);
};

type ActivityLinkScrollBlock = z.infer<typeof ActivityLinkScrollBlock>;
type ArticleScrollBlock = z.infer<typeof ArticleScrollBlock>;
type CommentInputScrollBlock = z.infer<typeof CommentInputScrollBlock>;
type CommentOutputScrollBlock = z.infer<typeof CommentOutputScrollBlock>;
type DilemmaInputScrollBlock = z.infer<typeof DilemmaInputScrollBlock>;
type KnowledgeQuizScrollBlock = z.infer<typeof KnowledgeQuizScrollBlock>;
type LanguageSelectScrollBlock = z.infer<typeof LanguageSelectScrollBlock>;
type LinksScrollBlock = z.infer<typeof LinksScrollBlock>;
type MediaUploadScrollBlock = z.infer<typeof MediaUploadScrollBlock>;
type MultipleChoiceInputScrollBlock = z.infer<
	typeof MultipleChoiceInputScrollBlock
>;
type MultipleChoiceOutputScrollBlock = z.infer<
	typeof MultipleChoiceOutputScrollBlock
>;
type NoteCategorizeScrollBlock = z.infer<typeof NoteCategorizeScrollBlock>;
type ParticipantsScrollBlock = z.infer<typeof ParticipantsScrollBlock>;
type PostItCategorizeScrollBlock = z.infer<typeof PostItCategorizeScrollBlock>;
type RateInputScrollBlock = z.infer<typeof RateInputScrollBlock>;
type RateOutputScrollBlock = z.infer<typeof RateOutputScrollBlock>;
type VideoPlayerScrollBlock = z.infer<typeof VideoPlayerScrollBlock>;
type VideoConversationScrollBlock = z.infer<
	typeof VideoConversationScrollBlock
>;
type ActionCreateSlideBlock = z.infer<typeof ActionCreateSlideBlock>;
type CommentInputSingleSlideBlock = z.infer<
	typeof CommentInputSingleSlideBlock
>;
type DilemmaInputSlideBlock = z.infer<typeof DilemmaInputSlideBlock>;
type DilemmaOutputSlideBlock = z.infer<typeof DilemmaOutputSlideBlock>;
type MultipleChoiceInputSlideBlock = z.infer<
	typeof MultipleChoiceInputSlideBlock
>;
type MultipleChoiceOutputSlideBlock = z.infer<
	typeof MultipleChoiceOutputSlideBlock
>;
type NoteCategorizeSlideBlock = z.infer<typeof NoteCategorizeSlideBlock>;
type NoteInputSlideBlock = z.infer<typeof NoteInputSlideBlock>;
type NoteOutputSlideBlock = z.infer<typeof NoteOutputSlideBlock>;
type RateOutputSlideBlock = z.infer<typeof RateOutputSlideBlock>;
type TechstormXYOutputScrollBlock = z.infer<
	typeof TechstormXYOutputScrollBlock
>;
type VideoPlayerSlideBlock = z.infer<typeof VideoPlayerSlideBlock>;
type VideoConversationSlideBlock = z.infer<typeof VideoConversationSlideBlock>;

export type {
	FATBlock,
	IO,
	ActivityLinkScrollBlock,
	ArticleScrollBlock,
	CommentInputScrollBlock,
	CommentOutputScrollBlock,
	DilemmaInputScrollBlock,
	KnowledgeQuizScrollBlock,
	LanguageSelectScrollBlock,
	LinksScrollBlock,
	MediaUploadScrollBlock,
	MultipleChoiceInputScrollBlock,
	MultipleChoiceOutputScrollBlock,
	NoteCategorizeScrollBlock,
	ParticipantsScrollBlock,
	PostItCategorizeScrollBlock,
	RateInputScrollBlock,
	RateOutputScrollBlock,
	VideoPlayerScrollBlock,
	VideoConversationScrollBlock,
	VideoPlayerSlideBlock,
	VideoConversationSlideBlock,
	ActionCreateSlideBlock,
	DilemmaInputSlideBlock,
	DilemmaOutputSlideBlock,
	MultipleChoiceInputSlideBlock,
	MultipleChoiceOutputSlideBlock,
	NoteCategorizeSlideBlock,
	NoteInputSlideBlock,
	NoteOutputSlideBlock,
	RateOutputSlideBlock,
	TechstormXYOutputScrollBlock,
	CommentInputSingleSlideBlock,
};
