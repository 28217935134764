import _ from "lodash";
import { getReducer } from "../utils/StoreUtils";
import { parseCMTPages } from "./parsers/parseCMTPages";
import { parseCMTPageItems } from "./parsers/parseCMTPageItems";
import { parseCMTChapters } from "./parsers/parseCMTChapters";
import { parseCMTSettings } from "./parsers/parseCMTSettings";
import { removeTexts } from "../redux/actions/TextActions";
import { updatePageItems } from "../redux/actions/PageItemActions";
import { updatePages } from "../redux/actions/PageActions";
import { sortItemsByKey } from "../utils/ArrayUtils";
import {
	defaultSchemes,
	defaultPages,
	workshopPages,
	defaultPageItems,
	defaultSymbols,
} from "./CMTConstants";
import { updateConfig } from "../redux/actions/ConfigActions";

export const parse = ({
	cmtParseSchemes,
	cmtPages = [],
	cmtPageItems = [],
	cmtTexts = [],
	projectActions,
	projectTexts,
	disable = false,
	template = false,
}) => {
	return new Promise((resolve) => {
		const parsed = {
			pages: [],
			pageItems: [],
			diffPageItemIds: [],
			texts: [],
		};

		if (disable || !cmtParseSchemes) {
			// For CMT productions that not uses the template project with scheme
			parsed.texts = cmtTexts;
			resolve(parsed);
			return;
		} else {
			// Exclude pages and chapter with visible set to "no"
			cmtPages = cmtPages.filter((cmtPage) => cmtPage.visible !== "no");

			if (template) {
				// Add default schemes
				cmtParseSchemes = cmtParseSchemes.concat(defaultSchemes);

				// Update side-menu
				const cmtSettingPages = cmtPages.find(
					(cmtPage) => cmtPage.hierarchy_level === 0
				);

				const sideMenu = defaultSymbols.find(
					(symbol) => symbol.type === "side-menu"
				);
				sideMenu.parentPageId =
					cmtSettingPages.menu_position === "right"
						? "global-right-container"
						: "global-left-container";

				// Add default page items/symbols
				updatePageItems(_.flatten([defaultSymbols, defaultPageItems]));

				const topPage = cmtPages.find((page) => page.client);
				const addPages = [];
				const projectPages = getReducer("pages").items;
				let templatePages = defaultPages.concat();
				if (topPage) {
					// Add workshop pages if Program type is "slide" and backend is "quiddity"
					if (
						cmtSettingPages.program_type === "slide" &&
						cmtSettingPages.backend === "quiddity" &&
						cmtSettingPages.members_panel_skip === "Show if quiddity backend"
					) {
						const pageIntroIndex = defaultPages.findIndex(
							(page) => page.type === "intro"
						);
						// defaultPages.splice(pageIntroIndex + 1, 0, workshopPages);
						templatePages = _.concat(
							defaultPages.slice(0, pageIntroIndex),
							workshopPages,
							defaultPages.slice(pageIntroIndex)
						);
					}

					// Add default pages to top page (course)
					templatePages.forEach((page) => {
						const foundPage = projectPages.find(
							(pojectPage) => pojectPage.friendlyId === page.friendlyId
						);
						// Add custom pages if found else default page from "CMTConstants.js"
						if (foundPage) {
							foundPage.parentPageId = topPage.id;
							addPages.push(foundPage);
						} else {
							page.parentPageId = topPage.id;
							addPages.push(page);
						}
					});
				}

				// Add default pages
				updatePages(addPages);

				// Get installed languages from CMT
				const installedLanguages = (cmtSettingPages.installed_languages || [])
					.split(",")
					.map((str) => str.trim())
					.filter((str) => str !== "");

				// Set backend | language
				parsed.config = {
					backend: cmtSettingPages.backend || "lms",
					language: cmtSettingPages.original_localisation_code,
					installedLanguages,
				};

				// ToDo: Wait for correct implementation in "quiddity"
				if (cmtSettingPages.backend === "quiddity") {
					parsed.config.storage = "quiddity";
				}

				updateConfig(parsed.config);
			}

			// Fix correct page order
			cmtPages = sortItemsByKey(cmtPages, "type");
			cmtPages = sortItemsByKey(cmtPages, "sort_index");

			if (!cmtParseSchemes) {
				console.warn("Parsing CMT can not be done without a parsing schemes!");
				parsed.texts = cmtTexts;
				resolve(parsed);
				return;
			}

			if (!getReducer("pageItems").items.length) {
				throw new Error(
					"Parsing CMT can not be done before pageItems are loaded and updated!"
				);
			}
			// cleanups
			removeTexts(
				projectTexts
					.filter((text) => text.source === "cmt")
					.map((text) => text.id)
			);
		}

		const promise1 = parseCMTPages({
			cmtPages,
			projectActions,
			cmtParseSchemes,
			template,
		}).then((result) => {
			parsed.pages = result.pages;
		});

		const promise2 = parseCMTPageItems({
			cmtParseSchemes,
			cmtPageItems,
			cmtPages,
			cmtTexts,
		}).then((result) => {
			parsed.pageItems = parsed.pageItems.concat(result.pageItems);
		});

		const promise3 = parseCMTChapters(cmtParseSchemes, cmtPages).then(
			(result) => {
				parsed.pageItems = parsed.pageItems.concat(result.pageItems);
			}
		);

		parsed.texts = cmtTexts.map((text) => ({ ...text, source: "cmt" }));
		const promise4 = parseCMTSettings({
			cmtParseSchemes,
			cmtPages,
			texts: parsed.texts,
		});

		Promise.all([promise1, promise2, promise3, promise4]).then((result) => {
			resolve(parsed);
		});
	});
};
