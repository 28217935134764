import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import * as StatusConstants from "../constants/StatusConstants";

import FFButton from "./FFButton";

class FFMultiSelect extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			status: StatusConstants.INCOMPLETE,
		};
	}

	static propTypes = {
		selectedIndexes: PropTypes.arrayOf(PropTypes.number),
		disabledIndexes: PropTypes.arrayOf(PropTypes.number),
		correctIndexes: PropTypes.arrayOf(PropTypes.number),
		minSelections: PropTypes.number,
		maxSelections: PropTypes.number,
		autoCorrect: PropTypes.bool,
		isCorrect: PropTypes.bool,
		toggle: PropTypes.bool,
		disableWhenMaxSelectionsReached: PropTypes.bool,
		disableDeselect: PropTypes.bool,
		autoDeselectWhenMaxSelectionsReached: PropTypes.bool,
		alwaysTriggerOnSelect: PropTypes.bool,
		onSelect: PropTypes.func,
		onComplete: PropTypes.func,
		onIncomplete: PropTypes.func,
		enabled: PropTypes.bool,
	};

	static defaultProps = {
		selectedIndexes: [],
		disabledIndexes: [],
		correctIndexes: [],
		minSelections: 0,
		maxSelections: Number.MAX_VALUE,
		autoCorrect: true,
		isCorrect: false,
		toggle: false,
		disableDeselect: false,
		disableWhenMaxSelectionsReached: true,
		autoDeselectWhenMaxSelectionsReached: false,
		alwaysTriggerOnSelect: false,
		enabled: true,
	};

	componentDidMount() {
		const { selectedIndexes } = this.props;
		const status = this.getStatus(selectedIndexes);
		// setTimeout is here because of the intial status set by "PageItem.jsx"
		this.didMountTimeOut = setTimeout(() => {
			this.setState({ status });
		}, 0);
	}

	componentWillUnmount() {
		clearTimeout(this.didMountTimeOut);
	}

	componentDidUpdate(prevProps, prevState) {
		const { onComplete, onIncomplete, selectedIndexes } = this.props;
		const prevStatus = prevState.status;
		const currStatus = this.state.status;
		if (prevStatus !== currStatus) {
			switch (currStatus) {
				case StatusConstants.COMPLETED:
					onComplete && onComplete(selectedIndexes);
					break;
				case StatusConstants.INCOMPLETE:
					onIncomplete && onIncomplete(selectedIndexes);
					break;
			}
		}

		const prevSelectedIndexes = prevProps.selectedIndexes;
		const currSelectedIndexes = this.props.selectedIndexes;
		if (!_.isEqual(prevSelectedIndexes, currSelectedIndexes)) {
			this.setState({ status: this.getStatus(currSelectedIndexes) });
		}
	}

	_onButtonClick = (index) => {
		const {
			onSelect,
			selectedIndexes,
			maxSelections,
			minSelections,
			toggle,
			autoDeselectWhenMaxSelectionsReached,
			alwaysTriggerOnSelect,
			disableDeselect,
		} = this.props;

		let newSelectedIndexes = [...selectedIndexes];

		if (toggle) {
			newSelectedIndexes = this.handleToggle(index, newSelectedIndexes);
		} else {
			newSelectedIndexes = this.handleDefault(index, newSelectedIndexes);
		}

		if (
			this.isMaxSelectionsReached(newSelectedIndexes) &&
			autoDeselectWhenMaxSelectionsReached
		) {
			newSelectedIndexes.shift();
		}

		if (disableDeselect && newSelectedIndexes.length < selectedIndexes.length) {
			newSelectedIndexes = [...selectedIndexes];
		}

		if (
			alwaysTriggerOnSelect ||
			(!_.isEqual(newSelectedIndexes, selectedIndexes) &&
				!this.isMaxSelectionsReached(newSelectedIndexes))
		) {
			onSelect && onSelect(newSelectedIndexes, index);
			this.setState({ status: this.getStatus(newSelectedIndexes) });
		}
	};

	isMaxSelectionsReached = (selectedIndexes) => {
		const { maxSelections } = this.props;
		return selectedIndexes.length > maxSelections;
	};

	getStatus(selectedIndexes) {
		const { maxSelections, minSelections } = this.props;
		if (
			selectedIndexes.length >= minSelections &&
			selectedIndexes.length <= maxSelections
		) {
			return StatusConstants.COMPLETED;
		} else {
			return StatusConstants.INCOMPLETE;
		}
	}

	handleDefault = (index, selectedIndexes) => {
		if (!selectedIndexes.includes(index)) {
			selectedIndexes.push(index);
		}
		return selectedIndexes;
	};

	handleToggle = (index, selectedIndexes) => {
		const foundAtIndex = selectedIndexes.findIndex(
			(selectedIndex) => selectedIndex === index
		);
		if (foundAtIndex > -1) {
			selectedIndexes.splice(foundAtIndex, 1);
		} else {
			selectedIndexes.push(index);
		}
		return selectedIndexes;
	};

	render() {
		const {
			style,
			className,
			buttonClassName,
			children,
			selectedIndexes,
			autoCorrect,
			isCorrect,
			correctIndexes,
			maxSelections,
			disableWhenMaxSelectionsReached,
			disabledIndexes,
			enabled,
			rest,
		} = this.props;

		const childItems = Array.isArray(children) ? children : [];
		return (
			<div
				style={{ ...style }}
				className={classnames(
					className,
					"ff-multi-select",
					autoCorrect ? "auto-correct" : "",
					{
						correct: autoCorrect && isCorrect,
						incorrect: autoCorrect && !isCorrect,
					}
				)}
			>
				{childItems.map((child, index) => {
					const selected = selectedIndexes.includes(index);
					const correct = correctIndexes.includes(index);
					const disabled =
						disabledIndexes.includes(index) ||
						(disableWhenMaxSelectionsReached &&
							selectedIndexes.length >= maxSelections &&
							!selected);
					return (
						<FFButton
							key={`multi-select-key-${index}`}
							className={classnames(
								`multi-select-button`,
								buttonClassName,
								`index-${index}`,
								selected ? "selected" : "unselected",
								correct ? "correct" : "incorrect"
							)}
							enabled={enabled && !disabled}
							{...rest}
							onClick={() => {
								this._onButtonClick(index);
							}}
						>
							{child}
						</FFButton>
					);
				})}
			</div>
		);
	}
}
export default FFMultiSelect;
