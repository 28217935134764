import { useState, useEffect } from "react";
import _ from "lodash";

export const useWindowResize = () => {
	const [size, setSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const onResizeHandler = () => {
		setSize({ width: window.innerWidth, height: window.innerHeight });
	};

	const debouncedResizeHandler = _.debounce(onResizeHandler, 50);

	useEffect(() => {
		window.addEventListener("resize", debouncedResizeHandler);
		return () => {
			window.removeEventListener("resize", debouncedResizeHandler);
		};
	});

	return size;
};
