import React from "react";
import PropTypes from "prop-types";
import Layer from "./Layer";

class Layers extends React.Component {
	static propTypes = {
		page: PropTypes.object.isRequired,
		locationPath: PropTypes.array.isRequired,
	};

	render() {
		const { page, location, locationPath } = this.props;
		return (
			<React.Fragment>
				{page.layers.map((layer) => (
					<Layer
						key={`layer-${layer}-${page.id}`}
						id={layer}
						page={page}
						location={location}
						locationPath={locationPath}
					/>
				))}
			</React.Fragment>
		);
	}
}

export default Layers;
