import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";

const styles = {
	base: {
		WebkitUserSelect: "none",
		msUserSelect: "none",
		MozUserSelect: "none",
		userSelect: "none",
	},

	enabled: {
		cursor: "pointer",
		pointerEvents: "auto",
	},

	disabled: {
		cursor: "auto",
		pointerEvents: "none",
	},
};

const FFButton = (props) => {
	const {
		className,
		enabled,
		style,
		onMouseEnter,
		onMouseLeave,
		children,
		stopPropagation,
		tagName: CustomTag = "div",
		rest,
		ref,
	} = props;

	let debounceAndProgagationHandledOnClick = (e) => {
		const { stopPropagation, onClick } = props;

		if (stopPropagation) {
			e.stopPropagation();
		}
		onClick && onClick(e);
	};

	const [hover, setHover] = useState(false);

	useEffect(() => {
		const { allowDoubleClicks } = props;
		debounceAndProgagationHandledOnClick = allowDoubleClicks
			? debounceAndProgagationHandledOnClick
			: _.debounce(debounceAndProgagationHandledOnClick, 400, {
					leading: true,
					trailing: false,
			  });

		return () => {
			debounceAndProgagationHandledOnClick.cancel();
		};
	}, []);

	return (
		<CustomTag
			disabled={!enabled}
			className={classnames(
				"ff-button",
				className,
				enabled ? " enabled" : " disabled"
			)}
			style={Object.assign(
				{},
				styles.base,
				style,
				enabled ? styles.enabled : styles.disabled
			)}
			onClick={debounceAndProgagationHandledOnClick}
			onMouseOver={() => {
				enabled && setHover(true);
			}}
			onMouseEnter={(e) => {
				if (stopPropagation) {
					e.stopPropagation();
				}
				onMouseEnter && onMouseEnter();
			}}
			onMouseLeave={(e) => {
				if (stopPropagation) {
					e.stopPropagation();
				}
				onMouseLeave && onMouseLeave();
				enabled && setHover(false);
			}}
			{...rest}
			ref={ref}
		>
			{typeof children === "function"
				? children({ props, styles, hover })
				: children}
		</CustomTag>
	);
};

FFButton.defaultProps = {
	enabled: true,
	allowDoubleClicks: false,
	stopPropagation: false,
};

FFButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	enabled: PropTypes.bool,
	stopPropagation: PropTypes.bool,
	allowDoubleClicks: PropTypes.bool,
};

export default FFButton;
