import _ from "lodash";

const schemePageItems = {
	"video-player": {
		show_next_button: { auto: "on-video-end" },
		border_color: "frame_color",
	},
};

const schemePages = {
	app: {
		main_menu_num_page_button_columns: "number_of_menu_columns", // Not changed in CMT
		main_menu_reveal_page_buttons: "reveal_menu_chapters", // Not changed in CMT
	}, // the first item in the cmt_pages json array is converted to app reducer props.
	chapter: { main_menu_image: "menu" },
	page: {
		main_menu_image: "menu_image",
		page_type: (item) => screenOrPrintConversion(item),
	},
};

export const compabilityParsePages = (cmtPages) => {
	return compabilityParseItem(cmtPages, schemePages, true);
};

export const compabilityParsePageItems = (cmtPageItems) => {
	return compabilityParseItem(cmtPageItems, schemePageItems);
};

const compabilityParseItem = (items, schemeItems, parseConfig = false) => {
	return items.map((item) => {
		const scheme =
			schemeItems[item.type] ||
			schemeItems[item.symbol_name] ||
			(parseConfig && schemeItems.app);
		if (scheme) {
			const parsedItem = {};
			_.forIn(scheme, (value, key) => {
				// don´t overwrite existing value if set
				if (item[value] === undefined) {
					if (typeof value === "function") {
						value(item);
					} else if (typeof value === "object") {
						_.forIn(value, (v, k) => {
							if (item[key] === k) {
								if (typeof v === "function") {
									item[key] = v(item);
								} else {
									item[key] = v;
								}
								// console.log(`Value "${k}" changed to "${item[key]}" for attribute/toggel "${key}"`);
							}
						});
					} else {
						// don´t overwrite existing value if set
						if (item[key] === undefined) {
							parsedItem[value] = item[key];
							// console.log(
							//   `Attribute "${value}" with value "${parsedItem[value]}" added for back compability with attribute "${key}"`
							// );
						}
					}
				}
			});
			return { ...item, ...parsedItem };
		} else {
			return item;
		}
	});
};

// Conversion functions
const screenOrPrintConversion = (item) => {
	item.page_type =
		(item.screen_or_print === "Print" && "print") ||
		(item.screen_or_print === "Screen" && "default") ||
		item.page_type;
	item.screen_or_print =
		(item.page_type === "default" && "Screen") ||
		(item.page_type === "print" && "Print") ||
		"";
};
