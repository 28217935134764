import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import { AudioUtils, PageItemActions } from "funkis-foundation";

const PageItemSoundPlayer = (props) => {
	const cmtPageItem = _.get(props, "pageItem.cmtPageItem", {});
	const sound = cmtPageItem.sound;
	const soundId = cmtPageItem.id;
	const loop = cmtPageItem.loop === "yes";
	const volume = cmtPageItem.hasOwnProperty("volume")
		? cmtPageItem.volume / 100
		: 1;
	const playOffScreen = cmtPageItem.play_off_screen === "yes";
	const playOnlyOnce = cmtPageItem.play_only_once === "yes";
	const positionYOffset = cmtPageItem.position_y_offset || 0;

	const [viewVisible, setViewVisible] = useState();
	const [soundInstance, setSoundInstance] = useState(
		AudioUtils.get(soundId) || false
	);
	const [hasTriggeredPlayThisViewCycle, setHasTriggeredPlayThisViewCycle] =
		useState(false);

	const { status, id } = props.pageItem;
	const hasPlayedAtLeastOnce = status === "completed";

	const checkIsAllowedToPlay = useCallback(() => {
		// console.log("inside checkIsAllowedToPlay", {
		//   playOnlyOnce, hasPlayedAtLeastOnce, playOffScreen, viewVisible, soundInstance
		// });

		const isBlockedByAlreadyHasPlayed = !loop && hasTriggeredPlayThisViewCycle;
		if (isBlockedByAlreadyHasPlayed) {
			// console.log("Is blocked by already having triggered playing of sound " + soundId);
			return false;
		}

		const isBlockedByAlreadyPlaying = soundInstance && soundInstance.playing();
		if (isBlockedByAlreadyPlaying) {
			// console.log("Is blocked by already playing sound " + soundId);
			return false;
		}

		const isBlockedByPlayLimit = playOnlyOnce && hasPlayedAtLeastOnce;
		if (isBlockedByPlayLimit) {
			// console.log(`Sound "${sound}" blocked. Has setting play_only_once === yes and pageItem.status === completed`);
			return false;
		}

		const isBlockedByViewStatus = !playOffScreen && !viewVisible;
		// only ok to play when view visible
		if (isBlockedByViewStatus) {
			return false;
		}

		// playing is not blocked by anything
		return true;
	}, [
		playOnlyOnce,
		hasPlayedAtLeastOnce,
		playOffScreen,
		viewVisible,
		soundInstance,
		loop,
		hasTriggeredPlayThisViewCycle,
	]);

	const setPageItemStatusCompleted = () =>
		PageItemActions.updatePageItem(id, { status: "completed" });

	const setCompletedAfterPlayed = () => {
		const audioInstance = AudioUtils.get(soundId);
		if (!audioInstance) return;
		audioInstance.once("end", () => {
			// console.log(`Setting page item status to completed as sound "${soundId}" reached the end`);
			setPageItemStatusCompleted();
		});
	};

	useEffect(() => {
		// Load sounds
		AudioUtils.set(soundId, {
			src: `./content/sounds/${sound}`,
			volume: playOffScreen ? volume : 0,
			loop,
		});
		const soundInstance = AudioUtils.get(soundId);
		setSoundInstance(soundInstance);

		return () => {
			AudioUtils.stop(soundId);
		};
	}, []);

	useEffect(() => {
		// console.log("checkIsAllowedToPlay");
		if (checkIsAllowedToPlay()) {
			AudioUtils.play(soundId);
			setHasTriggeredPlayThisViewCycle(true);
			setCompletedAfterPlayed();
		}
	}, [checkIsAllowedToPlay]);

	useEffect(() => {
		if (!playOffScreen) {
			if (viewVisible) {
				//AudioUtils.play(soundId);
				AudioUtils.fade(soundId, { from: 0, to: volume });
				// setCompletedAfterPlayed();
			} else {
				AudioUtils.fade(soundId, { from: volume, to: 0 });
				setHasTriggeredPlayThisViewCycle(false);
			}
		}
	}, [viewVisible, playOffScreen]);

	// when offsetting, we need to hide the divider, or else it will show up in a weird position
	const showDivider = positionYOffset === 0;
	return (
		<PageItemScrollBase
			{...props}
			showDivider={showDivider}
			revealNextBlock={true}
			rootStyle={{ height: 0, position: "relative", top: positionYOffset }}
			renderFunction={(renderProps) => {
				setViewVisible(renderProps.viewPartial);
				return <div />;
			}}
		/>
	);
};

export default PageItemSoundPlayer;
