import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	addItemInStateItems,
	removeItemInStateItems,
	removeItemsInStateItems,
	changeIndexForItemInStateItems,
	makeStateSnapshot,
	restoreStateSnapshot,
} from "../utils/reducerUtils";

import {
	UPDATE_PAGES,
	UPDATE_PAGE,
	REMOVE_PAGE,
	REMOVE_PAGES,
	ADD_PAGE,
	CHANGE_INDEX_FOR_PAGE,
	MAKE_PAGES_SNAPSHOT,
	RESTORE_FROM_PAGES_SNAPSHOT,
} from "../constants/ActionConstants";

const pages = (state = initialStateObjectCollection(), action) => {
	switch (action.type) {
		case UPDATE_PAGES:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_PAGE:
			return updateItemInStateItems(state, action.item);
		case ADD_PAGE:
			return addItemInStateItems(state, action.item);
		case REMOVE_PAGE:
			return removeItemInStateItems(state, action.item);
		case REMOVE_PAGES:
			return removeItemsInStateItems(state, action.item);
		case CHANGE_INDEX_FOR_PAGE:
			return changeIndexForItemInStateItems(state, action.item, action.toIndex);
		case MAKE_PAGES_SNAPSHOT:
			return makeStateSnapshot(state, action.item);
		case RESTORE_FROM_PAGES_SNAPSHOT:
			return restoreStateSnapshot(state, action.item);
		default:
			return state;
	}
};

export default pages;
