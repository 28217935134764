import Button from "components/Button/Button";
import { ChoiceVariant } from "components/Choice/Choice";
import Text from "funkis-template/components/core/Text";
import gsap from "gsap";
import React, { useEffect } from "react";
import { TextId } from "../../funkis-template/models/player";
import { GlobalText } from "./KnowledgeQuiz.constants";
import { getCorrectlyAnsweredQuestions } from "./KnowledgeQuiz.utils";
import KnowledgeQuizQuestion, {
	KnowledgeQuizQuestionModel,
} from "./KnowledgeQuizQuestion";
import KnowledgeQuizSummary from "./KnowledgeQuizSummary";

export type KnowledgeQuizProps = {
	questions: KnowledgeQuizQuestionModel[];
	answers?: KnowledgeQuizAnswersModel;
	resettable?: boolean;
	variant: ChoiceVariant;
	optionColor?: string;
	onChange?: (answers: KnowledgeQuizAnswersModel) => void;
	onSubmit?: (answers: KnowledgeQuizAnswersModel) => void;
	summaryTitle?: TextId;
	feedbacks?: KnowledgeQuizFeedbackModel[];
};

export type KnowledgeQuizAnswersModel = Record<
	string,
	string | number | undefined
>;

export type KnowledgeQuizFeedbackModel = {
	threshold: number;
	textId: TextId;
};

const KnowledgeQuiz: React.FC<KnowledgeQuizProps> = ({
	questions,
	answers = {},
	resettable = true,
	onChange,
	onSubmit,
	variant,
	optionColor,
	summaryTitle,
	feedbacks = [],
}) => {
	const containerRef = React.useRef<HTMLDivElement>(null);
	const summaryRef = React.useRef<HTMLDivElement>(null);
	const submitRef = React.useRef<HTMLDivElement>(null);
	const refs = React.useRef<Record<string, any>>({});

	const questionsWithoutAnswers = questions.filter(
		(q) => answers[q.id] === undefined
	);

	const correctlyAnsweredQuestions = getCorrectlyAnsweredQuestions(
		questions,
		answers
	);
	const correctRatio = correctlyAnsweredQuestions.length / questions.length;
	const feedback = feedbacks.find(
		(feedback) => correctRatio * 100 > feedback.threshold
	);

	const isSubmittable = questionsWithoutAnswers.length === 0;
	const [isSubmitted, setIsSubmitted] = React.useState(isSubmittable);

	const handleQuestionChange = React.useCallback(
		(questionId: string, value: string | number) => {
			const nextAnswers = { ...answers, [questionId]: value };

			onChange && onChange(nextAnswers);
		},
		[answers, onChange]
	);

	useEffect(() => {
		questions.forEach((currentQuestion, i) => {
			const prevQuestion: KnowledgeQuizQuestionModel | undefined =
				questions[i - 1];

			if (!prevQuestion || answers[prevQuestion.id]) {
				gsap.to(refs.current[currentQuestion.id], { autoAlpha: 1 });
			} else {
				gsap.set(refs.current[currentQuestion.id], { autoAlpha: 0 });
			}
		});
	}, [questions, answers]);

	useEffect(() => {
		if (summaryRef.current) {
			gsap.to(summaryRef.current, { autoAlpha: isSubmitted ? 1 : 0 });
		}
	}, [isSubmitted]);

	useEffect(() => {
		if (submitRef.current) {
			gsap.to(submitRef.current, { autoAlpha: isSubmittable ? 1 : 0 });
		}
	}, [isSubmittable]);

	return (
		<div
			ref={containerRef}
			className="container-fluid d-flex flex-column"
			style={{ gap: "6rem", scrollMarginTop: "calc(50px + 2rem)" }}
		>
			{questions.map((question, i) => (
				<div
					key={question.id}
					id={question.id}
					ref={(node) => {
						refs.current[question.id] = node;
					}}
					style={{ scrollMarginTop: "calc(50px + 2rem)" }}
					className=""
				>
					<KnowledgeQuizQuestion
						id={question.id}
						media={question.media}
						title={question.title}
						optionsTitle={GlobalText.OptionsTitle}
						question={question.question}
						options={question.options}
						questionNumber={i + 1}
						questionsCount={questions.length}
						onChange={handleQuestionChange}
						selectedOption={answers[question.id]}
						disabled={isSubmitted}
						optionVariant={variant}
						optionColor={optionColor}
					/>
					{i + 1 < questions.length && (
						<div className="d-flex justify-content-center">
							<Button
								disabled={answers[question.id] === undefined}
								className="mt-2"
								onClick={() => {
									if (i + 1 < questions.length) {
										const nextQuestion = questions[i + 1];
										const nextQuestionNode = refs.current[nextQuestion.id];
										if (nextQuestionNode) {
											nextQuestionNode.scrollIntoView();
										}
									}
								}}
							>
								<Text textId={GlobalText.NextQuestionButton} />
							</Button>
						</div>
					)}
				</div>
			))}

			<div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
				<div
					ref={submitRef}
					style={{ display: "flex", justifyContent: "center", gap: "1rem" }}
				>
					<Button
						disabled={!isSubmittable || isSubmitted}
						onClick={() => {
							setIsSubmitted(true);
							summaryRef.current?.scrollIntoView();

							onSubmit && onSubmit(answers);
						}}
					>
						<Text textId={GlobalText.ShowResult} />
					</Button>
				</div>

				<div
					className="mt-4"
					ref={summaryRef}
					style={{ scrollMarginTop: "calc(50px + 2rem)" }}
				>
					<KnowledgeQuizSummary
						title={summaryTitle}
						questions={questions}
						answers={answers}
						feedback={feedback?.textId}
					/>

					{resettable && (
						<div
							className="mt-4"
							style={{ display: "flex", justifyContent: "center", gap: "1rem" }}
						>
							<Button
								disabled={!isSubmitted}
								onClick={() => {
									if (containerRef.current) {
										containerRef.current.scrollIntoView();
									}

									setIsSubmitted(false);
									onChange && onChange({});
								}}
							>
								<Text textId={GlobalText.ResetQuiz} />
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default KnowledgeQuiz;
