import _ from "lodash";
import { getByPaths, pickByTypes } from "./ObjectUtils";
import * as QuidInputContext from "../constants/QuidInputContexts";

export function createQuidInputFromQuid(quid, params = {}) {
	/*
    If we don't clone the quid we risk creating object references across quid and quidInputs.

    For example if the "quid" is selected from Redux state and has a key "test" with default value of [],
    then if we push to the quidInput.data.test array, we will accidentally
    change the quid.quidInputModel.test.default array as well. (Which might cause bugs the next time this method is executed and we get an unexpected default value)
  */
	const quidClone = _.cloneDeep(quid);
	let data = {};
	for (const key in quidClone.quidInputModel) {
		data[key] = quidClone.quidInputModel[key].default;
	}
	return {
		sourceQuid: quidClone.id,
		data,
		..._.omitBy(params, (value) => value === undefined || value === null),
	};
}

export const getQueryForContext = (context, options = {}, omit = []) => {
	const validOptions = pickByTypes(
		{ ...options },
		{
			id: _.isString,
			archived: _.isBoolean,
			sourceQuid: _.isString,
			targetQuid: _.isString,
			targetQuidInput: _.isString,
			data: _.isObject,
			targetDataKey: (value) => value !== undefined,
			limit: _.isNumber,
			throttle: _.isBoolean,
			throttleWait: _.isNumber,
		}
	);

	const quidditySession = getByPaths(options, [
		"quidditySession",
		"sessionId",
		"quidditySessionId",
		"session.id",
		"session",
	]);
	const group = getByPaths(options, ["groupId", "group.id", "group"]);
	const member = getByPaths(options, ["memberId", "member.id", "member"]);

	switch (context) {
		case QuidInputContext.SESSION:
			return _.omit(
				{
					...validOptions,
					...pickByTypes(
						{
							quidditySession,
						},
						{ quidditySession: _.isString }
					),
				},
				omit
			);
		case QuidInputContext.GROUP:
			return _.omit(
				{
					...validOptions,
					...pickByTypes(
						{
							quidditySession,
							group,
						},
						{ quidditySession: _.isString, group: _.isString }
					),
				},
				omit
			);
		case QuidInputContext.MEMBER:
			return _.omit(
				{
					...validOptions,
					...pickByTypes(
						{
							quidditySession,
							member,
						},
						{ quidditySession: _.isString, member: _.isString }
					),
				},
				omit
			);
		case QuidInputContext.CURRENT_GROUP_MEMBERS:
			// Return a query object for all data in current session. The data must be filtered for "current group memners". Use selector "useMembersForGroup" to get members for group
			return _.omit(
				{
					...validOptions,
					...pickByTypes(
						{
							quidditySession,
						},
						{ quidditySession: _.isString }
					),
				},
				omit
			);

		default:
			throw new Error(`Context ${context} is not valid!`);
	}
};
