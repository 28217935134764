import React from "react";
import { connect as reactReduxConnect } from "react-redux";
import { StoreUtils } from "funkis-foundation";

export const connect = (WrappedComponent, ...args) => {
	const ConnectedWrappedComponent = reactReduxConnect(...args)(
		WrappedComponent
	);
	return (props) => {
		return (
			<ConnectedWrappedComponent {...props} store={StoreUtils.getStore()} />
		);
	};
};
