import debounce from "debounce-promise";
import _ from "lodash";

import * as StoreUtils from "../StoreUtils";
import * as Storages from "../../Storages";
import { getQueryObjectFromCurrentUrl } from "../../utils/LocationUtils";

let _observers = [];

export function addStoreObserver(
	name,
	storage,
	domain,
	restoreFunction,
	saveFunction,
	saveDebounce = 500,
	disableWatch = false // Disable save to enable manual save calling save();
) {
	// Make sure that only one watch for store is added.
	if (!_observers.find((observer) => observer.name === name)) {
		// Call save function when store changes
		StoreUtils.watchStore(name, (newVal, oldVal, objectPath) => {
			debouncedSave(newVal, oldVal, objectPath);
		});

		const debouncedSave = debounce(executeSave, saveDebounce);
	}

	// Store a reference
	_observers.push({
		name,
		storage,
		domain,
		restore: restoreFunction,
		save: saveFunction,
		saveDebounce,
		disableWatch,
	});

	// Call restore function
	restore(name);
}

export function restore(name) {
	const query = getQueryObjectFromCurrentUrl();
	if (query.clearStorage === "true") {
		console.warn("clearStorage was set to true. Restore function dissmissed!");
		return;
	}

	const observers = _observers.filter((obs) => obs.name === name);
	observers.forEach((observer) => {
		if (observer) {
			const { storage, domain, restore } = observer;

			Storages.getStorage(storage).restore(name, domain, restore);
		} else {
			console.error(`Observer withn name ${name} could not be found!`);
		}
	});
}

export function save(name) {
	const observers = _observers.filter((obs) => obs.name === name);

	observers.forEach((observer) => {
		if (observer) {
			const storage = Storages.getStorage(observer.storage);
			const { domain, restore, newVal = {}, oldVal = {} } = observer;
			storage.save(name, domain, observer.save(newVal, oldVal, name));
		}
	});
}

export function clear(name) {
	const names = name && [name];
	const observers = _observers.filter((obs) => names.includes(obs.name));
	observers.forEach((observer) => {
		if (observer) {
			const storage = Storages.getStorage(observer.storage);
			const { domain, restore, newVal, oldVal } = observer;
			storage.save(name, domain, []);
		}
	});
}

export function clearAll() {
	const observerNames = _.uniq(_observers.map((observer) => observer.name));
	observerNames.forEach((observerName) => clear(observerName));
}

function executeSave(newVal, oldVal, objectPath) {
	const observers = _observers.filter((obs) => obs.name === objectPath);

	observers.forEach((observer) => {
		if (observer && !observer.disableWatch) {
			observer.newVal = newVal;
			observer.oldVal = oldVal;
			save(objectPath);
		}
	});
}
