import React from "react";
import classnames from "classnames";
import { connect } from "../../utils/reduxUtils";
import {
	ReduxObjectSelectors,
	ReduxCollectionSelectors,
	FFButton,
	FFText,
	AppActions,
} from "funkis-foundation";
import SimpleReveal from "../core/SimpleReveal";

const { makeGetValueSelector } = ReduxObjectSelectors;
const { makeGetItemIdsSelector, makeGetItemValueByIdAndPathSelector } =
	ReduxCollectionSelectors;
const { updateAppValue } = AppActions;

const RevealMainMenuButton = ({
	revealedFromStart,
	revealedByUser,
	reveal,
}) => {
	return (
		<SimpleReveal
			className="w-100 d-flex justify-content-center"
			reveal={
				revealedFromStart === undefined
					? false
					: Boolean(reveal & !revealedFromStart && !revealedByUser)
			}
		>
			<FFButton
				onClick={() => {
					updateAppValue({ mainMenuRevealedByUser: true });
				}}
				className={classnames(
					"btn btn-black rounded-pill col-sm-4 m-4 button-small p-1 animated fadeIn"
				)}
			>
				<FFText
					textId="FAA5BD0C-8239-4FAF-8301-BCDC32ED8654"
					className="text-uppercase button text-white"
				/>
			</FFButton>
		</SimpleReveal>
	);
};

const makeMapStateToProps = () => {
	const getValueSelector = makeGetValueSelector();
	const getItemIdsSelector = makeGetItemIdsSelector();
	const getItemValueByIdAndPathSelector = makeGetItemValueByIdAndPathSelector();
	const mapStateToProps = (state, props) => {
		const menuPageIds = getItemIdsSelector(
			state,
			{
				match: (item) =>
					item.data &&
					item.data.cmt &&
					item.data.cmtPage.page_type === "main menu",
			},
			"pages"
		);
		const lastPageId = menuPageIds[menuPageIds.length - 1];
		const menuPagePageItemIds = getItemIdsSelector(
			state,
			{
				match: { parentPageId: lastPageId },
			},
			"pageItems"
		);
		const lastPageItemId = menuPagePageItemIds[menuPagePageItemIds.length - 1];
		const reveal = getItemValueByIdAndPathSelector(
			state,
			{ id: lastPageItemId, path: "showNextButton" },
			"pageItems"
		);
		return {
			revealedFromStart:
				getValueSelector(
					state,
					{ ...props, path: "main_menu_revealed" },
					"app"
				) === "yes",
			revealedByUser: getValueSelector(
				state,
				{ ...props, path: "mainMenuRevealedByUser" },
				"app"
			),
			reveal,
			menuPageIds,
		};
	};
	return mapStateToProps;
};

export default connect(RevealMainMenuButton, makeMapStateToProps);
