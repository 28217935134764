import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import uuid from "uuid/v4";
import _ from "lodash";
import { QuidInputActions } from "funkis-foundation";
const { updateQuidInput, removeQuidInput } = QuidInputActions;

const LocalInput = (props) => {
	const { renderFunction, targetDataKey, quidType, onRemove } = props;
	const quidInputId =
		props.quidInputId || props.id || _.get(props, "pageItem.id");

	const quidInput = useSelector(
		(state) =>
			(quidInputId && state.quidInputs.itemsById[quidInputId]) || undefined
	);

	const [data, setData] = useState(_.get(quidInput, "data", {}));

	useEffect(() => {
		if (quidInput) {
			setData(quidInput.data);
		}
	}, [quidInput]);

	const hasDataForKeys = (keys) => {
		if (keys.length === 0) return false;
		return keys.every((key) => data.hasOwnProperty(key) && data[key] !== "");
	};

	const hasSavedDataForKeys = (keys) => {
		if (keys.length === 0) return false;
		return keys.every(
			(key) =>
				quidInput && quidInput.data.hasOwnProperty(key) && data[key] !== ""
		);
	};

	const hasUnsavedDataForKeys = (keys) => {
		if (!quidInput && hasDataForKeys(keys)) {
			return true;
		} else if (quidInput && quidInput.data && hasDataForKeys(keys)) {
			return !_.isEqual(data, quidInput.data);
		} else {
			return false;
		}
	};

	const updateData = (updates) => {
		setData({ ...data, ...updates });
	};

	const save = (alwaysCreate, updates = {}) => {
		return new Promise((resolve) => {
			updateData(updates);
			const id = alwaysCreate || !quidInputId ? uuid() : quidInputId;
			// set "updatedAt" to simulate same data model as quiddity server
			// TODO: figure out how to know when it's "createdAt"
			const timestamps = { updatedAt: new Date() };
			updateQuidInput(id, {
				targetDataKey,
				quidType,
				...timestamps,
				data: { ...data, ...updates },
			});
			resolve();
		});
	};

	const create = (data = {}) => {
		return new Promise((resolve) => {
			const item = {
				id: uuid(),
				quidType,
				targetDataKey,
				data,
				createdAt: new Date(),
			};
			updateQuidInput(item.id, item);
			resolve(item);
		});
	};

	const remove = () => {
		onRemove && onRemove(quidInput);
		removeQuidInput(quidInputId);
	};

	return renderFunction({
		...props,
		children: null,
		data,
		input: quidInput,
		quidInput,
		quidInputId,
		hasDataForKeys,
		hasSavedDataForKeys,
		hasUnsavedDataForKeys,
		updateData,
		save,
		create,
		remove,
	});
};

export default LocalInput;
