import MissingListError from "errors/MissingListError";
import { PageItemActions } from "funkis-foundation";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { VALUE_LIST_TYPE } from "funkis-template/constants/valueListConstants";
import { IOContext, QuidType, useBackend } from "funkis-template/hooks/backend";
import { useValueListOptionsOrContentArray } from "funkis-template/hooks/valuelist";
import { RateContentArray } from "funkis-template/models/contentarray";
import { RateInputScrollBlock } from "funkis-template/models/pageitem";
import { PageItem } from "funkis-template/models/player";
import { getBootstrapColor } from "funkis-template/utils/colorUtils";
import _ from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import RateInput from "./RateInput";

const { updatePageItem } = PageItemActions;

const PageItemRateInput: FC<{ pageItem: PageItem<RateInputScrollBlock> }> = (
	props
) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;

	const [hasSetInitialValueFromBackend, setHasSetInitialValueFromBackend] =
		useState(false);

	const options = useValueListOptionsOrContentArray(
		cmtPageItem,
		VALUE_LIST_TYPE.RATE
	) as RateContentArray["values"];

	if (options === undefined || options.length === 0) {
		throw new MissingListError(cmtPageItem.symbol_name);
	}

	const minValue = _.minBy(options, "value")!.value;
	const maxValue = _.maxBy(options, "value")!.value;

	const {
		quidInputs,
		isSuccess,
		createQuidInput,
		updateQuidInput,
		deleteQuidInput,
	} = useBackend({
		targetDataKey:
			cmtPageItem.io_id !== "" ? cmtPageItem.io_id : cmtPageItem.id,
		inputContext: IOContext.Member,
		quidType: QuidType.Rate,
	});

	const debouncedUpdateQuidInput = useMemo(
		() => _.debounce(updateQuidInput, 400),
		[updateQuidInput]
	);

	const quidInput = _.first(quidInputs);

	const initialValue = cmtPageItem.slider_start_value;
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		if (isSuccess && !quidInput && !hasSetInitialValueFromBackend) {
			createQuidInput({
				data: { value: initialValue, min_value: minValue, max_value: maxValue },
			});
			setHasSetInitialValueFromBackend(true);
		}
	}, [
		isSuccess,
		initialValue,
		createQuidInput,
		quidInput,
		hasSetInitialValueFromBackend,
	]);

	useEffect(() => {
		/** We've seen some cases where multiple quidInputs were created but do not understand where they come from.
		So, instead, we simply delete unused quidInputs.
		**/
		const [_quidInput, ...rest] = quidInputs;
		rest.forEach(({ id }) => deleteQuidInput({ id }));
	}, [quidInputs, deleteQuidInput]);

	useEffect(() => {
		/**
		We only want to set the server value to our internal state once, on initial load.
		**/
		if (isSuccess && quidInput && !hasSetInitialValueFromBackend) {
			setValue(quidInput.data.value);
			setHasSetInitialValueFromBackend(true);
		}
	}, [isSuccess, quidInput, hasSetInitialValueFromBackend]);

	useEffect(() => {
		if (quidInput && quidInput.data.value !== value) {
			debouncedUpdateQuidInput({
				...quidInput,
				data: { value: value, min_value: minValue, max_value: maxValue },
			});
		} else {
			debouncedUpdateQuidInput.cancel();
		}
	}, [value, quidInput, debouncedUpdateQuidInput, minValue, maxValue]);

	const isLoading = !hasSetInitialValueFromBackend;

	const rateInputType =
		cmtPageItem.correct_values !== "" ||
		(!isNaN(cmtPageItem.correct_min_value) &&
			!isNaN(cmtPageItem.correct_max_value) &&
			cmtPageItem.correct_min_value !== cmtPageItem.correct_max_value)
			? "question"
			: "poll";

	const shouldShowNextButton =
		cmtPageItem.show_next_button === "always" ||
		rateInputType === "poll" ||
		(cmtPageItem.show_next_button === "auto" &&
			pageItem.status === "completed");

	return (
		<PageItemScrollBase {...props} showNextButton={shouldShowNextButton}>
			<RateInput
				contentArray={options}
				onChange={(newValue: number) => {
					setValue(newValue);
				}}
				onSubmit={(_newValue: number) => {
					updatePageItem(pageItem.id, {
						status: "completed",
						shouldSaveStatus: true,
					});
				}}
				loading={isLoading}
				value={value}
				variant={cmtPageItem.variant}
				submitted={pageItem.status === "completed"}
				redoable={cmtPageItem.redoable === "yes"}
				feedbackCorrectText={cmtPageItem.feedback_correct}
				feedbackIncorrectText={cmtPageItem.feedback_incorrect}
				bodyText={cmtPageItem.body}
				headingText={cmtPageItem.header}
				instructionText={cmtPageItem.instruction}
				sliderMedia={cmtPageItem.slider_media}
				media={cmtPageItem.media}
				sliderPadding={cmtPageItem.slider_margins}
				correctMinValue={cmtPageItem.correct_min_value}
				correctMaxValue={cmtPageItem.correct_max_value}
				track={cmtPageItem.slider_fill_mode === "colored"}
				sliderHandleColor={
					cmtPageItem.slider_handle_color !== ""
						? getBootstrapColor(cmtPageItem.slider_handle_color)
						: undefined
				}
				sliderRailColor={
					cmtPageItem.slider_track_color !== ""
						? getBootstrapColor(cmtPageItem.slider_track_color)
						: undefined
				}
				correctValues={
					cmtPageItem.correct_values !== ""
						? cmtPageItem.correct_values.split(",").map((v) => parseInt(v, 10))
						: undefined
				}
			/>
		</PageItemScrollBase>
	);
};

export default PageItemRateInput;
