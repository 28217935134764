import React from "react";
import classnames from "classnames";
import ReactDOM from "react-dom";
import Style from "./Portal.module.css";

export const Portal = ({ children, className, parent, element = "div" }) => {
	const classNames = classnames(
		Style.root,
		"position-absolute w-100 h-100",
		className
	);
	const [container] = React.useState(() => {
		const el = document.createElement(element);
		classNames.split(" ").forEach((name) => {
			el.classList.add(name.trim());
		});

		return el;
	});

	React.useEffect(() => {
		const element =
			parent ||
			document.getElementsByClassName("page-scalable")[0] ||
			document.getElementById("modal-page-item");

		if (element && container) {
			element.appendChild(container);
		}
		return () => {
			element.removeChild(container);
		};
	}, []);

	return ReactDOM.createPortal(children, container);
};
