import {
	setCourseStatus as _setCourseStatus,
	setCompletionStatus as _setCompletionStatus,
	setSuccessStatus as _setSuccessStatus,
	setScore as _setScore,
	clearData as _clearData,
} from "../utils/StorageUtils";
import { COMPLETED, PASSED } from "../constants/StatusConstants";

export function setCourseToCompleted({
	setSuccessStatus = true,
	score = 100,
	scoreMin = 0,
	scoreMax = 100,
} = {}) {
	_setCourseStatus(COMPLETED);
	if (setSuccessStatus) {
		_setSuccessStatus(PASSED);
		_setScore(score, scoreMin, scoreMax);
	}
}

export function setScore({ rawScore = 0, minScore = 0, maxScore = 100 } = {}) {
	_setScore(rawScore, minScore, maxScore);
}

export function clearData() {
	_clearData();
}

export function setCourseStatus(status) {
	console.warn(
		`Action setCourseStatus is depricated. Please use "setCourseToCompleted"`
	);
	_setCourseStatus(status);
}

export function setCompletionStatus(status) {
	console.warn(
		`Action setCourseStatus is depricated. Please use "setCourseToCompleted"`
	);
	_setCompletionStatus(status);
}

export function setSuccessStatus(status) {
	console.warn(
		`Action setCourseStatus is depricated. Please use "setCourseToCompleted"`
	);
	_setSuccessStatus(status);
}
