import React from "react";
import { FFText } from "funkis-foundation";
import classnames from "classnames";
import Styles from "./IconWithLabel.module.css";

const IconWithLabel = ({ className, textId, text, textColor, icon, style }) => {
	return (
		<div
			className={classnames("d-flex align-items-center mr-2", className)}
			style={style}
		>
			<div className={Styles.iconOutline}>{icon}</div>
			<FFText
				textId={textId}
				className={classnames(Styles.text, "ml-1 main-menu-icon")}
				placeholder={text}
				style={{ color: textColor }}
			/>
		</div>
	);
};

export default IconWithLabel;
