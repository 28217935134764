import { getReducer } from "../utils/StoreUtils";
import { clearData } from "../utils/StorageUtils";
import { addWords, removeWords } from "../utils/StringUtils";
import {
	loadText,
	loadCMTText,
	loadSubtitles,
	loadCMTSubtitles,
} from "../utils/LoadUtils";
import {
	updateSubtitles,
	clearSubtitles,
} from "../redux/actions/SubtitleActions";
import { updateTexts } from "../redux/actions/TextActions";
import { updateConfig } from "../redux/actions/ConfigActions";

export function alert(message) {
	window.alert(message);
}

export function log(message) {
	console.log(message);
}

export function print() {
	window.print();
}

export function warn(message) {
	console.warn(message);
}

export function error(message) {
	console.error(message);
}

export function closeWindow() {
	self.close();
	top.window.close();
}

export function reload() {
	window.location.reload();
}

export function addClassNamesToBody(classNames) {
	document.body.className = addWords(document.body.className, classNames);
}

export function removeClassNamesFromBody(classNames) {
	document.body.className = removeWords(document.body.className, classNames);
}

export function changeLanguage(language) {
	if (!language) {
		// if language from selecct
		if (this.pageItem.type === "select") {
			const { value } = this.pageItem;
			language = value.value;
		} else {
			throw new Error(
				"Please define a language or leave blank if you're using a select drop down!"
			);
		}
	}

	updateConfig({ language });
}

export async function loadLanguage(language, updateConfigValue = false) {
	const config = getReducer("config").item;
	const newConfig = { ...config };

	if (language) {
		newConfig.language = language;
		updateConfigValue && updateConfig({ language });
	}

	// load texts
	let texts;
	try {
		texts = await loadText(newConfig);
	} catch (error) {
		console.warn(error);
	}

	// Load cmt text
	let cmtTexts;
	try {
		cmtTexts = await loadCMTText(newConfig);
	} catch (error) {
		console.warn(error);
	}

	// Load subs
	let subs;
	try {
		subs = await loadSubtitles(newConfig);
	} catch (error) {
		console.warn(error);
	}

	// Load cmt subs
	let cmtSubs;
	try {
		cmtSubs = await loadCMTSubtitles(newConfig);
	} catch (error) {
		console.warn(error);
	}

	// update
	texts && updateTexts(texts);
	cmtTexts && updateTexts(cmtTexts);
	// clearing, because subtitles do not always share same id between languages. So if we don't clear there can
	// be duplicate subtitles for a specific file name, and PageItemSubtitles will be confused.
	clearSubtitles();
	subs && updateSubtitles(subs);
	cmtSubs && updateSubtitles(cmtSubs);

	return { texts, cmtTexts, subs, cmtSubs };
}

export function clearStorage() {
	clearData();
}
