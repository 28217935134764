import React from "react";
import classNames from "classnames";
import _ from "lodash";

import windowResizeHOC from "../hocs/windowResizeHOC";
import { scaleToFit } from "../utils/MathUtils";

class PageScalable extends React.Component {
	render() {
		const { page, window } = this.props;
		const {
			width = 1280,
			height = 720,
			transformOrigin = "top left",
			objectFit = "contain",
			translate = false,
		} = page;

		const result = scaleToFit({ width, height }, window, objectFit);
		const transform = translate
			? `${result.transformTranslate} ${result.transformScale}`
			: result.transformScale;
		const transformStyle = { transform, transformOrigin };

		/* Centering on both x an y-axis is default for template slide productions */
		let centeringStyles = {};

		if (_.get(page, "data.cmtPage.program_type") === "slide") {
			const scaledWidth = width * result.scaleX;
			const availableWidth = window.width;
			const scaledHeight = height * result.scaleY;
			const availableHeight = window.height;
			if (scaledWidth < availableWidth) {
				centeringStyles.left = Math.round((availableWidth - scaledWidth) / 2);
			}
			if (scaledHeight < availableHeight) {
				centeringStyles.top = Math.round((availableHeight - scaledHeight) / 2);
			}
		}
		return (
			<div
				className={classNames(
					"page page-scalable",
					`page-id-${page.friendlyId || page.id}`,
					page.className
				)}
				data-page-id={page.id}
				data-location-path={page.locationPath}
				data-page-name={page.name}
				style={{
					...page.style,
					...transformStyle,
					...centeringStyles,
					width: `${width}px`,
					height: `${height}px`,
				}}
			>
				{this.props.children}
			</div>
		);
	}
}

export default windowResizeHOC(PageScalable);
