import React from "react";
import { connect } from "../../utils/reduxUtils";
import classnames from "classnames";
import {
	ReduxCollectionSelectors,
	ReduxObjectSelectors,
	PageItemMenuButton,
	FFText,
	LocationSelectors,
	PageItemImage,
} from "funkis-foundation";
import Text from "../../components/core/Text";
import StatusIcon from "../../components/core/StatusIcon";
import Style from "./MainMenuPageButton.module.css";
import IconWithLabel from "../core/IconWithLabel";
import IconWatch from "../../assets/svg/IconWatch";

const { makeGetItemByIdSelector } = ReduxCollectionSelectors;
const { makeGetValueSelector } = ReduxObjectSelectors;
const { makeGetLocationSelector } = LocationSelectors;

let _component;

export const setComponent = (component) => {
	_component = component;
};

const MainMenuPageButton = (props) => {
	if (_component) {
		return _component(props);
	}
	const {
		page,
		location,
		index,
		chapterIndex,
		chapterStatus,
		className,
		theme,
	} = props;
	const { cmtPage } = page.data;
	const mainMenuImage = cmtPage.main_menu_image || cmtPage.menu_image;
	// the first "child" of the first "parent chapter" should aways be clickable
	// and if a "parent chapter" is  set to incomplete and has a first "child", it too should be clickable
	const menuButtonEnabled =
		page.status !== "not_attempted" ||
		((chapterIndex === 0 || chapterStatus == "incomplete") && index === 0);

	return (
		<PageItemMenuButton
			pageItem={{ pageId: page.id, enabled: menuButtonEnabled }}
			className={classnames(
				"chapter-button",
				Style.root,
				!menuButtonEnabled && "opacity-50",
				"border-bottom mb-5 pb-5",
				className
			)}
		>
			{mainMenuImage && (
				<PageItemImage
					pageItem={{
						src: mainMenuImage,
						backgroundImage: true,
						autoSize: false,
					}}
					className={classnames(Style.image, "")}
				>
					<div
						className={classnames(
							"cover",
							theme === "dark" && Style.cover,
							theme === "light" && Style.border,
							theme === "light" && "border border-light-gray"
						)}
					/>
				</PageItemImage>
			)}

			<div
				className={classnames(
					Style.textContainer,
					"px-3 d-flex flex-column align-content-stretch"
				)}
			>
				{/* PAGE TITLE */}
				<FFText
					tagName="h4"
					textId={cmtPage.title_txt_id}
					className="font-weight-bold flex-grow-0"
				/>
				{/* PAGE SUB TITLE 1 */}
				<Text
					tagName="h5"
					textId={cmtPage.sub_title_1_txt_id}
					className="flex-grow-0"
				/>
				<div className="d-flex flex-wrap flex-grow-1 align-content-end">
					{/* DURATION */}
					{cmtPage.duration && (
						<IconWithLabel Icon={IconWatch} labelText={cmtPage.duration} />
					)}
					{/* STATUS */}
					<StatusIcon status={page.status} className={Style.iconAndLabel} />
				</div>
			</div>
		</PageItemMenuButton>
	);
};

const makeMapStateToProps = () => {
	const getItemByIdSelector = makeGetItemByIdSelector();
	const getLocationSelector = makeGetLocationSelector();
	const getValueSelector = makeGetValueSelector();
	const mapStateToProps = (state, props) => {
		return {
			page: getItemByIdSelector(state, props, "pages"),
			location: getLocationSelector(state, props),
			theme:
				getValueSelector(state, { path: "main_menu_theme" }, "app") || "light",
		};
	};
	return mapStateToProps;
};

export default connect(MainMenuPageButton, makeMapStateToProps);
