class MissingListError extends Error {
	title: string;
	symbolName: string;

	constructor(symbolName: string) {
		const message = `This Page Item (${symbolName}) requires a List of values. Please add a List in FAT and try again.`;
		super(message);

		this.name = "MissingListError";
		this.title = `Page Item is missing a List of values`;
		this.symbolName = symbolName;
	}
}

export default MissingListError;
