import React from "react";
import classnames from "classnames";
import { FFButton } from "funkis-foundation";
import IconPlus from "../../../assets/svg/IconPlus";
import Text from "../Text";
import { getBootstrapColor } from "../../../utils/colorUtils";
import Styles from "./ButtonPlus.module.css";

const ButtonPlus = ({
	labelTextId,
	enabled = true,
	variant = "normal",
	onClick,
	ref,
	...rest
}) => {
	return (
		<FFButton
			className="h-100 d-flex justify-content-center align-items-center"
			onClick={onClick}
			enabled={enabled}
			style={{ opacity: enabled ? 1 : 0.5 }}
			{...rest}
		>
			{({ hover }) => (
				<React.Fragment>
					<div
						ref={ref}
						className={classnames(
							Styles.container,
							variant === "small" && Styles.small,
							variant === "tiny" && Styles.tiny,
							"mr-2"
						)}
						style={{
							backgroundColor:
								hover && enabled ? getBootstrapColor("interaction") : "black",
						}}
					>
						<IconPlus
							variant="thick"
							style={
								variant === "small"
									? { width: "30px", height: "30px" }
									: variant === "tiny"
									? { width: "12px", height: "12px" }
									: {}
							}
						/>
					</div>
					<Text
						textId={labelTextId}
						className="button-label-sm font-weight-bold text-uppercase text-nowrap"
					/>
				</React.Fragment>
			)}
		</FFButton>
	);
};

export default ButtonPlus;
