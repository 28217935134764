import React from "react";

const IconCalender = () => {
	return (
		<svg width="45px" height="45px" viewBox="0 0 45 45" version="1.1">
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="Group-18">
					<polygon
						id="Fill-2"
						fill="#FFFFFF"
						points="5 10 10 10 10 5 5 5"
					></polygon>
					<polygon
						id="Fill-3"
						fill="#FFFFFF"
						points="15 10 20 10 20 5 15 5"
					></polygon>
					<polygon
						id="Fill-4"
						fill="#FFFFFF"
						points="25 10 30 10 30 5 25 5"
					></polygon>
					<polygon
						id="Fill-5"
						fill="#FFFFFF"
						points="35 10 40 10 40 5 35 5"
					></polygon>
					<polygon
						id="Fill-6"
						fill="#FFFFFF"
						points="5 20 10 20 10 15 5 15"
					></polygon>
					<polygon
						id="Fill-7"
						fill="#FFFFFF"
						points="15 20 20 20 20 15 15 15"
					></polygon>
					<polygon
						id="Fill-8"
						fill="#E30613"
						points="25 20 30 20 30 15 25 15"
					></polygon>
					<polygon
						id="Fill-9"
						fill="#FFFFFF"
						points="35 20 40 20 40 15 35 15"
					></polygon>
					<polygon
						id="Fill-10"
						fill="#FFFFFF"
						points="5 30 10 30 10 25 5 25"
					></polygon>
					<polygon
						id="Fill-11"
						fill="#FFFFFF"
						points="15 30 20 30 20 25 15 25"
					></polygon>
					<polygon
						id="Fill-12"
						fill="#FFFFFF"
						points="25 30 30 30 30 25 25 25"
					></polygon>
					<polygon
						id="Fill-13"
						fill="#FFFFFF"
						points="35 30 40 30 40 25 35 25"
					></polygon>
					<polygon
						id="Fill-14"
						fill="#FFFFFF"
						points="5 40 10 40 10 35 5 35"
					></polygon>
					<polygon
						id="Fill-15"
						fill="#E30613"
						points="15 40 20 40 20 35 15 35"
					></polygon>
					<polygon
						id="Fill-16"
						fill="#FFFFFF"
						points="25 40 30 40 30 35 25 35"
					></polygon>
					<polygon
						id="Fill-17"
						fill="#FFFFFF"
						points="35 40 40 40 40 35 35 35"
					></polygon>
				</g>
				<rect
					id="Rectangle"
					stroke="none"
					strokeWidth="10"
					x="-493"
					y="-266"
					width="2681"
					height="6727"
				></rect>
			</g>
		</svg>
	);
};

export default IconCalender;
