import React from "react";
import Text from "./Text";
import classnames from "classnames";
import Styles from "./DraggableActionItem.module.css";
import DraggableBullet from "./DraggableBullet";

const DraggableActionItem = ({
	number,
	textId,
	className,
	draggable = true,
}) => {
	return (
		<div className={classnames("d-flex align-items-center", className)}>
			<div className={classnames("mr-1", Styles.numberContainer)}>
				<DraggableBullet
					className={Styles.draggableBullet}
					draggable={false}
					number={number}
				></DraggableBullet>
				{draggable && (
					<DraggableBullet
						className={Styles.draggableBullet}
						draggable
						number={number}
					></DraggableBullet>
				)}
			</div>
			<Text textId={textId} />
		</div>
	);
};

export default DraggableActionItem;
