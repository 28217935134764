import React, { useEffect, useState } from "react";
import classnames from "classnames";
import {
	FFButton,
	PageItemActions,
	Actions,
	StoreUtils,
} from "funkis-foundation";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import SelectItem from "../../components/core/SelectItem";
import Text from "../../components/core/Text";
import { getText } from "../../utils/textUtils";

const { updatePageItem } = PageItemActions;
const { setCourseToCompleted, closeWindow } = Actions;
const { clearAllStores } = StoreUtils;

const PageItemCourseStatus = (props) => {
	const { pageItem } = props;
	const { cmtPageItem = {}, status } = pageItem;
	const completed = status === "completed";

	const header = cmtPageItem.header;
	const body = cmtPageItem.body;
	const checkboxLabel = cmtPageItem.checkbox_label;
	const exitButtonLabel = cmtPageItem.exit_button_label;
	const showNextButton = completed || !getText(checkboxLabel, true);
	const deleteUserData = cmtPageItem.delete_user_data === "yes";

	const [hasBeenShown, setHasBeenShown] = useState(false);

	// Set course to completed
	useEffect(() => {
		hasBeenShown && setCourseToCompleted();
		// ISSUE #281 BLOCKED
		// deleteUserData && clearAllStores();
		// deleteUserData && clearData();
	}, [hasBeenShown]);

	const hide = !getText(header) && !getText(body);
	const rootStyle = hide ? { height: 0 } : {};

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={showNextButton}
			rootStyle={rootStyle}
			onScrollEnterStart={() => setHasBeenShown(true)}
			renderFunction={(renderProps) => (
				<div className={classnames("row m-0 mb-6")}>
					{/* HEADER */}
					<Text
						className={classnames(
							"col-sm-8 font-weight-bold animated",
							renderProps.viewVisible ? "fadeIn" : "fadeOut"
						)}
						tagName="h2"
						textId={header}
					/>
					{/* BODY */}
					<Text
						className={classnames(
							"col-sm-8 mt-4 animated",
							renderProps.viewVisible ? "fadeIn delay-250ms" : "fadeOut"
						)}
						tagName="h4"
						textId={body}
					/>
					{/* SELECT */}
					{getText(checkboxLabel, true) && (
						<FFButton
							className={classnames(
								"d-flex mt-4 col-sm-8 animated",
								renderProps.viewVisible ? "fadeInRight delay-5000ms" : "fadeOut"
							)}
							onClick={() => {
								updatePageItem(pageItem.id, {
									status: completed ? "incomplete" : "completed",
								});
							}}
						>
							<SelectItem selected={completed} />
							<Text tagName="p" textId={checkboxLabel} />
						</FFButton>
					)}
					{/* EXIT BUTTON  */}
					<div className="col-sm-12" />
					{getText(exitButtonLabel, true) && (
						<FFButton
							className={classnames(
								"btn btn-black rounded-pill mt-4 animated col-sm-4 btn-ellipsis",
								renderProps.viewVisible ? "fadeIn delay-500ms" : "fadeOut"
							)}
							onClick={() => {
								closeWindow();
							}}
						>
							<Text textId={exitButtonLabel} className="text-uppercase" />
						</FFButton>
					)}
				</div>
			)}
		/>
	);
};

export default PageItemCourseStatus;
