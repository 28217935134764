import classnames from "classnames";
import {
	FFButton,
	FFMultiSelect,
	FFText,
	PageItemActions,
} from "funkis-foundation";
import gsap from "gsap";
import React, { FC, useEffect, useRef, useState } from "react";
import Image from "../../../components/core/Image";
import SimpleReveal from "../../../components/core/SimpleReveal";
import Text from "../../../components/core/Text";
import Styles from "../../../components/core/version-2/IOMultiSelect.module.css";
import SelectItem from "../../../components/core/version-2/SelectItem";
import PageItemScrollBase from "../../../components/scroll/PageItemScrollBase";
import { useIOMultiSelect } from "../../../hooks/io/multiselect";
import { useIsShowingTopBar } from "../../../hooks/player";
import { MultipleChoiceInputScrollBlock } from "../../../models/pageitem";
import { PageItem } from "../../../models/player";
import { getText } from "../../../utils/textUtils";

const PageItemIOMultipleChoiceInput: FC<{
	correctDelay?: number;
	pageItem: PageItem<MultipleChoiceInputScrollBlock> & {
		// we're keeping the status in "completionStatus" instead of "status" to bypass strange
		// re-reveal behavior that was triggered by switching pageItem.status directly
		completionStatus: PageItem<MultipleChoiceInputScrollBlock>["status"];
	};
}> = (props) => {
	const { pageItem, correctDelay = 0 } = props;
	const { cmtPageItem } = pageItem;
	const shouldBlurImageBeforeAnswer =
		cmtPageItem.blur_image_before_answer === "yes";

	const isShowingTopBar = useIsShowingTopBar(pageItem.type);

	const instruction = cmtPageItem.instruction;
	const imageSize = cmtPageItem.image_size;
	const imageAlign = cmtPageItem.image_align;
	const maxHeight = cmtPageItem.image_max_height;
	const imageMarginsMobile = cmtPageItem.image_margins_mobile !== "no";

	const isCompleted = pageItem.completionStatus === "completed";
	const shouldCorrect = cmtPageItem.correct === "yes";
	const [showNextButton, setShowNextButton] = useState(
		cmtPageItem.show_next_button === "always"
	);
	const refFeedback = useRef(null);

	const [blurDuration] = useState(
		!isCompleted && shouldBlurImageBeforeAnswer ? 1.5 : 0
	);

	const {
		options,
		isCorrect,
		minSelections,
		maxSelections,
		isFinished: isSelectionFinished,
		hasConfirmationStep,
		hasConfirmedSelection,
		setHasConfirmedSelection,
		ffMultiSelectProps,
	} = useIOMultiSelect({ cmtPageItem });

	const getMode = (minSelections: number, maxSelections: number) => {
		if (minSelections === 1 && maxSelections === 1) return "radio";
		else return "select";
	};
	const mode = getMode(minSelections, maxSelections);
	const { selectedIndexes } = ffMultiSelectProps;

	const revealDoneButton =
		hasConfirmationStep && isSelectionFinished && !isCompleted;

	useEffect(() => {
		if (isCompleted && refFeedback.current) {
			const delay = shouldCorrect ? correctDelay * options.length : 0.6;
			gsap.set([refFeedback.current], { autoAlpha: 0, rotateY: 180 });
			gsap.to([refFeedback.current], {
				autoAlpha: 1,
				rotateY: 0,
				duration: 0.5,
				delay,
			});

			cmtPageItem.show_next_button === "on done" &&
				setTimeout(() => {
					setShowNextButton(true);
				}, delay * 1000);
		} else {
			cmtPageItem.show_next_button === "on done" && setShowNextButton(false);
		}
	}, [
		isCompleted,
		cmtPageItem.show_next_button,
		refFeedback,
		shouldCorrect,
		correctDelay,
		options.length,
	]);

	useEffect(() => {
		if (cmtPageItem.show_next_button === "on select") {
			setShowNextButton(selectedIndexes.length ? true : false);
		}
	}, [selectedIndexes, cmtPageItem.show_next_button]);

	useEffect(() => {
		refFeedback.current && gsap.set([refFeedback.current], { autoAlpha: 0 });
	}, [refFeedback]);

	useEffect(
		function handleCompletionStatus() {
			const isCompleted = !hasConfirmationStep
				? isSelectionFinished
				: isSelectionFinished && hasConfirmedSelection;
			const prevStatus = pageItem.status;
			const nextStatus = isCompleted ? "completed" : "incomplete";
			nextStatus !== prevStatus &&
				PageItemActions.updatePageItem(pageItem.id, {
					completionStatus: isCompleted ? "completed" : "incomplete",
				});
		},
		[
			isCompleted,
			isSelectionFinished,
			hasConfirmedSelection,
			hasConfirmationStep,
			pageItem.id,
			pageItem.status,
		]
	);

	const getOptionColumnClassnames = () => {
		const columnClassnamesByName = {
			one: "col-md-12",
			two: "col-md-6",
			three: "col-md-4",
		};
		const name = cmtPageItem.number_of_option_columns;
		return columnClassnamesByName[name] || columnClassnamesByName["one"];
	};

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={showNextButton}
			className="px-0 mx-0"
			containerClassName="w-100"
			renderFunction={({ viewVisible }) => (
				<React.Fragment>
					{/* IMAGE */}
					{cmtPageItem.image && (
						<div
							className={classnames(
								imageMarginsMobile ? "container-fluid" : ""
							)}
						>
							<div
								className={classnames(
									"row m-0 mb-5 animated",
									viewVisible ? "fadeIn" : "fadeOut"
								)}
							>
								<Image
									src={cmtPageItem.image}
									className={classnames(
										imageMarginsMobile ? "col-12" : "w-100 px-sm-0 px-md-5"
									)}
									size={imageSize}
									maxHeight={maxHeight}
									align={imageAlign}
									style={{
										filter: shouldBlurImageBeforeAnswer
											? !isCompleted && "blur(20px)"
											: "blur(0)",
										transition:
											shouldBlurImageBeforeAnswer && `filter ${blurDuration}s`,
									}}
									children={undefined}
									autoSize={undefined}
									background={undefined}
									isZoomable={undefined}
								/>
							</div>
						</div>
					)}

					<div className="container-fluid">
						{/* TEXT BLOCK */}
						<div
							className={classnames(
								"row m-0 mb-5 animated",
								viewVisible ? "fadeIn" : "fadeOut"
							)}
						>
							{/* QUESTION */}
							<Text
								tagName="h2"
								textId={cmtPageItem.question}
								className="col-sm-12 mb-4 font-weight-bold"
							/>

							{/* INSTRUCTION */}
							<Text
								textId={instruction}
								className="col-sm-9 instruction font-italic"
							/>
						</div>
						{/* OPTIONS */}

						<Text
							textId={cmtPageItem.validation_tooltip}
							tagName="div"
							hideIfMissing={true}
							style={{
								position: "sticky",
								top: isShowingTopBar ? 50 : 0,
								backgroundColor: "black",
								color: "white",
								padding: "1rem",
								marginBottom: "1rem",
								zIndex: 5,
							}}
							variables={{
								selections: ffMultiSelectProps.selectedIndexes.length,
								minSelections: ffMultiSelectProps.minSelections,
								maxSelections: ffMultiSelectProps.maxSelections,
							}}
						/>

						<FFMultiSelect
							{...ffMultiSelectProps}
							className={classnames("row m-0")}
							buttonClassName={classnames("mb-4", getOptionColumnClassnames())}
						>
							{options.map((option, index) => {
								const status =
									(isCompleted &&
										shouldCorrect &&
										option.isSelected &&
										option.isCorrect &&
										"selected-correct") ||
									(isCompleted &&
										shouldCorrect &&
										option.isSelected &&
										!option.isCorrect &&
										"selected-incorrect") ||
									(isCompleted &&
										shouldCorrect &&
										!option.isSelected &&
										option.isCorrect &&
										"unselected-correct") ||
									(isCompleted &&
										shouldCorrect &&
										!option.isSelected &&
										!option.isCorrect &&
										"unselected-incorrect") ||
									undefined;
								// console.log({ completed, correct, selected, isCorrect, status });
								return (
									<SelectItem
										key={`multi-choice-${index}`}
										mode={mode}
										index={index}
										correct={cmtPageItem.correct !== "no"}
										correctDelay={correctDelay}
										textId={option.option}
										bodyTextId={option.option_description}
										image={option.image}
										selected={option.isSelected}
										status={status}
										completed={isCompleted}
										className={classnames(
											"animated",
											viewVisible ? "fadeInUp" : "fadeOut"
										)}
										style={{ animationDelay: index * 0.15 + "s" }}
										text={undefined}
									/>
								);
							})}
						</FFMultiSelect>
						{/* DONE BUTTON */}
						<div
							className={classnames(
								"d-flex flex-column align-items-center",
								!hasConfirmationStep && "d-none",
								revealDoneButton ? Styles.reveal : Styles.unreveal
							)}
						>
							{/* @ts-expect-error FFButton's auto generated types are wonky */}
							<FFButton
								className={classnames(
									"btn btn-sm btn-black rounded-pill col-sm"
								)}
								onClick={() => {
									setHasConfirmedSelection(true);
								}}
							>
								<FFText
									textId="D24115A3-B9A4-4B40-90F1-5E1213241A84"
									className={classnames(
										"text-uppercase text-white font-weight-bold"
									)}
								/>
							</FFButton>
						</div>

						{/* ANSWER - Shows if COMPLETED and if there is feedback text */}
						<div ref={refFeedback}>
							<SimpleReveal
								reveal={Boolean(
									isCompleted &&
										(getText(cmtPageItem.feedback_correct) ||
											getText(cmtPageItem.feedback_incorrect))
								)}
								className="row m-0 mt-2"
							>
								<Text
									className={classnames(
										"col-9 p-md",
										isCompleted ? "animated fadeInUp" : "opacity-0"
									)}
									textId={
										isCorrect
											? cmtPageItem.feedback_correct
											: cmtPageItem.feedback_incorrect
									}
								/>
							</SimpleReveal>
						</div>
					</div>
				</React.Fragment>
			)}
		/>
	);
};

export default PageItemIOMultipleChoiceInput;
