import React from "react";
import classnames from "classnames";
import { FFButton, Actions, StoreUtils } from "funkis-foundation";
import IconPrint from "../../assets/svg/IconPrint";
import IconArrow from "../../assets/svg/IconArrow";

import styles from "./TopBarPrint.module.css";
import Text from "./Text";
import Hover from "./Hover";
import { getBootstrapColor } from "../../utils/colorUtils";
import _ from "lodash";

const { print, undoLocation } = Actions;

const TopBarPrint = (props) => {
	const { menuPosition } = props;

	return (
		<div
			className={classnames(
				styles.topBar,
				"no-print container-fluid border-bottom"
			)}
		>
			<div
				className={classnames(
					"h-100 d-flex justify-content-between align-items-center px-5"
				)}
			>
				{/* BACK BUTTON */}

				<Hover className={classnames(styles.menuButton, "back-button")}>
					{({ hover }) => (
						<FFButton
							onClick={() => {
								const hasPreviousLocation = _.get(
									StoreUtils.getReducer("location"),
									"pastItems[0]"
								);
								if (hasPreviousLocation) {
									undoLocation();
								} else {
									window.history.back();
								}
							}}
							className="d-flex align-items-center"
						>
							<IconArrow
								style={{ height: 50 }}
								type="lines"
								color={getBootstrapColor(hover ? "interaction" : "black")}
							/>
							<Text
								tagName="h4"
								textId="499B10D5-CFF0-4665-A070-05A4BEDCEB3E"
								className="text-uppercase font-weight-bold mx-3"
								style={{
									color: getBootstrapColor(hover ? "interaction" : "black"),
								}}
							/>
						</FFButton>
					)}
				</Hover>

				{/* PRINT BUTTON */}
				<Hover
					className={classnames(
						styles.menuButton,
						menuPosition === "right" && styles.menuButtonRight,
						"menu-button"
					)}
				>
					{({ hover }) => (
						<FFButton onClick={print} className="d-flex align-items-center">
							<Text
								tagName="h4"
								textId="FD4798BC-FC24-4D87-8AEC-2B33D2D7126A"
								className="text-uppercase font-weight-bold mx-4"
								style={{
									color: getBootstrapColor(hover ? "interaction" : "black"),
								}}
							/>
							<IconPrint
								style={{ height: 50, width: 50 }}
								size="md"
								color={getBootstrapColor(hover ? "interaction" : "black")}
							/>
						</FFButton>
					)}
				</Hover>
			</div>
		</div>
	);
};

export default TopBarPrint;
