import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Pages from "./Pages";
import PageItems from "./PageItems";

class Layer extends React.Component {
	constructor(props) {
		super(props);
	}

	static propTypes = {
		id: PropTypes.string.isRequired,
		page: PropTypes.object.isRequired,
		locationPath: PropTypes.array.isRequired,
	};

	static defaultProps = {};

	render() {
		const { id, location, locationPath, page } = this.props;
		const { layerClassNames } = page;
		return (
			<div className={classNames("layer", `layer-${id}`, layerClassNames)}>
				<PageItems
					location={location}
					locationPath={locationPath}
					parentPageId={page.id}
					layer={id}
				/>
				<Pages
					location={location}
					locationPath={locationPath}
					parentPageId={page.id}
					layer={id}
				/>
			</div>
		);
	}
}

export default Layer;
