import React from "react";
import _ from "lodash";
import classnames from "classnames";
import { connect } from "../../utils/reduxUtils";
import {
	AppActions,
	ConfigActions,
	Actions,
	StoreUtils,
} from "funkis-foundation";
import { getSizeObjectForSize } from "utils/ConfigUtils";
import LightBox from "./LightBox";
import Text from "./Text";
import DefaultButton from "../slide/DefaultButton";
import { resetStores } from "../../actions/Actions";

const { updateLocation } = Actions;

const globalTextIdsByFunction = {
	promptHeader: "5746F519-BCBC-450E-BB63-99FD32B1F3C1",
	promptBody: "3C789352-C28B-41C0-B5F4-B30F213E97BE",
	promptButtonContinueLabel: "90946F42-DF5E-44AD-A411-32749891B564",
	promptButtonRestartLabel: "653E8468-56D1-4A8B-9112-F67CCFC77C6B",
};

const PromptResumeOrRestart = ({
	restoreLocation,
	showResumeOrRestart,
	hasShownResumeOrRestart,
}) => {
	return (
		<LightBox
			skipUrlChange={true}
			show={!hasShownResumeOrRestart && showResumeOrRestart && restoreLocation}
			onCloseClick={() =>
				AppActions.updateAppValue({ hasShownResumeOrRestart: true })
			}
			className={classnames("prompt-resume-or-restart")}
			classNameContentContainer={classnames(
				"align-items-stretch flex-grow-1 prompt-resume-or-restart-content-container"
			)}
		>
			<Text
				className="message-lg flex-grow-0 mt-4"
				textId={globalTextIdsByFunction.promptHeader}
				tagName="h2"
			/>
			<Text
				className="p-md d-block flex-grow-0 mb-6"
				textId={globalTextIdsByFunction.promptBody}
				tagName="span"
			/>
			<div className="prompt-resume-or-restart-buttons w-100 d-flex flex-column flex-grow-1 justify-content-end align-items-center">
				{/* CONTINUE */}
				<DefaultButton
					style={{ width: "400px" }}
					className="mb-3"
					onClick={() => {
						updateLocation(restoreLocation);
						AppActions.updateAppValue({ hasShownResumeOrRestart: true });
						// scroll to bottom of page...
						setTimeout(() => {
							window.scrollTo(0, document.body.scrollHeight);
						}, 1500);
					}}
				>
					<Text textId={globalTextIdsByFunction.promptButtonContinueLabel} />
				</DefaultButton>
				{/* RESTART */}
				<DefaultButton
					style={{ width: "400px" }}
					onClick={() => {
						// remember the choosen language before resetting
						const language = _.get(
							StoreUtils.getReducer("config"),
							"item.language"
						);
						// remember the "calculated" backgroundColor before resetting
						const backgroundColor = _.get(
							StoreUtils.getReducer("app"),
							"item.backgroundColor"
						);
						resetStores();
						// uggly fix - resetting stores may reset an invalid app.size value. update this manually
						const config = _.get(StoreUtils.getReducer("config"), "item");
						const size =
							getSizeObjectForSize(
								config,
								window.innerWidth,
								window.innerHeight
							) || {};
						AppActions.updateAppValue({
							hasShownResumeOrRestart: true,
							size,
							backgroundColor,
						});
						ConfigActions.updateConfigValue({ language });
					}}
				>
					<Text textId={globalTextIdsByFunction.promptButtonRestartLabel} />
				</DefaultButton>
			</div>
		</LightBox>
	);
};

export default connect(PromptResumeOrRestart, function mapStateToProps(state) {
	return {
		hasShownResumeOrRestart: _.get(
			state,
			"app.item.hasShownResumeOrRestart",
			false
		),
		restoreLocation: _.get(state, "app.item.restoreLocation"),
		showResumeOrRestart:
			_.get(state, "app.item.show_continue_or_restart_dialog") === "yes",
	};
});
