import _ from "lodash";
import { useSelector } from "react-redux";

function selectGlobalTextVariables(state: any) {
	const sessionName = _.get(state, "quiddity.item.session.title", "");

	const memberFirstName = _.get(
		state,
		"quiddity.item.member.user.firstName",
		""
	);
	const memberLastName = _.get(state, "quiddity.item.member.user.lastName", "");
	const memberName = `${memberFirstName} ${memberLastName}`;

	const groupName = _.get(state, "quiddity.item.group.name", "");

	return {
		sessionName,
		memberFirstName,
		memberLastName,
		memberName,
		groupName,
	};
}

export function useGlobalTextVariables() {
	const globalTextVariables = useSelector(selectGlobalTextVariables, _.isEqual);
	return globalTextVariables;
}
