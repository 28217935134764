import classnames from "classnames";
import {
	FFButton,
	PageItemImage,
	StoreUtils,
	WindowResizeHOC,
} from "funkis-foundation";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { getLocalizedMediaPath, getMediaType } from "utils/MediaUtils";
import { makeGetFirstPage } from "../../../selectors/playerSelectors";
import { updateLocation } from "../../actions/Actions";
import ImageVideoOrDiv from "../../components/core/ImageVideoOrDiv";
import Text from "../../components/core/Text";
import Page from "../../components/scroll/Page";
import Logo from "../../components/slide/Logo";
import MessageTexts from "../../components/slide/MessageTexts";
import { useMediaPlayabilityStatus } from "../../hooks/media";
import { render } from "../../RenderFunctions";
import { connect } from "../../utils/reduxUtils";
import Style from "./PageIntro.module.css";

let fadeOutTimeOutRef;
let updateLocationTimeOutRef;

type SplashStyleDataSection = {
	textContainer?: React.CSSProperties;
	logo?: React.CSSProperties;
	header?: React.CSSProperties;
	subHeader?: React.CSSProperties;
	mediaWrapper?: React.CSSProperties;
	metaData: {
		resolution: {
			height: number;
			width: number;
		};
	};
};

type SplashStyleData = {
	landscape: SplashStyleDataSection;
	portrait: SplashStyleDataSection;
};

// SCROLL INTRO ////////////
const PageIntroScroll = (props) => {
	const {
		app,
		window,
		fadeOut,
		onClick,
		canAutoplayVideoWithSound,
		hasSplashHeaderOverride,
		hasSplashSubHeaderOverride,
	} = props;
	const splashShowLogo = app.splash_show_logo === "yes";

	// old property name was app.splash_bg_image
	// can now be
	// app.splash_bg_media
	// app.splash_bg_portrait_media
	const mediaLandscape = app.splash_bg_media || app.splash_bg_image;
	const mediaPortait =
		app.splash_bg_portrait_media || mediaLandscape || app.splash_bg_image;

	const tabletWidthHeightRatio = 0.73;
	/* 
    "portrait" is technically just window.height >= window.width
    but when using this, tablets (or tablet-like desktop ratios) ratios between 1 and 'tabletWidthHeightRatio',
    it looks strange if the adopt the mobile style

    So we wait until the ratio is lower than 'tabletWidthHeightRatio' before we switch.
  */
	const ratio = window.width / window.height;
	const isPortrait =
		window.height >= window.width && ratio < tabletWidthHeightRatio;

	const backgroundMedia = isPortrait ? mediaPortait : mediaLandscape;
	const backgroundMediaType = getMediaType(backgroundMedia);

	let splashStyleData: SplashStyleData = {
		landscape: { metaData: { resolution: { height: 1920, width: 1080 } } },
		portrait: { metaData: { resolution: { height: 1920, width: 1080 } } },
	};
	try {
		splashStyleData = eval(`(${app.splash_styles_object})`);
	} catch (error) {
		console.error(error);
		console.error(
			"Could not parse app.splash_styles_object. This is likely due to a syntax error. "
		);
		console.log("app.splash_styles_object", app.splash_styles_object);
	}

	const landscapeOrPortrait = !isPortrait ? "landscape" : "portrait";
	const originalScale = _.get(
		splashStyleData[landscapeOrPortrait],
		"metaData.resolution"
	);
	const styleProps = splashStyleData[landscapeOrPortrait];
	const { scale } = { scale: window.width / originalScale.width };

	const headerTextId = !hasSplashHeaderOverride
		? app.title_txt_id
		: app.splash_header_override_text;
	const subheaderTextId = !hasSplashSubHeaderOverride
		? app.sub_title_1_txt_id
		: app.splash_header_sub_override_text;

	return render(
		"splash",
		{ ...props },
		<Page {...props}>
			{/* @ts-expect-error FFButton's auto generated types are wonky */}
			<FFButton
				onClick={onClick}
				className={classnames(
					"splash-wrapper-button d-flex animated",
					`splash-mode-${landscapeOrPortrait}`,
					fadeOut ? "fadeOut delay-1000ms" : "fadeIn",
					Style.scrollWrapperButton
				)}
			>
				<div
					className={classnames("absolute-top h-100 animated")}
					style={{
						width: "100vw",
						height: "100vh",
						...styleProps.mediaWrapper,
						pointerEvents: "none",
					}}
				>
					{backgroundMediaType === "image" && (
						<PageItemImage
							className={classnames(
								Style.scrollBackgroundImage,
								Style.backgroundImageCoverWidth,
								"splash-background-media"
							)}
							pageItem={{
								src: backgroundMedia,
								backgroundImage: true,
								width: "100%",
								height: "100%",
							}}
						/>
					)}

					{backgroundMediaType === "video" &&
						canAutoplayVideoWithSound !== undefined && (
							<video
								className={classnames(
									"splash-video-player",
									`video-muted-${!canAutoplayVideoWithSound}`
								)}
								loop={true}
								style={{ objectFit: "contain", width: "100%", height: "auto" }}
								src={getLocalizedMediaPath(backgroundMedia)}
								muted={!canAutoplayVideoWithSound}
								autoPlay
								playsInline={true}
							></video>
						)}
				</div>
				{/* TITLE CONTAINER */}
				<div className="absolute-top w-100 h-100 splash-scaled-container-wrapper">
					<div
						className="splash-scaled-container"
						style={{
							transform: `scale(${scale})`,
							transformOrigin: "top left",
						}}
					>
						<div
							className="title-container absolute-top d-flex flex-column d-flex"
							style={{
								height: styleProps.metaData.resolution.height,
								width: styleProps.metaData.resolution.width,
							}}
						>
							{/* TITLE INNER CONTAINER */}
							<div
								className={classnames(
									"title-inner-container animated delay-1500ms animated",
									fadeOut ? "fadeOut" : "zoomIn"
								)}
								style={{
									display: "flex",
									position: "relative",
									flexDirection: "column",
									...styleProps.textContainer,
								}}
							>
								<Text
									tagName="span"
									textId={headerTextId}
									className={
										"splash-sm-title-header animated fadeInUp delay-2000ms"
									}
									style={{
										...styleProps.header,
									}}
								/>
								<Text
									tagName="span"
									textId={subheaderTextId}
									className={
										"splash-sm-title-subheader animated fadeInUp delay-2500ms"
									}
									style={{
										...styleProps.subHeader,
									}}
								/>
							</div>

							{splashShowLogo && (
								<Logo
									logoOverride={app.splash_logo_override}
									backgroundImage
									ignoreLogoPositionSetting
									className={Style.scrollSplashLogoContainer}
									classNameImage={Style.scrollSplashIcon}
									style={styleProps.logo}
								/>
							)}
						</div>
					</div>
				</div>
			</FFButton>
		</Page>
	);
};

// SLIDE INTRO ////////////
const PageIntroSlide = (props) => {
	const {
		app,
		fadeOut,
		onClick,
		hasSplashHeaderOverride,
		hasSplashSubHeaderOverride,
	} = props;
	const splashTextColor = app.splash_text_color || "black";
	const splashShowLogo = app.splash_show_logo === "yes";

	const headerTextId = !hasSplashHeaderOverride
		? app.title_txt_id
		: app.splash_header_override_text;
	const subheaderTextId = !hasSplashSubHeaderOverride
		? app.sub_title_1_txt_id
		: app.splash_header_sub_override_text;
	return render(
		"splash",
		props,
		<Page {...props}>
			{/* @ts-expect-error FFButton's auto generated types are wonky */}
			<FFButton onClick={onClick}>
				<ImageVideoOrDiv
					src={app.splash_bg_media || app.splash_bg_image}
					className={classnames(
						Style.slideBackgroundImage,
						"background-image animated",
						fadeOut ? "fadeOut delay-1000ms" : "fadeIn"
					)}
				>
					{/* @ts-expect-error FFButton's auto generated types are wonky */}
					<MessageTexts
						headerTextId={headerTextId}
						bodyTextId={subheaderTextId}
						headerAlign="center"
						bodyAlign="center"
						headerColor={splashTextColor}
						bodyColor={splashTextColor}
						textContainerColor={app.splash_text_background_color || "black"}
						textContainerOpacity={app.splash_text_background_opacity || 0}
						bodySize="md"
						className="animated fadeIn delay-500ms"
						classNameHeader="font-weight-bold"
						classNameBody="font-weight-bold animated flipInX delay-1000ms"
						textContainerWidth="1920px"
						textContainerPadding="0px 0px"
					/>
				</ImageVideoOrDiv>
				{splashShowLogo && <Logo logoOverride={app.splash_logo_override} />}
			</FFButton>
		</Page>
	);
};

const PageIntro = (props) => {
	const { page, firstPage, isDevMode } = props;
	const [fadeOut, setFadeOut] = useState(false);
	const app = StoreUtils.getReducer("app").item;

	// seconds. splash_duration has a default value of 6 from FAT
	const shouldSkipSplash = app.splash_duration === 0;
	let duration = app.splash_duration;

	const { canAutoplayVideoWithSound } = useMediaPlayabilityStatus({
		skip: shouldSkipSplash,
	});

	const navigate = useCallback(() => {
		const isFirstVisit = ["not_attempted", "unlocked", "locked"].includes(
			firstPage.status
		);

		const hasSkipMenuSetting = app.skip_menu === "yes";
		const explicitSkipBehavior = app.skip_menu_behavior || "once";

		const hasAlwaysSkipBehavior =
			hasSkipMenuSetting && explicitSkipBehavior === "always";

		const shouldSkipBecauseFirstVisit = hasSkipMenuSetting && isFirstVisit;

		const skipMenu =
			hasSkipMenuSetting &&
			(hasAlwaysSkipBehavior || shouldSkipBecauseFirstVisit);
		if (skipMenu) {
			updateLocation({
				friendlyId: firstPage.friendlyId,
				pageId: undefined,
				path: undefined,
			});
		} else {
			updateLocation({
				pageId: page.id,
				path: [{ sibling: 1 }],
				friendlyId: undefined,
			});
		}
	}, [firstPage, app, page]);

	useEffect(() => {
		if (shouldSkipSplash) return;

		fadeOutTimeOutRef = setTimeout(() => {
			setFadeOut(true);
		}, duration * 1000 - 1500);

		updateLocationTimeOutRef = setTimeout(() => {
			navigate();
		}, duration * 1000);

		return () => {
			if (shouldSkipSplash) return;

			clearTimeout(updateLocationTimeOutRef);
			clearTimeout(fadeOutTimeOutRef);
		};
	}, [shouldSkipSplash, duration, navigate]);

	// auto-navigate instantly if splash should be skipped
	useEffect(() => {
		if (shouldSkipSplash) {
			navigate();
		}
	}, [shouldSkipSplash, navigate]);

	const onClick = () => {
		setFadeOut(true);
		setTimeout(() => {
			navigate();
		}, 1500);
	};

	if (shouldSkipSplash) return null;

	if (app.program_type === "slide") {
		return (
			<PageIntroSlide
				{...props}
				app={app}
				fadeOut={fadeOut}
				onClick={onClick}
				canAutoplayVideoWithSound={canAutoplayVideoWithSound}
			/>
		);
	} else {
		return (
			<PageIntroScroll
				{...props}
				app={app}
				fadeOut={fadeOut}
				onClick={onClick}
				canAutoplayVideoWithSound={canAutoplayVideoWithSound}
			/>
		);
	}
};

const makeMapStateToProps = () => {
	const getFirstPage = makeGetFirstPage();

	const mapStateToProps = (state, props) => {
		const textsById = _.get(state, "texts.itemsById");
		const hasSplashHeaderOverride = _.get(
			textsById,
			`[${_.get(state, "app.item.splash_header_override_text")}]`
		);
		const hasSplashSubHeaderOverride = _.get(
			textsById,
			`[${_.get(state, "app.item.splash_header_sub_override_text")}]`
		);

		return {
			firstPage: getFirstPage(state, props),
			isDevMode: _.get(state, "config.item.devMode", false),
			hasSplashHeaderOverride,
			hasSplashSubHeaderOverride,
		};
	};
	return mapStateToProps;
};

export default WindowResizeHOC(connect(PageIntro, makeMapStateToProps));
