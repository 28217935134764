import React from "react";
import { FFText, PageItemMenuButton } from "funkis-foundation";

const PageItemPageButton = (props) => {
	const { pageItem } = props;
	const { cmtPage } = pageItem;
	return (
		<PageItemMenuButton pageItem={{ ...pageItem, pageId: cmtPage.id }}>
			<FFText tagName="h1" textId={cmtPage.title_txt_id} />
		</PageItemMenuButton>
	);
};

export default PageItemPageButton;
