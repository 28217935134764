import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	removeItemInStateItems,
	removeItemsInStateItems,
	resetItemsInStateItems,
	addItemInStateItems,
	changeIndexForItemInStateItems,
	makeStateSnapshot,
	restoreStateSnapshot,
} from "../utils/reducerUtils";

import {
	UPDATE_PAGE_ITEMS,
	UPDATE_PAGE_ITEM,
	ADD_PAGE_ITEM,
	REMOVE_PAGE_ITEM,
	REMOVE_PAGE_ITEMS,
	RESET_PAGE_ITEMS,
	CHANGE_INDEX_FOR_PAGE_ITEM,
	MAKE_PAGE_ITEMS_SNAPSHOT,
	RESTORE_FROM_PAGE_ITEMS_SNAPSHOT,
} from "../constants/ActionConstants";

const initialState = initialStateObjectCollection();

const pageItems = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_PAGE_ITEMS:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_PAGE_ITEM:
			return updateItemInStateItems(state, action.item);
		case ADD_PAGE_ITEM:
			return addItemInStateItems(state, action.item);
		case REMOVE_PAGE_ITEM:
			return removeItemInStateItems(state, action.item);
		case REMOVE_PAGE_ITEMS:
			return removeItemsInStateItems(state, action.item);
		case RESET_PAGE_ITEMS:
			return resetItemsInStateItems(initialState);
		case CHANGE_INDEX_FOR_PAGE_ITEM:
			return changeIndexForItemInStateItems(state, action.item, action.toIndex);
		case MAKE_PAGE_ITEMS_SNAPSHOT:
			return makeStateSnapshot(state, action.item);
		case RESTORE_FROM_PAGE_ITEMS_SNAPSHOT:
			return restoreStateSnapshot(state, action.item);
		default:
			return state;
	}
};

export default pageItems;
