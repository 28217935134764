import React, { useState, useEffect } from "react";
import { StoreUtils } from "funkis-foundation";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import Backend from "../../components/backend/Backend";
import classnames from "classnames";
import Text from "../../components/core/Text";
import { PageItemActions } from "funkis-foundation";
import Bullet from "../../components/core/Bullet";
import styled from "styled-components";
import { getBootstrapColor } from "../../utils/colorUtils";

const { updatePageItem } = PageItemActions;

const ListItem = styled.li`
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	cursor: pointer;
	color: ${({ color }) => getBootstrapColor(color)};
	:hover {
		color: ${({ color }) => getBootstrapColor("interaction" || color)};
	}
`;

const PageItemIOMediaUploadOutput = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;
	const quiddity = StoreUtils.getReducer("quiddity").item;
	const groups = StoreUtils.getReducer("groups").items;
	const { url } = quiddity;

	const [showNextButton, setShowNextButton] = useState(
		cmtPageItem.show_next_button === "always"
	);
	const [selectedQuidInput, setSelectedQuidInput] = useState();
	const videoSource =
		selectedQuidInput &&
		url + "/uploads/" + selectedQuidInput?.data?.files[0]?.filename;
	const videoComment =
		selectedQuidInput &&
		cmtPageItem.show_comments === "yes" &&
		selectedQuidInput?.data.comment;

	useEffect(() => {
		if (
			cmtPageItem.show_next_button === "always" ||
			((cmtPageItem.show_next_button === "on-video-end" ||
				cmtPageItem.show_next_button === "auto") &&
				pageItem.status === "completed")
		) {
			setShowNextButton(true);
		} else {
			setShowNextButton(false);
		}
	}, [pageItem.status, cmtPageItem.show_next_button]);

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={showNextButton}
			renderFunction={(renderProps) => (
				<div className="row m-0">
					<Text
						textId={cmtPageItem.header}
						tagName="h2"
						className={classnames(
							"col-sm-12 mb-4 mb-sm-5 font-weight-bold animated",
							renderProps.viewVisible ? "fadeIn" : "fadeOut"
						)}
					/>
					<Text
						className={classnames(
							"mb-6 col-sm-9 animated",
							renderProps.viewVisible ? "fadeIn delay-250ms" : "fadeOut"
						)}
						tagName="p"
						textId={cmtPageItem.body}
					/>
					<Backend
						{...props}
						className="col-sm-12"
						quidType="formData"
						mode="bareOutputs"
						targetDataKey={cmtPageItem.io_id || cmtPageItem.id}
						inputContext={cmtPageItem.io_context}
						renderFunction={(backendRenderProps) => {
							const { quidInputs } = backendRenderProps;
							return (
								<ul className="list-style-none p-0 m-0">
									{quidInputs.map((quidInput, index) => {
										const { data } = quidInput;

										const videoUrl =
											data.files && url + "/uploads/" + data.files[0].filename;
										const groupName = groups.find(
											(group) => quidInput.group === group.id
										)?.name;
										const label =
											groupName +
											" - " +
											(data.videoName ? data.videoName : "");

										return (
											<ListItem
												key={quidInput.id}
												color={
													videoSource === videoUrl ? "interaction" : "black"
												}
												className={classnames(
													"animated mb-3",
													renderProps.viewVisible ? "fadeInUp" : "fadeOut"
												)}
												style={{ animationDelay: 0.5 + index * 0.15 + "s" }}
												onClick={() => {
													setSelectedQuidInput(quidInput);
												}}
											>
												<div className="d-flex">
													<Bullet
														image={cmtPageItem.bullet}
														color={cmtPageItem.bullet_color || "primary"}
														size={cmtPageItem.bullet_size || 19}
													/>
												</div>
												<div className={"ml-2 pt-0"}>
													<span className="p-medium font-weight-bold text-decoration-underline">
														{label}
													</span>
												</div>
											</ListItem>
										);
									})}
								</ul>
							);
						}}
					/>
					{selectedQuidInput && (
						<React.Fragment>
							<div className="col-sm-12 px-0 px-md-3 px-lg-2 mb-5">
								<video
									src={videoSource}
									className="w-100"
									controls={true}
									onEnded={() => {
										updatePageItem(pageItem.id, { status: "completed" });
									}}
								/>
							</div>

							{videoComment && (
								<p
									className={classnames(
										"p-medium col-sm-9 animated",
										renderProps.viewVisible ? "fadeIn delay-250ms" : "fadeOut"
									)}
								>
									{videoComment}
								</p>
							)}
						</React.Fragment>
					)}
				</div>
			)}
		></PageItemScrollBase>
	);
};

export default PageItemIOMediaUploadOutput;
