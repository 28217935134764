import _ from "lodash";
import { TinyColor } from "@ctrl/tinycolor";
import { getReducer } from "./StoreUtils";

/**
 * @param  {} elementSelector Any valid css selector. eg. "#some_id" or ".some-class" or "sometagname"
 * @param  {} classToRemove the old class to remove - use empty string if you just want to add a new class
 * @param  {} classToAdd the new class to add
 */
export function replaceClassOnElement({
	elementSelector,
	classToRemove = "",
	classToAdd,
}) {
	const element = document.querySelector(elementSelector);

	const allOldClassNames = Array.from(element.classList);
	const allWithoutOldClass = allOldClassNames.filter(
		(className) => className != classToRemove
	);
	const allIncludingNewLangCode = allWithoutOldClass.concat(classToAdd);

	element.className = allIncludingNewLangCode.join(" ");
}

export function insertStyleTagInHead(cssText) {
	const styleEl = document.createElement("style");
	styleEl.type = "text/css";
	styleEl.appendChild(document.createTextNode(cssText));
	document.head.appendChild(styleEl);
}

function generateCommonBootstrapClasses(
	colorsByKeyword = { primary: "red", secondary: "blue" }
) {
	let style = "";
	for (let keyword of Object.keys(colorsByKeyword)) {
		const color = colorsByKeyword[keyword];
		const colorObj = new TinyColor(color);
		if (colorObj.isValid == false) {
			console.error(
				`generateCommonBootstrapClasses() color for keyword "${keyword}" is INVALID color ${color}. Will not generate classes`
			);
			return;
		}
		/* TODO: find out what properties are actually used */
		style += `
    /* generated ${keyword} classes */
    .border-${keyword} { border-color:${color} !important; }
    .bg-${keyword} { background-color:${color} !important; }
    .text-${keyword} {color:${color} !important; }
    
    ${[10, 20, 30, 40, 50, 60, 70, 80, 90]
			.map(
				(num) =>
					`.bg-${keyword}-${num} { background-color:${new TinyColor(
						color
					).setAlpha(num / 100)}!important;}`
			)
			.join("\n")}
    .btn-${keyword}-inverted:hover,
    .btn-${keyword}-inverted:focus,
    .btn-${keyword}-inverted:active { background-color:${color}!important; transition: background 0.2s!important; }
    `;
	}
	// always add these styles once
	let { primary, interaction } = colorsByKeyword;
	interaction = interaction || primary;
	style += `
  .btn-black:hover { background-color: ${interaction}!important; border-color: ${interaction}!important; }
  .btn-white:hover { background-color: ${interaction}!important; border-color: ${interaction}!important; }
  `;
	return style;
}

/**
 *
 * @param {Object} colorsByKeyword
 *
 * For example:
 *
 * Using colorsByKeyword = { primary: "red", secondary: "green" }
 * will create and insert classes like .bg-primary { background: red!important; }  and .border-secondary { border-color: red!important; }
 */
export function insertStylesForThemeColors(colorsByKeyword) {
	if (window.ipcRenderer) {
		return;
	}
	const styles = generateCommonBootstrapClasses(colorsByKeyword);
	insertStyleTagInHead(styles);
}

export function setDocumentTitleFromTextId(textId) {
	const title = _.get(
		getReducer("texts"),
		["itemsById", textId],
		document.title
	);
	window.document.title = title;
}
export function setDocumentTitle(text) {
	window.document.title = text || window.document.title;
}

/* Stolen from https://stackoverflow.com/a/27069089/4763083 */
export function checkIsInternetExplorer() {
	const ua = window.navigator.userAgent;
	if (ua.indexOf("Trident/7.0") > -1) return 11;
	else if (ua.indexOf("Trident/6.0") > -1) return 10;
	else if (ua.indexOf("Trident/5.0") > -1) return 9;
	else return 0; // not IE9, 10 or 11
}
