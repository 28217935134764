import _ from "lodash";
import { getQuiddityEvent } from "../requests/QuiddityEventRequests";
import { getQuidditySession } from "../requests/QuidditySessionRequests";
import {
	getMember,
	getMembers,
	createMemberFromCurrentUser,
	getMembersForSessionAndUnits,
	createMember,
} from "../requests/MemberRequests";
import { getCurrentUser } from "../requests/UserRequests";
import { getGroup, createGroup } from "../requests/GroupRequests";
import { getUnit } from "../requests/UnitRequests";
import {
	updateQuiddity,
	updateQuiddityValue,
} from "../redux/actions/QuiddityActions";

import { handleLoginError } from "../errors/errors";
import {
	MEMBER_NOT_FOUND,
	GROUP_NOT_FOUND,
	SESSION_NOT_FOUND,
	USER_NOT_FOUND,
} from "../constants/LoginErrorTypes";
import { updateMember } from "../redux/actions/MemberActions";
import { getQuiddityTitle } from "quiddity/requests/QuiddityTitleRequests";

export const loginMemberGroup = async ({
	url,
	api,
	code,
	onLoginSuccess,
	onLoginFail,
	getUserFromLoginDialog,
	allowAutoUpgradeOfUser = true,
	allowMissingGroup,
}) => {
	// Get user...
	const user =
		(await getCurrentUser(url + api)) ||
		(await getUserFromLoginDialog({ url: url + api }));
	if (!user) {
		handleLoginError(USER_NOT_FOUND);
		onLoginFail && onLoginFail({ error: USER_NOT_FOUND, user });
		return;
	} else {
		// if something goes wrong later, we can still read user value
		updateQuiddityValue({ user });
	}
	// Get session...
	const session = await getQuidditySession(url + api, { code });
	// !session.id is uggly fix for error handling that sends the error object
	if (!session || !session.id) {
		handleLoginError(SESSION_NOT_FOUND);
		onLoginFail && onLoginFail({ error: SESSION_NOT_FOUND });
		return;
	}
	// Get event...
	const event =
		session &&
		(await getQuiddityEvent(url + api, { id: session.quiddityEvent }));

	const title =
		event && (await getQuiddityTitle(url + api, { id: event.quiddityTitle }));
	// Get member for user...
	let member = await getMember(url + api, {
		user: user.id,
		quidditySession: session.id,
		populate: "user",
	});
	member = member
		? member
		: allowAutoUpgradeOfUser
		? await createMemberFromCurrentUser(url + api, code, { populate: "user" })
		: null;

	// Show alert message if member not found...
	if (!member || !member.id) {
		handleLoginError(MEMBER_NOT_FOUND);
		onLoginFail && onLoginFail({ error: MEMBER_NOT_FOUND });
		console.error(
			`Member for session could not be found! Please make sure to add user "${user.firstName} ${user.lastName}" to session "${session.title}!"`
		);
		updateQuiddityValue({ user: user });
		return;
	}

	// If an another selected member was selected to be used (in group select views)...
	const currentMember = _.get(member, "properties.selectedMember");
	if (currentMember) {
		member = await getMember(url + api, {
			id: currentMember,
			quidditySession: session.id,
			populate: "user",
		});
	}

	// TODO: REMOVE WHEN POPULATE IS SUPPRTED! If member not populate get the user!
	if (typeof member.user === "string") {
		member.user = user;
		updateMember(member.id, { user });
	}

	// Get group for member...
	const groupId = _.get(member, "group");
	if(!allowMissingGroup && !groupId) {
		// update quiddity because this data is used to display a relavant error message
		updateQuiddity({ session, user, member, group: null, event, title });
		return onLoginFail({error: GROUP_NOT_FOUND })
	}

	const maybeGroupForMember = groupId ? await getGroup(url + api, {
		id: _.get(member, "group"),
		quidditySession: _.get(session, "id"),
	}) : null;
	// Select the one that was found...
	const group = maybeGroupForMember;

	if (!member.group && maybeGroupForMember) {
		updateMember(member.id, { group: maybeGroupForMember.id });
	}

	// Get members for group...
	group &&
		(await getMembers(url + api, {
			group: group.id,
			quidditySession: session.id,
			populate: "user",
		}));

	// Update Quiddity reducer
	updateQuiddity({ session, user, member, group, event, title });

	if (user) {
		onLoginSuccess &&
			onLoginSuccess({ code, session, user, member, group, event, title });
	} else {
		onLoginFail && onLoginFail();
	}
};

export const loginUnitUserAndGroup = async ({
	url,
	api,
	code,
	onLoginSuccess,
	onLoginFail,
	getUserFromLoginDialog,
}) => {
	// Get user...
	const user =
		(await getCurrentUser(url + api)) ||
		(await getUserFromLoginDialog({ url: url + api }));
	if (!user) {
		handleLoginError(USER_NOT_FOUND);
		onLoginFail && onLoginFail({ error: USER_NOT_FOUND });
		return;
	}
	// Get session...
	const session = await getQuidditySession(url + api, { code });
	if (!session) {
		handleLoginError(SESSION_NOT_FOUND);
		onLoginFail && onLoginFail({ error: SESSION_NOT_FOUND });
		return;
	}
	// Get event...
	const event =
		session &&
		(await getQuiddityEvent(url + api, { id: session.quiddityEvent }));
	// Get member for user...
	const member =
		(await getMember(url + api, {
			user: user.id,
			quidditySession: session.id,
			populate: "user",
		})) ||
		(await createMemberFromCurrentUser(url + api, code, { populate: "user" }));
	// TODO: REMOVE WHEN POPULATE IS SUPPRTED! If member not populate get the user!
	if (typeof member.user === "string") {
		member.user = user;
		updateMember(member.id, { user });
	}
	// Get unit for user...
	const unit = await getUnit(url + api, {
		user: user.id,
		quidditySession: session.id,
	});
	// Get group for member...
	const groupForMember = await getGroup(url + api, {
		id: _.get(member, "group"),
		quidditySession: _.get(session, "id"),
	});
	// Get group for unit...
	const groupForUnit = await getGroup(url + api, {
		name: _.get(unit, "nodeId"),
		quidditySession: _.get(session, "id"),
	});
	// Create group for unit...
	const createdGroupForUnit = await createGroup(url + api, {
		name: _.get(unit, "name"),
	});
	// Select the one that was found...
	const group = groupForMember || groupForUnit || createdGroupForUnit;
	// Get members for group...
	group &&
		(await getMembers(url + api, {
			group: group.id,
			quidditySession: session.id,
			populate: "user",
		}));
	// Get members for session and unts...
	unit &&
		getMembersForSessionAndUnits(url + api, {
			session: session.id,
			units: [unit.id],
		});
	// Update Quiddity reducer
	updateQuiddity({ session, user, member, group, event, unit });

	if (user) {
		onLoginSuccess &&
			onLoginSuccess({ code, session, user, member, group, event });
	} else {
		onLoginFail && onLoginFail();
	}
};
