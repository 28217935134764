import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import { updateAppValue } from "../redux/actions/AppActions";
import { makeGetIsLocationSaveablePage } from "../../funkis-template/../selectors/playerSelectors";
import { useSelector } from "react-redux";

const PageDefault = ({ page, location, children }) => {
	const isLocationSaveablePageSelector = useMemo(
		makeGetIsLocationSaveablePage,
		[]
	);
	const isLocationSaveablePage = useSelector(isLocationSaveablePageSelector);
	useEffect(() => {
		if (isLocationSaveablePage) {
			updateAppValue({ location });
		}
	}, [location, isLocationSaveablePage]);

	return (
		<div
			className={classNames(
				"page page-default",
				`page-id-${page.friendlyId || page.id}`,
				page.className
			)}
			data-page-id={page.id}
			data-location-path={page.locationPath}
			data-page-name={page.name}
			style={page.style}
		>
			{children}
		</div>
	);
};

export default PageDefault;
