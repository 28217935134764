import React from "react";
import Backend from "../backend/Backend";
import _ from "lodash";
import { StoreUtils } from "funkis-foundation";
import { getBootstrapColor } from "../../utils/colorUtils";

const filterByPage = (quidInputs, pageId) => {
	if (pageId) {
		return quidInputs.filter((item) => _.get(item, "data.pageId") === pageId);
	} else {
		return quidInputs;
	}
};

const filterByChapter = (quidInputs, chapterId) => {
	if (chapterId) {
		return quidInputs.filter(
			(item) => _.get(item, "data.chapterId") === chapterId
		);
	} else {
		return quidInputs;
	}
};

const filterHighestScores = (quidInputs) => {
	if (!quidInputs || !quidInputs.length) {
		return 0;
	}
	const groups = _.groupBy(quidInputs, "targetDataKey");
	const groupKeys = _.keys(groups);

	const scoreItems = groupKeys.map((key) => {
		const item = groups[key];
		return item.reduce((prev, current) =>
			_.get(prev, "data.scoreRaw") > _.get(current, "data.scoreRaw")
				? prev
				: current
		);
	});

	const scores = scoreItems.map((item) => _.get(item, "data.scoreRaw", 0));
	const totalScore = scores.reduce((prev, current) => prev + current);
	return totalScore;
};

const IOScoreOutput = ({
	inputContext = "member",
	targetDataKey,
	chapterId,
	pageId,
	className,
}) => {
	return (
		<Backend
			className={className}
			quidType="result"
			inputContext={inputContext}
			throttle={true}
			throttleWait={5000}
			mode="bareOutputs"
			targetDataKey={targetDataKey}
			renderFunction={(renderProps) => {
				const { quidInputIds } = renderProps;
				const quidInputsById = StoreUtils.getReducer("quidInputs").itemsById;
				const quidInputs = quidInputIds.map((id) => quidInputsById[id]);
				const totalScore = filterHighestScores(
					filterByPage(filterByChapter(quidInputs, chapterId), pageId)
				);

				return (
					<span
						className="quiz-right font-weight-bold"
						style={{ color: getBootstrapColor("success") }}
					>
						{totalScore}
					</span>
				);
			}}
		/>
	);
};

export default IOScoreOutput;
