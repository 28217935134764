import React from "react";

function IconClose({
	className = "",
	color = "#000",
	crossFillColor = "#FFF",
}) {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="35"
			height="35"
			viewBox="0 0 35 35"
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<rect width="35" height="35" x="0" y="0" fill={color} rx="17.5"></rect>
				<g fill={crossFillColor} transform="translate(11.5 11.5)">
					<path d="M10.392 0l1.462 1.47L7.39 5.957l4.465 4.489-1.462 1.47-4.465-4.489-4.465 4.489L0 10.446l4.465-4.489L0 1.47 1.462 0l4.465 4.488L10.392 0z"></path>
				</g>
			</g>
		</svg>
	);
}

export default IconClose;
