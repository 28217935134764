import uuid from "uuid/v4";
import _ from "lodash";

import * as StoreUtils from "./StoreUtils";
import * as LocationUtils from "./LocationUtils";
import * as PageActions from "../redux/actions/PageActions";
import * as AppActions from "../redux/actions/AppActions";

let _pageSeeds = [];

export function addPageSeeds(pageSeeds, overwrite = true) {
	pageSeeds.forEach((pageItem) => {
		const index = _pageSeeds.findIndex((p) => {
			return p.type === pageItem.type;
		});
		if (index === -1) {
			_pageSeeds.push(pageItem);
		} else if (overwrite) {
			_pageSeeds[index] = pageItem;
		}
	});
}

export function getPageByPath(path, relativeToPageId) {
	const pages = StoreUtils.getReducer("pages").items;
	const pagesById = StoreUtils.getReducer("pages").itemsById;
	let page = pagesById[relativeToPageId];
	path.forEach((pathObj) => {
		const key = _.keys(pathObj)[0];
		switch (key) {
			case "friendlyId":
				page = pages.find((page) => page.friendlyId === pathObj[key]);
				break;
			case "parent":
				page = page && getParentPageForPageId(page.id, pathObj[key]);
				break;
			case "sibling":
				page = page && getSibling(page.id, pages, pathObj[key]);
				break;
			case "child":
				const pageChildren =
					page && getPageChildrenForPageId(page.id, pages, false);
				page = pageChildren && pageChildren[pathObj[key]];
				break;
		}
	});

	if (!page) {
		console.warn(`No page for path ${JSON.stringify(path)} were found!`);
	} else {
		// console.log(`Page ${page} found for path ${JSON.stringify(path)} were found!`);
	}
	return page;
}

export function getLocationPathForPage(pages, page) {
	let locationPath = [page.id];
	let parentPage = getParentPage(pages, page);
	while (parentPage) {
		locationPath.unshift(parentPage.id);
		parentPage = getParentPage(pages, parentPage);
	}
	return locationPath;
}

export function getLocationLevelForPage(pages, page) {
	return getLocationPathForPage(pages, page).length - 1;
}

export function getParentPage(pages, page) {
	return pages.find((p) => p.id === page.parentPageId);
}

export function createUniquePageItemId() {
	const pageStore = StoreUtils.getReducer("pages");
	let pageId = uuid();
	while (pageStore.items.find((item) => item.id === pageId)) {
		pageId = uuid();
	}
	return pageId;
}

export function getPageSeedByType(type) {
	return _pageSeeds.find((pageSeed) => pageSeed.type === type);
}

export function createPageFromType(type, props) {
	let page = this.getDefaultPageProps();
	page = Object.assign(
		{ type },
		page,
		this.getPageSeedByType(type).defaultProps,
		props
	);
	return page;
}

export function getDefaultPageProps() {
	return {
		friendlyId: "",
		name: "New page",
		type: "default",
		layer: "back",
		parentPageId: null,
		status: "not_attempted",
		layers: ["back", "mid", "front"],
		className: "",
		style: {},
		// winScroll: { scrollX: 0, scrollY: 0 }, // reserved
		winScrollBehaviour: "top", // null || "top" || "left" || "topLeft" || "persist"
		data: {},
	};
}

export function locationToPages(location, pages) {
	return locationToPage(location, pages);
}

function locationToPage(location, pages, path = []) {
	return location.map((loc) => {
		let result;
		for (let id in loc) {
			const locationPath = path.concat(id);
			const page = Object.assign(
				{},
				pages.find((p) => p.id === id),
				{ locationPath }
			);
			const children = locationToPage(loc[id], pages, locationPath);
			result = { id: page.id, page, children };
		}
		return result;
	});
}

export function getPageChildrenForPageItemId(pageId, pages, recursivly = true) {
	console.warn(
		`getPageChildrenForPageItemId is depricated please use "getPageChildrenForPageId"`
	);
	return getPageChildrenForPageId(pageId, pages, (recursivly = true));
}

export function getPageChildrenForPageId(pageId, pages, recursivly = true) {
	let pageChildren = pages.filter((page) => page.parentPageId === pageId);
	if (recursivly) {
		pageChildren = getPageChildrenForPagIdRecursivly(
			pageChildren,
			pages,
			pageChildren
		);
	}
	return pageChildren;
}

function getPageChildrenForPagIdRecursivly(pageChildren, pages, result = []) {
	pageChildren.forEach((pageChild) => {
		const foundPageChildren = pages.filter(
			(page) => page.parentPageId === pageChild.id
		);
		result = result.concat(foundPageChildren);
		if (foundPageChildren.length) {
			result = getPageChildrenForPagIdRecursivly(
				foundPageChildren,
				pages,
				result
			);
		}
	});
	return result;
}

export function getSibling(pageId, pages, offset = -1) {
	offset = Number(offset);
	const page = pages.find((pi) => pi.id === pageId);
	let siblings;

	if (page.parentPageId || page.parentId) {
		// sibling with the same parent
		siblings = pages.filter(
			(pi) =>
				(pi.parentPageId || pi.parentId) ===
				(page.parentPageId || page.parentId)
		);
	} else {
		// sibling with no parent (root)
		siblings = pages.filter((pi) => !pi.parentPageId && !page.parentId);
	}

	if (!siblings) {
		return undefined;
	}

	const pageSiblingIndex = siblings.findIndex((pi) => pi.id === page.id);
	if (
		pageSiblingIndex + offset >= 0 &&
		pageSiblingIndex + offset <= siblings.length - 1
	) {
		return siblings[pageSiblingIndex + offset];
	} else {
		return undefined;
	}
}

export function getRootPageForPageId(pageId) {
	let parentPage = getParentPageForPageId(pageId);
	let prevParentPage;
	while (parentPage) {
		prevParentPage = parentPage;
		parentPage = getParentPageForPageId(parentPage.id);
	}
	return prevParentPage;
}

export function getParentPageForPageId(pageId, offset = -1) {
	if (offset === 0) {
		return getRootPageForPageId(pageId);
	}

	const pagesById = StoreUtils.getReducer("pages").itemsById;
	const iterations = Math.abs(offset);
	let iteration = 0;
	let page = pagesById[pageId];
	while (iteration < iterations && page) {
		page = pagesById[page.parentPageId];
		iteration++;
	}
	return page;
}

export function getCurrentPage() {
	const location = StoreUtils.getReducer("location").item;
	const currentPages = LocationUtils.getPagesFromLocation(location);
	return _.last(currentPages);
}

export function saveScrollPositionForPage(page) {
	if (!page) {
		console.log(
			"saveScrollPositionForPage - no page specified, will use current page"
		);
		page = getCurrentPage();
	}

	PageActions.updatePage(page.id, {
		winScroll: { scrollY: window.scrollY, scrollX: window.scrollX },
	});
}

export function restoreScrollPositionForPage(page) {
	if (!page) {
		console.log(
			"saveScrollPositionForPage - no page specified, will use current page"
		);
		page = getCurrentPage();
	}

	const winScroll = Object.assign({ scrollX: 0, scrollY: 0 }, page.winScroll);
	setTimeout(() => {
		console.log("Restoring scroll position to ", winScroll);
		window.scrollTo(winScroll.scrollX, winScroll.scrollY);
	}, 100);
}

// Used for showing a print/result page with scroll blocks within a Slide production
export const imitateScrollProduction = () => {
	const pages = StoreUtils.getReducer("pages").items;
	const outerPage = pages.find((p) => p.type == "scalable");
	outerPage &&
		PageActions.updatePage(outerPage.id, {
			type: "default",
			height: undefined,
			width: undefined,
			isTempModifiedForSlidePrint: true,
		});
	AppActions.updateAppValue({
		program_type: "scroll",
		original_program_type: "slide",
	});
};

export const undoImitiateScrollProduction = () => {
	const pages = StoreUtils.getReducer("pages").items;
	const outerPage = pages.find((p) => p.isTempModifiedForSlidePrint);
	outerPage &&
		PageActions.updatePage(outerPage.id, {
			type: "scalable",
			height: 1080,
			width: 1920,
			isTempModifiedForSlidePrint: false,
		});
	AppActions.updateAppValue({ program_type: "slide" });
};
