import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { FFText, FFMultiSelect } from "funkis-foundation";
import RadioItem from "../RadioItem";
import TextWriter from "../TextWriter";
import { getBootstrapColor } from "../../../utils/colorUtils";
import ImageWithCaption from "../ImageWithCaption";
import Text from "../Text";
import { getText } from "../../../utils/textUtils";
import styled from "styled-components";

const StyledTitle = styled(Text)`
	p {
		font-weight: inherit;
		font-size: inherit;
		line-height: inherit;
	}
`;

const StorySprig = (props) => {
	const {
		sprigId,
		sprigStory,
		exits,
		onExitClick,
		onShow,
		onShowExit,
		selectedExitId,
		paragraphTextColor,
		animate,
		scrollIntoView = true,
		showParagraphTitles = false,
		titleId,
	} = props;
	const sprig = sprigStory.find((sp) => sp.id === sprigId);
	const [showExit, setShowExit] = useState(false);
	const [showImage, setShowImage] = useState(!animate);
	const media = sprig.media;
	const refExit = useRef(null);
	// Remove prvious selections
	// const exits = sprig.exits.filter((exit) => selectedExitId === exit.id || !prevSelectedExitIds.includes(exit.id));

	useEffect(() => {
		if (animate) {
			onShow && onShow(props);
			scrollIntoView && refExit.current.scrollIntoView(false);
		}
	}, [animate]);

	useEffect(() => {
		if (showExit) {
			onShowExit && onShowExit(props);
			scrollIntoView && refExit.current.scrollIntoView(false);
		}
	}, [showExit]);

	const hasTitleText = getText(sprig.txt_title_id);
	const shouldHideExits = animate && !showExit;
	return (
		<React.Fragment>
			{/* PARAGRAPH */}
			<div className="row m-0 mb-5 sprig-paragraph">
				<div className={classnames(media ? "col-md-6" : "col-md-9")}>
					{showParagraphTitles && hasTitleText && (
						<StyledTitle
							tagName="h4"
							className={classnames("font-weight-bold", {
								"animated fadeInUp": !selectedExitId,
							})}
							textId={titleId || sprig.txt_title_id}
						/>
					)}
					<TextWriter
						textId={sprig.txt_story_id}
						className="p-md parahraph-story-text"
						enabled={animate}
						startDelay={hasTitleText && showParagraphTitles ? 800 : 0}
						style={{ color: getBootstrapColor(paragraphTextColor) }}
						onStart={() => {
							setShowExit(false);
							setShowImage(true);
						}}
						onComplete={() => {
							setShowExit(true);
						}}
					/>
				</div>
				<ImageWithCaption
					animate={false}
					src={media}
					// maxHeight={imageMaxHeight}
					// style={{ order: imagePosition === "left" ? 1 : 2 }}
					className="col-md-6 "
					classNameImage={classnames("w-100 delay-500ms animate", {
						fadeInRight: showImage && animate,
					})}
					show={showImage}
					// size={imageSize}
					// caption={imageCaption}
				/>
			</div>
			{/* EXIT */}
			<div ref={refExit} className="row m-0 mb-1 align-items-end sprig-exit">
				<div
					className={classnames(
						"col-md-9 mb-6 ",
						animate && "animated",
						showExit && "fadeIn",
						shouldHideExits && "opacity-0 pointer-events-none",
						!sprig.exits.length && "d-none"
					)}
				>
					<FFMultiSelect
						maxSelections={1}
						minSelections={0}
						selectedIndexes={[]}
						buttonClassName="d-flex align-items-center mb-3"
						onSelect={(indexes) => {
							const index = indexes[0];
							const exit = sprig.exits[index];
							onExitClick({ sprig, exit });
						}}
						enabled={!shouldHideExits}
					>
						{exits.map((exit, index) => (
							<React.Fragment key={`sprig-item-${exit.id}`}>
								<RadioItem
									selected={exit.id === selectedExitId}
									className={classnames(
										selectedExitId && selectedExitId !== exit.id && "opacity-50"
									)}
								/>
								<FFText
									textId={exit.text_id}
									placeholder={exit.text_string}
									// style={{ color: getBootstrapColor(exitTextColor) }}
									className={classnames(
										"p-md font-italic",
										selectedExitId && selectedExitId !== exit.id && "opacity-50"
									)}
								/>
							</React.Fragment>
						))}
					</FFMultiSelect>
				</div>
			</div>
		</React.Fragment>
	);
};

export default StorySprig;
