import _ from "lodash";
import { getReducer } from "../utils/StoreUtils";
import {
	getPageItemIdFromQuery,
	getRootPageItemForPageItemId,
} from "../utils/PageItemsUtils";
import { convertLocationPathToLocation } from "../utils/LocationUtils";
import { getPageByPath, getLocationPathForPage } from "../utils/PagesUtils";
import {
	undoLocation as _undoLocation,
	redoLocation as _redoLocation,
	pushLocation as _pushLocation,
	popLocation as _popLocation,
	updateLocation as _updateLocation,
} from "../redux/actions/LocationActions";

export const undoLocation = _undoLocation;
export const redoLocation = _redoLocation;
export const pushLocation = _pushLocation;
export const popLocation = _popLocation;

export function updateLocation(location, query) {
	if (isLocationByPath(location)) {
		const pages = getReducer("pages").items;
		const action = this;

		let relativeToPageId;

		// if action from page item
		if (action.pageItem) {
			const pageItemId = action.pageItem.id;
			const rootPageItem = getRootPageItemForPageItemId(pageItemId);
			relativeToPageId = rootPageItem.parentPageId;
		}

		// if action from page
		if (action.page) {
			relativeToPageId = action.page.id;
		}

		const page = getPageByPath(location, relativeToPageId);
		if (!page) {
			console.warn(
				`No page found for path ${location} relative to page${
					action.page.friendly || action.page.id
				}`
			);
			return;
		}

		const locationPath = getLocationPathForPage(pages, page);
		location = convertLocationPathToLocation(locationPath);
	}

	_updateLocation(location, query);
}

export function updateWindowLocationHash(hash) {
	window.location.hash = hash;
}

export function openURL(
	urlOrTextId,
	windowName = "_blank",
	windowFeatures = ""
) {
	const texts = getReducer("texts").items;
	const text = texts.find((text) => text.id === urlOrTextId);
	window.open((text && text.value) || urlOrTextId, windowName, windowFeatures);
}

export function scrollPageItemIntoView(
	pageItemId,
	params = { behavior: "smooth", block: "end", inline: "end" }
) {
	pageItemId = getPageItemIdFromQuery(pageItemId, this);

	const { behavior, block, inline } = params;
	const elements = document.getElementsByClassName(
		`page-item-id-${pageItemId}`
	);
	const element = elements[0];
	if (element) {
		element.scrollIntoView({ behavior, block, inline });
	}
}

export function scrollTo(
	pageItemId,
	{ behavior = "smooth", top = 0, left = 0, relative = false }
) {
	let element = window;

	if (pageItemId && pageItemId != "window") {
		pageItemId = getPageItemIdFromQuery(pageItemId, this);
		const elements = document.getElementsByClassName(
			`page-item-id-${pageItemId}`
		);
		element = elements[0];
	}

	if (element) {
		if (relative) {
			element.scrollBy({ behavior, top, left });
		} else {
			element.scrollTo({ behavior, top, left });
		}
	}
}

export function scrollByWindowHeight({
	behavior = "smooth",
	offsetTop = 0,
	offsetLeft = 0,
} = {}) {
	let element = window;
	const top = window.innerHeight + offsetTop;
	element.scrollBy({ behavior, top, left: offsetLeft });
}

export function isLocationByPath(location) {
	const keyArray =
		Array.isArray(location) && location.map((locObj) => _.keys(locObj));
	const keys = _.flatten(keyArray);
	return keys.some((key) =>
		["parent", "sibling", "child", "friendlyId"].includes(key)
	);
}
