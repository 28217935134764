import React from "react";

import Styles from "./DraggableActionTarget.module.css";

const DraggableActionTarget = ({ onDrop, children }) => {
	const onDragEnterHandler = (e) => {
		// this.setState({ draggState: ON_DRAGG_OVER });
	};

	const onDragOverHandler = (e) => {
		e.stopPropagation();
		e.preventDefault();
		// this.setState({ draggState: ON_DRAGG_OVER });
	};

	const onDropHandler = (e) => {
		// var sourcePageItem = JSON.parse(e.dataTransfer.getData("Text"));
		// this.setState({ draggState: ON_DRAGG_IDLE });
		const rect = e.currentTarget.getBoundingClientRect();
		const dropX = e.clientX - rect.left;
		const dropY = e.clientY - rect.top;
		const dropValueX = (e.clientX - rect.left) / rect.width;
		const dropValueY = (e.clientY - rect.top) / rect.height;

		onDrop && onDrop({ ...e, dropX, dropY, dropValueX, dropValueY });
	};

	const onDragLeaveHandler = (e) => {
		// this.setState({ draggState: ON_DRAGG_IDLE });
	};

	return (
		<div
			className={Styles.root}
			onDragEnter={onDragEnterHandler}
			onDragOver={onDragOverHandler}
			onDrop={onDropHandler}
			onDragLeave={onDragLeaveHandler}
		>
			<div>{children}</div>
		</div>
	);
};

export default DraggableActionTarget;
