import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	removeItemInStateItems,
	removeItemsInStateItems,
	resetItemsInStateItems,
	addItemInStateItems,
	changeIndexForItemInStateItems,
} from "../utils/reducerUtils";

import {
	UPDATE_SPRIG_STORIES,
	UPDATE_SPRIG_STORY,
	ADD_SPRIG_STORY,
	REMOVE_SPRIG_STORY,
	REMOVE_SPRIG_STORIES,
	RESET_SPRIG_STORIES,
	CHANGE_INDEX_FOR_SPRIG_STORY,
} from "../constants/ActionConstants";

const initialState = initialStateObjectCollection();

const pageItems = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_SPRIG_STORIES:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_SPRIG_STORY:
			return updateItemInStateItems(state, action.item);
		case ADD_SPRIG_STORY:
			return addItemInStateItems(state, action.item);
		case REMOVE_SPRIG_STORY:
			return removeItemInStateItems(state, action.item);
		case REMOVE_SPRIG_STORIES:
			return removeItemsInStateItems(state, action.item);
		case RESET_SPRIG_STORIES:
			return resetItemsInStateItems(initialState);
		case CHANGE_INDEX_FOR_SPRIG_STORY:
			return changeIndexForItemInStateItems(state, action.item, action.toIndex);
		default:
			return state;
	}
};

export default pageItems;
