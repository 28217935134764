import React, { useRef, useState, useEffect } from "react";
import classnames from "classnames";
import { Power2, Power0, TweenMax } from "gsap";
import { getBootstrapColor } from "../../../../utils/colorUtils";
import DefaultButton from "../../../scroll/DefaultButton";

const Delimitter = ({
	className,
	colorA = "primary",
	colorB = "transparent",
	width = 26,
	animate = false,
}) => {
	const ref = useRef(null);
	const colA = getBootstrapColor(colorA);
	const colB = getBootstrapColor(colorB);

	const borderStyle = {
		background: `repeating-linear-gradient(145deg,${colA},${colA} ${
			width * 0.5
		}px,${colB} ${width * 0.5}px,${colB} ${width}px)`,
		height: "17px",
		minHeight: "17px",
		marginRight: 0,
		right: 0,
		top: 0,
		bottom: 0,
		position: "absolute",
	};

	useEffect(() => {
		if (animate) {
			TweenMax.to(ref.current, 2, {
				x: width,
				ease: Power0.easeNone,
				repeat: 100,
			});
		}
	}, [animate]);

	return (
		<div
			ref={ref}
			className={classnames("delimitter position-relative", className)}
			style={borderStyle}
		/>
	);
};

const QuizCard = ({
	show,
	cardContext = "unknown",
	contentClassName,
	onShow,
	onHide,
	delimitters = false,
	children = () => {},
	onTryAgainClick,
	onNextClick,
	contentHeight = "auto",
	contentMinHeight = "auto",
	baseColor = "primary",
	backgroundColor = "transparent",
	textIdLabelTryAgainButton = "953A980D-5E92-4D99-9885-374F4C7D4793",
	textIdLabelNextButton = "788C9682-C6C4-4825-8BE5-9883EFE51729",
}) => {
	const ref = useRef(null);
	const [visible, setVisibility] = useState(show);

	const reset = () => {
		TweenMax.set(ref.current, {
			transformStyle: "preserve-3d",
			rotateY: -360,
			z: -3000,
			opacity: 0,
		});
	};

	useEffect(() => {
		reset();
	}, []);

	useEffect(() => {
		if (show) {
			TweenMax.to(ref.current, 1, {
				rotateY: 0,
				opacity: 1,
				z: 0,
				ease: Power2.easeOut,
				onStart: () => {
					setVisibility(true);
				},
				onComplete: onShow,
			});
		} else {
			TweenMax.to(ref.current, 1, {
				opacity: 0,
				z: -4000,
				ease: Power2.easeOut,
				onComplete: () => {
					onHide && onHide();
					reset();
					setVisibility(false);
				},
			});
		}
	}, [show]);

	return (
		<div className={ classnames("quiz-card absolute-top w-100 d-flex flex-column justify-content-stretch pointer-events-none", `quiz-card-context-${cardContext}`)}>
			{/* CONTENT */}
			<div
				className={classnames(
					"position-relative flex-grow-1 w-100 px-sm-0 px-md-5 pointer-events-none"
				)}
				style={{
					perspective: 800,
					height: contentHeight,
					minHeight: contentMinHeight,
				}}
			>
				<div
					ref={ref}
					className={classnames(
						"w-100 h-100 d-flex flex-column justify-content-stretch",
						contentClassName,
						visible ? "d-flex" : "d-none"
					)}
				>
					{/* Delimitter */}
					{visible && delimitters && (
						<Delimitter colorA={baseColor} className="flex-grow-0" />
					)}
					{/* CHILDREN */}
					<div
						className="flex-grow-1 h-100 px-2"
						style={{ backgroundColor: getBootstrapColor(backgroundColor) }}
					>
						{visible &&
							(typeof children === "function" ? children() : children)}
					</div>
					{/* BUTTONS */}
					{/* position absolute, uggly fix for some devices not including this height (instead ADDING it) in the contentHeight, causing the card to grow too high  */}
					<div
						style={{ position: "absolute", bottom: 0 }}
						className="container-fluid w-100 justify-content-center"
					>
						{visible && (onTryAgainClick || onNextClick) && (
							<div className="row m-0 mb-6 w-100 justify-content-center">
								{/* BUTTON TRY AGAIN */}
								{onTryAgainClick && (
									<DefaultButton
										show={show}
										className="col-sm-6 m-1"
										onClick={onTryAgainClick}
										labelTextId={textIdLabelTryAgainButton}
									/>
								)}
								{/* BUTTON NEXT */}
								{onNextClick && (
									<DefaultButton
										className="col-sm-6 m-1"
										show={show}
										onClick={onNextClick}
										labelTextId={textIdLabelNextButton}
									/>
								)}
							</div>
						)}
					</div>
					{/* Delimitter */}
					{visible && delimitters && (
						<Delimitter colorA={baseColor} className="flex-grow-0" />
					)}
				</div>
			</div>
		</div>
	);
};

export default QuizCard;
