import Badge, { BadgeTone } from "components/Badge/Badge";
import Scrollable from "components/Scrollable/Scrollable";
import { SliderMarks } from "components/Slider/Slider";
import Text from "funkis-template/components/core/Text";
import { QuidInput } from "funkis-template/hooks/backend";
import { RateContentArray } from "funkis-template/models/contentarray";
import { TextId } from "funkis-template/models/player";
import { getBootstrapColor } from "funkis-template/utils/colorUtils";
import _ from "lodash";
import { CSSProperties, FC, ReactNode } from "react";
import { GlobalText } from "./RateOutput.constants";
import { calculateRateDataFromQuidInputs } from "./RateOutput.utils";
import RateOutputList, { RateOutputItem } from "./RateOutputList";
import { Marker } from "./RateOutputRow";

type SortAlgorithmsVariants =
	| "Average value ascending"
	| "Average value descending"
	| "Median value ascending"
	| "Median value descending"
	| "Deviation ascending"
	| "Deviation descending"
	| "none";

type QuidInputGroup = { name: TextId; quidInputs: QuidInput[] };

type AnalyzedQuidInputGroup = QuidInputGroup & {
	valueAverage: number;
	valueMedian: number;
	coefficientOfVariation: number;
	min: number;
	max: number;
};

type BadgeStyle = "text" | "badge";
type RateOutputBadgeTone = "auto" | "info";

export type RateOutputProps = {
	quidInputGroups: QuidInputGroup[];
	marker: Marker;
	badgeTone: RateOutputBadgeTone;
	badgeStyle: BadgeStyle;
	sort: SortAlgorithmsVariants;
	variationThresholdHigh: Number;
	variationThresholdMedium: Number;
	contentArray: RateContentArray;
	maxNumberOfRows?: number;
	showVariationLabels?: boolean;
	variant: "slider" | "bar chart";
	resultColor?: string;
};

const sortAlgorithms: Record<
	SortAlgorithmsVariants,
	(a: AnalyzedQuidInputGroup, b: AnalyzedQuidInputGroup) => number
> = {
	"Average value ascending": (a, b) => a.valueAverage - b.valueAverage,
	"Average value descending": (a, b) => b.valueAverage - a.valueAverage,

	"Median value ascending": (a, b) => a.valueMedian - b.valueMedian,
	"Median value descending": (a, b) => b.valueMedian - a.valueMedian,

	"Deviation ascending": (a, b) =>
		a.coefficientOfVariation - b.coefficientOfVariation,
	"Deviation descending": (a, b) =>
		b.coefficientOfVariation - a.coefficientOfVariation,

	none: () => 0,
};

function analyzeQuidInputGroups(
	quidInputGroups: QuidInputGroup[],
	min: number,
	max: number
): AnalyzedQuidInputGroup[] {
	return quidInputGroups.map((g) => {
		const { averageValue, medianValue, coefficientOfVariation } =
			calculateRateDataFromQuidInputs(g.quidInputs);

		return {
			...g,
			valueAverage: averageValue,
			valueMedian: medianValue,
			coefficientOfVariation: coefficientOfVariation,
			min: min,
			max: max,
		};
	});
}

export type VariationLevel = "low" | "medium" | "high";

export type VariationBadges = Record<
	VariationLevel,
	{ tone: BadgeTone; textId: TextId }
>;
const slideVariationBadges: VariationBadges = {
	low: {
		tone: "positive",
		textId: GlobalText.VariationLow,
	},
	medium: {
		tone: "caution",
		textId: GlobalText.VariationMedium,
	},
	high: {
		tone: "critical",
		textId: GlobalText.VariationHigh,
	},
};

export function composeBadge(
	badgeStyle: BadgeStyle,
	tone: RateOutputBadgeTone,
	variationLevel: VariationLevel,
	variationBadges: VariationBadges
): ReactNode {
	const textId = variationBadges[variationLevel].textId;
	const badgeTone =
		tone === "auto" ? variationBadges[variationLevel].tone : tone;

	switch (badgeStyle) {
		case "badge":
			return (
				<Badge tone={badgeTone}>
					<Text textId={textId} />
				</Badge>
			);

		case "text":
			return (
				<Text className="font-weight-bold" tone={badgeTone} textId={textId} />
			);
	}
}

const RateOutput: FC<RateOutputProps> = ({
	quidInputGroups,
	sort,
	marker,
	variationThresholdHigh,
	variationThresholdMedium,
	badgeTone,
	contentArray,
	maxNumberOfRows,
	showVariationLabels,
	badgeStyle,
	variant,
	resultColor,
}) => {
	const marks = contentArray.values.reduce(
		(acc, caItem) => ({ ...acc, [caItem.value]: { label: caItem.label } }),
		{}
	);

	const markKeys = Object.keys(marks).map((k) => parseInt(k, 10));

	const minValue = _.min(markKeys) ?? 0;
	const maxValue = _.max(markKeys) ?? 100;

	const analyzedGroups = analyzeQuidInputGroups(
		quidInputGroups,
		minValue,
		maxValue
	);

	const sortAlgorithm = sortAlgorithms[sort];
	const sortedGroups = [...analyzedGroups].sort(sortAlgorithm);

	const items: RateOutputItem[] = sortedGroups.map((group) => {
		const variationLevel: VariationLevel =
			group.coefficientOfVariation > variationThresholdHigh
				? "high"
				: group.coefficientOfVariation > variationThresholdMedium
				? "medium"
				: "low";

		return {
			description: group.name,
			value: group.valueAverage,
			min: group.min,
			max: group.max,
			marks: marks,
			badgesLeading: showVariationLabels
				? [
						composeBadge(
							badgeStyle,
							badgeTone,
							variationLevel,
							slideVariationBadges
						),
				  ]
				: [],
			badgesTrailing: [],
			quidInputs: group.quidInputs,
			variant: variant,
		};
	});

	const customColors = resultColor
		? ({
				"--rate-output-custom-result-color": getBootstrapColor(resultColor),
		  } as CSSProperties)
		: undefined;

	return (
		<Scrollable>
			<div style={customColors}>
				<RateOutputList
					marker={marker}
					items={items.slice(
						0,
						maxNumberOfRows !== 0 ? maxNumberOfRows : undefined
					)}
				/>
			</div>
		</Scrollable>
	);
};

export default RateOutput;
