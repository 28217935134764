module.exports = {
	STUDENT_NAME: "cmi.learner_name",
	STUDENT_ID: "cmi.learner_id",

	COMPLETION_STATUS: "cmi.completion_status",
	SUCCESS_STATUS: "cmi.success_status",
	LOCATION: "cmi.location",
	SUSPEND_DATA: "cmi.suspend_data",

	PROGRESS_MEASURE: "cmi.progress_measure",

	SCORE_RAW: "cmi.score.raw",
	SCORE_MAX: "cmi.score.max",
	SCORE_MIN: "cmi.score.min",
	SCORE_SCALED: "cmi.score.scaled",

	// QLP / quiddity specific
	QUIDDITY_TITLE_ID: "cmi.quiddity_title_id",
	QUIDDITY_TITLE_NAME: "cmi.quiddity_title_name",
	QUIDDITY_TITLE_ORGANIZATION_ID: "cmi.quiddity_title_organization_id",
};
