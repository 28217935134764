import React, { useEffect, useRef } from "react";
import numberFormatter from "number-formatter";
import { FFText, AudioUtils } from "funkis-foundation";
import QuizCard from "./core/QuizCard";
import { getBootstrapColor } from "../../../utils/colorUtils";
import Backend from "../../backend/Backend";
import {
	getCurrentChapterId,
	getCurrentPageId,
} from "../../../utils/locationUtils";
import { TweenMax, Power0 } from "gsap";
import QuizImage from "./core/QuizImage";

const BadgeValues = {
	gold: 1,
	silver: 2,
	bronze: 3,
};

const QuizScore = (props) => {
	const {
		show,
		textColor,
		baseColor = "primary",
		backgroundColor = "white",
		score,
		scoreMax,
		showScore = "total",
		badge,
		onTryAgainClick,
		onNextClick,
		contentHeight = "auto",
		contentMinHeight = "auto",
		badgeIoId,
		showBadge = true,
		feedbackTextId = "7BBECAD2-79BD-4B39-9BDE-3FE316650261",
	} = props;
	const refImage = useRef(null);
	const colorStyle = { color: getBootstrapColor(textColor || baseColor) };
	const scoreStr = score ? String(numberFormatter("#,###.", score)) : 0;

	useEffect(() => {
		// Timeout to make sure that "quizInput" doesnt fade in the music onViewVisible
		if (show) {
			setTimeout(() => {
				AudioUtils.fade("quiz-music", {
					from: AudioUtils.get("quiz-music").volume(),
					to: 0,
				});
			}, 1000);
		}
	}, [show]);

	useEffect(() => {
		if (refImage) {
			TweenMax.set(refImage.current, {
				z: 100,
				rotateY: 0,
			});
			TweenMax.to(refImage.current, {
				duration: 5,
				rotateY: 360,
				repeat: -1,
				ease: Power0.easeNone,
			});
		}
	}, [refImage]);

	return (
		<QuizCard
			show={show}
			cardContext="score"
			delimitters
			onTryAgainClick={onTryAgainClick}
			onNextClick={onNextClick}
			contentHeight={contentHeight}
			contentMinHeight={contentMinHeight}
			baseColor={baseColor}
			backgroundColor={backgroundColor}
			textIdLabelTryAgainButton="D25FB504-7773-417D-AC1F-D09045A6480F"
			textIdLabelNextButton="C103F956-672E-4026-B0D9-62EC149B92D1"
		>
			{() => (
				<div className="w-100 h-100 d-flex pt-5 flex-column align-items-center">
					{/* YOUR SCORE */}
					<FFText
						textId={"F147E0DB-9532-4C2B-AD3B-AD8B742E51AF"}
						className="quiz-label text-uppercase mt-5"
						style={colorStyle}
					/>
					{/* SCORE */}
					{showScore === "total" && (
						<h1 className="font-weight-bold mb-4" style={colorStyle}>
							{scoreStr}
						</h1>
					)}
					{/* SCORE OUT OF MAX*/}
					{showScore === "totalOutOfMax" && (
						<FFText
							className="text-center"
							tagName="h1"
							textId="11690496-E6D8-4402-AD84-4EF81BCD6B03"
							variables={{ numCorrectAnswers: score, numQuestions: scoreMax }}
						/>
					)}
					{/* FEEDBACK */}
					<FFText
						textId={feedbackTextId}
						tagName="h3"
						className="font-weight-bold text-center mb-5"
						style={colorStyle}
					/>
					{/* render this conditionally to ensure re-fetch, which leads to triggering of onFetchComplete,
						or else can't guarantee the badge is created the if you have round 1 with no badge, then a round 2 with a badge
					*/}
					{show && <Backend
						quidType="badge"
						mode="input"
						targetDataKey={badgeIoId}
						onFetchComplete={(e) => {
							const { quidInputs, save } = e;
							const quidInput = (quidInputs && quidInputs[0]) || {};
							const data = quidInput && quidInput.data;

							const badgeValue = BadgeValues[badge];
							const prevBadgeValue = data && BadgeValues[data.id];

							// Update badge if new badge is better than old.
							if (
								save &&
								badgeValue &&
								(prevBadgeValue > badgeValue || !prevBadgeValue)
							) {
								save({
									quidInput: {
										...quidInput,
										targetDataKey: badgeIoId,
										quidType: "badge",
									},
									data: {
										id: badge,
										chapterId: getCurrentChapterId(),
										pageId: getCurrentPageId(),
									},
								});
							}
						}}
						renderInput={({ data }) => {
							const badgeId = data.id || "none";
							if (showBadge) {
								return (
									<div style={{ height: "120px", width: "180px" }}>
										<QuizImage
											className="quiz-result-badge"
											height="120px"
											show={show && badgeId !== "none"}
											src={`badge-${badgeId}.png`}
											size="contain"
											slign="center"
											rotate
										/>
									</div>
								);
							} else {
								return <React.Fragment />;
							}
						}}
					/>}
				</div>
			)}
		</QuizCard>
	);
};

export default QuizScore;
