import * as StoreUtils from "./StoreUtils";
import * as StringUtils from "./StringUtils";
import uuid from "uuid/v4";
import _ from "lodash";

export function createUniqueTextId() {
	const texts = StoreUtils.getReducer("texts").items;
	let textId = uuid();
	while (texts.find((text) => text.id === textId)) {
		textId = uuid();
	}
	return textId;
}

export function spliceVariables(text, params) {
	if (!_.isUndefined(params) && params instanceof Array) {
		params.forEach((param) => {
			text = text.replace("%d", "" + param);
		});
	} else if (!_.isUndefined(params)) {
		text = StringUtils.template(text, params);
	}
	return text;
}

export function createText(value = "") {
	return { id: createUniqueTextId(), value };
}

export function filterTextsByString(texts, str = "", caseSensetive = true) {
	if (caseSensetive) {
		return texts.filter((text) => text.value.indexOf(str) > -1);
	} else {
		return texts.filter(
			(text) => text.value.toLowerCase().indexOf(str.toLowerCase()) > -1
		);
	}
}

export function getTextForVideoSource(videoSrc, array = false) {
	const subtitles = StoreUtils.getReducer("subtitles").items;
	const subtitlesForSrc = subtitles.filter(
		(subtitle) => subtitle.src === videoSrc
	);
	const subtitlesForSrcSorted = _.sortBy(
		subtitlesForSrc,
		(value) => value.enter
	); // Sort on enter time
	const subtitleTextsForSrc = subtitlesForSrcSorted.map(
		(subtitle) => subtitle.text
	);

	if (array) {
		return subtitleTextsForSrc;
	} else {
		return subtitleTextsForSrc.reduce((currVal, prevVal) => prevVal + currVal);
	}
}
