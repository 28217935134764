import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import classnames from "classnames";
import {
	PageItemScrollVertical,
	StoreUtils,
	PageItemActions,
	PageItemsUtils,
} from "funkis-foundation";
import RevealNextButton from "./RevealNextButton/RevealNextButton";
import Reveal from "../../components/core/Reveal";
import {
	makeGetIsFirstBlock,
	makeGetPageType,
	makeGetIsNextBlockRevealed,
	makeGetParentBlock,
} from "../../../selectors/playerSelectors";
import { connect } from "../../utils/reduxUtils";
import ReadMoreReveal from "./ReadMoreReveal/ReadMoreReveal";

const { updatePageItem } = PageItemActions;
const { getPageItemByPath } = PageItemsUtils;

const PageItemScrollBase = (props) => {
	const {
		children,
		pageItem,
		style,
		rootStyle,
		rootClassName,
		className,
		showDivider = true,
		showNextButton = true,
		enableNextButton = true,
		centerNextButtonOnScroll = true,
		containerClassName = "container-fluid",
		onScrollEnterStart,
		onScrollExitStart,
		onScrollExitEnd,
		onNextClick,
		renderFunction,
		isFirstBlock,
		isNextBlockRevealed,
		verticalPadding = true,
		pageType,
		revealEnabled = true,
		parentBlockTags,
	} = props;

	const { program_type } = StoreUtils.getReducer("app").item;

	if (
		program_type === "slide" &&
		!pageItem.isPrintPage &&
		!pageType === "print"
	) {
		return <div />;
	}

	const {
		cmtPageItem = {},
		autoScrollOnReval = true,
		autoScrollOnShowNextButton = true,
	} = pageItem;
	const isPrintPage = pageType === "print" || pageItem.isPrintPage;
	const reveal = isPrintPage || isFirstBlock || pageItem.reveal;
	/* 
  reveal_next_block_event "on-done", is used when we want to wait with the reveal, for example until video is done, before auto revealing 
  */
	const isDelayedAutoReveal =
		cmtPageItem.reveal_next_block_event === "on-done" &&
		pageItem.status === "completed";
	const revealNextBlock =
		cmtPageItem.reveal_next_block === "yes" ||
		props.revealNextBlock ||
		isDelayedAutoReveal;
	const revealNextButton =
		_.get(props, "pageItem.cmtPageItem.show_next_button") === "always" ||
		showNextButton;
	const [viewPartial, setViewPartial] = useState(isPrintPage);
	const [viewComplete, setViewComplete] = useState(isPrintPage);
	const [viewVisible, setViewVisible] = useState(isPrintPage);
	const [wasViewComplete, _setWasViewComplete] = useState(
		isPrintPage || pageItem.wasViewComplete
	);
	const [insertNextButton, setInsertNextButton] = useState(false);

	const setWasViewComplete = (value) => {
		_setWasViewComplete(value);
		if (value) {
			updatePageItem(pageItem.id, { wasViewComplete: value });
		}
	};

	const isFirstRun = useRef(true);
	const nextButtonRef = useRef(null);

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}

		const hasTags = _.get(cmtPageItem, "tags", []).length > 0;

		if (
			!isPrintPage &&
			showNextButton &&
			autoScrollOnShowNextButton &&
			!hasTags
		) {
			setTimeout(() => {
				if (
					nextButtonRef &&
					nextButtonRef.current &&
					!revealNextBlock &&
					!isNextBlockRevealed
				) {
					// make scroll to the position of the next-button, but try to "center" if centerNextButton === true, because
					// it might be a part of a sequence of auto-reveals
					if (centerNextButtonOnScroll) {
						window.scrollTo({
							top: nextButtonRef.current.offsetTop - window.innerHeight / 2,
							behavior: "smooth",
						});
					} else {
						nextButtonRef.current.scrollIntoView();
					}
				}
				updatePageItem(pageItem.id, { autoScrollOnShowNextButton: false });
			}, 650);
		}
	}, [showNextButton, isPrintPage, isNextBlockRevealed]);

	// Reveal next reveal (groups this block with next)
	useEffect(() => {
		const nextPageItem = getPageItemByPath(
			[{ parent: 0 }, { sibling: 1 }],
			pageItem.id
		);
		if (nextPageItem) {
			const currentTags = _.get(cmtPageItem, "tags", []);
			const nextTags = _.get(nextPageItem.cmtPageItem, "tags", []);
			if (currentTags.length > 0 && nextTags.length === 0 && !revealNextBlock) {
				setInsertNextButton(true);
			}
		}
		if (reveal && revealNextBlock && nextPageItem) {
			// when reveal_next === "on-done", we want to scroll down (like revealing auto-revealing after playing a video)
			const autoScrollOnReveal = isDelayedAutoReveal;
			updatePageItem(nextPageItem.id, {
				reveal: true,
				completed: true,
				autoScrollOnReval: autoScrollOnReveal,
				showDivider: false,
			});
		}
	}, [revealNextBlock, reveal, isDelayedAutoReveal]);

	// Hack to be help main menu keeping track of last block being revealed or not,
	useEffect(() => {
		const prevValue = pageItem.showNextButton;
		const nextValue =
			reveal && !isPrintPage && revealNextButton && !revealNextBlock;

		if (prevValue !== nextValue) {
			updatePageItem(pageItem.id, { showNextButton: nextValue });
		}
	}, [reveal, isPrintPage, revealNextButton, revealNextBlock]);

	// Optimization
	if (!reveal) {
		return <div />;
	}

	return (
		<React.Fragment>
			<ReadMoreReveal pageItem={pageItem}>
				{({ showBlockDividers, hasTags }) => {
					const showRevealDivider =
						showBlockDividers && showDivider && !isPrintPage && !isFirstBlock;
					return (
						<div
							className={classnames(
								"pageitem-scroll-base",
								rootClassName,
								!wasViewComplete && "opacity-0",
								!hasTags &&
									parentBlockTags.length &&
									showRevealDivider &&
									"mt-5"
							)}
							style={{ pageBreakInside: "avoid", ...rootStyle }}
						>
							<Reveal
								pageItem={{
									...pageItem,
									reveal,
									autoScrollOnReval:
										!isPrintPage && autoScrollOnReval && !hasTags,
								}}
								enabled={revealEnabled}
								showDivider={showRevealDivider}
							>
								<PageItemScrollVertical
									pageItem={pageItem}
									className={classnames(
										containerClassName,
										"page-item",
										(!isPrintPage && revealNextBlock && "pt-3 mt-3") ||
											(!isPrintPage && verticalPadding && "py-3 my-3"),
										`page-item-${pageItem.type}`,
										pageItem.className,
										className
									)}
									style={{ ...pageItem.style, ...style }}
									onScrollEnterStart={() => {
										setViewPartial(true);
										setWasViewComplete(true);
										setViewComplete(true);
										setViewVisible(true);
										onScrollEnterStart && onScrollEnterStart(props);
									}}
									onScrollEnterEnd={() => {
										setViewPartial(true);
										setViewComplete(true);
										setViewVisible(true);
										setWasViewComplete(true);
										onScrollExitStart && onScrollExitStart(props);
									}}
									onScrollExitEnd={() => {
										setViewPartial(false);
										setViewComplete(false);
										setViewVisible(true);
										setWasViewComplete(true);
										onScrollExitEnd && onScrollExitEnd(props);
									}}
								>
									{renderFunction
										? renderFunction({
												...props,
												viewPartial,
												viewComplete,
												viewVisible,
												wasViewComplete,
										  })
										: children}

									<RevealNextButton
										ref={nextButtonRef}
										pageItem={pageItem}
										show={
											!isPrintPage &&
											revealNextButton &&
											!revealNextBlock &&
											!hasTags
										}
										enabled={enableNextButton}
										textId="FAA5BD0C-8239-4FAF-8301-BCDC32ED8654"
										onClick={onNextClick}
									/>
								</PageItemScrollVertical>
							</Reveal>
						</div>
					);
				}}
			</ReadMoreReveal>
			<RevealNextButton
				pageItem={pageItem}
				show={insertNextButton}
				enabled={insertNextButton}
				textId="FAA5BD0C-8239-4FAF-8301-BCDC32ED8654"
			/>
		</React.Fragment>
	);
};

const makeMapStateToProps = () => {
	const getIsFirstBlock = makeGetIsFirstBlock();
	const getPageType = makeGetPageType();
	const getIsNextBlockRevealed = makeGetIsNextBlockRevealed();
	const getParentBlock = makeGetParentBlock();
	const mapStateToProps = (state, props) => {
		const parentBlock = getParentBlock(state, props);
		return {
			isFirstBlock: getIsFirstBlock(state, props),
			isNextBlockRevealed: getIsNextBlockRevealed(state, props),
			pageType: getPageType(state, props),
			parentBlockTags: _.get(parentBlock, "cmtPageItem.tags", []),
		};
	};
	return mapStateToProps;
};

export default connect(PageItemScrollBase, makeMapStateToProps);
