let _pageItems = {};

export function getPageItem(type, returnDivIfTypeIsMissing = true) {
	if (_pageItems[type]) {
		return _pageItems[type];
	} else {
		return returnDivIfTypeIsMissing && _pageItems["div"];
	}
}

export function addPageItem(type, PageItem) {
	_pageItems[type] = PageItem;
}

export function getAllPageItems() {
	return _pageItems;
}
