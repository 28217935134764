import React from "react";
import { PieChart, Pie, Cell } from "recharts";
import { getBootstrapColor } from "../../../utils/colorUtils";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (props) => {
	const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, name } =
		props;
	const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);
	return (
		<text
			className="speech-bubble-text-emphasized font-weight-bold"
			x={x}
			y={y}
			style={{ fontSize: "60px" }}
			fill="white"
			textAnchor={x > cx ? "middle" : "middle"}
			dominantBaseline="central"
		>
			{`${name}`}
		</text>
	);
};

const Chart = ({
	data,
	size = 360,
	fill = "#FFFFFF",
	renderLabel,
	color = "primary",
	...rest
}) => {
	return (
		<div {...rest}>
			<PieChart width={size} height={size}>
				<Pie
					isAnimationActive={false}
					data={data}
					labelLine={false}
					label={renderLabel || renderCustomizedLabel}
					outerRadius={size / 2}
					fill={fill}
					startAngle={-180}
					endAngle={180}
					dataKey="value"
				>
					{data.map((entry, index) => {
						const amount = (index / data.length) * 50 - 25;
						const cellColor = getBootstrapColor(color, amount);
						return <Cell key={`pie-${entry.color}`} fill={cellColor} />;
					})}
				</Pie>
			</PieChart>
		</div>
	);
};

export default Chart;
