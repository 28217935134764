import { useSelectedLanguage } from "funkis-template/hooks/player";
import _ from "lodash";
import { FC } from "react";

type DateValue = Date | string | number;
type FormattingStyle = "full" | "long" | "medium" | "short" | "none";

export type FormattedDateProps = {
	date: DateValue;
	dateStyle?: FormattingStyle;
	timeStyle?: FormattingStyle;
	preferredLanguageCode?: string;
};

function composeDate(dateValue: DateValue): Date {
	if (dateValue instanceof Date) {
		return dateValue;
	} else if (_.isString(dateValue)) {
		return new Date(dateValue);
	} else if (_.isNumber(dateValue)) {
		return new Date(dateValue);
	}

	return new Date();
}

const FormattedDate: FC<FormattedDateProps> = ({
	date: dateValue,
	timeStyle = "none",
	dateStyle = "medium",
	preferredLanguageCode,
}) => {
	const date = composeDate(dateValue);
	const selectedLanguage = useSelectedLanguage();
	const selectedLanguageCode = (
		preferredLanguageCode ?? selectedLanguage
	).split("-")[0];

	const dateFormatter = new Intl.DateTimeFormat(selectedLanguageCode, {
		timeStyle: timeStyle === "none" ? undefined : timeStyle,
		dateStyle: dateStyle === "none" ? undefined : dateStyle,
	});

	const dateString = dateFormatter.format(date);

	return <time>{dateString}</time>;
};

export default FormattedDate;
