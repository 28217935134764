import React, { useEffect } from "react";
import classnames from "classnames";
import hexToRgba from "hex-to-rgba";
import {
	PageItemReveal as FunkisPageItemReveal,
	ReduxObjectSelectors,
} from "funkis-foundation";
import { scrollPageItemToTop } from "../../actions/Actions";
import { connect } from "../../utils/reduxUtils";

const { makeGetValueSelector } = ReduxObjectSelectors;

const PageItemReveal = (props) => {
	const {
		pageItem,
		children,
		background,
		dividerColor,
		dividerStyle,
		enabled = true,
	} = props;
	const { autoScroll = true, showDivider = true } = pageItem;
	const showDelimitter = showDivider && (!background || background === "");
	const hasDividerColor = dividerColor && dividerColor !== "";

	useEffect(() => {
		if (pageItem.status === "completed" && autoScroll) {
			scrollPageItemToTop({ pageItem, offsetTop: -135 });
			// updatePageItem(pageItem.id, { autoScroll: false }); ToDo Fix so page not scrolling when returning
		}
	}, [pageItem.status]);

	const colorA = hasDividerColor && hexToRgba(dividerColor, 0.5);
	const colorB = hasDividerColor && hexToRgba(dividerColor, 1);

	const borderStyle =
		(dividerStyle === "dashed" &&
			dividerColor && {
				backgroundImage: `linear-gradient(to right, ${colorA} 50%, ${colorB} 0%)`,
			}) ||
		(dividerColor && { background: dividerColor }) ||
		{};

	if (enabled) {
		return (
			<div className="w-100">
				<div className="container-fluid">
					<div className="row m-0">
						<div className="col-sm-12">
							<div
								className={classnames(
									showDelimitter &&
										pageItem.reveal &&
										"border-divider animated fadeIn delay-500ms",
									dividerStyle === "solid" && "bg-primary"
								)}
								style={borderStyle}
							/>
						</div>
					</div>
				</div>
				<FunkisPageItemReveal
					className={classnames(`w-100 page-item-id-${pageItem.id}`)}
					pageItem={{ ...pageItem, unmountChildrenWhenUnrevealed: true }}
				>
					{children}
				</FunkisPageItemReveal>
			</div>
		);
	} else {
		return children;
	}
};

const makeMapStateToProps = () => {
	const getValueSelector = makeGetValueSelector();
	const mapStateToProps = (state, props) => {
		return {
			background: getValueSelector(
				state,
				{ ...props, path: "page_bg_image" },
				"app"
			),
			dividerColor: getValueSelector(
				state,
				{ ...props, path: "color_block_divider" },
				"app"
			),
			dividerStyle: getValueSelector(
				state,
				{ ...props, path: "block_divider" },
				"app"
			),
		};
	};
	return mapStateToProps;
};

export default connect(PageItemReveal, makeMapStateToProps);
