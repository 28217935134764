import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { useBackend, QuidType, IOContext } from "../backend";
import { useValueListOptionsOrContentArray } from "../valuelist";
import { VALUE_LIST_TYPE } from "../../constants/valueListConstants";
import {
	MultipleChoiceInputScrollBlock,
	MultipleChoiceInputSlideBlock,
} from "funkis-template/models/pageitem";

type MultipleChoiceInput = Pick<
	MultipleChoiceInputScrollBlock | MultipleChoiceInputSlideBlock,
	"ios" | "id"
> & {
	min_selections?: number;
	max_selections?: number;
	correct?: "yes" | "no";
	show_done_button?: "yes" | "no";
};

type Props = {
	cmtPageItem: MultipleChoiceInput & { toggle?: boolean };
};

export const useIOMultiSelect = ({ cmtPageItem }: Props) => {
	const ioSelectionBasis = cmtPageItem.ios.selection_basis;
	const ioCurrentSelection = cmtPageItem.ios.current_selection;

	const [isSaving, setIsSaving] = useState(false);

	const minSelections = Number(cmtPageItem.min_selections) || 1;
	const maxSelections = Number(cmtPageItem.max_selections) || Number.MAX_VALUE;

	const allOptions: any = useValueListOptionsOrContentArray(
		cmtPageItem,
		VALUE_LIST_TYPE.MULTIPLE_CHOICE
	);

	const selectionBasisInputContext =
		(ioSelectionBasis?.context === IOContext.Member &&
			IOContext.CurrentGroupMembers) ||
		(ioSelectionBasis?.context === IOContext.Group && IOContext.Group) ||
		ioSelectionBasis?.context;

	const { quidInputs: selectionBasisQuidInputs } = useBackend({
		/**
		If we don't have a valid selection basis IO we use a "blank" IO based on
		the id to ensure it's a unique id.
		**/
		targetDataKey:
			ioSelectionBasis?.id || `should-never-be-used-${cmtPageItem.id}`,
		inputContext: selectionBasisInputContext,
		quidType: QuidType.ContentSelection,
	});

	// Count the number of "votes" of each contentItemId
	const selectionCounts = _.countBy(
		selectionBasisQuidInputs,
		"data.contentItemId"
	);

	const distinctSelectionBasisQuidInputs = _.uniqBy(
		selectionBasisQuidInputs,
		"data.contentItemId"
	);
	const optionsFromSelectionBasis = distinctSelectionBasisQuidInputs.map(
		(quidInput) =>
			allOptions.find((option) => option.id === quidInput.data.contentItemId)
	);

	const minimumNumberOfOptionsToShow = minSelections;
	const options =
		optionsFromSelectionBasis.length >= minimumNumberOfOptionsToShow
			? optionsFromSelectionBasis
			: allOptions;

	const { quidInputs, createQuidInput, updateQuidInput } = useBackend({
		targetDataKey: ioCurrentSelection.id,
		inputContext: ioCurrentSelection.context,
		quidType: QuidType.ContentSelection,
		shouldIncludeArchivedQuidInputs: true,
	});

	const selectedQuidInputs = quidInputs.filter(
		(quidInput) => !quidInput.archived
	);

	const selectedIndexes = selectedQuidInputs.map((quidInput) =>
		options.findIndex((option) => option?.id === quidInput.data.contentItemId)
	);
	const correctIndexes = options.reduce(
		(acc: number[], option, index) => (option?.correct ? [...acc, index] : acc),
		[]
	);

	const isFinished =
		selectedIndexes.length >= minSelections &&
		selectedIndexes.length <= maxSelections;
	const isCorrect = _.isEqual(
		[...selectedIndexes].sort(),
		[...correctIndexes].sort()
	);

	const selectedQuidInputsByOptionId = _.keyBy(
		selectedQuidInputs,
		"data.contentItemId"
	);

	const decoratedOptions = options.map((option, i) => ({
		...option,
		isSelected: selectedIndexes.includes(i),
		isCorrect: correctIndexes.includes(i),
		selectedAt: _.get(selectedQuidInputsByOptionId[option!.id], "updatedAt"),
		selectionQuidInput: selectedQuidInputsByOptionId[option!.id],
		numberOfBasisSelections: selectionCounts[option!.id],
	}));

	const shouldAllowToggle = cmtPageItem.toggle ?? true; // true is default unless explicit value is set

	const onSelectHandler = useCallback(
		async (selectedIndexes: number[], selectedIndex: number) => {
			setIsSaving(true);

			const lastInteractedOption = options[selectedIndex];

			const selectedOptions = selectedIndexes
				.map((selectedIndex) => options[selectedIndex])
				.filter((o) => o !== undefined);

			const selectedOptionsById = _.keyBy(selectedOptions, "id");
			const quidInputsByOptionId = _.keyBy(quidInputs, "data.contentItemId");

			/**
			First, we need to figure out which quidInputs need to flip their "archived" property.
			We do this by checking if they no longer match a selected option id, which
			means that they have been unselected.
			**/
			const quidInputsToArchive = quidInputs.filter(
				(quidInput) =>
					selectedOptionsById[quidInput.data.contentItemId] === undefined &&
					quidInput.archived === false
			);

			/**
			Secondly, we need to figure out which quidinputs have previously been archived (unselected)
			but have been selected again and should thus have their "archived" property flipped to "true".
			
			We also need to cover an extra edge case for a behaviour that is used in Slide VideoConversation
			(that sets the property "shouldAllowToToggle" to "true).
			VideoConversation wants us to to update the timestamp on a quidinput even if, technically,
			the selection state hasn't changed. It's needed in VideoConversation to keep track on which
			answer was the most recent one.
			 **/
			const quidInputsToUnArchive = quidInputs.filter(
				(quidInput) =>
					(selectedOptionsById[quidInput.data.contentItemId] !== undefined &&
						quidInput.archived === true) ||
					(!shouldAllowToggle &&
						selectedOptionsById[quidInput.data.contentItemId] &&
						selectedOptionsById[quidInput.data.contentItemId].id ===
							lastInteractedOption?.id)
			);

			/** 
			Thirdly, we check which options that don't yet have a quidInput associated with it
			which means that we need to create them.
			**/
			const quidInputsToCreate = selectedOptions
				.filter((option) => quidInputsByOptionId[option.id] === undefined)
				.map((option) => ({ data: { contentItemId: option.id } }));

			/**
			Now, when all quidinputs/options are sorted, we "just" need to update them
			on the backend. We create all the promises and then await them all before
			exiting this handler.
				**/
			const archivePromises = quidInputsToArchive.map((quidInput) =>
				updateQuidInput({ id: quidInput.id, archived: true })
			);

			const unArchivePromises = quidInputsToUnArchive.map((quidInput) =>
				updateQuidInput({ id: quidInput.id, archived: false })
			);

			const createPromises = quidInputsToCreate.map((newQi) =>
				createQuidInput(newQi)
			);

			await Promise.all([
				...archivePromises,
				...unArchivePromises,
				...createPromises,
			]);

			setIsSaving(false);
		},
		[options, quidInputs, createQuidInput, updateQuidInput, shouldAllowToggle]
	);

	// confirm button logic
	const [hasConfirmedSelection, setHasConfirmedSelection] = useState(false);
	const shouldCorrect = cmtPageItem.correct === "yes";
	const hasConfirmationStep =
		cmtPageItem.show_done_button === "yes" || shouldCorrect;

	useEffect(() => {
		if (hasConfirmedSelection && !isFinished) {
			// handle invalidation of confirm
			setHasConfirmedSelection(false);
		}
	}, [
		hasConfirmationStep,
		isFinished,
		hasConfirmedSelection,
		setHasConfirmedSelection,
	]);

	const isDisabled = shouldCorrect && hasConfirmedSelection;
	const isEnabled = !isSaving && !isDisabled;

	const dateSortedSelectedOptions = _.sortBy(
		decoratedOptions.filter((opt) => Boolean(opt.selectedAt)),
		"selectedAt"
	);

	return {
		options: decoratedOptions,
		dateSortedSelectedOptions,
		isFinished,
		isCorrect,
		isSaving,
		shouldCorrect,
		maxSelections,
		minSelections,
		hasConfirmationStep,
		hasConfirmedSelection,
		setHasConfirmedSelection,
		ffMultiSelectProps: {
			selectedIndexes,
			isCorrect,
			maxSelections,
			minSelections,
			toggle: shouldAllowToggle,
			enabled: isEnabled,
			disableDeselect: false,
			alwaysTriggerOnSelect: true,
			disableWhenMaxSelectionsReached: maxSelections > 1,
			autoDeselectWhenMaxSelectionsReached: maxSelections === 1,
			onSelect: onSelectHandler,
		},
	};
};
