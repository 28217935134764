import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";

import {
	ReduxCollectionSelectors,
	PageItemActions,
	PageItemsUtils,
	StoreUtils,
} from "funkis-foundation";

import filemakerImage from "../../assets/images/filemaker-icon-transparent-small.webp";

const { getPageItemsChildrenForPageItemId } = PageItemsUtils;

const RevealButton = (props) => {
	const { id, name, revealed, index, cmtPageItemId, isSlidePageItem } = props;
	console.log("is slide page item", isSlidePageItem);
	const pageItems = StoreUtils.getReducer("pageItems").items;
	const pageItemChildren = getPageItemsChildrenForPageItemId(
		id,
		pageItems,
		false
	);
	const displayName = pageItemChildren.length
		? pageItemChildren[0].name || pageItemChildren[0].type
		: name;

	const filemakerIconDimens = 20;
	return (
		<div className={classnames("d-flex")}>
			{!isSlidePageItem ? (
				<button
					className={classnames(
						"btn-sm btn-ellipsis mt-1 flex-grow-1",
						revealed ? "btn-success" : "btn-danger"
					)}
					onClick={() => {
						PageItemActions.updatePageItem(id, { reveal: !revealed });
					}}
				>
					{index + 1 + ". " + displayName}
				</button>
			) : (
				<div>{displayName}</div>
			)}
			<a
				className={classnames(
					"filemaker-pageitem-link d-inline-flex ml-1",
					!isSlidePageItem ? "mt-1" : ""
				)}
				target="_blank"
				rel="noreferrer"
				href={`fmp://biz.funkismultimedia.se/Funkis%20Authoring%20Tool?script=linkTriggeredOpenPageItem&param=${cmtPageItemId}`}
			>
				<img
					className="m-auto"
					src={filemakerImage}
					style={{ width: filemakerIconDimens, height: filemakerIconDimens }}
				/>
			</a>
		</div>
	);
};

const makeMapStateToProps = () => {
	const getItemValueByIdAndPathSelector =
		ReduxCollectionSelectors.makeGetItemValueByIdAndPathSelector();
	const mapStateToProps = (state, props) => {
		return {
			revealed: getItemValueByIdAndPathSelector(
				state,
				{ ...props, path: ["reveal"] },
				"pageItems"
			),
			name: getItemValueByIdAndPathSelector(
				state,
				{ ...props, path: ["name"] },
				"pageItems"
			),
		};
	};
	return mapStateToProps;
};

export default connect(makeMapStateToProps)(RevealButton);
