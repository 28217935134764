import languageNames from "../constants/localization/LanguageNames.js";
import rtlLanguageCodes from "../constants/localization/RtlLanguageCodes.js";
import { replaceClassOnElement } from "./DomUtils.js";

export function replaceLanguageClassOnBody(oldLangCode, newLangCode) {
	replaceClassOnElement({
		elementSelector: "body",
		classToRemove: oldLangCode,
		classToAdd: newLangCode,
	});
}

export function getNativeLanguageNameForLanguageCode(
	languageCode,
	useCountryCodeIfAvailable = true
) {
	const { nativeName } = getLanguageObjectForLanguageCode(
		languageCode,
		useCountryCodeIfAvailable
	);

	// Some of the names in the file are comma separated lists of
	// name variations. We only use the first one.
	const shortenedNativeName = nativeName.split(",")[0];
	const upperCasedFirstLetter = shortenedNativeName
		.charAt(0)
		.toLocaleUpperCase(languageCode.split("-")[0]);

	return upperCasedFirstLetter + shortenedNativeName.slice(1);
}
export function getEnglishLanguageNameForLanguageCode(
	languageCode,
	useCountryCodeIfAvailable = true
) {
	const { name } = getLanguageObjectForLanguageCode(
		languageCode,
		useCountryCodeIfAvailable
	);
	return name;
}

function getLanguageObjectForLanguageCode(
	languageCode,
	useCountryCodeIfAvailable = true
) {
	languageCode = languageCode.toLowerCase();

	if (!useCountryCodeIfAvailable) {
		languageCode = languageCode.split("-")[0];
	}
	if (useCountryCodeIfAvailable && languageNames[languageCode]) {
		return languageNames[languageCode];
	} else {
		const languageCodeWithoutCountryCode = languageCode.split("-")[0];
		return languageNames[languageCodeWithoutCountryCode];
	}
}

export function isLanguageCodeRtl(languageCode) {
	if (!languageCode) {
		return false;
	}

	languageCode = languageCode.split("-")[0];
	return rtlLanguageCodes.indexOf(languageCode) > -1;
}

export function getLanguageDirection(languageCode) {
	return isLanguageCodeRtl(languageCode) ? "rtl" : "ltr";
}
