import React from "react";

const IconDelete = ({ color = "#FFFFFF", className }) => {
	return (
		<svg
			width="21px"
			height="21px"
			viewBox="0 0 21 21"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<title>Delete</title>
			<g id="_x" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<circle id="Oval" fill="#000000" cx="10.5" cy="10.5" r="10.5"></circle>
				<polygon
					id="X"
					fill={color}
					fillRule="nonzero"
					points="8.33806757 15 10.4960676 11.49 12.6540676 15 14.9030676 15 11.7700676 10.32 14.6820676 5.718 12.5630676 5.718 10.6000676 9.059 8.59806757 5.718 6.42706757 5.718 9.31306757 10.216 6.23206757 15"
				></polygon>
			</g>
		</svg>
	);
};

export default IconDelete;
