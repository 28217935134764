import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import PageItem from "./PageItem";
import {
	makeGetPageItemIdsByParentPageId,
	makeGetPageItemIdsByParentPageItemId,
} from "../../selectors/PageItemSelectors";

class PageItems extends React.Component {
	static propTypes = {
		pageItemIds: PropTypes.array.isRequired, // comming from connect
		parentPageId: PropTypes.string,
		parentPageItemId: PropTypes.string,
		layer: PropTypes.string,
		pageItemOverrides: PropTypes.array, // Passed down by pageItemLinked
	};

	static defaultProps = { parentPageId: null, pageItemOverrides: [] };

	shouldComponentUpdate(nextProps, nextState) {
		return !_.isEqual(nextProps.pageItemIds, this.props.pageItemIds);
	}

	render() {
		const {
			location,
			locationPath,
			parentPageId,
			pageItemIds,
			layer,
			pageItemOverrides,
			parentPageItemId,
		} = this.props;

		// console.count(`PageItems render ids:${pageItemIds}`);

		return (
			<React.Fragment>
				{pageItemIds.map((pageItemId) => (
					<PageItem
						key={`page-it em-${pageItemId}`}
						id={pageItemId}
						parentPageId={parentPageId}
						layer={layer}
						pageItemOverrides={pageItemOverrides}
					/>
				))}
			</React.Fragment>
		);
	}
}

const makeMapStateToProps = () => {
	const getPageItemIdsByParentPageId = makeGetPageItemIdsByParentPageId();
	const getPageItemIdsByParentPageItemId =
		makeGetPageItemIdsByParentPageItemId();
	const mapStateToProps = (state, props) => {
		return {
			pageItemIds: props.parentPageId
				? getPageItemIdsByParentPageId(state, props)
				: getPageItemIdsByParentPageItemId(state, props),
		};
	};
	return mapStateToProps;
};

export default connect(makeMapStateToProps)(PageItems);
