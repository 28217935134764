import React, { useEffect, useState } from "react";
import _ from "lodash";
import { upsertMember, getMembers, getGroups, QuiddityActions } from "quiddity";
import { StoreUtils, FFMultiSelect } from "funkis-foundation";
import GroupOrUnitListItem from "../../components/slide/GroupOrUnitListItem";
import { updateLocation } from "../../actions/Actions";
import { useSelector } from "react-redux";

const WorkshopGroupSetup = (props) => {
	const { page, className, style } = props;
	const [skip, setSkip] = useState(false);
	const [members, setMembers] = useState();
	const groupIds = members ? members.map((member) => member.group) : [];

	const backend = useSelector((state) => _.get(state, "app.item.backend"));
	const quidditySessionId = useSelector((state) =>
		_.get(state, "quiddity.item.session.id")
	);
	const apiUrl = useSelector(
		(state) =>
			_.get(state, "quiddity.item.url") + _.get(state, "quiddity.item.api")
	);
	const currentMember = useSelector((state) =>
		_.get(state, "quiddity.item.member")
	);

	useEffect(() => {
		fetchGroupsForMember();
	}, []);

	useEffect(() => {
		if (skip || backend !== "quiddity") {
			updateLocation({
				pageId: page && page.id,
				path: [{ sibling: 1 }],
			});
		}
	}, [skip, backend]);

	const fetchGroupsForMember = async () => {
		// get members with the same email as current member...
		const membersForUser = await getMembers(apiUrl, {
			quidditySession: quidditySessionId,
			populate: "user",
			user: _.get(currentMember, "user.id"),
		});
		const memberWithSameEmail = membersForUser.filter(
			(member) =>
				_.get(member, "user.email") === _.get(currentMember, "user.email")
		);

		setMembers(memberWithSameEmail);

		const memberGroupIds = memberWithSameEmail.map((member) => member.group);
		await getGroups(apiUrl, {
			quidditySession: quidditySessionId,
			id: memberGroupIds,
		});
		// If members only belongs to one group...
		setSkip(memberGroupIds.length < 2);
	};

	const onSelectGroup = async (indexes) => {
		const groups = StoreUtils.getReducer("groups").items;
		const currentMember = members[indexes[0]];
		const currentGroup = groups.find(
			(group) => group.id === currentMember.group
		);

		// Save selection on original member so that selection can be restored...
		const originalMember = members[0];
		await upsertMember(apiUrl, {
			id: originalMember.id,
			properties: {
				...originalMember.properties,
				selectedMember: currentMember.id,
			},
		});
		// Update current member and group...
		QuiddityActions.updateQuiddity({
			group: currentGroup,
			member: currentMember,
		});
		// Get members for curren group...
		await getMembers(apiUrl, {
			group: currentGroup.id,
			quidditySession: quidditySessionId,
			populate: "user",
		});
	};

	const selectedIndexes = [
		groupIds.findIndex((id) => id === currentMember.group),
	];

	return (
		<FFMultiSelect
			className={className}
			style={style}
			buttonClassName={
				"mb-1 border-bottom border-bottom-1 border-gray pb-1 align-items-center"
			}
			toggle={false}
			maxSelections={1}
			minSelections={1}
			selectedIndexes={selectedIndexes}
			disableWhenMaxSelectionsReached={false}
			autoDeselectWhenMaxSelectionsReached={true}
			onSelect={onSelectGroup}
		>
			{members &&
				members.map((member) => {
					const groups = StoreUtils.getReducer("groups").items;
					const group = groups.find((g) => g.id === member.group);
					return (
						<GroupOrUnitListItem
							id={group && group.id}
							selected={currentMember.group === member.group}
						/>
					);
				})}
		</FFMultiSelect>
	);
};

export default WorkshopGroupSetup;
