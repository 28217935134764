import { useSelectedLanguage } from "funkis-template/hooks/player";
import { FC } from "react";

export type FormattedPercentageProps = {
	value: number;
	preferredLanguageCode?: string;
	className?: string;
};

const FormattedPercentage: FC<FormattedPercentageProps> = ({
	value,
	preferredLanguageCode,
	className,
}) => {
	const selectedLanguage = useSelectedLanguage();
	const selectedLanguageCode = (
		preferredLanguageCode ?? selectedLanguage
	).split("-")[0];
	const languageCode = selectedLanguageCode.split("-").at(0)!;

	const percentString = new Intl.NumberFormat(languageCode, {
		style: "percent",
	}).format(value / 100);

	return <span className={className}>{percentString}</span>;
};

export default FormattedPercentage;
