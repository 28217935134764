import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import classnames from "classnames";
import { getLetterByIndex } from "../../utils/stringUtils";
import Text from "../../components/core/Text";
import { getBootstrapColor } from "../../utils/colorUtils";
import classNames from "classnames";
import styled from "styled-components";
import { getText } from "../../utils/textUtils";

import SelectedIcon from "../../components/core/SelectedIcon";

const OptionStyled = styled.div`
	position: relative;
	padding: ${({ layout }) =>
		(layout === "1x2" && "55px 35px") ||
		(layout === "1x3" && "35px") ||
		(layout === "2x2" && "22px 16px") ||
		(layout === "6x8" && "16px 16px 4px") ||
		"16px"};
	background-image: ${({ image }) =>
		image ? `url(./content/images/${image})` : "inherit"};
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	border-style: solid;
	border-width: ${({ layout, checkmark }) =>
		(checkmark === "check" && "0px") ||
		(layout == "1x2" && "6px") ||
		(layout == "2x2" && "6px") ||
		(layout == "2x3" && "4px") ||
		(layout == "6x8" && "2px") ||
		"1px"};
	border-color: ${({ selected }) =>
		selected
			? getBootstrapColor("interaction")
			: getBootstrapColor("transparent")};
	:hover {
		border-color: ${() => getBootstrapColor("interaction")};
	}
	justify-content: ${({ textAlign }) =>
		(textAlign === "bottom-center" && "flex-end") ||
		(textAlign === "bottom-left" && "flex-end") ||
		"flex-start"};
	align-items: ${({ textAlign }) =>
		(textAlign === "bottom-center" && "center") || "flex-start"};
	.option,
	.descr {
		text-align: ${({ textAlign }) =>
			(textAlign === "bottom-center" && "center") || "left"};
	}
	.icon-select {
		position: absolute;
		top: 20px;
		right: 20px;
	}
`;

const getOptionClassNames = (optionLayout, numOfOptions) => {
	const variant =
		optionLayout === "auto"
			? (numOfOptions <= 4 && "2x2") || (numOfOptions <= 6 && "3x2") || "3x4"
			: optionLayout;

	switch (variant) {
		case "1x2":
		case "1x3":
			return {
				num: "dashboard-value-lg font-weight-bold text-uppercase",
				option: "message-sm font-weight-bold",
				descr: "message-sm",
			};
		case "2x2":
			return {
				num: "dashboard-value-lg font-weight-bold text-uppercase",
				option: "p-lg font-weight-bold",
				descr: "p-lg",
			};
		case "2x3":
			return {
				num: "message-lg font-weight-bold text-uppercase",
				option: "message-xs font-weight-bold",
				descr: "message-xs",
			};
		case "2x5":
			return {
				num: "p-lg font-weight-bold text-uppercase",
				option: "p-lg font-weight-bold",
				descr: "p-lg",
			};
		case "6x8":
			return {
				num: "p-sm font-weight-bold",
				option: "p-sm font-weight-bold",
				descr: "p-sm",
			};
		default:
			return { num: "", option: "", descr: "" };
	}
};

const MultipleChoiceOption = ({
	className = "",
	optionTextId,
	descriptionTextId = null,
	image,
	textAlign,
	selected,
	identifier,
	index,
	layout = "2x2",
	backgroundColor,
	showDelay = 0,
	checkmark = "frame",
}) => {
	const ref = useRef(null);

	const inlineIdentifier = layout === "2x5" || layout === "6x8";
	const indexChar =
		(identifier == "123" && index + 1 + ". ") ||
		(identifier == "abc" && getLetterByIndex(index) + ". ") ||
		null;
	const optionText =
		((inlineIdentifier && indexChar) || "") + getText(optionTextId);
	const checkMarkSize = (layout === "6x8" && "20px") || "40px";
	const optionClassNames = getOptionClassNames(layout);

	useEffect(() => {
		gsap.set(ref.current, { opacity: 0 });
		gsap.to(ref.current, 2, { opacity: 1, delay: showDelay });
	}, []);

	return (
		<OptionStyled
			ref={ref}
			className={classNames(
				className,
				optionClassNames.col,
				"select-item",
				"d-flex flex-column h-100 w-100"
			)}
			style={{
				...optionClassNames.containerStyle,
				maxWidth: "initial",
				backgroundColor: getBootstrapColor(backgroundColor),
			}}
			selected={selected}
			layout={layout}
			textAlign={textAlign}
			image={image}
			checkmark={checkmark}
		>
			{/* CHECK MARK */}
			{checkmark === "check" && selected && (
				<SelectedIcon
					className="icon-select"
					show={selected}
					style={{ width: checkMarkSize, height: checkMarkSize }}
				/>
			)}
			{/* IDENTIFIER */}
			{identifier !== "none" && !inlineIdentifier && (
				<div className="px-2 d-flex w-100 justify-content-between flex-grow-0">
					{/* NUM / CHAR */}
					<span
						className={classnames(
							"identifier",
							optionClassNames.num,
							"d-block"
						)}
					>
						{indexChar}
					</span>
				</div>
			)}
			{/* TEXTS */}
			<div className="text-container px-2 w-100 flex-grow-0">
				{/* OPTION */}
				<span
					className={classnames("option", optionClassNames.option, "d-block")}
				>
					{optionText}
				</span>
				{/* DESCR */}
				<Text
					textId={descriptionTextId}
					className={classnames("descr", optionClassNames.descr, "d-block")}
				/>
			</div>
		</OptionStyled>
	);
};

export default MultipleChoiceOption;
