import React, { useEffect } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import FFButton from "../components/FFButton";
import { makeGetInspectorSelector } from "../selectors/AppSelectors";
import * as AppActions from "../redux/actions/AppActions";
import * as ConfigActions from "../redux/actions/ConfigActions";

const Inspector = ({ tabs, config = {}, pages, inspector }) => {
	const { open, tabIndex } = inspector;
	const { showInspector } = config;
	useEffect(() => {
		window.showInspector = () => toggleOpenInspector(true);
		// new alias
		window.inspectorOpen = () => toggleOpenInspector(true);
		// remove this  hacky trick if it's buggy - it will open the inspector even quicker just typing "ins" in Chrome because Chrome tries to give hints while typing
		window.inspectorOpen.toString = () => toggleOpenInspector(true);
		window.hideInspector = () => toggleOpenInspector(false);
		window.disableInspector = () => disableInspector();
		// new alias.  Use "Shut" instead of "Close" to have autocomplete prefere "Open" (alphabetically sorted)
		window.inspectorShut = () => toggleOpenInspector(false);
		window.inspectorDisable = () => disableInspector();
	}, []);

	const disableInspector = () => {
		const newInspector = { ...inspector };
		newInspector.open = false;
		AppActions.updateApp({ inspector: newInspector });
		ConfigActions.updateConfig({ showInspector: false });
	};

	const toggleOpenInspector = (open) => {
		const newInspector = { ...inspector };
		newInspector.open = open !== undefined ? open : !newInspector.open;
		newInspector.open && ConfigActions.updateConfig({ showInspector: true });
		AppActions.updateApp({ inspector: newInspector });
		return `Inspector is ${newInspector.open ? "open" : "closed"}!`;
	};

	const onDrawerClick = () => {
		toggleOpenInspector();
	};

	const onTabSelect = (tabIndex) => {
		const newInspector = { ...inspector };
		newInspector.tabIndex = tabIndex;
		AppActions.updateApp({ inspector: newInspector });
	};

	if (!showInspector) {
		return <React.Fragment />;
	}

	return (
		<div className={classnames("inspector", open ? "open" : "closed")}>
			<div className="drawer" style={{ zIndex: 2 }}>
				{open && (
					<Tabs selectedIndex={tabIndex} onSelect={onTabSelect}>
						<button
							onClick={disableInspector}
							style={{
								fontSize: 13,
								background: "black",
								position: "absolute",
								right: 0,
							}}
							className="btn"
						>
							💀
						</button>
						<TabList>
							{tabs.map((tab) => (
								<Tab key={`tab-${tab.title}`}>{tab.title}</Tab>
							))}
						</TabList>
						{tabs.map((tab) => (
							<TabPanel key={`tab-panel-${tab.title}`}>
								<tab.element
									config={config}
									pages={pages}
									{...tab.props}
									isInspectorOpen={open}
								/>
							</TabPanel>
						))}
					</Tabs>
				)}
				<FFButton className="drawer-button" onClick={onDrawerClick} />
			</div>
		</div>
	);
};

Inspector.defaultProps = { tabs: [] };

export default connect(() => {
	const getInspectorSelector = makeGetInspectorSelector();
	const mapStateToProps = (state, props) => {
		return {
			inspector: getInspectorSelector(state, props),
			config: state.config.item,
			pages: state.pages.items,
		};
	};
	return mapStateToProps;
})(Inspector);
