import React from "react";
import classnames from "classnames";
import _ from "lodash";
import { connect } from "../../utils/reduxUtils";
import { ReduxCollectionSelectors } from "funkis-foundation";
import Hover from "../../components/core/Hover";
import IconMembers from "../../assets/svg/IconMembers";
import { getBootstrapColor } from "../../utils/colorUtils";
import StatusIcon from "../core/StatusIcon";
import SelectItem from "../core/version-2/SelectItem";

const { makeGetItemByIdSelector } = ReduxCollectionSelectors;

const MemeberListItem = ({ member, selected, active, status }) => {
	const firstName =
		_.get(member, "firstName") || _.get(member, "user.firstName");
	const lastName = _.get(member, "lastName") || _.get(member, "user.lastName");
	const name = firstName + " " + lastName;
	const mail = _.get(member, "user.email");

	return (
		<Hover
			className="w-100 row m-0 align-items-stretch"
			style={{ maxHeight: "74px" }}
		>
			{({ hover }) => {
				const color = getBootstrapColor(
					(hover && "interaction") || (selected && "white") || "gray"
				);
				const fontWeight = hover || active ? "font-weight-bold" : "";

				return (
					<React.Fragment>
						<div className="col-sm-1 my-0 py-0 d-flex align-items-center">
							<SelectItem
								mode="select"
								selected={selected || active}
								className="mr-1"
								style={{ transform: "scale(1.2)" }}
							/>
							<IconMembers
								style={{ height: "59px", width: "88px" }}
								color={getBootstrapColor(
									(active && "primary") || (selected && "white") || "gray"
								)}
							/>
						</div>
						<div className="col-sm-2 d-flex align-items-center">
							<p
								style={{ color }}
								className={classnames(" member-name", fontWeight)}
							>
								{name}
							</p>
						</div>
						<div className="col-sm-7 d-flex align-items-center">
							<p
								style={{ color }}
								className={classnames("member-mail", fontWeight)}
							>
								{mail}
							</p>
						</div>
						<div className="col-sm-2 d-flex align-items-center">
							<StatusIcon
								color={getBootstrapColor("primary")}
								status={status}
								showLabel={false}
								iconStyle={{
									marginLeft: "14px",
									width: "34px",
									height: "34px",
								}}
							/>
						</div>
					</React.Fragment>
				);
			}}
		</Hover>
	);
};

const makeMapStateToProps = () => {
	const getItemByIdSelector = makeGetItemByIdSelector();
	const mapStateToProps = (state, props) => {
		return {
			member: getItemByIdSelector(state, props, "members"),
		};
	};
	return mapStateToProps;
};

export default connect(MemeberListItem, makeMapStateToProps);
