import { QuidInputActions, StoreUtils } from "funkis-foundation";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import uuid from "uuid/v4";
import {
	Backend,
	QuidInput,
	QuidInputParams,
	QuidInputUpdateParams,
	QuidType,
} from "../backend";
import { useDevMode } from "../player";

type LMSBackendProps = {
	targetDataKey: string;
	quidType: QuidType;
	shouldIncludeArchivedQuidInputs: boolean;
};

export const useBackend = ({
	targetDataKey,
	quidType,
	shouldIncludeArchivedQuidInputs = false,
}: LMSBackendProps): Backend => {
	const isLoading = useDevModeLoading();
	const quidInputs = useQuidInputs(
		targetDataKey,
		quidType,
		shouldIncludeArchivedQuidInputs
	);

	const createQuidInput = useMemo(
		() => (params: QuidInputParams) =>
			createQuidInputFunc(targetDataKey, quidType, params),
		[targetDataKey, quidType]
	);

	return {
		isLoading,
		isSuccess: !isLoading,
		error: undefined,
		quidInputs,
		createQuidInput,
		updateQuidInput,
		deleteQuidInput,
	};
};

/**
 * During development we briefly set `isLoading` to true
 * so that we can simulate the state transition that would happen
 * when running with the quiddity backend.
 **/
const useDevModeLoading = () => {
	const isDevMode = useDevMode();
	const [isLoading, setIsLoading] = useState(isDevMode ? true : false);

	useEffect(() => {
		if (!isDevMode) {
			return;
		}

		const timeoutId = setTimeout(() => {
			setIsLoading(false);
		}, Math.random() * 600);

		return () => {
			clearTimeout(timeoutId);
		};
	});

	return isLoading;
};

/**
 * We modify stored quid inputs to add a default "archived" flag as "false" and
 * make sure "sourceQuid" and "quidType" have the same values so that they can be
 * queried by legacy <Backend /> components that are still in use throughout funkis-template.
 **/
const normalizeQuidInputs = (
	prevQuidInputs: Partial<QuidInput>[]
): QuidInput[] => {
	const nextQuidInputs = prevQuidInputs.map(
		({
			id,
			targetDataKey,
			archived,
			sourceQuid,
			quidType,
			createdAt,
			updatedAt,
			data,
			targetQuidInput,
		}) =>
			({
				id,
				targetDataKey,
				archived: archived ?? false,
				sourceQuid: sourceQuid ?? quidType,
				quidType: sourceQuid ?? quidType,
				targetQuidInput,
				createdAt,
				updatedAt,
				data,
			} as QuidInput)
	);

	return nextQuidInputs;
};

const makeSelectQuidInputs = () =>
	createSelector(
		[
			(state: any) => state.quidInputs.items,
			(_: any, { targetDataKey }) => targetDataKey,
			(_: any, { shouldIncludeArchivedQuidInputs }) =>
				shouldIncludeArchivedQuidInputs,
			(_: any, { quidType }) => quidType,
		],
		(
			allQuidInputs: Partial<QuidInput>[],
			targetDataKey: string,
			shouldIncludeArchivedQuidInputs: boolean,
			quidType: QuidType
		) => {
			const targetDataKeys = targetDataKey.split(",");
			const query = {
				sourceQuid: quidType,
				...(shouldIncludeArchivedQuidInputs ? {} : { archived: false }),
			};

			const normalizedAllQuidInputs = normalizeQuidInputs(allQuidInputs || []);

			const quidInputs = targetDataKeys.flatMap(
				(targetDataKey): QuidInput[] => {
					const matchObject = { ...query, targetDataKey };
					return _.filter(normalizedAllQuidInputs, _.matches(matchObject));
				}
			);

			return quidInputs;
		}
	);

const useQuidInputs = (
	targetDataKey: string,
	quidType: QuidType,
	shouldIncludeArchivedQuidInputs: boolean
) => {
	const selectQuidInputs = useMemo(makeSelectQuidInputs, []);
	const quidInputs = useSelector((state) =>
		selectQuidInputs(state, {
			targetDataKey,
			quidType,
			shouldIncludeArchivedQuidInputs,
		})
	);

	return quidInputs;
};

const createQuidInputFunc = async (
	targetDataKey: string,
	quidType: QuidType,
	params: QuidInputParams
) => {
	const newQuidInput: QuidInput = {
		id: uuid(),
		sourceQuid: quidType,
		quidType: quidType,
		targetDataKey,
		archived: false,
		createdAt: Date.now().toString(),
		updatedAt: Date.now().toString(),
		data: {},
		...params,
	};

	QuidInputActions.updateQuidInput(newQuidInput.id, newQuidInput);
	return newQuidInput;
};

const getQuidInput = async ({ id }) => {
	return StoreUtils.getReducer("quidInputs").itemsById[id] as QuidInput;
};

const updateQuidInput = async ({
	id,
	data,
	archived,
	targetDataKey,
	targetQuidInput,
}: QuidInputUpdateParams) => {
	const prevQuidInput = await getQuidInput({ id });

	// Omit undefined so that we don't override the current QuidInput fields with undefined values
	const quidInputParams = _.omitBy(
		{ id, targetDataKey, data, archived, targetQuidInput },
		_.isUndefined
	);

	const nextQuidInput = {
		...prevQuidInput,
		...quidInputParams,
		updatedAt: Date.now().toString(),
	};
	QuidInputActions.updateQuidInput(id, nextQuidInput);

	return nextQuidInput;
};

const deleteQuidInput = async ({ id }) => {
	return QuidInputActions.removeQuidInput(id);
};
