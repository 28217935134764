import * as StoreUtils from "../../utils/StoreUtils";
import {
	UPDATE_PAGES,
	UPDATE_PAGE,
	REMOVE_PAGE,
	REMOVE_PAGES,
	ADD_PAGE,
	CHANGE_INDEX_FOR_PAGE,
	MAKE_PAGES_SNAPSHOT,
	RESTORE_FROM_PAGES_SNAPSHOT,
} from "../constants/ActionConstants";

export function updatePages(updates) {
	StoreUtils.getStore().dispatch(_updatePages(updates));
}

export function updatePage(id, updates) {
	StoreUtils.getStore().dispatch(_updatePage(Object.assign(updates, { id })));
}

export function addPage(item) {
	StoreUtils.getStore().dispatch(_addPage(Object.assign(item)));
}

export function removePage(id) {
	StoreUtils.getStore().dispatch(_removePage({ id }));
}

export function removePages(ids) {
	StoreUtils.getStore().dispatch(_removePages({ ids }));
}

export function makePagesSnapshot(item) {
	StoreUtils.getStore().dispatch(_makePagesSnapshot(item));
}

export function restoreFromPagesSnapshot(item) {
	StoreUtils.getStore().dispatch(_restoreFromPagesSnapshot(item));
}

function _updatePages(items) {
	return (dispatch: (action) => void) => {
		dispatch({ items, type: UPDATE_PAGES });
	};
}

function _updatePage(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: UPDATE_PAGE });
	};
}

function _removePage(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_PAGE });
	};
}

function _removePages(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: REMOVE_PAGES });
	};
}

function _addPage(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: ADD_PAGE });
	};
}

export function changeIndexForPage(id, toIndex) {
	StoreUtils.getStore().dispatch(_changeIndexForPage({ id }, toIndex));
}

function _changeIndexForPage(item, toIndex) {
	return (dispatch: (action) => void) => {
		dispatch({ item, toIndex, type: CHANGE_INDEX_FOR_PAGE });
	};
}

function _makePagesSnapshot(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: MAKE_PAGES_SNAPSHOT });
	};
}

function _restoreFromPagesSnapshot(item) {
	return (dispatch: (action) => void) => {
		dispatch({ item, type: RESTORE_FROM_PAGES_SNAPSHOT });
	};
}
