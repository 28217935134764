export const ON_CLICK = "onClick";
export const ON_ENTER = "onEnter";
export const ON_EXIT = "onExit";
export const ON_TIME = "onTime";
export const ON_START = "onStart";
export const ON_END = "onEnd";
export const ON_CAN_PLAY = "onCanPlay";
export const ON_PLAY = "onPlay";
export const ON_PAUSE = "onPause";
export const ON_CHANGE = "onChange";
export const ON_SELECT = "onSelect";
export const ON_COMPLETED = "onCompleted";
export const ON_INCOMPLETE = "onIncomplete";
export const ON_PASSED = "onPassed";
export const ON_FAILED = "onFailed";
export const ON_UNKNOWN = "onUnknown";
export const ON_CONDITIONALS_PASSED = "onConditionalsPassed";
export const ON_CONDITIONALS_FAILED = "onConditionalsFailed";
export const ON_WATCH_PASSED = "onWatchPassed";
export const ON_WATCH_FAILED = "onWatchFailed";
export const ON_BLUR = "onBlur";
export const ON_FOCUS = "onFocus";
export const ON_LOAD_PROGRESS = "onLoadProgress";
export const ON_LOAD_START = "onLoadStart";
export const ON_LOAD_COMPLETE = "onLoadComplete";
export const ON_DRAGG = "onDragg";
export const ON_DRAGG_IDLE = "onDraggIdle";
export const ON_DRAGG_START = "onDraggStart";
export const ON_DRAGG_END = "onDraggEnd";
export const ON_DRAGG_ENTER = "onDraggEnter";
export const ON_DRAGG_LEAVE = "onDraggLeave";
export const ON_DRAGG_OVER = "onDraggOver";
export const ON_DRAGG_DROP = "onDraggDrop";
export const ON_CUEPOINT_ENTER = "onCuepointEnter";
export const ON_CUEPOINT_EXIT = "onCuepointExit";
