import React from "react";

const IconPrint = (props) => {
	const { size = "md" } = props;

	switch (size) {
		case "md":
			return <IconPrintMedium {...props} />;
		case "lg":
			return <IconPrintLarge {...props} />;
	}
};

const IconPrintMedium = ({ className, style, color }) => {
	return (
		<svg
			width="22px"
			height="24px"
			className={className}
			style={style}
			viewBox="0 0 22 24"
			version="1.1"
		>
			<title>icon-print</title>
			<g
				id="icon-print"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g transform="translate(-242.000000, -8395.000000)">
					<rect fill="#FFFFFF" x="0" y="0" width="1280" height="9405"></rect>
					<g id="Group-45" transform="translate(242.000000, 8395.000000)">
						<g id="Group-7" transform="translate(0.000000, 1.000000)">
							<polygon
								id="Fill-1"
								fill="#000000"
								points="2.9997 -0.0004 2.9997 15.9996 -0.0003 15.9996 -0.0003 22.9996 21.9997 22.9996 21.9997 15.9996 18.9997 15.9996 18.9997 -0.0004"
							></polygon>
							<polygon
								id="Fill-2"
								fill="#FFFFFF"
								points="5 16 17 16 17 2 5 2"
							></polygon>
							<polygon
								id="Fill-3"
								fill="#FFFFFF"
								points="2 21 20 21 20 18 2 18"
							></polygon>
							<polygon
								id="Fill-4"
								fill="#000000"
								points="7 10 15 10 15 8 7 8"
							></polygon>
							<polygon
								id="Fill-5"
								fill="#000000"
								points="7 6 15 6 15 4 7 4"
							></polygon>
							<polygon
								id="Fill-6"
								fill="#000000"
								points="7 14 15 14 15 12 7 12"
							></polygon>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
const IconPrintLarge = ({ className, style, color }) => {
	return (
		<svg
			width="88px"
			height="84px"
			className={className}
			style={style}
			viewBox="0 0 88 84"
			version="1.1"
		>
			<defs>
				<rect
					id="path-1"
					x="0"
					y="-5.68434189e-14"
					width="1920"
					height="156"
				></rect>
			</defs>
			<g
				id="Blocks-Result"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="RESULT-PAGE" transform="translate(-1779.000000, -36.000000)">
					<g id="Rectangle">
						<use fill="black" fillOpacity="1"></use>
						<use fill="#FFFFFF" fillRule="evenodd"></use>
					</g>
					<path
						d="M1793,36 L1793,90 L1779,90 L1779,120 L1867,120 L1867,90 L1852.999,90 L1852.999,36 L1793,36 Z M1800.999,90 L1844.999,90 L1844.999,44 L1800.999,44 L1800.999,90 Z M1787,111.999 L1859,111.999 L1859,98 L1787,98 L1787,111.999 Z M1806.999,58.999 L1838.999,58.999 L1838.999,53 L1806.999,53 L1806.999,58.999 Z M1806.999,71 L1838.999,71 L1838.999,65 L1806.999,65 L1806.999,71 Z M1806.999,82 L1838.999,82 L1838.999,76 L1806.999,76 L1806.999,82 Z"
						id="Fill-1"
						fill={color}
					></path>
				</g>
			</g>
		</svg>
	);
};

export default IconPrint;
