import React from "react";

const IconReplay = ({ style, className }) => {
	return (
		<svg
			width="36px"
			height="42px"
			style={style}
			className={className}
			viewBox="0 0 36 42"
			version="1.1"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="Group-6" transform="translate(-27.000000, -21.000000)">
					<g
						id="Fill-4"
						transform="translate(27.883000, 21.484300)"
						fill="#FFFFFF"
					>
						<path d="M17.117,17.798 L5.273,8.899 L17.117,3.90798505e-14 L17.117,6.399 C26.555,6.399 34.234,14.078 34.234,23.515 C34.234,32.953 26.555,40.631 17.117,40.631 C7.679,40.631 1.84741111e-13,32.953 1.84741111e-13,23.515 L5,23.515 C5,30.196 10.436,35.631 17.117,35.631 C23.798,35.631 29.234,30.196 29.234,23.515 C29.234,16.835 23.798,11.399 17.117,11.399 L17.117,17.798 Z"></path>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default IconReplay;
