import React, { useEffect, useState, useRef } from "react";
import { FFText } from "funkis-foundation";
import { TweenMax, TimelineMax } from "gsap";
import classnames from "classnames";
import IconCheck from "../../../assets/svg/IconCheck";
import IconCheckIncorrect from "../../../assets/svg/IconCheckIncorrect";
import { getBootstrapColor } from "../../../utils/colorUtils";
import Style from "./SelectItem.module.css";
import Hover from "../Hover";
import Image from "../Image";
import { getText } from "../../../utils/textUtils";

const IconDot = ({ className, color }) => {
	return (
		<div
			className={classnames(Style.iconDot, className)}
			style={{ background: color }}
		/>
	);
};

const IconSquare = ({ className, color }) => {
	return (
		<div
			className={classnames(Style.iconSquare, className)}
			style={{ background: color }}
		/>
	);
};

const Icon = ({ type, className, style, mode }) => {
	// Mode radio
	if (mode === "radio") {
		switch (type) {
			case "selected":
				return (
					<IconDot
						className={className}
						style={style}
						color={getBootstrapColor("gray")}
					/>
				);
			case "unselected":
			case "none":
				return (
					<IconDot
						className={className}
						style={style}
						color={getBootstrapColor("transparent")}
					/>
				);
			case "selected-correct":
				return (
					<IconCheck
						className={className}
						style={style}
						color={getBootstrapColor("success")}
					/>
				);
			case "selected-incorrect":
				return (
					<IconCheckIncorrect
						className={className}
						style={style}
						color={getBootstrapColor("danger")}
					/>
				);
			case "unselected-correct":
				return (
					<IconDot
						className={className}
						style={style}
						color={getBootstrapColor("success")}
					/>
				);
			case "unselected-incorrect":
				return (
					<IconDot
						className={className}
						style={style}
						color={getBootstrapColor("transparent")}
					/>
				);
		}
	}
	// Mode select
	if (mode === "select") {
		switch (type) {
			case "selected":
				return (
					<IconCheck
						className={className}
						style={style}
						color={getBootstrapColor("black")}
					/>
				);
			case "unselected":
			case "none":
				return <div />;
			case "selected-correct":
				return (
					<IconCheck
						className={className}
						style={style}
						color={getBootstrapColor("success")}
					/>
				);
			case "selected-incorrect":
				return (
					<IconCheckIncorrect
						className={className}
						style={style}
						color={getBootstrapColor("danger")}
					/>
				);
			case "unselected-correct":
				return (
					<IconSquare
						className={className}
						style={style}
						color={getBootstrapColor("success")}
					/>
				);
			case "unselected-incorrect":
				return <div />;
		}
	}
};

const SelectItem = ({
	className,
	textId,
	bodyTextId,
	text,
	selected,
	index = 0,
	correctDelay = 0.6,
	status,
	style,
	correct,
	completed,
	mode,
	image,
}) => {
	const refIcon = useRef(null);
	const refOuterCircle = useRef(null);

	const [iconType, setIconType] = useState("unselected");
	const [innerCircleColor, setInnerCircleColor] = useState("gray");

	// Animations
	const animationCorrect = ({ delay, onStart }) => {
		const correctTimeLine = new TimelineMax();
		correctTimeLine.to(refIcon.current, 0.4, {
			scale: 3,
			delay,
			ease: "bounce.out",
			onStart,
		});
		correctTimeLine.to(refIcon.current, 0.4, { scale: 1 });
		return correctTimeLine;
	};
	const animationInCorrect = animationCorrect;
	const animationMissedCorrect = ({ delay, duration = 0.4, onStart }) => {
		const missedTimeLine = new TimelineMax();
		missedTimeLine.to(refIcon.current, duration, {
			opacity: 1,
			scale: 2,
			delay,
			ease: "bounce.out",
			onStart,
		});
		missedTimeLine.to(refIcon.current, duration, { opacity: 0.5, scale: 1 });
		missedTimeLine.to(refIcon.current, duration, { opacity: 1, scale: 2 });
		missedTimeLine.to(refIcon.current, duration, { opacity: 0.5, scale: 1 });
		missedTimeLine.to(refIcon.current, duration, { opacity: 1, scale: 2 });
		missedTimeLine.to(refIcon.current, duration, { opacity: 0.5, scale: 1 });
		missedTimeLine.to(refIcon.current, duration, { opacity: 1, scale: 2 });
		missedTimeLine.to(refIcon.current, duration, { opacity: 0.5, scale: 1 });
		missedTimeLine.to(refIcon.current, duration, { opacity: 1, scale: 2 });
		missedTimeLine.to(refIcon.current, duration, { opacity: 1, scale: 1 });
		return missedTimeLine;
	};
	const animationMissedIncorrect = ({ duration = 0.4, onStart }) => {
		const missedTimeLine = new TimelineMax();
		missedTimeLine.to(refIcon.current, duration, {
			opacity: 0.5,
			scale: 1,
			onStart,
		});
		return missedTimeLine;
	};
	const animationUnSelected = ({ delay, duration = 0.4, onStart }) => {
		TweenMax.to(refOuterCircle.current, duration, {
			opacity: 0.5,
			delay,
			onStart,
		});
	};
	const animationSelect = () => {
		return TweenMax.fromTo(
			refIcon.current,
			0.4,
			{ scale: 3 },
			{ scale: 1, ease: "bounce.out" }
		);
	};

	// Select mode...
	useEffect(() => {
		if (!correct || !completed) {
			// console.log("Select mode!");
			if (selected) {
				setIconType("selected");
				setInnerCircleColor("black");
				animationSelect();
			} else {
				setIconType("unselected");
				setInnerCircleColor("gray");
			}
		}
	}, [correct, completed, selected]);

	// Correct mode...
	useEffect(() => {
		if (completed && correct) {
			const animationDelay = correctDelay + index * 0.4;
			const animationDuration = 0.4;
			// console.log("Correct mode!", { status, animationDelay, animationDuration });
			switch (status) {
				case "selected-correct":
					animationCorrect({
						delay: animationDelay,
						duration: animationDuration,
						onStart: () => {
							setInnerCircleColor("transparent");
							setIconType("selected-correct");
						},
					});
					break;
				case "selected-incorrect":
					animationInCorrect({
						delay: animationDelay,
						duration: animationDuration,
						onStart: () => {
							setInnerCircleColor("transparent");
							setIconType("selected-incorrect");
						},
					});
					break;
				case "unselected-correct":
					animationMissedCorrect({
						delay: animationDelay,
						duration: animationDuration,
						onStart: () => {
							setIconType("unselected-correct");
							setInnerCircleColor("success");
						},
					});
					break;
				case "unselected-incorrect":
					animationMissedIncorrect({
						delay: animationDelay,
						duration: animationDuration,
						onStart: () => {
							setIconType("unselected-incorrect");
							setInnerCircleColor("transparent");
						},
					});
					break;
				default:
					animationUnSelected({
						delay: animationDelay,
						duration: animationDuration,
						onStart: () => {
							setInnerCircleColor("transparent");
							setIconType("none");
						},
					});
			}
		}
	}, [correct, completed]);

	const Item =
		(mode === "radio" && RadioItem) || (mode === "select" && CheckboxItem);

	return (
		<Hover
			className={classnames(
				Style.root,
				selected && Style.rootSelected,
				className
			)}
			style={{ alignItems: image ? "center" : "baseline", ...style }}
		>
			{({ hover }) => (
				<React.Fragment>
					<Item
						{...{
							innerCircleColor,
							refOuterCircle,
							refIcon,
							selected,
							iconType,
							status,
							hover,
							mode,
						}}
					/>
					{image && (
						<Image
							src={image}
							maxHeight={106}
							background
							style={{ maxWidth: "106px" }}
							className={classnames(
								"ml-2",
								"flex-shrink-0",
								"select-item__image"
							)}
						/>
					)}
					<div
						className={classnames(
							"ml-2 option align-self-center d-flex flex-column"
						)}
					>
						<FFText
							textId={textId}
							placeholder={text}
							className={classnames("font-weight-bold")}
							style={{
								color: getBootstrapColor(hover ? "interaction" : "black"),
							}}
						/>
						{getText(bodyTextId) && (
							<FFText
								textId={bodyTextId}
								placeholder={text}
								className={classnames("")}
								style={{
									color: getBootstrapColor(hover ? "interaction" : "black"),
								}}
							/>
						)}
					</div>
				</React.Fragment>
			)}
		</Hover>
	);
};

const RadioItem = ({
	innerCircleColor,
	refOuterCircle,
	refIcon,
	selected,
	iconType,
	status,
	hover,
	mode,
}) => {
	return (
		<div
			ref={refOuterCircle}
			className={classnames(Style.circleOuter)}
			style={{ borderColor: getBootstrapColor(hover ? "interaction" : "gray") }}
		>
			<div
				ref={refIcon}
				className={classnames(Style.circleInner)}
				style={{
					borderColor: getBootstrapColor(
						hover ? "interaction" : innerCircleColor
					),
					opacity: selected ? 1 : 0.5,
				}}
			>
				<div className="d-flex">
					<Icon
						type={iconType}
						style={{
							marginTop: status && status.includes("incorrect") ? "0" : 0,
						}}
						scale={1.2}
						mode={mode}
					/>
				</div>
			</div>
		</div>
	);
};

const CheckboxItem = ({ refOuterCircle, refIcon, iconType, status, mode }) => {
	return (
		<div ref={refOuterCircle} className={classnames(Style.checkOuter)}>
			<div ref={refIcon}>
				<Icon
					type={iconType}
					mode={mode}
					style={{
						marginTop:
							status && status.includes("incorrect") ? "-0.1875rem" : 0,
					}}
					scale={1.2}
				/>
			</div>
		</div>
	);
};

export default SelectItem;
