import { StoreUtils } from "..";
import _ from "lodash";
import {
	createQuidInput,
	getQuidInput,
	upsertQuidInput,
} from "../requests/QuidInputRequests";
import { getQuid } from "../requests/QuidRequests";
import {
	createQuidInputFromQuid,
	getQueryForContext as getQuidInputQueryForContext,
} from "../utils/QuidInputUtils";
import { getQueryForContext as getQuidQueryForContext } from "../utils/QuidUtils";
import * as StatusConstants from "../constants/StatusConstants";
import { convertStatusConstantToScormStatus } from "../utils/StatusUtils";

export default class QuiddityStorage {
	async save(name, domain, data) {
		await this.setData(name, domain, data);
	}

	async restore(name, domain, restoreFunction) {
		const data = await this.getData(domain);
		return restoreFunction(data[name]);
	}

	async clearData(domain) {
		// console.log("QuiddityStorage: clearData()");
		const appQuidInput = await this.getAppQuidInput();
		appQuidInput.data.suspendData = {};
		upsertQuidInput(this.getQuiddityUrl(), appQuidInput);
	}

	async getData(domain) {
		const appQuidInput = await this.getAppQuidInput();
		// console.log("getData", { appQuidInput });
		return _.get(appQuidInput, "data.suspendData", {});
	}

	async setData(name, domain, data) {
		const appQuidInput = await this.getAppQuidInput();
		const oldData = await this.getData(domain);
		// console.log("setData", { appQuidInput });
		appQuidInput.data.suspendData = { ...oldData, [name]: data };
		upsertQuidInput(this.getQuiddityUrl(), appQuidInput);
	}

	async setCourseStatus(domain, value) {
		console.log("QuiddityStorage: setCourseStatus", value);

		// Convert to succesStatus...
		let successStatus = value;
		if (
			successStatus === StatusConstants.COMPLETED ||
			successStatus ===
				convertStatusConstantToScormStatus(StatusConstants.COMPLETED)
		) {
			console.log(
				"QuiddityStorage: course is completed and success_status is therefore set to passed"
			);
			successStatus = convertStatusConstantToScormStatus(
				StatusConstants.PASSED
			);
		} else {
			console.log(
				"QuiddityStorage: course is not completed and success_status is therefore set to failed"
			);
			successStatus = convertStatusConstantToScormStatus(
				StatusConstants.FAILED
			);
		}

		const appQuidInput = await this.getAppQuidInput();
		appQuidInput.data.successStatus = value;
		appQuidInput.data.courseStatus = value;
		appQuidInput.data.completionStatus = value;

		return upsertQuidInput(this.getQuiddityUrl(), appQuidInput);
	}

	async getCourseStatus(domain) {
		const appQuidInput = await this.getAppQuidInput();
		const value = _.get(appQuidInput, "data.courseStatus");
		console.log("QuiddityStorage: getCourseStatus", value);
		return value;
	}

	async setCompletionStatus(domain, value) {
		console.log(
			`QuiddityStorage: setCompletionStatus: Setting completion status with value:`,
			value
		);
		const appQuidInput = await this.getAppQuidInput();
		appQuidInput.data.completionStatus = value;
		appQuidInput.data.courseStatus = value;
		return upsertQuidInput(this.getQuiddityUrl(), appQuidInput);
	}

	async getCompletionStatus(domain) {
		const appQuidInput = await this.getAppQuidInput();
		const value = _.get(appQuidInput, "data.completionStatus");
		console.log(
			`QuiddityStorage: getCompletionStatus: Getting completion status'. Value:`,
			value
		);
		return appQuidInput.data.completionStatus;
	}

	async setSuccessStatus(domain, value) {
		console.log(
			`QuiddityStorage: setSuccessStatus: Setting success status with value:`,
			value
		);
		const appQuidInput = await this.getAppQuidInput();
		appQuidInput.data.successStatus = value;
		return upsertQuidInput(this.getQuiddityUrl(), appQuidInput);
	}

	async getSuccessStatus(domain) {
		console.log(
			`QuiddityStorage: getSuccessStatus: Getting success status'. Value:`,
			value
		);
		const appQuidInput = await this.getAppQuidInput();
		return appQuidInput.data.successStatus;
	}

	async setScore(domain, rawScore, minScore, maxScore) {
		console.log("QuiddityStorage: setScore", rawScore, minScore, maxScore);
		const appQuidInput = await this.getAppQuidInput();
		appQuidInput.data.rawScore = rawScore;
		appQuidInput.data.minScore = minScore;
		appQuidInput.data.maxScore = maxScore;
		appQuidInput.data.scaledScore = (rawScore / maxScore).toFixed(2);

		return upsertQuidInput(this.getQuiddityUrl(), appQuidInput);
	}

	async getScore(domain) {
		const appQuidInput = await this.getAppQuidInput();
		var value = appQuidInput.data.rawScore;
		console.log("LocalStorage: getScore", value);
		return value;
	}

	getUserName(domain) {
		console.warn(`QuiddityStorage: getUserName(). Not implemented!`);
	}

	getUserId(domain) {
		console.warn(`QuiddityStorage: getUserId(). Not implemented!`);
	}

	// Helpers

	getQuiddityUrl() {
		const { url, api } = StoreUtils.getReducer("quiddity").item;
		return url + api;
	}

	async getAppQuid() {
		const quids = StoreUtils.getReducer("quids").items;
		const query = getQuidQueryForContext("session", {
			type: "app",
			template: true,
		});
		return (
			_.filter(quids, query)[0] ||
			(await getQuid(this.getQuiddityUrl(), { ...query }))
		);
	}

	getAppQuidInput = _.throttle(this._getAppQuidInput, 500);

	async _getAppQuidInput() {
		const { session, member, group } = StoreUtils.getReducer("quiddity").item;
		const { id, program_type } = StoreUtils.getReducer("app").item;
		const quid = await this.getAppQuid();
		const quidInputs = StoreUtils.getReducer("quidInputs").items;
		const context =
			(program_type === "slide" && "group") ||
			(program_type === "scroll" && "member") ||
			"session";

		const query = getQuidInputQueryForContext(context, {
			sourceQuid: quid.id,
			quidditySession: session.id,
			member: context === "member" && member.id,
			group: context === "group" && group.id,
			targetDataKey: id,
		});

		const reducerQuidInput = _.filter(quidInputs, query)[0];
		const fetchedQuidInput =
			!reducerQuidInput &&
			(await getQuidInput(this.getQuiddityUrl(), { ...query }));

		if (!reducerQuidInput && !fetchedQuidInput) {
		}

		const createdQuidInput =
			!reducerQuidInput &&
			!fetchedQuidInput &&
			(await createQuidInput(
				this.getQuiddityUrl(),
				createQuidInputFromQuid(quid, query)
			));

		return reducerQuidInput || fetchedQuidInput || createdQuidInput;
	}
}
