import React from "react";

const IconStatusIncomplete = ({
	className,
	style,
	color = "#00005A",
	variant = "normal",
}) => {
	if (variant === "normal") {
		return (
			<svg
				width="24px"
				height="24px"
				className={className}
				style={style}
				viewBox="0 0 24 24"
				version="1.1"
			>
				<g
					id="Symbols"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
				>
					<g id="Group">
						<circle
							id="Oval-Copy-20"
							fill={color}
							cx="12"
							cy="12"
							r="12"
						></circle>
						<polyline
							id="Rectangle-Copy-34"
							stroke="#FFFFFF"
							strokeWidth="2"
							transform="translate(11.659001, 10.451058) rotate(21.000000) translate(-11.659001, -10.451058) "
							points="13.6590006 5.45105761 13.6590006 15.4510576 9.6590006 15.4510576"
						></polyline>
					</g>
					<rect
						id="Rectangle-Copy-4"
						stroke="#CA0000"
						strokeWidth="11"
						x="-140"
						y="-2115"
						width="4075"
						height="5819"
					></rect>
				</g>
			</svg>
		);
	}

	if (variant === "large") {
		return (
			<svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
				<g
					id="Symbols"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
				>
					<g
						id="maIn-menu/page-menu-item/status-completed"
						transform="translate(-3.000000, -3.000000)"
					>
						<g id="Group" transform="translate(3.000000, 3.000000)">
							<circle
								id="Oval-Copy-20"
								fill={color}
								cx="15"
								cy="15"
								r="15"
							></circle>
							<polyline
								id="Rectangle-Copy-34"
								stroke="#FFFFFF"
								strokeWidth="2"
								transform="translate(14.573751, 13.063822) rotate(21.000000) translate(-14.573751, -13.063822) "
								points="17.0737508 6.81382201 17.0737508 19.313822 12.0737508 19.313822"
							></polyline>
						</g>
					</g>
				</g>
			</svg>
		);
	}

	return <></>;
};

export default IconStatusIncomplete;
