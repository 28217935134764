import _ from "lodash";
import { getByPaths, pickByTypes } from "./ObjectUtils";

export const getQueryForContext = (context, options, omit = []) => {
	const validOptions = pickByTypes(options, {
		id: _.isString,
		title: _.isString,
		type: _.isString,
		archived: _.isObject,
		data: _.isObject,
		quidInputModel: _.isObject,
		quidModel: _.isObject,
		properties: _.isObject,
		template: _.isBoolean,
		limit: _.isNumber,
		localization: _.isObject,
		throttle: _.isBoolean,
		throttleWait: _.isNumber,
	});

	const quidditySession = getByPaths(options, [
		"sessionId",
		"quidditySessionId",
		"session.id",
		"session",
	]);
	const quiddityEvent = getByPaths(options, [
		"eventId",
		"quiddityEventId",
		"event.id",
		"event",
	]);

	switch (context) {
		case "event":
			return _.omit(
				{
					...validOptions,
					...pickByTypes(
						{
							quiddityEvent,
						},
						{ quiddityEvent: _.isString }
					),
				},
				omit
			);
		case "session":
			return _.omit(
				{
					...validOptions,
					...pickByTypes(
						{
							quidditySession,
						},
						{ quidditySession: _.isString }
					),
				},
				omit
			);
		default:
			throw new Error(`Context ${context} is not valid!`);
	}
};
