import classnames from "classnames";
import Slider, { SliderMarks } from "components/Slider/Slider";
import Stack from "components/Stack/Stack";
import Text from "funkis-template/components/core/Text";
import { QuidInput } from "funkis-template/hooks/backend";
import { useText } from "funkis-template/hooks/text";
import { TextId } from "funkis-template/models/player";
import _ from "lodash";
import { FC, ReactNode } from "react";
import {
	Bar,
	CartesianGrid,
	ComposedChart,
	LabelList,
	ResponsiveContainer,
	XAxis,
} from "recharts";
import Style from "./RateOutputRow.module.css";

export type Marker = "number" | "letter" | "none";

export type RateOutputRowProps = {
	marker: Marker;
	index?: number;
	description: TextId;
	value: number;
	min: number;
	max: number;
	marks: SliderMarks;
	badgesLeading?: ReactNode[];
	badgesTrailing?: ReactNode[];
	quidInputs: QuidInput[];
	variant: "slider" | "bar chart";
};

function getMarkerText(marker: Marker, index?: number): string | undefined {
	switch (marker) {
		case "letter":
			const letters = [
				"a",
				"b",
				"c",
				"d",
				"e",
				"f",
				"g",
				"h",
				"i",
				"j",
				"k",
				"l",
				"m",
				"n",
				"o",
				"p",
				"q",
				"r",
				"s",
				"t",
				"u",
				"v",
				"w",
				"x",
				"y",
				"z",
			];
			return letters[index ?? 0];

		case "number":
			return "" + ((index ?? 0) + 1);

		case "none":
			return undefined;
	}
}

const RateOutputRow: FC<RateOutputRowProps> = ({
	marker,
	index,
	description,
	value,
	min,
	max,
	marks,
	badgesLeading = [],
	badgesTrailing = [],
	quidInputs,
	variant,
}) => {
	const markerText = getMarkerText(marker, index);

	const markTexts = useText(_.mapValues(marks, "label"));
	const data = Object.keys(marks).map((markKey) => ({
		name: markTexts[markKey],
		value: _.filter(quidInputs, ["data.value", parseInt(markKey)]).length,
	}));

	return (
		<div className={classnames(Style.row)}>
			{markerText && (
				<div
					className={classnames(Style.marker, "message-xl font-weight-bold")}
				>
					{markerText}
				</div>
			)}

			<Stack direction="vertical" spacing="xxsmall">
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<Stack direction="horizontal" align="start">
						{badgesLeading}
					</Stack>
					<Stack direction="horizontal" align="end">
						{badgesTrailing}
					</Stack>
				</div>

				<Stack direction="vertical" spacing="small">
					{variant === "slider" ? (
						<Slider
							tone="neutral"
							min={min}
							max={max}
							value={value}
							marks={marks}
							tooltip="auto"
							onChange={_.noop}
							disabled={true}
							step={0.1}
							track={true}
						/>
					) : variant === "bar chart" ? (
						<ResponsiveContainer width={"100%"} height={200}>
							<ComposedChart
								data={data}
								barSize={150}
								margin={{ top: 24, bottom: 12 }}
							>
								<CartesianGrid stroke="#f5f5f5" />
								<Bar
									dataKey="value"
									fill="var(--rate-output-result-color)"
									isAnimationActive={false}
								>
									<LabelList dataKey="value" position="top" />
								</Bar>
								<XAxis
									dataKey="name"
									dy={10}
									stroke={"black"}
									tick={{ fill: "black" }}
									tickLine={{ stroke: "black" }}
								/>
							</ComposedChart>
						</ResponsiveContainer>
					) : null}

					<Text
						tagName="p"
						className="p-md font-weight-bold"
						textId={description}
					/>
				</Stack>
			</Stack>
		</div>
	);
};

export default RateOutputRow;
