import classnames from "classnames";
import FormattedPercentage from "components/FormattedPercentage/FormattedPercentage";
import Stack from "components/Stack/Stack";
import MissingIOError from "errors/MissingIOError";
import MissingListError from "errors/MissingListError";
import Text from "funkis-template/components/core/Text";
import ChartFactory from "funkis-template/components/scroll/charts/ChartFactory";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { QuidType, useBackend } from "funkis-template/hooks/backend";
import { useValueList } from "funkis-template/hooks/valuelist";
import { DilemmaContentArray } from "funkis-template/models/contentarray";
import { getChartDataFromQuidInputs } from "funkis-template/utils/quidInputUtils";
import { getLetterByIndex } from "funkis-template/utils/stringUtils";
import _ from "lodash";

const PageItemIOMultipleChoiceOutput = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;

	const contentArray: DilemmaContentArray = useValueList(
		cmtPageItem.valuelist_id
	);

	if (!cmtPageItem.ios.answers) {
		throw new MissingIOError("answers", cmtPageItem.symbol_name);
	}

	if (
		contentArray?.values === undefined ||
		contentArray?.values?.length === 0
	) {
		throw new MissingListError(cmtPageItem.symbol_name);
	}

	const options = contentArray.values;
	const chartType = cmtPageItem.chart_type || cmtPageItem.theme || "pie";

	const { quidInputs } = useBackend({
		targetDataKey: cmtPageItem.ios.answers.id,
		inputContext: cmtPageItem.ios.answers.context,
		quidType: QuidType.Dilemma,
	});

	const responseCount = quidInputs.length;
	const quidInputsWithOnlySingleSelectedIndexes = quidInputs.map((qi) => ({
		...qi,
		data: {
			...qi.data,
			selectedIndexes: qi.data.selectedIndexes.slice(0, 1),
		},
	}));

	const data = getChartDataFromQuidInputs({
		quidInputs: quidInputsWithOnlySingleSelectedIndexes,
		options: contentArray.values,
	});

	return (
		<PageItemScrollBase
			{...props}
			showNextButton={true}
			className="px-0 mx-0"
			containerClassName="w-100"
			renderFunction={(renderProps) => (
				<div className="container-fluid">
					<div
						className={classnames(
							"row m-0 mb-4 animated",
							renderProps.viewVisible ? "fadeIn" : "fadeOut"
						)}
					>
						{/* HEADER */}
						<Text
							tagName="h2"
							textId={cmtPageItem.header}
							className="col-sm-12 font-weight-bold"
						/>
					</div>
					{/* STATS */}

					<div className={classnames("row m-0 mb-4 justify-content-center")}>
						<Stack spacing="large" align="center">
							<ChartFactory
								data={data}
								type={chartType}
								color={cmtPageItem.base_color}
							/>

							{/* BODY */}
							<Text
								tagName="div"
								textId={cmtPageItem.body}
								className={classnames(
									"col-sm-12 p-medium animated",
									renderProps.viewVisible ? "fadeIn" : "fadeOut"
								)}
							/>

							{/* OPTIONS */}
							<div
								className={classnames(
									"row m-0 animated",
									renderProps.viewVisible ? "fadeIn" : "fadeOut"
								)}
							>
								{options.map((option, index) => {
									const optionData = data.find((item) => item.id === index);
									const stringValue = _.get(optionData, "percent", "0");
									const value = parseInt(stringValue);

									return (
										<div
											key={option.id}
											className={classnames(
												"col-md-6 animated mb-5",
												renderProps.viewVisible ? "fadeInUp" : "fadeOut"
											)}
										>
											<Stack spacing="xsmall">
												<h3 className="m-0 font-weight-bold">
													<span className="text-uppercase">
														{getLetterByIndex(index)}:
													</span>
													{` `}
													<FormattedPercentage value={value} />
												</h3>

												<Text
													tagName="h4"
													textId={option.header}
													balance={true}
													className="font-weight-bold m-0"
												/>
												<Text textId={option.body} className="p-medium" />
											</Stack>
										</div>
									);
								})}
							</div>
						</Stack>
					</div>
				</div>
			)}
		/>
	);
};

export default PageItemIOMultipleChoiceOutput;
