import React, { useEffect, useRef } from "react";
import { FFText } from "funkis-foundation";
import classnames from "classnames";
import { TimelineMax, TweenMax } from "gsap";

import Style from "./SelectItem.module.css";
import IconCheck from "../../assets/svg/IconCheck";
import IconCheckIncorrect from "../../assets/svg/IconCheckIncorrect";
import IconMissed from "../../assets/svg/IconMissed";
import { getBootstrapColor } from "../../utils/colorUtils";
import Hover from "./Hover";

const SelectItem = ({
	enabled,
	className,
	textId,
	text,
	selected,
	status,
	style,
	index = 0,
	correctDelay = 0.6,
	completed,
}) => {
	const refIconContainer = useRef(null);
	const refCheckOuter = useRef(null);

	const colorIcon =
		(status === "correct" && getBootstrapColor("success")) ||
		(status === "incorrect" && getBootstrapColor("danger")) ||
		(status === "missed" && getBootstrapColor("success")) ||
		"#000000";
	const Icon =
		(status === "incorrect" && IconCheckIncorrect) ||
		(status === "missed" && IconMissed) ||
		IconCheck;

	useEffect(() => {
		if (!completed) {
			return;
		}
		const delay = correctDelay * index;
		switch (status) {
			case "correct":
			case "incorrect":
				const incorrectTimeLine = new TimelineMax();
				incorrectTimeLine.to(refIconContainer.current, 0.4, {
					scale: 3,
					delay,
					ease: "bounce.out",
				});
				incorrectTimeLine.to(refIconContainer.current, 0.4, { scale: 1 });
				incorrectTimeLine.play();
				break;
			case "missed":
				const missedTimeLine = new TimelineMax();
				missedTimeLine.to(refIconContainer.current, 0.4, {
					opacity: 1,
					scale: 2,
					delay,
					ease: "bounce.out",
				});
				missedTimeLine.to(refIconContainer.current, 0.4, {
					opacity: 0.5,
					scale: 1,
				});
				missedTimeLine.play();
				break;
			default:
				TweenMax.to(refCheckOuter.current, 0.4, { opacity: 0.5, delay });
		}
	}, [status, completed]);

	useEffect(() => {
		if (selected) {
			TweenMax.fromTo(
				refIconContainer.current,
				0.4,
				{ scale: 3 },
				{ scale: 1, ease: "bounce.out" }
			);
		}
	}, [selected]);

	return (
		<Hover
			className={classnames(
				Style.root,
				selected && Style.rootSelected,
				className
			)}
			style={style}
		>
			{({ hover }) => {
				return (
					<React.Fragment>
						<div ref={refCheckOuter} className={classnames(Style.checkOuter)}>
							<div ref={refIconContainer}>
								{(selected || status === "missed") && (
									<Icon className="icon" color={colorIcon} />
								)}
							</div>
						</div>
						<FFText
							textId={textId}
							placeholder={text}
							className={classnames(
								"option ml-2 font-weight-bold",
								hover && "text-decoration-underline"
							)}
							style={{
								color: getBootstrapColor(hover ? "interaction" : "black"),
							}}
						/>
					</React.Fragment>
				);
			}}
		</Hover>
	);
};

export default SelectItem;
