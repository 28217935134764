import {
	initialStateObjectCollection,
	updateItemInStateItems,
	updateItemsInStateItems,
	removeItemInStateItems,
	addItemInStateItems,
} from "../utils/reducerUtils";

import {
	UPDATE_SUBTITLES,
	UPDATE_SUBTITLE,
	ADD_SUBTITLE,
	REMOVE_SUBTITLE,
	CLEAR_SUBTITLES,
} from "../constants/ActionConstants";

const subtitles = (state = initialStateObjectCollection(), action) => {
	switch (action.type) {
		case UPDATE_SUBTITLES:
			return updateItemsInStateItems(state, action.items);
		case UPDATE_SUBTITLE:
			return updateItemInStateItems(state, action.item);
		case ADD_SUBTITLE:
			return addItemInStateItems(state, action.item);
		case REMOVE_SUBTITLE:
			return removeItemInStateItems(state, action.item);
		case CLEAR_SUBTITLES:
			return { items: [], itemsById: {} };
		default:
			return state;
	}
};

export default subtitles;
