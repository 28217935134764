import React from "react";

const IconStatusNotAttempted = ({ className, style, variant = "normal" }) => {
	if (variant === "normal") {
		return (
			<svg
				width="24px"
				height="24px"
				className={className}
				style={style}
				viewBox="0 0 24 24"
				version="1.1"
			>
				<g
					id="Symbols"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
				>
					<g id="menu/page-menu-item/status-not-started" fill="#BABABA">
						<circle id="Oval" cx="12" cy="12" r="12"></circle>
					</g>
					<rect
						id="Rectangle-Copy-4"
						stroke="#CA0000"
						strokeWidth="11"
						x="-140"
						y="-2153"
						width="4075"
						height="5819"
					></rect>
				</g>
			</svg>
		);
	}

	if (variant === "large") {
		return (
			<svg
				width="30px"
				height="30px"
				viewBox="0 0 30 30"
				version="1.1"
				className={className}
				style={style}
			>
				<g
					id="Symbols"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
				>
					<g
						id="maIn-menu/page-menu-item/status-not-started"
						transform="translate(-3.000000, -3.000000)"
						fill="#CCCCCC"
					>
						<circle id="Oval" cx="18" cy="18" r="15"></circle>
					</g>
				</g>
			</svg>
		);
	}

	return <></>;
};

export default IconStatusNotAttempted;
