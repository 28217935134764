import React from "react";

class TabWebLinks extends React.Component {
	render() {
		const style = { color: "lightgray", fontWeight: "bold" };
		return (
			<div>
				<div className="ff-button">
					<a style={style} target="_blank" href="./content/json">
						Browse json folder
					</a>
				</div>
				<div className="ff-button">
					<a style={style} target="_blank" href="./build_time.html">
						Open build_time.html
					</a>
				</div>
			</div>
		);
	}
}

export default TabWebLinks;
