import KnowledgeQuiz, {
	KnowledgeQuizAnswersModel,
	KnowledgeQuizFeedbackModel,
} from "components/KnowledgeQuiz/KnowledgeQuiz";
import { GlobalText } from "components/KnowledgeQuiz/KnowledgeQuiz.constants";
import { getCorrectlyAnsweredQuestions } from "components/KnowledgeQuiz/KnowledgeQuiz.utils";
import { KnowledgeQuizQuestionModel } from "components/KnowledgeQuiz/KnowledgeQuizQuestion";
import { PageItemActions } from "funkis-foundation";
import PageItemScrollBase from "funkis-template/components/scroll/PageItemScrollBase";
import { IOContext, QuidType, useBackend } from "funkis-template/hooks/backend";
import { KnowledgeQuizScrollBlock } from "funkis-template/models/pageitem";
import { PageItem } from "funkis-template/models/player";
import { getBootstrapColor } from "funkis-template/utils/colorUtils";
import _ from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import {
	composeQuidInput,
	composeQuizAnswers,
	getQuizPageBlocks,
} from "./PageItemKnowledgeQuiz.utils";

const { updatePageItem } = PageItemActions;

const KnowledgeQuizScrollBlockComponent: FC<{
	pageItem: PageItem<KnowledgeQuizScrollBlock>;
}> = (props) => {
	const { pageItem } = props;
	const { cmtPageItem, status } = pageItem;
	const { quiz_page } = cmtPageItem;

	if (_.isEmpty(cmtPageItem.ios?.quiz_data)) {
		throw Error(
			"Knowledge Quiz cannot be rendered. It's missing an IO for 'quiz_data'."
		);
	}

	const [quizBlocks, setQuizBlocks] = useState<any[]>([]);

	useEffect(() => {
		const cmtPageItems = getQuizPageBlocks(quiz_page);
		cmtPageItems.length && setQuizBlocks(cmtPageItems);
	}, [quiz_page]);

	const {
		quidInputs,
		isSuccess,
		createQuidInput,
		updateQuidInput,
		deleteQuidInput,
	} = useBackend({
		targetDataKey: cmtPageItem.ios.quiz_data.id,
		inputContext: cmtPageItem.ios.quiz_data.context,
		quidType: QuidType.Quiz,
	});

	const questions: KnowledgeQuizQuestionModel[] = quizBlocks
		.filter((qb) => qb.symbol_name === "quiz-question")
		.map((qb) => ({
			title: GlobalText.QuestionTitle,
			id: qb.id,
			question: qb.question,
			media: qb.media,
			options: qb.content_array.map((ca) => ({
				id: ca.id,
				textId: ca.option,
				correct: ca.correct,
			})),
		}));

	const scoreQuizBlock = quizBlocks.find(
		(qb) => qb.symbol_name === "quiz-score"
	);

	const feedbacks: KnowledgeQuizFeedbackModel[] = scoreQuizBlock
		? [
				{
					textId: scoreQuizBlock.feedback_1,
					threshold: scoreQuizBlock.feedback_1_threshold,
				},
				{
					textId: scoreQuizBlock.feedback_2,
					threshold: scoreQuizBlock.feedback_2_threshold,
				},
				{
					textId: scoreQuizBlock.feedback_3,
					threshold: scoreQuizBlock.feedback_3_threshold,
				},
				{
					textId: scoreQuizBlock.feedback_4,
					threshold: scoreQuizBlock.feedback_4_threshold,
				},
				{
					textId: scoreQuizBlock.feedback_5,
					threshold: scoreQuizBlock.feedback_5_threshold,
				},
		  ]
		: [];

	const summaryQuizBlock = quizBlocks.find(
		(qb) => qb.symbol_name === "quiz-summary"
	);

	const firstQuidInput = quidInputs.at(0);
	const quidInputId = firstQuidInput?.id;

	const handleQuizChange = useCallback(
		(newAnswers: KnowledgeQuizAnswersModel) => {
			const nextQuidInput = composeQuidInput(newAnswers);

			if (quidInputId) {
				updateQuidInput({ id: quidInputId, ...nextQuidInput });
			} else {
				createQuidInput(nextQuidInput);
			}
		},
		[quidInputId, updateQuidInput, createQuidInput]
	);

	const handleQuizSubmit = useCallback(
		(newAnswers: KnowledgeQuizAnswersModel) => {
			const correctlyAnsweredQuestions = getCorrectlyAnsweredQuestions(
				questions,
				newAnswers
			);
			const hasPassedThreshold =
				correctlyAnsweredQuestions.length >= cmtPageItem.passed_threshold;

			const areAllQuestionsAnswered =
				Object.entries(newAnswers).length === questions.length;

			if (areAllQuestionsAnswered) {
				if (
					cmtPageItem.completion_criteria === "passed" &&
					hasPassedThreshold
				) {
					updatePageItem(pageItem.id, { status: "completed" });
				} else if (cmtPageItem.completion_criteria === "after one run") {
					updatePageItem(pageItem.id, { status: "completed" });
				}
			}
		},
		[questions, cmtPageItem, pageItem]
	);

	if (_.isEmpty(quiz_page)) {
		return (
			<div>
				<p>Knowledge Quiz cannot be shown</p>
				<p>"Quiz page" has not been set in FAT</p>
			</div>
		);
	}

	if (!isSuccess) {
		return <div></div>;
	}

	const quizAnswers = firstQuidInput ? composeQuizAnswers(firstQuidInput) : {};

	const shouldShowNextButton =
		cmtPageItem.show_next_button === "always" || status === "completed";

	const optionBackgroundColor =
		cmtPageItem.option_background_color !== ""
			? getBootstrapColor(cmtPageItem.option_background_color)
			: undefined;

	return (
		<PageItemScrollBase
			{...props}
			containerClassName=""
			showNextButton={shouldShowNextButton}
			renderFunction={() => {
				return (
					<KnowledgeQuiz
						summaryTitle={summaryQuizBlock?.header}
						variant={cmtPageItem.variant}
						optionColor={optionBackgroundColor}
						questions={questions}
						onChange={handleQuizChange}
						onSubmit={handleQuizSubmit}
						answers={quizAnswers}
						feedbacks={feedbacks}
					/>
				);
			}}
		/>
	);
};

export default KnowledgeQuizScrollBlockComponent;
