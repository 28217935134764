import React from "react";
import _ from "lodash";
import { StoreUtils } from "funkis-foundation";
import { useSelector } from "react-redux";
import { QuiddityBackend } from "quiddity";
import LocalBase, {
	save as saveToLms,
	remove as removeFromLms,
	saveItems as saveItemsToLms,
	getItems as getLmsItems,
} from "./local/LocalBase";

const saveToQuiddity = QuiddityBackend.save;
const saveItemsToQuiddity = QuiddityBackend.saveItems;
const getQuiddityItems = QuiddityBackend.getItems;

const Backend = (props) => {
	const { pageItem } = props;
	const quidInputContext = useSelector((state) => {
		const programType = _.get(state.app.item, "program_type");
		return (
			_.get(props, "pageItem.cmtPageItem.io_context") ||
			props.inputContext ||
			(programType === "slide" && "group") ||
			(programType === "scroll" && "member")
		);
	});
	const backend = useSelector((state) => _.get(state.config.item, "backend"));
	const targetDataKey =
		props.targetDataKey ||
		_.get(props, "pageItem.cmtPageItem.valuelist_id") ||
		_.get(props, "pageItem.cmtPageItem.io_id") ||
		(pageItem && pageItem.id);

	const newProps = { ...props, backend, quidInputContext };

	if (backend === "quiddity") {
		return <QuiddityBackend {...newProps} targetDataKey={targetDataKey} />;
	}

	return <LocalBase {...newProps} targetDataKey={targetDataKey} />;
};

export const save = ({ id, data, ioID }) => {
	const app = StoreUtils.getReducer("app").item;
	const { backend } = app;
	switch (backend) {
		case "quiddity":
			return saveToQuiddity({ id, data });

		case "lms":
			return saveToLms({ id, data, ioID });

		default:
			throw new Error(`Backend ${backend} not found`);
			break;
	}
};

export const remove = ({ id }) => {
	const app = StoreUtils.getReducer("app").item;
	const { backend } = app;
	switch (backend) {
		case "quiddity":
			return QuiddityBackend.remove({ id });
		case "lms":
			return removeFromLms({ id });
		default:
			throw new Error(`Backend ${backend} not found`);
			break;
	}
};

export const saveItems = ({ items }) => {
	const app = StoreUtils.getReducer("app").item;
	const { backend } = app;
	switch (backend) {
		case "quiddity":
			return saveItemsToQuiddity({ items });

		case "lms":
			return saveItemsToLms({ items });

		default:
			throw new Error(`Backend ${backend} not found`);
			break;
	}
};

export const getItems = ({ ioID }) => {
	const app = StoreUtils.getReducer("app").item;
	const { backend } = app;
	switch (backend) {
		case "quiddity":
			return getQuiddityItems({ match: { targetDataKey: ioID } });
			break;
		case "lms":
			return getLmsItems({ match: { ioID } });
			break;
		default:
			throw new Error(`Backend ${backend} not found`);
			break;
	}
};

export default Backend;
