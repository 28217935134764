import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { StoreUtils, PageItemActions } from "funkis-foundation";
import RevealButton from "./RevelButton";

const Reveals = (props) => {
	const { page } = props;

	const pageItems = StoreUtils.getReducer("pageItems").items;
	const { program_type } = StoreUtils.getReducer("app").item || {};
	const revealPageItems = pageItems
		.filter(
			(pageItem) =>
				pageItem.parentPageId === page.id && !pageItem.parentPageItemId
		)
		.filter((pageItem) => pageItem.type === "reveal" || pageItem.cmtPageItem);

	if (!revealPageItems.length) {
		return <div />;
	}

	const revealAll = () => {
		PageItemActions.updatePageItems(
			revealPageItems.map((pageItem) => ({ id: pageItem.id, reveal: true }))
		);
	};

	const unrevealAll = () => {
		PageItemActions.updatePageItems(
			revealPageItems.map((pageItem) => ({ id: pageItem.id, reveal: false }))
		);
	};

	const isSlideProgram = program_type == "slide";

	return (
		<div
			className={classNames(
				"d-flex mt-2",
				!isSlideProgram
					? "flex-column mb-2 mr-5 border p-3"
					: "justify-content-center align-items-center",
				{ "text-white": isSlideProgram }
			)}
		>
			{!isSlideProgram ? (
				<React.Fragment>
					<button className="btn-sm btn-success mt-1" onClick={revealAll}>
						{"REVEAL"}
					</button>
					<button className="btn-sm btn-danger mt-1" onClick={unrevealAll}>
						{"UNREVEAL"}
					</button>
					<div className="w-100 border-top my-2" />
				</React.Fragment>
			) : null}
			{revealPageItems.map((pageItem, index) => (
				<RevealButton
					key={`RevealButton-${pageItem.id}`}
					id={pageItem.id}
					isSlidePageItem={isSlideProgram}
					cmtPageItemId={_.get(pageItem, "cmtPageItem.id")}
					index={index}
				/>
			))}
		</div>
	);
};

export default Reveals;
