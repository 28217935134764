let _store;

export function setStore(store) {
	_store = store;
}

export function getStore() {
	return _store;
}

export function getState() {
	return _store.getState();
}

export function getReducer(reducer) {
	return getState()[reducer];
}
