import React from "react";
import { FFText } from "funkis-foundation";

const IconWithLabel = (props) => {
	const {
		className = "d-flex align-items-center mr-2",
		iconClassName,
		labelTagName = "span",
		labelClassName = "ml-1 main-menu-icon",
		labelTextId,
		labelText,
		Icon,
		iconColor,
		textColor,
		renderIcon,
		show = true,
		showLabel = true,
		style = {},
		iconStyle = {},
	} = props;

	if (!show) {
		return <React.Fragment />;
	}

	return (
		<div className={className} style={style}>
			{renderIcon ? (
				renderIcon(props)
			) : (
				<Icon className={iconClassName} color={iconColor} style={iconStyle} />
			)}
			{showLabel && (
				<FFText
					tagName={labelTagName}
					textId={labelTextId}
					className={labelClassName}
					placeholder={labelText}
					style={{
						marginBottom: "0.1rem",
						marginRight: "0.2rem",
						color: textColor,
					}}
				/>
			)}
		</div>
	);
};

export default IconWithLabel;
