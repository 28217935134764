import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import classnames from "classnames";
import LoaderRoller from "../loaders/LoaderRoller";
import { StoreUtils } from "funkis-foundation";
import {
	upsertQuidInput,
	createQuidInput,
} from "../../requests/QuidInputRequests";
import { getQuids } from "../../requests/QuidRequests";
import { getQuidInputs } from "../../requests/QuidInputRequests";
import { getQueryForContext as getQuidInputQueryForContext } from "../../utils/QuidInputUtils";
import * as QuidInputContext from "../../constants/QuidInputContexts";
import { useQuidInputs } from "../../hooks/selectors/quidInputSelectors";
import { useQuidTemplate } from "../../hooks/selectors/quidSelectors";
import { useQuids } from "../../hooks/selectors/quidSelectors";

const RenderNotLoggedIn = (props) => {
	return (
		<p className="text-warning">{`Not correctly logged for context: ${
			props.logInType || props.quidInputContext
		}!`}</p>
	);
};

const RenderFetch = (props) => {
	return <LoaderRoller />;
};

const RenderLoggedIn = (props) => {
	return <React.Fragment />;
};

const QuiddityBase = (props) => {
	const {
		// Base...
		className,
		style,
		hide,
		children,
		// Query props...
		url = "",
		api = "/api",
		quidType,
		quidContext = QuidInputContext.EVENT,
		quidInputContext = QuidInputContext.SESSION,
		targetQuidId,
		targetQuidInputId,
		targetDataKey,
		throttle = false, // Throttle cancels query that already been called.
		throttleWait = 0, // If set the amount of time that throttle is active.
		//Filters...
		quidFilter = (ids) => ids,
		quidInputFilter = (ids) => ids,
		// Callbacks...
		onFetchComplete,
		// Render functions...
		renderLoggedIn = RenderLoggedIn,
		renderNotLoggedIn = RenderNotLoggedIn,
		renderFetch = RenderFetch,
		// From map stat to props...
		// quidTemplateId,
	} = props;

	const quiddity = useSelector((state) => state.quiddity.item, shallowEqual);
	const quidIds = useQuids(props);
	const quidTemplateId = useQuidTemplate(props);
	const quidInputIds = useQuidInputs(props);

	const isReady =
		quiddity &&
		((quiddity.session && quidInputContext === QuidInputContext.SESSION) ||
			(quiddity.member && quidInputContext === QuidInputContext.MEMBER) ||
			(quiddity.group && quidInputContext === QuidInputContext.GROUP) ||
			(quiddity.group &&
				quidInputContext === QuidInputContext.CURRENT_GROUP_MEMBERS));
	const [isQuidsFetched, setIsQuidsFetched] = useState(
		typeof quidTemplateId === "string"
	);
	const [isQuidInputsFetched, setIsQuidInputsFetched] = useState(false);

	// GET QUID TEMPLATE...
	useEffect(() => {
		if (isReady && !quidTemplateId && !isQuidsFetched) {
			getQuids(url + api, {
				type: quidType,
				template: true,
				throttle,
				throttleWait,
			});
			setIsQuidsFetched(true);
		}
	}, [isReady, isQuidsFetched, quidTemplateId]);

	// GET QUID INPUTS...
	useEffect(() => {
		if (isReady && quidTemplateId && !isQuidInputsFetched) {
			const targetDataKeys = targetDataKey && targetDataKey.split(",");

			const query = getQuidInputQueryForContext(quidInputContext, {
				...quiddity,
				sourceQuid: quidTemplateId,
				targetQuidInputId,
				targetQuidId,
				targetDataKey: targetDataKeys,
				throttle,
				throttleWait,
			});

			getQuidInputs(url + api, query).then((quidInputs) => {
				onFetchComplete && onFetchComplete({ ...props, quidInputs, save });
			});
			setIsQuidInputsFetched(true);
		}
	}, [isReady, quidTemplateId, isQuidInputsFetched]);

	const save = async ({ quidInput, data, alwaysCreate = false }) => {
		const quiddity = StoreUtils.getReducer("quiddity").item;
		const { url, api } = quiddity;
		const query = getQuidInputQueryForContext(quidInputContext, {
			...props,
			...quiddity,
			...quidInput,
			sourceQuid: quidTemplateId,
			data: { ...quidInput.data, ...data },
		});
		return alwaysCreate
			? await createQuidInput(url + api, query)
			: await upsertQuidInput(url + api, query);
	};

	// Some helping checks...
	if (!quidType || !quidContext || !quidInputContext) {
		return (
			<p>{`Missing required props! ${(!quidType && "quidType") || ""} ${
				(!quidContext && "quidContext") || ""
			} ${(!quidInputContext && "quidInputContext") || ""}`}</p>
		);
	}

	const newProps = {
		...props,
		quidIds: quidFilter(quidIds),
		quidInputIds: quidInputFilter(quidInputIds),
		quidTemplateId,
	};

	return (
		<div className={classnames("quiddity-base", className)} style={style}>
			{(hide && <React.Fragment />) ||
				(!isReady && renderNotLoggedIn(newProps)) ||
				((!isQuidInputsFetched || !isQuidsFetched) &&
					renderFetch(newProps)) || [
					renderLoggedIn({ key: "quiddity-base-logged-in", ...newProps }),
					children,
				]}
		</div>
	);
};

export default QuiddityBase;
