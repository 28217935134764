import config from "./redux/reducers/configReducer";
import location from "./redux/reducers/locationReducer";
import pages from "./redux/reducers/pagesReducer";
import pageItems from "./redux/reducers/pageItemsReducer";
import app from "./redux/reducers/appReducer";
import texts from "./redux/reducers/textsReducer";
import subtitles from "./redux/reducers/subtitlesReducer";
import sprigstories from "./redux/reducers/sprigStoriesReducer";
import quidInputs from "./redux/reducers/quidInputsReducer";
import timers from "./redux/reducers/timersReducer";

let reducers = {
	config,
	location,
	pages,
	pageItems,
	app,
	texts,
	subtitles,
	sprigstories,
	quidInputs,
	timers,
};

export function getReducers() {
	return reducers;
}

export function addReducer(reducerKey, reducer) {
	reducers[reducerKey] = reducer;
}
