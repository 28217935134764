import React from "react";
import classnames from "classnames";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import Backend from "../../components/backend/Backend";
import { getText } from "../../utils/textUtils";
import ActionItem from "../../components/core/ActionItem";
import IconPenAndPaper from "../../assets/svg/IconPenAndPaper";
import Text from "../../components/core/Text";
import FormattedDate from "components/FormattedDate/FormattedDate";

const PageItemIOActionOutput = (props) => {
	const { pageItem } = props;
	const { cmtPageItem } = pageItem;
	return (
		<PageItemScrollBase
			{...props}
			containerClassName="container-fluid"
			showNextButton={cmtPageItem.show_next_button !== "no"}
			renderFunction={(renderProps) => {
				const { io_id } = cmtPageItem;
				return (
					<Backend
						className={classnames("mt-4 row m-0")}
						pageItem={pageItem}
						quidType="action"
						mode="bareOutputs"
						targetDataKey={io_id}
						match={{ targetDataKey: io_id }}
						renderFunction={(backendRenderProps) => {
							const { quidInputs } = backendRenderProps;

							return (
								<React.Fragment>
									{/* HEADER */}
									<Text
										className={classnames(
											"col-12 mb-5 font-weight-bold animated",
											renderProps.viewVisible ? "fadeIn" : "fadeOut"
										)}
										tagName="h2"
										textId={cmtPageItem.header}
									/>
									{/* BODY */}
									<Text
										className="col-md-10 mb-4"
										tagName="p"
										textId={cmtPageItem.body}
									/>
									{/* RESULT */}
									{quidInputs.map((quidInput) => {
										const { data = {} } = quidInput;
										const { what, descr, who, prio, when } = data;
										const breaklinePerservingStyles = {
											whiteSpace: "pre-line",
										};
										return (
											<React.Fragment>
												<div className="col-12" style={{ height: 0 }}>
													<IconPenAndPaper className="absolute-right mr-3" />
												</div>
												<div
													className="col-12 mb-4"
													style={{ minHeight: "70px" }}
												>
													{/* WHAT */}
													<h4 className="font-weight-bold">{what}</h4>
													{/* DESCR */}
													{descr && (
														<p
															style={breaklinePerservingStyles}
															className="p-sm"
														>
															{descr}
														</p>
													)}
												</div>
												{/* WHO */}
												{who && (
													<ActionItem
														header={getText(
															"E7B6168F-B7F5-44D8-8C41-4FAF6B19FD2F"
														)}
														body={who}
														icon="person"
														className="col-sm-4"
														classNameHeader="p-sm font-weight-bold"
														classNameBody="p-sm"
													/>
												)}
												{/* PRIO */}
												{prio && (
													<ActionItem
														header={getText(
															"16EF4270-B06B-475D-89E3-0EB025C24867"
														)}
														prio={prio}
														body={prio}
														icon="prio"
														className="col-sm-4"
														classNameHeader="p-sm font-weight-bold"
														classNameBody="p-sm"
													/>
												)}
												{/* WHEN */}
												{prio && (
													<ActionItem
														header={getText(
															"8F0FCE29-62B6-49E8-B26E-B1721D0DE709"
														)}
														prio={prio}
														body={
															when ? <FormattedDate date={when} /> : undefined
														}
														icon="calender"
														className="col-sm-4"
														classNameHeader="p-sm font-weight-bold"
														classNameBody="p-sm"
													/>
												)}
												<div className="col-12">
													<hr />
												</div>
											</React.Fragment>
										);
									})}
								</React.Fragment>
							);
						}}
					></Backend>
				);
			}}
		/>
	);
};

export default PageItemIOActionOutput;
