import { ColorUtils } from "funkis-foundation";

export const getBootstrapColor = (name: string, lighten = 0): string => {
	return ColorUtils.getBootstrapColor(name, lighten);
};

export const getColorArray = ({
	color = "primary",
	length,
	darken = -90,
}: {
	color: string;
	length: number;
	darken: number;
}): string[] => {
	const colors: string[] = [];
	for (let index = 0; index < length; index++) {
		const lighten = (index / length) * (darken / length);
		colors.push(getBootstrapColor(color, lighten));
	}

	return colors;
};

type Overrides = Record<string, string | undefined>;

/**
 * Get a bootstrap style override object that only has overrides for the properties where the provided value is present.
 *
 * @example
 * const safeOverrides = getBootstrapOverridesForPresentValues({ color: cmtPageItem.color });
 * // if cmtPageItem.color exists, will return { color: "some resolved color" }
 * // otherwise, will return { }
 *
 */
export const getBootstrapOverridesForPresentValues = (
	potentialOverrides: Overrides = {}
): Overrides => {
	const safeOverrides = {};

	for (const [propertyName, propertyValue] of Object.entries(
		potentialOverrides
	)) {
		if (propertyValue) {
			safeOverrides[propertyName] = getBootstrapColor(propertyValue);
		}
	}

	return safeOverrides;
};
