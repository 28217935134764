import { QuidInput } from "funkis-template/hooks/backend";
import _ from "lodash";
import { useSelector } from "react-redux";

type Tag = {
	io_id: string;
	tag_category: string;
	tag_id: string;
	tag_text: string;
	tag_text_id: string;
	tag_text_pure: string;
	valuelist_id: string;
	valuelistitem_id: string;
};

export const useTags = (tags: Tag[] = []) => {
	const targetDataKeys = tags.map((tag) => tag.io_id);

	const quidInputs: QuidInput[] = useSelector((state: any) => {
		return state.quidInputs.items.filter(
			(item: QuidInput) =>
				targetDataKeys.includes(item.targetDataKey) && !item.archived
		);
	});

	const selectionIds = _.map(quidInputs, "data.contentItemId");

	const isSomeTagSelected = tags.some((tag) => {
		return selectionIds.includes(tag.valuelistitem_id);
	});

	return { isTagSelected: isSomeTagSelected, hasTags: tags.length > 0 };
};
