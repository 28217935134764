import _ from "lodash";
import { LocationUtils } from "funkis-foundation";

const { getQueryObjectFromCurrentUrl } = LocationUtils;

export const getSessionKey = ({ config, cmtConfig, localStorageKey }) => {
	let sessionKey;
	// 1. urlQuery
	sessionKey = getQueryObjectFromCurrentUrl().sessionKey;
	if (sessionKey && localStorageKey) {
		window.localStorage.setItem(localStorageKey, sessionKey);
	}
	// 2. localStorage
	if (!sessionKey && localStorageKey) {
		sessionKey = window.localStorage.getItem(localStorageKey);
	}
	// 3. cmt
	if (!sessionKey) {
		sessionKey = _.get(cmtConfig, "quiddity_key");
	}
	// 4. config
	if (!sessionKey) {
		sessionKey = _.get(config, "quiddity.key");
	}

	return sessionKey;
};
