import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { FFText } from "funkis-foundation";
import { getBootstrapColor } from "../../../../utils/colorUtils";
import Styles from "./QuizOption.module.css";
import Hover from "../../../core/Hover";
import { TweenMax, Power2 } from "gsap";

type QuizOptionProps = {
	className: string;
	style: React.CSSProperties;
	option: string;
	show: boolean;
	showDelay: number;
	textColor: string;
	baseColor: string;
	interactionColor: string;
	correct: boolean;
	complete: boolean;
	selected: boolean;
	onComplete: () => void;
};

const QuizOption: React.FC<QuizOptionProps> = ({
	className,
	style,
	option,
	show,
	showDelay = 0,
	textColor = "white",
	baseColor = "primary",
	interactionColor = "interaction",
	correct,
	complete,
	selected,
	onComplete,
}) => {
	const ref = useRef(null);

	const resetTween = () => {
		TweenMax.set(ref.current, {
			opacity: 0,
			scale: 0.8,
		});
	};

	const [isAnimationComplete, setIsAnimationComplete] = useState(false);
	const [hasCalledOnComplete, setHasCalledOnComplete] = useState(false);
	
	useEffect(() => {
		if(isAnimationComplete && !hasCalledOnComplete) {
			onComplete();
			setHasCalledOnComplete(true);
		}
	}, [isAnimationComplete, onComplete, hasCalledOnComplete])

	useEffect(() => {
		if(isAnimationComplete) return;

		if (complete && selected) {
			if (correct) {
				TweenMax.to(ref.current, 0.3, {
					scale: 1.2,
					repeat: 3,
					yoyo: true,
					onComplete: () => setIsAnimationComplete(true),
				});
			} else {
				TweenMax.to(ref.current, 0.2, {
					opacity: 0.2,
					repeat: 7,
					yoyo: true,
					onComplete: () => setIsAnimationComplete(true),
				});
			}
		}
	}, [complete, correct, selected, onComplete, isAnimationComplete]);

	useEffect(() => {
		if (show) {
			TweenMax.to(ref.current, 0.6, {
				delay: showDelay,
				opacity: 1,
				scale: 1,
				ease: Power2.easeOut,
			});
		} else {
			resetTween();
		}
	}, [show, showDelay]);

	return (
		<div className="position-relative">
			<Hover
				className={classnames(
					"h-100 position-relative",
					className,
					!show && "pointer-events-none"
				)}
				style={style}
			>
				{({ hover }) => {
					// BACKGROUND BASE COLOR
					const backgroundColor =
						(complete && correct && selected && "success") ||
						(complete && !correct && selected && "fail") ||
						(hover && interactionColor) ||
						baseColor;
					// OUTER BACKGROUND
					const backgroundOuter = `linear-gradient(180deg, ${getBootstrapColor(
						backgroundColor,
						40
					)} 80%, ${getBootstrapColor(backgroundColor, -50)} 100%)`;
					// INNER BACKGROUND
					const backgroundInner = `linear-gradient(180deg, ${getBootstrapColor(
						backgroundColor,
						15
					)} 0%, ${getBootstrapColor(backgroundColor, -15)} 100%)`;
					return (
						<div
							ref={ref}
							className={classnames(
								Styles.root,
								"h-100 d-flex justify-content-stretch"
							)}
							style={{
								background: backgroundOuter,
								padding: "2px",
								borderRadius: "8px",
								opacity: complete && !selected ? 0.2 : 1,
							}}
						>
							<div
								className="position-relative w-100 px-4 pt-2"
								style={{ background: backgroundInner, borderRadius: "6px" }}
							>
								<FFText
									className="quiz-button-label font-weight-bold d-block"
									textId={option}
									style={{
										color: getBootstrapColor(textColor),
										wordWrap: "break-word",
										hyphens: "none",
									}}
								/>
							</div>
						</div>
					);
				}}
			</Hover>
		</div>
	);
};

export default QuizOption;
