import React from "react";

const IconPlus = ({
	color = "#FFFFFF",
	style,
	className,
	variant = "default",
	...rest
}) => {
	if (variant === "thick") {
		return (
			<svg
				width="44px"
				height="44px"
				className={className}
				style={style}
				viewBox="0 0 44 44"
				version="1.1"
				{...rest}
			>
				<g
					id="Symbols"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
				>
					<g id="plus" fill={color}>
						<polygon id="Fill-4" points="0 27 44 27 44 17 0 17"></polygon>
						<polygon id="Fill-6" points="17 44 27 44 27 0 17 0"></polygon>
					</g>
				</g>
			</svg>
		);
	}

	return (
		<svg
			width="49px"
			height="48px"
			className={className}
			style={style}
			viewBox="0 0 49 48"
			version="1.1"
			{...rest}
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<rect
					id="Rectangle"
					stroke="#FF0D0D"
					strokeWidth="10"
					x="-946"
					y="-821"
					width="2681"
					height="6727"
				></rect>
				<g
					id="Buttons/New-item-BLACK"
					transform="translate(-103.000000, -19.000000)"
				>
					<rect
						id="Rectangle"
						fill="none"
						x="0"
						y="0"
						width="256"
						height="105"
						rx="52.5"
					></rect>
					<path
						d="M128.5,21 L128.5,65 M105.5,43 L149.5,43"
						id="Combined-Shape"
						stroke={color}
						strokeWidth="4"
						strokeLinecap="square"
					></path>
				</g>
			</g>
		</svg>
	);
};

export default IconPlus;
