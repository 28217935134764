module.exports = {
	STUDENT_NAME: "cmi.core.student_name",
	STUDENT_ID: "cmi.core.student_id",

	LESSON_STATUS: "cmi.core.lesson_status",
	LESSON_LOCATION: "cmi.core.lesson_location",
	SUSPEND_DATA: "cmi.suspend_data",

	SCORE_RAW: "cmi.core.score.raw",
	SCORE_MAX: "cmi.core.score.max",
	SCORE_MIN: "cmi.core.score.min",
};
