import React, { useEffect } from "react";
import classnames from "classnames";
import hexToRgba from "hex-to-rgba";
import {
	PageItemReveal as FunkisPageItemReveal,
	ReduxObjectSelectors,
	PageItemActions,
} from "funkis-foundation";
import { scrollPageItemToTop } from "../../actions/Actions";
import { connect } from "../../utils/reduxUtils";
import Image from "../core/Image";
import { makeGetPageType } from "../../../selectors/playerSelectors";

const { makeGetValueSelector } = ReduxObjectSelectors;
const { updatePageItem } = PageItemActions;

const Reveal = (props) => {
	const {
		pageItem,
		children,
		background,
		dividerColor,
		dividerStyle,
		dividerImage,
		pageType,
		enabled = true,
	} = props;
	const { autoScrollOnReval = true, showDivider = true } = pageItem;
	const showDelimitter =
		props.showDivider !== false &&
		showDivider &&
		(!background || background === "");
	const hasDividerColor = dividerColor && dividerColor !== "";

	useEffect(() => {
		// Note: Add pageType check to prevent scrolling when page with blocks is present in the main menu.
		if (pageItem.revealed && autoScrollOnReval && pageType === "default") {
			scrollPageItemToTop({ pageItem, offsetTop: -135 });
			updatePageItem(pageItem.id, { autoScrollOnReval: false }); // Fix so page not scrolling when returning
		}
	}, [pageItem.revealed]);

	const colorA = hasDividerColor && hexToRgba(dividerColor, 0.5);
	const colorB = hasDividerColor && hexToRgba(dividerColor, 1);

	const borderStyle =
		(dividerStyle === "dashed" &&
			dividerColor && {
				backgroundImage: `linear-gradient(to right, ${colorA} 50%, ${colorB} 0%)`,
			}) ||
		(dividerColor && { background: dividerColor }) ||
		{};

	if (enabled) {
		return (
			<div className="w-100">
				<div className="container-fluid">
					<div className="row m-0">
						<div className="col-sm-12">
							{dividerImage ? (
								<Image
									className={classnames(
										"border-divider",
										showDelimitter && pageItem.reveal
											? "animated fadeIn delay-500ms"
											: "d-none opacity-0"
									)}
									src={dividerImage}
								/>
							) : (
								<div
									className={classnames(
										showDelimitter &&
											pageItem.reveal &&
											"border-divider animated fadeIn delay-500ms",
										dividerStyle === "solid" && !dividerColor && "bg-primary"
									)}
									style={borderStyle}
								/>
							)}
						</div>
					</div>
				</div>
				<FunkisPageItemReveal
					updateStatus={false}
					className={classnames(`w-100 page-item-id-${pageItem.id}`)}
					pageItem={{ ...pageItem, unmountChildrenWhenUnrevealed: true }}
				>
					{children}
				</FunkisPageItemReveal>
			</div>
		);
	} else {
		return children;
	}
};

const makeMapStateToProps = () => {
	const getValueSelector = makeGetValueSelector();
	const getPageType = makeGetPageType();
	const mapStateToProps = (state, props) => {
		return {
			pageType: getPageType(state, props),
			background: getValueSelector(
				state,
				{ ...props, path: "page_bg_image" },
				"app"
			),
			dividerColor: getValueSelector(
				state,
				{ ...props, path: "color_block_divider" },
				"app"
			),
			dividerStyle: getValueSelector(
				state,
				{ ...props, path: "block_divider" },
				"app"
			),
			dividerImage: getValueSelector(
				state,
				{ ...props, path: "block_divider_image" },
				"app"
			),
		};
	};
	return mapStateToProps;
};

export default connect(Reveal, makeMapStateToProps);
