import PageDefault from "./pages/PageDefault";
import PageScalable from "./pages/PageScalable";

let _pages = {
	default: PageDefault,
	scalable: PageScalable,
};

export function getPage(type) {
	if (_pages[type]) {
		return _pages[type];
	} else {
		console.warn(`No page for type ${type} found! Returning "default" page.`);
		return _pages["default"];
	}
}

export function addPage(type, page) {
	_pages[type] = page;
}

export function getAllPages() {
	return _pages;
}
