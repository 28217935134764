import React, { useState, useEffect, useMemo } from "react";
import classnames from "classnames";
import _ from "lodash";

const modifySvg = (
	svgString: string,
	templatingVariables: TemplatingVariables
): [svg: string, viewBox?: SVGRect] => {
	if (_.isEmpty(svgString)) {
		return [""];
	}

	const svgDoc = new DOMParser().parseFromString(svgString, "image/svg+xml");
	const documentElement = svgDoc.documentElement as HTMLElement & SVGSVGElement;

	for (const key in templatingVariables) {
		const queryResult = svgDoc.documentElement.querySelector(`#${key}`);
		if (queryResult) {
			queryResult.innerHTML = templatingVariables[key];
		}
	}

	const innerSvgString = svgDoc.documentElement.innerHTML;
	const viewBox: SVGRect = documentElement.viewBox.baseVal;

	return [innerSvgString, viewBox];
};

type TemplatingVariables = {
	[key: string]: string;
};

export type SvgInlineProps = {
	href: string;
	templatingVariables?: TemplatingVariables;
	style?: React.CSSProperties;
	className?: string;
	width?: string | number;
	height?: string | number;
};

const InlineSVG: React.FC<SvgInlineProps> = ({
	href,
	templatingVariables,
	style,
	className,
	width,
	height,
}) => {
	const [originalSvg, setOriginalSvg] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<unknown | undefined>(undefined);

	useEffect(() => {
		let isMounted = true;
		const fetchSvg = async (href: string) => {
			setIsLoading(true);
			const svgAsPlainText = await fetch(href).then((res) => res.text());
			if (isMounted) {
				setOriginalSvg(svgAsPlainText);
				setIsLoading(false);
			}
		};

		try {
			if (!_.isEmpty(href)) {
				fetchSvg(href);
			}
		} catch (e) {
			if (isMounted) {
				setError(e);
			}
		}

		return () => {
			isMounted = false;
		};
	}, [href]);

	const [modifiedSvg, viewBox] = useMemo(() => {
		return modifySvg(originalSvg, templatingVariables ?? {});
	}, [originalSvg, templatingVariables]);

	return (
		<svg
			style={style}
			width={width}
			height={height}
			preserveAspectRatio="none"
			className={classnames(className, { loading: isLoading, error: error })}
			dangerouslySetInnerHTML={{ __html: modifiedSvg }}
			viewBox={
				viewBox &&
				`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`
			}
			version="1.1"
		/>
	);
};

export default InlineSVG;
