import React, { useState, useEffect } from "react";
import _ from "lodash";
import classnames from "classnames";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Text from "../core/Text";
import TextInput from "../core/TextInput";
import Backend, { saveItems, getItems } from "../backend/Backend";
import { FFButton, FFText } from "funkis-foundation";
import IconDelete from "../../assets/svg/IconDelete";
import IconMustach from "../../assets/svg/IconMustach";

import StylesDefault from "./IOListsInput.module.css";
import StylesMustach from "./IOListsInputMustach.module.css";
import { getBootstrapColor } from "../../utils/colorUtils";

const getItemStyle = (isDragging, draggableStyle, theme) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	background: theme === "mustach" ? "rgba(255,255,255,0.4)" : "transparent",
	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = (isDraggingOver, theme) => ({});

const IOListsInputSortable = (props) => {
	const {
		header,
		body,
		className = "border-1 border-bottom border-light-gray overflow-hidden h-100",
		classNameHeader = "w-100 font-weight-bold",
		classNameBody = "w-100 mb-4",
		classNameTextArea = "bg-transparent",
		classNameOuterContainer = "",
		classNameListText = "font-weight-bold",
		classNameSaveButtonLabel = "text-uppercase",
		tagNameHeader = "h3",
		headerMustach,
		buttonLabel,
		targetDataKey,

		theme = "default",
		color,
		showFillet = true,
		onStatusChange,
	} = props;
	const [completed, setCompleted] = useState();
	const [canSave, setCanSave] = useState();
	const [textValue, setTextValue] = useState("");

	useEffect(() => {
		onStatusChange && onStatusChange(completed ? "completed" : "incomplete");
	}, [completed]);

	const Styles = (theme === "mustach" && StylesMustach) || StylesDefault;

	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const { destination } = result;
		const items = getItems({ ioID: targetDataKey });

		const draggedItem = items.find((item) => item.id === result.draggableId);
		draggedItem.data.index = destination.index;

		const notDraggedItems = _.sortBy(
			items.filter((item) => item.id !== result.draggableId),
			"data.index"
		);

		notDraggedItems.forEach((item, index) => {
			item.data.index = index < destination.index ? index : index + 1;
		});

		const updatedItems = _.flatten([notDraggedItems, [draggedItem]]);

		saveItems({ items: updatedItems });
	};

	const editTextInput = false;
	const editable = false;

	return (
		<div
			className={className}
			style={{
				background:
					theme === "default" ? getBootstrapColor(color) : "transparent",
				...props.style,
			}}
		>
			<Text
				className={classNameHeader}
				tagName={tagNameHeader}
				textId={header}
			/>
			<Text className={classNameBody} tagName="p" textId={body} />
			<div
				className={classnames(
					Styles.outerContainer,
					"outer-container w-100 d-flex flex-column align-items-center",
					classNameOuterContainer
				)}
				style={{ background: theme === "mustach" ? color : "transparent" }}
			>
				<div
					className={classnames(
						"inner-container",
						Styles.innerContainer,
						theme === "mustach" && "m-2"
					)}
				>
					{/* ---> DRAG AND DROP */}

					{theme === "mustach" && (
						<h2 className="w-100 text-center font-weight-bold">
							{headerMustach}
						</h2>
					)}
					{theme === "mustach" && <IconMustach />}
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId={targetDataKey}>
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
								>
									<Backend
										{...props}
										className=""
										quidType="comment"
										mode="inputs"
										sort={({ ids, items }) => {
											const itemsData = items.map((item) => ({
												...item.data,
												id: item.id,
											}));
											const sortedItems = _.sortBy(itemsData, "index");
											return sortedItems.map((item) => item.id);
										}}
										targetDataKey={targetDataKey}
										renderInputs={(renderProps) => {
											const { data, input, remove, index } = renderProps;
											return (
												<Draggable
													key={input.id}
													draggableId={input.id}
													index={index}
												>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={getItemStyle(
																snapshot.isDragging,
																provided.draggableProps.style,
																theme
															)}
															className={classnames(
																"d-flex w-100 justify-content-between mb-1",
																theme === "mustach" && "shadow-sm p-1"
															)}
														>
															<FFButton
																onClick={() => {
																	//   setTextValue(data.value);
																	//   remove();
																}}
															>
																<p className={classNameListText}>
																	{data.value}
																</p>
															</FFButton>
															<FFButton
																onClick={() => {
																	remove();
																}}
															>
																<IconDelete
																	className={Styles.iconDelete}
																></IconDelete>
															</FFButton>
														</div>
													)}
												</Draggable>
											);
										}}
									></Backend>
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>

					{/* <--- DRAG AND DROP */}
					<Backend
						{...props}
						className=""
						quidType="comment"
						mode="input"
						renderInput={(renderProps) => {
							const {
								hasSavedDataForKeys,
								hasUnsavedDataForKeys,
								updateData,
								create,
								data,
							} = renderProps;
							const showSendButton = hasUnsavedDataForKeys;
							setCompleted(hasSavedDataForKeys(["value"]));
							setCanSave(hasUnsavedDataForKeys(["value"]));
							// Save to quiddity an reset textfield...
							const save = () => {
								const items = getItems({ ioID: targetDataKey });
								create({
									targetDataKey,
									value: textValue,
									index: items.length,
								});
								setTimeout(() => {
									setTextValue("");
								}, 100);
							};
							return (
								<React.Fragment>
									<TextInput
										className="w-100 mt-4 mb-2"
										classNameTextArea={classNameTextArea}
										rows={1}
										value={textValue}
										editable={true}
										onChange={(value) => {
											setTextValue(value);
										}}
										onEnter={() => {
											save();
										}}
									/>

									{(showSendButton || editable) && (
										<div
											className={classnames(
												"row m-0",
												theme === "mustach"
													? "justify-content-center"
													: "justify-content-end"
											)}
										>
											<FFButton
												className={classnames(
													"col-sm-4 rounded-pill btn btn-black col animated",
													showSendButton || editTextInput ? "fadeIn" : "fadeOut"
												)}
												onClick={() => {
													save();
												}}
											>
												<FFText
													textId={buttonLabel}
													className={classNameSaveButtonLabel}
												/>
											</FFButton>
										</div>
									)}
								</React.Fragment>
							);
						}}
					></Backend>
				</div>
			</div>
			{showFillet && <div className={Styles.fillet} />}
		</div>
	);
};

export default IOListsInputSortable;
