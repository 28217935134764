import * as API from "../api";
import { getCode } from "../utils/QuiddityUtils";
import * as QuiddityEventActions from "../redux/actions/QuiddityEventActions";

export const getQuiddityEvents = async (url, query, addToStore = true) => {
	try {
		const events = await API.getQuiddityEvents(url, query, getCode());
		addToStore && QuiddityEventActions.updateQuiddityEvents(events);
		return events;
	} catch (error) {
		return [];
	}
};

export const getQuiddityEvent = async (url, query, addToStore = true) => {
	const events = await getQuiddityEvents(url, query, addToStore);
	return events[0];
};
