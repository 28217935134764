import classnames from "classnames";
import { ConfigActions, FFText } from "funkis-foundation";
import {
	getNativeLanguageNameForLanguageCode,
	getEnglishLanguageNameForLanguageCode,
} from "funkis-foundation/utils/LocalizationUtils";
import {
	useAllLanguages,
	useLanguageMenuSort,
	useSelectedLanguage,
} from "funkis-template/hooks/player";
import _ from "lodash";
import React from "react";

const { updateConfig } = ConfigActions;

function languagesToOptions(
	languageCodes: string[],
	type: "native" | "english"
): { value: string; label: string }[] {
	if (type === "native") {
		return languageCodes.map((value) => ({
			value,
			label: getNativeLanguageNameForLanguageCode(value),
		}));
	} else {
		return languageCodes.map((value) => ({
			value,
			label: getEnglishLanguageNameForLanguageCode(value),
		}));
	}
}

type LanguageMenuProps = {
	className?: string;
	classNameHeader?: string;
};

const LanguageMenu: React.FC<LanguageMenuProps> = (props) => {
	const { className, classNameHeader, children } = props;

	const selectedLanguage = useSelectedLanguage();
	const installedLanguages = useAllLanguages();
	const languageMenuSort = useLanguageMenuSort();

	let options: { value: string; label: string }[];
	if (languageMenuSort === "native") {
		options = _.sortBy(
			languagesToOptions(installedLanguages, "native"),
			"label"
		);
	} else if (languageMenuSort === "english") {
		options = _.sortBy(
			languagesToOptions(installedLanguages, "english"),
			"label"
		);
	} else {
		// "none", and also backwards compatible, catching "undefined", null etc.
		options = languagesToOptions(installedLanguages, "native");
	}

	const handleChange = React.useCallback((e) => {
		const nextLanguage = e.target.value;
		updateConfig({ language: nextLanguage });
	}, []);

	return (
		<div className={classnames("language-menu", className)}>
			<label htmlFor="pet-select">
				<FFText
					textId="98F8879A-99F4-43F4-90BC-CBD324E7A1B7"
					tagName="h4"
					className={classnames(classNameHeader)}
				/>
			</label>

			<select
				id="language-select"
				className="custom-select"
				value={selectedLanguage}
				onChange={handleChange}
			>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>

			{children}
		</div>
	);
};

export default LanguageMenu;
