import { KnowledgeQuizAnswersModel } from "components/KnowledgeQuiz/KnowledgeQuiz";
import { StoreUtils } from "funkis-foundation";
import { QuidInput, QuidInputParams } from "funkis-template/hooks/backend";

export function composeQuidInput(
	answers: KnowledgeQuizAnswersModel
): QuidInputParams {
	const quidInputAnswers = Object.entries(answers).reduce(
		(acc, [questionId, answerId]) => ({
			...acc,
			[questionId]: { answer: answerId },
		}),
		{}
	);
	return { data: { answers: quidInputAnswers } };
}

export function composeQuizAnswers(
	quidInput: QuidInput
): KnowledgeQuizAnswersModel {
	return Object.entries(quidInput.data?.answers ?? {}).reduce(
		(acc, [questionId, answerObject]: [string, any]) => {
			return { ...acc, [questionId]: answerObject.answer };
		},
		{}
	);
}

export function getQuizPageBlocks(pageId: string): any[] {
	return StoreUtils.getReducer("pageItems")
		.items.filter((item) => item.parentPageId === pageId && item.cmtPageItem)
		.map((item) => item.cmtPageItem);
}
