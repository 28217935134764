import Details from "components/Details/Details";
import { useText } from "funkis-template/hooks/text";
import { useTags } from "hooks/useTags";
import _ from "lodash";
import { CSSProperties, useState } from "react";
import { useSelector } from "react-redux";
import { getBootstrapColor } from "funkis-template/utils/colorUtils";
import Style from "./ReadMoreReveal.module.css";
import classnames from "classnames";

const ReadMoreReveal = (props) => {
	const { children, pageItem } = props;
	const { cmtPageItem } = pageItem;
	const tags = _.get(cmtPageItem, "tags", []);
	// This is for optimization...
	if (tags.length) {
		return <ReadMoreRevealRenderer {...props} />;
	} else {
		return children({
			isTagSelected: false,
			hasTags: false,
			showBlockDividers: true,
		});
	}
};

const ReadMoreRevealRenderer = (props) => {
	const { children, pageItem } = props;
	const { cmtPageItem } = pageItem;

	const tagDividerColor = useSelector(
		(state: any) => state.app.item.tag_divider_color
	);
	const tagTextColor = useSelector(
		(state: any) => state.app.item.tag_text_color
	);

	const tags = _.get(cmtPageItem, "tags", []);
	const { isTagSelected, hasTags } = useTags(tags);
	const tagTextIds = _.map(tags, "tag_text_id");
	const tagTexts = useText(tagTextIds).join(", ");

	const [open, setOpen] = useState(false);

	const onOpenClickHandler = () => {
		setOpen(!open);
	};

	if (isTagSelected || !hasTags) {
		return children({ isTagSelected, hasTags, showBlockDividers: true });
	}

	const styleOverrides = {
		"--details-text-color": getBootstrapColor(tagTextColor),
		"--details-icon-color": getBootstrapColor(tagTextColor),
		"--details-divider-color": getBootstrapColor(tagDividerColor),
	} as CSSProperties;

	return (
		<div
			className={classnames(Style.readMoreReveal, "container-fluid")}
			style={styleOverrides}
		>
			<div className="row m-0">
				<div className="col-sm-12">
					<Details
						expandLabel={<span>{tagTexts}</span>}
						collapseLabel={<span>{tagTexts}</span>}
						open={open}
						onToggle={onOpenClickHandler}
					>
						<div className="mx-sm-n5 mx-n4">
							{children({ isTagSelected, hasTags, showBlockDividers: false })}
						</div>
					</Details>
				</div>
			</div>
		</div>
	);
};

export default ReadMoreReveal;
