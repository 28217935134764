import {
	initialStateObject,
	updateStateItem,
	removeValueInStateItem,
	makeStateSnapshot,
	restoreStateSnapshot,
} from "../utils/reducerUtils";
import {
	UPDATE_CONFIG,
	UPDATE_CONFIG_VALUE,
	REMOVE_CONFIG_VALUE,
	MAKE_CONFIG_SNAPSHOT,
	RESTORE_FROM_CONFIG_SNAPSHOT,
} from "../constants/ActionConstants";

const config = (state = initialStateObject(), action) => {
	switch (action.type) {
		case UPDATE_CONFIG:
		case UPDATE_CONFIG_VALUE:
			return updateStateItem(state, action.item);
		case REMOVE_CONFIG_VALUE:
			return removeValueInStateItem(state, action.key);
		case MAKE_CONFIG_SNAPSHOT:
			return makeStateSnapshot(state, action.item);
		case RESTORE_FROM_CONFIG_SNAPSHOT:
			return restoreStateSnapshot(state, action.item);
		default:
			return state;
	}
};

export default config;
