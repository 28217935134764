import React from "react";
import { connect } from "../../utils/reduxUtils";
import {
	PageItemLinked as FoundationPageItemLinked,
	StoreUtils,
} from "funkis-foundation";
import { makeGetIsPrintPage } from "../../../selectors/playerSelectors";

const PageItemLinkedBlock = (props) => {
	const { pageItem, isPrintPage } = props;
	const { cmtPageItem = {} } = pageItem;
	const originalBlockId = cmtPageItem.original_block_id;
	const foundationPageItem = StoreUtils.getReducer("pageItems").items.find(
		(item) =>
			item.data && item.cmtPageItem && item.cmtPageItem.id === originalBlockId
	);
	if (!foundationPageItem) {
		return (
			<p className="color-warning">{`No block with id ${originalBlockId} found!`}</p>
		);
	}

	return (
		<FoundationPageItemLinked
			pageItem={{
				...pageItem,
				pageItemOverrides: [
					{
						id: foundationPageItem.id,
						isPrintPage,
					},
				],
				sourcePageItemId: foundationPageItem.id,
			}}
		/>
	);
};

const makeMapStateToProps = () => {
	const getIsPrintPage = makeGetIsPrintPage();

	const mapStateToProps = (state, props) => {
		return {
			isPrintPage: getIsPrintPage(state, props),
		};
	};
	return mapStateToProps;
};

export default connect(PageItemLinkedBlock, makeMapStateToProps);
