import React, { useState, useCallback, useLayoutEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import _ from "lodash";
import { TinyColor } from "@ctrl/tinycolor";

import * as StoreUtils from "../../utils/StoreUtils";
import { updateWindowHash } from "../../utils/LocationUtils";
import * as ConfigActions from "../../redux/actions/ConfigActions";
import FFButton from "../../components/FFButton";
import FFText from "../../components/FFText";
import { getEnglishLanguageNameForLanguageCode } from "../../utils/LocalizationUtils";
import { makeGetConfigSelector } from "../../selectors/ConfigSelectors";
import { makeGetAppSelector } from "../../selectors/AppSelectors";
import { clearStorage } from "../../actions/MiscActions";
import { updateAppValue } from "../../redux/actions/AppActions";

import { insertStylesForThemeColors } from "../../utils/DomUtils";

const ColorPicker = ({
	initialValue,
	desc = "No desc",
	propertyName,
	createCssClasses,
}) => {
	const [color, setColor] = useState(null);
	const throttledSetColor = useCallback(_.debounce(setColor, 200, {}));
	if (color == null) {
		console.log(
			"inital color value for ",
			propertyName,
			new TinyColor(initialValue).toHexString()
		);
	}
	useLayoutEffect(() => {
		if (color == null) return;
		createCssClasses && insertStylesForThemeColors({ [propertyName]: color });
		updateAppValue({ [propertyName]: color });
	}, [color]);

	// console.log(`Got ${propertyName} color `, color);
	return (
		<div className="d-flex mx-1 mt-1 p-1 bg-dark">
			<FFText
				className="text-white"
				style={styles.breadText}
				placeholder={desc}
			/>
			<input
				className="ml-auto"
				type="color"
				value={color || new TinyColor(initialValue).toHexString()}
				onChange={(evt) => throttledSetColor(evt.target.value)}
			></input>
		</div>
	);
};

class TabGeneral extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			primaryColor: "red",
			secondaryColor: "green",
		};
	}

	getCountriesAsOptions = () => {
		const config = StoreUtils.getReducer("config").item;
		return config.installedLanguages.map((langCode) => ({
			value: langCode,
			label: getEnglishLanguageNameForLanguageCode(langCode),
		}));
	};

	_onChangeLanguage = (value) => {
		ConfigActions.updateConfigValue({ language: value.value });
	};

	_onClearStorageClick = () => {
		clearStorage();
		const location = StoreUtils.getReducer("location").item;
		updateWindowHash(location.location, { clearStorage: true });

		setTimeout(() => {
			window.location.reload(true);
		}, 100);
	};

	getDevProjectFolders = () => {
		const isLocalhost = window.location.host.includes(":");
		if (!isLocalhost) return [];
		const config = StoreUtils.getReducer("config").item;
		return _.get(config, "devSettings.projects", []);
	};

	getCurrentSessionKey = () => {
		return _.get(StoreUtils.getReducer("quiddity"), "item.session.code");
	};

	render() {
		const { config, app } = this.props;

		const parsePossibleDateNumber = (dateNumber) => {
			let num = false;
			try {
				num = Number.parseInt(dateNumber);
			} catch (err) {
				// nothing
			}
			if (_.isNumber(num)) return new Date(num).toLocaleString("sv");
			else return dateNumber;
		};
		const buildInfo = {
			"config.version": _.get(config, "version"),
			buildTime: parsePossibleDateNumber(
				_.get(config, "latestCommitAtBuildTime.buildTime")
			),
			..._.mapValues(_.get(config, "dependencies"), (value) => {
				if (value.includes("@")) {
					// linked - has extra info
					const infoParts = value.split("time:");
					const date = infoParts.pop();
					const info = _.head(infoParts) + parsePossibleDateNumber(date);
					return info;
				} else return value;
			}),
		};

		const sessionKey = this.getCurrentSessionKey();

		return (
			<div style={styles.root}>
				<FFText
					style={styles.breadText}
					placeholder={`Storage: ${config.storage}`}
				/>
				<FFButton
					onClick={this._onClearStorageClick}
					className="btn btn-sm btn-light mb-1"
				>
					<FFText placeholder={`Clear storage`} />
				</FFButton>
				<FFButton
					onClick={() => {
						updateAppValue({ hideDevStatus: !app.hideDevStatus });
					}}
					className="btn btn-sm btn-light"
				>
					<FFText
						placeholder={
							app.hideDevStatus
								? `Show block dev status`
								: `Hide block dev status`
						}
					/>
				</FFButton>
				<FFButton
					onClick={() => {
						ConfigActions.updateConfigValue({ devMode: !config.devMode });
					}}
					className="btn btn-sm btn-light mt-1"
				>
					<FFText
						placeholder={!config.devMode ? `Enable devMode` : `Disable devMode`}
					/>
				</FFButton>
				<FFText
					tagName="pre"
					style={{ ...styles.breadText, fontSize: 10 }}
					placeholder={JSON.stringify(
						{
							...buildInfo,
						},
						null,
						1
					)}
				/>
				<Select
					onChange={this._onChangeLanguage}
					value={{
						value: config.language,
						label: getEnglishLanguageNameForLanguageCode(config.language),
					}}
					options={this.getCountriesAsOptions()}
					classNamePrefix="react-select"
					className="react-select-container"
					isClearable={false}
					isSearchable={true}
					autoFocus={false}
				/>

				{this.getDevProjectFolders().length > 0 && (
					<>
						<hr />
						<span className="text-white">Dev projects</span>
						<ul>
							{this.getDevProjectFolders().map((folderName) => (
								<li key={folderName}>
									<a
										className="text-white"
										target="_blank"
										rel="noreferrer"
										href={`${window.location.origin}#?project=${folderName}${
											!sessionKey ? "" : `&sessionKey=${sessionKey}`
										}`}
									>
										{folderName}
									</a>
								</li>
							))}
						</ul>
						<hr />
					</>
				)}

				{[
					{
						desc: "Primary color",
						colorPropertyName: "primary",
						createCssClasses: true,
					},
					{
						desc: "Secondary color",
						colorPropertyName: "secondary",
						createCssClasses: true,
					},
					{
						desc: "Interaction color",
						colorPropertyName: "interaction",
						createCssClasses: true,
					},
					{
						desc: "Info color",
						colorPropertyName: "info",
						createCssClasses: true,
					},
					{
						desc: "Warning color",
						colorPropertyName: "warning",
						createCssClasses: true,
					},
					{
						desc: "Fail color",
						colorPropertyName: "fail",
						createCssClasses: true,
					},
					{
						desc: "Danger color",
						colorPropertyName: "danger",
						createCssClasses: true,
					},
					{
						desc: "Success color",
						colorPropertyName: "success",
						createCssClasses: true,
					},
					{
						desc: "Block divider color",
						colorPropertyName: "color_block_divider",
						createCssClasses: false,
					},
				].map(({ colorPropertyName, createCssClasses, desc }) => (
					<ColorPicker
						key={colorPropertyName}
						initialValue={app[colorPropertyName]}
						desc={desc}
						propertyName={colorPropertyName}
						createCssClasses={createCssClasses}
					/>
				))}
			</div>
		);
	}
}

const styles = {
	root: {
		display: "flex",
		flexDirection: "column",
		padding: "2%",
	},
	breadText: {
		color: "white",
	},
};

const makeMapStateToProps = () => {
	const getConfigSelector = makeGetConfigSelector();
	const getAppSelector = makeGetAppSelector();
	const mapStateToProps = (state, props) => {
		return {
			config: getConfigSelector(state, props),
			app: getAppSelector(state, props),
		};
	};
	return mapStateToProps;
};

export default connect(makeMapStateToProps)(TabGeneral);
