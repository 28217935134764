import React, { useEffect } from "react";
import classnames from "classnames";
import { connect } from "../utils/reduxUtils";
import {
	ReduxCollectionSelectors,
	Actions,
	StoreUtils,
} from "funkis-foundation";
import MainMenuChapterContainer from "../components/slide/MainMenuChapterContainer";
import MainMenuPageButton from "../components/slide/MainMenuPageButton";
import PromptBrowserSwitch from "../components/core/PromptBrowserSwitch";

import Style from "./PageMenu.module.css";
import Text from "../components/core/Text";
import { getBootstrapColor } from "../utils/colorUtils";
import ImageVideoOrDiv from "../components/core/ImageVideoOrDiv";

const { makeGetItemIdsSelector } = ReduxCollectionSelectors;

const PageMenuSlide = (props) => {
	const { chapterIds, pageIds } = props;
	const app = StoreUtils.getReducer("app").item;

	useEffect(() => {
		Actions.addClassNamesToBody("page-menu");
		return () => {
			Actions.removeClassNamesFromBody("page-menu");
		};
	}, []);

	return (
		<ImageVideoOrDiv
			className={classnames(Style.slideOuterContainer)}
			style={{
				backgroundColor: getBootstrapColor(app.main_menu_background_color),
			}}
			src={app.main_menu_background_media}
		>
			<div
				className={classnames(
					Style.slideInnerContainer,
					"w-100 position-relative"
				)}
			>
				<PromptBrowserSwitch />

				<Text
					tagName="h1"
					textId={app.title_txt_id}
					className="ml-5 mb-3 font-weight-bold w-100"
					style={{ color: getBootstrapColor(app.main_menu_text_color) }}
				/>

				{chapterIds
					? chapterIds.map((id, index) => (
							<MainMenuChapterContainer
								key={`MainMenuChapterContainer-${id}`}
								id={id}
								index={index}
								className={Style.slideMainMenuChapterContainer}
							/>
					  ))
					: pageIds.map((id, index) => (
							<MainMenuPageButton
								key={`MainMenuPageButton-${id}`}
								id={id}
								index={index}
								className="col-sm-6"
							/>
					  ))}
			</div>
		</ImageVideoOrDiv>
	);
};

const makeMapStateToProps = () => {
	const getItemIdsSelector = makeGetItemIdsSelector();
	const mapStateToProps = (state, props) => {
		return {
			chapterIds: getItemIdsSelector(
				state,
				{
					match: (item) =>
						item.data && item.data.cmt && item.data.cmtPage.type === "chapter",
				},
				"pages"
			),
			pageIds: getItemIdsSelector(
				state,
				{
					match: (item) =>
						item.data && item.data.cmt && item.data.cmtPage.type === "page",
				},
				"pages"
			),
		};
	};
	return mapStateToProps;
};

export default connect(PageMenuSlide, makeMapStateToProps);
