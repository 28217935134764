import React, { useEffect } from "react";
import { FFText, AudioUtils } from "funkis-foundation";
import QuizCard from "./core/QuizCard";
import { getBootstrapColor } from "../../../utils/colorUtils";
import DefaultButton from "../../scroll/DefaultButton";

const Tab = ({ className }) => {
	return (
		<svg
			className={className}
			width="92px"
			height="92px"
			viewBox="0 0 92 92"
			version="1.1"
		>
			<title>Rectangle Copy 7</title>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="Tab">
					<polygon
						id="Rectangle-Copy-5"
						fillOpacity="0.8"
						fill="#FFFFFF"
						points="0 0 92 0 0 92"
					></polygon>
					<g
						id="Rectangle-Copy-7"
						transform="translate(46.000000, 46.000000) rotate(-180.000000) translate(-46.000000, -46.000000) translate(0.000000, 0.000000)"
						fill="#FFFFFF"
					>
						<polygon id="Rectangle-Copy-6" points="0 0 92 0 0 92"></polygon>
					</g>
				</g>
			</g>
		</svg>
	);
};

const QuizIntro = ({
	show,
	header,
	body,
	startButtonLabelTextId = "0428A4A5-51BF-43B8-B27D-C2B07F64A491",
	headerTextColor = "white",
	bodyTextColor = "white",
	onStart,
	contentHeight = "auto",
	contentMinHeight = "auto",
	backgroundColor,
	baseColor = "primary",
	soundOn = true,
	musicVolume = 1,
}) => {
	useEffect(() => {
		if (show && soundOn) {
			AudioUtils.play(`quiz-music`, { delay: 500 });
			if (AudioUtils.get("quiz-music").volume() === 0) {
				AudioUtils.fade("quiz-music", {
					from: AudioUtils.get("quiz-music").volume(),
					to: musicVolume,
				});
			}
		}
	}, [show, soundOn]);

	return (
		<QuizCard
			show={show}
			cardContext="intro"
			contentHeight={contentHeight}
			contentMinHeight={contentMinHeight}
		>
			{() => (
				<div
					style={{
						backgroundColor: getBootstrapColor(backgroundColor || baseColor),
					}}
					className="p-4 position-relative w-100 h-100 d-flex flex-column justify-content-stretch"
				>
					<FFText
						textId={header}
						className="quiz-intro-header mb-4"
						tagName="h1"
						style={{ color: getBootstrapColor(headerTextColor) }}
					/>
					<FFText
						className="mt-4 font-weight-bold"
						textId={body}
						tagName="p"
						style={{ color: getBootstrapColor(bodyTextColor), width: "75%" }}
					/>
					<div className="flex-grow-1 d-flex flex-column justify-content-end align-items-center">
						<DefaultButton
							onClick={() => {
								onStart && onStart();
							}}
						>
							<FFText
								textId={startButtonLabelTextId}
								className="font-weight-bold"
							/>
						</DefaultButton>
					</div>
					<Tab className="absolute-bottom absolute-right" />
				</div>
			)}
		</QuizCard>
	);
};

export default QuizIntro;
