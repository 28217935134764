import * as LogonErrorTypes from "../constants/LoginErrorTypes";

export const handleLoginError = (type, { code, url, api } = {}) => {
	switch (type) {
		case LogonErrorTypes.USER_NOT_FOUND:
			alert(`Wrong user or password. Please try again!`);
			break;
		case LogonErrorTypes.SESSION_NOT_FOUND:
			console.error(
				`Session for code ${code} could not be found! Is ${
					url + api
				} the correct endpoint?`
			);
			break;

		default:
			break;
	}
};
