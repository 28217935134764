import { convertQueryToString } from "../utils/ApiUtils";

const queries = {};

const throttleFetch = (query) => {
	const { throttle, throttleWait = 0 } = query;
	const queryStr = convertQueryToString(query, []);
	const dateNow = Date.now();
	const dateThen = queries?.[queryStr]?.date ?? 0;
	const dateDiff = dateNow - dateThen;

	let shouldThrottle = false;

	if (throttle && throttleWait > 0) {
		shouldThrottle = dateDiff < throttleWait;
	} else if (throttle) {
		shouldThrottle = dateThen > 0;
	}

	if (!shouldThrottle) {
		queries[queryStr] = { date: dateNow };
	}

	return shouldThrottle;
};

export default throttleFetch;
