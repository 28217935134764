import React from "react";
import PageItemScrollBase from "../../components/scroll/PageItemScrollBase";
import Article from "../../components/scroll/Article";

const PageItemArticle = (props) => {
	return (
		<PageItemScrollBase
			{...props}
			containerClassName="w-100"
			renderFunction={(renderProps) => (
				<Article {...props} viewVisible={renderProps.viewVisible}></Article>
			)}
		/>
	);
};

export default PageItemArticle;
