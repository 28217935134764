import React from "react";
import PieChart from "./PieChart";
import BarChart from "./BarChart";

const ChartFactory = (props) => {
	const { type } = props;
	switch (type) {
		case "bar":
			return <BarChart {...props} />;
		default:
			return <PieChart {...props} />;
	}
};

export default ChartFactory;
