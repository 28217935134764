import React, { useEffect } from "react";
import numberFormatter from "number-formatter";
import { useCountUp } from "react-countup";
import classnames from "classnames";
import _ from "lodash";
import Text from "../../../core/Text";
import { getBootstrapColor } from "../../../../utils/colorUtils";

const QizPoints = ({
	className,
	labelId,
	show = false,
	from = 0,
	to = 0,
	duration = 2,
	tagNameLabel = "span",
	tagNameCountUp: CustomTagCountUp = "h1",
	color = "white",
	countUpPrefix = "",
	countUpSuffix = "",
	onShowComplete = _.noop,
}) => {
	const { countUp, start, reset } = useCountUp({
		startOnMount: false,
		start: from,
		end: to,
		duration,
		onEnd: onShowComplete,
	});

	// Start count up on "show"
	useEffect(() => {
		if (show) {
			reset();
			start();
		} else {
			reset();
		}
	}, [show]);

	// Restart count up on value changes
	useEffect(() => {
		if (show) {
			reset();
			start();
		}
	}, [from, to]);

	const styleColor = { color: getBootstrapColor(color) };
	const value = String(numberFormatter("#,###.", countUp) || 0); //.replace(",", " ");

	return (
		<div className={classnames("d-flex flex-column", className)}>
			<Text
				tagName={tagNameLabel}
				textId={labelId}
				className="quiz-label mb-n1 font-weight-bold text-uppercase"
				style={styleColor}
			/>
			<CustomTagCountUp
				style={styleColor}
				className="d-block my-n1"
			>{`${countUpPrefix}${value}${countUpSuffix}`}</CustomTagCountUp>
		</div>
	);
};

export default (props) => {
	return props.show ? <QizPoints {...props} /> : <div />;
};
