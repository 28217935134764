const middlewares = [];

export const addMiddleware = (middleware) => {
	middlewares.push(middleware);
};

export const executeMiddlewares = (store) => (next) => (action) => {
	middlewares.forEach((middleware) => {
		middleware({ action, store, next });
	});
	// console.log("dispatching", action);
	let result = next(action);
	// console.log("next state", store.getState());
	return result;
};
