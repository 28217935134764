import { LocationUtils } from "funkis-foundation";
import { useEffect, useState } from "react";
import {
	parseThemeVariablesFromConfigPage,
	TitleThemeVariables,
} from "Theme/Theme.utils";
import AppTemplate from "./AppTemplate";
import Theme from "./Theme/Theme";

type ThemeConfig = {
	themeName: string;
	programType: "scroll" | "slide";
	themeVariables?: TitleThemeVariables;
};

type ConfigLoadingState = {
	state: "loading";
};

type ConfigFailedState = {
	state: "failed";
};

type ConfigSuccessState = {
	state: "success";
	themeConfig: ThemeConfig;
};

type ConfigState = ConfigLoadingState | ConfigFailedState | ConfigSuccessState;

const LoadApp = () => {
	const [loadState, setLoadState] = useState<ConfigState>({ state: "loading" });

	useEffect(() => {
		const fetchConfig = async () => {
			const query = LocationUtils.getQueryObjectFromCurrentUrl();

			// Get query param project to load content from different source...
			const { project: projectQueryParam } = query;
			const originalContentPath = "content/";
			const contentPath = projectQueryParam
				? `content/projects/${projectQueryParam}/public/content/`
				: originalContentPath;

			const data = await fetch(`${contentPath}json/cmt_page.json`);
			const cmtPages = await data.json();
			const cmtConfig = cmtPages[0];

			let themeVariables: TitleThemeVariables | undefined;

			try {
				themeVariables = parseThemeVariablesFromConfigPage(cmtConfig);
			} catch (e) {
				console.error("Theme variables could not be parsed", e);
			}

			setLoadState({
				state: "success",
				themeConfig: {
					themeName: cmtConfig.theme,
					programType: cmtConfig.program_type,
					themeVariables: themeVariables,
				},
			});
		};

		fetchConfig().catch((e) => {
			console.error(e);
			setLoadState({ state: "failed" });
		});
	}, []);

	switch (loadState.state) {
		case "loading":
			return <div>Loading...</div>;

		case "failed":
			return <div>Something went wrong when loading the config page</div>;

		case "success":
			const { themeConfig } = loadState;
			return (
				<Theme
					theme={themeConfig.themeName}
					programType={themeConfig.programType}
					titleThemeVariables={themeConfig.themeVariables}
				>
					<AppTemplate />
				</Theme>
			);
	}
};

export default LoadApp;
